export class NotificationTemplateFields {
  static readonly AGENT_SIGNUP_UPDATES = [
    'orgName',
    'organisationLogo',
    'newUserName',
    'userName',
  ];

  static readonly TRANSACTION_UPDATES = [
    'orgName',
    'organisationLogo',
    'title',
    'userName',
    'viewNotificationUrl',
    'subTitle',
    'newPrice',
    'depositType',
    'dueAt',
    'listingName',
    'overdueAt',
  ];

  static readonly NEW_MEMBER_UPDATES = [
    'orgName',
    'organisationLogo',
    'userName',
    'newUserName',
    'status'
  ];

  static readonly ALLOCATION_UPDATES = [
    'orgName',
    'organisationLogo',
    'userName',
    'viewNotificationUrl'
  ];

  static readonly LISTING_DETAIL_UPDATES = [
    'orgName',
    'organisationLogo',
    'listingName',
    'userName',
    'viewNotificationUrl',
    'newPrice',
    'oldPrice',
    'newStatus',
    'oldStatus',
    'oldBedroom',
    'newBedroom',
    'oldExternalArea',
    'newExternalArea',
    'oldInternalArea',
    'newInternalArea',
    'oldBathroom',
    'newBathroom'
  ];

  static readonly AGENCY_AGREEMENT_STATUS_UPDATES = [
    'orgName',
    'organisationLogo',
    'title',
    'userName',
    'envelopTitle',
    'newStatus',
    'sentAt',
    'completedAt',
    'voidAt',
    'voidReason'
  ];

  static readonly DEAL_STATUS_UPDATES = [
    'orgName',
    'organisationLogo',
    'listingName',
    'userName',
    'viewNotificationUrl',
    'newStatus',
    'oldStatus',
  ];

  static readonly NOVU_TEMPLATE_LINK = {
    AGENT_SIGNUP_UPDATES: 'https://web.novu.co/workflows/edit/65fbfca38453b14f2f7b9e54',
    AGENCY_AGREEMENT_STATUS_UPDATES: 'https://web.novu.co/workflows/edit/6604ff1fb551758561bc7c9c',
    ALLOCATION_UPDATES: 'https://web.novu.co/workflows/edit/6604f6e76df43c247a1787cd',
    DEAL_STATUS_UPDATES: 'https://web.novu.co/workflows/edit/660543da8b3193b7f0ff2012',
    NEW_MEMBER_UPDATES: 'https://web.novu.co/workflows/edit/6604edf38453b14f2f718faf',
    LISTING_DETAIL_UPDATES: 'https://web.novu.co/workflows/edit/6604fb586df43c247a17f53d',
    TRANSACTION_UPDATES: 'https://web.novu.co/workflows/edit/65fd362456028de3d8f06966',
  };
}
