import { HasuraUserDetailsInterface } from '../interfaces/hasura-user-details.interface';
import { Member_Role_Enum, Organisation_Type_Enum } from '@skychute/schema';
import { JwtHasuraRole } from '../types/jwt-hasura-role.type';
import { HasuraClaimsInterface } from '@skychute/shared-models';

export class JwtTokenModel {
  private readonly exp: number;
  private readonly iss: string;
  private readonly sub: string;
  private readonly authTime: number;
  private readonly iat: number;
  private readonly eventId: string;
  private readonly tokenUse: string;
  private readonly email: string;
  private readonly aud: string;
  private readonly firstName: string;
  private readonly lastName: string;
  private readonly cognitoUserName: string;
  private readonly hasuraClaims: HasuraClaimsInterface;
  private readonly userDetails: HasuraUserDetailsInterface;

  constructor(jwtToken: string) {
    const splitJwt = jwtToken.split('.');
    const decodedJwtBodyString = atob(splitJwt[1]);
    const decodedJwt = JSON.parse(decodedJwtBodyString);

    this.exp = decodedJwt.exp;
    this.iss = decodedJwt.iss;
    this.sub = decodedJwt.sub;
    this.authTime = decodedJwt.auth_time;
    this.iat = decodedJwt.iat;
    this.eventId = decodedJwt.event_id;
    this.tokenUse = decodedJwt.token_use;
    this.email = decodedJwt.email;
    this.aud = decodedJwt.aud;
    this.firstName = decodedJwt.given_name;
    this.lastName = decodedJwt.family_name;
    this.cognitoUserName = decodedJwt['cognito:username'];
    this.hasuraClaims = JSON.parse(decodedJwt['https://hasura.io/jwt/claims']);
    this.userDetails = JSON.parse(decodedJwt['user-details']) as HasuraUserDetailsInterface;
  }

  getHasuraClaims(): HasuraClaimsInterface {
    return this.hasuraClaims;
  }

  getHasuraUserId(): string {
    return this.hasuraClaims['x-hasura-user-id'];
  }

  getHasuraDefaultRole(): JwtHasuraRole {
    return this.hasuraClaims['x-hasura-default-role'] as JwtHasuraRole;
  }

  getHasuraTeamId(): string {
    return this.hasuraClaims['x-hasura-team-id'];
  }

  getHasuraTeamRole(): Member_Role_Enum {
    return this.hasuraClaims['x-hasura-team-role'];
  }

  getFirstName(): string {
    return this.firstName ?? '';
  }

  getLastName(): string {
    return this.lastName ?? '';
  }

  getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  getEmail(): string {
    return this.email;
  }

  getTeamType(): Organisation_Type_Enum {
    return this.hasuraClaims['x-hasura-team-type'] as Organisation_Type_Enum;
  }
}
