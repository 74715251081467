/**
 * Event coming from Salesforce to this service when we change data in Salesforce
 */
import { ISalesForceEventObject } from './salesforce-event-object.interface';

export interface ISalesForceEvent {
  new: ISalesForceEventObject[];
  old: ISalesForceEventObject[];
  userId: string;
  orgId: string; // it's actually an sfInstanceId
  hookToken: string;
  version: string;
  timestamp: number;
}
