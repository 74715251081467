import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SegmentService, UserAppConfigService } from '@skychute/shared-services';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@skychute/ui-models';
import { firstValueFrom } from 'rxjs';
import { JwtService } from '@skychute/jwt';
import { LoaderService } from '@skychute/ui/loader';
import { Apollo, gql } from 'apollo-angular';
import {
  HasuraLogQueriesMutation,
  HasuraLogQueriesMutationVariables,
} from '../generated/lib/operations';
import { Graphql_Query_Log_Insert_Input } from '@skychute/schema';
import { CssService } from '@skychute/css';

@Component({
  selector: 'skychute-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  favIconElement: HTMLLinkElement = document.querySelector('#appIcon');
  teamId: string | null;
  faviconUrl = '';
  isLoading = false;

  constructor(
    private router: Router,
    private segment: SegmentService,
    private jwt: JwtService,
    private apollo: Apollo,
    private userAppConfigService: UserAppConfigService, // private swUpdate: SwUpdate,
    private css: CssService,
  ) {
    super();
    this.css.setCssVars();
    // Start and Stop loader
    LoaderService.isLoading.pipe<boolean>(takeUntil(this.destroyed$)).subscribe((value) => {
      this.isLoading = value;
    });
    // TODO: disable after few days (added on April 19, 2023)
    // this.unregisterServiceWorkerAndRemoveCache();
  }

  ngOnInit(): void {
    this.watchHasuraQueriesIfNecessary();

    // this.trackWebAppUpdates();

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationStart),
      )
      .subscribe((event: NavigationStart) => {
        // Start loader if param does not exist
        // When Param change we can't receive a NavigationEnd event
        // Need to start loader from locally when Param changed if needed
        if (event.url.indexOf('?') === -1) {
          this.isLoading = true; // Start loader when initialising the module
        }
      });

    /**
     * segment code to track user's events
     */
    // ANALYTICS_START
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map((event) => event['url']),
        filter((url) => !!url),
        distinctUntilChanged(),
      )
      .subscribe((url: string) => {
        this.segment.page();
        this.isLoading = false; // Stop loader when initialised the module
      });
    // ANALYTICS_END

    try {
      this.teamId = this?.jwt?.getTeamId();
    } catch {
      // no token yet
    }

    if (this.teamId) {
      this.userAppConfigService
        .getSubject()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((appConfig) => {
          if (!appConfig) {
            return;
          }
          this.faviconUrl = appConfig.team.favIconUrl;
          this.favIconElement.href = this.faviconUrl;
        });
    }
  }

  trackWebAppUpdates(): void {
    // possible events
    /**
     * # When no new version was detected:
     * {
     *   "type": "NO_NEW_VERSION_DETECTED",
     *   "version": {
     *     "hash": "3ec9d33410e49caaf73e35b1d552bdfa977cbb8c"
     *   }
     * }
     *
     * # This is when new version was detected:
     * {
     *   "type": "VERSION_DETECTED",
     *   "version": {
     *     "hash": "3ec9d33410e49caaf73e35b1d552bdfa977cbb8c"
     *   }
     * }
     *
     * # This is when we can refresh the browser to apply new version:
     * {
     *   "type": "VERSION_READY",
     *   "currentVersion": {
     *     "hash": "121d1544c696eb713ec5883821c92e8fd091b127"
     *   },
     *   "latestVersion": {
     *     "hash": "3ec9d33410e49caaf73e35b1d552bdfa977cbb8c"
     *   }
     * }
     */
    // this.swUpdate.versionUpdates.subscribe((event) => {
    //   console.log(JSON.stringify(event, null, 4));
    //   if (event.type === 'VERSION_READY') {
    //     this.notify.newVersionAvailable();
    //   }
    // });
  }

  unregisterServiceWorkerAndRemoveCache(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          for (const registration of registrations) {
            //unregister service worker for old domain
            registration.unregister();
          }
        })
        .catch(function (err) {
          // fail state, which is fine as we just don't want a service worker.
          console.log('Fail: ', err);
        });
    }

    caches
      .keys()
      .then((keys) => Promise.all(keys.map(async (key) => await caches.delete(key))))
      .catch((err) => console.error('Something went wrong: ', err));
    console.log('Service Worker Unregister requested');
    console.log('Service Worker Cache cleanup requested');
    console.warn('Remove this in few days');
  }

  watchHasuraQueriesIfNecessary(): void {
    this.userAppConfigService
      .get()
      .then((appConfig) => {
        if (!appConfig.graphQlQueryLog) {
          return;
        }
        this.watchHasuraQueries();
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  watchHasuraQueries(): void {
    console.log('👀 Watching for queries');

    // send query summary every 10 seconds
    setInterval(async () => {
      try {
        await this.logHasuraEventsIfNecessary();
      } catch (err) {
        console.error(err);
      }
    }, 10000);
  }

  async logHasuraEventsIfNecessary(): Promise<void> {
    const logKey = '_x_hasura_logs_';
    if (window[logKey] === undefined || window[logKey].length === 0) {
      return;
    }
    let logItem: Graphql_Query_Log_Insert_Input;
    const input: Graphql_Query_Log_Insert_Input[] = [];

    // get first item & remove from array
    logItem = window[logKey].shift();

    while (logItem) {
      // skip query which inserts logs, otherwise we get infinite loop
      if (logItem.op_name === 'hasuraLogQueries') {
        // get next item & remove from array
        logItem = window[logKey].shift();
        continue;
      }

      input.push(logItem);

      // get next item & remove from array
      logItem = window[logKey].shift();
    }
    try {
      await firstValueFrom(
        this.apollo.mutate<HasuraLogQueriesMutation, HasuraLogQueriesMutationVariables>({
          mutation: HASURA_LOG_QUERIES,
          variables: {
            input,
          },
        }),
      );
    } catch (err) {
      console.log('unable to log queries, probably an anonymous user.');
    }
  }
}

//
// Queries
//
const HASURA_LOG_QUERIES = gql`
  mutation hasuraLogQueries($input: [graphql_query_log_insert_input!]!) {
    insert_graphql_query_log(objects: $input) {
      affected_rows
    }
  }
`;
