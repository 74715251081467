import { Attachment_Type_Enum } from '@skychute/schema';
import { AttachmentConfigInterface } from './attachment-config.interface';
import { AttachmentThumbnailInterface } from './attachment-thumbnail.interface';

export interface AttachmentInterface {
  id?: string;
  name?: string;
  download_url?: string;
  type?: Attachment_Type_Enum;
  content_type?: string;
  uploaded_by?: string;
  attachment_config?: AttachmentConfigInterface[];
  thumbnails?: AttachmentThumbnailInterface[];
  attachment_product_types?: Array<{
    product_type: string;
  }>;
  path?: string;
  product_type?: string;
  projectByLogoAttachmentId?: {
    id: string;
  };
  floor_properties?: Array<{
    id: string;
  }>;
  linen_properties?: Array<{
    id: string;
  }>;
  uploaded_by_team_id?: string;
}
