import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts "Shailesh Ladumor" to 'SL'
 * Usage: {{item.fullName | initials}}
 */
@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(fullName: string): string {
    if (!fullName) {
      return '';
    }

    return fullName
      .split(' ')
      .splice(0, 2) // keep only two first words
      .map((n) => n[0])
      .join('');
  }
}
