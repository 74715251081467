/**
 * This interface represents object we receive from Salesforce
 * when we do down-sync
 */
export interface ISalesForceEventObject {
  Id: string;
  RecordTypeId: string;
  orgId: string;
  [key: string]: any;
  attributes: {
    type: string;
    url: string;
  };
}
