import { Component } from '@angular/core';
import { LoaderService } from '../../loader.service';
import { distinctUntilChanged, takeUntil } from 'rxjs';
import { BaseComponent } from '@skychute/ui-models';

@Component({
  selector: 'skychute-bar-loader',
  templateUrl: './bar-loader.component.html',
  styleUrls: ['./bar-loader.component.scss'],
})
export class BarLoaderComponent extends BaseComponent {
  progress = 0;
  isHidden = true;

  private interval: number | null = null;
  private progressStep = 8;

  constructor(private loaderService: LoaderService) {
    super();
    this.loaderService.isBarLoaderVisible
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe(async (isVisible) => {
        if (this.interval) {
          clearInterval(this.interval);
        }
        if (isVisible) {
          this.startProgressBarAnimation();
        } else {
          await this.fastForwardToTheEndAndHide();
        }
      });
  }

  waitFor(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  startProgressBarAnimation(): void {
    this.progress = 0;
    this.isHidden = false;
    this.interval = window.setInterval(() => {
      const step = this.getProgressNextStep(this.progress);
      this.progress += step;
    }, 200);
  }

  async fastForwardToTheEndAndHide(): Promise<void> {
    this.progress = 100;
    await this.waitFor(300);
    this.isHidden = true;
    this.progress = 0;
  }

  /**
   * Every next step for progress bar is less and less based on current progress
   * @param progress
   */
  getProgressNextStep(progress: number): number {
    if (progress === 0) {
      return this.progressStep;
    }
    return this.progressStep * (1 - this.progress / 100);
  }
}
