import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts number to area string
 * example: {{TORN_MARKETING | underscoreToSpace}} => 'TORN MARKETING'
 */
@Pipe({
  name: 'underscoreToSpace',
})
export class UnderscoreToSpace implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return value;
    }

    return value.replace('_', ' ')
  }
}
