import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { JwtService } from '@skychute/jwt';
import { UserAppConfig } from './model/user-app-config.model';
import { GetUserAppConfigQuery, GetUserAppConfigQueryVariables } from '../generated/lib/operations';
import { IEnvironment } from '@skychute/ui-models';

@Injectable({
  providedIn: 'root',
})
export class UserAppConfigService {
  private userAppConfig$: BehaviorSubject<UserAppConfig> = new BehaviorSubject<UserAppConfig>(
    undefined,
  );
  private environment: IEnvironment;

  constructor(private apollo: Apollo, private jwt: JwtService, @Inject('environment') environment) {
    this.environment = environment;
  }

  getNonAsync(): UserAppConfig {
    return this.userAppConfig$.value;
  }

  async get(): Promise<UserAppConfig> {
    if (this.userAppConfig$.value) {
      return this.userAppConfig$.value;
    }
    await this.refresh();
    return this.userAppConfig$.value;
  }

  getSubject(): BehaviorSubject<UserAppConfig> {
    return this.userAppConfig$;
  }

  async refresh(): Promise<void> {
    const config = await this.getUserAppConfig();
    if (config.success) {
      const userAppConfig = new UserAppConfig(config, this.jwt.getTeamId() ?? '');
      this.environment.services.data.logQueries = userAppConfig.graphQlQueryLog ? 'true' : 'false';
      this.userAppConfig$.next(userAppConfig);
    }
  }

  private async getUserAppConfig(): Promise<GetUserAppConfigQuery['user_app_config']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetUserAppConfigQuery, GetUserAppConfigQueryVariables>({
        query: GET_USER_APP_CONFIG,
      }),
    );
    return resp.data.user_app_config;
  }
}

//
// Queries
//
const GET_USER_APP_CONFIG = gql`
  query getUserAppConfig {
    user_app_config {
      success
      message
      isEmailVerified
      isPhoneVerified
      webAppFeatures
      featurePacks
      extraFeatures
      memberFeatures
      teamFavIconUrl
      teamLogoUrl
      projectIdsToAllocate
      teams {
        id
        name
        role
      }
      salesforce {
        name
        sfInstanceId
        isCrmOwner
      }
      graphQlQueryLog
      cdn
    }
  }
`;
