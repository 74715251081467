import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ContainerComponent } from './container/container.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { FileUploadControlComponent } from './file-upload-control/file-upload-control.component';
import { CurrencySuffixPipe } from './pipes/currency-suffix.pipe';
import { ScreenAlertComponent } from './screen-alert/screen-alert.component';
import { LoaderComponent } from './loader/loader.component';
import { PropertyLoaderComponent } from './property-loader/property-loader.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { InsufficientInfiniteScrollDirective } from './directives/insufficient-infinite-scroll.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoComponent } from './logo/logo.component';
import { AreaPipe } from './pipes/area.pipe';
import { DistancePipe } from './pipes/distance.pipe';
import { CleanValuePipe } from './pipes/cleanValue.pipe';
import { YesOrNoPipe } from './pipes/yesOrNo.pipe';
import { ProjectListPipe } from './pipes/projectList.pipe';
import { UiUploadedDocumentModule } from '@skychute/ui/uploaded-document';
import { StripePaymentMethodComponent } from './stripe-payment-method/stripe-payment-method.component';
import { BpayComponent } from './bpay/bpay.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SortByAspectPipe } from './pipes/sort-by-aspect.pipe';
import { SortByAttachPipe } from './pipes/sort-by-attach.pipe';
import { AddressInputComponent } from './address-input/address-input.component';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { UiModelsModule } from '@skychute/ui-models';
import { UiAlertModule } from '@skychute/ui/alert';
import { AusCurrencyPipe } from './pipes/aus-currency.pipe';
import { SpinLoaderComponent } from './spin-loader/spin-loader.component';
import { TeamSwitcherDialogComponent } from './team-switcher-dialog/team-switcher-dialog.component';
import { DealStatusComponent } from './deal-status/deal-status.component';
import { DealDepositStatusComponent } from './deal-deposit-status/deal-deposit-status.component';
import { AusCurrencyWithoutDecimalPipe } from './pipes/aus-currency-without-decimal.pipe';
import { NumericRangeComponent } from './numeric-range/numeric-range.component';
import { SearchComponent } from './search/search.component';
import { SortAttachmentByCustomOrderPipe } from './pipes/sort-attachment-by-custom-order.pipe';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { CdnModule } from '@skychute/cdn';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UnderscoreToSpace } from './pipes/underscore-to-space.pipe';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InfiniteScrollModule,
    FormsModule,
    UiUploadedDocumentModule,
    ReactiveFormsModule,
    MatLegacyAutocompleteModule,
    UiModelsModule,
    UiAlertModule,
    CdnModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatLegacySlideToggleModule,
    MatDialogModule,
    MatButtonModule,
  ],
  declarations: [
    FilePreviewComponent,
    FileUploadControlComponent,
    ContainerComponent,
    CurrencySuffixPipe,
    AreaPipe,
    DistancePipe,
    CleanValuePipe,
    YesOrNoPipe,
    ProjectListPipe,
    ScreenAlertComponent,
    LoaderComponent,
    PropertyLoaderComponent,
    InsufficientInfiniteScrollDirective,
    BackButtonDirective,
    LogoComponent,
    StripePaymentMethodComponent,
    BpayComponent,
    PaginationComponent,
    SortByAspectPipe,
    SortByAttachPipe,
    AddressInputComponent,
    AusCurrencyPipe,
    AusCurrencyWithoutDecimalPipe,
    SpinLoaderComponent,
    TeamSwitcherDialogComponent,
    DealStatusComponent,
    DealDepositStatusComponent,
    NumericRangeComponent,
    SearchComponent,
    SortAttachmentByCustomOrderPipe,
    SlideToggleComponent,
    UnderscoreToSpace,
  ],
  exports: [
    FilePreviewComponent,
    FileUploadControlComponent,
    ContainerComponent,
    CurrencySuffixPipe,
    AreaPipe,
    DistancePipe,
    CleanValuePipe,
    YesOrNoPipe,
    ProjectListPipe,
    ScreenAlertComponent,
    LoaderComponent,
    PropertyLoaderComponent,
    InsufficientInfiniteScrollDirective,
    BackButtonDirective,
    LogoComponent,
    StripePaymentMethodComponent,
    BpayComponent,
    PaginationComponent,
    SortByAspectPipe,
    SortByAttachPipe,
    AddressInputComponent,
    AusCurrencyPipe,
    AusCurrencyWithoutDecimalPipe,
    SpinLoaderComponent,
    TeamSwitcherDialogComponent,
    DealDepositStatusComponent,
    DealStatusComponent,
    DealStatusComponent,
    NumericRangeComponent,
    SearchComponent,
    SortAttachmentByCustomOrderPipe,
    SlideToggleComponent,
    UnderscoreToSpace,
  ],
})
export class UiCommonModule {}
