import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@skychute/ui-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'skychute-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends BaseComponent implements AfterViewInit {
  @Input() placeholder = 'Search...';
  @Input() name = '';
  @Output() searchChanged = new EventEmitter<string>();
  @Output() searchChangedNew = new EventEmitter<string>();

  searchText = '';

  searchText$ = new BehaviorSubject<string>('');
  search$: Observable<string> | undefined = this.searchText$.pipe(
    takeUntil(this.destroyed$),
    debounceTime(1000),
    distinctUntilChanged(),
  );

  getViewValue(): string {
    return this.searchText;
  }

  setSearchTextNoTriggers(text: string): void {
    this.searchText = text;
  }

  setSearchText(text: string): void {
    this.setSearchTextNoTriggers(text);
    this.searchText$.next(text);
  }

  ngAfterViewInit(): void {
    if (this.searchChanged.observed) {
      this.search$.subscribe((value) => {
        this.searchChanged.emit(value);
      });
    }
  }

  onSearchChange(event): void {
    const newSearchText = (event.target as HTMLInputElement).value;
    this.searchText = newSearchText;
    this.searchText$.next(newSearchText);
    this.searchChangedNew.emit(newSearchText);
  }
}
