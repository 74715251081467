import { ISalesForceEvent } from '../interfaces/salesforce-event.interface';
import _ from 'lodash';

export function getSalesforceEventPropertiesDiff(
  salesforceEvent: ISalesForceEvent,
): EventPropertyDiffInterface[] {
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  const newObj = salesforceEvent.new[0] ?? {};
  const oldObj = salesforceEvent.old[0] ?? {};
  const oldFieldNames = Object.keys(newObj);
  const newFieldNames = Object.keys(oldObj);
  const fieldNames = [...oldFieldNames, ...newFieldNames].filter(onlyUnique).sort();

  let eventProperties: EventPropertyDiffInterface[] = [];
  for (const fieldName of fieldNames) {
    const newValue = newObj[fieldName];
    const oldValue = oldObj[fieldName];
    if (_.isObject(newValue) || _.isObject(newValue)) {
      continue;
    }

    eventProperties.push({
      fieldName,
      oldValue,
      newValue,
      isDifferent: newValue !== oldValue,
    });
  }
  eventProperties = eventProperties.sort((a, b) => {
    return Number(b.isDifferent) - Number(a.isDifferent);
  });
  return eventProperties;
}

export interface EventPropertyDiffInterface {
  fieldName: string;
  oldValue: string | number | boolean | null;
  newValue: string | number | boolean | null;
  isDifferent: boolean;
}
