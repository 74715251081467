import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { AddressInputComponentControlsInterface, AddressInputModel } from './address-input.model';
import { AddressService } from '../services/address.service';
import { BaseComponent, IEnvironment } from '@skychute/ui-models';
import { GetCountriesQuery } from '../../generated/operations';

declare let AddressFinder: any;

@Component({
  selector: 'skychute-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressInputComponent extends BaseComponent implements OnInit, AfterViewInit {
  addressForm: UntypedFormGroup;
  address1Control: UntypedFormControl;
  stateControl: UntypedFormControl;
  suburbControl: UntypedFormControl;
  postcodeControl: UntypedFormControl;
  countryControl: UntypedFormControl;
  // additional properties
  streetNumber: string = null;
  streetName: string = null;
  unitNumber: string = null;
  countrySearch: UntypedFormControl = new UntypedFormControl();
  filterCountries: GetCountriesQuery['country'] = [];
  countries: GetCountriesQuery['country'] = [];
  environment: IEnvironment;
  @Input() disabled = false;
  @ViewChild('form', { static: false }) form: ElementRef;

  constructor(
    @Inject('environment') environment: IEnvironment,
    private addressService: AddressService,
  ) {
    super();
    this.environment = environment;
    this.setControls(AddressInputComponent.getInitialControls());
    this.addressService
      .getCountries()
      .pipe(map((countriesResponse) => countriesResponse.data.country))
      .subscribe((countries) => {
        this.countries = countries;
        this.filterCountries = countries;
      });

    this.countrySearch.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.filterCountries = this.countries.filter((o) =>
        o.value.toLowerCase().includes(value.toLowerCase()),
      );
    });
  }

  get data(): AddressInputModel {
    return this.getData();
  }

  @Input()
  set data(details: AddressInputModel) {
    if (!details) {
      return;
    }
    this.setData(details);
  }

  protected static getInitialControls(): AddressInputComponentControlsInterface {
    const address1Control = new UntypedFormControl('', [Validators.required]);
    const stateControl = new UntypedFormControl('', [Validators.required]);
    const suburbControl = new UntypedFormControl('', [Validators.required]);
    const postcodeControl = new UntypedFormControl('', [Validators.required]);
    const countryControl = new UntypedFormControl('', [Validators.required]);
    return {
      address1Control,
      stateControl,
      suburbControl,
      postcodeControl,
      countryControl,
      addressForm: new UntypedFormGroup({
        address1Control,
        stateControl,
        suburbControl,
        postcodeControl,
        countryControl,
      }),
    };
  }

  async ngOnInit(): Promise<void> {
    this.addressForm.markAsPristine();
  }

  ngAfterViewInit(): void {
    this.loadAddressFinderScript();
  }

  loadAddressFinderScript(): void {
    const script = document.createElement('script');
    script.src = this.environment.addressFinder.src;
    script.async = true;
    script.onload = () => {
      const widget = new AddressFinder.Widget(
        document.getElementById('address-input__address'),
        this.environment.addressFinder.key,
        'AU',
        {},
      );
      widget.on('result:select', (fullAddress, metaData) => {
        let address = metaData.address_line_1;
        if (metaData.address_line_2) {
          address += `, ${metaData.address_line_2}`;
        }
        this.address1Control.setValue(address);
        this.stateControl.setValue(metaData.state_territory);
        this.suburbControl.setValue(metaData.locality_name);
        this.postcodeControl.setValue(metaData.postcode);
        // as we will only have autocomplete address for australia so selected it static here
        this.countryControl.setValue('AUSTRALIA');
        this.streetName = metaData.street;
        this.streetNumber = metaData.street_number_1;
        this.unitNumber = metaData.unit_identifier;

        if (!metaData.street_number_1 && metaData.lot_identifier) {
          this.streetNumber = `Lot ${metaData.lot_identifier}`;
        }
      });
    };
    document.body.appendChild(script);
  }

  setData(input: AddressInputModel): void {
    this.address1Control.setValue(input.address1);
    this.suburbControl.setValue(input.suburb);
    this.stateControl.setValue(input.state);
    this.postcodeControl.setValue(input.postcode);
    this.countryControl.setValue(input.country);

    // non fields data
    this.streetName = input.streetName;
    this.streetNumber = input.streetNumber;
    this.unitNumber = input.unitNumber;
    this.addressForm.markAsPristine();
  }

  isValid(): boolean {
    return this.addressForm.valid;
  }

  isAddressValid(): boolean {
    return (
      this.address1Control.valid &&
      this.stateControl.valid &&
      this.suburbControl.valid &&
      this.postcodeControl.valid
    );
  }

  validate(): void {
    this.addressForm.markAllAsTouched();
  }

  isDirty(): boolean {
    return this.addressForm.dirty;
  }

  markAsPristine(): void {
    this.addressForm.markAsPristine();
  }

  getData(): AddressInputModel {
    return {
      address1: this.address1Control.value,
      postcode: this.postcodeControl.value,
      state: this.stateControl.value,
      streetName: this.streetName,
      streetNumber: this.streetNumber,
      suburb: this.suburbControl.value,
      unitNumber: this.unitNumber,
      country: this.countryControl.value,
    };
  }

  getControls(): AddressInputComponentControlsInterface {
    return {
      address1Control: this.address1Control,
      stateControl: this.stateControl,
      suburbControl: this.suburbControl,
      postcodeControl: this.postcodeControl,
      addressForm: this.addressForm,
      countryControl: this.countryControl,
    };
  }

  private setControls(controls: AddressInputComponentControlsInterface): void {
    this.address1Control = controls.address1Control;
    this.stateControl = controls.stateControl;
    this.suburbControl = controls.suburbControl;
    this.postcodeControl = controls.postcodeControl;
    this.addressForm = controls.addressForm;
    this.countryControl = controls.countryControl;
  }
}
