export interface AgencyAgreementSettings {
  enabled: boolean;
  inheritFromStageId: string;
  annatureMasterEnvelopeId: string;
  envelopeSenderUserId: string;
  developerSignersUserIds: string[];
  agentMessage: string; // message agent will see in the envelope Email
  developerMessage: string; // message developer will see in the envelope Email
  groupId: string; // used for branding
}
