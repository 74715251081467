<div class="sky-container" [style.background-color]="bgColor">
  <div class="sky-container__header" [ngClass]="{ 'sky-container__spacing': !slim }"
       [style.border-bottom]="border">
    {{ titleText }}
    <!-- Primary Button -->
    <button mat-button *ngIf="primaryButton && !showSaveCancelButtons"
            (click)="primaryButtonClick.emit()">
      <mat-icon>{{ primaryButton.icon }}</mat-icon>
      {{ primaryButton.label }}
    </button>
    <div class="sky-container__save-cancel" *ngIf="showSaveCancelButtons">
      <!-- SAVE BUTTON -->
      <button mat-button
              (click)="saveButtonClick.emit()">
        <mat-icon>save</mat-icon>
        SAVE
      </button>
      <!-- CANCEL BUTTON -->
      <button mat-button
              (click)="cancelButtonClick.emit()">
        <mat-icon>close</mat-icon>
        CANCEL
      </button>
    </div>
  </div>
  <div class="sky-container__content" [ngClass]="{ 'sky-container__spacing': !slim }">
    <ng-content></ng-content>
  </div>
</div>
