import { Inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import {
  CodeVerificationQuery,
  CodeVerificationQueryVariables,
  CognitoRecoverUserMutation,
  CognitoRecoverUserMutationVariables,
  CreateCodeActionMutation,
  CreateCodeActionMutationVariables,
  EmailVerificationLoginQuery,
  EmailVerificationLoginQueryVariables,
  GetContactByUserIdQuery,
  GetContactByUserIdQueryVariables,
  GetRecentDealOfClientQuery,
  GetRecentDealOfClientQueryVariables,
  GetUserByContactIdQuery,
  GetUserByContactIdQueryVariables,
  ImpersonateUserQuery,
  ImpersonateUserQueryVariables,
  IsEmailPhoneVerifiedQuery,
  IsEmailPhoneVerifiedQueryVariables,
  IsExistingUserQuery,
  IsExistingUserQueryVariables,
  LoginByLoginSecretQuery,
  LoginByLoginSecretQueryVariables,
  MobileVerificationQuery,
  MobileVerificationQueryVariables,
  ResendEmailQuery,
  ResendEmailQueryVariables,
  ResetPasswordQuery,
  ResetPasswordQueryVariables,
  SendCodeActionMutation,
  SendCodeActionMutationVariables,
  SendEmailQuery,
  SendEmailQueryVariables,
  SendResetLinkQuery,
  SendResetLinkQueryVariables,
  VerifyCodeActionQuery,
  VerifyCodeActionQueryVariables,
} from '../generated/lib/operations';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { JwtLoginInterface, JwtService } from '@skychute/jwt';
import {
  IEnvironment,
  IUserInfo,
  IUserInfoNew,
} from '@skychute/ui-models';
import { SegmentService } from '@skychute/shared-services';
import { SegmentEnum } from '@skychute/shared-constants';
import { SignUpParams } from '@aws-amplify/auth/src/types';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import { normalizePhoneNumber } from '@skychute/shared-models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected privateUser: CognitoUser;
  protected refreshTokenInterval;
  protected currentSession: CognitoUserSession;
  protected environment: IEnvironment;

  // name of the key in browser's Local Storage to reference refresh token
  private REFRESH_TOKEN_KEY = 'refresh_token';

  // name of the key in browser's Local Storage to reference expiry date & time (in seconds)
  private TOKEN_EXPIRY_KEY = 'token_expiry';

  constructor(
    private jwt: JwtService,
    private apollo: Apollo,
    private wsClient: GraphQLWsLink,
    @Inject('environment') environment: IEnvironment,
    private segment: SegmentService,
    private router: Router,
  ) {
    this.environment = environment;
    const cognitoUserPoolConfig = {
      userPoolId: environment.services.aws.UserPoolId,
      userPoolWebClientId: environment.services.aws.AppClientId,
      // we no longer use localstack, and hence no need this
      // but we may need it at some point
      // endpoint: 'http://localhost:4566',
    };

    if (environment.production) {
      delete cognitoUserPoolConfig['endpoint'];
    }
    Auth.configure(cognitoUserPoolConfig);

    // refresh token every 30 minutes
    if (!this.refreshTokenInterval) {
      this.refreshTokenInterval = setInterval(async () => {
        await this.refreshToken();
      }, 30 * 60 * 1000);
    }
  }

  async user(): Promise<CognitoUser | null> {
    if (!this.privateUser) {
      try {
        this.privateUser = await Auth.currentUserPoolUser();
      } catch (e) {
        console.error(e);
        console.error(e?.stack);
        return null;
      }
    }
    return this.privateUser;
  }

  async refreshToken(): Promise<void> {
    if (!this.privateUser) {
      // attempt to get current user
      this.privateUser = await this.user();
      // probably not authenticated now
      if (!this.privateUser) {
        return;
      }
    }

    // get current session, then refresh it
    // put new token into localStorage
    Auth.currentSession().then((session) => {
      this.currentSession = session;
      this.privateUser.refreshSession(this.currentSession.getRefreshToken(), () => {
        Auth.currentSession().then((newSession) => {
          this.jwt.setToken(newSession.getIdToken().getJwtToken());
          console.log('token refreshed');
          this.wsClient.client.terminate();
        });
      });
    });
  }

  /**
   * Used for team switching, we acquire new token via switch_team hasura action
   * and apply this new token via given method
   */
  loginWithToken(jwtLogin: JwtLoginInterface): void {
    this.jwt.setToken(jwtLogin.token);
    this.jwt.setRefreshToken(jwtLogin.refreshToken);
    try {
      this.segment.identifyUser(jwtLogin.token).then();
    } catch (err) {
      console.error(err);
    }
    this.wsClient.client.terminate();
  }

  /**
   * @throws Exception
   * @param refreshToken
   */
  async exchangeRefreshTokenToJwtToken(refreshToken: string): Promise<void> {
    const clientId = this.environment.services.aws.AppClientId;
    const cognito = new CognitoIdentityProvider({
      region: 'ap-southeast-2',
      apiVersion: '2016-04-18',
    });

    const params = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };

    const resp = await cognito.initiateAuth(params);
    const token = resp.AuthenticationResult.IdToken;
    this.loginWithToken({ token, refreshToken });
  }

  async login(username: string, password: string): Promise<boolean> {
    try {
      this.privateUser = await Auth.signIn(username, password);
      const signInUserSession = this.privateUser.getSignInUserSession();
      if (!signInUserSession) {
        return false;
      }
      const token = signInUserSession.getIdToken().getJwtToken();
      const refreshToken = signInUserSession.getRefreshToken().getToken();
      this.loginWithToken({ token, refreshToken });
      return true;
    } catch (error) {
      if (await this.user()) {
        await this.logOut();
      }
      throw error;
    }
  }

  async signUp(userInfo: IUserInfo): Promise<CognitoUser> {
    try {
      const validationData: { [key: string]: any } = {
        given_name: userInfo.firstName,
        family_name: userInfo.lastName,
        email: userInfo.email,
      };
      const signUpReq: SignUpParams = {
        username: this.emailToUserName(userInfo.email),
        password: userInfo.password,
        attributes: {
          given_name: userInfo.firstName,
          family_name: userInfo.lastName,
          email: userInfo.email,
        },
        validationData,
        clientMetadata: {
          role: 'user_old',
        },
      };

      if (userInfo?.invitationId) {
        signUpReq.clientMetadata.invitationId = userInfo.invitationId;
      }

      if (userInfo?.agencyName) {
        signUpReq.clientMetadata.agencyName = userInfo.agencyName;
      }

      if (userInfo?.invitationId && userInfo?.agencyName) {
        signUpReq.clientMetadata.role = 'partner';
      }

      if (userInfo?.teamType) {
        signUpReq.clientMetadata.teamType = userInfo.teamType;
      }

      const { user } = await Auth.signUp(signUpReq);
      return user;
    } catch (error) {
      console.log('error signing up:', error.message);
      throw error;
    }
  }

  async signUpNew(userInfo: IUserInfoNew): Promise<CognitoUser> {
    try {
      const validationData = {
        given_name: userInfo.firstName,
        family_name: userInfo.lastName,
        email: userInfo.email,
        phone_number: userInfo.phone,
      };
      const { user } = await Auth.signUp({
        username: userInfo.username,
        password: userInfo.password,
        attributes: {
          given_name: userInfo.firstName,
          family_name: userInfo.lastName,
          phone_number: userInfo.phone,
          email: userInfo.email,
        },
        validationData,
        clientMetadata: {
          role: 'user',
        },
      });
      return user;
    } catch (error) {
      console.log('error signing up:', error.message);
      throw error;
    }
  }

  async verifyEmailAndLogin(
    verificationToken: string,
  ): Promise<EmailVerificationLoginQuery['token_verification_login']> {
    const resp = await firstValueFrom(
      this.apollo.query<EmailVerificationLoginQuery, EmailVerificationLoginQueryVariables>({
        query: EMAIL_VERIFICATION_AND_LOGIN,
        variables: { verificationToken },
      }),
    );
    if (resp.errors) {
      throw new Error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data.token_verification_login;
  }

  async resendEmailVerificationLink(): Promise<ApolloQueryResult<ResendEmailQuery>> {
    return firstValueFrom(
      this.apollo.query<ResendEmailQuery, ResendEmailQueryVariables>({
        query: RESEND_EMAIL,
        variables: {},
      }),
    );
  }

  async mobileVerification(
    mobileNumber: string,
    type: string,
    channel?: string,
  ): Promise<ApolloQueryResult<MobileVerificationQuery>> {
    mobileNumber = normalizePhoneNumber(mobileNumber);
    return firstValueFrom(
      this.apollo.query<MobileVerificationQuery, MobileVerificationQueryVariables>({
        query: MOBILE_VERIFICATION_QUERY,
        variables: { mobileNumber, type, channel: channel || 'sms' },
      }),
    );
  }

  async codeVerification(
    mobileNumber: string,
    code: string,
  ): Promise<CodeVerificationQuery['mobile_code_verification']> {
    mobileNumber = normalizePhoneNumber(mobileNumber);
    const resp = await firstValueFrom(
      this.apollo.mutate<CodeVerificationQuery, CodeVerificationQueryVariables>({
        mutation: CODE_VERIFICATION_QUERY,
        variables: { mobileNumber, code },
      }),
    );
    return resp.data.mobile_code_verification;
  }

  async forgotPassword(email: string): Promise<SendResetLinkQuery['send_reset_password_link']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<SendResetLinkQuery, SendResetLinkQueryVariables>({
        mutation: SEND_RESET_LINK,
        variables: { email },
      }),
    );
    return resp.data.send_reset_password_link;
  }

  async resetPassword(token: string, password: string): Promise<FetchResult<ResetPasswordQuery>> {
    return firstValueFrom(
      this.apollo.mutate<ResetPasswordQuery, ResetPasswordQueryVariables>({
        mutation: RESET_PASSWORD,
        variables: { token, password },
      }),
    );
  }

  async sendEmailVerificationLink(email: string): Promise<ApolloQueryResult<SendEmailQuery>> {
    return firstValueFrom(
      this.apollo.query<SendEmailQuery, SendEmailQueryVariables>({
        query: SEND_EMAIL,
        variables: { email },
      }),
    );
  }

  async impersonateUser(userId: string): Promise<ApolloQueryResult<ImpersonateUserQuery>> {
    return firstValueFrom(
      this.apollo.query<ImpersonateUserQuery, ImpersonateUserQueryVariables>({
        query: IMPERSONATE_USER,
        variables: { userId },
      }),
    );
  }

  async isEmailPhoneVerified(userId: string): Promise<IsEmailPhoneVerifiedQuery['user_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.query<IsEmailPhoneVerifiedQuery, IsEmailPhoneVerifiedQueryVariables>({
        query: IS_EMAIL_PHONE_VERIFIED,
        variables: { id: userId },
      }),
    );
    return resp.data.user_by_pk;
  }

  async loginByLoginSecret(loginSecret: string): Promise<LoginByLoginSecretQuery['link_login']> {
    const resp = await firstValueFrom(
      this.apollo.query<LoginByLoginSecretQuery, LoginByLoginSecretQueryVariables>({
        query: LOGIN_BY_LOGIN_SECRET,
        variables: {
          loginSecret,
        },
      }),
    );
    if (resp.errors) {
      throw new Error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data.link_login;
  }

  async getBuyerContactByUserId(
    userId: string,
  ): Promise<GetContactByUserIdQuery['user_by_pk']['contacts'][0] | null> {
    const resp = await firstValueFrom(
      this.apollo.query<GetContactByUserIdQuery, GetContactByUserIdQueryVariables>({
        query: GET_BUYER_CONTACT_BY_USER_ID,
        variables: {
          userId,
        },
        fetchPolicy: 'cache-first',
      }),
    );
    if (resp.errors) {
      console.error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data?.user_by_pk?.contacts?.[0] || null;
  }

  async getUserIdBuyContactId(contactId: string): Promise<string | null> {
    const resp = await firstValueFrom(
      this.apollo.query<GetUserByContactIdQuery, GetUserByContactIdQueryVariables>({
        query: GET_USER_ID_BY_CONTACT_ID,
        variables: {
          contactId,
        },
      }),
    );
    if (resp.errors) {
      console.error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data?.contact_by_pk?.user_id || null;
  }

  async getRecentDealOfClient(contactId: string): Promise<string | null> {
    const resp = await firstValueFrom(
      this.apollo.query<GetRecentDealOfClientQuery, GetRecentDealOfClientQueryVariables>({
        query: GET_RECENT_DEAL_OF_CLIENT,
        variables: {
          contactId,
        },
      }),
    );
    if (resp.errors) {
      console.error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data?.deal?.[0]?.id || null;
  }

  async logOut(): Promise<void> {
    const name = this.jwt.getTokenModel()?.getFullName() ?? 'unknown';
    this.segment.trackEvent(SegmentEnum.USER_LOGGED_OUT, { name });
    try {
      // this can fail if refresh token was revoked on AWS
      await Auth.signOut({ global: true });
    } catch (err) {
      console.log(err.message);
    }
    // refresh token is invalidated on log out, no reason to keep in the Local Storage
    this.jwt.deleteToken();
    this.setRefreshToken('');
    this.wsClient.client.terminate();
  }

  async signInNew(
    emailOrPhone: string,
    verifyVia: 'phone' | 'email' = 'phone',
  ): Promise<CognitoUser> {
    // client metadata is not available in Lambdas for some reason, so this bit is yet to be verified
    return await Auth.signIn(emailOrPhone, undefined, {
      verifyVia,
    });
  }

  async verifyOtpCode(user: CognitoUser, code: string): Promise<CognitoUser | any> {
    return await Auth.sendCustomChallengeAnswer(user, code);
  }

  async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  /**
   * Checks if user with given phone or email exists
   * @param input
   */
  async isExistingUser(input: {
    email?: string;
    phone?: string;
  }): Promise<IsExistingUserQuery['is_existing_user']> {
    const email = input.email ?? '';
    const phone = input.phone ?? '';
    const resp = await firstValueFrom(
      this.apollo.query<IsExistingUserQuery, IsExistingUserQueryVariables>({
        query: IS_EXISTING_USER,
        variables: {
          email,
          phone,
        },
      }),
    );
    return resp.data.is_existing_user;
  }

  /**
   * Creates security code, return id of the code
   * @param key Email or Phone number in internation format +996......
   */
  async createCode(key: string): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.mutate<CreateCodeActionMutation, CreateCodeActionMutationVariables>({
        mutation: CREATE_CODE_ACTION,
        variables: {
          key,
        },
      }),
    );
    return resp.data.create_code.id;
  }

  /**
   * Verify if code with given id matches given code value
   * @param id
   * @param code
   */
  async verifyCode(id: string, code: string): Promise<VerifyCodeActionQuery['verify_code']> {
    const resp = await firstValueFrom(
      this.apollo.query<VerifyCodeActionQuery, VerifyCodeActionQueryVariables>({
        query: VERIFY_CODE_ACTION,
        variables: {
          id,
          code,
        },
      }),
    );
    return resp.data.verify_code;
  }

  /**
   * Sends code with given id to the Email or SMS, depending on the value of the code.key
   * @param id
   */
  async sendCode(id: string): Promise<SendCodeActionMutation['send_code']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<SendCodeActionMutation, SendCodeActionMutationVariables>({
        mutation: SEND_CODE_ACTION,
        variables: {
          id,
        },
      }),
    );
    return resp.data.send_code;
  }

  async cognitoRecoverUser(
    userId: string,
  ): Promise<CognitoRecoverUserMutation['cognito_recover_user']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<CognitoRecoverUserMutation, CognitoRecoverUserMutationVariables>({
        mutation: COGNITO_RECOVER_USER,
        variables: {
          userId,
        },
      }),
    );
    return resp.data.cognito_recover_user;
  }

  /**
   * In AWS Cognito we're using a conventional usernames
   * for user with email pk@skychute.com.au username look like pk[at]skychute.com.au
   * we usually try to login with username instead of Email or Phone
   * because to login with Email, it should be verified in Cognito, same for phone number
   * however for username we don't need any verifications, we can login even though Email & phone are not verified
   * so username is quite convenient
   * @param email
   */
  emailToUserName(email: string): string {
    return email.replace('@', '[at]');
  }

  getRefreshToken(): string {
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
    return refreshToken ? refreshToken : '';
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  getTokenExpiry(): string {
    const tokenExpiry = localStorage.getItem(this.TOKEN_EXPIRY_KEY);
    return tokenExpiry ? tokenExpiry : '';
  }

  setTokenExpiry(unixTimeInSeconds: string): void {
    localStorage.setItem(this.TOKEN_EXPIRY_KEY, unixTimeInSeconds);
  }

  /**
   * @param returnUrl (optional) URL to return user to, after successful login. Empty by default.
   */
  async goToLoginPage(returnUrl = ''): Promise<void> {
    if (returnUrl) {
      await this.router.navigate(['/auth/login'], {
        queryParams: { return: returnUrl },
      });
    } else {
      await this.router.navigate(['/auth/login']);
    }
  }

  /**
   * Redirect user to a default page
   */
  async goToDefaultPage(): Promise<void> {
    await this.router.navigate(['/pages/projects']);
  }
}

//
// GraphQL Queries
//
const EMAIL_VERIFICATION_AND_LOGIN = gql`
  query emailVerificationLogin($verificationToken: String!) {
    token_verification_login(verificationToken: $verificationToken) {
      token
      refreshToken
      success
      error
    }
  }
`;

const RESEND_EMAIL = gql`
  query resendEmail {
    resend_email_verification_link {
      success
      message
    }
  }
`;

const MOBILE_VERIFICATION_QUERY = gql`
  query mobileVerification($mobileNumber: String!, $type: String!, $channel: String!) {
    verify_mobile_number(mobileNumber: $mobileNumber, type: $type, channel: $channel) {
      success
      message
    }
  }
`;

const CODE_VERIFICATION_QUERY = gql`
  query codeVerification($mobileNumber: String!, $code: String!) {
    mobile_code_verification(mobileNumber: $mobileNumber, code: $code) {
      success
      message
    }
  }
`;

const SEND_RESET_LINK = gql`
  query sendResetLink($email: String!) {
    send_reset_password_link(email: $email) {
      success
      error
    }
  }
`;

const RESET_PASSWORD = gql`
  query resetPassword($password: String!, $token: String!) {
    reset_password(password: $password, token: $token) {
      success
      error
      email
    }
  }
`;

const SEND_EMAIL = gql`
  query sendEmail($email: String!) {
    send_email_verification_link(email: $email) {
      success
      message
    }
  }
`;

const IMPERSONATE_USER = gql`
  query impersonateUser($userId: String!) {
    impersonate_user(userId: $userId) {
      accessToken
      success
      error
    }
  }
`;

const IS_EMAIL_PHONE_VERIFIED = gql`
  query isEmailPhoneVerified($id: uuid!) {
    user_by_pk(id: $id) {
      email_verified
      phone_number_verified
    }
  }
`;

const LOGIN_BY_LOGIN_SECRET = gql`
  query loginByLoginSecret($loginSecret: String!) {
    link_login(loginSecret: $loginSecret) {
      token
      refreshToken
      error
    }
  }
`;

const GET_BUYER_CONTACT_BY_USER_ID = gql`
  query getContactByUserId($userId: uuid!) {
    user_by_pk(id: $userId) {
      contacts(limit: 1, where: { type: { _eq: CLIENT } }) {
        id
        type
      }
    }
  }
`;

const GET_USER_ID_BY_CONTACT_ID = gql`
  query getUserByContactId($contactId: uuid!) {
    contact_by_pk(id: $contactId) {
      user_id
    }
  }
`;

const GET_RECENT_DEAL_OF_CLIENT = gql`
  query getRecentDealOfClient($contactId: uuid!) {
    deal(where: { lead_id: { _eq: $contactId } }, order_by: { created_at: desc }, limit: 1) {
      id
    }
  }
`;

const IS_EXISTING_USER = gql`
  query isExistingUser($email: String!, $phone: String!) {
    is_existing_user(email: $email, phone: $phone) {
      success
      phone_exists
      phone
      error
      id
    }
  }
`;

const CREATE_CODE_ACTION = gql`
  mutation createCodeAction($key: String!) {
    create_code(key: $key) {
      id
    }
  }
`;

const VERIFY_CODE_ACTION = gql`
  query verifyCodeAction($id: String!, $code: String!) {
    verify_code(id: $id, code: $code) {
      success
      error
    }
  }
`;

const SEND_CODE_ACTION = gql`
  mutation sendCodeAction($id: String!) {
    send_code(id: $id) {
      success
      error
    }
  }
`;

const COGNITO_RECOVER_USER = gql`
  mutation cognitoRecoverUser($userId: String!) {
    cognito_recover_user(userId: $userId) {
      success
      error
    }
  }
`;
