import { AnnatureAgencyAgreementFieldId, AnnatureFieldId } from '../types/annature-field-id.type';

export class AnnatureEnvelopeFields {
  static readonly AGENT_FIELDS: AnnatureAgencyAgreementFieldId[] = [
    'agency_name',
    'agency_full_address',
    'agency_acn',
    'agency_abn',
    'agency_registered_for_gst',
    'agency_trading_name',
    'agency_licence_number',
    'licensee_name',
    'licensee_full_address',
    'commission_rate',
    'agency_full_address_2',
    'agent_user_email',
    'agent_user_phone',
    'agent_user_company',
    'agent_user_sig_1',
    'agent_user_sig_2',
    'agent_user_full_name',
    'buildings',
  ];

  static readonly AGENCY_AGREEMENT_VENDOR_FIELDS: AnnatureAgencyAgreementFieldId[] = [
    'dev_user_sig',
    'dev_user_full_name',
    'dev_sign_date',
  ];

  static readonly FIRST_BUYER_PHYSICAL_FIELDS: AnnatureFieldId[] = [
    'psol_org_name',
    'psol_org_address',
    'psol_name',
    'psol_phone',
    'psol_email',
    'price',
    'deposit',
    'balance',
    'unit',
    'lot',
    'buyer_1_sig',
    'joint_tenants',
    'tenants_in_common',
    'in_unequal_shares',
    'in_unequal_shares_specify',
    'buyer_1_name',
    'buyer_1_address',
    'buyer_1_address_2',
    'buyer_1_email',
    'buyer_1_tax_file_number',
    'resident_au',
    'resident_permanent',
    'resident_nationality',
    'buyer_1_trustee_yes',
    'buyer_1_trustee_no',
    'buyer_1_trustee_info',
    'flooring_upgrade',
    'appliance_upgrade_package',
    'blinds_automation',
    'firb_buyer_1_name',
    'firb_buyer_1_address',
    'firb_buyer_1_tax_file_number',
    'resident_other',
    'resident_other_nationality',
    'name_on_contract',
    'colour_dark',
    'colour_light',
    'has_carspace',
    'num_carspaces',
    'storage',
  ];

  static readonly FIRST_BUYER_COMPANY_FIELDS: AnnatureFieldId[] = [
    'buyer_1_address',
    'name_on_contract',
    'psol_org_name',
    'psol_org_address',
    'psol_name',
    'psol_phone',
    'psol_email',
    'price',
    'deposit',
    'balance',
    'unit',
    'lot',
    'joint_tenants',
    'tenants_in_common',
    'in_unequal_shares',
    'in_unequal_shares_specify',
    'buyer_1_company_name',
    'buyer_1_director_name',
    'buyer_1_company_shareholders',
    'buyer_1_company_sig',
    'flooring_upgrade',
    'appliance_upgrade_package',
    'blinds_automation',
    'buyer_1_company_position',
    'buyer_2_company_position',
    'colour_dark',
    'colour_light',
    'has_carspace',
    'num_carspaces',
    'storage',
  ];

  static readonly FIRST_BUYER_S66W_FIELDS: AnnatureFieldId[] = [
    's66w_psol_name',
    's66w_psol_org_name',
    's66w_unit',
    's66w_lot',
    's66w_name_on_contract',
  ];

  static readonly SECOND_BUYER_PHYSICAL_FIELDS: AnnatureFieldId[] = [
    'buyer_2_name',
    'buyer_2_sig',
    'buyer_2_email',
    'firb_buyer_2_name',
    'firb_buyer_2_address',
    'firb_buyer_2_tax_file_number',
  ];

  static readonly SECOND_BUYER_COMPANY_FIELDS: AnnatureFieldId[] = [
    'buyer_2_director_name',
    'buyer_2_company_sig',
  ];

  static readonly FIRST_VENDOR_FIELDS: AnnatureFieldId[] = ['vendor_1_sig', 'contract_date'];

  static readonly SECOND_VENDOR_FIELDS: AnnatureFieldId[] = ['vendor_2_sig'];

  static readonly BUYER_WITNESS_FIELDS: AnnatureFieldId[] = [
    'witness_1_sig_1',
    'witness_1_sig_2',
    'witness_1_name_1',
    'witness_1_name_2',
  ];

  static readonly BUYER_FIRST_WITNESS_FIELDS: AnnatureFieldId[] = [
    'witness_1_name_1',
    'witness_1_sig_1',
  ];

  static readonly BUYER_SECOND_WITNESS_FIELDS: AnnatureFieldId[] = [
    'witness_1_name_2',
    'witness_1_sig_2',
  ];

  static readonly PSOL_FIELDS: AnnatureFieldId[] = ['s66w_psol_date_signed', 's66w_psol_sig_1'];
}
