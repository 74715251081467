import { Deal_Status_Enum, Listing_Status_Enum } from '@skychute/schema';
import { ListingPriceRequest } from '../interfaces/listing-price-request.interface';

export function isDealReserved(status: Deal_Status_Enum | null | undefined): boolean {
  return [
    Deal_Status_Enum.Reserved,
    Deal_Status_Enum.Issued,
    Deal_Status_Enum.Unconditional,
    Deal_Status_Enum.Settled,
    Deal_Status_Enum.Conditional,
    Deal_Status_Enum.Cancelled,
  ].includes(status);
}

export function isDealExchanged(status: Deal_Status_Enum | null | undefined): boolean {
  return [
    Deal_Status_Enum.Conditional,
    Deal_Status_Enum.Unconditional,
    Deal_Status_Enum.Settled,
  ].includes(status);
}

export function isDealOnHold(status: Deal_Status_Enum): boolean {
  return Deal_Status_Enum.OnHold === status;
}

export function listingStatusCanBeReserved(status: Listing_Status_Enum): boolean {
  return (
    status === Listing_Status_Enum.Available ||
    status === Listing_Status_Enum.OnSale ||
    status === Listing_Status_Enum.SalesHold
  );
}

export function isDealActive(status: Deal_Status_Enum | null | undefined): boolean {
  return [
    Deal_Status_Enum.Reserved,
    Deal_Status_Enum.Issued,
    Deal_Status_Enum.Unconditional,
    Deal_Status_Enum.Settled,
    Deal_Status_Enum.Conditional,
    Deal_Status_Enum.Eoi,
  ].includes(status);
}

export function getListingDisplayPrice(req: ListingPriceRequest): number {
  return isDealActive(req.offer.status) ? req.offer.priceIncVariationsCalc : req.listingPrice;
}
