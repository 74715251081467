import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { hasMessage } from '@skychute/ui-models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private snackBar: MatSnackBar) {
  }

  success(message: string, duration = 6000): void {
    this.snackBar.open(message, 'Close', {
      duration: duration,
      panelClass: 'global__notification--success',
    });
  }

  /**
   * Prints error message contained in the error object, in case it has .message property
   * Otherwise prints 'Unknown Error' message
   */
  errorObj(err: any, duration = 10000): void {
    let message = 'Unknown Error';
    if (hasMessage(err)) {
      message = err.message;
    }
    this.snackBar.open(message, 'Close', {
      duration: duration,
      panelClass: 'global__notification--error',
    });
  }

  error(message: string, duration = 10000): void {
    this.snackBar.open(message, 'Close', {
      duration: duration,
      panelClass: 'global__notification--error',
    });
  }

  warn(message: string, duration = 6000): void {
    this.snackBar.open(message, 'Close', {
      duration: duration,
      panelClass: 'global__notification--warning',
    });
  }

  newVersionAvailable(): void {
    const snackBarRef = this.snackBar.open('New version available!', 'Upgrade', {
      duration: 20000,
      verticalPosition: 'top',
      panelClass: 'global__notification--success',
    });

    snackBarRef
      .onAction()
      .pipe(take(1))
      .subscribe(() => {
        document.location.reload();
      });
  }
}
