export function isAbnOrAcn(abnOrAcn: string | null | undefined): boolean {
  return isAbn(abnOrAcn) || isAcn(abnOrAcn);
}

export function extractAbn(abn: string): string {
  return abn.trim().replace(/[^0-9]/g, '');
}

export function isAbn(abn: string | null | undefined): boolean {
  if (!abn) {
    return false;
  }
  const cleanAbn = abn.trim().replace(/[^0-9]/g, '');
  const abnRegex = /^\d{11}$/;
  return abnRegex.test(cleanAbn);
}

export function isAcn(acn: string | null | undefined): boolean {
  if (!acn) {
    return false;
  }
  const cleanAcn = acn.trim().replace(/[^0-9]/g, '');
  const acnRegex = /^\d{9}$/;
  return acnRegex.test(cleanAcn);
}
