export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: boolean;
  Float: number;
  ID: string;
  Int: number;
  String: string;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  point: any;
  polygon: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
}
export enum __DirectiveLocation {

  Query = 'QUERY',

  Mutation = 'MUTATION',

  Subscription = 'SUBSCRIPTION',

  Field = 'FIELD',

  FragmentDefinition = 'FRAGMENT_DEFINITION',

  FragmentSpread = 'FRAGMENT_SPREAD',

  InlineFragment = 'INLINE_FRAGMENT',

  VariableDefinition = 'VARIABLE_DEFINITION',

  Schema = 'SCHEMA',

  Scalar = 'SCALAR',

  Object = 'OBJECT',

  FieldDefinition = 'FIELD_DEFINITION',

  ArgumentDefinition = 'ARGUMENT_DEFINITION',

  Interface = 'INTERFACE',

  Union = 'UNION',

  Enum = 'ENUM',

  EnumValue = 'ENUM_VALUE',

  InputObject = 'INPUT_OBJECT',

  InputFieldDefinition = 'INPUT_FIELD_DEFINITION',
}

export enum __TypeKind {

  Scalar = 'SCALAR',

  Object = 'OBJECT',

  Interface = 'INTERFACE',

  Union = 'UNION',

  Enum = 'ENUM',

  InputObject = 'INPUT_OBJECT',

  List = 'LIST',

  NonNull = 'NON_NULL',
}

/** unique or primary key constraints on table "acl_allocation_edit" */
export enum Acl_Allocation_Edit_Constraint {
  /** unique or primary key constraint on columns "project_id", "user_id", "description", "team_id" */

  IdxAclAllocationEditUnique = 'idx_acl_allocation_edit_unique',
}

/** select columns of table "acl_allocation_edit" */
export enum Acl_Allocation_Edit_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_allocation_edit" */
export enum Acl_Allocation_Edit_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_allocation" */
export enum Acl_Allocation_Select_Column {
  /** column name */

  AllocationId = 'allocation_id',
  /** column name */

  Description = 'description',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_contact_agent_member" */
export enum Acl_Contact_Agent_Member_Constraint {
  /** unique or primary key constraint on columns "contact_id", "user_id", "description", "team_id" */

  IdxAclContactAgentMemberUnique = 'idx_acl_contact_agent_member_unique',
}

/** select columns of table "acl_contact_agent_member" */
export enum Acl_Contact_Agent_Member_Select_Column {
  /** column name */

  ContactEmail = 'contact_email',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserEmail = 'user_email',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_contact_agent_member" */
export enum Acl_Contact_Agent_Member_Update_Column {
  /** column name */

  ContactEmail = 'contact_email',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserEmail = 'user_email',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_contact_developer_member" */
export enum Acl_Contact_Developer_Member_Constraint {
  /** unique or primary key constraint on columns "contact_id", "user_id", "description", "team_id" */

  IdxAclContactDeveloperMemberUnique = 'idx_acl_contact_developer_member_unique',
}

/** select columns of table "acl_contact_developer_member" */
export enum Acl_Contact_Developer_Member_Select_Column {
  /** column name */

  ContactEmail = 'contact_email',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserEmail = 'user_email',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_contact_developer_member" */
export enum Acl_Contact_Developer_Member_Update_Column {
  /** column name */

  ContactEmail = 'contact_email',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserEmail = 'user_email',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_contact_master_agent" */
export enum Acl_Contact_Master_Agent_Constraint {
  /** unique or primary key constraint on columns "contact_id", "user_id", "description", "team_id" */

  AclContactMasterAgentUnique = 'acl_contact_master_agent_unique',
}

/** select columns of table "acl_contact_master_agent" */
export enum Acl_Contact_Master_Agent_Select_Column {
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_contact_master_agent" */
export enum Acl_Contact_Master_Agent_Update_Column {
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_deal_agent_member" */
export enum Acl_Deal_Agent_Member_Constraint {
  /** unique or primary key constraint on columns "user_id", "envelope_owner", "description", "deal_id", "team_id" */

  IdxAclDealAgentMemberUnique = 'idx_acl_deal_agent_member_unique',
}

/** select columns of table "acl_deal_agent_member" */
export enum Acl_Deal_Agent_Member_Select_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  EnvelopeOwner = 'envelope_owner',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_deal_agent_member" */
export enum Acl_Deal_Agent_Member_Update_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  EnvelopeOwner = 'envelope_owner',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_deal_developer_member" */
export enum Acl_Deal_Developer_Member_Constraint {
  /** unique or primary key constraint on columns "user_id", "description", "deal_id", "team_id" */

  IdxAclDealDeveloperMemberUnique = 'idx_acl_deal_developer_member_unique',
}

/** select columns of table "acl_deal_developer_member" */
export enum Acl_Deal_Developer_Member_Select_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_deal_developer_member" */
export enum Acl_Deal_Developer_Member_Update_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_deal_master_agent" */
export enum Acl_Deal_Master_Agent_Constraint {
  /** unique or primary key constraint on columns "user_id", "description", "deal_id", "team_id" */

  IdxAclDealMasterAgentUnique = 'idx_acl_deal_master_agent_unique',
}

/** select columns of table "acl_deal_master_agent" */
export enum Acl_Deal_Master_Agent_Select_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_deal_master_agent" */
export enum Acl_Deal_Master_Agent_Update_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_deal_user_team_desc" */
export enum Acl_Deal_User_Team_Desc_Select_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_deal_user_team" */
export enum Acl_Deal_User_Team_Select_Column {
  /** column name */

  DealId = 'deal_id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_listing_agent_member" */
export enum Acl_Listing_Agent_Member_Constraint {
  /** unique or primary key constraint on columns "project_id", "listing_status", "property_id", "user_id", "listing_id", "description", "team_id" */

  AclListingAgentMemberUnique = 'acl_listing_agent_member_unique',
}

/** select columns of table "acl_listing_agent_member" */
export enum Acl_Listing_Agent_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ListingStatus = 'listing_status',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_listing_agent_member" */
export enum Acl_Listing_Agent_Member_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ListingStatus = 'listing_status',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_developer_member" */
export enum Acl_Listing_Developer_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_listing_master_agent" */
export enum Acl_Listing_Master_Agent_Constraint {
  /** unique or primary key constraint on columns "project_id", "property_id", "user_id", "listing_id", "description", "team_id", "status" */

  AclListingMasterAgentUnique = 'acl_listing_master_agent_unique',
}

/** select columns of table "acl_listing_master_agent" */
export enum Acl_Listing_Master_Agent_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  Status = 'status',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_listing_master_agent" */
export enum Acl_Listing_Master_Agent_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  Status = 'status',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_property_desc" */
export enum Acl_Listing_Property_Desc_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ParentPropertyGroupId = 'parent_property_group_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_property_member" */
export enum Acl_Listing_Property_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_property" */
export enum Acl_Listing_Property_Select_Column {
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ParentPropertyGroupId = 'parent_property_group_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_property_team_desc" */
export enum Acl_Listing_Property_Team_Desc_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ParentPropertyGroupId = 'parent_property_group_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_property_team" */
export enum Acl_Listing_Property_Team_Select_Column {
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ParentPropertyGroupId = 'parent_property_group_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_via_listing_agent_member" */
export enum Acl_Listing_Via_Listing_Agent_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ListingStatus = 'listing_status',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "acl_listing_via_stage_agent_member" */
export enum Acl_Listing_Via_Stage_Agent_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  ListingStatus = 'listing_status',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_org_agent_member" */
export enum Acl_Org_Agent_Member_Constraint {
  /** unique or primary key constraint on columns "user_id", "organisation_id", "description", "team_id" */

  IdxAclOrgAgentMemberUnique = 'idx_acl_org_agent_member_unique',
}

/** select columns of table "acl_org_agent_member" */
export enum Acl_Org_Agent_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_org_agent_member" */
export enum Acl_Org_Agent_Member_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_org_developer_member" */
export enum Acl_Org_Developer_Member_Constraint {
  /** unique or primary key constraint on columns "user_id", "organisation_id", "description", "team_id" */

  IdxAclOrgDeveloperMemberUnique = 'idx_acl_org_developer_member_unique',
}

/** select columns of table "acl_org_developer_member" */
export enum Acl_Org_Developer_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_org_developer_member" */
export enum Acl_Org_Developer_Member_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_org_feature_enabled" */
export enum Acl_Org_Feature_Enabled_Constraint {
  /** unique or primary key constraint on columns "feature", "org_id" */

  IdxAclOrgFeatureEnabledUnique = 'idx_acl_org_feature_enabled_unique',
}

/** select columns of table "acl_org_feature_enabled" */
export enum Acl_Org_Feature_Enabled_Select_Column {
  /** column name */

  Feature = 'feature',
  /** column name */

  OrgId = 'org_id',
}

/** update columns of table "acl_org_feature_enabled" */
export enum Acl_Org_Feature_Enabled_Update_Column {
  /** column name */

  Feature = 'feature',
  /** column name */

  OrgId = 'org_id',
}

/** unique or primary key constraints on table "acl_org_master_agent" */
export enum Acl_Org_Master_Agent_Constraint {
  /** unique or primary key constraint on columns "org_id", "user_id", "description", "team_id" */

  IdxAclOrgMasterAgentUnique = 'idx_acl_org_master_agent_unique',
}

/** select columns of table "acl_org_master_agent" */
export enum Acl_Org_Master_Agent_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_org_master_agent" */
export enum Acl_Org_Master_Agent_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_property_allocation" */
export enum Acl_Property_Allocation_Constraint {
  /** unique or primary key constraint on columns "allocated_at", "property_group_id", "project_id", "allocated_to_org_name", "listing_status", "property_id", "property_type", "property_name", "allocated_to_org_id", "allocation_type" */

  IdxAclPropertyAllocation = 'idx_acl_property_allocation',
}

/** unique or primary key constraints on table "acl_property_allocation_count" */
export enum Acl_Property_Allocation_Count_Constraint {
  /** unique or primary key constraint on columns "last_group_allocated_at", "last_listing_allocated_at", "last_allocated_at", "listing_allocation_count", "sort_alloc_count", "property_id", "allocated_to", "alloc_count", "group_alloc_count" */

  IdxAclPropertyAllocationCount = 'idx_acl_property_allocation_count',
}

/** select columns of table "acl_property_allocation_count" */
export enum Acl_Property_Allocation_Count_Select_Column {
  /** column name */

  AllocCount = 'alloc_count',
  /** column name */

  AllocatedTo = 'allocated_to',
  /** column name */

  GroupAllocCount = 'group_alloc_count',
  /** column name */

  LastAllocatedAt = 'last_allocated_at',
  /** column name */

  LastGroupAllocatedAt = 'last_group_allocated_at',
  /** column name */

  LastListingAllocatedAt = 'last_listing_allocated_at',
  /** column name */

  ListingAllocationCount = 'listing_allocation_count',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  SortAllocCount = 'sort_alloc_count',
}

/** update columns of table "acl_property_allocation_count" */
export enum Acl_Property_Allocation_Count_Update_Column {
  /** column name */

  AllocCount = 'alloc_count',
  /** column name */

  AllocatedTo = 'allocated_to',
  /** column name */

  GroupAllocCount = 'group_alloc_count',
  /** column name */

  LastAllocatedAt = 'last_allocated_at',
  /** column name */

  LastGroupAllocatedAt = 'last_group_allocated_at',
  /** column name */

  LastListingAllocatedAt = 'last_listing_allocated_at',
  /** column name */

  ListingAllocationCount = 'listing_allocation_count',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  SortAllocCount = 'sort_alloc_count',
}

/** select columns of table "acl_property_allocation" */
export enum Acl_Property_Allocation_Select_Column {
  /** column name */

  AllocatedAt = 'allocated_at',
  /** column name */

  AllocatedToOrgId = 'allocated_to_org_id',
  /** column name */

  AllocatedToOrgName = 'allocated_to_org_name',
  /** column name */

  AllocationType = 'allocation_type',
  /** column name */

  ListingStatus = 'listing_status',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  PropertyName = 'property_name',
  /** column name */

  PropertyType = 'property_type',
}

/** update columns of table "acl_property_allocation" */
export enum Acl_Property_Allocation_Update_Column {
  /** column name */

  AllocatedAt = 'allocated_at',
  /** column name */

  AllocatedToOrgId = 'allocated_to_org_id',
  /** column name */

  AllocatedToOrgName = 'allocated_to_org_name',
  /** column name */

  AllocationType = 'allocation_type',
  /** column name */

  ListingStatus = 'listing_status',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  PropertyName = 'property_name',
  /** column name */

  PropertyType = 'property_type',
}

/** unique or primary key constraints on table "acl_stage_agent_member" */
export enum Acl_Stage_Agent_Member_Constraint {
  /** unique or primary key constraint on columns "user_id", "description", "stage_id", "team_id" */

  IdxAclStageAgentMemberUnique = 'idx_acl_stage_agent_member_unique',
}

/** select columns of table "acl_stage_agent_member" */
export enum Acl_Stage_Agent_Member_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  StageId = 'stage_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "acl_stage_agent_member" */
export enum Acl_Stage_Agent_Member_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  StageId = 'stage_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "acl_update" */
export enum Acl_Update_Constraint {
  /** unique or primary key constraint on columns "acl" */

  AclUpdatePkey = 'acl_update_pkey',
}

/** select columns of table "acl_update" */
export enum Acl_Update_Select_Column {
  /** column name */

  Acl = 'acl',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Message = 'message',
  /** column name */

  RequestCount = 'request_count',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "acl_update_status" */
export enum Acl_Update_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  AclUpdateStatusPkey = 'acl_update_status_pkey',
}

export enum Acl_Update_Status_Enum {
  /** ACL refresh returned error */

  Error = 'ERROR',
  /** ACL refresh is in progress */

  InProgress = 'IN_PROGRESS',
  /** ACL was successfully updated */

  Ok = 'OK',
}

/** select columns of table "acl_update_status" */
export enum Acl_Update_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "acl_update_status" */
export enum Acl_Update_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "acl_update" */
export enum Acl_Update_Update_Column {
  /** column name */

  Acl = 'acl',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Message = 'message',
  /** column name */

  RequestCount = 'request_count',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "agency_agreement" */
export enum Agency_Agreement_Constraint {
  /** unique or primary key constraint on columns "id" */

  AgencyAgreementPkey = 'agency_agreement_pkey',
}

/** select columns of table "agency_agreement" */
export enum Agency_Agreement_Select_Column {
  /** column name */

  AgencyAbnAcn = 'agency_abn_acn',
  /** column name */

  AgencyAddressCitySuburb = 'agency_address_city_suburb',
  /** column name */

  AgencyAddressCountry = 'agency_address_country',
  /** column name */

  AgencyAddressLine_1 = 'agency_address_line_1',
  /** column name */

  AgencyAddressPostcode = 'agency_address_postcode',
  /** column name */

  AgencyAddressState = 'agency_address_state',
  /** column name */

  AgencyId = 'agency_id',
  /** column name */

  AgencyName = 'agency_name',
  /** column name */

  AgencyRegisteredForGst = 'agency_registered_for_gst',
  /** column name */

  AgencyTradingName = 'agency_trading_name',
  /** column name */

  AgencyUserId = 'agency_user_id',
  /** column name */

  CommissionRate = 'commission_rate',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DevUserId = 'dev_user_id',
  /** column name */

  EnvelopeOwner = 'envelope_owner',
  /** column name */

  Id = 'id',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  LicenseeAddressCitySuburb = 'licensee_address_city_suburb',
  /** column name */

  LicenseeAddressCountry = 'licensee_address_country',
  /** column name */

  LicenseeAddressLine_1 = 'licensee_address_line_1',
  /** column name */

  LicenseeAddressPostcode = 'licensee_address_postcode',
  /** column name */

  LicenseeAddressState = 'licensee_address_state',
  /** column name */

  LicenseeEmail = 'licensee_email',
  /** column name */

  LicenseeName = 'licensee_name',
  /** column name */

  Payload = 'payload',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "agency_agreement_aggregate_bool_exp_bool_and_arguments_columns" columns of table "agency_agreement" */
export enum Agency_Agreement_Select_Column_Agency_Agreement_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  AgencyRegisteredForGst = 'agency_registered_for_gst',
}

/** select "agency_agreement_aggregate_bool_exp_bool_or_arguments_columns" columns of table "agency_agreement" */
export enum Agency_Agreement_Select_Column_Agency_Agreement_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  AgencyRegisteredForGst = 'agency_registered_for_gst',
}

/** update columns of table "agency_agreement" */
export enum Agency_Agreement_Update_Column {
  /** column name */

  AgencyAbnAcn = 'agency_abn_acn',
  /** column name */

  AgencyAddressCitySuburb = 'agency_address_city_suburb',
  /** column name */

  AgencyAddressCountry = 'agency_address_country',
  /** column name */

  AgencyAddressLine_1 = 'agency_address_line_1',
  /** column name */

  AgencyAddressPostcode = 'agency_address_postcode',
  /** column name */

  AgencyAddressState = 'agency_address_state',
  /** column name */

  AgencyId = 'agency_id',
  /** column name */

  AgencyName = 'agency_name',
  /** column name */

  AgencyRegisteredForGst = 'agency_registered_for_gst',
  /** column name */

  AgencyTradingName = 'agency_trading_name',
  /** column name */

  AgencyUserId = 'agency_user_id',
  /** column name */

  CommissionRate = 'commission_rate',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DevUserId = 'dev_user_id',
  /** column name */

  EnvelopeOwner = 'envelope_owner',
  /** column name */

  Id = 'id',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  LicenseeAddressCitySuburb = 'licensee_address_city_suburb',
  /** column name */

  LicenseeAddressCountry = 'licensee_address_country',
  /** column name */

  LicenseeAddressLine_1 = 'licensee_address_line_1',
  /** column name */

  LicenseeAddressPostcode = 'licensee_address_postcode',
  /** column name */

  LicenseeAddressState = 'licensee_address_state',
  /** column name */

  LicenseeEmail = 'licensee_email',
  /** column name */

  LicenseeName = 'licensee_name',
  /** column name */

  Payload = 'payload',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "agreement" */
export enum Agreement_Constraint {
  /** unique or primary key constraint on columns "id" */

  AgreementPkey = 'agreement_pkey',
}

/** select columns of table "agreement" */
export enum Agreement_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  ExchangePayableAmount = 'exchange_payable_amount',
  /** column name */

  ExchangePayablePercentage = 'exchange_payable_percentage',
  /** column name */

  Id = 'id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  SettlementPayableAmount = 'settlement_payable_amount',
  /** column name */

  SettlementPayablePercentage = 'settlement_payable_percentage',
  /** column name */

  StartDate = 'start_date',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "agreement" */
export enum Agreement_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  ExchangePayableAmount = 'exchange_payable_amount',
  /** column name */

  ExchangePayablePercentage = 'exchange_payable_percentage',
  /** column name */

  Id = 'id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  SettlementPayableAmount = 'settlement_payable_amount',
  /** column name */

  SettlementPayablePercentage = 'settlement_payable_percentage',
  /** column name */

  StartDate = 'start_date',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "allocation" */
export enum Allocation_Constraint {
  /** unique or primary key constraint on columns "listing_id", "organisation_id" */

  AllocationOrganisationIdListingIdKey = 'allocation_organisation_id_listing_id_key',
  /** unique or primary key constraint on columns "id" */

  AllocationPkey = 'allocation_pkey',
  /** unique or primary key constraint on columns "project_id", "organisation_id" */

  AllocationProjectIdOrganisationIdKey = 'allocation_project_id_organisation_id_key',
  /** unique or primary key constraint on columns "property_group_id", "organisation_id" */

  AllocationPropertyGroupIdOrganisationIdKey = 'allocation_property_group_id_organisation_id_key',
  /** unique or primary key constraint on columns "sf_id" */

  AllocationSfIdKey = 'allocation_sf_id_key',
}

/** unique or primary key constraints on table "allocation_log" */
export enum Allocation_Log_Constraint {
  /** unique or primary key constraint on columns "id" */

  AllocationLogPkey = 'allocation_log_pkey',
  /** unique or primary key constraint on columns "org_id", "user_id", "listing_id" */

  AllocationLogUserIdListingIdOrgIdKey = 'allocation_log_user_id_listing_id_org_id_key',
}

/** select columns of table "allocation_log" */
export enum Allocation_Log_Select_Column {
  /** column name */

  Action = 'action',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "allocation_log" */
export enum Allocation_Log_Update_Column {
  /** column name */

  Action = 'action',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "allocation" */
export enum Allocation_Select_Column {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  AgreementId = 'agreement_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  CreatedByTeam = 'created_by_team',
  /** column name */

  Id = 'id',
  /** column name */

  IncludeListings = 'include_listings',
  /** column name */

  InheritedFrom = 'inherited_from',
  /** column name */

  InvitationId = 'invitation_id',
  /** column name */

  IsFrontendUpdated = 'is_frontend_updated',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  Notes = 'notes',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SkipUpSync = 'skip_up_sync',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "allocation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "allocation" */
export enum Allocation_Select_Column_Allocation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  IncludeListings = 'include_listings',
  /** column name */

  IsFrontendUpdated = 'is_frontend_updated',
  /** column name */

  SkipUpSync = 'skip_up_sync',
}

/** select "allocation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "allocation" */
export enum Allocation_Select_Column_Allocation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  IncludeListings = 'include_listings',
  /** column name */

  IsFrontendUpdated = 'is_frontend_updated',
  /** column name */

  SkipUpSync = 'skip_up_sync',
}

/** unique or primary key constraints on table "allocation_type" */
export enum Allocation_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  AllocationTypePkey = 'allocation_type_pkey',
}

export enum Allocation_Type_Enum {
  /** Negative Allocation */

  Negative = 'NEGATIVE',
  /** Positive Allocation */

  Positive = 'POSITIVE',
}

/** select columns of table "allocation_type" */
export enum Allocation_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "allocation_type" */
export enum Allocation_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "allocation" */
export enum Allocation_Update_Column {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  AgreementId = 'agreement_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  CreatedByTeam = 'created_by_team',
  /** column name */

  Id = 'id',
  /** column name */

  IncludeListings = 'include_listings',
  /** column name */

  InheritedFrom = 'inherited_from',
  /** column name */

  InvitationId = 'invitation_id',
  /** column name */

  IsFrontendUpdated = 'is_frontend_updated',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  Notes = 'notes',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SkipUpSync = 'skip_up_sync',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "apartment_plan_building" */
export enum Apartment_Plan_Building_Constraint {
  /** unique or primary key constraint on columns "building_id" */

  ApartmentPlanBuildingBuildingIdKey = 'apartment_plan_building_building_id_key',
  /** unique or primary key constraint on columns "id" */

  ApartmentPlanBuildingPkey = 'apartment_plan_building_pkey',
}

/** select columns of table "apartment_plan_building" */
export enum Apartment_Plan_Building_Select_Column {
  /** column name */

  BuildingId = 'building_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  PlanObject = 'plan_object',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "apartment_plan_building" */
export enum Apartment_Plan_Building_Update_Column {
  /** column name */

  BuildingId = 'building_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  PlanObject = 'plan_object',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "apartment_plan_property" */
export enum Apartment_Plan_Property_Constraint {
  /** unique or primary key constraint on columns "apartment_id" */

  ApartmentPlanPropertyApartmentIdKey = 'apartment_plan_property_apartment_id_key',
  /** unique or primary key constraint on columns "id" */

  ApartmentPlanPropertyPkey = 'apartment_plan_property_pkey',
}

/** select columns of table "apartment_plan_property" */
export enum Apartment_Plan_Property_Select_Column {
  /** column name */

  ApartmentId = 'apartment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  PlanObject = 'plan_object',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "apartment_plan_property" */
export enum Apartment_Plan_Property_Update_Column {
  /** column name */

  ApartmentId = 'apartment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  PlanObject = 'plan_object',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "area_plot" */
export enum Area_Plot_Constraint {
  /** unique or primary key constraint on columns "id" */

  AreaPlotPkey = 'area_plot_pkey',
}

/** select columns of table "area_plot" */
export enum Area_Plot_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  PlotPolygon = 'plot_polygon',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TextStyling = 'text_styling',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "area_plot_type" */
export enum Area_Plot_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  AreaPlotTypePkey = 'area_plot_type_pkey',
}

export enum Area_Plot_Type_Enum {
  /** A property unit */

  Property = 'PROPERTY',
  /** A stage property group */

  Stage = 'STAGE',
  /** A sub stage */

  SubStage = 'SUB_STAGE',
}

/** select columns of table "area_plot_type" */
export enum Area_Plot_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "area_plot_type" */
export enum Area_Plot_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "area_plot" */
export enum Area_Plot_Update_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  PlotPolygon = 'plot_polygon',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  TextStyling = 'text_styling',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "attachment_config" */
export enum Attachment_Config_Constraint {
  /** unique or primary key constraint on columns "id" */

  AttachmentConfigPkey = 'attachment_config_pkey',
}

/** select columns of table "attachment_config" */
export enum Attachment_Config_Select_Column {
  /** column name */

  AttachmentThumbnailType = 'attachment_thumbnail_type',
  /** column name */

  AttachmentType = 'attachment_type',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Fit = 'fit',
  /** column name */

  Format = 'format',
  /** column name */

  Height = 'height',
  /** column name */

  Id = 'id',
  /** column name */

  Position = 'position',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Width = 'width',
}

/** update columns of table "attachment_config" */
export enum Attachment_Config_Update_Column {
  /** column name */

  AttachmentThumbnailType = 'attachment_thumbnail_type',
  /** column name */

  AttachmentType = 'attachment_type',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Fit = 'fit',
  /** column name */

  Format = 'format',
  /** column name */

  Height = 'height',
  /** column name */

  Id = 'id',
  /** column name */

  Position = 'position',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Width = 'width',
}

/** unique or primary key constraints on table "attachment" */
export enum Attachment_Constraint {
  /** unique or primary key constraint on columns "id" */

  AttachmentPkey = 'attachment_pkey',
  /** unique or primary key constraint on columns "sf_id" */

  AttachmentSfIdKey = 'attachment_sf_id_key',
}

/** unique or primary key constraints on table "attachment_edit_project_order" */
export enum Attachment_Edit_Project_Order_Constraint {
  /** unique or primary key constraint on columns "id" */

  AttachmentEditProjectOrderPkey = 'attachment_edit_project_order_pkey',
}

/** select columns of table "attachment_edit_project_order" */
export enum Attachment_Edit_Project_Order_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Order = 'order',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "attachment_edit_project_order" */
export enum Attachment_Edit_Project_Order_Update_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Order = 'order',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "attachment_owner" */
export enum Attachment_Owner_Constraint {
  /** unique or primary key constraint on columns "value" */

  AttachmentOwnerPkey = 'attachment_owner_pkey',
}

export enum Attachment_Owner_Enum {
  /** Attachment belongs to deal */

  Deal = 'DEAL',
  /** Attachment belongs to transaction */

  Transaction = 'TRANSACTION',
}

/** select columns of table "attachment_owner" */
export enum Attachment_Owner_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "attachment_owner" */
export enum Attachment_Owner_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "attachment_product_type" */
export enum Attachment_Product_Type_Constraint {
  /** unique or primary key constraint on columns "attachment_id", "product_type" */

  AttachmentProductTypeAttachmentIdProductTypeKey = 'attachment_product_type_attachment_id_product_type_key',
  /** unique or primary key constraint on columns "id" */

  AttachmentProductTypePkey = 'attachment_product_type_pkey',
}

/** select columns of table "attachment_product_type" */
export enum Attachment_Product_Type_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProductType = 'product_type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "attachment_product_type" */
export enum Attachment_Product_Type_Update_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProductType = 'product_type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "attachment" */
export enum Attachment_Select_Column {
  /** column name */

  AddToContract = 'add_to_contract',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  ContentType = 'content_type',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  DownloadUrl = 'download_url',
  /** column name */

  Id = 'id',
  /** column name */

  IsThumbnailGenerate = 'is_thumbnail_generate',
  /** column name */

  Level = 'level',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  Name = 'name',
  /** column name */

  OwnerId = 'owner_id',
  /** column name */

  OwnerType = 'owner_type',
  /** column name */

  Path = 'path',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  RequireSign = 'require_sign',
  /** column name */

  SfDownSyncTime = 'sf_down_sync_time',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UploadedBy = 'uploaded_by',
  /** column name */

  UploadedByTeamId = 'uploaded_by_team_id',
}

/** select "attachment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "attachment" */
export enum Attachment_Select_Column_Attachment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  AddToContract = 'add_to_contract',
  /** column name */

  IsThumbnailGenerate = 'is_thumbnail_generate',
  /** column name */

  RequireSign = 'require_sign',
}

/** select "attachment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "attachment" */
export enum Attachment_Select_Column_Attachment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  AddToContract = 'add_to_contract',
  /** column name */

  IsThumbnailGenerate = 'is_thumbnail_generate',
  /** column name */

  RequireSign = 'require_sign',
}

/** unique or primary key constraints on table "attachment_stage_order" */
export enum Attachment_Stage_Order_Constraint {
  /** unique or primary key constraint on columns "id" */

  AttachmentStageOrderPkey = 'attachment_stage_order_pkey',
}

/** select columns of table "attachment_stage_order" */
export enum Attachment_Stage_Order_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Order = 'order',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "attachment_stage_order" */
export enum Attachment_Stage_Order_Update_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Order = 'order',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "attachment_thumbnail" */
export enum Attachment_Thumbnail_Constraint {
  /** unique or primary key constraint on columns "id" */

  AttachmentThumbnailPkey = 'attachment_thumbnail_pkey',
}

/** select columns of table "attachment_thumbnail_count" */
export enum Attachment_Thumbnail_Count_Select_Column {
  /** column name */

  Id = 'id',
  /** column name */

  ThumbnailCount = 'thumbnail_count',
}

/** select columns of table "attachment_thumbnail" */
export enum Attachment_Thumbnail_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  AttachmentThumbnailType = 'attachment_thumbnail_type',
  /** column name */

  ContentType = 'content_type',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Fit = 'fit',
  /** column name */

  Height = 'height',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  Path = 'path',
  /** column name */

  Position = 'position',
  /** column name */

  ThumbnailUrl = 'thumbnail_url',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Width = 'width',
}

/** unique or primary key constraints on table "attachment_thumbnail_type" */
export enum Attachment_Thumbnail_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  AttachmentThumbnailTypePkey = 'attachment_thumbnail_type_pkey',
}

export enum Attachment_Thumbnail_Type_Enum {
  /** this type used basically in galley card. ex. see in project detail page */

  GalleryCard = 'GALLERY_CARD',
  /** this type used basically in gallery Viewer in large size in view . ex. see in project detail page */

  GalleryView = 'GALLERY_VIEW',
  /** this type used for slider.  for ex. see in listing detail page */

  ListingPage = 'LISTING_PAGE',
  /** this type used for project card.  for ex. see in project listing page */

  ProjectCard = 'PROJECT_CARD',
  /** project QR codes, pixel density X1 */

  ProjectLogoMd = 'PROJECT_LOGO_MD',
  /** project QR codes, pixel density X2 */

  ProjectLogoMdX2 = 'PROJECT_LOGO_MD_X2',
  /** this type used for project logo small thumbnail */

  ProjectLogoSm = 'PROJECT_LOGO_SM',
  /** poject card, pixel ratio X2 */

  ProjectLogoSmX2 = 'PROJECT_LOGO_SM_X2',
}

/** select columns of table "attachment_thumbnail_type" */
export enum Attachment_Thumbnail_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "attachment_thumbnail_type" */
export enum Attachment_Thumbnail_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "attachment_thumbnail" */
export enum Attachment_Thumbnail_Update_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  AttachmentThumbnailType = 'attachment_thumbnail_type',
  /** column name */

  ContentType = 'content_type',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Fit = 'fit',
  /** column name */

  Height = 'height',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  Path = 'path',
  /** column name */

  Position = 'position',
  /** column name */

  ThumbnailUrl = 'thumbnail_url',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Width = 'width',
}

/** unique or primary key constraints on table "attachment_type" */
export enum Attachment_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  AttachmentTypePkey = 'attachment_type_pkey',
}

export enum Attachment_Type_Enum {
  /** Attachment meant to have polygons drawn on it */

  AreaPlotPlan = 'AREA_PLOT_PLAN',
  /** Document */

  Document = 'DOCUMENT',
  /** favicon image */

  Favicon = 'FAVICON',
  /** Floor plan */

  FloorPlan = 'FLOOR_PLAN',
  /** Id proof */

  IdProof = 'ID_PROOF',
  /** Id verification report */

  IdVerificationReport = 'ID_VERIFICATION_REPORT',
  /** Linen plan */

  LinenPlan = 'LINEN_PLAN',
  /** Media file */

  Media = 'MEDIA',
  /** Property photography */

  Photo = 'PHOTO',
  /** this  type used  in project background */

  ProjectBg = 'PROJECT_BG',
  /** this type used for project logo */

  ProjectLogo = 'PROJECT_LOGO',
  /** Usually a PDF document generated on the Sales Contract page by Dev Admin or Head/Master Agency Admin */

  SalesAdvice = 'SALES_ADVICE',
  /** Team logo image */

  TeamLogo = 'TEAM_LOGO',
  /** Transfer receipt */

  TransferReceipt = 'TRANSFER_RECEIPT',
}

/** select columns of table "attachment_type" */
export enum Attachment_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "attachment_type" */
export enum Attachment_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "attachment" */
export enum Attachment_Update_Column {
  /** column name */

  AddToContract = 'add_to_contract',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  ContentType = 'content_type',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  DownloadUrl = 'download_url',
  /** column name */

  Id = 'id',
  /** column name */

  IsThumbnailGenerate = 'is_thumbnail_generate',
  /** column name */

  Level = 'level',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  Name = 'name',
  /** column name */

  OwnerId = 'owner_id',
  /** column name */

  OwnerType = 'owner_type',
  /** column name */

  Path = 'path',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  RequireSign = 'require_sign',
  /** column name */

  SfDownSyncTime = 'sf_down_sync_time',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UploadedBy = 'uploaded_by',
  /** column name */

  UploadedByTeamId = 'uploaded_by_team_id',
}

/** unique or primary key constraints on table "buyer_portal" */
export enum Buyer_Portal_Constraint {
  /** unique or primary key constraint on columns "deal_id" */

  BuyerPortalPkey = 'buyer_portal_pkey',
}

/** select columns of table "buyer_portal" */
export enum Buyer_Portal_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  SignUpConfig = 'sign_up_config',
  /** column name */

  StepConfig = 'step_config',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "buyer_portal" */
export enum Buyer_Portal_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  SignUpConfig = 'sign_up_config',
  /** column name */

  StepConfig = 'step_config',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "buyer_project" */
export enum Buyer_Project_Constraint {
  /** unique or primary key constraint on columns "buyer_id", "project_id" */

  BuyerProjectBuyerIdProjectIdKey = 'buyer_project_buyer_id_project_id_key',
  /** unique or primary key constraint on columns "id" */

  BuyerProjectPkey = 'buyer_project_pkey',
}

/** select columns of table "buyer_project" */
export enum Buyer_Project_Select_Column {
  /** column name */

  AgentTeamId = 'agent_team_id',
  /** column name */

  AgentUserId = 'agent_user_id',
  /** column name */

  BuyerId = 'buyer_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "buyer_project" */
export enum Buyer_Project_Update_Column {
  /** column name */

  AgentTeamId = 'agent_team_id',
  /** column name */

  AgentUserId = 'agent_user_id',
  /** column name */

  BuyerId = 'buyer_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "buyer_type" */
export enum Buyer_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  BuyerTypePkey = 'buyer_type_pkey',
}

export enum Buyer_Type_Enum {
  /** Company buyer */

  Company = 'COMPANY',
  /** First Home Buyer */

  FirstHomeBuyer = 'FIRST_HOME_BUYER',
  /** Individual buyer */

  Individual = 'INDIVIDUAL',
  /** Investor */

  Investor = 'INVESTOR',
  /** Owner Occupier */

  OwnerOccupier = 'OWNER_OCCUPIER',
  /** Re Investor */

  ReInvestor = 'RE_INVESTOR',
}

/** select columns of table "buyer_type" */
export enum Buyer_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "buyer_type" */
export enum Buyer_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "cache" */
export enum Cache_Constraint {
  /** unique or primary key constraint on columns "key" */

  CachePkey = 'cache_pkey',
}

/** select columns of table "cache" */
export enum Cache_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  ExpiresAt = 'expires_at',
  /** column name */

  Key = 'key',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** update columns of table "cache" */
export enum Cache_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  ExpiresAt = 'expires_at',
  /** column name */

  Key = 'key',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "change_in" */
export enum Change_In_Constraint {
  /** unique or primary key constraint on columns "id" */

  ChangePkey = 'change_pkey',
}

/** select columns of table "change_in" */
export enum Change_In_Select_Column {
  /** column name */

  Action = 'action',
  /** column name */

  Change = 'change',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DependsOnSfId = 'depends_on_sf_id',
  /** column name */

  ErrorMsg = 'error_msg',
  /** column name */

  Event = 'event',
  /** column name */

  Id = 'id',
  /** column name */

  IsBulkImport = 'is_bulk_import',
  /** column name */

  Notes = 'notes',
  /** column name */

  PayloadTimestamp = 'payload_timestamp',
  /** column name */

  RecordType = 'record_type',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  ShouldBeDeleted = 'should_be_deleted',
  /** column name */

  Source = 'source',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "change_in" */
export enum Change_In_Update_Column {
  /** column name */

  Action = 'action',
  /** column name */

  Change = 'change',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DependsOnSfId = 'depends_on_sf_id',
  /** column name */

  ErrorMsg = 'error_msg',
  /** column name */

  Event = 'event',
  /** column name */

  Id = 'id',
  /** column name */

  IsBulkImport = 'is_bulk_import',
  /** column name */

  Notes = 'notes',
  /** column name */

  PayloadTimestamp = 'payload_timestamp',
  /** column name */

  RecordType = 'record_type',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  ShouldBeDeleted = 'should_be_deleted',
  /** column name */

  Source = 'source',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "change_status" */
export enum Change_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  ChangeStatusPkey = 'change_status_pkey',
}

export enum Change_Status_Enum {
  /** Error syncing data */

  Error = 'error',
  /** Sync is pending */

  Pending = 'pending',
  /** down sync skipped for some reason which can be found in change_in.notes field probably */

  Skip = 'skip',
  /** Sync is ok */

  Synced = 'synced',
}

/** select columns of table "change_status" */
export enum Change_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "change_status" */
export enum Change_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "code" */
export enum Code_Constraint {
  /** unique or primary key constraint on columns "key" */

  CodeKeyKey = 'code_key_key',
  /** unique or primary key constraint on columns "id" */

  CodePkey = 'code_pkey',
}

/** select columns of table "code" */
export enum Code_Select_Column {
  /** column name */

  Code = 'code',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  ExpiresAt = 'expires_at',
  /** column name */

  Id = 'id',
  /** column name */

  IsVerified = 'is_verified',
  /** column name */

  Key = 'key',
  /** column name */

  Request = 'request',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "code" */
export enum Code_Update_Column {
  /** column name */

  Code = 'code',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  ExpiresAt = 'expires_at',
  /** column name */

  Id = 'id',
  /** column name */

  IsVerified = 'is_verified',
  /** column name */

  Key = 'key',
  /** column name */

  Request = 'request',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "commission" */
export enum Commission_Constraint {
  /** unique or primary key constraint on columns "id" */

  CommissionPkey = 'commission_pkey',
  /** unique or primary key constraint on columns "sf_id" */

  CommissionSfIdKey = 'commission_sf_id_key',
}

/** select columns of table "commission" */
export enum Commission_Select_Column {
  /** column name */

  AgencyAgreementId = 'agency_agreement_id',
  /** column name */

  AgentAmount = 'agent_amount',
  /** column name */

  AgentCommissionRateLessSubAgent = 'agent_commission_rate_less_sub_agent',
  /** column name */

  AgentCommissionValueLessSubAgent = 'agent_commission_value_less_sub_agent',
  /** column name */

  AgentExchangeInvoicePaidDate = 'agent_exchange_invoice_paid_date',
  /** column name */

  AgentExchangePayableAmount = 'agent_exchange_payable_amount',
  /** column name */

  AgentExchangePayablePercentage = 'agent_exchange_payable_percentage',
  /** column name */

  AgentIncentiveAmount = 'agent_incentive_amount',
  /** column name */

  AgentIncentivePayable = 'agent_incentive_payable',
  /** column name */

  AgentIncentiveRate = 'agent_incentive_rate',
  /** column name */

  AgentOutstandingBalance = 'agent_outstanding_balance',
  /** column name */

  AgentPaymentNotes = 'agent_payment_notes',
  /** column name */

  AgentRate = 'agent_rate',
  /** column name */

  AgentSettlementInvoicePaidDate = 'agent_settlement_invoice_paid_date',
  /** column name */

  AgentSettlementPayableAmount = 'agent_settlement_payable_amount',
  /** column name */

  AgentSettlementPayablePercentage = 'agent_settlement_payable_percentage',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  ExchangeInvoiceAgent = 'exchange_invoice_agent',
  /** column name */

  ExchangeInvoiceSubAgent = 'exchange_invoice_sub_agent',
  /** column name */

  ExternalCommissionId = 'external_commission_id',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  PropertyName = 'property_name',
  /** column name */

  SettlementInvoiceAgent = 'settlement_invoice_agent',
  /** column name */

  SettlementInvoiceSubAgent = 'settlement_invoice_sub_agent',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SubAgencyAgreementId = 'sub_agency_agreement_id',
  /** column name */

  SubAgentAmount = 'sub_agent_amount',
  /** column name */

  SubAgentExchangeInvoicePaidDate = 'sub_agent_exchange_invoice_paid_date',
  /** column name */

  SubAgentExchangePayableAmount = 'sub_agent_exchange_payable_amount',
  /** column name */

  SubAgentExchangePayablePercentage = 'sub_agent_exchange_payable_percentage',
  /** column name */

  SubAgentIncentiveAmount = 'sub_agent_incentive_amount',
  /** column name */

  SubAgentIncentiveRate = 'sub_agent_incentive_rate',
  /** column name */

  SubAgentOutstandingBalance = 'sub_agent_outstanding_balance',
  /** column name */

  SubAgentPaymentNotes = 'sub_agent_payment_notes',
  /** column name */

  SubAgentRate = 'sub_agent_rate',
  /** column name */

  SubAgentSettlementInvoicePaidDate = 'sub_agent_settlement_invoice_paid_date',
  /** column name */

  SubAgentSettlementPayableAmount = 'sub_agent_settlement_payable_amount',
  /** column name */

  SubAgentSettlementPayablePercentage = 'sub_agent_settlement_payable_percentage',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "commission_type" */
export enum Commission_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  CommissionTypePkey = 'commission_type_pkey',
}

/** select columns of table "commission_type" */
export enum Commission_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "commission_type" */
export enum Commission_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "commission" */
export enum Commission_Update_Column {
  /** column name */

  AgencyAgreementId = 'agency_agreement_id',
  /** column name */

  AgentAmount = 'agent_amount',
  /** column name */

  AgentCommissionRateLessSubAgent = 'agent_commission_rate_less_sub_agent',
  /** column name */

  AgentCommissionValueLessSubAgent = 'agent_commission_value_less_sub_agent',
  /** column name */

  AgentExchangeInvoicePaidDate = 'agent_exchange_invoice_paid_date',
  /** column name */

  AgentExchangePayableAmount = 'agent_exchange_payable_amount',
  /** column name */

  AgentExchangePayablePercentage = 'agent_exchange_payable_percentage',
  /** column name */

  AgentIncentiveAmount = 'agent_incentive_amount',
  /** column name */

  AgentIncentivePayable = 'agent_incentive_payable',
  /** column name */

  AgentIncentiveRate = 'agent_incentive_rate',
  /** column name */

  AgentOutstandingBalance = 'agent_outstanding_balance',
  /** column name */

  AgentPaymentNotes = 'agent_payment_notes',
  /** column name */

  AgentRate = 'agent_rate',
  /** column name */

  AgentSettlementInvoicePaidDate = 'agent_settlement_invoice_paid_date',
  /** column name */

  AgentSettlementPayableAmount = 'agent_settlement_payable_amount',
  /** column name */

  AgentSettlementPayablePercentage = 'agent_settlement_payable_percentage',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  ExchangeInvoiceAgent = 'exchange_invoice_agent',
  /** column name */

  ExchangeInvoiceSubAgent = 'exchange_invoice_sub_agent',
  /** column name */

  ExternalCommissionId = 'external_commission_id',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  PropertyName = 'property_name',
  /** column name */

  SettlementInvoiceAgent = 'settlement_invoice_agent',
  /** column name */

  SettlementInvoiceSubAgent = 'settlement_invoice_sub_agent',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SubAgencyAgreementId = 'sub_agency_agreement_id',
  /** column name */

  SubAgentAmount = 'sub_agent_amount',
  /** column name */

  SubAgentExchangeInvoicePaidDate = 'sub_agent_exchange_invoice_paid_date',
  /** column name */

  SubAgentExchangePayableAmount = 'sub_agent_exchange_payable_amount',
  /** column name */

  SubAgentExchangePayablePercentage = 'sub_agent_exchange_payable_percentage',
  /** column name */

  SubAgentIncentiveAmount = 'sub_agent_incentive_amount',
  /** column name */

  SubAgentIncentiveRate = 'sub_agent_incentive_rate',
  /** column name */

  SubAgentOutstandingBalance = 'sub_agent_outstanding_balance',
  /** column name */

  SubAgentPaymentNotes = 'sub_agent_payment_notes',
  /** column name */

  SubAgentRate = 'sub_agent_rate',
  /** column name */

  SubAgentSettlementInvoicePaidDate = 'sub_agent_settlement_invoice_paid_date',
  /** column name */

  SubAgentSettlementPayableAmount = 'sub_agent_settlement_payable_amount',
  /** column name */

  SubAgentSettlementPayablePercentage = 'sub_agent_settlement_payable_percentage',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "contact" */
export enum Contact_Constraint {
  /** unique or primary key constraint on columns "id" */

  LeadPkey = 'lead_pkey',
}

/** select columns of table "contact" */
export enum Contact_Select_Column {
  /** column name */

  AddressCitySuburb = 'address_city_suburb',
  /** column name */

  AddressLine_1 = 'address_line_1',
  /** column name */

  AddressPostcode = 'address_postcode',
  /** column name */

  AddressState = 'address_state',
  /** column name */

  Country = 'country',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByContactId = 'created_by_contact_id',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Email = 'email',
  /** column name */

  FaxNumber = 'fax_number',
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  FirstName = 'first_name',
  /** column name */

  Id = 'id',
  /** column name */

  IsPrimary = 'is_primary',
  /** column name */

  LastName = 'last_name',
  /** column name */

  MiddleName = 'middle_name',
  /** column name */

  Nationality = 'nationality',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  PhoneNumber = 'phone_number',
  /** column name */

  SfDownSyncTime = 'sf_down_sync_time',
  /** column name */

  TaxFileNumber = 'tax_file_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** select "contact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "contact" */
export enum Contact_Select_Column_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  IsPrimary = 'is_primary',
}

/** select "contact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "contact" */
export enum Contact_Select_Column_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  IsPrimary = 'is_primary',
}

/** unique or primary key constraints on table "contact_type" */
export enum Contact_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  ContactTypePkey = 'contact_type_pkey',
}

export enum Contact_Type_Enum {
  /** Contact is an agent */

  Agent = 'AGENT',
  /** Contact is a client who wants to deal for a property */

  Client = 'CLIENT',
  /** Contact is a developer */

  Developer = 'DEVELOPER',
  /** Other */

  Other = 'OTHER',
  /** Contact is a solicitor who can e a part of deal but can not purchase it */

  Solicitor = 'SOLICITOR',
  /** "Valuation / Bank" contact type from Salesforce */

  ValuationBank = 'VALUATION_BANK',
  /** Vendor */

  Vendor = 'VENDOR',
}

/** select columns of table "contact_type" */
export enum Contact_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "contact_type" */
export enum Contact_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "contact" */
export enum Contact_Update_Column {
  /** column name */

  AddressCitySuburb = 'address_city_suburb',
  /** column name */

  AddressLine_1 = 'address_line_1',
  /** column name */

  AddressPostcode = 'address_postcode',
  /** column name */

  AddressState = 'address_state',
  /** column name */

  Country = 'country',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByContactId = 'created_by_contact_id',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Email = 'email',
  /** column name */

  FaxNumber = 'fax_number',
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  FirstName = 'first_name',
  /** column name */

  Id = 'id',
  /** column name */

  IsPrimary = 'is_primary',
  /** column name */

  LastName = 'last_name',
  /** column name */

  MiddleName = 'middle_name',
  /** column name */

  Nationality = 'nationality',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  PhoneNumber = 'phone_number',
  /** column name */

  SfDownSyncTime = 'sf_down_sync_time',
  /** column name */

  TaxFileNumber = 'tax_file_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "count_with_rolling_avg" */
export enum Count_With_Rolling_Avg_Constraint {
  /** unique or primary key constraint on columns "date_start" */

  CountWithRollingAvgPkey = 'count_with_rolling_avg_pkey',
}

/** select columns of table "count_with_rolling_avg" */
export enum Count_With_Rolling_Avg_Select_Column {
  /** column name */

  Count = 'count',
  /** column name */

  DateStart = 'date_start',
  /** column name */

  RollingAvg = 'rolling_avg',
}

/** update columns of table "count_with_rolling_avg" */
export enum Count_With_Rolling_Avg_Update_Column {
  /** column name */

  Count = 'count',
  /** column name */

  DateStart = 'date_start',
  /** column name */

  RollingAvg = 'rolling_avg',
}

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "value" */

  CountryPkey = 'country_pkey',
}

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */

  Value = 'value',
}

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "crm_connection" */
export enum Crm_Connection_Constraint {
  /** unique or primary key constraint on columns "sf_instance_id" */

  CrmConnectionPkey = 'crm_connection_pkey',
}

/** select columns of table "crm_connection" */
export enum Crm_Connection_Select_Column {
  /** column name */

  AuthToken = 'auth_token',
  /** column name */

  ConsumerKey = 'consumer_key',
  /** column name */

  ConsumerSecret = 'consumer_secret',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DefaultAdminUser = 'default_admin_user',
  /** column name */

  Env = 'env',
  /** column name */

  HerokuConnectSecret = 'heroku_connect_secret',
  /** column name */

  HerokuConnectUrl = 'heroku_connect_url',
  /** column name */

  HookToken = 'hook_token',
  /** column name */

  Name = 'name',
  /** column name */

  OwnerTeamId = 'owner_team_id',
  /** column name */

  RefreshToken = 'refresh_token',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  SfRestApiUri = 'sf_rest_api_uri',
  /** column name */

  TokenIssuedAt = 'token_issued_at',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "crm_connection" */
export enum Crm_Connection_Update_Column {
  /** column name */

  AuthToken = 'auth_token',
  /** column name */

  ConsumerKey = 'consumer_key',
  /** column name */

  ConsumerSecret = 'consumer_secret',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DefaultAdminUser = 'default_admin_user',
  /** column name */

  Env = 'env',
  /** column name */

  HerokuConnectSecret = 'heroku_connect_secret',
  /** column name */

  HerokuConnectUrl = 'heroku_connect_url',
  /** column name */

  HookToken = 'hook_token',
  /** column name */

  Name = 'name',
  /** column name */

  OwnerTeamId = 'owner_team_id',
  /** column name */

  RefreshToken = 'refresh_token',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  SfRestApiUri = 'sf_rest_api_uri',
  /** column name */

  TokenIssuedAt = 'token_issued_at',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */

  Asc = 'ASC',
  /** descending ordering of the cursor */

  Desc = 'DESC',
}

/** unique or primary key constraints on table "deal" */
export enum Deal_Constraint {
  /** unique or primary key constraint on columns "id" */

  ContractPkey = 'contract_pkey',
  /** unique or primary key constraint on columns "bpay_crn" */

  DealBpayCrnKey = 'deal_bpay_crn_key',
  /** unique or primary key constraint on columns "sf_enquiry_id" */

  DealSfEnquiryIdKey = 'deal_sf_enquiry_id_key',
  /** unique or primary key constraint on columns "sf_legal_id" */

  DealSfLegalIdKey = 'deal_sf_legal_id_key',
}

/** unique or primary key constraints on table "deal_contact" */
export enum Deal_Contact_Constraint {
  /** unique or primary key constraint on columns "contact_id", "deal_id" */

  DealContactDealIdContactIdKey = 'deal_contact_deal_id_contact_id_key',
  /** unique or primary key constraint on columns "id" */

  DealContactPkey = 'deal_contact_pkey',
}

/** select columns of table "deal_contact" */
export enum Deal_Contact_Select_Column {
  /** column name */

  BuyerType = 'buyer_type',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsExisting = 'is_existing',
}

/** select "deal_contact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deal_contact" */
export enum Deal_Contact_Select_Column_Deal_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsExisting = 'is_existing',
}

/** select "deal_contact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deal_contact" */
export enum Deal_Contact_Select_Column_Deal_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsExisting = 'is_existing',
}

/** unique or primary key constraints on table "deal_contact_status" */
export enum Deal_Contact_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealContactStatusPkey = 'deal_contact_status_pkey',
}

export enum Deal_Contact_Status_Enum {
  /** Attempted Call */

  AttemptedCall = 'ATTEMPTED_CALL',
  /** Bought elsewhere */

  BoughtElsewhere = 'BOUGHT_ELSEWHERE',
  /** Duplicate */

  Duplicate = 'DUPLICATE',
  /** Follow up */

  FollowUp = 'FOLLOW_UP',
  /** Inspection booked */

  InspectionBooked = 'INSPECTION_BOOKED',
  /** Jewel Cocktail */

  JewelCocktail = 'JEWEL_COCKTAIL',
  /** Left a message */

  LeftAMessage = 'LEFT_A_MESSAGE',
  /** New inquiry, not yet contacted */

  NewInquiryNotYetContacted = 'NEW_INQUIRY_NOT_YET_CONTACTED',
  /** Not longer interested */

  NotLongerInterested = 'NOT_LONGER_INTERESTED',
  /** Out of price range */

  OutOfPriceRange = 'OUT_OF_PRICE_RANGE',
  /** Please remove */

  PleaseRemove = 'PLEASE_REMOVE',
  /** Sent e-mail */

  SentEmail = 'SENT_EMAIL',
  /** Spoken to */

  SpokenTo = 'SPOKEN_TO',
  /** Wants complete stock */

  WantsCompleteStock = 'WANTS_COMPLETE_STOCK',
  /** Wrong number */

  WrongNumber = 'WRONG_NUMBER',
}

/** select columns of table "deal_contact_status" */
export enum Deal_Contact_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_contact_status" */
export enum Deal_Contact_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_contact" */
export enum Deal_Contact_Update_Column {
  /** column name */

  BuyerType = 'buyer_type',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsExisting = 'is_existing',
}

/** unique or primary key constraints on table "deal_deposit_status" */
export enum Deal_Deposit_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealDepositStatusPkey = 'deal_deposit_status_pkey',
}

export enum Deal_Deposit_Status_Enum {
  /** Approved */

  Approved = 'APPROVED',
  /** Invalid Info */

  InvalidInfo = 'INVALID_INFO',
  /** In Process */

  InProcess = 'IN_PROCESS',
  /** Missing Info */

  MissingInfo = 'MISSING_INFO',
  /** Pending */

  Pending = 'PENDING',
  /** Reassigned */

  Reassigned = 'REASSIGNED',
  /** Received */

  Received = 'RECEIVED',
  /** Recheck */

  Recheck = 'RECHECK',
  /** Refunded */

  Refunded = 'REFUNDED',
  /** Refund Pending */

  RefundPending = 'REFUND_PENDING',
  /** Sent to VSOL */

  SentToVsol = 'SENT_TO_VSOL',
}

/** select columns of table "deal_deposit_status" */
export enum Deal_Deposit_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_deposit_status" */
export enum Deal_Deposit_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_deposit_type" */
export enum Deal_Deposit_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealDepositTypePkey = 'deal_deposit_type_pkey',
}

export enum Deal_Deposit_Type_Enum {
  /** Bank Guarantee */

  BankGuarantee = 'BANK_GUARANTEE',
  /** Cash */

  Cash = 'CASH',
  /** Deposit Bond */

  DepositBond = 'DEPOSIT_BOND',
}

/** select columns of table "deal_deposit_type" */
export enum Deal_Deposit_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_deposit_type" */
export enum Deal_Deposit_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_enquiry_source" */
export enum Deal_Enquiry_Source_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealEnquirySourcePkey = 'deal_enquiry_source_pkey',
}

export enum Deal_Enquiry_Source_Enum {
  /** Australia Chinese Daily */

  AustraliaChineseDaily = 'AUSTRALIA_CHINESE_DAILY',
  /** Central Courier */

  CentralCourier = 'CENTRAL_COURIER',
  /** Chinese Herald Property Weekly */

  ChineseHeraldPropertyWeekly = 'CHINESE_HERALD_PROPERTY_WEEKLY',
  /** Cocktail Launch */

  CocktailLaunch = 'COCKTAIL_LAUNCH',
  /** Connect Portal */

  ConnectPortal = 'CONNECT_PORTAL',
  /** Display Suite */

  DisplaySuite = 'DISPLAY_SUITE',
  /** EDM */

  Edm = 'EDM',
  /** Fortune Weekly */

  FortuneWeekly = 'FORTUNE_WEEKLY',
  /** Inner West Courier */

  InnerWestCourier = 'INNER_WEST_COURIER',
  /** Leaflet */

  Leaflet = 'LEAFLET',
  /** Leaflet_Letter drop */

  LeafletLetterDrop = 'LEAFLET_LETTER_DROP',
  /** Local_Community Publication */

  LocalCommunityPublication = 'LOCAL_COMMUNITY_PUBLICATION',
  /** New Express Daily */

  NewExpressDaily = 'NEW_EXPRESS_DAILY',
  /** Online search */

  OnlineSearch = 'ONLINE_SEARCH',
  /** Other */

  Other = 'OTHER',
  /** Phone */

  Phone = 'PHONE',
  /** Signage */

  Signage = 'SIGNAGE',
  /** Sing Tao */

  SingTao = 'SING_TAO',
  /** Torn Marketing */

  TornMarketing = 'TORN_MARKETING',
  /** urban.com.au */

  Urban = 'URBAN',
  /** Word of mouth */

  WordOfMouth = 'WORD_OF_MOUTH',
}

/** select columns of table "deal_enquiry_source" */
export enum Deal_Enquiry_Source_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_enquiry_source" */
export enum Deal_Enquiry_Source_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_fallen_reason" */
export enum Deal_Fallen_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealFallenReasonPkey = 'deal_fallen_reason_pkey',
}

export enum Deal_Fallen_Reason_Enum {
  /** Dodgy purchaser / agent */

  DodgyPurchaserAgent = 'DODGY_PURCHASER_AGENT',
  /** Name Change */

  NameChange = 'NAME_CHANGE',
  /** Other */

  Other = 'OTHER',
  /** Pricing */

  Pricing = 'PRICING',
  /** Purchased in other development */

  PurchasedInOtherDevelopment = 'PURCHASED_IN_OTHER_DEVELOPMENT',
  /** Rescission */

  Rescission = 'RESCISSION',
  /** Swap to another unit */

  SwapToAnotherUnit = 'SWAP_TO_ANOTHER_UNIT',
  /** Termination */

  Termination = 'TERMINATION',
  /** Timing too tight */

  TimingTooTight = 'TIMING_TOO_TIGHT',
  /** Unable to secure finances */

  UnableToSecureFinance = 'UNABLE_TO_SECURE_FINANCE',
  /** Unacceptable delay from date of Issue */

  UnacceptableDelayFromDateOfIssue = 'UNACCEPTABLE_DELAY_FROM_DATE_OF_ISSUE',
}

/** select columns of table "deal_fallen_reason" */
export enum Deal_Fallen_Reason_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_fallen_reason" */
export enum Deal_Fallen_Reason_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_payment_method" */
export enum Deal_Payment_Method_Constraint {
  /** unique or primary key constraint on columns "value" */

  PaymentMethodPkey = 'payment_method_pkey',
}

export enum Deal_Payment_Method_Enum {
  /** BPAY to VENDOR */

  BpayToVendor = 'BPAY_TO_VENDOR',
  /** Card to VENDOR */

  CardToVendor = 'CARD_TO_VENDOR',
  /** Cash to VENDOR */

  CashToVendor = 'CASH_TO_VENDOR',
  /** Cash to VSOL */

  CashToVsol = 'CASH_TO_VSOL',
  /** Cheque to VENDOR */

  ChequeToVendor = 'CHEQUE_TO_VENDOR',
  /** Cheque to VSOL */

  ChequeToVsol = 'CHEQUE_TO_VSOL',
  /** EFT to VENDOR */

  EftToVendor = 'EFT_TO_VENDOR',
  /** EFT to VSOL */

  EftToVsol = 'EFT_TO_VSOL',
}

/** select columns of table "deal_payment_method" */
export enum Deal_Payment_Method_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_payment_method" */
export enum Deal_Payment_Method_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_progress_update" */
export enum Deal_Progress_Update_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealProgressUpdatePkey = 'deal_progress_update_pkey',
}

export enum Deal_Progress_Update_Enum {
  /** Buyer Unsure */

  BuyerUnsure = 'BUYER_UNSURE',
  /** Client Away */

  ClientAway = 'CLIENT_AWAY',
  /** Client Unresponsive */

  ClientUnresponsive = 'CLIENT_UNRESPONSIVE',
  /** Exchanging in less than 48 hours */

  ExchangingInLessThan_48Hours = 'EXCHANGING_IN_LESS_THAN_48_HOURS',
  /** Exchanging in less than 7 days */

  ExchangingInLessThan_7Days = 'EXCHANGING_IN_LESS_THAN_7_DAYS',
  /** Negotiating */

  Negotiating = 'NEGOTIATING',
  /** Signed Contract in Transit */

  SignedContractInTransit = 'SIGNED_CONTRACT_IN_TRANSIT',
}

/** select columns of table "deal_progress_update" */
export enum Deal_Progress_Update_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_progress_update" */
export enum Deal_Progress_Update_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_reason_purchase" */
export enum Deal_Reason_Purchase_Constraint {
  /** unique or primary key constraint on columns "value" */

  ReasonToPurchasePkey = 'reason_to_purchase_pkey',
}

/** select columns of table "deal_reason_purchase" */
export enum Deal_Reason_Purchase_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_reason_purchase" */
export enum Deal_Reason_Purchase_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** select columns of table "deal" */
export enum Deal_Select_Column {
  /** column name */

  AccountManagerId = 'account_manager_id',
  /** column name */

  AdvisedContractReadyToProgress = 'advised_contract_ready_to_progress',
  /** column name */

  AgencyContactId = 'agency_contact_id',
  /** column name */

  AgencyId = 'agency_id',
  /** column name */

  AwaitingEnquirySfId = 'awaiting_enquiry_sf_id',
  /** column name */

  AwaitingLegalSfId = 'awaiting_legal_sf_id',
  /** column name */

  BalanceDueBy = 'balance_due_by',
  /** column name */

  BalanceOfDeposit = 'balance_of_deposit',
  /** column name */

  BankGuaranteeExpires = 'bank_guarantee_expires',
  /** column name */

  Bathrooms = 'bathrooms',
  /** column name */

  Bedrooms = 'bedrooms',
  /** column name */

  BedroomsPbMax = 'bedrooms_pb_max',
  /** column name */

  BedroomsPbMin = 'bedrooms_pb_min',
  /** column name */

  BedroomsRequested = 'bedrooms_requested',
  /** column name */

  BondExpires = 'bond_expires',
  /** column name */

  BpayBillerCode = 'bpay_biller_code',
  /** column name */

  BpayCrn = 'bpay_crn',
  /** column name */

  BuildingId = 'building_id',
  /** column name */

  BuyingCapacity = 'buying_capacity',
  /** column name */

  Cars = 'cars',
  /** column name */

  ChangesRequested = 'changes_requested',
  /** column name */

  ColourScheme = 'colour_scheme',
  /** column name */

  Comment = 'comment',
  /** column name */

  CommentForBuyer = 'comment_for_buyer',
  /** column name */

  CommissionSplit = 'commission_split',
  /** column name */

  ConfirmedFirbStatus = 'confirmed_firb_status',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  ContactStatus = 'contact_status',
  /** column name */

  ContractConditional = 'contract_conditional',
  /** column name */

  ContractDepositAmount = 'contract_deposit_amount',
  /** column name */

  ContractExchanged = 'contract_exchanged',
  /** column name */

  ContractIssued = 'contract_issued',
  /** column name */

  ContractProgressUpdate = 'contract_progress_update',
  /** column name */

  ContractReturnedToVendor = 'contract_returned_to_vendor',
  /** column name */

  ContractSentToPurchaser = 'contract_sent_to_purchaser',
  /** column name */

  ContractUnconditional = 'contract_unconditional',
  /** column name */

  CoolingOffEnds = 'cooling_off_ends',
  /** column name */

  CoolingOffStarts = 'cooling_off_starts',
  /** column name */

  CoolingOffWaived = 'cooling_off_waived',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  DepositDue = 'deposit_due',
  /** column name */

  DepositReceived = 'deposit_received',
  /** column name */

  DepositStatus = 'deposit_status',
  /** column name */

  DepositType = 'deposit_type',
  /** column name */

  EnquirySource = 'enquiry_source',
  /** column name */

  EnquirySourceOther = 'enquiry_source_other',
  /** column name */

  EnvelopeOpenedByBuyer = 'envelope_opened_by_buyer',
  /** column name */

  EnvelopeOwner = 'envelope_owner',
  /** column name */

  EoiApprovedByVendor = 'eoi_approved_by_vendor',
  /** column name */

  FallenDate = 'fallen_date',
  /** column name */

  FallenExplanation = 'fallen_explanation',
  /** column name */

  FallenReason = 'fallen_reason',
  /** column name */

  FinanceStatus = 'finance_status',
  /** column name */

  FirbApprovedVendor = 'firb_approved_vendor',
  /** column name */

  FirbConfirmationReceived = 'firb_confirmation_received',
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  ForecastExchange = 'forecast_exchange',
  /** column name */

  FormOfSettlement = 'form_of_settlement',
  /** column name */

  HoldingDepositAmount = 'holding_deposit_amount',
  /** column name */

  HoldingDepositAmountReceived = 'holding_deposit_amount_received',
  /** column name */

  HoldingDepositConfirmedByVendor = 'holding_deposit_confirmed_by_vendor',
  /** column name */

  HoldingDepositNumber = 'holding_deposit_number',
  /** column name */

  HoldingDepositPaidHandled = 'holding_deposit_paid_handled',
  /** column name */

  HoldingDepositPercentage = 'holding_deposit_percentage',
  /** column name */

  HoldingDepositReceived = 'holding_deposit_received',
  /** column name */

  HoldingDepositReceivedByVsol = 'holding_deposit_received_by_vsol',
  /** column name */

  HowDidYouHearAboutTheProperty = 'how_did_you_hear_about_the_property',
  /** column name */

  Id = 'id',
  /** column name */

  IdReceived = 'id_received',
  /** column name */

  IdentityDoc1Number = 'identity_doc1_number',
  /** column name */

  IdentityDoc1Region = 'identity_doc1_region',
  /** column name */

  IdentityDoc1Type = 'identity_doc1_type',
  /** column name */

  InclInDeposit = 'incl_in_deposit',
  /** column name */

  LastSalesAdviceGeneratedAt = 'last_sales_advice_generated_at',
  /** column name */

  LeadId = 'lead_id',
  /** column name */

  LeadStrength = 'lead_strength',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  Name = 'name',
  /** column name */

  NameOnContract = 'name_on_contract',
  /** column name */

  OnHold = 'on_hold',
  /** column name */

  OnHoldApprovedByClient = 'on_hold_approved_by_client',
  /** column name */

  OnHoldApprovedByVendor = 'on_hold_approved_by_vendor',
  /** column name */

  OnHoldRelease = 'on_hold_release',
  /** column name */

  PaidBy = 'paid_by',
  /** column name */

  ParkingSpaces = 'parking_spaces',
  /** column name */

  PayableOn = 'payable_on',
  /** column name */

  PaymentMethod = 'payment_method',
  /** column name */

  PreSettlementBookedBy = 'pre_settlement_booked_by',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  ProofVerified = 'proof_verified',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  PurchaseProposalSent = 'purchase_proposal_sent',
  /** column name */

  PurchaserSolicitorContactId = 'purchaser_solicitor_contact_id',
  /** column name */

  PurchaserSolicitorId = 'purchaser_solicitor_id',
  /** column name */

  ReasonToPurchase = 'reason_to_purchase',
  /** column name */

  RefundAccountBsb = 'refund_account_bsb',
  /** column name */

  RefundAccountName = 'refund_account_name',
  /** column name */

  RefundAccountNumber = 'refund_account_number',
  /** column name */

  RefundRequested = 'refund_requested',
  /** column name */

  RefundSwiftCode = 'refund_swift_code',
  /** column name */

  RequestReference = 'request_reference',
  /** column name */

  ReservationFinalised = 'reservation_finalised',
  /** column name */

  ReservedOn = 'reserved_on',
  /** column name */

  SaReceived = 'sa_received',
  /** column name */

  SaSentToLegal = 'sa_sent_to_legal',
  /** column name */

  SalePrice = 'sale_price',
  /** column name */

  Settlement = 'settlement',
  /** column name */

  SettlementBooked = 'settlement_booked',
  /** column name */

  SettlementDefectStatus = 'settlement_defect_status',
  /** column name */

  SettlementDueDate = 'settlement_due_date',
  /** column name */

  SettlementExtensionRequestedDate = 'settlement_extension_requested_date',
  /** column name */

  SettlementInspection = 'settlement_inspection',
  /** column name */

  SettlementInspectionCompleted = 'settlement_inspection_completed',
  /** column name */

  SettlementKeysCollectedDate = 'settlement_keys_collected_date',
  /** column name */

  SettlementOutlook = 'settlement_outlook',
  /** column name */

  SettlementStory = 'settlement_story',
  /** column name */

  SettlementSummary = 'settlement_summary',
  /** column name */

  SfDownSyncTime = 'sf_down_sync_time',
  /** column name */

  SfEnquiryId = 'sf_enquiry_id',
  /** column name */

  SfLegalId = 'sf_legal_id',
  /** column name */

  SolicitorNotes = 'solicitor_notes',
  /** column name */

  Source = 'source',
  /** column name */

  Status = 'status',
  /** column name */

  SubAgencyContactId = 'sub_agency_contact_id',
  /** column name */

  SubAgencyId = 'sub_agency_id',
  /** column name */

  Terms = 'terms',
  /** column name */

  TimeFrame = 'time_frame',
  /** column name */

  TotalDepositExtensionDate = 'total_deposit_extension_date',
  /** column name */

  TotalDepositOutstanding = 'total_deposit_outstanding',
  /** column name */

  TotalDepositPercentage = 'total_deposit_percentage',
  /** column name */

  TotalDepositSelection = 'total_deposit_selection',
  /** column name */

  TransferReceiptTime = 'transfer_receipt_time',
  /** column name */

  TransferStatus = 'transfer_status',
  /** column name */

  TransferToVsolRequested = 'transfer_to_vsol_requested',
  /** column name */

  TransferredToVsol = 'transferred_to_vsol',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UploadedId = 'uploaded_id',
  /** column name */

  UploadedIdCount = 'uploaded_id_count',
  /** column name */

  UploadedReceipt = 'uploaded_receipt',
  /** column name */

  VendorSolicitorContactId = 'vendor_solicitor_contact_id',
  /** column name */

  VendorSolicitorId = 'vendor_solicitor_id',
  /** column name */

  X66wCertificateProvided = 'x66w_certificate_provided',
  /** column name */

  X66wReceivedByVsol = 'x66w_received_by_vsol',
}

/** select "deal_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deal" */
export enum Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  AwaitingEnquirySfId = 'awaiting_enquiry_sf_id',
  /** column name */

  AwaitingLegalSfId = 'awaiting_legal_sf_id',
  /** column name */

  ChangesRequested = 'changes_requested',
  /** column name */

  CommissionSplit = 'commission_split',
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  HoldingDepositPaidHandled = 'holding_deposit_paid_handled',
  /** column name */

  HoldingDepositReceived = 'holding_deposit_received',
  /** column name */

  IdReceived = 'id_received',
  /** column name */

  InclInDeposit = 'incl_in_deposit',
  /** column name */

  ProofVerified = 'proof_verified',
  /** column name */

  PurchaseProposalSent = 'purchase_proposal_sent',
  /** column name */

  ReservationFinalised = 'reservation_finalised',
  /** column name */

  SettlementInspectionCompleted = 'settlement_inspection_completed',
  /** column name */

  TransferToVsolRequested = 'transfer_to_vsol_requested',
  /** column name */

  UploadedId = 'uploaded_id',
  /** column name */

  UploadedReceipt = 'uploaded_receipt',
  /** column name */

  X66wCertificateProvided = 'x66w_certificate_provided',
}

/** select "deal_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deal" */
export enum Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  AwaitingEnquirySfId = 'awaiting_enquiry_sf_id',
  /** column name */

  AwaitingLegalSfId = 'awaiting_legal_sf_id',
  /** column name */

  ChangesRequested = 'changes_requested',
  /** column name */

  CommissionSplit = 'commission_split',
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  HoldingDepositPaidHandled = 'holding_deposit_paid_handled',
  /** column name */

  HoldingDepositReceived = 'holding_deposit_received',
  /** column name */

  IdReceived = 'id_received',
  /** column name */

  InclInDeposit = 'incl_in_deposit',
  /** column name */

  ProofVerified = 'proof_verified',
  /** column name */

  PurchaseProposalSent = 'purchase_proposal_sent',
  /** column name */

  ReservationFinalised = 'reservation_finalised',
  /** column name */

  SettlementInspectionCompleted = 'settlement_inspection_completed',
  /** column name */

  TransferToVsolRequested = 'transfer_to_vsol_requested',
  /** column name */

  UploadedId = 'uploaded_id',
  /** column name */

  UploadedReceipt = 'uploaded_receipt',
  /** column name */

  X66wCertificateProvided = 'x66w_certificate_provided',
}

/** unique or primary key constraints on table "deal_sf_action" */
export enum Deal_Sf_Action_Constraint {
  /** unique or primary key constraint on columns "id" */

  DealSfActionPkey = 'deal_sf_action_pkey',
}

/** select columns of table "deal_sf_action" */
export enum Deal_Sf_Action_Select_Column {
  /** column name */

  Action = 'action',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  Payload = 'payload',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "deal_sf_action" */
export enum Deal_Sf_Action_Update_Column {
  /** column name */

  Action = 'action',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  Payload = 'payload',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "deal_spoken_language" */
export enum Deal_Spoken_Language_Constraint {
  /** unique or primary key constraint on columns "language", "deal_id" */

  DealSpokenLanguageLanguageDealIdKey = 'deal_spoken_language_language_deal_id_key',
  /** unique or primary key constraint on columns "id" */

  DealSpokenLanguagePkey = 'deal_spoken_language_pkey',
}

/** select columns of table "deal_spoken_language" */
export enum Deal_Spoken_Language_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  Language = 'language',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "deal_spoken_language" */
export enum Deal_Spoken_Language_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  Language = 'language',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "deal_status" */
export enum Deal_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealStatusPkey = 'deal_status_pkey',
}

export enum Deal_Status_Enum {
  /** Active Enquiry */

  Active = 'ACTIVE',
  /** BA_Approved */

  BaApproved = 'BA_APPROVED',
  /** BA_Lodged */

  BaLodged = 'BA_LODGED',
  /** SF Legal Cancelled */

  Cancelled = 'CANCELLED',
  /** Conditional Exchange */

  Conditional = 'CONDITIONAL',
  /** Construction_Enclosed */

  ConstructionEnclosed = 'CONSTRUCTION_ENCLOSED',
  /** Construction_Fixing */

  ConstructionFixing = 'CONSTRUCTION_FIXING',
  /** Construction_Frame */

  ConstructionFrame = 'CONSTRUCTION_FRAME',
  /** Construction_Practical_Completion */

  ConstructionPracticalCompletion = 'CONSTRUCTION_PRACTICAL_COMPLETION',
  /** Construction_Site */

  ConstructionSite = 'CONSTRUCTION_SITE',
  /** Construction_Slab */

  ConstructionSlab = 'CONSTRUCTION_SLAB',
  /** SF Legal Contracted */

  Contracted = 'CONTRACTED',
  /** SF Legal Contract issued */

  ContractIssued = 'CONTRACT_ISSUED',
  /** SF Legal Deposit */

  Deposit = 'DEPOSIT',
  /** We have a deal and a lot. No reservation, no deposit. */

  Enquiry = 'ENQUIRY',
  /** EOI */

  Eoi = 'EOI',
  /** SF Legal Exchanged */

  Exchanged = 'EXCHANGED',
  /** Handover */

  Handover = 'HANDOVER',
  /** Issued */

  Issued = 'ISSUED',
  /** We have a deal but not lot. So it's just a lead. */

  Lead = 'LEAD',
  /** Lost Enquiry */

  Lost = 'LOST',
  /** SF Legal Offer */

  Offer = 'OFFER',
  /** On Hold */

  OnHold = 'ON_HOLD',
  /** Pending Enquiry */

  Pending = 'PENDING',
  /** Rescinded */

  Rescinded = 'RESCINDED',
  /** SF Legal rescission */

  Rescission = 'RESCISSION',
  /** Reservation Cancelled */

  Reservationcancelled = 'RESERVATIONCANCELLED',
  /** Reserved */

  Reserved = 'RESERVED',
  /** SF Legal SA received */

  SaReceived = 'SA_RECEIVED',
  /** SF Legal SA sent to legal */

  SaSentToLegal = 'SA_SENT_TO_LEGAL',
  /** Settled */

  Settled = 'SETTLED',
  /** SF Legal termination */

  Termination = 'TERMINATION',
  /** Unconditional Exchange */

  Unconditional = 'UNCONDITIONAL',
  /** Won Enquiry */

  Won = 'WON',
}

/** select columns of table "deal_status" */
export enum Deal_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_status" */
export enum Deal_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_transfer_status" */
export enum Deal_Transfer_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealTransferStatusPkey = 'deal_transfer_status_pkey',
}

export enum Deal_Transfer_Status_Enum {
  /** Entered */

  Entered = 'ENTERED',
  /** Error */

  Error = 'ERROR',
  /** Requested */

  Requested = 'REQUESTED',
  /** Transfer Complete */

  TransferComplete = 'TRANSFER_COMPLETE',
}

/** select columns of table "deal_transfer_status" */
export enum Deal_Transfer_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_transfer_status" */
export enum Deal_Transfer_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "deal_type" */
export enum Deal_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealTypePkey = 'deal_type_pkey',
}

export enum Deal_Type_Enum {
  /** Enquiry */

  Enquiry = 'ENQUIRY',
  /** Offer */

  Offer = 'OFFER',
}

/** select columns of table "deal_type" */
export enum Deal_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal_type" */
export enum Deal_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "deal" */
export enum Deal_Update_Column {
  /** column name */

  AccountManagerId = 'account_manager_id',
  /** column name */

  AdvisedContractReadyToProgress = 'advised_contract_ready_to_progress',
  /** column name */

  AgencyContactId = 'agency_contact_id',
  /** column name */

  AgencyId = 'agency_id',
  /** column name */

  AwaitingEnquirySfId = 'awaiting_enquiry_sf_id',
  /** column name */

  AwaitingLegalSfId = 'awaiting_legal_sf_id',
  /** column name */

  BalanceDueBy = 'balance_due_by',
  /** column name */

  BalanceOfDeposit = 'balance_of_deposit',
  /** column name */

  BankGuaranteeExpires = 'bank_guarantee_expires',
  /** column name */

  Bathrooms = 'bathrooms',
  /** column name */

  Bedrooms = 'bedrooms',
  /** column name */

  BedroomsPbMax = 'bedrooms_pb_max',
  /** column name */

  BedroomsPbMin = 'bedrooms_pb_min',
  /** column name */

  BedroomsRequested = 'bedrooms_requested',
  /** column name */

  BondExpires = 'bond_expires',
  /** column name */

  BpayBillerCode = 'bpay_biller_code',
  /** column name */

  BpayCrn = 'bpay_crn',
  /** column name */

  BuildingId = 'building_id',
  /** column name */

  BuyingCapacity = 'buying_capacity',
  /** column name */

  Cars = 'cars',
  /** column name */

  ChangesRequested = 'changes_requested',
  /** column name */

  ColourScheme = 'colour_scheme',
  /** column name */

  Comment = 'comment',
  /** column name */

  CommentForBuyer = 'comment_for_buyer',
  /** column name */

  CommissionSplit = 'commission_split',
  /** column name */

  ConfirmedFirbStatus = 'confirmed_firb_status',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  ContactStatus = 'contact_status',
  /** column name */

  ContractConditional = 'contract_conditional',
  /** column name */

  ContractDepositAmount = 'contract_deposit_amount',
  /** column name */

  ContractExchanged = 'contract_exchanged',
  /** column name */

  ContractIssued = 'contract_issued',
  /** column name */

  ContractProgressUpdate = 'contract_progress_update',
  /** column name */

  ContractReturnedToVendor = 'contract_returned_to_vendor',
  /** column name */

  ContractSentToPurchaser = 'contract_sent_to_purchaser',
  /** column name */

  ContractUnconditional = 'contract_unconditional',
  /** column name */

  CoolingOffEnds = 'cooling_off_ends',
  /** column name */

  CoolingOffStarts = 'cooling_off_starts',
  /** column name */

  CoolingOffWaived = 'cooling_off_waived',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  DepositDue = 'deposit_due',
  /** column name */

  DepositReceived = 'deposit_received',
  /** column name */

  DepositStatus = 'deposit_status',
  /** column name */

  DepositType = 'deposit_type',
  /** column name */

  EnquirySource = 'enquiry_source',
  /** column name */

  EnquirySourceOther = 'enquiry_source_other',
  /** column name */

  EnvelopeOpenedByBuyer = 'envelope_opened_by_buyer',
  /** column name */

  EnvelopeOwner = 'envelope_owner',
  /** column name */

  EoiApprovedByVendor = 'eoi_approved_by_vendor',
  /** column name */

  FallenDate = 'fallen_date',
  /** column name */

  FallenExplanation = 'fallen_explanation',
  /** column name */

  FallenReason = 'fallen_reason',
  /** column name */

  FinanceStatus = 'finance_status',
  /** column name */

  FirbApprovedVendor = 'firb_approved_vendor',
  /** column name */

  FirbConfirmationReceived = 'firb_confirmation_received',
  /** column name */

  FirbPurchaser = 'firb_purchaser',
  /** column name */

  ForecastExchange = 'forecast_exchange',
  /** column name */

  FormOfSettlement = 'form_of_settlement',
  /** column name */

  HoldingDepositAmount = 'holding_deposit_amount',
  /** column name */

  HoldingDepositAmountReceived = 'holding_deposit_amount_received',
  /** column name */

  HoldingDepositConfirmedByVendor = 'holding_deposit_confirmed_by_vendor',
  /** column name */

  HoldingDepositNumber = 'holding_deposit_number',
  /** column name */

  HoldingDepositPaidHandled = 'holding_deposit_paid_handled',
  /** column name */

  HoldingDepositPercentage = 'holding_deposit_percentage',
  /** column name */

  HoldingDepositReceived = 'holding_deposit_received',
  /** column name */

  HoldingDepositReceivedByVsol = 'holding_deposit_received_by_vsol',
  /** column name */

  HowDidYouHearAboutTheProperty = 'how_did_you_hear_about_the_property',
  /** column name */

  Id = 'id',
  /** column name */

  IdReceived = 'id_received',
  /** column name */

  IdentityDoc1Number = 'identity_doc1_number',
  /** column name */

  IdentityDoc1Region = 'identity_doc1_region',
  /** column name */

  IdentityDoc1Type = 'identity_doc1_type',
  /** column name */

  InclInDeposit = 'incl_in_deposit',
  /** column name */

  LastSalesAdviceGeneratedAt = 'last_sales_advice_generated_at',
  /** column name */

  LeadId = 'lead_id',
  /** column name */

  LeadStrength = 'lead_strength',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  Name = 'name',
  /** column name */

  NameOnContract = 'name_on_contract',
  /** column name */

  OnHold = 'on_hold',
  /** column name */

  OnHoldApprovedByClient = 'on_hold_approved_by_client',
  /** column name */

  OnHoldApprovedByVendor = 'on_hold_approved_by_vendor',
  /** column name */

  OnHoldRelease = 'on_hold_release',
  /** column name */

  PaidBy = 'paid_by',
  /** column name */

  ParkingSpaces = 'parking_spaces',
  /** column name */

  PayableOn = 'payable_on',
  /** column name */

  PaymentMethod = 'payment_method',
  /** column name */

  PreSettlementBookedBy = 'pre_settlement_booked_by',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  ProofVerified = 'proof_verified',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  PurchaseProposalSent = 'purchase_proposal_sent',
  /** column name */

  PurchaserSolicitorContactId = 'purchaser_solicitor_contact_id',
  /** column name */

  PurchaserSolicitorId = 'purchaser_solicitor_id',
  /** column name */

  ReasonToPurchase = 'reason_to_purchase',
  /** column name */

  RefundAccountBsb = 'refund_account_bsb',
  /** column name */

  RefundAccountName = 'refund_account_name',
  /** column name */

  RefundAccountNumber = 'refund_account_number',
  /** column name */

  RefundRequested = 'refund_requested',
  /** column name */

  RefundSwiftCode = 'refund_swift_code',
  /** column name */

  RequestReference = 'request_reference',
  /** column name */

  ReservationFinalised = 'reservation_finalised',
  /** column name */

  ReservedOn = 'reserved_on',
  /** column name */

  SaReceived = 'sa_received',
  /** column name */

  SaSentToLegal = 'sa_sent_to_legal',
  /** column name */

  SalePrice = 'sale_price',
  /** column name */

  Settlement = 'settlement',
  /** column name */

  SettlementBooked = 'settlement_booked',
  /** column name */

  SettlementDefectStatus = 'settlement_defect_status',
  /** column name */

  SettlementDueDate = 'settlement_due_date',
  /** column name */

  SettlementExtensionRequestedDate = 'settlement_extension_requested_date',
  /** column name */

  SettlementInspection = 'settlement_inspection',
  /** column name */

  SettlementInspectionCompleted = 'settlement_inspection_completed',
  /** column name */

  SettlementKeysCollectedDate = 'settlement_keys_collected_date',
  /** column name */

  SettlementOutlook = 'settlement_outlook',
  /** column name */

  SettlementStory = 'settlement_story',
  /** column name */

  SettlementSummary = 'settlement_summary',
  /** column name */

  SfDownSyncTime = 'sf_down_sync_time',
  /** column name */

  SfEnquiryId = 'sf_enquiry_id',
  /** column name */

  SfLegalId = 'sf_legal_id',
  /** column name */

  SolicitorNotes = 'solicitor_notes',
  /** column name */

  Source = 'source',
  /** column name */

  Status = 'status',
  /** column name */

  SubAgencyContactId = 'sub_agency_contact_id',
  /** column name */

  SubAgencyId = 'sub_agency_id',
  /** column name */

  Terms = 'terms',
  /** column name */

  TimeFrame = 'time_frame',
  /** column name */

  TotalDepositExtensionDate = 'total_deposit_extension_date',
  /** column name */

  TotalDepositOutstanding = 'total_deposit_outstanding',
  /** column name */

  TotalDepositPercentage = 'total_deposit_percentage',
  /** column name */

  TotalDepositSelection = 'total_deposit_selection',
  /** column name */

  TransferReceiptTime = 'transfer_receipt_time',
  /** column name */

  TransferStatus = 'transfer_status',
  /** column name */

  TransferToVsolRequested = 'transfer_to_vsol_requested',
  /** column name */

  TransferredToVsol = 'transferred_to_vsol',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UploadedId = 'uploaded_id',
  /** column name */

  UploadedIdCount = 'uploaded_id_count',
  /** column name */

  UploadedReceipt = 'uploaded_receipt',
  /** column name */

  VendorSolicitorContactId = 'vendor_solicitor_contact_id',
  /** column name */

  VendorSolicitorId = 'vendor_solicitor_id',
  /** column name */

  X66wCertificateProvided = 'x66w_certificate_provided',
  /** column name */

  X66wReceivedByVsol = 'x66w_received_by_vsol',
}

/** unique or primary key constraints on table "deal_valuation_booking" */
export enum Deal_Valuation_Booking_Constraint {
  /** unique or primary key constraint on columns "id" */

  DealValuationBookingPkey = 'deal_valuation_booking_pkey',
}

/** select columns of table "deal_valuation_booking" */
export enum Deal_Valuation_Booking_Select_Column {
  /** column name */

  BankRequestingValuation = 'bank_requesting_valuation',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  Id = 'id',
  /** column name */

  SyncToHubspot = 'sync_to_hubspot',
  /** column name */

  SyncToSalesforce = 'sync_to_salesforce',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  ValuationDate = 'valuation_date',
  /** column name */

  ValuationResult = 'valuation_result',
  /** column name */

  ValuerContactId = 'valuer_contact_id',
}

/** update columns of table "deal_valuation_booking" */
export enum Deal_Valuation_Booking_Update_Column {
  /** column name */

  BankRequestingValuation = 'bank_requesting_valuation',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  Id = 'id',
  /** column name */

  SyncToHubspot = 'sync_to_hubspot',
  /** column name */

  SyncToSalesforce = 'sync_to_salesforce',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  ValuationDate = 'valuation_date',
  /** column name */

  ValuationResult = 'valuation_result',
  /** column name */

  ValuerContactId = 'valuer_contact_id',
}

/** unique or primary key constraints on table "deal_witness" */
export enum Deal_Witness_Constraint {
  /** unique or primary key constraint on columns "id" */

  DealWitnessPkey = 'deal_witness_pkey',
}

/** select columns of table "deal_witness" */
export enum Deal_Witness_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Email = 'email',
  /** column name */

  FirstName = 'first_name',
  /** column name */

  Id = 'id',
  /** column name */

  LastName = 'last_name',
  /** column name */

  Mobile = 'mobile',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "deal_witness" */
export enum Deal_Witness_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Email = 'email',
  /** column name */

  FirstName = 'first_name',
  /** column name */

  Id = 'id',
  /** column name */

  LastName = 'last_name',
  /** column name */

  Mobile = 'mobile',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "developer_agent" */
export enum Developer_Agent_Constraint {
  /** unique or primary key constraint on columns "agent_id", "developer_id" */

  DeveloperAgentDeveloperIdAgentIdKey = 'developer_agent_developer_id_agent_id_key',
  /** unique or primary key constraint on columns "id" */

  DeveloperAgentPkey = 'developer_agent_pkey',
}

/** select columns of table "developer_agent" */
export enum Developer_Agent_Select_Column {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  AgentId = 'agent_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBySalesforce = 'created_by_salesforce',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DeveloperId = 'developer_id',
  /** column name */

  Id = 'id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "developer_agent_aggregate_bool_exp_bool_and_arguments_columns" columns of table "developer_agent" */
export enum Developer_Agent_Select_Column_Developer_Agent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  CreatedBySalesforce = 'created_by_salesforce',
}

/** select "developer_agent_aggregate_bool_exp_bool_or_arguments_columns" columns of table "developer_agent" */
export enum Developer_Agent_Select_Column_Developer_Agent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  CreatedBySalesforce = 'created_by_salesforce',
}

/** update columns of table "developer_agent" */
export enum Developer_Agent_Update_Column {
  /** column name */

  AccessActive = 'access_active',
  /** column name */

  AgentId = 'agent_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBySalesforce = 'created_by_salesforce',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DeveloperId = 'developer_id',
  /** column name */

  Id = 'id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "document" */
export enum Document_Constraint {
  /** unique or primary key constraint on columns "contact_id" */

  DocumentContactIdKey = 'document_contact_id_key',
  /** unique or primary key constraint on columns "id" */

  DocumentPkey = 'document_pkey',
}

/** select columns of table "document_expired" */
export enum Document_Expired_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  Description = 'description',
  /** column name */

  ExpiryDate = 'expiry_date',
  /** column name */

  Id = 'id',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "document" */
export enum Document_Select_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  BirthDate = 'birth_date',
  /** column name */

  CardExpiry = 'card_expiry',
  /** column name */

  CardNumber = 'card_number',
  /** column name */

  CardType = 'card_type',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  ExpiryDate = 'expiry_date',
  /** column name */

  FamilyName = 'family_name',
  /** column name */

  FullName1 = 'full_name1',
  /** column name */

  FullName2 = 'full_name2',
  /** column name */

  FullName3 = 'full_name3',
  /** column name */

  FullName4 = 'full_name4',
  /** column name */

  Gender = 'gender',
  /** column name */

  GivenName = 'given_name',
  /** column name */

  Id = 'id',
  /** column name */

  IndividualReferenceNumber = 'individual_reference_number',
  /** column name */

  IsValid = 'is_valid',
  /** column name */

  LicenceAttempt = 'licence_attempt',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  MedicareAttempt = 'medicare_attempt',
  /** column name */

  MiddleName = 'middle_name',
  /** column name */

  PassportAttempt = 'passport_attempt',
  /** column name */

  Response = 'response',
  /** column name */

  StateOfIssue = 'state_of_issue',
  /** column name */

  TravelDocumentNumber = 'travel_document_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VerificationRequestNumber = 'verification_request_number',
}

/** select "document_aggregate_bool_exp_bool_and_arguments_columns" columns of table "document" */
export enum Document_Select_Column_Document_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsValid = 'is_valid',
}

/** select "document_aggregate_bool_exp_bool_or_arguments_columns" columns of table "document" */
export enum Document_Select_Column_Document_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsValid = 'is_valid',
}

/** unique or primary key constraints on table "document_type" */
export enum Document_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  DocumentTypePkey = 'document_type_pkey',
}

export enum Document_Type_Enum {
  /** Drivers License */

  DriversLicense = 'DRIVERS_LICENSE',
  /** Manually uploaded document */

  Manual = 'MANUAL',
  /** Medicare */

  Medicare = 'MEDICARE',
  /** Passport */

  Passport = 'PASSPORT',
}

/** select columns of table "document_type" */
export enum Document_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "document_type" */
export enum Document_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "document" */
export enum Document_Update_Column {
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  BirthDate = 'birth_date',
  /** column name */

  CardExpiry = 'card_expiry',
  /** column name */

  CardNumber = 'card_number',
  /** column name */

  CardType = 'card_type',
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  ExpiryDate = 'expiry_date',
  /** column name */

  FamilyName = 'family_name',
  /** column name */

  FullName1 = 'full_name1',
  /** column name */

  FullName2 = 'full_name2',
  /** column name */

  FullName3 = 'full_name3',
  /** column name */

  FullName4 = 'full_name4',
  /** column name */

  Gender = 'gender',
  /** column name */

  GivenName = 'given_name',
  /** column name */

  Id = 'id',
  /** column name */

  IndividualReferenceNumber = 'individual_reference_number',
  /** column name */

  IsValid = 'is_valid',
  /** column name */

  LicenceAttempt = 'licence_attempt',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  MedicareAttempt = 'medicare_attempt',
  /** column name */

  MiddleName = 'middle_name',
  /** column name */

  PassportAttempt = 'passport_attempt',
  /** column name */

  Response = 'response',
  /** column name */

  StateOfIssue = 'state_of_issue',
  /** column name */

  TravelDocumentNumber = 'travel_document_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VerificationRequestNumber = 'verification_request_number',
}

/** unique or primary key constraints on table "embed" */
export enum Embed_Constraint {
  /** unique or primary key constraint on columns "id" */

  EmbedPkey = 'embed_pkey',
}

/** select columns of table "embed" */
export enum Embed_Select_Column {
  /** column name */

  Config = 'config',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "embed" */
export enum Embed_Update_Column {
  /** column name */

  Config = 'config',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "envelope" */
export enum Envelope_Constraint {
  /** unique or primary key constraint on columns "id" */

  DealEnvelopePkey = 'deal_envelope_pkey',
}

/** unique or primary key constraints on table "envelope_owner" */
export enum Envelope_Owner_Constraint {
  /** unique or primary key constraint on columns "value" */

  EnvelopeOwnerPkey = 'envelope_owner_pkey',
}

export enum Envelope_Owner_Enum {
  /** Envelope is connected to agency_agreement table */

  AgencyAgreement = 'AGENCY_AGREEMENT',
  /** Envelope is connected to the deal table */

  Deal = 'DEAL',
}

/** select columns of table "envelope_owner" */
export enum Envelope_Owner_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "envelope_owner" */
export enum Envelope_Owner_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** select columns of table "envelope" */
export enum Envelope_Select_Column {
  /** column name */

  CompletedRedirectUrl = 'completed_redirect_url',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DeclinedRedirectUrl = 'declined_redirect_url',
  /** column name */

  EnvelopeId = 'envelope_id',
  /** column name */

  EnvelopeRequest = 'envelope_request',
  /** column name */

  EnvelopeResponse = 'envelope_response',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  Owner = 'owner',
  /** column name */

  OwnerId = 'owner_id',
  /** column name */

  RecipientStatus = 'recipient_status',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VoidReason = 'void_reason',
  /** column name */

  VoidedByTeamId = 'voided_by_team_id',
  /** column name */

  VoidedByUserId = 'voided_by_user_id',
}

/** unique or primary key constraints on table "envelope_status" */
export enum Envelope_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  DealEnvelopeStatusPkey = 'deal_envelope_status_pkey',
}

export enum Envelope_Status_Enum {
  /** Everyone has signed the envelope */

  Completed = 'COMPLETED',
  /** Envelope created successfully */

  Created = 'CREATED',
  /** Envelope is in DRAFT state and can be modified */

  Draft = 'DRAFT',
  /** Error cought by ProjectRE during envelope creation */

  Error = 'ERROR',
  /** ProjectRE initiated envelope creation. envelope_id is null in this case. */

  Initiated = 'INITIATED',
  /** Envelope was sent to signers via Email or SMS */

  Sent = 'SENT',
  /** Envelope is voided */

  Voided = 'VOIDED',
}

/** select columns of table "envelope_status" */
export enum Envelope_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "envelope_status" */
export enum Envelope_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "envelope_type" */
export enum Envelope_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  EnvelopeTypePkey = 'envelope_type_pkey',
}

export enum Envelope_Type_Enum {
  /** Agreement between developer and agency */

  AgencyAgreement = 'AGENCY_AGREEMENT',
  /** Initial envelope which can be created on the Sales Contract page */

  SalesContract = 'SALES_CONTRACT',
}

/** select columns of table "envelope_type" */
export enum Envelope_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "envelope_type" */
export enum Envelope_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "envelope" */
export enum Envelope_Update_Column {
  /** column name */

  CompletedRedirectUrl = 'completed_redirect_url',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  DeclinedRedirectUrl = 'declined_redirect_url',
  /** column name */

  EnvelopeId = 'envelope_id',
  /** column name */

  EnvelopeRequest = 'envelope_request',
  /** column name */

  EnvelopeResponse = 'envelope_response',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  Owner = 'owner',
  /** column name */

  OwnerId = 'owner_id',
  /** column name */

  RecipientStatus = 'recipient_status',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VoidReason = 'void_reason',
  /** column name */

  VoidedByTeamId = 'voided_by_team_id',
  /** column name */

  VoidedByUserId = 'voided_by_user_id',
}

/** unique or primary key constraints on table "experiment" */
export enum Experiment_Constraint {
  /** unique or primary key constraint on columns "value" */

  ExperimentPkey = 'experiment_pkey',
}

export enum Experiment_Enum {
  /** Support for new roles: developer_member, developer_admin, agent_member, agent_admin, agent_member_master, agent_admin_master */

  NewRoles = 'NEW_ROLES',
}

/** select columns of table "experiment" */
export enum Experiment_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "experiment" */
export enum Experiment_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "feature_check" */
export enum Feature_Check_Constraint {
  /** unique or primary key constraint on columns "result" */

  FeatureCheckPkey = 'feature_check_pkey',
}

/** select columns of table "feature_check" */
export enum Feature_Check_Select_Column {
  /** column name */

  Result = 'result',
}

/** update columns of table "feature_check" */
export enum Feature_Check_Update_Column {
  /** column name */

  Result = 'result',
}

/** unique or primary key constraints on table "feature" */
export enum Feature_Constraint {
  /** unique or primary key constraint on columns "id" */

  FeaturePkey = 'feature_pkey',
}

/** unique or primary key constraints on table "feature_pack" */
export enum Feature_Pack_Constraint {
  /** unique or primary key constraint on columns "id" */

  FeaturePackPkey = 'feature_pack_pkey',
}

/** unique or primary key constraints on table "feature_pack_feature" */
export enum Feature_Pack_Feature_Constraint {
  /** unique or primary key constraint on columns "feature", "feature_pack_id" */

  FeaturePackFeatureFeaturePackIdFeatureKey = 'feature_pack_feature_feature_pack_id_feature_key',
  /** unique or primary key constraint on columns "id" */

  FeaturePackFeaturePkey = 'feature_pack_feature_pkey',
}

/** select columns of table "feature_pack_feature" */
export enum Feature_Pack_Feature_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  FeaturePackId = 'feature_pack_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "feature_pack_feature" */
export enum Feature_Pack_Feature_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  FeaturePackId = 'feature_pack_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "feature_pack" */
export enum Feature_Pack_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Description = 'description',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "feature_pack" */
export enum Feature_Pack_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Description = 'description',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "feature" */
export enum Feature_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** update columns of table "feature" */
export enum Feature_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "filter_page" */
export enum Filter_Page_Constraint {
  /** unique or primary key constraint on columns "value" */

  FilterPagePkey = 'filter_page_pkey',
}

export enum Filter_Page_Enum {
  /** analytics Sale page */

  AnalyticsSale = 'ANALYTICS_SALE',
  /** Analytics Snapshot Page */

  AnalyticsSnapshot = 'ANALYTICS_SNAPSHOT',
}

/** select columns of table "filter_page" */
export enum Filter_Page_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "filter_page" */
export enum Filter_Page_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "filter_state" */
export enum Filter_State_Constraint {
  /** unique or primary key constraint on columns "id" */

  FilterStatePkey = 'filter_state_pkey',
  /** unique or primary key constraint on columns "user_id", "page", "team_id" */

  FilterStateUserIdTeamIdPageKey = 'filter_state_user_id_team_id_page_key',
}

/** select columns of table "filter_state" */
export enum Filter_State_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Page = 'page',
  /** column name */

  State = 'state',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "filter_state" */
export enum Filter_State_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Page = 'page',
  /** column name */

  State = 'state',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "finance_status" */
export enum Finance_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  FinanceStatusPkey = 'finance_status_pkey',
}

export enum Finance_Status_Enum {
  /** Approved */

  Approved = 'APPROVED',
  /** In Progress */

  InProgress = 'IN_PROGRESS',
  /** Not Started */

  NotStarted = 'NOT_STARTED',
  /** Rejected */

  Rejected = 'REJECTED',
}

/** select columns of table "finance_status" */
export enum Finance_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "finance_status" */
export enum Finance_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "graphql_query_log" */
export enum Graphql_Query_Log_Constraint {
  /** unique or primary key constraint on columns "id" */

  GraphqlQueryLogPkey = 'graphql_query_log_pkey',
}

/** select columns of table "graphql_query_log" */
export enum Graphql_Query_Log_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByRole = 'created_by_role',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByTeamRole = 'created_by_team_role',
  /** column name */

  CreatedByTeamType = 'created_by_team_type',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Duration = 'duration',
  /** column name */

  FinishedAt = 'finished_at',
  /** column name */

  Id = 'id',
  /** column name */

  OpContent = 'op_content',
  /** column name */

  OpName = 'op_name',
  /** column name */

  OpVars = 'op_vars',
  /** column name */

  Source = 'source',
  /** column name */

  StartedAt = 'started_at',
}

/** update columns of table "graphql_query_log" */
export enum Graphql_Query_Log_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByRole = 'created_by_role',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByTeamRole = 'created_by_team_role',
  /** column name */

  CreatedByTeamType = 'created_by_team_type',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Duration = 'duration',
  /** column name */

  FinishedAt = 'finished_at',
  /** column name */

  Id = 'id',
  /** column name */

  OpContent = 'op_content',
  /** column name */

  OpName = 'op_name',
  /** column name */

  OpVars = 'op_vars',
  /** column name */

  Source = 'source',
  /** column name */

  StartedAt = 'started_at',
}

/** unique or primary key constraints on table "hubspot_action" */
export enum Hubspot_Action_Constraint {
  /** unique or primary key constraint on columns "id" */

  HubspotActionPkey = 'hubspot_action_pkey',
}

/** select columns of table "hubspot_action" */
export enum Hubspot_Action_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  Payload = 'payload',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_action_type" */
export enum Hubspot_Action_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  HubspotActionTypePkey = 'hubspot_action_type_pkey',
}

export enum Hubspot_Action_Type_Enum {
  /** Delete existing deal */

  DeleteDeal = 'DELETE_DEAL',
  /** Delete existing project */

  DeleteProject = 'DELETE_PROJECT',
  /** Delete Deal Valuation Booking */

  DeleteValuationBooking = 'DELETE_VALUATION_BOOKING',
  /** Updates existing company or creates new one */

  UpsertCompany = 'UPSERT_COMPANY',
  /** Updates existing contact or creates new one */

  UpsertContact = 'UPSERT_CONTACT',
  /** updates existing deal or creates new one */

  UpsertDeal = 'UPSERT_DEAL',
  /** updates existing project or creates new one */

  UpsertProject = 'UPSERT_PROJECT',
  /** updates existing Property Group or creates new one */

  UpsertPropertyGroup = 'UPSERT_PROPERTY_GROUP',
  /** updates existing Listing or creates new one */

  UpsertPropertyListing = 'UPSERT_PROPERTY_LISTING',
  /** Updates existing contact for given user or creates new contact for given user */

  UpsertUser = 'UPSERT_USER',
  /** Create or Update Deal Valuation Booking */

  UpsertValuationBooking = 'UPSERT_VALUATION_BOOKING',
  /** Do nothing just wait for 10 seconds. Use this if you need to start processing HubSpot action queue, after some edits. Queue processing starts only when new record is added. */

  Wait_10 = 'WAIT_10',
}

/** select columns of table "hubspot_action_type" */
export enum Hubspot_Action_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "hubspot_action_type" */
export enum Hubspot_Action_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "hubspot_action" */
export enum Hubspot_Action_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  Payload = 'payload',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_contact" */
export enum Hubspot_Contact_Constraint {
  /** unique or primary key constraint on columns "id" */

  HsContactPkey = 'hs_contact_pkey',
  /** unique or primary key constraint on columns "contact_id", "hs_instance_id", "hs_id" */

  HubspotContactContactIdHsInstanceIdHsIdKey = 'hubspot_contact_contact_id_hs_instance_id_hs_id_key',
}

/** select columns of table "hubspot_contact" */
export enum Hubspot_Contact_Select_Column {
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "hubspot_contact" */
export enum Hubspot_Contact_Update_Column {
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_deal" */
export enum Hubspot_Deal_Constraint {
  /** unique or primary key constraint on columns "hs_instance_id", "hs_id", "deal_id" */

  HubspotDealDealIdHsInstanceIdHsIdKey = 'hubspot_deal_deal_id_hs_instance_id_hs_id_key',
  /** unique or primary key constraint on columns "id" */

  HubspotDealPkey = 'hubspot_deal_pkey',
}

/** select columns of table "hubspot_deal" */
export enum Hubspot_Deal_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "hubspot_deal" */
export enum Hubspot_Deal_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_organisation" */
export enum Hubspot_Organisation_Constraint {
  /** unique or primary key constraint on columns "id" */

  HsOrganisationPkey = 'hs_organisation_pkey',
  /** unique or primary key constraint on columns "org_id", "hs_instance_id", "hs_id" */

  HubspotOrganisationHsInstanceIdOrgIdHsIdKey = 'hubspot_organisation_hs_instance_id_org_id_hs_id_key',
}

/** select columns of table "hubspot_organisation" */
export enum Hubspot_Organisation_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "hubspot_organisation" */
export enum Hubspot_Organisation_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_project" */
export enum Hubspot_Project_Constraint {
  /** unique or primary key constraint on columns "id" */

  HubspotProjectPkey = 'hubspot_project_pkey',
  /** unique or primary key constraint on columns "project_id", "hs_instance_id", "hs_id" */

  HubspotProjectProjectIdHsInstanceIdHsIdKey = 'hubspot_project_project_id_hs_instance_id_hs_id_key',
}

/** select columns of table "hubspot_project" */
export enum Hubspot_Project_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "hubspot_project" */
export enum Hubspot_Project_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_schema_error_log" */
export enum Hubspot_Schema_Error_Log_Constraint {
  /** unique or primary key constraint on columns "id" */

  HubspotSchemaErrorLogPkey = 'hubspot_schema_error_log_pkey',
}

/** select columns of table "hubspot_schema_error_log" */
export enum Hubspot_Schema_Error_Log_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "hubspot_schema_error_log" */
export enum Hubspot_Schema_Error_Log_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "hubspot_user" */
export enum Hubspot_User_Constraint {
  /** unique or primary key constraint on columns "id" */

  HubspotUserPkey = 'hubspot_user_pkey',
  /** unique or primary key constraint on columns "user_id", "hs_instance_id" */

  HubspotUserUserIdHsInstanceIdKey = 'hubspot_user_user_id_hs_instance_id_key',
}

/** select columns of table "hubspot_user" */
export enum Hubspot_User_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "hubspot_user" */
export enum Hubspot_User_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "hubspot_valuation_booking" */
export enum Hubspot_Valuation_Booking_Constraint {
  /** unique or primary key constraint on columns "id" */

  HubspotValuationBookingPkey = 'hubspot_valuation_booking_pkey',
}

/** select columns of table "hubspot_valuation_booking" */
export enum Hubspot_Valuation_Booking_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  ValuationBookingId = 'valuation_booking_id',
}

/** update columns of table "hubspot_valuation_booking" */
export enum Hubspot_Valuation_Booking_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  HsId = 'hs_id',
  /** column name */

  HsInstanceId = 'hs_instance_id',
  /** column name */

  Id = 'id',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  ValuationBookingId = 'valuation_booking_id',
}

/** unique or primary key constraints on table "invitation" */
export enum Invitation_Constraint {
  /** unique or primary key constraint on columns "id" */

  InvitationPkey = 'invitation_pkey',
}

/** select columns of table "invitation" */
export enum Invitation_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Email = 'email',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  InviteeFirstname = 'invitee_firstname',
  /** column name */

  InviteeLastname = 'invitee_lastname',
  /** column name */

  InviteePhoneNumber = 'invitee_phone_number',
  /** column name */

  InviterFirstname = 'inviter_firstname',
  /** column name */

  InviterLastname = 'inviter_lastname',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  OrganisationName = 'organisation_name',
  /** column name */

  PartnerPortalId = 'partner_portal_id',
  /** column name */

  Role = 'role',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "invitation_status" */
export enum Invitation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  InvitationStatusPkey = 'invitation_status_pkey',
}

export enum Invitation_Status_Enum {
  /** Invitation was accepted */

  Accepted = 'ACCEPTED',
  /** Invitation was declined */

  Declined = 'DECLINED',
  /** There was an error while processing invitation */

  Error = 'ERROR',
  /** Invitation is still pending */

  Pending = 'PENDING',
  /** Invitation was withdrawn */

  Withdrawn = 'WITHDRAWN',
}

/** select columns of table "invitation_status" */
export enum Invitation_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "invitation_status" */
export enum Invitation_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "invitation_type" */
export enum Invitation_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  InvitationTypePkey = 'invitation_type_pkey',
}

export enum Invitation_Type_Enum {
  /** Organisation invite */

  Organisation = 'ORGANISATION',
  /** Invite a new user to become an Admin of a new Agency and become a partner for inviting party */

  Partner = 'PARTNER',
  /** An invitation record, which allows an invitation link to be sent to potential partner Agencies. Agency person can use invitation URL to get access to a partner onboarding form which must be filled in by an agent. Once submitted a new agency will be created, or another option will be presented if such company already exists. */

  PartnerPortal = 'PARTNER_PORTAL',
  /** User invite */

  User = 'USER',
}

/** select columns of table "invitation_type" */
export enum Invitation_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "invitation_type" */
export enum Invitation_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "invitation" */
export enum Invitation_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Email = 'email',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  InviteeFirstname = 'invitee_firstname',
  /** column name */

  InviteeLastname = 'invitee_lastname',
  /** column name */

  InviteePhoneNumber = 'invitee_phone_number',
  /** column name */

  InviterFirstname = 'inviter_firstname',
  /** column name */

  InviterLastname = 'inviter_lastname',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  OrganisationName = 'organisation_name',
  /** column name */

  PartnerPortalId = 'partner_portal_id',
  /** column name */

  Role = 'role',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "lambda_log" */
export enum Lambda_Log_Constraint {
  /** unique or primary key constraint on columns "id" */

  LambdaLogPkey = 'lambda_log_pkey',
}

/** select columns of table "lambda_log" */
export enum Lambda_Log_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  Request = 'request',
  /** column name */

  Response = 'response',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "lambda_log" */
export enum Lambda_Log_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  Request = 'request',
  /** column name */

  Response = 'response',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "lead_generation" */
export enum Lead_Generation_Constraint {
  /** unique or primary key constraint on columns "id" */

  LeadGenerationPkey = 'lead_generation_pkey',
  /** unique or primary key constraint on columns "project_id", "source", "organisation_id" */

  LeadGenerationProjectIdOrganisationIdSourceKey = 'lead_generation_project_id_organisation_id_source_key',
}

/** select columns of table "lead_generation" */
export enum Lead_Generation_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  LeadOwnerId = 'lead_owner_id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  Source = 'source',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "lead_generation_source" */
export enum Lead_Generation_Source_Constraint {
  /** unique or primary key constraint on columns "value" */

  LeadGenerationSourcePkey = 'lead_generation_source_pkey',
}

export enum Lead_Generation_Source_Enum {
  /** Domain */

  Domain = 'DOMAIN',
  /** Torn Marketing */

  TornMarketing = 'TORN_MARKETING',
  /** urban.com.au */

  Urban = 'URBAN',
}

/** select columns of table "lead_generation_source" */
export enum Lead_Generation_Source_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "lead_generation_source" */
export enum Lead_Generation_Source_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "lead_generation" */
export enum Lead_Generation_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  LeadOwnerId = 'lead_owner_id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  Source = 'source',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "lead_generation_webhook_log" */
export enum Lead_Generation_Webhook_Log_Constraint {
  /** unique or primary key constraint on columns "id" */

  LeadGenerationWebhookLogPkey = 'lead_generation_webhook_log_pkey',
}

/** select columns of table "lead_generation_webhook_log" */
export enum Lead_Generation_Webhook_Log_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  LeadGenerationId = 'lead_generation_id',
  /** column name */

  Request = 'request',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "lead_generation_webhook_log" */
export enum Lead_Generation_Webhook_Log_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  LeadGenerationId = 'lead_generation_id',
  /** column name */

  Request = 'request',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "listing" */
export enum Listing_Constraint {
  /** unique or primary key constraint on columns "id" */

  ListingPkey = 'listing_pkey',
  /** unique or primary key constraint on columns "sf_id" */

  ListingSfIdKey = 'listing_sf_id_key',
}

/** select columns of table "listing_price_per_sqmt" */
export enum Listing_Price_Per_Sqmt_Select_Column {
  /** column name */

  Id = 'id',
  /** column name */

  PricePerSqmt = 'price_per_sqmt',
}

/** unique or primary key constraints on table "listing_publish_errors" */
export enum Listing_Publish_Errors_Constraint {
  /** unique or primary key constraint on columns "listing_id", "portal_source" */

  ListingPublishErrorsListingIdPortalSourceKey = 'listing_publish_errors_listing_id_portal_source_key',
  /** unique or primary key constraint on columns "id" */

  ListingPublishErrorsPkey = 'listing_publish_errors_pkey',
}

/** select columns of table "listing_publish_errors" */
export enum Listing_Publish_Errors_Select_Column {
  /** column name */

  AgencyId = 'agency_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  ErrorField = 'error_field',
  /** column name */

  ErrorMessage = 'error_message',
  /** column name */

  Id = 'id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  PortalSource = 'portal_source',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "listing_publish_errors" */
export enum Listing_Publish_Errors_Update_Column {
  /** column name */

  AgencyId = 'agency_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  ErrorField = 'error_field',
  /** column name */

  ErrorMessage = 'error_message',
  /** column name */

  Id = 'id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  PortalSource = 'portal_source',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "listing_publish_portal_source" */
export enum Listing_Publish_Portal_Source_Constraint {
  /** unique or primary key constraint on columns "value" */

  ListingPublishPortalSourcePkey = 'listing_publish_portal_source_pkey',
}

export enum Listing_Publish_Portal_Source_Enum {
  /** domain */

  Domain = 'domain',
  /** real estate */

  Rea = 'rea',
}

/** select columns of table "listing_publish_portal_source" */
export enum Listing_Publish_Portal_Source_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "listing_publish_portal_source" */
export enum Listing_Publish_Portal_Source_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "listing_publish_status" */
export enum Listing_Publish_Status_Constraint {
  /** unique or primary key constraint on columns "listing_id", "portal_source" */

  ListingPublishStatusListingIdPortalSourceKey = 'listing_publish_status_listing_id_portal_source_key',
  /** unique or primary key constraint on columns "id" */

  ListingPublishStatusPkey = 'listing_publish_status_pkey',
}

/** select columns of table "listing_publish_status" */
export enum Listing_Publish_Status_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DomainData = 'domain_data',
  /** column name */

  Id = 'id',
  /** column name */

  IsTest = 'is_test',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  PortalSource = 'portal_source',
  /** column name */

  ProcessJobId = 'process_job_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PublishStatus = 'publish_status',
  /** column name */

  Request = 'request',
  /** column name */

  Response = 'response',
  /** column name */

  TestXml = 'test_xml',
  /** column name */

  TestXmlName = 'test_xml_name',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  XmlFileName = 'xml_file_name',
}

/** unique or primary key constraints on table "listing_publish_status_type" */
export enum Listing_Publish_Status_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  ListingPublishStatusTypePkey = 'listing_publish_status_type_pkey',
}

export enum Listing_Publish_Status_Type_Enum {
  /** draft */

  Draft = 'draft',
  /** error */

  Error = 'error',
  /** processed */

  Processed = 'processed',
  /** processing */

  Processing = 'processing',
  /** queued */

  Queued = 'queued',
}

/** select columns of table "listing_publish_status_type" */
export enum Listing_Publish_Status_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "listing_publish_status_type" */
export enum Listing_Publish_Status_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "listing_publish_status" */
export enum Listing_Publish_Status_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DomainData = 'domain_data',
  /** column name */

  Id = 'id',
  /** column name */

  IsTest = 'is_test',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  PortalSource = 'portal_source',
  /** column name */

  ProcessJobId = 'process_job_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PublishStatus = 'publish_status',
  /** column name */

  Request = 'request',
  /** column name */

  Response = 'response',
  /** column name */

  TestXml = 'test_xml',
  /** column name */

  TestXmlName = 'test_xml_name',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  XmlFileName = 'xml_file_name',
}

/** select columns of table "listing" */
export enum Listing_Select_Column {
  /** column name */

  BlockDate = 'block_date',
  /** column name */

  BlockReleaseDate = 'block_release_date',
  /** column name */

  BlockedByUser = 'blocked_by_user',
  /** column name */

  BlockedForUser = 'blocked_for_user',
  /** column name */

  CarSpace = 'car_space',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Price = 'price',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  ReleaseNumber = 'release_number',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Status = 'status',
  /** column name */

  UnitAllocationCount = 'unit_allocation_count',
  /** column name */

  UnitAllocationCountMaster = 'unit_allocation_count_master',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "listing_status" */
export enum Listing_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  ListingStatusPkey = 'listing_status_pkey',
}

export enum Listing_Status_Enum {
  /** Available */

  Available = 'AVAILABLE',
  /** Blocked */

  Blocked = 'BLOCKED',
  /** SF Dahua */

  Dahua = 'DAHUA',
  /** SF Deposit */

  Deposit = 'DEPOSIT',
  /** Developer sale */

  DeveloperSale = 'DEVELOPER_SALE',
  /** SF Exchanged */

  Exchanged = 'EXCHANGED',
  /** Hopefully blocked */

  HopefullyBlocked = 'HOPEFULLY_BLOCKED',
  /** Hopefully on sale pending */

  HopefullyOnSalePending = 'HOPEFULLY_ON_SALE_PENDING',
  /** Listed */

  Listed = 'LISTED',
  /** SF On Sale */

  OnSale = 'ON_SALE',
  /** SF Reserved */

  Reserved = 'RESERVED',
  /** SF Sales Hold */

  SalesHold = 'SALES_HOLD',
  /** Settled */

  Settled = 'SETTLED',
  /** Under contract */

  UnderContract = 'UNDER_CONTRACT',
  /** SF Unreleased */

  Unreleased = 'UNRELEASED',
  /** Withheld */

  Withheld = 'WITHHELD',
}

/** select columns of table "listing_status" */
export enum Listing_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "listing_status" */
export enum Listing_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "listing" */
export enum Listing_Update_Column {
  /** column name */

  BlockDate = 'block_date',
  /** column name */

  BlockReleaseDate = 'block_release_date',
  /** column name */

  BlockedByUser = 'blocked_by_user',
  /** column name */

  BlockedForUser = 'blocked_for_user',
  /** column name */

  CarSpace = 'car_space',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Price = 'price',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  ReleaseNumber = 'release_number',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Status = 'status',
  /** column name */

  UnitAllocationCount = 'unit_allocation_count',
  /** column name */

  UnitAllocationCountMaster = 'unit_allocation_count_master',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "member" */
export enum Member_Constraint {
  /** unique or primary key constraint on columns "id" */

  MemberPkey = 'member_pkey',
  /** unique or primary key constraint on columns "user_id", "organisation_id" */

  MemberUserIdOrganisationIdKey = 'member_user_id_organisation_id_key',
}

/** unique or primary key constraints on table "member_feature" */
export enum Member_Feature_Constraint {
  /** unique or primary key constraint on columns "feature", "member_id" */

  MemberFeatureMemberIdFeatureKey = 'member_feature_member_id_feature_key',
  /** unique or primary key constraint on columns "id" */

  MemberFeaturePkey = 'member_feature_pkey',
}

/** select columns of table "member_feature" */
export enum Member_Feature_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  Id = 'id',
  /** column name */

  MemberId = 'member_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "member_feature" */
export enum Member_Feature_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  Id = 'id',
  /** column name */

  MemberId = 'member_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "member_project_allocate" */
export enum Member_Project_Allocate_Constraint {
  /** unique or primary key constraint on columns "project_id", "member_id" */

  MemberProjectAllocateMemberIdProjectIdKey = 'member_project_allocate_member_id_project_id_key',
  /** unique or primary key constraint on columns "id" */

  MemberProjectAllocatePkey = 'member_project_allocate_pkey',
}

/** select columns of table "member_project_allocate" */
export enum Member_Project_Allocate_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Id = 'id',
  /** column name */

  MemberId = 'member_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "member_project_allocate" */
export enum Member_Project_Allocate_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  CreatedByUserId = 'created_by_user_id',
  /** column name */

  Id = 'id',
  /** column name */

  MemberId = 'member_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "member_role" */
export enum Member_Role_Constraint {
  /** unique or primary key constraint on columns "value" */

  MemberRolePkey = 'member_role_pkey',
}

export enum Member_Role_Enum {
  /** Admin */

  Admin = 'ADMIN',
  /** Buyer user, can access buyer portal, and buyer related pages */

  Buyer = 'BUYER',
  /** Member */

  Member = 'MEMBER',
}

/** select columns of table "member_role" */
export enum Member_Role_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "member_role" */
export enum Member_Role_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** select columns of table "member" */
export enum Member_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  Role = 'role',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "member" */
export enum Member_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  Role = 'role',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "nationality" */
export enum Nationality_Constraint {
  /** unique or primary key constraint on columns "value" */

  NationalityPkey = 'nationality_pkey',
}

/** select columns of table "nationality" */
export enum Nationality_Select_Column {
  /** column name */

  Value = 'value',
}

/** update columns of table "nationality" */
export enum Nationality_Update_Column {
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "notification_channel" */
export enum Notification_Channel_Constraint {
  /** unique or primary key constraint on columns "value" */

  NotificationChannelPkey = 'notification_channel_pkey',
}

export enum Notification_Channel_Enum {
  /** Courier Index Push */

  CourierPush = 'COURIER_PUSH',
  /** email */

  Email = 'EMAIL',
  /** Project RE */

  Projectre = 'PROJECTRE',
  /** SLACK */

  Slack = 'SLACK',
  /** sms */

  Sms = 'SMS',
}

/** select columns of table "notification_channel" */
export enum Notification_Channel_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "notification_channel" */
export enum Notification_Channel_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */

  NotificationPkey = 'notification_pkey',
}

/** unique or primary key constraints on table "notification_owner" */
export enum Notification_Owner_Constraint {
  /** unique or primary key constraint on columns "value" */

  NotificationOwnerPkey = 'notification_owner_pkey',
}

export enum Notification_Owner_Enum {
  /** ProjectRE system */

  System = 'system',
  /** user table */

  User = 'user',
}

/** select columns of table "notification_owner" */
export enum Notification_Owner_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "notification_owner" */
export enum Notification_Owner_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */

  Channel = 'channel',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DstId = 'dst_id',
  /** column name */

  DstTeamId = 'dst_team_id',
  /** column name */

  DstType = 'dst_type',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  Payload = 'payload',
  /** column name */

  ReadAt = 'read_at',
  /** column name */

  ScheduledAt = 'scheduled_at',
  /** column name */

  SentAt = 'sent_at',
  /** column name */

  SrcId = 'src_id',
  /** column name */

  SrcType = 'src_type',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "notification_type" */
export enum Notification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  NotificationTypePkey = 'notification_type_pkey',
}

export enum Notification_Type_Enum {
  /** Agency Agreement Change */

  AgencyAgreementChange = 'AGENCY_AGREEMENT_CHANGE',
  /** Allocation Changed */

  AllocationChanged = 'ALLOCATION_CHANGED',
  /** Deal status change (created, reserved, issued, conditional, unconditional, settled, cancelled) */

  DealStatusChange = 'DEAL_STATUS_CHANGE',
  /** Listing detail change (price, int size, ext size, beds, baths) */

  ListingDetailChange = 'LISTING_DETAIL_CHANGE',
  /** New Agent Signup */

  NewAgentSignUp = 'NEW_AGENT_SIGN_UP',
  /** New user sign up */

  NewUserSignUp = 'NEW_USER_SIGN_UP',
  /** Property Sold */

  PropertySold = 'PROPERTY_SOLD',
  /** TRANSACTION created updated notification  */

  Transaction = 'TRANSACTION',
}

/** select columns of table "notification_type" */
export enum Notification_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "notification_type" */
export enum Notification_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */

  Channel = 'channel',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DstId = 'dst_id',
  /** column name */

  DstTeamId = 'dst_team_id',
  /** column name */

  DstType = 'dst_type',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  Payload = 'payload',
  /** column name */

  ReadAt = 'read_at',
  /** column name */

  ScheduledAt = 'scheduled_at',
  /** column name */

  SentAt = 'sent_at',
  /** column name */

  SrcId = 'src_id',
  /** column name */

  SrcType = 'src_type',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */

  Asc = 'asc',
  /** in ascending order, nulls first */

  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */

  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */

  Desc = 'desc',
  /** in descending order, nulls first */

  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */

  DescNullsLast = 'desc_nulls_last',
}

/** select columns of table "org_feature_enabled" */
export enum Org_Feature_Enabled_Select_Column {
  /** column name */

  Feature = 'feature',
  /** column name */

  OrgId = 'org_id',
}

/** unique or primary key constraints on table "org_feature_pack" */
export enum Org_Feature_Pack_Constraint {
  /** unique or primary key constraint on columns "org_id", "feature_pack_id" */

  OrgFeaturePackOrgIdFeaturePackIdKey = 'org_feature_pack_org_id_feature_pack_id_key',
  /** unique or primary key constraint on columns "id" */

  OrgFeaturePackPkey = 'org_feature_pack_pkey',
}

/** select columns of table "org_feature_pack" */
export enum Org_Feature_Pack_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  FeaturePackId = 'feature_pack_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsEnabled = 'is_enabled',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "org_feature_pack_aggregate_bool_exp_bool_and_arguments_columns" columns of table "org_feature_pack" */
export enum Org_Feature_Pack_Select_Column_Org_Feature_Pack_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsEnabled = 'is_enabled',
}

/** select "org_feature_pack_aggregate_bool_exp_bool_or_arguments_columns" columns of table "org_feature_pack" */
export enum Org_Feature_Pack_Select_Column_Org_Feature_Pack_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsEnabled = 'is_enabled',
}

/** update columns of table "org_feature_pack" */
export enum Org_Feature_Pack_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  FeaturePackId = 'feature_pack_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsEnabled = 'is_enabled',
  /** column name */

  OrgId = 'org_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "organisation_allocation_counts_view" */
export enum Organisation_Allocation_Counts_View_Select_Column {
  /** column name */

  ExclusiveCount = 'exclusive_count',
  /** column name */

  OpenCount = 'open_count',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  SharedCount = 'shared_count',
}

/** unique or primary key constraints on table "organisation" */
export enum Organisation_Constraint {
  /** unique or primary key constraint on columns "id" */

  OrganisationPkey = 'organisation_pkey',
}

/** unique or primary key constraints on table "organisation_feature" */
export enum Organisation_Feature_Constraint {
  /** unique or primary key constraint on columns "feature", "organisation_id" */

  OrganisationFeatureOrganisationIdFeatureKey = 'organisation_feature_organisation_id_feature_key',
  /** unique or primary key constraint on columns "id" */

  OrganisationFeaturePkey = 'organisation_feature_pkey',
}

/** select columns of table "organisation_feature" */
export enum Organisation_Feature_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  Id = 'id',
  /** column name */

  IsEnabled = 'is_enabled',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "organisation_feature_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organisation_feature" */
export enum Organisation_Feature_Select_Column_Organisation_Feature_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsEnabled = 'is_enabled',
}

/** select "organisation_feature_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organisation_feature" */
export enum Organisation_Feature_Select_Column_Organisation_Feature_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsEnabled = 'is_enabled',
}

/** update columns of table "organisation_feature" */
export enum Organisation_Feature_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  Id = 'id',
  /** column name */

  IsEnabled = 'is_enabled',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "organisation" */
export enum Organisation_Select_Column {
  /** column name */

  Abn = 'abn',
  /** column name */

  Acn = 'acn',
  /** column name */

  Address = 'address',
  /** column name */

  AddressCitySuburb = 'address_city_suburb',
  /** column name */

  AddressCountry = 'address_country',
  /** column name */

  AddressLine_1 = 'address_line_1',
  /** column name */

  AddressPostcode = 'address_postcode',
  /** column name */

  AddressState = 'address_state',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  Email = 'email',
  /** column name */

  FaviconId = 'favicon_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsSetup = 'is_setup',
  /** column name */

  IsUserTeam = 'is_user_team',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  LicenceStateOfIssue = 'licence_state_of_issue',
  /** column name */

  LicenseeContactId = 'licensee_contact_id',
  /** column name */

  LogoId = 'logo_id',
  /** column name */

  LogoUrl = 'logo_url',
  /** column name */

  MainContactId = 'main_contact_id',
  /** column name */

  Name = 'name',
  /** column name */

  NswApiVerify = 'nsw_api_verify',
  /** column name */

  Phone = 'phone',
  /** column name */

  PrimaryContactSfId = 'primary_contact_sf_id',
  /** column name */

  RegisteredForGst = 'registered_for_gst',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Website = 'website',
}

/** select "organisation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organisation" */
export enum Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsSetup = 'is_setup',
  /** column name */

  IsUserTeam = 'is_user_team',
  /** column name */

  RegisteredForGst = 'registered_for_gst',
}

/** select "organisation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organisation" */
export enum Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsSetup = 'is_setup',
  /** column name */

  IsUserTeam = 'is_user_team',
  /** column name */

  RegisteredForGst = 'registered_for_gst',
}

/** unique or primary key constraints on table "organisation_type" */
export enum Organisation_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  OrganisationTypePkey = 'organisation_type_pkey',
}

export enum Organisation_Type_Enum {
  /** Agent */

  Agent = 'AGENT',
  /** Buyer Organisation. Every buyer belongs to such organisation. Multiple buyers can be a part of the same organisation when there are mutliple buyers on the same deal. */

  Buyer = 'BUYER',
  /** Buyer Portal */

  BuyerPortal = 'BUYER_PORTAL',
  /** Developer */

  Developer = 'DEVELOPER',
  /** Other */

  Other = 'OTHER',
  /** Solicitor */

  Solicitor = 'SOLICITOR',
  /** "Valuation / Bank" account type from Salesforce */

  ValuationBank = 'VALUATION_BANK',
  /** Vendor */

  Vendor = 'VENDOR',
}

/** select columns of table "organisation_type" */
export enum Organisation_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "organisation_type" */
export enum Organisation_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "organisation" */
export enum Organisation_Update_Column {
  /** column name */

  Abn = 'abn',
  /** column name */

  Acn = 'acn',
  /** column name */

  Address = 'address',
  /** column name */

  AddressCitySuburb = 'address_city_suburb',
  /** column name */

  AddressCountry = 'address_country',
  /** column name */

  AddressLine_1 = 'address_line_1',
  /** column name */

  AddressPostcode = 'address_postcode',
  /** column name */

  AddressState = 'address_state',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  CreatedByTeamId = 'created_by_team_id',
  /** column name */

  Email = 'email',
  /** column name */

  FaviconId = 'favicon_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsSetup = 'is_setup',
  /** column name */

  IsUserTeam = 'is_user_team',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  LicenceStateOfIssue = 'licence_state_of_issue',
  /** column name */

  LicenseeContactId = 'licensee_contact_id',
  /** column name */

  LogoId = 'logo_id',
  /** column name */

  LogoUrl = 'logo_url',
  /** column name */

  MainContactId = 'main_contact_id',
  /** column name */

  Name = 'name',
  /** column name */

  NswApiVerify = 'nsw_api_verify',
  /** column name */

  Phone = 'phone',
  /** column name */

  PrimaryContactSfId = 'primary_contact_sf_id',
  /** column name */

  RegisteredForGst = 'registered_for_gst',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Website = 'website',
}

/** unique or primary key constraints on table "partner_portal" */
export enum Partner_Portal_Constraint {
  /** unique or primary key constraint on columns "abn" */

  PartnerPortalAbnKey = 'partner_portal_abn_key',
  /** unique or primary key constraint on columns "id" */

  PartnerPortalPkey = 'partner_portal_pkey',
}

/** select columns of table "partner_portal" */
export enum Partner_Portal_Select_Column {
  /** column name */

  Abn = 'abn',
  /** column name */

  AgencyOrgId = 'agency_org_id',
  /** column name */

  CompanyAddressCitySuburb = 'company_address_city_suburb',
  /** column name */

  CompanyAddressCountry = 'company_address_country',
  /** column name */

  CompanyAddressLine_1 = 'company_address_line_1',
  /** column name */

  CompanyAddressPostcode = 'company_address_postcode',
  /** column name */

  CompanyAddressState = 'company_address_state',
  /** column name */

  CompanyName = 'company_name',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  InvitationId = 'invitation_id',
  /** column name */

  LicenceHolderAddressCitySuburb = 'licence_holder_address_city_suburb',
  /** column name */

  LicenceHolderAddressCountry = 'licence_holder_address_country',
  /** column name */

  LicenceHolderAddressLine_1 = 'licence_holder_address_line_1',
  /** column name */

  LicenceHolderAddressPostcode = 'licence_holder_address_postcode',
  /** column name */

  LicenceHolderAddressState = 'licence_holder_address_state',
  /** column name */

  LicenceHolderContactId = 'licence_holder_contact_id',
  /** column name */

  LicenceHolderEmail = 'licence_holder_email',
  /** column name */

  LicenceHolderFirstName = 'licence_holder_first_name',
  /** column name */

  LicenceHolderLastName = 'licence_holder_last_name',
  /** column name */

  LicenceHolderPhone = 'licence_holder_phone',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  MainContactEmail = 'main_contact_email',
  /** column name */

  MainContactFirstName = 'main_contact_first_name',
  /** column name */

  MainContactId = 'main_contact_id',
  /** column name */

  MainContactLastName = 'main_contact_last_name',
  /** column name */

  MainContactPhone = 'main_contact_phone',
  /** column name */

  NewAgencyOrgId = 'new_agency_org_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "partner_portal" */
export enum Partner_Portal_Update_Column {
  /** column name */

  Abn = 'abn',
  /** column name */

  AgencyOrgId = 'agency_org_id',
  /** column name */

  CompanyAddressCitySuburb = 'company_address_city_suburb',
  /** column name */

  CompanyAddressCountry = 'company_address_country',
  /** column name */

  CompanyAddressLine_1 = 'company_address_line_1',
  /** column name */

  CompanyAddressPostcode = 'company_address_postcode',
  /** column name */

  CompanyAddressState = 'company_address_state',
  /** column name */

  CompanyName = 'company_name',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Error = 'error',
  /** column name */

  Id = 'id',
  /** column name */

  InvitationId = 'invitation_id',
  /** column name */

  LicenceHolderAddressCitySuburb = 'licence_holder_address_city_suburb',
  /** column name */

  LicenceHolderAddressCountry = 'licence_holder_address_country',
  /** column name */

  LicenceHolderAddressLine_1 = 'licence_holder_address_line_1',
  /** column name */

  LicenceHolderAddressPostcode = 'licence_holder_address_postcode',
  /** column name */

  LicenceHolderAddressState = 'licence_holder_address_state',
  /** column name */

  LicenceHolderContactId = 'licence_holder_contact_id',
  /** column name */

  LicenceHolderEmail = 'licence_holder_email',
  /** column name */

  LicenceHolderFirstName = 'licence_holder_first_name',
  /** column name */

  LicenceHolderLastName = 'licence_holder_last_name',
  /** column name */

  LicenceHolderPhone = 'licence_holder_phone',
  /** column name */

  LicenceNumber = 'licence_number',
  /** column name */

  MainContactEmail = 'main_contact_email',
  /** column name */

  MainContactFirstName = 'main_contact_first_name',
  /** column name */

  MainContactId = 'main_contact_id',
  /** column name */

  MainContactLastName = 'main_contact_last_name',
  /** column name */

  MainContactPhone = 'main_contact_phone',
  /** column name */

  NewAgencyOrgId = 'new_agency_org_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "payment_method" */
export enum Payment_Method_Constraint {
  /** unique or primary key constraint on columns "id" */

  PaymentMethodPkey1 = 'payment_method_pkey1',
}

/** select columns of table "payment_method" */
export enum Payment_Method_Select_Column {
  /** column name */

  BillerCode = 'biller_code',
  /** column name */

  Id = 'id',
  /** column name */

  PaymentDescription = 'payment_description',
  /** column name */

  Type = 'type',
}

/** unique or primary key constraints on table "payment_method_type" */
export enum Payment_Method_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  PaymentMethodTypePkey = 'payment_method_type_pkey',
}

export enum Payment_Method_Type_Enum {
  /** BPAY */

  Bpay = 'BPAY',
  /** Credit Card */

  CreditCard = 'CREDIT_CARD',
  /** Debit Card */

  DebitCard = 'DEBIT_CARD',
  /** OTHER */

  Other = 'OTHER',
  /** Paypal */

  Paypal = 'PAYPAL',
  /** Stripe */

  Stripe = 'STRIPE',
}

/** select columns of table "payment_method_type" */
export enum Payment_Method_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "payment_method_type" */
export enum Payment_Method_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "payment_method" */
export enum Payment_Method_Update_Column {
  /** column name */

  BillerCode = 'biller_code',
  /** column name */

  Id = 'id',
  /** column name */

  PaymentDescription = 'payment_description',
  /** column name */

  Type = 'type',
}

/** unique or primary key constraints on table "preference" */
export enum Preference_Constraint {
  /** unique or primary key constraint on columns "id" */

  PreferencePkey = 'preference_pkey',
}

/** select columns of table "preference" */
export enum Preference_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  Priority = 'priority',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "preference" */
export enum Preference_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  Priority = 'priority',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  PropertyId = 'property_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "process" */
export enum Process_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProcessPkey = 'process_pkey',
}

/** select columns of table "process" */
export enum Process_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Description = 'description',
  /** column name */

  Id = 'id',
  /** column name */

  IsRunning = 'is_running',
  /** column name */

  Message = 'message',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UpdatedAtUnix = 'updated_at_unix',
}

/** unique or primary key constraints on table "process_status" */
export enum Process_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  ProcessStatusPkey = 'process_status_pkey',
}

export enum Process_Status_Enum {
  /** Error */

  Error = 'ERROR',
  /** In Progress */

  InProgress = 'IN_PROGRESS',
  /** Pending */

  Pending = 'PENDING',
  /** Processed */

  Processed = 'PROCESSED',
  /** Suppressed */

  Suppressed = 'SUPPRESSED',
  /** Undefined */

  Undefined = 'UNDEFINED',
}

/** select columns of table "process_status" */
export enum Process_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "process_status" */
export enum Process_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "process" */
export enum Process_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Description = 'description',
  /** column name */

  Id = 'id',
  /** column name */

  IsRunning = 'is_running',
  /** column name */

  Message = 'message',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UpdatedAtUnix = 'updated_at_unix',
}

/** select columns of table "project_apartment_level" */
export enum Project_Apartment_Level_Select_Column {
  /** column name */

  ApartmentCount = 'apartment_count',
  /** column name */

  AttachmentId = 'attachment_id',
  /** column name */

  BuildingId = 'building_id',
  /** column name */

  Level = 'level',
  /** column name */

  LevelPlanUrl = 'level_plan_url',
  /** column name */

  ProjectId = 'project_id',
}

/** select columns of table "project_apartment_stat" */
export enum Project_Apartment_Stat_Select_Column {
  /** column name */

  BuildingId = 'building_id',
  /** column name */

  BuildingName = 'building_name',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  ProjectName = 'project_name',
  /** column name */

  TotalLots = 'total_lots',
  /** column name */

  TotalPrice = 'total_price',
}

/** select columns of table "project_client_count" */
export enum Project_Client_Count_Select_Column {
  /** column name */

  ClientCount = 'client_count',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  Type = 'type',
}

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
  /** unique or primary key constraint on columns "master_plan_attachment_id" */

  ProjectMasterPlanAttachmentIdKey = 'project_master_plan_attachment_id_key',
  /** unique or primary key constraint on columns "id" */

  ProjectPkey = 'project_pkey',
  /** unique or primary key constraint on columns "sf_id" */

  ProjectSfIdKey = 'project_sf_id_key',
}

/** unique or primary key constraints on table "project_detail" */
export enum Project_Detail_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProjectDetailPkey = 'project_detail_pkey',
  /** unique or primary key constraint on columns "project_id" */

  ProjectDetailProjectIdKey = 'project_detail_project_id_key',
}

/** select columns of table "project_detail" */
export enum Project_Detail_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DevelopmentAddress = 'development_address',
  /** column name */

  DevelopmentAddressPostalCode = 'development_address_postal_code',
  /** column name */

  DevelopmentAddressState = 'development_address_state',
  /** column name */

  DevelopmentAddressSuburb = 'development_address_suburb',
  /** column name */

  DevelopmentCountry = 'development_country',
  /** column name */

  EmailAddress = 'email_address',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  SuiteAddress = 'suite_address',
  /** column name */

  SuiteAddressPostalCode = 'suite_address_postal_code',
  /** column name */

  SuiteAddressState = 'suite_address_state',
  /** column name */

  SuiteAddressSuburb = 'suite_address_suburb',
  /** column name */

  SuiteCountry = 'suite_country',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  WebsiteAddress = 'website_address',
}

/** update columns of table "project_detail" */
export enum Project_Detail_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DevelopmentAddress = 'development_address',
  /** column name */

  DevelopmentAddressPostalCode = 'development_address_postal_code',
  /** column name */

  DevelopmentAddressState = 'development_address_state',
  /** column name */

  DevelopmentAddressSuburb = 'development_address_suburb',
  /** column name */

  DevelopmentCountry = 'development_country',
  /** column name */

  EmailAddress = 'email_address',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  SuiteAddress = 'suite_address',
  /** column name */

  SuiteAddressPostalCode = 'suite_address_postal_code',
  /** column name */

  SuiteAddressState = 'suite_address_state',
  /** column name */

  SuiteAddressSuburb = 'suite_address_suburb',
  /** column name */

  SuiteCountry = 'suite_country',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  WebsiteAddress = 'website_address',
}

/** unique or primary key constraints on table "project_features" */
export enum Project_Features_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProjectFeaturesPkey = 'project_features_pkey',
  /** unique or primary key constraint on columns "feature", "project_id" */

  ProjectFeaturesProjectIdFeatureKey = 'project_features_project_id_feature_key',
}

/** select columns of table "project_features" */
export enum Project_Features_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "project_features" */
export enum Project_Features_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Feature = 'feature',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "project_payment_method" */
export enum Project_Payment_Method_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProjectPaymentMethodPkey = 'project_payment_method_pkey',
  /** unique or primary key constraint on columns "project_id", "method" */

  ProjectPaymentMethodProjectIdMethodKey = 'project_payment_method_project_id_method_key',
}

/** select columns of table "project_payment_method" */
export enum Project_Payment_Method_Select_Column {
  /** column name */

  Config = 'config',
  /** column name */

  Id = 'id',
  /** column name */

  IsEnabled = 'is_enabled',
  /** column name */

  Method = 'method',
  /** column name */

  ProjectId = 'project_id',
}

/** select "project_payment_method_aggregate_bool_exp_bool_and_arguments_columns" columns of table "project_payment_method" */
export enum Project_Payment_Method_Select_Column_Project_Payment_Method_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsEnabled = 'is_enabled',
}

/** select "project_payment_method_aggregate_bool_exp_bool_or_arguments_columns" columns of table "project_payment_method" */
export enum Project_Payment_Method_Select_Column_Project_Payment_Method_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsEnabled = 'is_enabled',
}

/** update columns of table "project_payment_method" */
export enum Project_Payment_Method_Update_Column {
  /** column name */

  Config = 'config',
  /** column name */

  Id = 'id',
  /** column name */

  IsEnabled = 'is_enabled',
  /** column name */

  Method = 'method',
  /** column name */

  ProjectId = 'project_id',
}

/** unique or primary key constraints on table "project_qr_code_for_buyer" */
export enum Project_Qr_Code_For_Buyer_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProjectQrCodeForBuyerPkey = 'project_qr_code_for_buyer_pkey',
  /** unique or primary key constraint on columns "project_id", "agent_user_id", "agent_team_id" */

  ProjectQrCodeForBuyerProjectIdAgentUserIdAgentTeKey = 'project_qr_code_for_buyer_project_id_agent_user_id_agent_te_key',
}

/** select columns of table "project_qr_code_for_buyer" */
export enum Project_Qr_Code_For_Buyer_Select_Column {
  /** column name */

  AgentTeamId = 'agent_team_id',
  /** column name */

  AgentUserId = 'agent_user_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "project_qr_code_for_buyer" */
export enum Project_Qr_Code_For_Buyer_Update_Column {
  /** column name */

  AgentTeamId = 'agent_team_id',
  /** column name */

  AgentUserId = 'agent_user_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "project_reservation_setting" */
export enum Project_Reservation_Setting_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProjectReservationSettingPkey = 'project_reservation_setting_pkey',
  /** unique or primary key constraint on columns "project_id" */

  ProjectReservationSettingProjectIdKey = 'project_reservation_setting_project_id_key',
}

/** select columns of table "project_reservation_setting" */
export enum Project_Reservation_Setting_Select_Column {
  /** column name */

  AgentHoldingDepositContent = 'agent_holding_deposit_content',
  /** column name */

  AgentReviewSummaryContent = 'agent_review_summary_content',
  /** column name */

  BuyerHoldingDepositContent = 'buyer_holding_deposit_content',
  /** column name */

  BuyerReviewSummaryContent = 'buyer_review_summary_content',
  /** column name */

  BuyerSignContractContent = 'buyer_sign_contract_content',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "project_reservation_setting" */
export enum Project_Reservation_Setting_Update_Column {
  /** column name */

  AgentHoldingDepositContent = 'agent_holding_deposit_content',
  /** column name */

  AgentReviewSummaryContent = 'agent_review_summary_content',
  /** column name */

  BuyerHoldingDepositContent = 'buyer_holding_deposit_content',
  /** column name */

  BuyerReviewSummaryContent = 'buyer_review_summary_content',
  /** column name */

  BuyerSignContractContent = 'buyer_sign_contract_content',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "project" */
export enum Project_Select_Column {
  /** column name */

  ActiveProject = 'active_project',
  /** column name */

  BackgroundAttachmentId = 'background_attachment_id',
  /** column name */

  BrandBgColor = 'brand_bg_color',
  /** column name */

  BrandFgColor = 'brand_fg_color',
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  Country = 'country',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  DefaultHeadAgencyId = 'default_head_agency_id',
  /** column name */

  DelegatedBy = 'delegated_by',
  /** column name */

  Description = 'description',
  /** column name */

  DomainAgencyId = 'domain_agency_id',
  /** column name */

  DomainProjectId = 'domain_project_id',
  /** column name */

  DomainVerificationCode = 'domain_verification_code',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  Fake = 'fake',
  /** column name */

  HoldingDeposit = 'holding_deposit',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  Id = 'id',
  /** column name */

  IsUseVariableHoldingDeposit = 'is_use_variable_holding_deposit',
  /** column name */

  LogoAttachmentId = 'logo_attachment_id',
  /** column name */

  MapLatLng = 'map_lat_lng',
  /** column name */

  MasterPlanAttachmentId = 'master_plan_attachment_id',
  /** column name */

  Name = 'name',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  PaymentInfo = 'payment_info',
  /** column name */

  PaymentMethodId = 'payment_method_id',
  /** column name */

  Postcode = 'postcode',
  /** column name */

  ProjectDirectorContactId = 'project_director_contact_id',
  /** column name */

  SalesAdviceVendor = 'sales_advice_vendor',
  /** column name */

  SettingHoldingHours = 'setting_holding_hours',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  State = 'state',
  /** column name */

  Status = 'status',
  /** column name */

  SubDescription = 'sub_description',
  /** column name */

  Suburb = 'suburb',
  /** column name */

  TotalDepositPercentage = 'total_deposit_percentage',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VariableHoldingDepositPercentage = 'variable_holding_deposit_percentage',
  /** column name */

  VendorSolicitorContactId = 'vendor_solicitor_contact_id',
  /** column name */

  VendorSolicitorId = 'vendor_solicitor_id',
}

/** select "project_aggregate_bool_exp_bool_and_arguments_columns" columns of table "project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  ActiveProject = 'active_project',
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  Fake = 'fake',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  IsUseVariableHoldingDeposit = 'is_use_variable_holding_deposit',
}

/** select "project_aggregate_bool_exp_bool_or_arguments_columns" columns of table "project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  ActiveProject = 'active_project',
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  Fake = 'fake',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  IsUseVariableHoldingDeposit = 'is_use_variable_holding_deposit',
}

/** unique or primary key constraints on table "project_sources" */
export enum Project_Sources_Constraint {
  /** unique or primary key constraint on columns "id" */

  ProjectSourcesPkey = 'project_sources_pkey',
}

/** select columns of table "project_sources" */
export enum Project_Sources_Select_Column {
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
}

/** update columns of table "project_sources" */
export enum Project_Sources_Update_Column {
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
}

/** unique or primary key constraints on table "project_status" */
export enum Project_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  ProjectStatusPkey = 'project_status_pkey',
}

export enum Project_Status_Enum {
  /** Project is archived and is hidden from the Projects page, can be opened via show Archived link */

  Archived = 'ARCHIVED',
  /** Project is available */

  Available = 'AVAILABLE',
  /** Project is for sale */

  ForSale = 'FOR_SALE',
}

/** select columns of table "project_status" */
export enum Project_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "project_status" */
export enum Project_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "project_type" */
export enum Project_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  ProjectTypePkey = 'project_type_pkey',
}

export enum Project_Type_Enum {
  /** Apartment */

  Apartment = 'APARTMENT',
  /** House and Land */

  Houseandland = 'HOUSEANDLAND',
  /** Land */

  Land = 'LAND',
  /** Townhouse */

  Townhouse = 'TOWNHOUSE',
}

/** select columns of table "project_type" */
export enum Project_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "project_type" */
export enum Project_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** select columns of table "project_units" */
export enum Project_Units_Select_Column {
  /** column name */

  BgThumbnailUrl = 'bg_thumbnail_url',
  /** column name */

  BgUrl = 'bg_url',
  /** column name */

  BrandBgColor = 'brand_bg_color',
  /** column name */

  BrandFgColor = 'brand_fg_color',
  /** column name */

  DelegatedBy = 'delegated_by',
  /** column name */

  Description = 'description',
  /** column name */

  DevOrgLogoUrl = 'dev_org_logo_url',
  /** column name */

  DevOrgName = 'dev_org_name',
  /** column name */

  ListingAvailableCount = 'listing_available_count',
  /** column name */

  ListingCount = 'listing_count',
  /** column name */

  LogoThumbnailUrl = 'logo_thumbnail_url',
  /** column name */

  LogoThumbnailUrlX2 = 'logo_thumbnail_url_x2',
  /** column name */

  LogoUrl = 'logo_url',
  /** column name */

  ManagedDevLogoUrl = 'managed_dev_logo_url',
  /** column name */

  ManagedDevName = 'managed_dev_name',
  /** column name */

  Name = 'name',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
}

/** update columns of table "project" */
export enum Project_Update_Column {
  /** column name */

  ActiveProject = 'active_project',
  /** column name */

  BackgroundAttachmentId = 'background_attachment_id',
  /** column name */

  BrandBgColor = 'brand_bg_color',
  /** column name */

  BrandFgColor = 'brand_fg_color',
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  Country = 'country',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  CreatedBy = 'created_by',
  /** column name */

  DefaultHeadAgencyId = 'default_head_agency_id',
  /** column name */

  DelegatedBy = 'delegated_by',
  /** column name */

  Description = 'description',
  /** column name */

  DomainAgencyId = 'domain_agency_id',
  /** column name */

  DomainProjectId = 'domain_project_id',
  /** column name */

  DomainVerificationCode = 'domain_verification_code',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  Fake = 'fake',
  /** column name */

  HoldingDeposit = 'holding_deposit',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  Id = 'id',
  /** column name */

  IsUseVariableHoldingDeposit = 'is_use_variable_holding_deposit',
  /** column name */

  LogoAttachmentId = 'logo_attachment_id',
  /** column name */

  MapLatLng = 'map_lat_lng',
  /** column name */

  MasterPlanAttachmentId = 'master_plan_attachment_id',
  /** column name */

  Name = 'name',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  PaymentInfo = 'payment_info',
  /** column name */

  PaymentMethodId = 'payment_method_id',
  /** column name */

  Postcode = 'postcode',
  /** column name */

  ProjectDirectorContactId = 'project_director_contact_id',
  /** column name */

  SalesAdviceVendor = 'sales_advice_vendor',
  /** column name */

  SettingHoldingHours = 'setting_holding_hours',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  State = 'state',
  /** column name */

  Status = 'status',
  /** column name */

  SubDescription = 'sub_description',
  /** column name */

  Suburb = 'suburb',
  /** column name */

  TotalDepositPercentage = 'total_deposit_percentage',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VariableHoldingDepositPercentage = 'variable_holding_deposit_percentage',
  /** column name */

  VendorSolicitorContactId = 'vendor_solicitor_contact_id',
  /** column name */

  VendorSolicitorId = 'vendor_solicitor_id',
}

/** select columns of table "property_allocation_count" */
export enum Property_Allocation_Count_Select_Column {
  /** column name */

  Count = 'count',
  /** column name */

  Id = 'id',
}

/** select columns of table "property_allocation" */
export enum Property_Allocation_Select_Column {
  /** column name */

  AllocatedAt = 'allocated_at',
  /** column name */

  AllocatedToOrgId = 'allocated_to_org_id',
  /** column name */

  AllocatedToOrgName = 'allocated_to_org_name',
  /** column name */

  AllocationType = 'allocation_type',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
}

/** unique or primary key constraints on table "property_completion_status" */
export enum Property_Completion_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  PropertyCompletionStatusPkey = 'property_completion_status_pkey',
}

export enum Property_Completion_Status_Enum {
  /** Completed */

  Completed = 'COMPLETED',
  /** Off the plan */

  OffThePlan = 'OFF_THE_PLAN',
  /** Registered */

  Registered = 'REGISTERED',
  /** Unregistered */

  Unregistered = 'UNREGISTERED',
}

/** select columns of table "property_completion_status" */
export enum Property_Completion_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "property_completion_status" */
export enum Property_Completion_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "property" */
export enum Property_Constraint {
  /** unique or primary key constraint on columns "id" */

  PropertyPkey = 'property_pkey',
  /** unique or primary key constraint on columns "property_group_id", "project_id", "name" */

  PropertyProjectIdNamePropertyGroupIdKey = 'property_project_id_name_property_group_id_key',
  /** unique or primary key constraint on columns "sf_id" */

  PropertySfIdKey = 'property_sf_id_key',
}

/** select columns of table "property_detail" */
export enum Property_Detail_Select_Column {
  /** column name */

  AccessType = 'access_type',
  /** column name */

  AllocationAgencyCount = 'allocation_agency_count',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  Status = 'status',
  /** column name */

  Type = 'type',
}

/** select columns of table "property_group_allocation_detail_view" */
export enum Property_Group_Allocation_Detail_View_Select_Column {
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  ProjectAccessActive = 'project_access_active',
  /** column name */

  ProjectAllocationId = 'project_allocation_id',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  StageAccessActive = 'stage_access_active',
  /** column name */

  StageAllocationId = 'stage_allocation_id',
  /** column name */

  StageIncludeListings = 'stage_include_listings',
}

/** select columns of table "property_group_allocation_view" */
export enum Property_Group_Allocation_View_Select_Column {
  /** column name */

  ExclusiveCount = 'exclusive_count',
  /** column name */

  OpenCount = 'open_count',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  SharedCount = 'shared_count',
  /** column name */

  StageId = 'stage_id',
  /** column name */

  StageName = 'stage_name',
  /** column name */

  StageParentId = 'stage_parent_id',
}

/** unique or primary key constraints on table "property_group_completion_status" */
export enum Property_Group_Completion_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  PropertyGroupCompletionStatusPkey = 'property_group_completion_status_pkey',
}

export enum Property_Group_Completion_Status_Enum {
  /** Finished */

  Finished = 'FINISHED',
  /** In Development */

  InDevelopment = 'IN_DEVELOPMENT',
  /** To Be Announced */

  ToBeAnnounced = 'TO_BE_ANNOUNCED',
}

/** select columns of table "property_group_completion_status" */
export enum Property_Group_Completion_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "property_group_completion_status" */
export enum Property_Group_Completion_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "property_group" */
export enum Property_Group_Constraint {
  /** unique or primary key constraint on columns "project_id", "name" */

  PropertyGroupNameProjectIdKey = 'property_group_name_project_id_key',
  /** unique or primary key constraint on columns "id" */

  PropertyGroupPkey = 'property_group_pkey',
  /** unique or primary key constraint on columns "plan_attachment_id" */

  PropertyGroupPlanAttachmentIdKey = 'property_group_plan_attachment_id_key',
  /** unique or primary key constraint on columns "sf_id" */

  PropertyGroupSfIdKey = 'property_group_sf_id_key',
}

/** select columns of table "property_group" */
export enum Property_Group_Select_Column {
  /** column name */

  Address = 'address',
  /** column name */

  AgencyAgreementSettings = 'agency_agreement_settings',
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  Completion = 'completion',
  /** column name */

  CompletionStatus = 'completion_status',
  /** column name */

  ConstructionStart = 'construction_start',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DigitalContractSettings = 'digital_contract_settings',
  /** column name */

  EnableReservation = 'enable_reservation',
  /** column name */

  EnableSettlementStatus = 'enable_settlement_status',
  /** column name */

  EnabledRegistration = 'enabled_registration',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  HoldingDeposit = 'holding_deposit',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  Id = 'id',
  /** column name */

  Latitude = 'latitude',
  /** column name */

  Longitude = 'longitude',
  /** column name */

  Name = 'name',
  /** column name */

  ParentId = 'parent_id',
  /** column name */

  PlanAttachmentId = 'plan_attachment_id',
  /** column name */

  PreferenceEnabled = 'preference_enabled',
  /** column name */

  PriceFromRange = 'price_from_range',
  /** column name */

  PriceToRange = 'price_to_range',
  /** column name */

  ProductType = 'product_type',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  ShowPriceRange = 'show_price_range',
  /** column name */

  ShowPricing = 'show_pricing',
  /** column name */

  StreetName = 'street_name',
  /** column name */

  StreetNumber = 'street_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VendorId = 'vendor_id',
  /** column name */

  VendorSolicitorId = 'vendor_solicitor_id',
}

/** select "property_group_aggregate_bool_exp_bool_and_arguments_columns" columns of table "property_group" */
export enum Property_Group_Select_Column_Property_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  EnableReservation = 'enable_reservation',
  /** column name */

  EnableSettlementStatus = 'enable_settlement_status',
  /** column name */

  EnabledRegistration = 'enabled_registration',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  PreferenceEnabled = 'preference_enabled',
  /** column name */

  ShowPriceRange = 'show_price_range',
  /** column name */

  ShowPricing = 'show_pricing',
}

/** select "property_group_aggregate_bool_exp_bool_or_arguments_columns" columns of table "property_group" */
export enum Property_Group_Select_Column_Property_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  EnableReservation = 'enable_reservation',
  /** column name */

  EnableSettlementStatus = 'enable_settlement_status',
  /** column name */

  EnabledRegistration = 'enabled_registration',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  PreferenceEnabled = 'preference_enabled',
  /** column name */

  ShowPriceRange = 'show_price_range',
  /** column name */

  ShowPricing = 'show_pricing',
}

/** unique or primary key constraints on table "property_group_type" */
export enum Property_Group_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  PropertyGroupTypePkey = 'property_group_type_pkey',
}

export enum Property_Group_Type_Enum {
  /** Building */

  Building = 'BUILDING',
  /** Stage */

  Stage = 'STAGE',
  /** Sub Stage */

  SubStage = 'SUB_STAGE',
}

/** select columns of table "property_group_type" */
export enum Property_Group_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "property_group_type" */
export enum Property_Group_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "property_group" */
export enum Property_Group_Update_Column {
  /** column name */

  Address = 'address',
  /** column name */

  AgencyAgreementSettings = 'agency_agreement_settings',
  /** column name */

  CanPublish = 'can_publish',
  /** column name */

  Completion = 'completion',
  /** column name */

  CompletionStatus = 'completion_status',
  /** column name */

  ConstructionStart = 'construction_start',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DigitalContractSettings = 'digital_contract_settings',
  /** column name */

  EnableReservation = 'enable_reservation',
  /** column name */

  EnableSettlementStatus = 'enable_settlement_status',
  /** column name */

  EnabledRegistration = 'enabled_registration',
  /** column name */

  EoiEnabled = 'eoi_enabled',
  /** column name */

  HoldingDeposit = 'holding_deposit',
  /** column name */

  HoldingEnabled = 'holding_enabled',
  /** column name */

  Id = 'id',
  /** column name */

  Latitude = 'latitude',
  /** column name */

  Longitude = 'longitude',
  /** column name */

  Name = 'name',
  /** column name */

  ParentId = 'parent_id',
  /** column name */

  PlanAttachmentId = 'plan_attachment_id',
  /** column name */

  PreferenceEnabled = 'preference_enabled',
  /** column name */

  PriceFromRange = 'price_from_range',
  /** column name */

  PriceToRange = 'price_to_range',
  /** column name */

  ProductType = 'product_type',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  ShowPriceRange = 'show_price_range',
  /** column name */

  ShowPricing = 'show_pricing',
  /** column name */

  StreetName = 'street_name',
  /** column name */

  StreetNumber = 'street_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  VendorId = 'vendor_id',
  /** column name */

  VendorSolicitorId = 'vendor_solicitor_id',
}

/** select columns of table "property" */
export enum Property_Select_Column {
  /** column name */

  Address = 'address',
  /** column name */

  Aspect = 'aspect',
  /** column name */

  Bathroom = 'bathroom',
  /** column name */

  Bedroom = 'bedroom',
  /** column name */

  CompletionStatus = 'completion_status',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  EnableUnitPreferences = 'enable_unit_preferences',
  /** column name */

  ExternalArea = 'external_area',
  /** column name */

  FloorPlanId = 'floor_plan_id',
  /** column name */

  Garage = 'garage',
  /** column name */

  Id = 'id',
  /** column name */

  InternalArea = 'internal_area',
  /** column name */

  Level = 'level',
  /** column name */

  LevelNumber = 'level_number',
  /** column name */

  LinenPlanId = 'linen_plan_id',
  /** column name */

  Lot = 'lot',
  /** column name */

  LotArea = 'lot_area',
  /** column name */

  LotDepth = 'lot_depth',
  /** column name */

  LotSuffix = 'lot_suffix',
  /** column name */

  LotWidth = 'lot_width',
  /** column name */

  Name = 'name',
  /** column name */

  ProductType = 'product_type',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Storage = 'storage',
  /** column name */

  Storeys = 'storeys',
  /** column name */

  StreetName = 'street_name',
  /** column name */

  StreetNumber = 'street_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "property_aggregate_bool_exp_bool_and_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  EnableUnitPreferences = 'enable_unit_preferences',
  /** column name */

  Storage = 'storage',
}

/** select "property_aggregate_bool_exp_bool_or_arguments_columns" columns of table "property" */
export enum Property_Select_Column_Property_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  EnableUnitPreferences = 'enable_unit_preferences',
  /** column name */

  Storage = 'storage',
}

/** unique or primary key constraints on table "property_type" */
export enum Property_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  PropertyTypePkey = 'property_type_pkey',
}

export enum Property_Type_Enum {
  /** Apartment */

  Apartment = 'APARTMENT',
  /** Condo */

  Condo = 'CONDO',
  /** Duplex */

  Duplex = 'DUPLEX',
  /** House and Land */

  Houseandland = 'HOUSEANDLAND',
  /** Lot/Land */

  LotLand = 'LOT_LAND',
  /** Multi Family */

  MultiFamily = 'MULTI_FAMILY',
  /** Office */

  Office = 'OFFICE',
  /** Other */

  Other = 'OTHER',
  /** Parking */

  Parking = 'PARKING',
  /** Penthouse */

  Penthouse = 'PENTHOUSE',
  /** Retail */

  Retail = 'RETAIL',
  /** Single Family */

  SingleFamily = 'SINGLE_FAMILY',
  /** Special Purpose */

  SpecialPurpose = 'SPECIAL_PURPOSE',
  /** Studio */

  Studio = 'STUDIO',
  /** Townhouse */

  Townhouse = 'TOWNHOUSE',
  /** Villa */

  Villa = 'VILLA',
  /** Warehouse */

  Warehouse = 'WAREHOUSE',
}

/** select columns of table "property_type" */
export enum Property_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "property_type" */
export enum Property_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "property" */
export enum Property_Update_Column {
  /** column name */

  Address = 'address',
  /** column name */

  Aspect = 'aspect',
  /** column name */

  Bathroom = 'bathroom',
  /** column name */

  Bedroom = 'bedroom',
  /** column name */

  CompletionStatus = 'completion_status',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  EnableUnitPreferences = 'enable_unit_preferences',
  /** column name */

  ExternalArea = 'external_area',
  /** column name */

  FloorPlanId = 'floor_plan_id',
  /** column name */

  Garage = 'garage',
  /** column name */

  Id = 'id',
  /** column name */

  InternalArea = 'internal_area',
  /** column name */

  Level = 'level',
  /** column name */

  LevelNumber = 'level_number',
  /** column name */

  LinenPlanId = 'linen_plan_id',
  /** column name */

  Lot = 'lot',
  /** column name */

  LotArea = 'lot_area',
  /** column name */

  LotDepth = 'lot_depth',
  /** column name */

  LotSuffix = 'lot_suffix',
  /** column name */

  LotWidth = 'lot_width',
  /** column name */

  Name = 'name',
  /** column name */

  ProductType = 'product_type',
  /** column name */

  ProjectId = 'project_id',
  /** column name */

  PropertyGroupId = 'property_group_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Storage = 'storage',
  /** column name */

  Storeys = 'storeys',
  /** column name */

  StreetName = 'street_name',
  /** column name */

  StreetNumber = 'street_number',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "reservation" */
export enum Reservation_Constraint {
  /** unique or primary key constraint on columns "deal_id" */

  ReservationPkey = 'reservation_pkey',
}

/** select columns of table "reservation" */
export enum Reservation_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  StepConfig = 'step_config',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "reservation" */
export enum Reservation_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  ListingId = 'listing_id',
  /** column name */

  StepConfig = 'step_config',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "salesforce_action" */
export enum Salesforce_Action_Constraint {
  /** unique or primary key constraint on columns "value" */

  SalesforceActionPkey = 'salesforce_action_pkey',
}

export enum Salesforce_Action_Enum {
  /** Triggered by schedule when RELEASE_STOCK_ON_HOLD feature is enabled, and holding period is expired */

  AutoReleaseHold = 'AUTO_RELEASE_HOLD',
  /** Cancel deal feature in Reservation Wizard. Performs necessary actions to cancel the deal. */

  CancelDeal = 'CANCEL_DEAL',
  /** Client Registration: step 1. Ensures that AgentOrg exists, AgentContact exists, ClientContact exists */

  ClientRegistration = 'CLIENT_REGISTRATION',
  /** Client Registration: step 2. Creates new Enquiry with client purchase preferences. */

  ClientRegistrationEnquiry = 'CLIENT_REGISTRATION_ENQUIRY',
  /** When annature envelope was signed by all parties. Envelope completed webhook from annature */

  ContractExchanged = 'CONTRACT_EXCHANGED',
  /** When annture envelope was signed by all buyers and witness */

  ContractReturnedToVendor = 'CONTRACT_RETURNED_TO_VENDOR',
  /** Digital Contract was sent to purchaser. Usually when annature envelope was SENT */

  ContractSentToPurchaser = 'CONTRACT_SENT_TO_PURCHASER',
  /** When reserve from holding deposit page while convert EOI to Reserved */

  ConvertEoiToReservation = 'CONVERT_EOI_TO_RESERVATION',
  /** Creates new Agent Allocation in Salesforce. */

  CreateAgentAllocation = 'CREATE_AGENT_ALLOCATION',
  /** Creates new transaction for Enquiry in Salesforce */

  CreateDealTransaction = 'CREATE_DEAL_TRANSACTION',
  /** Creates variation from Salesforce */

  CreateDealVariation = 'CREATE_DEAL_VARIATION',
  /** Creates pba__Listing__c in Salesforce */

  CreateListing = 'CREATE_LISTING',
  /** Creates Project Allocation on Salesforce */

  CreateProjectAllocation = 'CREATE_PROJECT_ALLOCATION',
  /** Creates pba__Property__c in Salesforce */

  CreateProperty = 'CREATE_PROPERTY',
  /** Creates Stage Allocation on Salesforce */

  CreateStageAllocation = 'CREATE_STAGE_ALLOCATION',
  /** Creates Unit Allocation on Salesforce */

  CreateUnitAllocation = 'CREATE_UNIT_ALLOCATION',
  /** Executed when deal name on contract was changed via ProjectRE */

  DealNameOnContractUpdated = 'DEAL_NAME_ON_CONTRACT_UPDATED',
  /** Deletes Agent Allocation from Salesforce. */

  DeleteAgentAllocation = 'DELETE_AGENT_ALLOCATION',
  /** Deletes transaction from Salesforce */

  DeleteDealTransaction = 'DELETE_DEAL_TRANSACTION',
  /** Deletes variation from Salesforce */

  DeleteDealVariation = 'DELETE_DEAL_VARIATION',
  /** Deletes Project Allocation from Salesforce */

  DeleteProjectAllocation = 'DELETE_PROJECT_ALLOCATION',
  /** Deletes linked pba__Listing__c first then pba__Property__c from Salesforce */

  DeleteProperty = 'DELETE_PROPERTY',
  /** Deletes Stage Allocation from Salesforce */

  DeleteStageAllocation = 'DELETE_STAGE_ALLOCATION',
  /** Deletes Unit Allocation from Salesforce */

  DeleteUnitAllocation = 'DELETE_UNIT_ALLOCATION',
  /** When we receive web hook from Annature that digital contract war voided, we should update offer status in Salesforce */

  DigitalContractVoided = 'DIGITAL_CONTRACT_VOIDED',
  /** Up-Sync all listing fields to Salesforce */

  EditListing = 'EDIT_LISTING',
  /** When we change price of the listing via Edit feature of the Price List (edit_listing_price hasura action) */

  EditListingPrice = 'EDIT_LISTING_PRICE',
  /** When we edit status of the listing via Price List's Edit feature. (edit_listing_status hasura action) We update status on Salesforce */

  EditListingStatus = 'EDIT_LISTING_STATUS',
  /** Up-Sync all property fields to Salesforce */

  EditProperty = 'EDIT_PROPERTY',
  /** Enquiry we send via Enquiry Form of the embed */

  EmbedEnquiry = 'EMBED_ENQUIRY',
  /** Annature envelope was opened by the first buyer */

  EnvelopeOpenedByBuyer = 'ENVELOPE_OPENED_BY_BUYER',
  /** Executed when holding deposit was paid via autometed payment system */

  HoldingDepositPaid = 'HOLDING_DEPOSIT_PAID',
  /** Puts listing on hold, so nobody can reserve it, except agent who put listing on hold */

  HoldListing = 'HOLD_LISTING',
  /** Moves pba__Listing__c and pba__Property__c to a new building/stage/project */

  MoveProperty = 'MOVE_PROPERTY',
  /** Reservation, step 1: Client Information. Ensures that AgentOrg exists, AgentContact exists, ClientContact exists and then creates new Enquiry */

  RegisterEnquiry = 'REGISTER_ENQUIRY',
  /** Releases listing which was previously put on hold via HOLD_LISTING action. */

  ReleaseHold = 'RELEASE_HOLD',
  /** Creates request refund for Salesforce */

  RequestRefund = 'REQUEST_REFUND',
  /** Reservation wizard, step 4: Performs necessary actions to reserve Listing/Enquiry/Offer */

  ReserveDeal = 'RESERVE_DEAL',
  /** Update contact ID verification status in Salesforce */

  SetContactIdVerificationStatus = 'SET_CONTACT_ID_VERIFICATION_STATUS',
  /** Set a new comment on the deal which goes to pba__Enquiry__c.Comment__c */

  SetDealComment = 'SET_DEAL_COMMENT',
  /** Reads contact details in ProjectRE and pushes them to Salesforce */

  UpdateContact = 'UPDATE_CONTACT',
  /** When amount due by updated from sales contract page by admin */

  UpdateDealAmountDueBy = 'UPDATE_DEAL_AMOUNT_DUE_BY',
  /** Execute when deal transaction created and updated or deleted */

  UpdateDealBalanceOfDeposit = 'UPDATE_DEAL_BALANCE_OF_DEPOSIT',
  /** Reservation wizard, step 3: Updates Enquiry/Offer with contract details */

  UpdateDealContract = 'UPDATE_DEAL_CONTRACT',
  /** Execute when deal total deposit percentage updated */

  UpdateDealDepositPercentage = 'UPDATE_DEAL_DEPOSIT_PERCENTAGE',
  /** When Deposit paid and transaction created or updated with paid status */

  UpdateDealDepositReceivedDate = 'UPDATE_DEAL_DEPOSIT_RECEIVED_DATE',
  /** Executed when holding deposit was changed via sales contract page by admin */

  UpdateDealHoldingDeposit = 'UPDATE_DEAL_HOLDING_DEPOSIT',
  /** Updates Pre-Settlement Inspection Booking related fields of pba__offer__c */

  UpdateDealPreSettlementInspectionBooking = 'UPDATE_DEAL_PRE_SETTLEMENT_INSPECTION_BOOKING',
  /** Updates Settlement Outlook related fields , date fields. See sales contract Settlement Outlook section */

  UpdateDealSettlementOutlook = 'UPDATE_DEAL_SETTLEMENT_OUTLOOK',
  /** Updates Settlement Status related fields , date fields. See sales contract Settlement Status section */

  UpdateDealSettlementStatus = 'UPDATE_DEAL_SETTLEMENT_STATUS',
  /** Reservation, step 2: Update Solicitor info. Ensures that SolicitorOrg exists, SolicitorContact exists and then updates given Enquiry/Offer */

  UpdateDealSolicitor = 'UPDATE_DEAL_SOLICITOR',
  /** Executed when deal status was changed via sales contract page by admin */

  UpdateDealStatusChange = 'UPDATE_DEAL_STATUS_CHANGE',
  /** Executed when deal terms was changed via sales contract page by admin */

  UpdateDealTerms = 'UPDATE_DEAL_TERMS',
  /** Updates Valuation Booking related fields in Salesforce */

  UpdateDealValuationBooking = 'UPDATE_DEAL_VALUATION_BOOKING',
  /** Updates variation from Salesforce */

  UpdateDealVariation = 'UPDATE_DEAL_VARIATION',
  /** Updates BPay CRN and Payment Method Plan for Enquiry in Salesforce */

  UpdateEnquiryBpay = 'UPDATE_ENQUIRY_BPAY',
  /** Tries to update org details in Salesforce in Account object */

  UpdateOrg = 'UPDATE_ORG',
  /** Updates Project Allocation on Salesforce */

  UpdateProjectAllocation = 'UPDATE_PROJECT_ALLOCATION',
  /** Updates Stage Allocation on Salesforce */

  UpdateStageAllocation = 'UPDATE_STAGE_ALLOCATION',
  /** Updates Unit Allocation on Salesforce */

  UpdateUnitAllocation = 'UPDATE_UNIT_ALLOCATION',
  /** Updates existing contact or creates new one */

  UpsertContact = 'UPSERT_CONTACT',
  /** Updates existing org or creates new one */

  UpsertOrg = 'UPSERT_ORG',
  /** Process will wait for 10 seconds. Used for testing purposes. No Salesforce operations will be executed during this time. */

  Wait_10 = 'WAIT_10',
  /** Process will wait for 30 seconds. Used for testing purposes. No Salesforce operations will be executed during this time. */

  Wait_30 = 'WAIT_30',
  /** Process will wait for 60 seconds. Used for testing purposes. No Salesforce operations will be executed during this time. */

  Wait_60 = 'WAIT_60',
}

/** select columns of table "salesforce_action" */
export enum Salesforce_Action_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "salesforce_action_status" */
export enum Salesforce_Action_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  SalesforceActionStatusPkey = 'salesforce_action_status_pkey',
}

export enum Salesforce_Action_Status_Enum {
  /** Action returned error */

  Error = 'ERROR',
  /** Action is currently being processed */

  InProgress = 'IN_PROGRESS',
  /** Action is waiting to be processed */

  Pending = 'PENDING',
  /** Action was completed successfully */

  Success = 'SUCCESS',
}

/** select columns of table "salesforce_action_status" */
export enum Salesforce_Action_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "salesforce_action_status" */
export enum Salesforce_Action_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "salesforce_action" */
export enum Salesforce_Action_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "salesforce_bulk_import" */
export enum Salesforce_Bulk_Import_Constraint {
  /** unique or primary key constraint on columns "id" */

  SalesforceBulkImportPkey = 'salesforce_bulk_import_pkey',
}

/** select columns of table "salesforce_bulk_import" */
export enum Salesforce_Bulk_Import_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  PageSize = 'page_size',
  /** column name */

  Payload = 'payload',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  SfObjectName = 'sf_object_name',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "salesforce_bulk_import" */
export enum Salesforce_Bulk_Import_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  PageSize = 'page_size',
  /** column name */

  Payload = 'payload',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  SfObjectName = 'sf_object_name',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "salesforce_connection" */
export enum Salesforce_Connection_Constraint {
  /** unique or primary key constraint on columns "instance_url" */

  SalesforceConnectionInstanceUrlKey = 'salesforce_connection_instance_url_key',
  /** unique or primary key constraint on columns "access_token" */

  SalesforceConnectionPkey = 'salesforce_connection_pkey',
  /** unique or primary key constraint on columns "refresh_token" */

  SalesforceConnectionRefreshTokenKey = 'salesforce_connection_refresh_token_key',
}

/** select columns of table "salesforce_connection" */
export enum Salesforce_Connection_Select_Column {
  /** column name */

  AccessToken = 'access_token',
  /** column name */

  InstanceUrl = 'instance_url',
  /** column name */

  RefreshToken = 'refresh_token',
}

/** update columns of table "salesforce_connection" */
export enum Salesforce_Connection_Update_Column {
  /** column name */

  AccessToken = 'access_token',
  /** column name */

  InstanceUrl = 'instance_url',
  /** column name */

  RefreshToken = 'refresh_token',
}

/** unique or primary key constraints on table "salesforce_sync_status" */
export enum Salesforce_Sync_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  SalesforceSyncStatusPkey = 'salesforce_sync_status_pkey',
}

/** select columns of table "salesforce_sync_status" */
export enum Salesforce_Sync_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "salesforce_sync_status" */
export enum Salesforce_Sync_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "settings" */
export enum Settings_Constraint {
  /** unique or primary key constraint on columns "key" */

  SettingsPkey = 'settings_pkey',
}

/** select columns of table "settings" */
export enum Settings_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Key = 'key',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** update columns of table "settings" */
export enum Settings_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Key = 'key',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "sf_action" */
export enum Sf_Action_Constraint {
  /** unique or primary key constraint on columns "id" */

  SfActionPkey = 'sf_action_pkey',
}

/** select columns of table "sf_action" */
export enum Sf_Action_Select_Column {
  /** column name */

  Action = 'action',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  Payload = 'payload',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "sf_action" */
export enum Sf_Action_Update_Column {
  /** column name */

  Action = 'action',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Message = 'message',
  /** column name */

  Payload = 'payload',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  Status = 'status',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "sf_contact" */
export enum Sf_Contact_Constraint {
  /** unique or primary key constraint on columns "id" */

  SfContactPkey = 'sf_contact_pkey',
  /** unique or primary key constraint on columns "contact_id", "sf_instance_id" */

  SfContactSfInstanceIdContactIdKey = 'sf_contact_sf_instance_id_contact_id_key',
}

/** select columns of table "sf_contact" */
export enum Sf_Contact_Select_Column {
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "sf_contact" */
export enum Sf_Contact_Update_Column {
  /** column name */

  ContactId = 'contact_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "sf_organisation" */
export enum Sf_Organisation_Constraint {
  /** unique or primary key constraint on columns "sf_instance_id", "organisation_id" */

  SfOrganisationOrganisationIdSfInstanceIdKey = 'sf_organisation_organisation_id_sf_instance_id_key',
  /** unique or primary key constraint on columns "id" */

  SfOrganisationPkey = 'sf_organisation_pkey',
}

/** select columns of table "sf_organisation" */
export enum Sf_Organisation_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "sf_organisation" */
export enum Sf_Organisation_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  OrganisationId = 'organisation_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  UpdatedAt = 'updated_at',
}

export enum Solicitor_Orgs_Enum_Name {
  /** column name */

  ActiveDealCount = 'active_deal_count',
  /** column name */

  ContactCount = 'contact_count',
  /** column name */

  CrmName = 'crm_name',
  /** column name */

  DealCount = 'deal_count',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  ProjectCount = 'project_count',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  SfInstanceId = 'sf_instance_id',
  /** column name */

  Type = 'type',
}

/** unique or primary key constraints on table "spoken_language" */
export enum Spoken_Language_Constraint {
  /** unique or primary key constraint on columns "value" */

  SpokenLanguagePkey = 'spoken_language_pkey',
}

export enum Spoken_Language_Enum {
  /** Bengali */

  Bengali = 'BENGALI',
  /** Bhojpuri */

  Bhojpuri = 'BHOJPURI',
  /** Egyptian Arabic */

  EgyptianArabic = 'EGYPTIAN_ARABIC',
  /** English */

  English = 'ENGLISH',
  /** French */

  French = 'FRENCH',
  /** Gujarati */

  Gujarati = 'GUJARATI',
  /** Hausa */

  Hausa = 'HAUSA',
  /** Hindi */

  Hindi = 'HINDI',
  /** Iranian Persian */

  IranianPersian = 'IRANIAN_PERSIAN',
  /** Italian */

  Italian = 'ITALIAN',
  /** Japanese */

  Japanese = 'JAPANESE',
  /** Javanese */

  Javanese = 'JAVANESE',
  /** Korean */

  Korean = 'KOREAN',
  /** Levantine Arabic */

  LevantineArabic = 'LEVANTINE_ARABIC',
  /** Marathi */

  Marathi = 'MARATHI',
  /** Portuguese */

  Portuguese = 'PORTUGUESE',
  /** Russian */

  Russian = 'RUSSIAN',
  /** Southern Min */

  SouthernMin = 'SOUTHERN_MIN',
  /** Spanish */

  Spanish = 'SPANISH',
  /** Standard German */

  StandardGerman = 'STANDARD_GERMAN',
  /** Tamil */

  Tamil = 'TAMIL',
  /** Telugu */

  Telugu = 'TELUGU',
  /** Turkish */

  Turkish = 'TURKISH',
  /** Urdu */

  Urdu = 'URDU',
  /** Vietnamese */

  Vietnamese = 'VIETNAMESE',
  /** Western Punjabi */

  WesternPunjabi = 'WESTERN_PUNJABI',
  /** Wu Chinese */

  WuChinese = 'WU_CHINESE',
  /** Yue Chinese */

  YueChinese = 'YUE_CHINESE',
}

/** select columns of table "spoken_language" */
export enum Spoken_Language_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "spoken_language" */
export enum Spoken_Language_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "system" */
export enum System_Constraint {
  /** unique or primary key constraint on columns "name" */

  SystemNameKey = 'system_name_key',
  /** unique or primary key constraint on columns "id" */

  SystemPkey = 'system_pkey',
}

/** select columns of table "system" */
export enum System_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** update columns of table "system" */
export enum System_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Name = 'name',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "transaction" */
export enum Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */

  TransactionPkey = 'transaction_pkey',
}

/** select columns of table "transaction" */
export enum Transaction_Select_Column {
  /** column name */

  Amount = 'amount',
  /** column name */

  AttachmentOwner = 'attachment_owner',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DateTime = 'date_time',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsBulkImport = 'is_bulk_import',
  /** column name */

  Json = 'json',
  /** column name */

  Note = 'note',
  /** column name */

  OffPlatform = 'off_platform',
  /** column name */

  OriginId = 'origin_id',
  /** column name */

  OriginStatus = 'origin_status',
  /** column name */

  OriginType = 'origin_type',
  /** column name */

  PaidByContactId = 'paid_by_contact_id',
  /** column name */

  PaidToContactId = 'paid_to_contact_id',
  /** column name */

  PaymentMethod = 'payment_method',
  /** column name */

  PaymentUrl = 'payment_url',
  /** column name */

  ReceiptId = 'receipt_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Status = 'status',
  /** column name */

  SurchargeFee = 'surcharge_fee',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "transaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "transaction" */
export enum Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  IsBulkImport = 'is_bulk_import',
  /** column name */

  OffPlatform = 'off_platform',
}

/** select "transaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "transaction" */
export enum Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  IsBulkImport = 'is_bulk_import',
  /** column name */

  OffPlatform = 'off_platform',
}

/** unique or primary key constraints on table "transaction_status" */
export enum Transaction_Status_Constraint {
  /** unique or primary key constraint on columns "value" */

  TransactionStatusPkey = 'transaction_status_pkey',
}

export enum Transaction_Status_Enum {
  /** Approved */

  Approved = 'Approved',
  /** Cancelled */

  Cancelled = 'Cancelled',
  /** Created */

  Created = 'Created',
  /** Paid */

  Paid = 'Paid',
}

/** select columns of table "transaction_status" */
export enum Transaction_Status_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "transaction_status" */
export enum Transaction_Status_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "transaction_type" */
export enum Transaction_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  TransactionTypePkey = 'transaction_type_pkey',
}

export enum Transaction_Type_Enum {
  /** Contract Deposit */

  ContractDeposit = 'CONTRACT_DEPOSIT',
  /** Holding Deposit */

  HoldingDeposit = 'HOLDING_DEPOSIT',
  /** Should not be included in Holding or Contract deposit calculations */

  Investment = 'INVESTMENT',
  /** Transfer to Vendor */

  TransferToVendor = 'TRANSFER_TO_VENDOR',
  /** Transfer to Vendor Solicitor */

  TransferToVsol = 'TRANSFER_TO_VSOL',
}

/** select columns of table "transaction_type" */
export enum Transaction_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "transaction_type" */
export enum Transaction_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "transaction" */
export enum Transaction_Update_Column {
  /** column name */

  Amount = 'amount',
  /** column name */

  AttachmentOwner = 'attachment_owner',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DateTime = 'date_time',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Id = 'id',
  /** column name */

  IsBulkImport = 'is_bulk_import',
  /** column name */

  Json = 'json',
  /** column name */

  Note = 'note',
  /** column name */

  OffPlatform = 'off_platform',
  /** column name */

  OriginId = 'origin_id',
  /** column name */

  OriginStatus = 'origin_status',
  /** column name */

  OriginType = 'origin_type',
  /** column name */

  PaidByContactId = 'paid_by_contact_id',
  /** column name */

  PaidToContactId = 'paid_to_contact_id',
  /** column name */

  PaymentMethod = 'payment_method',
  /** column name */

  PaymentUrl = 'payment_url',
  /** column name */

  ReceiptId = 'receipt_id',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Status = 'status',
  /** column name */

  SurchargeFee = 'surcharge_fee',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select columns of table "unit_allocation_count" */
export enum Unit_Allocation_Count_Select_Column {
  /** column name */

  Count = 'count',
  /** column name */

  Id = 'id',
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "cognito_id" */

  UserCognitoIdKey = 'user_cognito_id_key',
  /** unique or primary key constraint on columns "email" */

  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "login_secret" */

  UserLoginSecretKey = 'user_login_secret_key',
  /** unique or primary key constraint on columns "phone_number" */

  UserPhoneNumberKey = 'user_phone_number_key',
  /** unique or primary key constraint on columns "id" */

  UserPkey = 'user_pkey',
}

/** unique or primary key constraints on table "user_experiment" */
export enum User_Experiment_Constraint {
  /** unique or primary key constraint on columns "user_id" */

  UserExperimentPkey = 'user_experiment_pkey',
}

/** select columns of table "user_experiment" */
export enum User_Experiment_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Experiment = 'experiment',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "user_experiment" */
export enum User_Experiment_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Experiment = 'experiment',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** unique or primary key constraints on table "user_feature" */
export enum User_Feature_Constraint {
  /** unique or primary key constraint on columns "value" */

  UserFeaturePkey = 'user_feature_pkey',
}

export enum User_Feature_Enum {
  /** Ability to manage allocations for selected projects */

  Allocation = 'ALLOCATION',
  /** Ability to access Commissions calculations. */

  Commission = 'COMMISSION',
  /** Ability to access Deposits */

  Deposits = 'DEPOSITS',
  /** Ability to access transactions */

  Transactions = 'TRANSACTIONS',
}

/** select columns of table "user_feature" */
export enum User_Feature_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "user_feature" */
export enum User_Feature_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "user_push_token" */
export enum User_Push_Token_Constraint {
  /** unique or primary key constraint on columns "id" */

  UserPushTokenPkey = 'user_push_token_pkey',
  /** unique or primary key constraint on columns "user_id", "token" */

  UserPushTokenUserIdTokenKey = 'user_push_token_user_id_token_key',
}

/** select columns of table "user_push_token" */
export enum User_Push_Token_Select_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Token = 'token',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** update columns of table "user_push_token" */
export enum User_Push_Token_Update_Column {
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  Id = 'id',
  /** column name */

  Token = 'token',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  UserId = 'user_id',
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */

  AllowedRoles = 'allowed_roles',
  /** column name */

  CodeTimestamp = 'code_timestamp',
  /** column name */

  CognitoId = 'cognito_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DefaultRole = 'default_role',
  /** column name */

  Email = 'email',
  /** column name */

  EmailVerified = 'email_verified',
  /** column name */

  FirstName = 'first_name',
  /** column name */

  Hash = 'hash',
  /** column name */

  Id = 'id',
  /** column name */

  ImpersonationRequestedAt = 'impersonation_requested_at',
  /** column name */

  IsEnable = 'is_enable',
  /** column name */

  IsSignUpNotificationSent = 'is_sign_up_notification_sent',
  /** column name */

  LastLoginAt = 'last_login_at',
  /** column name */

  LastName = 'last_name',
  /** column name */

  LinkLoginRequestedAt = 'link_login_requested_at',
  /** column name */

  LoginSecret = 'login_secret',
  /** column name */

  MiddleName = 'middle_name',
  /** column name */

  NotificationOwner = 'notification_owner',
  /** column name */

  PasswordHash = 'password_hash',
  /** column name */

  PhoneNumber = 'phone_number',
  /** column name */

  PhoneNumberVerified = 'phone_number_verified',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  TeamSwitchRequestedAt = 'team_switch_requested_at',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */

  EmailVerified = 'email_verified',
  /** column name */

  IsEnable = 'is_enable',
  /** column name */

  IsSignUpNotificationSent = 'is_sign_up_notification_sent',
  /** column name */

  PhoneNumberVerified = 'phone_number_verified',
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */

  EmailVerified = 'email_verified',
  /** column name */

  IsEnable = 'is_enable',
  /** column name */

  IsSignUpNotificationSent = 'is_sign_up_notification_sent',
  /** column name */

  PhoneNumberVerified = 'phone_number_verified',
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */

  AllowedRoles = 'allowed_roles',
  /** column name */

  CodeTimestamp = 'code_timestamp',
  /** column name */

  CognitoId = 'cognito_id',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DefaultRole = 'default_role',
  /** column name */

  Email = 'email',
  /** column name */

  EmailVerified = 'email_verified',
  /** column name */

  FirstName = 'first_name',
  /** column name */

  Hash = 'hash',
  /** column name */

  Id = 'id',
  /** column name */

  ImpersonationRequestedAt = 'impersonation_requested_at',
  /** column name */

  IsEnable = 'is_enable',
  /** column name */

  IsSignUpNotificationSent = 'is_sign_up_notification_sent',
  /** column name */

  LastLoginAt = 'last_login_at',
  /** column name */

  LastName = 'last_name',
  /** column name */

  LinkLoginRequestedAt = 'link_login_requested_at',
  /** column name */

  LoginSecret = 'login_secret',
  /** column name */

  MiddleName = 'middle_name',
  /** column name */

  NotificationOwner = 'notification_owner',
  /** column name */

  PasswordHash = 'password_hash',
  /** column name */

  PhoneNumber = 'phone_number',
  /** column name */

  PhoneNumberVerified = 'phone_number_verified',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  TeamId = 'team_id',
  /** column name */

  TeamSwitchRequestedAt = 'team_switch_requested_at',
  /** column name */

  UpdatedAt = 'updated_at',
}

/** unique or primary key constraints on table "variation_category" */
export enum Variation_Category_Constraint {
  /** unique or primary key constraint on columns "value" */

  VariationCategoryPkey = 'variation_category_pkey',
}

export enum Variation_Category_Enum {
  /** Construction */

  Construction = 'CONSTRUCTION',
  /** Contract */

  Contract = 'CONTRACT',
  /** Incentive */

  Incentive = 'INCENTIVE',
  /** Other */

  Other = 'OTHER',
  /** Waitlist */

  Waitlist = 'WAITLIST',
}

/** select columns of table "variation_category" */
export enum Variation_Category_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "variation_category" */
export enum Variation_Category_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "variation" */
export enum Variation_Constraint {
  /** unique or primary key constraint on columns "id" */

  VariationPkey = 'variation_pkey',
  /** unique or primary key constraint on columns "sf_id" */

  VariationSfIdKey = 'variation_sf_id_key',
}

/** select columns of table "variation" */
export enum Variation_Select_Column {
  /** column name */

  Approved = 'approved',
  /** column name */

  Category = 'category',
  /** column name */

  CommissionPayable = 'commission_payable',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DateApplied = 'date_applied',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  Id = 'id',
  /** column name */

  IsPassOnContractDeposit = 'is_pass_on_contract_deposit',
  /** column name */

  Name = 'name',
  /** column name */

  PassOnCost = 'pass_on_cost',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "variation_type_category" */
export enum Variation_Type_Category_Constraint {
  /** unique or primary key constraint on columns "id" */

  VariationTypeCategoryPkey = 'variation_type_category_pkey',
}

/** select columns of table "variation_type_category" */
export enum Variation_Type_Category_Select_Column {
  /** column name */

  CategoryValue = 'category_value',
  /** column name */

  Id = 'id',
  /** column name */

  TypeValue = 'type_value',
}

/** update columns of table "variation_type_category" */
export enum Variation_Type_Category_Update_Column {
  /** column name */

  CategoryValue = 'category_value',
  /** column name */

  Id = 'id',
  /** column name */

  TypeValue = 'type_value',
}

/** unique or primary key constraints on table "variation_type" */
export enum Variation_Type_Constraint {
  /** unique or primary key constraint on columns "value" */

  VariationTypePkey = 'variation_type_pkey',
}

export enum Variation_Type_Enum {
  /** Allowance Package */

  AllowancePackage = 'ALLOWANCE_PACKAGE',
  /** Bathroom */

  Bathroom = 'BATHROOM',
  /** Bedroom */

  Bedroom = 'BEDROOM',
  /** Blinds */

  Blinds = 'BLINDS',
  /** Carspace buy-back */

  CarspaceBuyBack = 'CARSPACE_BUY_BACK',
  /** Depreciation Schedule */

  DepreciationSchedule = 'DEPRECIATION_SCHEDULE',
  /** External */

  External = 'EXTERNAL',
  /** FHB Grant */

  FhbGrant = 'FHB_GRANT',
  /** Furniture */

  Furniture = 'FURNITURE',
  /** Interest */

  Interest = 'INTEREST',
  /** Kitchen */

  Kitchen = 'KITCHEN',
  /** Laundry */

  Laundry = 'LAUNDRY',
  /** Living */

  Living = 'LIVING',
  /** Other */

  Other = 'OTHER',
  /** Price Incentive */

  PriceIncentive = 'PRICE_INCENTIVE',
  /** Price Variation */

  PriceVariation = 'PRICE_VARIATION',
  /** Rental Guarantee */

  RentalGuarantee = 'RENTAL_GUARANTEE',
  /** Settlement Rebate */

  SettlementRebate = 'SETTLEMENT_REBATE',
  /** Special Condition */

  SpecialCondition = 'SPECIAL_CONDITION',
  /** Stamp Duty */

  StampDuty = 'STAMP_DUTY',
  /** Storage/Parking Purchase */

  StorageParkingPurchase = 'STORAGE_PARKING_PURCHASE',
  /** TFN */

  Tfn = 'TFN',
  /** Waitlist Car Space */

  WaitlistCarSpace = 'WAITLIST_CAR_SPACE',
  /** Waitlist Storage */

  WaitlistStorage = 'WAITLIST_STORAGE',
  /** Whitegoods */

  Whitegoods = 'WHITEGOODS',
}

/** select columns of table "variation_type" */
export enum Variation_Type_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "variation_type" */
export enum Variation_Type_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "variation" */
export enum Variation_Update_Column {
  /** column name */

  Approved = 'approved',
  /** column name */

  Category = 'category',
  /** column name */

  CommissionPayable = 'commission_payable',
  /** column name */

  CreatedAt = 'created_at',
  /** column name */

  DateApplied = 'date_applied',
  /** column name */

  DealId = 'deal_id',
  /** column name */

  Description = 'description',
  /** column name */

  Id = 'id',
  /** column name */

  IsPassOnContractDeposit = 'is_pass_on_contract_deposit',
  /** column name */

  Name = 'name',
  /** column name */

  PassOnCost = 'pass_on_cost',
  /** column name */

  SfId = 'sf_id',
  /** column name */

  Type = 'type',
  /** column name */

  UpdatedAt = 'updated_at',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "web_app_feature" */
export enum Web_App_Feature_Constraint {
  /** unique or primary key constraint on columns "value" */

  WebAppFeaturePkey = 'web_app_feature_pkey',
}

export enum Web_App_Feature_Enum {
  /** Ability to create and send digital agency agreement */

  AgencyAgreement = 'AGENCY_AGREEMENT',
  /** Allocation button in the price list, DEVELOPER only. */

  AllocationButtons = 'ALLOCATION_BUTTONS',
  /** This feature is for Developers only and allows them to see an analytics-page page that covers data across projects */

  Analytics = 'ANALYTICS',
  /** Enables UI component for automated ID, Driver License and Medicare verification. */

  AutomatedIdVerification = 'AUTOMATED_ID_VERIFICATION',
  /** Ability to use features which are not fully implemented */

  BetaFeatures = 'BETA_FEATURES',
  /** Ability to send Buyer Portal invitation to the client during listing reservation. */

  BuyerPortal = 'BUYER_PORTAL',
  /** Ability to access conditions and variations in sales contract page */

  ConditionsAndVariations = 'CONDITIONS_AND_VARIATIONS',
  /** Ability to specify percentage for contract deposit */

  ContractDepositVariable = 'CONTRACT_DEPOSIT_VARIABLE',
  /** Ability to update Contract Statuses. DEVELOPER org only. */

  ContractStatusEdit = 'CONTRACT_STATUS_EDIT',
  /** Ability to access Partners page */

  Crm = 'CRM',
  /** Manually enter transactions (automatically approved) */

  DepositExternalApprovalProcess = 'DEPOSIT_EXTERNAL_APPROVAL_PROCESS',
  /** Availability of Deposit details section on the Sales Contract page */

  DepositManagement = 'DEPOSIT_MANAGEMENT',
  /** Ability to access Commissions page. */

  DisplayCommissions = 'DISPLAY_COMMISSIONS',
  /** Enables Digital Contract Signing with Annature */

  EnableDigitalContractSigningAnnature = 'ENABLE_DIGITAL_CONTRACT_SIGNING_ANNATURE',
  /** Ability to specify fixed holding deposit amount for project */

  HoldingDepositFixed = 'HOLDING_DEPOSIT_FIXED',
  /** Ability to specify % of purchase price for the Holding Deposit of individual listings */

  HoldingDepositVariable = 'HOLDING_DEPOSIT_VARIABLE',
  /** Ability to create new listing */

  ListingCreate = 'LISTING_CREATE',
  /** Ability to delete listing */

  ListingDelete = 'LISTING_DELETE',
  /** Ability to edit listing details */

  ListingEdit = 'LISTING_EDIT',
  /** Ability to put listings ON_HOLD. */

  ListingHold = 'LISTING_HOLD',
  /** Ability to reserve listing */

  ListingReserve = 'LISTING_RESERVE',
  /** Ability to to specify copy for reservation process */

  ListingReserveCopy = 'LISTING_RESERVE_COPY',
  /** Share Button with automatic listing cards including QR Code, Listing Details and Agent Contract details */

  ListingShareButton = 'LISTING_SHARE_BUTTON',
  /** New Client */

  NewClient = 'NEW_CLIENT',
  /** Ability to send a notification to the user when agency agreement status changes. */

  NotificationAgencyAgreementStatusUpdates = 'NOTIFICATION_AGENCY_AGREEMENT_STATUS_UPDATES',
  /** Ability to send a notification to the user when a new allocation happens. */

  NotificationAllocationUpdates = 'NOTIFICATION_ALLOCATION_UPDATES',
  /** Ability to send a notification to the user when Deal status changes. */

  NotificationDealStatusUpdates = 'NOTIFICATION_DEAL_STATUS_UPDATES',
  /** Ability to send a notification to the user when Listing detail changes. */

  NotificationListingDetailUpdates = 'NOTIFICATION_LISTING_DETAIL_UPDATES',
  /** Ability to send notification to the user when a new agent signs up.. */

  NotificationNewAgentUpdates = 'NOTIFICATION_NEW_AGENT_UPDATES',
  /** Ability to send notification to the user when a new member signs up in the agency. */

  NotificationNewMemberUpdates = 'NOTIFICATION_NEW_MEMBER_UPDATES',
  /** Ability to send transaction update notifications to users. */

  NotificationTransactionUpdates = 'NOTIFICATION_TRANSACTION_UPDATES',
  /** Ability to enable/disable online payment methods: Card, BPay, PayPal */

  PaymentOnline = 'PAYMENT_ONLINE',
  /** Ability to create apartment or land master plans */

  PlanEdit = 'PLAN_EDIT',
  /** Ability to embed land or apartment plan on another website with enquiry form */

  PlanEmbed = 'PLAN_EMBED',
  /** Track deposits on the Listings page */

  PriceListDeposit = 'PRICE_LIST_DEPOSIT',
  /** Ability to create projects */

  ProjectCreate = 'PROJECT_CREATE',
  /** Ability to generate invitation QR Codes for particular project, user signs up using this QR code and is linked to an Agent who generated QR code */

  ProjectQrCodes = 'PROJECT_QR_CODES',
  /** Ability to auto release listings which were put ON_HOLD after specified amount of time. */

  ReleaseStockOnHold = 'RELEASE_STOCK_ON_HOLD',
  /** Adds Settlement section to the Sales Contract page */

  SalesContractSettlement = 'SALES_CONTRACT_SETTLEMENT',
  /** Ability to view network page */

  SalesNetwork = 'SALES_NETWORK',
  /** Ability to enable Share via Email or SMS button for Embed */

  ShareEmbedEnquiry = 'SHARE_EMBED_ENQUIRY',
  /** Ability to skip Id verification and Document upload */

  SkipIdAndUploadDocs = 'SKIP_ID_AND_UPLOAD_DOCS',
  /** Team Branding */

  TeamBranding = 'TEAM_BRANDING',
  /** Ability to authenticate with ProjectRE Zapier app. */

  Zapier = 'ZAPIER',
}

/** select columns of table "web_app_feature" */
export enum Web_App_Feature_Select_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** update columns of table "web_app_feature" */
export enum Web_App_Feature_Update_Column {
  /** column name */

  Description = 'description',
  /** column name */

  Value = 'value',
}

/** unique or primary key constraints on table "websocket_log" */
export enum Websocket_Log_Constraint {
  /** unique or primary key constraint on columns "request_id" */

  WebsocketLogPkey = 'websocket_log_pkey',
}

/** select columns of table "websocket_log" */
export enum Websocket_Log_Select_Column {
  /** column name */

  CompletedAt = 'completed_at',
  /** column name */

  Duration = 'duration',
  /** column name */

  OperationName = 'operation_name',
  /** column name */

  RequestId = 'request_id',
  /** column name */

  StartedAt = 'started_at',
  /** column name */

  UserId = 'user_id',
  /** column name */

  UserRole = 'user_role',
}

/** update columns of table "websocket_log" */
export enum Websocket_Log_Update_Column {
  /** column name */

  CompletedAt = 'completed_at',
  /** column name */

  Duration = 'duration',
  /** column name */

  OperationName = 'operation_name',
  /** column name */

  RequestId = 'request_id',
  /** column name */

  StartedAt = 'started_at',
  /** column name */

  UserId = 'user_id',
  /** column name */

  UserRole = 'user_role',
}

export type AddressAutoCompleteInput = {
  addressInput: Scalars['String'];
};

export type AgencyAgreementSendInput = {
  agency_agreement_id: Scalars['String'];
  completed_redirect_url: Scalars['String'];
  declined_redirect_url: Scalars['String'];
};

export type AnnatureCreateEnvelopeReq = {
  completed_redirect_url: Scalars['String'];
  deal_id: Scalars['String'];
  declined_redirect_url: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BuyerPropertyDetailInput = {
  listingId: Scalars['String'];
  qrCodeId: Scalars['String'];
};

export type BuyerPropertyListInput = {
  bed?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  qrCodeId: Scalars['String'];
};

export type BuyerQrCodeSignInInput = {
  phone: Scalars['String'];
  qrCodeId: Scalars['String'];
};

export type BuyerQrCodeSignUpInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  qrCodeId: Scalars['String'];
};

export type CancelDealRequest = {
  buyerIds?: InputMaybe<Array<Scalars['String']>>;
  dealId: Scalars['String'];
  fallenDate: Scalars['String'];
  fallenExplanation: Scalars['String'];
  fallenReason: Scalars['String'];
  fallenStatus?: InputMaybe<Scalars['String']>;
  newBuyerId?: InputMaybe<Scalars['String']>;
};

export type CheckPublishStatusRequest = {
  listingId: Scalars['String'];
  portal: Scalars['String'];
};

export type ClientRegistrationDealRequest = {
  bed?: InputMaybe<Scalars['Int']>;
  buyerTeamId?: InputMaybe<Scalars['String']>;
  buyerUserId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
  firbPurchaser?: InputMaybe<Scalars['Boolean']>;
  organisationId: Scalars['String'];
  preferencePropertyId1?: InputMaybe<Scalars['String']>;
  preferencePropertyId2?: InputMaybe<Scalars['String']>;
  preferencePropertyId3?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectSource?: InputMaybe<Scalars['String']>;
  propertyGroupId?: InputMaybe<Scalars['String']>;
  reasonOfPurchase?: InputMaybe<Scalars['String']>;
};

export type ContactRequest = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firbPurchaser?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisation_id?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxFileNumber?: InputMaybe<Scalars['String']>;
};

export type CreateEmbedEnquiryRequest = {
  email: Scalars['String'];
  embed_id: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  listing_id: Scalars['String'];
  phone: Scalars['String'];
};

export type CypressSignUpRequest = {
  allowed_roles: Scalars['String'];
  default_role: Scalars['String'];
  email: Scalars['String'];
  email_verified: Scalars['Boolean'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  team_id: Scalars['String'];
};

export type DealContractRequest = {
  dealId: Scalars['String'];
  firbPurchaser?: InputMaybe<Scalars['Boolean']>;
  nameOnContract: Scalars['String'];
  projectSource?: InputMaybe<Scalars['String']>;
  reasonOfPurchase?: InputMaybe<Scalars['String']>;
};

export type DealRequest = {
  agentHoldRequest?: InputMaybe<Scalars['Boolean']>;
  agentOrganisationId: Scalars['String'];
  buyerType?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firbPurchaser: Scalars['Boolean'];
  firstName?: InputMaybe<Scalars['String']>;
  isExistingBuyer?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  listingId: Scalars['String'];
  name_on_contract?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxFileNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type DealSolicitorAndContractRequest = {
  dealId: Scalars['String'];
  nameOnContract: Scalars['String'];
  purchaserSolicitorContactId: Scalars['String'];
  purchaserSolicitorOrgId: Scalars['String'];
};

export type DealSolicitorRequest = {
  dealId: Scalars['String'];
  purchaserSolicitorContactId?: InputMaybe<Scalars['String']>;
  purchaserSolicitorId: Scalars['String'];
  solicitorNotes?: InputMaybe<Scalars['String']>;
};

export type DealStatusUpdateInput = {
  dealId: Scalars['String'];
  status: Scalars['String'];
  statusDate?: InputMaybe<Scalars['String']>;
};

export type EditListingInput = {
  car_space?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

export type EditPropertyInput = {
  aspect?: InputMaybe<Scalars['String']>;
  bathroom?: InputMaybe<Scalars['Int']>;
  bedroom?: InputMaybe<Scalars['Int']>;
  external_area?: InputMaybe<Scalars['Float']>;
  internal_area?: InputMaybe<Scalars['Float']>;
  level?: InputMaybe<Scalars['String']>;
  lot?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  product_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  storage?: InputMaybe<Scalars['Boolean']>;
};

export type EnquiryRequest = {
  agencyId: Scalars['String'];
  bath?: InputMaybe<Scalars['Int']>;
  bed?: InputMaybe<Scalars['Int']>;
  buyerType?: InputMaybe<Scalars['String']>;
  buyingCapacity?: InputMaybe<Scalars['Int']>;
  car?: InputMaybe<Scalars['Int']>;
  contactId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  financeStatus?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  leadSource?: InputMaybe<Scalars['String']>;
  ownerUserId: Scalars['String'];
  phoneNumber: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  propertyGroupId: Scalars['String'];
  spokenLanguages?: InputMaybe<Array<Scalars['String']>>;
  timeFrame?: InputMaybe<Scalars['String']>;
};

export type EnquiryUpdateRequest = {
  agencyId: Scalars['String'];
  bath?: InputMaybe<Scalars['Int']>;
  bed?: InputMaybe<Scalars['Int']>;
  buyerType?: InputMaybe<Scalars['String']>;
  buyingCapacity?: InputMaybe<Scalars['Int']>;
  car?: InputMaybe<Scalars['Int']>;
  contactId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  enquiryId: Scalars['String'];
  financeStatus?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  ownerUserId: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  propertyGroupId: Scalars['String'];
  spokenLanguages?: InputMaybe<Array<Scalars['String']>>;
  timeFrame?: InputMaybe<Scalars['String']>;
};

export type EoiDealRequest = {
  dealId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type FlatFileTokenRequest = {
  projectType: Scalars['String'];
};

export type GetAddressDetailInput = {
  addressId: Scalars['String'];
};

export type HerokuConnectBulkImportReq = {
  page_size: Scalars['Int'];
  sf_instance_id: Scalars['String'];
  sf_object_name: Scalars['String'];
};

export type HoldListingActionRequest = {
  listingId: Scalars['String'];
};

export type HubspotContactsRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotDealRequest = {
  dealId: Scalars['String'];
  hsInstanceId: Scalars['String'];
};

export type HubspotDealsRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotPartnerContactsRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotPartnerTeamSyncRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotPartnerUsersRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotProjectSyncRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotSyncShemaRequest = {
  hsInstanceId: Scalars['String'];
  objectName: Scalars['String'];
};

export type HubspotTeamSyncRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

export type HubspotUsersRequest = {
  hsInstanceId: Scalars['String'];
  teamIds: Array<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type OrganisationPayload = {
  isNew: Scalars['Boolean'];
  organisationId?: InputMaybe<Scalars['String']>;
  organisationName?: InputMaybe<Scalars['String']>;
};

export type OrganisationRequest = {
  organisationAddress?: InputMaybe<Scalars['String']>;
  organisationName: Scalars['String'];
  organisationType: Scalars['String'];
};

export type PublishListingRequest = {
  agencyId: Scalars['String'];
  listingId: Scalars['String'];
  portal: Scalars['String'];
  projectId: Scalars['String'];
};

export type SalesforceUpdateContactInput = {
  contactId?: InputMaybe<Scalars['uuid']>;
  contactSfId?: InputMaybe<Scalars['String']>;
};

export type SendPaymentReceiptToBuyerInput = {
  dealId: Scalars['String'];
  qrCodeId: Scalars['String'];
};

export type SendPushInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userId: Scalars['uuid'];
};

export type SendReservationInvitationToBuyerRequest = {
  dealId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  listingId: Scalars['String'];
  qrCodeId: Scalars['String'];
};

export type ShareEmbedEnquiry = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  imageType: Scalars['String'];
  lastName: Scalars['String'];
  listingId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  sendVia: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  _niregex?: InputMaybe<Scalars['String']>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nregex?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _regex?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

export type TwilioSendMessageInput = {
  message: Scalars['String'];
  phone: Scalars['String'];
};

export type ValidateDriverLicenseInput = {
  birth_date: Scalars['String'];
  card_number: Scalars['String'];
  contact_id: Scalars['String'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  licence_number: Scalars['String'];
  middle_name?: InputMaybe<Scalars['String']>;
  state_of_issue: Scalars['String'];
};

export type ValidateMedicareInput = {
  birth_date: Scalars['String'];
  card_expiry: Scalars['String'];
  card_number: Scalars['String'];
  card_type: Scalars['String'];
  contact_id: Scalars['String'];
  full_name1: Scalars['String'];
  full_name2?: InputMaybe<Scalars['String']>;
  full_name3?: InputMaybe<Scalars['String']>;
  full_name4?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  individual_reference_number: Scalars['Int'];
};

export type ValidatePassportInput = {
  birth_date: Scalars['String'];
  contact_id: Scalars['String'];
  expiry_date?: InputMaybe<Scalars['String']>;
  family_name: Scalars['String'];
  gender: Scalars['String'];
  given_name: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  travel_document_number: Scalars['String'];
};

export type Acl_Allocation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Allocation_Aggregate_Bool_Exp_Count>;
};

export type Acl_Allocation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Allocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_allocation" */
export type Acl_Allocation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Allocation_Max_Order_By>;
  min?: InputMaybe<Acl_Allocation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_allocation" */
export type Acl_Allocation_Arr_Rel_Insert_Input = {
  data: Array<Acl_Allocation_Insert_Input>;
};

/** Boolean expression to filter rows from the table "acl_allocation". All fields are combined with a logical 'AND'. */
export type Acl_Allocation_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Allocation_Bool_Exp>>;
  _not?: InputMaybe<Acl_Allocation_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Allocation_Bool_Exp>>;
  allocation_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

export type Acl_Allocation_Edit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Allocation_Edit_Aggregate_Bool_Exp_Count>;
};

export type Acl_Allocation_Edit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_allocation_edit" */
export type Acl_Allocation_Edit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Allocation_Edit_Max_Order_By>;
  min?: InputMaybe<Acl_Allocation_Edit_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_allocation_edit" */
export type Acl_Allocation_Edit_Arr_Rel_Insert_Input = {
  data: Array<Acl_Allocation_Edit_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Allocation_Edit_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_allocation_edit". All fields are combined with a logical 'AND'. */
export type Acl_Allocation_Edit_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Allocation_Edit_Bool_Exp>>;
  _not?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Allocation_Edit_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_allocation_edit" */
export type Acl_Allocation_Edit_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_allocation_edit" */
export type Acl_Allocation_Edit_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_allocation_edit" */
export type Acl_Allocation_Edit_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_allocation_edit" */
export type Acl_Allocation_Edit_On_Conflict = {
  constraint: Acl_Allocation_Edit_Constraint;
  update_columns: Array<Acl_Allocation_Edit_Update_Column>;
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_allocation_edit". */
export type Acl_Allocation_Edit_Order_By = {
  description?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_allocation_edit" */
export type Acl_Allocation_Edit_Stream_Cursor_Input = {
  initial_value: Acl_Allocation_Edit_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Allocation_Edit_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting data into table "acl_allocation" */
export type Acl_Allocation_Insert_Input = {
  allocation_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_allocation" */
export type Acl_Allocation_Max_Order_By = {
  allocation_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_allocation" */
export type Acl_Allocation_Min_Order_By = {
  allocation_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "acl_allocation". */
export type Acl_Allocation_Order_By = {
  allocation_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_allocation" */
export type Acl_Allocation_Stream_Cursor_Input = {
  initial_value: Acl_Allocation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Allocation_Stream_Cursor_Value_Input = {
  allocation_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Contact_Agent_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Contact_Agent_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Contact_Agent_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Contact_Agent_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Contact_Agent_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Contact_Agent_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Contact_Agent_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_contact_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Contact_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Contact_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Contact_Agent_Member_Bool_Exp>>;
  contact_email?: InputMaybe<String_Comparison_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Insert_Input = {
  contact_email?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Max_Order_By = {
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Min_Order_By = {
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_On_Conflict = {
  constraint: Acl_Contact_Agent_Member_Constraint;
  update_columns: Array<Acl_Contact_Agent_Member_Update_Column>;
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_contact_agent_member". */
export type Acl_Contact_Agent_Member_Order_By = {
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Contact_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Contact_Agent_Member_Stream_Cursor_Value_Input = {
  contact_email?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Contact_Developer_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Contact_Developer_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Contact_Developer_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Contact_Developer_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Contact_Developer_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Contact_Developer_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Contact_Developer_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_contact_developer_member". All fields are combined with a logical 'AND'. */
export type Acl_Contact_Developer_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Contact_Developer_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Contact_Developer_Member_Bool_Exp>>;
  contact_email?: InputMaybe<String_Comparison_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Insert_Input = {
  contact_email?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Max_Order_By = {
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Min_Order_By = {
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_On_Conflict = {
  constraint: Acl_Contact_Developer_Member_Constraint;
  update_columns: Array<Acl_Contact_Developer_Member_Update_Column>;
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_contact_developer_member". */
export type Acl_Contact_Developer_Member_Order_By = {
  contact_email?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Stream_Cursor_Input = {
  initial_value: Acl_Contact_Developer_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Contact_Developer_Member_Stream_Cursor_Value_Input = {
  contact_email?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Contact_Master_Agent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Contact_Master_Agent_Aggregate_Bool_Exp_Count>;
};

export type Acl_Contact_Master_Agent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Contact_Master_Agent_Max_Order_By>;
  min?: InputMaybe<Acl_Contact_Master_Agent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Arr_Rel_Insert_Input = {
  data: Array<Acl_Contact_Master_Agent_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Contact_Master_Agent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_contact_master_agent". All fields are combined with a logical 'AND'. */
export type Acl_Contact_Master_Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Contact_Master_Agent_Bool_Exp>>;
  _not?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Contact_Master_Agent_Bool_Exp>>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Insert_Input = {
  contact_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_On_Conflict = {
  constraint: Acl_Contact_Master_Agent_Constraint;
  update_columns: Array<Acl_Contact_Master_Agent_Update_Column>;
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_contact_master_agent". */
export type Acl_Contact_Master_Agent_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Stream_Cursor_Input = {
  initial_value: Acl_Contact_Master_Agent_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Contact_Master_Agent_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Deal_Agent_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Deal_Agent_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Deal_Agent_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Deal_Agent_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Deal_Agent_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Deal_Agent_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_deal_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Deal_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Deal_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Deal_Agent_Member_Bool_Exp>>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  envelope_owner?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Insert_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_On_Conflict = {
  constraint: Acl_Deal_Agent_Member_Constraint;
  update_columns: Array<Acl_Deal_Agent_Member_Update_Column>;
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_deal_agent_member". */
export type Acl_Deal_Agent_Member_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Deal_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Deal_Agent_Member_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Deal_Developer_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Deal_Developer_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Deal_Developer_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Deal_Developer_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Deal_Developer_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Deal_Developer_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_deal_developer_member". All fields are combined with a logical 'AND'. */
export type Acl_Deal_Developer_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Deal_Developer_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Deal_Developer_Member_Bool_Exp>>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Insert_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_On_Conflict = {
  constraint: Acl_Deal_Developer_Member_Constraint;
  update_columns: Array<Acl_Deal_Developer_Member_Update_Column>;
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_deal_developer_member". */
export type Acl_Deal_Developer_Member_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Stream_Cursor_Input = {
  initial_value: Acl_Deal_Developer_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Deal_Developer_Member_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Deal_Master_Agent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Deal_Master_Agent_Aggregate_Bool_Exp_Count>;
};

export type Acl_Deal_Master_Agent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Deal_Master_Agent_Max_Order_By>;
  min?: InputMaybe<Acl_Deal_Master_Agent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Arr_Rel_Insert_Input = {
  data: Array<Acl_Deal_Master_Agent_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Deal_Master_Agent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_deal_master_agent". All fields are combined with a logical 'AND'. */
export type Acl_Deal_Master_Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Deal_Master_Agent_Bool_Exp>>;
  _not?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Deal_Master_Agent_Bool_Exp>>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Insert_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_On_Conflict = {
  constraint: Acl_Deal_Master_Agent_Constraint;
  update_columns: Array<Acl_Deal_Master_Agent_Update_Column>;
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_deal_master_agent". */
export type Acl_Deal_Master_Agent_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Stream_Cursor_Input = {
  initial_value: Acl_Deal_Master_Agent_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Deal_Master_Agent_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Deal_User_Team_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Deal_User_Team_Aggregate_Bool_Exp_Count>;
};

export type Acl_Deal_User_Team_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_deal_user_team" */
export type Acl_Deal_User_Team_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Deal_User_Team_Max_Order_By>;
  min?: InputMaybe<Acl_Deal_User_Team_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_deal_user_team" */
export type Acl_Deal_User_Team_Arr_Rel_Insert_Input = {
  data: Array<Acl_Deal_User_Team_Insert_Input>;
};

/** Boolean expression to filter rows from the table "acl_deal_user_team". All fields are combined with a logical 'AND'. */
export type Acl_Deal_User_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Deal_User_Team_Bool_Exp>>;
  _not?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Deal_User_Team_Bool_Exp>>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "acl_deal_user_team_desc". All fields are combined with a logical 'AND'. */
export type Acl_Deal_User_Team_Desc_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Bool_Exp>>;
  _not?: InputMaybe<Acl_Deal_User_Team_Desc_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Bool_Exp>>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_deal_user_team_desc". */
export type Acl_Deal_User_Team_Desc_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_deal_user_team_desc" */
export type Acl_Deal_User_Team_Desc_Stream_Cursor_Input = {
  initial_value: Acl_Deal_User_Team_Desc_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Deal_User_Team_Desc_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting data into table "acl_deal_user_team" */
export type Acl_Deal_User_Team_Insert_Input = {
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_deal_user_team" */
export type Acl_Deal_User_Team_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_deal_user_team" */
export type Acl_Deal_User_Team_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "acl_deal_user_team". */
export type Acl_Deal_User_Team_Order_By = {
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_deal_user_team" */
export type Acl_Deal_User_Team_Stream_Cursor_Input = {
  initial_value: Acl_Deal_User_Team_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Deal_User_Team_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Listing_Agent_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Listing_Agent_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Listing_Agent_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Listing_Agent_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Listing_Agent_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Listing_Agent_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Listing_Agent_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_listing_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Agent_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  listing_status?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  listing_status?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_On_Conflict = {
  constraint: Acl_Listing_Agent_Member_Constraint;
  update_columns: Array<Acl_Listing_Agent_Member_Update_Column>;
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_listing_agent_member". */
export type Acl_Listing_Agent_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Agent_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  listing_status?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "acl_listing_developer_member". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Developer_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Developer_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Developer_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Developer_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_listing_developer_member". */
export type Acl_Listing_Developer_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_developer_member" */
export type Acl_Listing_Developer_Member_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Developer_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Developer_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Listing_Master_Agent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Listing_Master_Agent_Aggregate_Bool_Exp_Count>;
};

export type Acl_Listing_Master_Agent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Listing_Master_Agent_Max_Order_By>;
  min?: InputMaybe<Acl_Listing_Master_Agent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Arr_Rel_Insert_Input = {
  data: Array<Acl_Listing_Master_Agent_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Listing_Master_Agent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_listing_master_agent". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Master_Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Master_Agent_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Master_Agent_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_On_Conflict = {
  constraint: Acl_Listing_Master_Agent_Constraint;
  update_columns: Array<Acl_Listing_Master_Agent_Update_Column>;
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_listing_master_agent". */
export type Acl_Listing_Master_Agent_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Master_Agent_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Master_Agent_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Listing_Property_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Listing_Property_Aggregate_Bool_Exp_Count>;
};

export type Acl_Listing_Property_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_listing_property" */
export type Acl_Listing_Property_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Listing_Property_Max_Order_By>;
  min?: InputMaybe<Acl_Listing_Property_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_listing_property" */
export type Acl_Listing_Property_Arr_Rel_Insert_Input = {
  data: Array<Acl_Listing_Property_Insert_Input>;
};

/** Boolean expression to filter rows from the table "acl_listing_property". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Property_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Property_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Property_Bool_Exp>>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "acl_listing_property_desc". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Property_Desc_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Property_Desc_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Property_Desc_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Property_Desc_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_listing_property_desc". */
export type Acl_Listing_Property_Desc_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_property_desc" */
export type Acl_Listing_Property_Desc_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Property_Desc_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Property_Desc_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  parent_property_group_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting data into table "acl_listing_property" */
export type Acl_Listing_Property_Insert_Input = {
  listing_id?: InputMaybe<Scalars['uuid']>;
  parent_property_group_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_listing_property" */
export type Acl_Listing_Property_Max_Order_By = {
  listing_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "acl_listing_property_member". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Property_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Property_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Property_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Property_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_listing_property_member". */
export type Acl_Listing_Property_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_property_member" */
export type Acl_Listing_Property_Member_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Property_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Property_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by min() on columns of table "acl_listing_property" */
export type Acl_Listing_Property_Min_Order_By = {
  listing_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "acl_listing_property". */
export type Acl_Listing_Property_Order_By = {
  listing_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_property" */
export type Acl_Listing_Property_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Property_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Property_Stream_Cursor_Value_Input = {
  listing_id?: InputMaybe<Scalars['uuid']>;
  parent_property_group_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Listing_Property_Team_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Bool_Exp_Count>;
};

export type Acl_Listing_Property_Team_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Listing_Property_Team_Max_Order_By>;
  min?: InputMaybe<Acl_Listing_Property_Team_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Arr_Rel_Insert_Input = {
  data: Array<Acl_Listing_Property_Team_Insert_Input>;
};

/** Boolean expression to filter rows from the table "acl_listing_property_team". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Property_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Property_Team_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Property_Team_Bool_Exp>>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "acl_listing_property_team_desc". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Property_Team_Desc_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Property_Team_Desc_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_listing_property_team_desc". */
export type Acl_Listing_Property_Team_Desc_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_property_team_desc" */
export type Acl_Listing_Property_Team_Desc_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Property_Team_Desc_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Property_Team_Desc_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  parent_property_group_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting data into table "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Insert_Input = {
  listing_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  parent_property_group_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Max_Order_By = {
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Min_Order_By = {
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "acl_listing_property_team". */
export type Acl_Listing_Property_Team_Order_By = {
  listing_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  parent_property_group_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Property_Team_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Property_Team_Stream_Cursor_Value_Input = {
  listing_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  parent_property_group_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "acl_listing_via_listing_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Via_Listing_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  listing_status?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_listing_via_listing_agent_member". */
export type Acl_Listing_Via_Listing_Agent_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_via_listing_agent_member" */
export type Acl_Listing_Via_Listing_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Via_Listing_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Via_Listing_Agent_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  listing_status?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "acl_listing_via_stage_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Listing_Via_Stage_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  listing_status?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "acl_listing_via_stage_agent_member". */
export type Acl_Listing_Via_Stage_Agent_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_listing_via_stage_agent_member" */
export type Acl_Listing_Via_Stage_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Listing_Via_Stage_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Listing_Via_Stage_Agent_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  listing_status?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Org_Agent_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Org_Agent_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Org_Agent_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Org_Agent_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Org_Agent_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Org_Agent_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Org_Agent_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_org_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Org_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Org_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Org_Agent_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_On_Conflict = {
  constraint: Acl_Org_Agent_Member_Constraint;
  update_columns: Array<Acl_Org_Agent_Member_Update_Column>;
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_org_agent_member". */
export type Acl_Org_Agent_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Org_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Org_Agent_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Org_Developer_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Org_Developer_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Org_Developer_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Org_Developer_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Org_Developer_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Org_Developer_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Org_Developer_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_org_developer_member". All fields are combined with a logical 'AND'. */
export type Acl_Org_Developer_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Org_Developer_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Org_Developer_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_On_Conflict = {
  constraint: Acl_Org_Developer_Member_Constraint;
  update_columns: Array<Acl_Org_Developer_Member_Update_Column>;
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_org_developer_member". */
export type Acl_Org_Developer_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Stream_Cursor_Input = {
  initial_value: Acl_Org_Developer_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Org_Developer_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Org_Feature_Enabled_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Org_Feature_Enabled_Aggregate_Bool_Exp_Count>;
};

export type Acl_Org_Feature_Enabled_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Org_Feature_Enabled_Max_Order_By>;
  min?: InputMaybe<Acl_Org_Feature_Enabled_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Arr_Rel_Insert_Input = {
  data: Array<Acl_Org_Feature_Enabled_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Org_Feature_Enabled_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_org_feature_enabled". All fields are combined with a logical 'AND'. */
export type Acl_Org_Feature_Enabled_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Org_Feature_Enabled_Bool_Exp>>;
  _not?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Org_Feature_Enabled_Bool_Exp>>;
  feature?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Insert_Input = {
  feature?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Max_Order_By = {
  feature?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Min_Order_By = {
  feature?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_On_Conflict = {
  constraint: Acl_Org_Feature_Enabled_Constraint;
  update_columns: Array<Acl_Org_Feature_Enabled_Update_Column>;
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_org_feature_enabled". */
export type Acl_Org_Feature_Enabled_Order_By = {
  feature?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Stream_Cursor_Input = {
  initial_value: Acl_Org_Feature_Enabled_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Org_Feature_Enabled_Stream_Cursor_Value_Input = {
  feature?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Org_Master_Agent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Org_Master_Agent_Aggregate_Bool_Exp_Count>;
};

export type Acl_Org_Master_Agent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Org_Master_Agent_Max_Order_By>;
  min?: InputMaybe<Acl_Org_Master_Agent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Arr_Rel_Insert_Input = {
  data: Array<Acl_Org_Master_Agent_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Org_Master_Agent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_org_master_agent". All fields are combined with a logical 'AND'. */
export type Acl_Org_Master_Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Org_Master_Agent_Bool_Exp>>;
  _not?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Org_Master_Agent_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_On_Conflict = {
  constraint: Acl_Org_Master_Agent_Constraint;
  update_columns: Array<Acl_Org_Master_Agent_Update_Column>;
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_org_master_agent". */
export type Acl_Org_Master_Agent_Order_By = {
  description?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Stream_Cursor_Input = {
  initial_value: Acl_Org_Master_Agent_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Org_Master_Agent_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Acl_Property_Allocation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Property_Allocation_Aggregate_Bool_Exp_Count>;
};

export type Acl_Property_Allocation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_property_allocation" */
export type Acl_Property_Allocation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Property_Allocation_Max_Order_By>;
  min?: InputMaybe<Acl_Property_Allocation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_property_allocation" */
export type Acl_Property_Allocation_Arr_Rel_Insert_Input = {
  data: Array<Acl_Property_Allocation_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Property_Allocation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_property_allocation". All fields are combined with a logical 'AND'. */
export type Acl_Property_Allocation_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Property_Allocation_Bool_Exp>>;
  _not?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Property_Allocation_Bool_Exp>>;
  allocated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  allocated_to_org_id?: InputMaybe<Uuid_Comparison_Exp>;
  allocated_to_org_name?: InputMaybe<String_Comparison_Exp>;
  allocation_type?: InputMaybe<String_Comparison_Exp>;
  listing_status?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_name?: InputMaybe<String_Comparison_Exp>;
  property_type?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "acl_property_allocation_count". All fields are combined with a logical 'AND'. */
export type Acl_Property_Allocation_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Property_Allocation_Count_Bool_Exp>>;
  _not?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Property_Allocation_Count_Bool_Exp>>;
  alloc_count?: InputMaybe<Bigint_Comparison_Exp>;
  allocated_to?: InputMaybe<String_Comparison_Exp>;
  group_alloc_count?: InputMaybe<Bigint_Comparison_Exp>;
  last_allocated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_group_allocated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_listing_allocated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  listing_allocation_count?: InputMaybe<Bigint_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort_alloc_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count_Insert_Input = {
  alloc_count?: InputMaybe<Scalars['bigint']>;
  allocated_to?: InputMaybe<Scalars['String']>;
  group_alloc_count?: InputMaybe<Scalars['bigint']>;
  last_allocated_at?: InputMaybe<Scalars['timestamptz']>;
  last_group_allocated_at?: InputMaybe<Scalars['timestamptz']>;
  last_listing_allocated_at?: InputMaybe<Scalars['timestamptz']>;
  listing_allocation_count?: InputMaybe<Scalars['bigint']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  sort_alloc_count?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count_Obj_Rel_Insert_Input = {
  data: Acl_Property_Allocation_Count_Insert_Input;
  on_conflict?: InputMaybe<Acl_Property_Allocation_Count_On_Conflict>;
};

/** on_conflict condition type for table "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count_On_Conflict = {
  constraint: Acl_Property_Allocation_Count_Constraint;
  update_columns: Array<Acl_Property_Allocation_Count_Update_Column>;
  where?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_property_allocation_count". */
export type Acl_Property_Allocation_Count_Order_By = {
  alloc_count?: InputMaybe<Order_By>;
  allocated_to?: InputMaybe<Order_By>;
  group_alloc_count?: InputMaybe<Order_By>;
  last_allocated_at?: InputMaybe<Order_By>;
  last_group_allocated_at?: InputMaybe<Order_By>;
  last_listing_allocated_at?: InputMaybe<Order_By>;
  listing_allocation_count?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  sort_alloc_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count_Stream_Cursor_Input = {
  initial_value: Acl_Property_Allocation_Count_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Property_Allocation_Count_Stream_Cursor_Value_Input = {
  alloc_count?: InputMaybe<Scalars['bigint']>;
  allocated_to?: InputMaybe<Scalars['String']>;
  group_alloc_count?: InputMaybe<Scalars['bigint']>;
  last_allocated_at?: InputMaybe<Scalars['timestamptz']>;
  last_group_allocated_at?: InputMaybe<Scalars['timestamptz']>;
  last_listing_allocated_at?: InputMaybe<Scalars['timestamptz']>;
  listing_allocation_count?: InputMaybe<Scalars['bigint']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  sort_alloc_count?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "acl_property_allocation" */
export type Acl_Property_Allocation_Insert_Input = {
  allocated_at?: InputMaybe<Scalars['timestamptz']>;
  allocated_to_org_id?: InputMaybe<Scalars['uuid']>;
  allocated_to_org_name?: InputMaybe<Scalars['String']>;
  allocation_type?: InputMaybe<Scalars['String']>;
  listing_status?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  property_name?: InputMaybe<Scalars['String']>;
  property_type?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "acl_property_allocation" */
export type Acl_Property_Allocation_Max_Order_By = {
  allocated_at?: InputMaybe<Order_By>;
  allocated_to_org_id?: InputMaybe<Order_By>;
  allocated_to_org_name?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_property_allocation" */
export type Acl_Property_Allocation_Min_Order_By = {
  allocated_at?: InputMaybe<Order_By>;
  allocated_to_org_id?: InputMaybe<Order_By>;
  allocated_to_org_name?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_property_allocation" */
export type Acl_Property_Allocation_On_Conflict = {
  constraint: Acl_Property_Allocation_Constraint;
  update_columns: Array<Acl_Property_Allocation_Update_Column>;
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_property_allocation". */
export type Acl_Property_Allocation_Order_By = {
  allocated_at?: InputMaybe<Order_By>;
  allocated_to_org_id?: InputMaybe<Order_By>;
  allocated_to_org_name?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_property_allocation" */
export type Acl_Property_Allocation_Stream_Cursor_Input = {
  initial_value: Acl_Property_Allocation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Property_Allocation_Stream_Cursor_Value_Input = {
  allocated_at?: InputMaybe<Scalars['timestamptz']>;
  allocated_to_org_id?: InputMaybe<Scalars['uuid']>;
  allocated_to_org_name?: InputMaybe<Scalars['String']>;
  allocation_type?: InputMaybe<Scalars['String']>;
  listing_status?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  property_name?: InputMaybe<Scalars['String']>;
  property_type?: InputMaybe<Scalars['String']>;
};

export type Acl_Stage_Agent_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acl_Stage_Agent_Member_Aggregate_Bool_Exp_Count>;
};

export type Acl_Stage_Agent_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acl_Stage_Agent_Member_Max_Order_By>;
  min?: InputMaybe<Acl_Stage_Agent_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Arr_Rel_Insert_Input = {
  data: Array<Acl_Stage_Agent_Member_Insert_Input>;
  on_conflict?: InputMaybe<Acl_Stage_Agent_Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "acl_stage_agent_member". All fields are combined with a logical 'AND'. */
export type Acl_Stage_Agent_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Stage_Agent_Member_Bool_Exp>>;
  _not?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Stage_Agent_Member_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  stage_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_On_Conflict = {
  constraint: Acl_Stage_Agent_Member_Constraint;
  update_columns: Array<Acl_Stage_Agent_Member_Update_Column>;
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_stage_agent_member". */
export type Acl_Stage_Agent_Member_Order_By = {
  description?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Stream_Cursor_Input = {
  initial_value: Acl_Stage_Agent_Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Stage_Agent_Member_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  stage_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "acl_update". All fields are combined with a logical 'AND'. */
export type Acl_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Update_Bool_Exp>>;
  _not?: InputMaybe<Acl_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Update_Bool_Exp>>;
  acl?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  request_count?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Acl_Update_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "acl_update" */
export type Acl_Update_Inc_Input = {
  request_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "acl_update" */
export type Acl_Update_Insert_Input = {
  acl?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  message?: InputMaybe<Scalars['String']>;
  request_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Acl_Update_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "acl_update" */
export type Acl_Update_On_Conflict = {
  constraint: Acl_Update_Constraint;
  update_columns: Array<Acl_Update_Update_Column>;
  where?: InputMaybe<Acl_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_update". */
export type Acl_Update_Order_By = {
  acl?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  request_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: acl_update */
export type Acl_Update_Pk_Columns_Input = {
  acl: Scalars['String'];
};

/** input type for updating data in table "acl_update" */
export type Acl_Update_Set_Input = {
  acl?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  message?: InputMaybe<Scalars['String']>;
  request_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Acl_Update_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "acl_update_status". All fields are combined with a logical 'AND'. */
export type Acl_Update_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Acl_Update_Status_Bool_Exp>>;
  _not?: InputMaybe<Acl_Update_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Acl_Update_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "acl_update_status_enum". All fields are combined with logical 'AND'. */
export type Acl_Update_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Acl_Update_Status_Enum>;
  _in?: InputMaybe<Array<Acl_Update_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Acl_Update_Status_Enum>;
  _nin?: InputMaybe<Array<Acl_Update_Status_Enum>>;
};

/** input type for inserting data into table "acl_update_status" */
export type Acl_Update_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "acl_update_status" */
export type Acl_Update_Status_On_Conflict = {
  constraint: Acl_Update_Status_Constraint;
  update_columns: Array<Acl_Update_Status_Update_Column>;
  where?: InputMaybe<Acl_Update_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "acl_update_status". */
export type Acl_Update_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: acl_update_status */
export type Acl_Update_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "acl_update_status" */
export type Acl_Update_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "acl_update_status" */
export type Acl_Update_Status_Stream_Cursor_Input = {
  initial_value: Acl_Update_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Update_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Acl_Update_Status_Updates = {
  _set?: InputMaybe<Acl_Update_Status_Set_Input>;
  where: Acl_Update_Status_Bool_Exp;
};

/** Streaming cursor of the table "acl_update" */
export type Acl_Update_Stream_Cursor_Input = {
  initial_value: Acl_Update_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acl_Update_Stream_Cursor_Value_Input = {
  acl?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  message?: InputMaybe<Scalars['String']>;
  request_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Acl_Update_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Acl_Update_Updates = {
  _inc?: InputMaybe<Acl_Update_Inc_Input>;
  _set?: InputMaybe<Acl_Update_Set_Input>;
  where: Acl_Update_Bool_Exp;
};

export type Agency_Agreement_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Agency_Agreement_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Agency_Agreement_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Agency_Agreement_Aggregate_Bool_Exp_Count>;
};

export type Agency_Agreement_Aggregate_Bool_Exp_Bool_And = {
  arguments: Agency_Agreement_Select_Column_Agency_Agreement_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Agency_Agreement_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Agency_Agreement_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Agency_Agreement_Select_Column_Agency_Agreement_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Agency_Agreement_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Agency_Agreement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Agency_Agreement_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "agency_agreement" */
export type Agency_Agreement_Aggregate_Order_By = {
  avg?: InputMaybe<Agency_Agreement_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Agency_Agreement_Max_Order_By>;
  min?: InputMaybe<Agency_Agreement_Min_Order_By>;
  stddev?: InputMaybe<Agency_Agreement_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Agency_Agreement_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Agency_Agreement_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Agency_Agreement_Sum_Order_By>;
  var_pop?: InputMaybe<Agency_Agreement_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Agency_Agreement_Var_Samp_Order_By>;
  variance?: InputMaybe<Agency_Agreement_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Agency_Agreement_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "agency_agreement" */
export type Agency_Agreement_Arr_Rel_Insert_Input = {
  data: Array<Agency_Agreement_Insert_Input>;
  on_conflict?: InputMaybe<Agency_Agreement_On_Conflict>;
};

/** order by avg() on columns of table "agency_agreement" */
export type Agency_Agreement_Avg_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "agency_agreement". All fields are combined with a logical 'AND'. */
export type Agency_Agreement_Bool_Exp = {
  _and?: InputMaybe<Array<Agency_Agreement_Bool_Exp>>;
  _not?: InputMaybe<Agency_Agreement_Bool_Exp>;
  _or?: InputMaybe<Array<Agency_Agreement_Bool_Exp>>;
  agency?: InputMaybe<Organisation_Bool_Exp>;
  agency_abn_acn?: InputMaybe<String_Comparison_Exp>;
  agency_address_city_suburb?: InputMaybe<String_Comparison_Exp>;
  agency_address_country?: InputMaybe<String_Comparison_Exp>;
  agency_address_line_1?: InputMaybe<String_Comparison_Exp>;
  agency_address_postcode?: InputMaybe<String_Comparison_Exp>;
  agency_address_state?: InputMaybe<String_Comparison_Exp>;
  agency_full_address?: InputMaybe<String_Comparison_Exp>;
  agency_id?: InputMaybe<Uuid_Comparison_Exp>;
  agency_name?: InputMaybe<String_Comparison_Exp>;
  agency_registered_for_gst?: InputMaybe<Boolean_Comparison_Exp>;
  agency_trading_name?: InputMaybe<String_Comparison_Exp>;
  agency_user?: InputMaybe<User_Bool_Exp>;
  agency_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  commission_rate?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  dev_user?: InputMaybe<User_Bool_Exp>;
  dev_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  envelope_owner?: InputMaybe<String_Comparison_Exp>;
  envelopes?: InputMaybe<Envelope_Bool_Exp>;
  envelopes_aggregate?: InputMaybe<Envelope_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  licence_number?: InputMaybe<String_Comparison_Exp>;
  licensee_address_city_suburb?: InputMaybe<String_Comparison_Exp>;
  licensee_address_country?: InputMaybe<String_Comparison_Exp>;
  licensee_address_line_1?: InputMaybe<String_Comparison_Exp>;
  licensee_address_postcode?: InputMaybe<String_Comparison_Exp>;
  licensee_address_state?: InputMaybe<String_Comparison_Exp>;
  licensee_email?: InputMaybe<String_Comparison_Exp>;
  licensee_full_address?: InputMaybe<String_Comparison_Exp>;
  licensee_name?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Agency_Agreement_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Agency_Agreement_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Agency_Agreement_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "agency_agreement" */
export type Agency_Agreement_Inc_Input = {
  commission_rate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "agency_agreement" */
export type Agency_Agreement_Insert_Input = {
  agency?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  agency_abn_acn?: InputMaybe<Scalars['String']>;
  agency_address_city_suburb?: InputMaybe<Scalars['String']>;
  agency_address_country?: InputMaybe<Scalars['String']>;
  agency_address_line_1?: InputMaybe<Scalars['String']>;
  agency_address_postcode?: InputMaybe<Scalars['String']>;
  agency_address_state?: InputMaybe<Scalars['String']>;
  agency_id?: InputMaybe<Scalars['uuid']>;
  agency_name?: InputMaybe<Scalars['String']>;
  agency_registered_for_gst?: InputMaybe<Scalars['Boolean']>;
  agency_trading_name?: InputMaybe<Scalars['String']>;
  agency_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  agency_user_id?: InputMaybe<Scalars['uuid']>;
  commission_rate?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  dev_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  dev_user_id?: InputMaybe<Scalars['uuid']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  envelopes?: InputMaybe<Envelope_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  licence_number?: InputMaybe<Scalars['String']>;
  licensee_address_city_suburb?: InputMaybe<Scalars['String']>;
  licensee_address_country?: InputMaybe<Scalars['String']>;
  licensee_address_line_1?: InputMaybe<Scalars['String']>;
  licensee_address_postcode?: InputMaybe<Scalars['String']>;
  licensee_address_state?: InputMaybe<Scalars['String']>;
  licensee_email?: InputMaybe<Scalars['String']>;
  licensee_name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "agency_agreement" */
export type Agency_Agreement_Max_Order_By = {
  agency_abn_acn?: InputMaybe<Order_By>;
  agency_address_city_suburb?: InputMaybe<Order_By>;
  agency_address_country?: InputMaybe<Order_By>;
  agency_address_line_1?: InputMaybe<Order_By>;
  agency_address_postcode?: InputMaybe<Order_By>;
  agency_address_state?: InputMaybe<Order_By>;
  agency_id?: InputMaybe<Order_By>;
  agency_name?: InputMaybe<Order_By>;
  agency_trading_name?: InputMaybe<Order_By>;
  agency_user_id?: InputMaybe<Order_By>;
  commission_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  dev_user_id?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  licensee_address_city_suburb?: InputMaybe<Order_By>;
  licensee_address_country?: InputMaybe<Order_By>;
  licensee_address_line_1?: InputMaybe<Order_By>;
  licensee_address_postcode?: InputMaybe<Order_By>;
  licensee_address_state?: InputMaybe<Order_By>;
  licensee_email?: InputMaybe<Order_By>;
  licensee_name?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "agency_agreement" */
export type Agency_Agreement_Min_Order_By = {
  agency_abn_acn?: InputMaybe<Order_By>;
  agency_address_city_suburb?: InputMaybe<Order_By>;
  agency_address_country?: InputMaybe<Order_By>;
  agency_address_line_1?: InputMaybe<Order_By>;
  agency_address_postcode?: InputMaybe<Order_By>;
  agency_address_state?: InputMaybe<Order_By>;
  agency_id?: InputMaybe<Order_By>;
  agency_name?: InputMaybe<Order_By>;
  agency_trading_name?: InputMaybe<Order_By>;
  agency_user_id?: InputMaybe<Order_By>;
  commission_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  dev_user_id?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  licensee_address_city_suburb?: InputMaybe<Order_By>;
  licensee_address_country?: InputMaybe<Order_By>;
  licensee_address_line_1?: InputMaybe<Order_By>;
  licensee_address_postcode?: InputMaybe<Order_By>;
  licensee_address_state?: InputMaybe<Order_By>;
  licensee_email?: InputMaybe<Order_By>;
  licensee_name?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "agency_agreement" */
export type Agency_Agreement_Obj_Rel_Insert_Input = {
  data: Agency_Agreement_Insert_Input;
  on_conflict?: InputMaybe<Agency_Agreement_On_Conflict>;
};

/** on_conflict condition type for table "agency_agreement" */
export type Agency_Agreement_On_Conflict = {
  constraint: Agency_Agreement_Constraint;
  update_columns: Array<Agency_Agreement_Update_Column>;
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

/** Ordering options when selecting data from "agency_agreement". */
export type Agency_Agreement_Order_By = {
  agency?: InputMaybe<Organisation_Order_By>;
  agency_abn_acn?: InputMaybe<Order_By>;
  agency_address_city_suburb?: InputMaybe<Order_By>;
  agency_address_country?: InputMaybe<Order_By>;
  agency_address_line_1?: InputMaybe<Order_By>;
  agency_address_postcode?: InputMaybe<Order_By>;
  agency_address_state?: InputMaybe<Order_By>;
  agency_full_address?: InputMaybe<Order_By>;
  agency_id?: InputMaybe<Order_By>;
  agency_name?: InputMaybe<Order_By>;
  agency_registered_for_gst?: InputMaybe<Order_By>;
  agency_trading_name?: InputMaybe<Order_By>;
  agency_user?: InputMaybe<User_Order_By>;
  agency_user_id?: InputMaybe<Order_By>;
  commission_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  dev_user?: InputMaybe<User_Order_By>;
  dev_user_id?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  envelopes_aggregate?: InputMaybe<Envelope_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  licensee_address_city_suburb?: InputMaybe<Order_By>;
  licensee_address_country?: InputMaybe<Order_By>;
  licensee_address_line_1?: InputMaybe<Order_By>;
  licensee_address_postcode?: InputMaybe<Order_By>;
  licensee_address_state?: InputMaybe<Order_By>;
  licensee_email?: InputMaybe<Order_By>;
  licensee_full_address?: InputMaybe<Order_By>;
  licensee_name?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agency_agreement */
export type Agency_Agreement_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Agency_Agreement_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "agency_agreement" */
export type Agency_Agreement_Set_Input = {
  agency_abn_acn?: InputMaybe<Scalars['String']>;
  agency_address_city_suburb?: InputMaybe<Scalars['String']>;
  agency_address_country?: InputMaybe<Scalars['String']>;
  agency_address_line_1?: InputMaybe<Scalars['String']>;
  agency_address_postcode?: InputMaybe<Scalars['String']>;
  agency_address_state?: InputMaybe<Scalars['String']>;
  agency_id?: InputMaybe<Scalars['uuid']>;
  agency_name?: InputMaybe<Scalars['String']>;
  agency_registered_for_gst?: InputMaybe<Scalars['Boolean']>;
  agency_trading_name?: InputMaybe<Scalars['String']>;
  agency_user_id?: InputMaybe<Scalars['uuid']>;
  commission_rate?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  dev_user_id?: InputMaybe<Scalars['uuid']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  licence_number?: InputMaybe<Scalars['String']>;
  licensee_address_city_suburb?: InputMaybe<Scalars['String']>;
  licensee_address_country?: InputMaybe<Scalars['String']>;
  licensee_address_line_1?: InputMaybe<Scalars['String']>;
  licensee_address_postcode?: InputMaybe<Scalars['String']>;
  licensee_address_state?: InputMaybe<Scalars['String']>;
  licensee_email?: InputMaybe<Scalars['String']>;
  licensee_name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "agency_agreement" */
export type Agency_Agreement_Stddev_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "agency_agreement" */
export type Agency_Agreement_Stddev_Pop_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "agency_agreement" */
export type Agency_Agreement_Stddev_Samp_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "agency_agreement" */
export type Agency_Agreement_Stream_Cursor_Input = {
  initial_value: Agency_Agreement_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agency_Agreement_Stream_Cursor_Value_Input = {
  agency_abn_acn?: InputMaybe<Scalars['String']>;
  agency_address_city_suburb?: InputMaybe<Scalars['String']>;
  agency_address_country?: InputMaybe<Scalars['String']>;
  agency_address_line_1?: InputMaybe<Scalars['String']>;
  agency_address_postcode?: InputMaybe<Scalars['String']>;
  agency_address_state?: InputMaybe<Scalars['String']>;
  agency_id?: InputMaybe<Scalars['uuid']>;
  agency_name?: InputMaybe<Scalars['String']>;
  agency_registered_for_gst?: InputMaybe<Scalars['Boolean']>;
  agency_trading_name?: InputMaybe<Scalars['String']>;
  agency_user_id?: InputMaybe<Scalars['uuid']>;
  commission_rate?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  dev_user_id?: InputMaybe<Scalars['uuid']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  licence_number?: InputMaybe<Scalars['String']>;
  licensee_address_city_suburb?: InputMaybe<Scalars['String']>;
  licensee_address_country?: InputMaybe<Scalars['String']>;
  licensee_address_line_1?: InputMaybe<Scalars['String']>;
  licensee_address_postcode?: InputMaybe<Scalars['String']>;
  licensee_address_state?: InputMaybe<Scalars['String']>;
  licensee_email?: InputMaybe<Scalars['String']>;
  licensee_name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "agency_agreement" */
export type Agency_Agreement_Sum_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

export type Agency_Agreement_Updates = {
  _append?: InputMaybe<Agency_Agreement_Append_Input>;
  _delete_at_path?: InputMaybe<Agency_Agreement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Agency_Agreement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Agency_Agreement_Delete_Key_Input>;
  _inc?: InputMaybe<Agency_Agreement_Inc_Input>;
  _prepend?: InputMaybe<Agency_Agreement_Prepend_Input>;
  _set?: InputMaybe<Agency_Agreement_Set_Input>;
  where: Agency_Agreement_Bool_Exp;
};

/** order by var_pop() on columns of table "agency_agreement" */
export type Agency_Agreement_Var_Pop_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "agency_agreement" */
export type Agency_Agreement_Var_Samp_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "agency_agreement" */
export type Agency_Agreement_Variance_Order_By = {
  commission_rate?: InputMaybe<Order_By>;
};

export type Agreement_Aggregate_Bool_Exp = {
  count?: InputMaybe<Agreement_Aggregate_Bool_Exp_Count>;
};

export type Agreement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Agreement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Agreement_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "agreement" */
export type Agreement_Aggregate_Order_By = {
  avg?: InputMaybe<Agreement_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Agreement_Max_Order_By>;
  min?: InputMaybe<Agreement_Min_Order_By>;
  stddev?: InputMaybe<Agreement_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Agreement_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Agreement_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Agreement_Sum_Order_By>;
  var_pop?: InputMaybe<Agreement_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Agreement_Var_Samp_Order_By>;
  variance?: InputMaybe<Agreement_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "agreement" */
export type Agreement_Arr_Rel_Insert_Input = {
  data: Array<Agreement_Insert_Input>;
  on_conflict?: InputMaybe<Agreement_On_Conflict>;
};

/** order by avg() on columns of table "agreement" */
export type Agreement_Avg_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "agreement". All fields are combined with a logical 'AND'. */
export type Agreement_Bool_Exp = {
  _and?: InputMaybe<Array<Agreement_Bool_Exp>>;
  _not?: InputMaybe<Agreement_Bool_Exp>;
  _or?: InputMaybe<Array<Agreement_Bool_Exp>>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  commissions?: InputMaybe<Commission_Bool_Exp>;
  commissions_aggregate?: InputMaybe<Commission_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exchange_payable_amount?: InputMaybe<Numeric_Comparison_Exp>;
  exchange_payable_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  settlement_payable_amount?: InputMaybe<Numeric_Comparison_Exp>;
  settlement_payable_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "agreement" */
export type Agreement_Inc_Input = {
  exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "agreement" */
export type Agreement_Insert_Input = {
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  commissions?: InputMaybe<Commission_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  start_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "agreement" */
export type Agreement_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "agreement" */
export type Agreement_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "agreement" */
export type Agreement_Obj_Rel_Insert_Input = {
  data: Agreement_Insert_Input;
  on_conflict?: InputMaybe<Agreement_On_Conflict>;
};

/** on_conflict condition type for table "agreement" */
export type Agreement_On_Conflict = {
  constraint: Agreement_Constraint;
  update_columns: Array<Agreement_Update_Column>;
  where?: InputMaybe<Agreement_Bool_Exp>;
};

/** Ordering options when selecting data from "agreement". */
export type Agreement_Order_By = {
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  commissions_aggregate?: InputMaybe<Commission_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: agreement */
export type Agreement_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "agreement" */
export type Agreement_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  start_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "agreement" */
export type Agreement_Stddev_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "agreement" */
export type Agreement_Stddev_Pop_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "agreement" */
export type Agreement_Stddev_Samp_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "agreement" */
export type Agreement_Stream_Cursor_Input = {
  initial_value: Agreement_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Agreement_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  start_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "agreement" */
export type Agreement_Sum_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

export type Agreement_Updates = {
  _inc?: InputMaybe<Agreement_Inc_Input>;
  _set?: InputMaybe<Agreement_Set_Input>;
  where: Agreement_Bool_Exp;
};

/** order by var_pop() on columns of table "agreement" */
export type Agreement_Var_Pop_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "agreement" */
export type Agreement_Var_Samp_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "agreement" */
export type Agreement_Variance_Order_By = {
  exchange_payable_amount?: InputMaybe<Order_By>;
  exchange_payable_percentage?: InputMaybe<Order_By>;
  settlement_payable_amount?: InputMaybe<Order_By>;
  settlement_payable_percentage?: InputMaybe<Order_By>;
};

export type Allocation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Allocation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Allocation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Allocation_Aggregate_Bool_Exp_Count>;
};

export type Allocation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Allocation_Select_Column_Allocation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allocation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Allocation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Allocation_Select_Column_Allocation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allocation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Allocation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "allocation" */
export type Allocation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Allocation_Max_Order_By>;
  min?: InputMaybe<Allocation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "allocation" */
export type Allocation_Arr_Rel_Insert_Input = {
  data: Array<Allocation_Insert_Input>;
  on_conflict?: InputMaybe<Allocation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "allocation". All fields are combined with a logical 'AND'. */
export type Allocation_Bool_Exp = {
  _and?: InputMaybe<Array<Allocation_Bool_Exp>>;
  _not?: InputMaybe<Allocation_Bool_Exp>;
  _or?: InputMaybe<Array<Allocation_Bool_Exp>>;
  access_active?: InputMaybe<Boolean_Comparison_Exp>;
  acl_allocation?: InputMaybe<Acl_Allocation_Bool_Exp>;
  acl_allocation_aggregate?: InputMaybe<Acl_Allocation_Aggregate_Bool_Exp>;
  agreement?: InputMaybe<Agreement_Bool_Exp>;
  agreement_id?: InputMaybe<Uuid_Comparison_Exp>;
  allocation_type?: InputMaybe<Allocation_Type_Bool_Exp>;
  createdByOrganisation?: InputMaybe<Organisation_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_team?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  include_listings?: InputMaybe<Boolean_Comparison_Exp>;
  inherited_from?: InputMaybe<Uuid_Comparison_Exp>;
  invitation?: InputMaybe<Invitation_Bool_Exp>;
  invitation_id?: InputMaybe<Uuid_Comparison_Exp>;
  is_frontend_updated?: InputMaybe<Boolean_Comparison_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  skip_up_sync?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<Allocation_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** input type for inserting data into table "allocation" */
export type Allocation_Insert_Input = {
  access_active?: InputMaybe<Scalars['Boolean']>;
  acl_allocation?: InputMaybe<Acl_Allocation_Arr_Rel_Insert_Input>;
  agreement?: InputMaybe<Agreement_Obj_Rel_Insert_Input>;
  agreement_id?: InputMaybe<Scalars['uuid']>;
  allocation_type?: InputMaybe<Allocation_Type_Obj_Rel_Insert_Input>;
  createdByOrganisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  include_listings?: InputMaybe<Scalars['Boolean']>;
  inherited_from?: InputMaybe<Scalars['uuid']>;
  invitation?: InputMaybe<Invitation_Obj_Rel_Insert_Input>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  is_frontend_updated?: InputMaybe<Scalars['Boolean']>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  skip_up_sync?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Allocation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** Boolean expression to filter rows from the table "allocation_log". All fields are combined with a logical 'AND'. */
export type Allocation_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Allocation_Log_Bool_Exp>>;
  _not?: InputMaybe<Allocation_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Allocation_Log_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "allocation_log" */
export type Allocation_Log_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "allocation_log" */
export type Allocation_Log_On_Conflict = {
  constraint: Allocation_Log_Constraint;
  update_columns: Array<Allocation_Log_Update_Column>;
  where?: InputMaybe<Allocation_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "allocation_log". */
export type Allocation_Log_Order_By = {
  action?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allocation_log */
export type Allocation_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "allocation_log" */
export type Allocation_Log_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "allocation_log" */
export type Allocation_Log_Stream_Cursor_Input = {
  initial_value: Allocation_Log_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allocation_Log_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Allocation_Log_Updates = {
  _set?: InputMaybe<Allocation_Log_Set_Input>;
  where: Allocation_Log_Bool_Exp;
};

/** order by max() on columns of table "allocation" */
export type Allocation_Max_Order_By = {
  agreement_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherited_from?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "allocation" */
export type Allocation_Min_Order_By = {
  agreement_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherited_from?: InputMaybe<Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "allocation" */
export type Allocation_On_Conflict = {
  constraint: Allocation_Constraint;
  update_columns: Array<Allocation_Update_Column>;
  where?: InputMaybe<Allocation_Bool_Exp>;
};

/** Ordering options when selecting data from "allocation". */
export type Allocation_Order_By = {
  access_active?: InputMaybe<Order_By>;
  acl_allocation_aggregate?: InputMaybe<Acl_Allocation_Aggregate_Order_By>;
  agreement?: InputMaybe<Agreement_Order_By>;
  agreement_id?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Allocation_Type_Order_By>;
  createdByOrganisation?: InputMaybe<Organisation_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  include_listings?: InputMaybe<Order_By>;
  inherited_from?: InputMaybe<Order_By>;
  invitation?: InputMaybe<Invitation_Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  is_frontend_updated?: InputMaybe<Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  listing_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  skip_up_sync?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: allocation */
export type Allocation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "allocation" */
export type Allocation_Set_Input = {
  access_active?: InputMaybe<Scalars['Boolean']>;
  agreement_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  include_listings?: InputMaybe<Scalars['Boolean']>;
  inherited_from?: InputMaybe<Scalars['uuid']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  is_frontend_updated?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  skip_up_sync?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Allocation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "allocation" */
export type Allocation_Stream_Cursor_Input = {
  initial_value: Allocation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allocation_Stream_Cursor_Value_Input = {
  access_active?: InputMaybe<Scalars['Boolean']>;
  agreement_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  include_listings?: InputMaybe<Scalars['Boolean']>;
  inherited_from?: InputMaybe<Scalars['uuid']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  is_frontend_updated?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  skip_up_sync?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Allocation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "allocation_type". All fields are combined with a logical 'AND'. */
export type Allocation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Allocation_Type_Bool_Exp>>;
  _not?: InputMaybe<Allocation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Allocation_Type_Bool_Exp>>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "allocation_type_enum". All fields are combined with logical 'AND'. */
export type Allocation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Allocation_Type_Enum>;
  _in?: InputMaybe<Array<Allocation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Allocation_Type_Enum>;
  _nin?: InputMaybe<Array<Allocation_Type_Enum>>;
};

/** input type for inserting data into table "allocation_type" */
export type Allocation_Type_Insert_Input = {
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "allocation_type" */
export type Allocation_Type_Obj_Rel_Insert_Input = {
  data: Allocation_Type_Insert_Input;
  on_conflict?: InputMaybe<Allocation_Type_On_Conflict>;
};

/** on_conflict condition type for table "allocation_type" */
export type Allocation_Type_On_Conflict = {
  constraint: Allocation_Type_Constraint;
  update_columns: Array<Allocation_Type_Update_Column>;
  where?: InputMaybe<Allocation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "allocation_type". */
export type Allocation_Type_Order_By = {
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allocation_type */
export type Allocation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "allocation_type" */
export type Allocation_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "allocation_type" */
export type Allocation_Type_Stream_Cursor_Input = {
  initial_value: Allocation_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allocation_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Allocation_Type_Updates = {
  _set?: InputMaybe<Allocation_Type_Set_Input>;
  where: Allocation_Type_Bool_Exp;
};

export type Allocation_Updates = {
  _set?: InputMaybe<Allocation_Set_Input>;
  where: Allocation_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apartment_Plan_Building_Append_Input = {
  plan_object?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "apartment_plan_building". All fields are combined with a logical 'AND'. */
export type Apartment_Plan_Building_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Plan_Building_Bool_Exp>>;
  _not?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
  _or?: InputMaybe<Array<Apartment_Plan_Building_Bool_Exp>>;
  building_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_object?: InputMaybe<Jsonb_Comparison_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apartment_Plan_Building_Delete_At_Path_Input = {
  plan_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apartment_Plan_Building_Delete_Elem_Input = {
  plan_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apartment_Plan_Building_Delete_Key_Input = {
  plan_object?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "apartment_plan_building" */
export type Apartment_Plan_Building_Insert_Input = {
  building_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_object?: InputMaybe<Scalars['jsonb']>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "apartment_plan_building" */
export type Apartment_Plan_Building_Obj_Rel_Insert_Input = {
  data: Apartment_Plan_Building_Insert_Input;
  on_conflict?: InputMaybe<Apartment_Plan_Building_On_Conflict>;
};

/** on_conflict condition type for table "apartment_plan_building" */
export type Apartment_Plan_Building_On_Conflict = {
  constraint: Apartment_Plan_Building_Constraint;
  update_columns: Array<Apartment_Plan_Building_Update_Column>;
  where?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
};

/** Ordering options when selecting data from "apartment_plan_building". */
export type Apartment_Plan_Building_Order_By = {
  building_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_object?: InputMaybe<Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apartment_plan_building */
export type Apartment_Plan_Building_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apartment_Plan_Building_Prepend_Input = {
  plan_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "apartment_plan_building" */
export type Apartment_Plan_Building_Set_Input = {
  building_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_object?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "apartment_plan_building" */
export type Apartment_Plan_Building_Stream_Cursor_Input = {
  initial_value: Apartment_Plan_Building_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apartment_Plan_Building_Stream_Cursor_Value_Input = {
  building_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_object?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Apartment_Plan_Building_Updates = {
  _append?: InputMaybe<Apartment_Plan_Building_Append_Input>;
  _delete_at_path?: InputMaybe<Apartment_Plan_Building_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apartment_Plan_Building_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apartment_Plan_Building_Delete_Key_Input>;
  _prepend?: InputMaybe<Apartment_Plan_Building_Prepend_Input>;
  _set?: InputMaybe<Apartment_Plan_Building_Set_Input>;
  where: Apartment_Plan_Building_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apartment_Plan_Property_Append_Input = {
  plan_object?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "apartment_plan_property". All fields are combined with a logical 'AND'. */
export type Apartment_Plan_Property_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Plan_Property_Bool_Exp>>;
  _not?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
  _or?: InputMaybe<Array<Apartment_Plan_Property_Bool_Exp>>;
  apartment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_object?: InputMaybe<Jsonb_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apartment_Plan_Property_Delete_At_Path_Input = {
  plan_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apartment_Plan_Property_Delete_Elem_Input = {
  plan_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apartment_Plan_Property_Delete_Key_Input = {
  plan_object?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "apartment_plan_property" */
export type Apartment_Plan_Property_Insert_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_object?: InputMaybe<Scalars['jsonb']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "apartment_plan_property" */
export type Apartment_Plan_Property_Obj_Rel_Insert_Input = {
  data: Apartment_Plan_Property_Insert_Input;
  on_conflict?: InputMaybe<Apartment_Plan_Property_On_Conflict>;
};

/** on_conflict condition type for table "apartment_plan_property" */
export type Apartment_Plan_Property_On_Conflict = {
  constraint: Apartment_Plan_Property_Constraint;
  update_columns: Array<Apartment_Plan_Property_Update_Column>;
  where?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
};

/** Ordering options when selecting data from "apartment_plan_property". */
export type Apartment_Plan_Property_Order_By = {
  apartment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_object?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: apartment_plan_property */
export type Apartment_Plan_Property_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apartment_Plan_Property_Prepend_Input = {
  plan_object?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "apartment_plan_property" */
export type Apartment_Plan_Property_Set_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_object?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "apartment_plan_property" */
export type Apartment_Plan_Property_Stream_Cursor_Input = {
  initial_value: Apartment_Plan_Property_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apartment_Plan_Property_Stream_Cursor_Value_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_object?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Apartment_Plan_Property_Updates = {
  _append?: InputMaybe<Apartment_Plan_Property_Append_Input>;
  _delete_at_path?: InputMaybe<Apartment_Plan_Property_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Apartment_Plan_Property_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Apartment_Plan_Property_Delete_Key_Input>;
  _prepend?: InputMaybe<Apartment_Plan_Property_Prepend_Input>;
  _set?: InputMaybe<Apartment_Plan_Property_Set_Input>;
  where: Apartment_Plan_Property_Bool_Exp;
};

export type Area_Plot_Aggregate_Bool_Exp = {
  count?: InputMaybe<Area_Plot_Aggregate_Bool_Exp_Count>;
};

export type Area_Plot_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Area_Plot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Area_Plot_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "area_plot" */
export type Area_Plot_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Area_Plot_Max_Order_By>;
  min?: InputMaybe<Area_Plot_Min_Order_By>;
};

/** input type for inserting array relation for remote table "area_plot" */
export type Area_Plot_Arr_Rel_Insert_Input = {
  data: Array<Area_Plot_Insert_Input>;
  on_conflict?: InputMaybe<Area_Plot_On_Conflict>;
};

/** Boolean expression to filter rows from the table "area_plot". All fields are combined with a logical 'AND'. */
export type Area_Plot_Bool_Exp = {
  _and?: InputMaybe<Array<Area_Plot_Bool_Exp>>;
  _not?: InputMaybe<Area_Plot_Bool_Exp>;
  _or?: InputMaybe<Array<Area_Plot_Bool_Exp>>;
  area_plot_type?: InputMaybe<Area_Plot_Type_Bool_Exp>;
  attachment?: InputMaybe<Attachment_Bool_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plot_polygon?: InputMaybe<Polygon_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  text_styling?: InputMaybe<Json_Comparison_Exp>;
  type?: InputMaybe<Area_Plot_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "area_plot" */
export type Area_Plot_Insert_Input = {
  area_plot_type?: InputMaybe<Area_Plot_Type_Obj_Rel_Insert_Input>;
  attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plot_polygon?: InputMaybe<Scalars['polygon']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  text_styling?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<Area_Plot_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "area_plot" */
export type Area_Plot_Max_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "area_plot" */
export type Area_Plot_Min_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "area_plot" */
export type Area_Plot_On_Conflict = {
  constraint: Area_Plot_Constraint;
  update_columns: Array<Area_Plot_Update_Column>;
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

/** Ordering options when selecting data from "area_plot". */
export type Area_Plot_Order_By = {
  area_plot_type?: InputMaybe<Area_Plot_Type_Order_By>;
  attachment?: InputMaybe<Attachment_Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plot_polygon?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  text_styling?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: area_plot */
export type Area_Plot_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "area_plot" */
export type Area_Plot_Set_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plot_polygon?: InputMaybe<Scalars['polygon']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  text_styling?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<Area_Plot_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "area_plot" */
export type Area_Plot_Stream_Cursor_Input = {
  initial_value: Area_Plot_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Area_Plot_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plot_polygon?: InputMaybe<Scalars['polygon']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  text_styling?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<Area_Plot_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "area_plot_type". All fields are combined with a logical 'AND'. */
export type Area_Plot_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Area_Plot_Type_Bool_Exp>>;
  _not?: InputMaybe<Area_Plot_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Area_Plot_Type_Bool_Exp>>;
  area_plots?: InputMaybe<Area_Plot_Bool_Exp>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "area_plot_type_enum". All fields are combined with logical 'AND'. */
export type Area_Plot_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Area_Plot_Type_Enum>;
  _in?: InputMaybe<Array<Area_Plot_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Area_Plot_Type_Enum>;
  _nin?: InputMaybe<Array<Area_Plot_Type_Enum>>;
};

/** input type for inserting data into table "area_plot_type" */
export type Area_Plot_Type_Insert_Input = {
  area_plots?: InputMaybe<Area_Plot_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "area_plot_type" */
export type Area_Plot_Type_Obj_Rel_Insert_Input = {
  data: Area_Plot_Type_Insert_Input;
  on_conflict?: InputMaybe<Area_Plot_Type_On_Conflict>;
};

/** on_conflict condition type for table "area_plot_type" */
export type Area_Plot_Type_On_Conflict = {
  constraint: Area_Plot_Type_Constraint;
  update_columns: Array<Area_Plot_Type_Update_Column>;
  where?: InputMaybe<Area_Plot_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "area_plot_type". */
export type Area_Plot_Type_Order_By = {
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: area_plot_type */
export type Area_Plot_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "area_plot_type" */
export type Area_Plot_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "area_plot_type" */
export type Area_Plot_Type_Stream_Cursor_Input = {
  initial_value: Area_Plot_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Area_Plot_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Area_Plot_Type_Updates = {
  _set?: InputMaybe<Area_Plot_Type_Set_Input>;
  where: Area_Plot_Type_Bool_Exp;
};

export type Area_Plot_Updates = {
  _set?: InputMaybe<Area_Plot_Set_Input>;
  where: Area_Plot_Bool_Exp;
};

export type Attachment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Attachment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Attachment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Attachment_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Attachment_Select_Column_Attachment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attachment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Attachment_Select_Column_Attachment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attachment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "attachment" */
export type Attachment_Aggregate_Order_By = {
  avg?: InputMaybe<Attachment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachment_Max_Order_By>;
  min?: InputMaybe<Attachment_Min_Order_By>;
  stddev?: InputMaybe<Attachment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attachment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attachment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attachment_Sum_Order_By>;
  var_pop?: InputMaybe<Attachment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attachment_Var_Samp_Order_By>;
  variance?: InputMaybe<Attachment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attachment" */
export type Attachment_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_On_Conflict>;
};

/** order by avg() on columns of table "attachment" */
export type Attachment_Avg_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment". All fields are combined with a logical 'AND'. */
export type Attachment_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Bool_Exp>>;
  add_to_contract?: InputMaybe<Boolean_Comparison_Exp>;
  area_plots?: InputMaybe<Area_Plot_Bool_Exp>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Bool_Exp>;
  attachment_config?: InputMaybe<Attachment_Config_Bool_Exp>;
  attachment_config_aggregate?: InputMaybe<Attachment_Config_Aggregate_Bool_Exp>;
  attachment_product_types?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
  attachment_product_types_aggregate?: InputMaybe<Attachment_Product_Type_Aggregate_Bool_Exp>;
  attachment_type?: InputMaybe<Attachment_Type_Bool_Exp>;
  buyer_project?: InputMaybe<Buyer_Project_Bool_Exp>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  download_url?: InputMaybe<String_Comparison_Exp>;
  edit_project_order?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
  floor_properties?: InputMaybe<Property_Bool_Exp>;
  floor_properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_thumbnail_generate?: InputMaybe<Boolean_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  linen_properties?: InputMaybe<Property_Bool_Exp>;
  linen_properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  logo_orgs?: InputMaybe<Organisation_Bool_Exp>;
  logo_orgs_aggregate?: InputMaybe<Organisation_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organisation_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_type?: InputMaybe<Attachment_Owner_Enum_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  projectByBackgroudAttachmentId?: InputMaybe<Project_Bool_Exp>;
  projectByLogoAttachmentId?: InputMaybe<Project_Bool_Exp>;
  projectByMasterPlanId?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  require_sign?: InputMaybe<Boolean_Comparison_Exp>;
  sf_down_sync_time?: InputMaybe<Bigint_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  stage_order?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
  thumbnails?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
  thumbnails_aggregate?: InputMaybe<Attachment_Thumbnail_Aggregate_Bool_Exp>;
  type?: InputMaybe<Attachment_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_by?: InputMaybe<Uuid_Comparison_Exp>;
  uploaded_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  uploaded_by_user?: InputMaybe<User_Bool_Exp>;
};

export type Attachment_Config_Aggregate_Bool_Exp = {
  count?: InputMaybe<Attachment_Config_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Config_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachment_Config_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "attachment_config" */
export type Attachment_Config_Aggregate_Order_By = {
  avg?: InputMaybe<Attachment_Config_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachment_Config_Max_Order_By>;
  min?: InputMaybe<Attachment_Config_Min_Order_By>;
  stddev?: InputMaybe<Attachment_Config_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attachment_Config_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attachment_Config_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attachment_Config_Sum_Order_By>;
  var_pop?: InputMaybe<Attachment_Config_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attachment_Config_Var_Samp_Order_By>;
  variance?: InputMaybe<Attachment_Config_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attachment_config" */
export type Attachment_Config_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Config_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_Config_On_Conflict>;
};

/** order by avg() on columns of table "attachment_config" */
export type Attachment_Config_Avg_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_config". All fields are combined with a logical 'AND'. */
export type Attachment_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Config_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Config_Bool_Exp>>;
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum_Comparison_Exp>;
  attachment_type?: InputMaybe<Attachment_Type_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fit?: InputMaybe<String_Comparison_Exp>;
  format?: InputMaybe<String_Comparison_Exp>;
  height?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  position?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  width?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "attachment_config" */
export type Attachment_Config_Inc_Input = {
  height?: InputMaybe<Scalars['numeric']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "attachment_config" */
export type Attachment_Config_Insert_Input = {
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  attachment_type?: InputMaybe<Attachment_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fit?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  position?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** order by max() on columns of table "attachment_config" */
export type Attachment_Config_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fit?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "attachment_config" */
export type Attachment_Config_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fit?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "attachment_config" */
export type Attachment_Config_On_Conflict = {
  constraint: Attachment_Config_Constraint;
  update_columns: Array<Attachment_Config_Update_Column>;
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_config". */
export type Attachment_Config_Order_By = {
  attachment_thumbnail_type?: InputMaybe<Order_By>;
  attachment_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fit?: InputMaybe<Order_By>;
  format?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_config */
export type Attachment_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "attachment_config" */
export type Attachment_Config_Set_Input = {
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  attachment_type?: InputMaybe<Attachment_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fit?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  position?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** order by stddev() on columns of table "attachment_config" */
export type Attachment_Config_Stddev_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "attachment_config" */
export type Attachment_Config_Stddev_Pop_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "attachment_config" */
export type Attachment_Config_Stddev_Samp_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attachment_config" */
export type Attachment_Config_Stream_Cursor_Input = {
  initial_value: Attachment_Config_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Config_Stream_Cursor_Value_Input = {
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  attachment_type?: InputMaybe<Attachment_Type_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fit?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  position?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "attachment_config" */
export type Attachment_Config_Sum_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

export type Attachment_Config_Updates = {
  _inc?: InputMaybe<Attachment_Config_Inc_Input>;
  _set?: InputMaybe<Attachment_Config_Set_Input>;
  where: Attachment_Config_Bool_Exp;
};

/** order by var_pop() on columns of table "attachment_config" */
export type Attachment_Config_Var_Pop_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "attachment_config" */
export type Attachment_Config_Var_Samp_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "attachment_config" */
export type Attachment_Config_Variance_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_edit_project_order". All fields are combined with a logical 'AND'. */
export type Attachment_Edit_Project_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Edit_Project_Order_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Edit_Project_Order_Bool_Exp>>;
  attachment?: InputMaybe<Attachment_Bool_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Insert_Input = {
  attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Obj_Rel_Insert_Input = {
  data: Attachment_Edit_Project_Order_Insert_Input;
  on_conflict?: InputMaybe<Attachment_Edit_Project_Order_On_Conflict>;
};

/** on_conflict condition type for table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_On_Conflict = {
  constraint: Attachment_Edit_Project_Order_Constraint;
  update_columns: Array<Attachment_Edit_Project_Order_Update_Column>;
  where?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_edit_project_order". */
export type Attachment_Edit_Project_Order_Order_By = {
  attachment?: InputMaybe<Attachment_Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_edit_project_order */
export type Attachment_Edit_Project_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Set_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Stream_Cursor_Input = {
  initial_value: Attachment_Edit_Project_Order_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Edit_Project_Order_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Attachment_Edit_Project_Order_Updates = {
  _inc?: InputMaybe<Attachment_Edit_Project_Order_Inc_Input>;
  _set?: InputMaybe<Attachment_Edit_Project_Order_Set_Input>;
  where: Attachment_Edit_Project_Order_Bool_Exp;
};

/** input type for incrementing numeric columns in table "attachment" */
export type Attachment_Inc_Input = {
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "attachment" */
export type Attachment_Insert_Input = {
  add_to_contract?: InputMaybe<Scalars['Boolean']>;
  area_plots?: InputMaybe<Area_Plot_Arr_Rel_Insert_Input>;
  attachment_config?: InputMaybe<Attachment_Config_Arr_Rel_Insert_Input>;
  attachment_product_types?: InputMaybe<Attachment_Product_Type_Arr_Rel_Insert_Input>;
  attachment_type?: InputMaybe<Attachment_Type_Obj_Rel_Insert_Input>;
  buyer_project?: InputMaybe<Buyer_Project_Obj_Rel_Insert_Input>;
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  download_url?: InputMaybe<Scalars['String']>;
  edit_project_order?: InputMaybe<Attachment_Edit_Project_Order_Obj_Rel_Insert_Input>;
  floor_properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_thumbnail_generate?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  linen_properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  logo_orgs?: InputMaybe<Organisation_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  owner_type?: InputMaybe<Attachment_Owner_Enum>;
  path?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  projectByBackgroudAttachmentId?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  projectByLogoAttachmentId?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  projectByMasterPlanId?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  require_sign?: InputMaybe<Scalars['Boolean']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  sf_id?: InputMaybe<Scalars['String']>;
  stage_order?: InputMaybe<Attachment_Stage_Order_Obj_Rel_Insert_Input>;
  thumbnails?: InputMaybe<Attachment_Thumbnail_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Attachment_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_by?: InputMaybe<Scalars['uuid']>;
  uploaded_by_team_id?: InputMaybe<Scalars['uuid']>;
  uploaded_by_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "attachment" */
export type Attachment_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  download_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_by?: InputMaybe<Order_By>;
  uploaded_by_team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "attachment" */
export type Attachment_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  download_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_by?: InputMaybe<Order_By>;
  uploaded_by_team_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "attachment" */
export type Attachment_Obj_Rel_Insert_Input = {
  data: Attachment_Insert_Input;
  on_conflict?: InputMaybe<Attachment_On_Conflict>;
};

/** on_conflict condition type for table "attachment" */
export type Attachment_On_Conflict = {
  constraint: Attachment_Constraint;
  update_columns: Array<Attachment_Update_Column>;
  where?: InputMaybe<Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment". */
export type Attachment_Order_By = {
  add_to_contract?: InputMaybe<Order_By>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Order_By>;
  attachment_config_aggregate?: InputMaybe<Attachment_Config_Aggregate_Order_By>;
  attachment_product_types_aggregate?: InputMaybe<Attachment_Product_Type_Aggregate_Order_By>;
  attachment_type?: InputMaybe<Attachment_Type_Order_By>;
  buyer_project?: InputMaybe<Buyer_Project_Order_By>;
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  download_url?: InputMaybe<Order_By>;
  edit_project_order?: InputMaybe<Attachment_Edit_Project_Order_Order_By>;
  floor_properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_thumbnail_generate?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  linen_properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  listing_id?: InputMaybe<Order_By>;
  logo_orgs_aggregate?: InputMaybe<Organisation_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organisation_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  owner_type?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  projectByBackgroudAttachmentId?: InputMaybe<Project_Order_By>;
  projectByLogoAttachmentId?: InputMaybe<Project_Order_By>;
  projectByMasterPlanId?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  require_sign?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  stage_order?: InputMaybe<Attachment_Stage_Order_Order_By>;
  thumbnails_aggregate?: InputMaybe<Attachment_Thumbnail_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_by?: InputMaybe<Order_By>;
  uploaded_by_team_id?: InputMaybe<Order_By>;
  uploaded_by_user?: InputMaybe<User_Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_owner". All fields are combined with a logical 'AND'. */
export type Attachment_Owner_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Owner_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Owner_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Owner_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "attachment_owner_enum". All fields are combined with logical 'AND'. */
export type Attachment_Owner_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Attachment_Owner_Enum>;
  _in?: InputMaybe<Array<Attachment_Owner_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Attachment_Owner_Enum>;
  _nin?: InputMaybe<Array<Attachment_Owner_Enum>>;
};

/** input type for inserting data into table "attachment_owner" */
export type Attachment_Owner_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "attachment_owner" */
export type Attachment_Owner_On_Conflict = {
  constraint: Attachment_Owner_Constraint;
  update_columns: Array<Attachment_Owner_Update_Column>;
  where?: InputMaybe<Attachment_Owner_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_owner". */
export type Attachment_Owner_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_owner */
export type Attachment_Owner_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "attachment_owner" */
export type Attachment_Owner_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "attachment_owner" */
export type Attachment_Owner_Stream_Cursor_Input = {
  initial_value: Attachment_Owner_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Owner_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Attachment_Owner_Updates = {
  _set?: InputMaybe<Attachment_Owner_Set_Input>;
  where: Attachment_Owner_Bool_Exp;
};

/** primary key columns input for table: attachment */
export type Attachment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export type Attachment_Product_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Attachment_Product_Type_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Product_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "attachment_product_type" */
export type Attachment_Product_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachment_Product_Type_Max_Order_By>;
  min?: InputMaybe<Attachment_Product_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "attachment_product_type" */
export type Attachment_Product_Type_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Product_Type_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_Product_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "attachment_product_type". All fields are combined with a logical 'AND'. */
export type Attachment_Product_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Product_Type_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Product_Type_Bool_Exp>>;
  attachment?: InputMaybe<Attachment_Bool_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "attachment_product_type" */
export type Attachment_Product_Type_Insert_Input = {
  attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  product_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "attachment_product_type" */
export type Attachment_Product_Type_Max_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "attachment_product_type" */
export type Attachment_Product_Type_Min_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "attachment_product_type" */
export type Attachment_Product_Type_On_Conflict = {
  constraint: Attachment_Product_Type_Constraint;
  update_columns: Array<Attachment_Product_Type_Update_Column>;
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_product_type". */
export type Attachment_Product_Type_Order_By = {
  attachment?: InputMaybe<Attachment_Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_product_type */
export type Attachment_Product_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "attachment_product_type" */
export type Attachment_Product_Type_Set_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  product_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "attachment_product_type" */
export type Attachment_Product_Type_Stream_Cursor_Input = {
  initial_value: Attachment_Product_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Product_Type_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  product_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Attachment_Product_Type_Updates = {
  _set?: InputMaybe<Attachment_Product_Type_Set_Input>;
  where: Attachment_Product_Type_Bool_Exp;
};

/** input type for updating data in table "attachment" */
export type Attachment_Set_Input = {
  add_to_contract?: InputMaybe<Scalars['Boolean']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  download_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_thumbnail_generate?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  owner_type?: InputMaybe<Attachment_Owner_Enum>;
  path?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  require_sign?: InputMaybe<Scalars['Boolean']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  sf_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Attachment_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_by?: InputMaybe<Scalars['uuid']>;
  uploaded_by_team_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "attachment_stage_order". All fields are combined with a logical 'AND'. */
export type Attachment_Stage_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Stage_Order_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Stage_Order_Bool_Exp>>;
  attachment?: InputMaybe<Attachment_Bool_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "attachment_stage_order" */
export type Attachment_Stage_Order_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "attachment_stage_order" */
export type Attachment_Stage_Order_Insert_Input = {
  attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "attachment_stage_order" */
export type Attachment_Stage_Order_Obj_Rel_Insert_Input = {
  data: Attachment_Stage_Order_Insert_Input;
  on_conflict?: InputMaybe<Attachment_Stage_Order_On_Conflict>;
};

/** on_conflict condition type for table "attachment_stage_order" */
export type Attachment_Stage_Order_On_Conflict = {
  constraint: Attachment_Stage_Order_Constraint;
  update_columns: Array<Attachment_Stage_Order_Update_Column>;
  where?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_stage_order". */
export type Attachment_Stage_Order_Order_By = {
  attachment?: InputMaybe<Attachment_Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_stage_order */
export type Attachment_Stage_Order_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "attachment_stage_order" */
export type Attachment_Stage_Order_Set_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "attachment_stage_order" */
export type Attachment_Stage_Order_Stream_Cursor_Input = {
  initial_value: Attachment_Stage_Order_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Stage_Order_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Attachment_Stage_Order_Updates = {
  _inc?: InputMaybe<Attachment_Stage_Order_Inc_Input>;
  _set?: InputMaybe<Attachment_Stage_Order_Set_Input>;
  where: Attachment_Stage_Order_Bool_Exp;
};

/** order by stddev() on columns of table "attachment" */
export type Attachment_Stddev_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "attachment" */
export type Attachment_Stddev_Pop_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "attachment" */
export type Attachment_Stddev_Samp_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attachment" */
export type Attachment_Stream_Cursor_Input = {
  initial_value: Attachment_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Stream_Cursor_Value_Input = {
  add_to_contract?: InputMaybe<Scalars['Boolean']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  download_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_thumbnail_generate?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  owner_type?: InputMaybe<Attachment_Owner_Enum>;
  path?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  require_sign?: InputMaybe<Scalars['Boolean']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  sf_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Attachment_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_by?: InputMaybe<Scalars['uuid']>;
  uploaded_by_team_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "attachment" */
export type Attachment_Sum_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

export type Attachment_Thumbnail_Aggregate_Bool_Exp = {
  count?: InputMaybe<Attachment_Thumbnail_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Thumbnail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Aggregate_Order_By = {
  avg?: InputMaybe<Attachment_Thumbnail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachment_Thumbnail_Max_Order_By>;
  min?: InputMaybe<Attachment_Thumbnail_Min_Order_By>;
  stddev?: InputMaybe<Attachment_Thumbnail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attachment_Thumbnail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attachment_Thumbnail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attachment_Thumbnail_Sum_Order_By>;
  var_pop?: InputMaybe<Attachment_Thumbnail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attachment_Thumbnail_Var_Samp_Order_By>;
  variance?: InputMaybe<Attachment_Thumbnail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attachment_thumbnail" */
export type Attachment_Thumbnail_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Thumbnail_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_Thumbnail_On_Conflict>;
};

/** order by avg() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Avg_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_thumbnail". All fields are combined with a logical 'AND'. */
export type Attachment_Thumbnail_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Thumbnail_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Thumbnail_Bool_Exp>>;
  attachment?: InputMaybe<Attachment_Bool_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum_Comparison_Exp>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fit?: InputMaybe<String_Comparison_Exp>;
  height?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  width?: InputMaybe<Numeric_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "attachment_thumbnail_count". All fields are combined with a logical 'AND'. */
export type Attachment_Thumbnail_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Thumbnail_Count_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Thumbnail_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Thumbnail_Count_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  thumbnail_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "attachment_thumbnail_count". */
export type Attachment_Thumbnail_Count_Order_By = {
  id?: InputMaybe<Order_By>;
  thumbnail_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attachment_thumbnail_count" */
export type Attachment_Thumbnail_Count_Stream_Cursor_Input = {
  initial_value: Attachment_Thumbnail_Count_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Thumbnail_Count_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  thumbnail_count?: InputMaybe<Scalars['bigint']>;
};

/** input type for incrementing numeric columns in table "attachment_thumbnail" */
export type Attachment_Thumbnail_Inc_Input = {
  height?: InputMaybe<Scalars['numeric']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "attachment_thumbnail" */
export type Attachment_Thumbnail_Insert_Input = {
  attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fit?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** order by max() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Max_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fit?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Min_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fit?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "attachment_thumbnail" */
export type Attachment_Thumbnail_On_Conflict = {
  constraint: Attachment_Thumbnail_Constraint;
  update_columns: Array<Attachment_Thumbnail_Update_Column>;
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_thumbnail". */
export type Attachment_Thumbnail_Order_By = {
  attachment?: InputMaybe<Attachment_Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  attachment_thumbnail_type?: InputMaybe<Order_By>;
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fit?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_thumbnail */
export type Attachment_Thumbnail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "attachment_thumbnail" */
export type Attachment_Thumbnail_Set_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fit?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** order by stddev() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Stddev_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Stddev_Pop_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Stddev_Samp_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attachment_thumbnail" */
export type Attachment_Thumbnail_Stream_Cursor_Input = {
  initial_value: Attachment_Thumbnail_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Thumbnail_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  attachment_thumbnail_type?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fit?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  width?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Sum_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_thumbnail_type". All fields are combined with a logical 'AND'. */
export type Attachment_Thumbnail_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Thumbnail_Type_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Thumbnail_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "attachment_thumbnail_type_enum". All fields are combined with logical 'AND'. */
export type Attachment_Thumbnail_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  _in?: InputMaybe<Array<Attachment_Thumbnail_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Attachment_Thumbnail_Type_Enum>;
  _nin?: InputMaybe<Array<Attachment_Thumbnail_Type_Enum>>;
};

/** input type for inserting data into table "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_On_Conflict = {
  constraint: Attachment_Thumbnail_Type_Constraint;
  update_columns: Array<Attachment_Thumbnail_Type_Update_Column>;
  where?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_thumbnail_type". */
export type Attachment_Thumbnail_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_thumbnail_type */
export type Attachment_Thumbnail_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_Stream_Cursor_Input = {
  initial_value: Attachment_Thumbnail_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Thumbnail_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Attachment_Thumbnail_Type_Updates = {
  _set?: InputMaybe<Attachment_Thumbnail_Type_Set_Input>;
  where: Attachment_Thumbnail_Type_Bool_Exp;
};

export type Attachment_Thumbnail_Updates = {
  _inc?: InputMaybe<Attachment_Thumbnail_Inc_Input>;
  _set?: InputMaybe<Attachment_Thumbnail_Set_Input>;
  where: Attachment_Thumbnail_Bool_Exp;
};

/** order by var_pop() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Var_Pop_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Var_Samp_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "attachment_thumbnail" */
export type Attachment_Thumbnail_Variance_Order_By = {
  height?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_type". All fields are combined with a logical 'AND'. */
export type Attachment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Type_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Type_Bool_Exp>>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "attachment_type_enum". All fields are combined with logical 'AND'. */
export type Attachment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Attachment_Type_Enum>;
  _in?: InputMaybe<Array<Attachment_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Attachment_Type_Enum>;
  _nin?: InputMaybe<Array<Attachment_Type_Enum>>;
};

/** input type for inserting data into table "attachment_type" */
export type Attachment_Type_Insert_Input = {
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "attachment_type" */
export type Attachment_Type_Obj_Rel_Insert_Input = {
  data: Attachment_Type_Insert_Input;
  on_conflict?: InputMaybe<Attachment_Type_On_Conflict>;
};

/** on_conflict condition type for table "attachment_type" */
export type Attachment_Type_On_Conflict = {
  constraint: Attachment_Type_Constraint;
  update_columns: Array<Attachment_Type_Update_Column>;
  where?: InputMaybe<Attachment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_type". */
export type Attachment_Type_Order_By = {
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_type */
export type Attachment_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "attachment_type" */
export type Attachment_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "attachment_type" */
export type Attachment_Type_Stream_Cursor_Input = {
  initial_value: Attachment_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Attachment_Type_Updates = {
  _set?: InputMaybe<Attachment_Type_Set_Input>;
  where: Attachment_Type_Bool_Exp;
};

export type Attachment_Updates = {
  _inc?: InputMaybe<Attachment_Inc_Input>;
  _set?: InputMaybe<Attachment_Set_Input>;
  where: Attachment_Bool_Exp;
};

/** order by var_pop() on columns of table "attachment" */
export type Attachment_Var_Pop_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "attachment" */
export type Attachment_Var_Samp_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "attachment" */
export type Attachment_Variance_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Buyer_Portal_Append_Input = {
  sign_up_config?: InputMaybe<Scalars['jsonb']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "buyer_portal". All fields are combined with a logical 'AND'. */
export type Buyer_Portal_Bool_Exp = {
  _and?: InputMaybe<Array<Buyer_Portal_Bool_Exp>>;
  _not?: InputMaybe<Buyer_Portal_Bool_Exp>;
  _or?: InputMaybe<Array<Buyer_Portal_Bool_Exp>>;
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Bool_Exp>;
  acl_deal_user_team?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
  acl_deal_user_team_aggregate?: InputMaybe<Acl_Deal_User_Team_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  sign_up_config?: InputMaybe<Jsonb_Comparison_Exp>;
  step_config?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Buyer_Portal_Delete_At_Path_Input = {
  sign_up_config?: InputMaybe<Array<Scalars['String']>>;
  step_config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Buyer_Portal_Delete_Elem_Input = {
  sign_up_config?: InputMaybe<Scalars['Int']>;
  step_config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Buyer_Portal_Delete_Key_Input = {
  sign_up_config?: InputMaybe<Scalars['String']>;
  step_config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "buyer_portal" */
export type Buyer_Portal_Insert_Input = {
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Arr_Rel_Insert_Input>;
  acl_deal_user_team?: InputMaybe<Acl_Deal_User_Team_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  sign_up_config?: InputMaybe<Scalars['jsonb']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "buyer_portal" */
export type Buyer_Portal_On_Conflict = {
  constraint: Buyer_Portal_Constraint;
  update_columns: Array<Buyer_Portal_Update_Column>;
  where?: InputMaybe<Buyer_Portal_Bool_Exp>;
};

/** Ordering options when selecting data from "buyer_portal". */
export type Buyer_Portal_Order_By = {
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Order_By>;
  acl_deal_user_team_aggregate?: InputMaybe<Acl_Deal_User_Team_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  sign_up_config?: InputMaybe<Order_By>;
  step_config?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: buyer_portal */
export type Buyer_Portal_Pk_Columns_Input = {
  deal_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Buyer_Portal_Prepend_Input = {
  sign_up_config?: InputMaybe<Scalars['jsonb']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "buyer_portal" */
export type Buyer_Portal_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  sign_up_config?: InputMaybe<Scalars['jsonb']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "buyer_portal" */
export type Buyer_Portal_Stream_Cursor_Input = {
  initial_value: Buyer_Portal_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buyer_Portal_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  sign_up_config?: InputMaybe<Scalars['jsonb']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Buyer_Portal_Updates = {
  _append?: InputMaybe<Buyer_Portal_Append_Input>;
  _delete_at_path?: InputMaybe<Buyer_Portal_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Buyer_Portal_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Buyer_Portal_Delete_Key_Input>;
  _prepend?: InputMaybe<Buyer_Portal_Prepend_Input>;
  _set?: InputMaybe<Buyer_Portal_Set_Input>;
  where: Buyer_Portal_Bool_Exp;
};

export type Buyer_Project_Aggregate_Bool_Exp = {
  count?: InputMaybe<Buyer_Project_Aggregate_Bool_Exp_Count>;
};

export type Buyer_Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Buyer_Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "buyer_project" */
export type Buyer_Project_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Buyer_Project_Max_Order_By>;
  min?: InputMaybe<Buyer_Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "buyer_project" */
export type Buyer_Project_Arr_Rel_Insert_Input = {
  data: Array<Buyer_Project_Insert_Input>;
  on_conflict?: InputMaybe<Buyer_Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "buyer_project". All fields are combined with a logical 'AND'. */
export type Buyer_Project_Bool_Exp = {
  _and?: InputMaybe<Array<Buyer_Project_Bool_Exp>>;
  _not?: InputMaybe<Buyer_Project_Bool_Exp>;
  _or?: InputMaybe<Array<Buyer_Project_Bool_Exp>>;
  agent_team?: InputMaybe<Organisation_Bool_Exp>;
  agent_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  agent_user?: InputMaybe<User_Bool_Exp>;
  agent_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  buyer_id?: InputMaybe<Uuid_Comparison_Exp>;
  buyer_user?: InputMaybe<User_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "buyer_project" */
export type Buyer_Project_Insert_Input = {
  agent_team?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  agent_team_id?: InputMaybe<Scalars['uuid']>;
  agent_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  agent_user_id?: InputMaybe<Scalars['uuid']>;
  buyer_id?: InputMaybe<Scalars['uuid']>;
  buyer_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "buyer_project" */
export type Buyer_Project_Max_Order_By = {
  agent_team_id?: InputMaybe<Order_By>;
  agent_user_id?: InputMaybe<Order_By>;
  buyer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "buyer_project" */
export type Buyer_Project_Min_Order_By = {
  agent_team_id?: InputMaybe<Order_By>;
  agent_user_id?: InputMaybe<Order_By>;
  buyer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "buyer_project" */
export type Buyer_Project_Obj_Rel_Insert_Input = {
  data: Buyer_Project_Insert_Input;
  on_conflict?: InputMaybe<Buyer_Project_On_Conflict>;
};

/** on_conflict condition type for table "buyer_project" */
export type Buyer_Project_On_Conflict = {
  constraint: Buyer_Project_Constraint;
  update_columns: Array<Buyer_Project_Update_Column>;
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "buyer_project". */
export type Buyer_Project_Order_By = {
  agent_team?: InputMaybe<Organisation_Order_By>;
  agent_team_id?: InputMaybe<Order_By>;
  agent_user?: InputMaybe<User_Order_By>;
  agent_user_id?: InputMaybe<Order_By>;
  buyer_id?: InputMaybe<Order_By>;
  buyer_user?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: buyer_project */
export type Buyer_Project_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "buyer_project" */
export type Buyer_Project_Set_Input = {
  agent_team_id?: InputMaybe<Scalars['uuid']>;
  agent_user_id?: InputMaybe<Scalars['uuid']>;
  buyer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "buyer_project" */
export type Buyer_Project_Stream_Cursor_Input = {
  initial_value: Buyer_Project_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buyer_Project_Stream_Cursor_Value_Input = {
  agent_team_id?: InputMaybe<Scalars['uuid']>;
  agent_user_id?: InputMaybe<Scalars['uuid']>;
  buyer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Buyer_Project_Updates = {
  _set?: InputMaybe<Buyer_Project_Set_Input>;
  where: Buyer_Project_Bool_Exp;
};

/** Boolean expression to filter rows from the table "buyer_type". All fields are combined with a logical 'AND'. */
export type Buyer_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Buyer_Type_Bool_Exp>>;
  _not?: InputMaybe<Buyer_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Buyer_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "buyer_type_enum". All fields are combined with logical 'AND'. */
export type Buyer_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Buyer_Type_Enum>;
  _in?: InputMaybe<Array<Buyer_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Buyer_Type_Enum>;
  _nin?: InputMaybe<Array<Buyer_Type_Enum>>;
};

/** input type for inserting data into table "buyer_type" */
export type Buyer_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "buyer_type" */
export type Buyer_Type_On_Conflict = {
  constraint: Buyer_Type_Constraint;
  update_columns: Array<Buyer_Type_Update_Column>;
  where?: InputMaybe<Buyer_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "buyer_type". */
export type Buyer_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: buyer_type */
export type Buyer_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "buyer_type" */
export type Buyer_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "buyer_type" */
export type Buyer_Type_Stream_Cursor_Input = {
  initial_value: Buyer_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buyer_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Buyer_Type_Updates = {
  _set?: InputMaybe<Buyer_Type_Set_Input>;
  where: Buyer_Type_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cache_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "cache". All fields are combined with a logical 'AND'. */
export type Cache_Bool_Exp = {
  _and?: InputMaybe<Array<Cache_Bool_Exp>>;
  _not?: InputMaybe<Cache_Bool_Exp>;
  _or?: InputMaybe<Array<Cache_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Bigint_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cache_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cache_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cache_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "cache" */
export type Cache_Inc_Input = {
  expires_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "cache" */
export type Cache_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** on_conflict condition type for table "cache" */
export type Cache_On_Conflict = {
  constraint: Cache_Constraint;
  update_columns: Array<Cache_Update_Column>;
  where?: InputMaybe<Cache_Bool_Exp>;
};

/** Ordering options when selecting data from "cache". */
export type Cache_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cache */
export type Cache_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cache_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "cache" */
export type Cache_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "cache" */
export type Cache_Stream_Cursor_Input = {
  initial_value: Cache_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cache_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

export type Cache_Updates = {
  _append?: InputMaybe<Cache_Append_Input>;
  _delete_at_path?: InputMaybe<Cache_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cache_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cache_Delete_Key_Input>;
  _inc?: InputMaybe<Cache_Inc_Input>;
  _prepend?: InputMaybe<Cache_Prepend_Input>;
  _set?: InputMaybe<Cache_Set_Input>;
  where: Cache_Bool_Exp;
};

/** Boolean expression to filter rows from the table "change_in". All fields are combined with a logical 'AND'. */
export type Change_In_Bool_Exp = {
  _and?: InputMaybe<Array<Change_In_Bool_Exp>>;
  _not?: InputMaybe<Change_In_Bool_Exp>;
  _or?: InputMaybe<Array<Change_In_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  change?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  depends_on_sf_id?: InputMaybe<String_Comparison_Exp>;
  error_msg?: InputMaybe<String_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_bulk_import?: InputMaybe<Boolean_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  payload_timestamp?: InputMaybe<Bigint_Comparison_Exp>;
  record_type?: InputMaybe<String_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  should_be_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Change_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "change_in" */
export type Change_In_Inc_Input = {
  payload_timestamp?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "change_in" */
export type Change_In_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  change?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  depends_on_sf_id?: InputMaybe<Scalars['String']>;
  error_msg?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bulk_import?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  payload_timestamp?: InputMaybe<Scalars['bigint']>;
  record_type?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  should_be_deleted?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Change_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** on_conflict condition type for table "change_in" */
export type Change_In_On_Conflict = {
  constraint: Change_In_Constraint;
  update_columns: Array<Change_In_Update_Column>;
  where?: InputMaybe<Change_In_Bool_Exp>;
};

/** Ordering options when selecting data from "change_in". */
export type Change_In_Order_By = {
  action?: InputMaybe<Order_By>;
  change?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  depends_on_sf_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bulk_import?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  payload_timestamp?: InputMaybe<Order_By>;
  record_type?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  should_be_deleted?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: change_in */
export type Change_In_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "change_in" */
export type Change_In_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  change?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  depends_on_sf_id?: InputMaybe<Scalars['String']>;
  error_msg?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bulk_import?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  payload_timestamp?: InputMaybe<Scalars['bigint']>;
  record_type?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  should_be_deleted?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Change_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "change_in" */
export type Change_In_Stream_Cursor_Input = {
  initial_value: Change_In_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_In_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  change?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  depends_on_sf_id?: InputMaybe<Scalars['String']>;
  error_msg?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bulk_import?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  payload_timestamp?: InputMaybe<Scalars['bigint']>;
  record_type?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  should_be_deleted?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Change_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

export type Change_In_Updates = {
  _inc?: InputMaybe<Change_In_Inc_Input>;
  _set?: InputMaybe<Change_In_Set_Input>;
  where: Change_In_Bool_Exp;
};

/** Boolean expression to filter rows from the table "change_status". All fields are combined with a logical 'AND'. */
export type Change_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Change_Status_Bool_Exp>>;
  _not?: InputMaybe<Change_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Change_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "change_status_enum". All fields are combined with logical 'AND'. */
export type Change_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Change_Status_Enum>;
  _in?: InputMaybe<Array<Change_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Change_Status_Enum>;
  _nin?: InputMaybe<Array<Change_Status_Enum>>;
};

/** input type for inserting data into table "change_status" */
export type Change_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "change_status" */
export type Change_Status_On_Conflict = {
  constraint: Change_Status_Constraint;
  update_columns: Array<Change_Status_Update_Column>;
  where?: InputMaybe<Change_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "change_status". */
export type Change_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: change_status */
export type Change_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "change_status" */
export type Change_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "change_status" */
export type Change_Status_Stream_Cursor_Input = {
  initial_value: Change_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Change_Status_Updates = {
  _set?: InputMaybe<Change_Status_Set_Input>;
  where: Change_Status_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Code_Append_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "code". All fields are combined with a logical 'AND'. */
export type Code_Bool_Exp = {
  _and?: InputMaybe<Array<Code_Bool_Exp>>;
  _not?: InputMaybe<Code_Bool_Exp>;
  _or?: InputMaybe<Array<Code_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  code_user?: InputMaybe<User_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  expires_at?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_verified?: InputMaybe<Boolean_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Code_Delete_At_Path_Input = {
  request?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Code_Delete_Elem_Input = {
  request?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Code_Delete_Key_Input = {
  request?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "code" */
export type Code_Inc_Input = {
  expires_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "code" */
export type Code_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  code_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "code" */
export type Code_On_Conflict = {
  constraint: Code_Constraint;
  update_columns: Array<Code_Update_Column>;
  where?: InputMaybe<Code_Bool_Exp>;
};

/** Ordering options when selecting data from "code". */
export type Code_Order_By = {
  code?: InputMaybe<Order_By>;
  code_user?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_verified?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: code */
export type Code_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Code_Prepend_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "code" */
export type Code_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "code" */
export type Code_Stream_Cursor_Input = {
  initial_value: Code_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  expires_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Code_Updates = {
  _append?: InputMaybe<Code_Append_Input>;
  _delete_at_path?: InputMaybe<Code_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Code_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Code_Delete_Key_Input>;
  _inc?: InputMaybe<Code_Inc_Input>;
  _prepend?: InputMaybe<Code_Prepend_Input>;
  _set?: InputMaybe<Code_Set_Input>;
  where: Code_Bool_Exp;
};

export type Commission_Aggregate_Bool_Exp = {
  count?: InputMaybe<Commission_Aggregate_Bool_Exp_Count>;
};

export type Commission_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Commission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Commission_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "commission" */
export type Commission_Aggregate_Order_By = {
  avg?: InputMaybe<Commission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Commission_Max_Order_By>;
  min?: InputMaybe<Commission_Min_Order_By>;
  stddev?: InputMaybe<Commission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Commission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Commission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Commission_Sum_Order_By>;
  var_pop?: InputMaybe<Commission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Commission_Var_Samp_Order_By>;
  variance?: InputMaybe<Commission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "commission" */
export type Commission_Arr_Rel_Insert_Input = {
  data: Array<Commission_Insert_Input>;
  on_conflict?: InputMaybe<Commission_On_Conflict>;
};

/** order by avg() on columns of table "commission" */
export type Commission_Avg_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "commission". All fields are combined with a logical 'AND'. */
export type Commission_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Bool_Exp>>;
  _not?: InputMaybe<Commission_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Bool_Exp>>;
  agency_agreement?: InputMaybe<Agreement_Bool_Exp>;
  agency_agreement_id?: InputMaybe<Uuid_Comparison_Exp>;
  agent_amount?: InputMaybe<Numeric_Comparison_Exp>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Numeric_Comparison_Exp>;
  agent_commission_value_less_sub_agent?: InputMaybe<Numeric_Comparison_Exp>;
  agent_exchange_invoice_paid_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  agent_exchange_payable_amount?: InputMaybe<Numeric_Comparison_Exp>;
  agent_exchange_payable_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  agent_incentive_amount?: InputMaybe<Numeric_Comparison_Exp>;
  agent_incentive_payable?: InputMaybe<Numeric_Comparison_Exp>;
  agent_incentive_rate?: InputMaybe<Numeric_Comparison_Exp>;
  agent_outstanding_balance?: InputMaybe<Numeric_Comparison_Exp>;
  agent_payment_notes?: InputMaybe<String_Comparison_Exp>;
  agent_rate?: InputMaybe<Numeric_Comparison_Exp>;
  agent_settlement_invoice_paid_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  agent_settlement_payable_amount?: InputMaybe<Numeric_Comparison_Exp>;
  agent_settlement_payable_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  exchange_invoice_agent?: InputMaybe<String_Comparison_Exp>;
  exchange_invoice_sub_agent?: InputMaybe<String_Comparison_Exp>;
  external_commission_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_name?: InputMaybe<String_Comparison_Exp>;
  settlement_invoice_agent?: InputMaybe<String_Comparison_Exp>;
  settlement_invoice_sub_agent?: InputMaybe<String_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  sub_agency_agreement?: InputMaybe<Agreement_Bool_Exp>;
  sub_agency_agreement_id?: InputMaybe<Uuid_Comparison_Exp>;
  sub_agent_amount?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  sub_agent_exchange_payable_amount?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_incentive_amount?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_incentive_rate?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_outstanding_balance?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_payment_notes?: InputMaybe<String_Comparison_Exp>;
  sub_agent_rate?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  sub_agent_settlement_payable_amount?: InputMaybe<Numeric_Comparison_Exp>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "commission" */
export type Commission_Inc_Input = {
  agent_amount?: InputMaybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  agent_incentive_payable?: InputMaybe<Scalars['numeric']>;
  agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  agent_rate?: InputMaybe<Scalars['numeric']>;
  agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  sub_agent_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  sub_agent_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "commission" */
export type Commission_Insert_Input = {
  agency_agreement?: InputMaybe<Agreement_Obj_Rel_Insert_Input>;
  agency_agreement_id?: InputMaybe<Scalars['uuid']>;
  agent_amount?: InputMaybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_exchange_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  agent_incentive_payable?: InputMaybe<Scalars['numeric']>;
  agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  agent_payment_notes?: InputMaybe<Scalars['String']>;
  agent_rate?: InputMaybe<Scalars['numeric']>;
  agent_settlement_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  exchange_invoice_agent?: InputMaybe<Scalars['String']>;
  exchange_invoice_sub_agent?: InputMaybe<Scalars['String']>;
  external_commission_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  property_name?: InputMaybe<Scalars['String']>;
  settlement_invoice_agent?: InputMaybe<Scalars['String']>;
  settlement_invoice_sub_agent?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sub_agency_agreement?: InputMaybe<Agreement_Obj_Rel_Insert_Input>;
  sub_agency_agreement_id?: InputMaybe<Scalars['uuid']>;
  sub_agent_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  sub_agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  sub_agent_payment_notes?: InputMaybe<Scalars['String']>;
  sub_agent_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  sub_agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "commission" */
export type Commission_Max_Order_By = {
  agency_agreement_id?: InputMaybe<Order_By>;
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_invoice_paid_date?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_payment_notes?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_invoice_paid_date?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  exchange_invoice_agent?: InputMaybe<Order_By>;
  exchange_invoice_sub_agent?: InputMaybe<Order_By>;
  external_commission_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  settlement_invoice_agent?: InputMaybe<Order_By>;
  settlement_invoice_sub_agent?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sub_agency_agreement_id?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_payment_notes?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "commission" */
export type Commission_Min_Order_By = {
  agency_agreement_id?: InputMaybe<Order_By>;
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_invoice_paid_date?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_payment_notes?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_invoice_paid_date?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  exchange_invoice_agent?: InputMaybe<Order_By>;
  exchange_invoice_sub_agent?: InputMaybe<Order_By>;
  external_commission_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  settlement_invoice_agent?: InputMaybe<Order_By>;
  settlement_invoice_sub_agent?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sub_agency_agreement_id?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_payment_notes?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "commission" */
export type Commission_On_Conflict = {
  constraint: Commission_Constraint;
  update_columns: Array<Commission_Update_Column>;
  where?: InputMaybe<Commission_Bool_Exp>;
};

/** Ordering options when selecting data from "commission". */
export type Commission_Order_By = {
  agency_agreement?: InputMaybe<Agreement_Order_By>;
  agency_agreement_id?: InputMaybe<Order_By>;
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_invoice_paid_date?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_payment_notes?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_invoice_paid_date?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  exchange_invoice_agent?: InputMaybe<Order_By>;
  exchange_invoice_sub_agent?: InputMaybe<Order_By>;
  external_commission_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  property_name?: InputMaybe<Order_By>;
  settlement_invoice_agent?: InputMaybe<Order_By>;
  settlement_invoice_sub_agent?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sub_agency_agreement?: InputMaybe<Agreement_Order_By>;
  sub_agency_agreement_id?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_payment_notes?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission */
export type Commission_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "commission" */
export type Commission_Set_Input = {
  agency_agreement_id?: InputMaybe<Scalars['uuid']>;
  agent_amount?: InputMaybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_exchange_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  agent_incentive_payable?: InputMaybe<Scalars['numeric']>;
  agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  agent_payment_notes?: InputMaybe<Scalars['String']>;
  agent_rate?: InputMaybe<Scalars['numeric']>;
  agent_settlement_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  exchange_invoice_agent?: InputMaybe<Scalars['String']>;
  exchange_invoice_sub_agent?: InputMaybe<Scalars['String']>;
  external_commission_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  property_name?: InputMaybe<Scalars['String']>;
  settlement_invoice_agent?: InputMaybe<Scalars['String']>;
  settlement_invoice_sub_agent?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sub_agency_agreement_id?: InputMaybe<Scalars['uuid']>;
  sub_agent_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  sub_agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  sub_agent_payment_notes?: InputMaybe<Scalars['String']>;
  sub_agent_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  sub_agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "commission" */
export type Commission_Stddev_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "commission" */
export type Commission_Stddev_Pop_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "commission" */
export type Commission_Stddev_Samp_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "commission" */
export type Commission_Stream_Cursor_Input = {
  initial_value: Commission_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Stream_Cursor_Value_Input = {
  agency_agreement_id?: InputMaybe<Scalars['uuid']>;
  agent_amount?: InputMaybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: InputMaybe<Scalars['numeric']>;
  agent_exchange_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  agent_incentive_payable?: InputMaybe<Scalars['numeric']>;
  agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  agent_payment_notes?: InputMaybe<Scalars['String']>;
  agent_rate?: InputMaybe<Scalars['numeric']>;
  agent_settlement_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  exchange_invoice_agent?: InputMaybe<Scalars['String']>;
  exchange_invoice_sub_agent?: InputMaybe<Scalars['String']>;
  external_commission_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  property_name?: InputMaybe<Scalars['String']>;
  settlement_invoice_agent?: InputMaybe<Scalars['String']>;
  settlement_invoice_sub_agent?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sub_agency_agreement_id?: InputMaybe<Scalars['uuid']>;
  sub_agent_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  sub_agent_exchange_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: InputMaybe<Scalars['numeric']>;
  sub_agent_payment_notes?: InputMaybe<Scalars['String']>;
  sub_agent_rate?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_invoice_paid_date?: InputMaybe<Scalars['timestamptz']>;
  sub_agent_settlement_payable_amount?: InputMaybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "commission" */
export type Commission_Sum_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "commission_type". All fields are combined with a logical 'AND'. */
export type Commission_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  _not?: InputMaybe<Commission_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "commission_type" */
export type Commission_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "commission_type" */
export type Commission_Type_On_Conflict = {
  constraint: Commission_Type_Constraint;
  update_columns: Array<Commission_Type_Update_Column>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "commission_type". */
export type Commission_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission_type */
export type Commission_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "commission_type" */
export type Commission_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "commission_type" */
export type Commission_Type_Stream_Cursor_Input = {
  initial_value: Commission_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Commission_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Commission_Type_Updates = {
  _set?: InputMaybe<Commission_Type_Set_Input>;
  where: Commission_Type_Bool_Exp;
};

export type Commission_Updates = {
  _inc?: InputMaybe<Commission_Inc_Input>;
  _set?: InputMaybe<Commission_Set_Input>;
  where: Commission_Bool_Exp;
};

/** order by var_pop() on columns of table "commission" */
export type Commission_Var_Pop_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "commission" */
export type Commission_Var_Samp_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "commission" */
export type Commission_Variance_Order_By = {
  agent_amount?: InputMaybe<Order_By>;
  agent_commission_rate_less_sub_agent?: InputMaybe<Order_By>;
  agent_commission_value_less_sub_agent?: InputMaybe<Order_By>;
  agent_exchange_payable_amount?: InputMaybe<Order_By>;
  agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  agent_incentive_amount?: InputMaybe<Order_By>;
  agent_incentive_payable?: InputMaybe<Order_By>;
  agent_incentive_rate?: InputMaybe<Order_By>;
  agent_outstanding_balance?: InputMaybe<Order_By>;
  agent_rate?: InputMaybe<Order_By>;
  agent_settlement_payable_amount?: InputMaybe<Order_By>;
  agent_settlement_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_amount?: InputMaybe<Order_By>;
  sub_agent_exchange_payable_percentage?: InputMaybe<Order_By>;
  sub_agent_incentive_amount?: InputMaybe<Order_By>;
  sub_agent_incentive_rate?: InputMaybe<Order_By>;
  sub_agent_outstanding_balance?: InputMaybe<Order_By>;
  sub_agent_rate?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_amount?: InputMaybe<Order_By>;
  sub_agent_settlement_payable_percentage?: InputMaybe<Order_By>;
};

export type Contact_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Contact_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Contact_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Contact_Aggregate_Bool_Exp_Count>;
};

export type Contact_Aggregate_Bool_Exp_Bool_And = {
  arguments: Contact_Select_Column_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Contact_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Contact_Select_Column_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "contact" */
export type Contact_Aggregate_Order_By = {
  avg?: InputMaybe<Contact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contact_Max_Order_By>;
  min?: InputMaybe<Contact_Min_Order_By>;
  stddev?: InputMaybe<Contact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Contact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Contact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Contact_Sum_Order_By>;
  var_pop?: InputMaybe<Contact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Contact_Var_Samp_Order_By>;
  variance?: InputMaybe<Contact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact" */
export type Contact_Arr_Rel_Insert_Input = {
  data: Array<Contact_Insert_Input>;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** order by avg() on columns of table "contact" */
export type Contact_Avg_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact". All fields are combined with a logical 'AND'. */
export type Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Bool_Exp>>;
  _not?: InputMaybe<Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Bool_Exp>>;
  acl_contact_agent_member?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
  acl_contact_agent_member_aggregate?: InputMaybe<Acl_Contact_Agent_Member_Aggregate_Bool_Exp>;
  acl_contact_developer_member?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
  acl_contact_developer_member_aggregate?: InputMaybe<Acl_Contact_Developer_Member_Aggregate_Bool_Exp>;
  acl_contact_master_agent?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
  acl_contact_master_agent_aggregate?: InputMaybe<Acl_Contact_Master_Agent_Aggregate_Bool_Exp>;
  address_city_suburb?: InputMaybe<String_Comparison_Exp>;
  address_line_1?: InputMaybe<String_Comparison_Exp>;
  address_postcode?: InputMaybe<String_Comparison_Exp>;
  address_state?: InputMaybe<String_Comparison_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  contact_type?: InputMaybe<Contact_Type_Bool_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  countryByCountry?: InputMaybe<Country_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_contact?: InputMaybe<Contact_Bool_Exp>;
  created_by_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_team?: InputMaybe<Organisation_Bool_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user?: InputMaybe<User_Bool_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deal_contacts?: InputMaybe<Deal_Contact_Bool_Exp>;
  deal_contacts_aggregate?: InputMaybe<Deal_Contact_Aggregate_Bool_Exp>;
  dealsByAgencyContactId?: InputMaybe<Deal_Bool_Exp>;
  dealsByAgencyContactId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsByLeadId?: InputMaybe<Deal_Bool_Exp>;
  dealsByLeadId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsByPurchaserSolicitorContactId?: InputMaybe<Deal_Bool_Exp>;
  dealsByPurchaserSolicitorContactId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsBySubAgencyContactId?: InputMaybe<Deal_Bool_Exp>;
  dealsBySubAgencyContactId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsByVendorSolicitorContactId?: InputMaybe<Deal_Bool_Exp>;
  dealsByVendorSolicitorContactId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fax_number?: InputMaybe<String_Comparison_Exp>;
  firb_purchaser?: InputMaybe<Boolean_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_address?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  hubspot?: InputMaybe<Hubspot_Contact_Bool_Exp>;
  hubspot_aggregate?: InputMaybe<Hubspot_Contact_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idProofs?: InputMaybe<Document_Bool_Exp>;
  idProofs_aggregate?: InputMaybe<Document_Aggregate_Bool_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  manyDealsByLeadId?: InputMaybe<Deal_Contact_Bool_Exp>;
  manyDealsByLeadId_aggregate?: InputMaybe<Deal_Contact_Aggregate_Bool_Exp>;
  middle_name?: InputMaybe<String_Comparison_Exp>;
  nationality?: InputMaybe<String_Comparison_Exp>;
  nationalityByNationality?: InputMaybe<Nationality_Bool_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  salesforce?: InputMaybe<Sf_Contact_Bool_Exp>;
  salesforce_aggregate?: InputMaybe<Sf_Contact_Aggregate_Bool_Exp>;
  sf_down_sync_time?: InputMaybe<Bigint_Comparison_Exp>;
  tax_file_number?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Contact_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "contact" */
export type Contact_Inc_Input = {
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contact" */
export type Contact_Insert_Input = {
  acl_contact_agent_member?: InputMaybe<Acl_Contact_Agent_Member_Arr_Rel_Insert_Input>;
  acl_contact_developer_member?: InputMaybe<Acl_Contact_Developer_Member_Arr_Rel_Insert_Input>;
  acl_contact_master_agent?: InputMaybe<Acl_Contact_Master_Agent_Arr_Rel_Insert_Input>;
  address_city_suburb?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_postcode?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  contact_type?: InputMaybe<Contact_Type_Obj_Rel_Insert_Input>;
  country?: InputMaybe<Scalars['String']>;
  countryByCountry?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  created_by_contact_id?: InputMaybe<Scalars['uuid']>;
  created_by_team?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal_contacts?: InputMaybe<Deal_Contact_Arr_Rel_Insert_Input>;
  dealsByAgencyContactId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsByLeadId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsByPurchaserSolicitorContactId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsBySubAgencyContactId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsByVendorSolicitorContactId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  firb_purchaser?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  hubspot?: InputMaybe<Hubspot_Contact_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  idProofs?: InputMaybe<Document_Arr_Rel_Insert_Input>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  manyDealsByLeadId?: InputMaybe<Deal_Contact_Arr_Rel_Insert_Input>;
  middle_name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  nationalityByNationality?: InputMaybe<Nationality_Obj_Rel_Insert_Input>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  salesforce?: InputMaybe<Sf_Contact_Arr_Rel_Insert_Input>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  tax_file_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Contact_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contact" */
export type Contact_Max_Order_By = {
  address_city_suburb?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_postcode?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_contact_id?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fax_number?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  nationality?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  tax_file_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "contact" */
export type Contact_Min_Order_By = {
  address_city_suburb?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_postcode?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_contact_id?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fax_number?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  nationality?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  tax_file_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "contact" */
export type Contact_Obj_Rel_Insert_Input = {
  data: Contact_Insert_Input;
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

/** on_conflict condition type for table "contact" */
export type Contact_On_Conflict = {
  constraint: Contact_Constraint;
  update_columns: Array<Contact_Update_Column>;
  where?: InputMaybe<Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "contact". */
export type Contact_Order_By = {
  acl_contact_agent_member_aggregate?: InputMaybe<Acl_Contact_Agent_Member_Aggregate_Order_By>;
  acl_contact_developer_member_aggregate?: InputMaybe<Acl_Contact_Developer_Member_Aggregate_Order_By>;
  acl_contact_master_agent_aggregate?: InputMaybe<Acl_Contact_Master_Agent_Aggregate_Order_By>;
  address_city_suburb?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_postcode?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  contact_type?: InputMaybe<Contact_Type_Order_By>;
  country?: InputMaybe<Order_By>;
  countryByCountry?: InputMaybe<Country_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_contact?: InputMaybe<Contact_Order_By>;
  created_by_contact_id?: InputMaybe<Order_By>;
  created_by_team?: InputMaybe<Organisation_Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<User_Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deal_contacts_aggregate?: InputMaybe<Deal_Contact_Aggregate_Order_By>;
  dealsByAgencyContactId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsByLeadId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsByPurchaserSolicitorContactId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsBySubAgencyContactId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsByVendorSolicitorContactId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  fax_number?: InputMaybe<Order_By>;
  firb_purchaser?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_address?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  hubspot_aggregate?: InputMaybe<Hubspot_Contact_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  idProofs_aggregate?: InputMaybe<Document_Aggregate_Order_By>;
  is_primary?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  manyDealsByLeadId_aggregate?: InputMaybe<Deal_Contact_Aggregate_Order_By>;
  middle_name?: InputMaybe<Order_By>;
  nationality?: InputMaybe<Order_By>;
  nationalityByNationality?: InputMaybe<Nationality_Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  salesforce_aggregate?: InputMaybe<Sf_Contact_Aggregate_Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  tax_file_number?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact */
export type Contact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "contact" */
export type Contact_Set_Input = {
  address_city_suburb?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_postcode?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_contact_id?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  firb_purchaser?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  tax_file_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Contact_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "contact" */
export type Contact_Stddev_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "contact" */
export type Contact_Stddev_Pop_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "contact" */
export type Contact_Stddev_Samp_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "contact" */
export type Contact_Stream_Cursor_Input = {
  initial_value: Contact_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Stream_Cursor_Value_Input = {
  address_city_suburb?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_postcode?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_contact_id?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  fax_number?: InputMaybe<Scalars['String']>;
  firb_purchaser?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_primary?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  tax_file_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Contact_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "contact" */
export type Contact_Sum_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_type". All fields are combined with a logical 'AND'. */
export type Contact_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Type_Bool_Exp>>;
  _not?: InputMaybe<Contact_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Type_Bool_Exp>>;
  contacts?: InputMaybe<Contact_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "contact_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contact_Type_Enum>;
  _in?: InputMaybe<Array<Contact_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Contact_Type_Enum>;
  _nin?: InputMaybe<Array<Contact_Type_Enum>>;
};

/** input type for inserting data into table "contact_type" */
export type Contact_Type_Insert_Input = {
  contacts?: InputMaybe<Contact_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "contact_type" */
export type Contact_Type_Obj_Rel_Insert_Input = {
  data: Contact_Type_Insert_Input;
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};

/** on_conflict condition type for table "contact_type" */
export type Contact_Type_On_Conflict = {
  constraint: Contact_Type_Constraint;
  update_columns: Array<Contact_Type_Update_Column>;
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_type". */
export type Contact_Type_Order_By = {
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_type */
export type Contact_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "contact_type" */
export type Contact_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "contact_type" */
export type Contact_Type_Stream_Cursor_Input = {
  initial_value: Contact_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Contact_Type_Updates = {
  _set?: InputMaybe<Contact_Type_Set_Input>;
  where: Contact_Type_Bool_Exp;
};

export type Contact_Updates = {
  _inc?: InputMaybe<Contact_Inc_Input>;
  _set?: InputMaybe<Contact_Set_Input>;
  where: Contact_Bool_Exp;
};

/** order by var_pop() on columns of table "contact" */
export type Contact_Var_Pop_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "contact" */
export type Contact_Var_Samp_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "contact" */
export type Contact_Variance_Order_By = {
  sf_down_sync_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "count_with_rolling_avg". All fields are combined with a logical 'AND'. */
export type Count_With_Rolling_Avg_Bool_Exp = {
  _and?: InputMaybe<Array<Count_With_Rolling_Avg_Bool_Exp>>;
  _not?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
  _or?: InputMaybe<Array<Count_With_Rolling_Avg_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  date_start?: InputMaybe<Date_Comparison_Exp>;
  rolling_avg?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Inc_Input = {
  count?: InputMaybe<Scalars['Int']>;
  rolling_avg?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Insert_Input = {
  count?: InputMaybe<Scalars['Int']>;
  date_start?: InputMaybe<Scalars['date']>;
  rolling_avg?: InputMaybe<Scalars['numeric']>;
};

/** on_conflict condition type for table "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_On_Conflict = {
  constraint: Count_With_Rolling_Avg_Constraint;
  update_columns: Array<Count_With_Rolling_Avg_Update_Column>;
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

/** Ordering options when selecting data from "count_with_rolling_avg". */
export type Count_With_Rolling_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
  date_start?: InputMaybe<Order_By>;
  rolling_avg?: InputMaybe<Order_By>;
};

/** primary key columns input for table: count_with_rolling_avg */
export type Count_With_Rolling_Avg_Pk_Columns_Input = {
  date_start: Scalars['date'];
};

/** input type for updating data in table "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Set_Input = {
  count?: InputMaybe<Scalars['Int']>;
  date_start?: InputMaybe<Scalars['date']>;
  rolling_avg?: InputMaybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Stream_Cursor_Input = {
  initial_value: Count_With_Rolling_Avg_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Count_With_Rolling_Avg_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']>;
  date_start?: InputMaybe<Scalars['date']>;
  rolling_avg?: InputMaybe<Scalars['numeric']>;
};

export type Count_With_Rolling_Avg_Updates = {
  _inc?: InputMaybe<Count_With_Rolling_Avg_Inc_Input>;
  _set?: InputMaybe<Count_With_Rolling_Avg_Set_Input>;
  where: Count_With_Rolling_Avg_Bool_Exp;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  contacts?: InputMaybe<Contact_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  contacts?: InputMaybe<Contact_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  initial_value: Country_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Country_Updates = {
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};

/** Boolean expression to filter rows from the table "crm_connection". All fields are combined with a logical 'AND'. */
export type Crm_Connection_Bool_Exp = {
  _and?: InputMaybe<Array<Crm_Connection_Bool_Exp>>;
  _not?: InputMaybe<Crm_Connection_Bool_Exp>;
  _or?: InputMaybe<Array<Crm_Connection_Bool_Exp>>;
  auth_token?: InputMaybe<String_Comparison_Exp>;
  consumer_key?: InputMaybe<String_Comparison_Exp>;
  consumer_secret?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_admin_user?: InputMaybe<Uuid_Comparison_Exp>;
  env?: InputMaybe<String_Comparison_Exp>;
  heroku_connect_secret?: InputMaybe<String_Comparison_Exp>;
  heroku_connect_url?: InputMaybe<String_Comparison_Exp>;
  hook_token?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  sf_rest_api_uri?: InputMaybe<String_Comparison_Exp>;
  token_issued_at?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "crm_connection" */
export type Crm_Connection_Insert_Input = {
  auth_token?: InputMaybe<Scalars['String']>;
  consumer_key?: InputMaybe<Scalars['String']>;
  consumer_secret?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_admin_user?: InputMaybe<Scalars['uuid']>;
  env?: InputMaybe<Scalars['String']>;
  heroku_connect_secret?: InputMaybe<Scalars['String']>;
  heroku_connect_url?: InputMaybe<Scalars['String']>;
  hook_token?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_team_id?: InputMaybe<Scalars['uuid']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  sf_rest_api_uri?: InputMaybe<Scalars['String']>;
  token_issued_at?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "crm_connection" */
export type Crm_Connection_Obj_Rel_Insert_Input = {
  data: Crm_Connection_Insert_Input;
  on_conflict?: InputMaybe<Crm_Connection_On_Conflict>;
};

/** on_conflict condition type for table "crm_connection" */
export type Crm_Connection_On_Conflict = {
  constraint: Crm_Connection_Constraint;
  update_columns: Array<Crm_Connection_Update_Column>;
  where?: InputMaybe<Crm_Connection_Bool_Exp>;
};

/** Ordering options when selecting data from "crm_connection". */
export type Crm_Connection_Order_By = {
  auth_token?: InputMaybe<Order_By>;
  consumer_key?: InputMaybe<Order_By>;
  consumer_secret?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_admin_user?: InputMaybe<Order_By>;
  env?: InputMaybe<Order_By>;
  heroku_connect_secret?: InputMaybe<Order_By>;
  heroku_connect_url?: InputMaybe<Order_By>;
  hook_token?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_team_id?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  sf_rest_api_uri?: InputMaybe<Order_By>;
  token_issued_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: crm_connection */
export type Crm_Connection_Pk_Columns_Input = {
  sf_instance_id: Scalars['String'];
};

/** input type for updating data in table "crm_connection" */
export type Crm_Connection_Set_Input = {
  auth_token?: InputMaybe<Scalars['String']>;
  consumer_key?: InputMaybe<Scalars['String']>;
  consumer_secret?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_admin_user?: InputMaybe<Scalars['uuid']>;
  env?: InputMaybe<Scalars['String']>;
  heroku_connect_secret?: InputMaybe<Scalars['String']>;
  heroku_connect_url?: InputMaybe<Scalars['String']>;
  hook_token?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_team_id?: InputMaybe<Scalars['uuid']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  sf_rest_api_uri?: InputMaybe<Scalars['String']>;
  token_issued_at?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "crm_connection" */
export type Crm_Connection_Stream_Cursor_Input = {
  initial_value: Crm_Connection_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Crm_Connection_Stream_Cursor_Value_Input = {
  auth_token?: InputMaybe<Scalars['String']>;
  consumer_key?: InputMaybe<Scalars['String']>;
  consumer_secret?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_admin_user?: InputMaybe<Scalars['uuid']>;
  env?: InputMaybe<Scalars['String']>;
  heroku_connect_secret?: InputMaybe<Scalars['String']>;
  heroku_connect_url?: InputMaybe<Scalars['String']>;
  hook_token?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_team_id?: InputMaybe<Scalars['uuid']>;
  refresh_token?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  sf_rest_api_uri?: InputMaybe<Scalars['String']>;
  token_issued_at?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Crm_Connection_Updates = {
  _set?: InputMaybe<Crm_Connection_Set_Input>;
  where: Crm_Connection_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type Deal_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Deal_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Deal_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Deal_Aggregate_Bool_Exp_Count>;
};

export type Deal_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "deal" */
export type Deal_Aggregate_Order_By = {
  avg?: InputMaybe<Deal_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deal_Max_Order_By>;
  min?: InputMaybe<Deal_Min_Order_By>;
  stddev?: InputMaybe<Deal_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Deal_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Deal_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Deal_Sum_Order_By>;
  var_pop?: InputMaybe<Deal_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Deal_Var_Samp_Order_By>;
  variance?: InputMaybe<Deal_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deal" */
export type Deal_Arr_Rel_Insert_Input = {
  data: Array<Deal_Insert_Input>;
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

/** order by avg() on columns of table "deal" */
export type Deal_Avg_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
export type Deal_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Bool_Exp>>;
  _not?: InputMaybe<Deal_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Bool_Exp>>;
  account_manager_id?: InputMaybe<Uuid_Comparison_Exp>;
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Bool_Exp>;
  acl_deal_developer_member?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
  acl_deal_developer_member_aggregate?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Bool_Exp>;
  acl_deal_master_agent?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
  acl_deal_master_agent_aggregate?: InputMaybe<Acl_Deal_Master_Agent_Aggregate_Bool_Exp>;
  acl_deal_user_team?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
  acl_deal_user_team_aggregate?: InputMaybe<Acl_Deal_User_Team_Aggregate_Bool_Exp>;
  advised_contract_ready_to_progress?: InputMaybe<Date_Comparison_Exp>;
  agency_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  agency_id?: InputMaybe<Uuid_Comparison_Exp>;
  agency_organisation?: InputMaybe<Organisation_Bool_Exp>;
  agent_contact?: InputMaybe<Contact_Bool_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  awaiting_enquiry_sf_id?: InputMaybe<Boolean_Comparison_Exp>;
  awaiting_legal_sf_id?: InputMaybe<Boolean_Comparison_Exp>;
  balance_due_by?: InputMaybe<Timestamptz_Comparison_Exp>;
  balance_of_deposit?: InputMaybe<Int_Comparison_Exp>;
  balance_of_deposit_calc?: InputMaybe<Numeric_Comparison_Exp>;
  bank_guarantee_expires?: InputMaybe<Date_Comparison_Exp>;
  bathrooms?: InputMaybe<Int_Comparison_Exp>;
  bedrooms?: InputMaybe<Int_Comparison_Exp>;
  bedrooms_pb_max?: InputMaybe<Int_Comparison_Exp>;
  bedrooms_pb_min?: InputMaybe<Int_Comparison_Exp>;
  bedrooms_requested?: InputMaybe<String_Comparison_Exp>;
  bond_expires?: InputMaybe<Date_Comparison_Exp>;
  bpay_biller_code?: InputMaybe<String_Comparison_Exp>;
  bpay_crn?: InputMaybe<String_Comparison_Exp>;
  building_id?: InputMaybe<Uuid_Comparison_Exp>;
  buying_capacity?: InputMaybe<Int_Comparison_Exp>;
  cars?: InputMaybe<Int_Comparison_Exp>;
  changes_requested?: InputMaybe<Boolean_Comparison_Exp>;
  colour_scheme?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  comment_for_buyer?: InputMaybe<String_Comparison_Exp>;
  commission_split?: InputMaybe<Boolean_Comparison_Exp>;
  commissions?: InputMaybe<Commission_Bool_Exp>;
  commissions_aggregate?: InputMaybe<Commission_Aggregate_Bool_Exp>;
  confirmed_firb_status?: InputMaybe<String_Comparison_Exp>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  contact_status?: InputMaybe<Deal_Contact_Status_Enum_Comparison_Exp>;
  contacts?: InputMaybe<Deal_Contact_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Deal_Contact_Aggregate_Bool_Exp>;
  contract_conditional?: InputMaybe<Date_Comparison_Exp>;
  contract_deposit_amount?: InputMaybe<Numeric_Comparison_Exp>;
  contract_deposit_amount_calc?: InputMaybe<Numeric_Comparison_Exp>;
  contract_deposit_paid_calc?: InputMaybe<Numeric_Comparison_Exp>;
  contract_deposit_paid_total_calc?: InputMaybe<Numeric_Comparison_Exp>;
  contract_exchanged?: InputMaybe<Timestamptz_Comparison_Exp>;
  contract_issued?: InputMaybe<Timestamptz_Comparison_Exp>;
  contract_progress_update?: InputMaybe<Deal_Progress_Update_Enum_Comparison_Exp>;
  contract_returned_to_vendor?: InputMaybe<Date_Comparison_Exp>;
  contract_sent_to_purchaser?: InputMaybe<Date_Comparison_Exp>;
  contract_unconditional?: InputMaybe<Timestamptz_Comparison_Exp>;
  cooling_off_ends?: InputMaybe<Timestamptz_Comparison_Exp>;
  cooling_off_starts?: InputMaybe<Timestamptz_Comparison_Exp>;
  cooling_off_waived?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  deal_contact_status?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
  deal_contact_type?: InputMaybe<Deal_Contact_Bool_Exp>;
  deal_deposit_status?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
  deal_enquiry_source?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
  deal_fallen_reason?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
  deal_payment_method?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
  deal_price_inc_variations_calc?: InputMaybe<Numeric_Comparison_Exp>;
  deal_progress_update?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
  deal_reason_to_purchase?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
  deal_status?: InputMaybe<Deal_Status_Bool_Exp>;
  deal_transfer_status?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
  deal_type?: InputMaybe<Deal_Type_Bool_Exp>;
  deal_witnesses?: InputMaybe<Deal_Witness_Bool_Exp>;
  deal_witnesses_aggregate?: InputMaybe<Deal_Witness_Aggregate_Bool_Exp>;
  deposit_due?: InputMaybe<Int_Comparison_Exp>;
  deposit_received?: InputMaybe<Date_Comparison_Exp>;
  deposit_status?: InputMaybe<Deal_Deposit_Status_Enum_Comparison_Exp>;
  deposit_type?: InputMaybe<Deal_Deposit_Type_Enum_Comparison_Exp>;
  enquiry_source?: InputMaybe<Deal_Enquiry_Source_Enum_Comparison_Exp>;
  enquiry_source_other?: InputMaybe<String_Comparison_Exp>;
  envelope_opened_by_buyer?: InputMaybe<Timestamptz_Comparison_Exp>;
  envelope_owner?: InputMaybe<String_Comparison_Exp>;
  eoi_approved_by_vendor?: InputMaybe<Timestamptz_Comparison_Exp>;
  fallen_date?: InputMaybe<Date_Comparison_Exp>;
  fallen_explanation?: InputMaybe<String_Comparison_Exp>;
  fallen_reason?: InputMaybe<Deal_Fallen_Reason_Enum_Comparison_Exp>;
  finance_status?: InputMaybe<Finance_Status_Enum_Comparison_Exp>;
  firb_approved_vendor?: InputMaybe<String_Comparison_Exp>;
  firb_confirmation_received?: InputMaybe<Date_Comparison_Exp>;
  firb_purchaser?: InputMaybe<Boolean_Comparison_Exp>;
  forecast_exchange?: InputMaybe<Date_Comparison_Exp>;
  form_of_settlement?: InputMaybe<String_Comparison_Exp>;
  holding_deposit_amount?: InputMaybe<Numeric_Comparison_Exp>;
  holding_deposit_amount_received?: InputMaybe<Numeric_Comparison_Exp>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Date_Comparison_Exp>;
  holding_deposit_due_calc?: InputMaybe<Numeric_Comparison_Exp>;
  holding_deposit_number?: InputMaybe<String_Comparison_Exp>;
  holding_deposit_paid_calc?: InputMaybe<Numeric_Comparison_Exp>;
  holding_deposit_paid_handled?: InputMaybe<Boolean_Comparison_Exp>;
  holding_deposit_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  holding_deposit_received?: InputMaybe<Boolean_Comparison_Exp>;
  holding_deposit_received_by_vsol?: InputMaybe<Date_Comparison_Exp>;
  how_did_you_hear_about_the_property?: InputMaybe<String_Comparison_Exp>;
  hubspot?: InputMaybe<Hubspot_Deal_Bool_Exp>;
  hubspot_aggregate?: InputMaybe<Hubspot_Deal_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  id_received?: InputMaybe<Boolean_Comparison_Exp>;
  identity_doc1_number?: InputMaybe<String_Comparison_Exp>;
  identity_doc1_region?: InputMaybe<String_Comparison_Exp>;
  identity_doc1_type?: InputMaybe<String_Comparison_Exp>;
  incl_in_deposit?: InputMaybe<Boolean_Comparison_Exp>;
  last_sales_advice_generated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  lead_id?: InputMaybe<Uuid_Comparison_Exp>;
  lead_strength?: InputMaybe<String_Comparison_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_on_contract?: InputMaybe<String_Comparison_Exp>;
  on_hold?: InputMaybe<Timestamptz_Comparison_Exp>;
  on_hold_approved_by_client?: InputMaybe<Timestamptz_Comparison_Exp>;
  on_hold_approved_by_vendor?: InputMaybe<Timestamptz_Comparison_Exp>;
  on_hold_release?: InputMaybe<Date_Comparison_Exp>;
  paid_by?: InputMaybe<String_Comparison_Exp>;
  parking_spaces?: InputMaybe<Int_Comparison_Exp>;
  payable_on?: InputMaybe<Date_Comparison_Exp>;
  payment_method?: InputMaybe<Deal_Payment_Method_Enum_Comparison_Exp>;
  pre_settlement_booked_by?: InputMaybe<String_Comparison_Exp>;
  preferences?: InputMaybe<Preference_Bool_Exp>;
  preferences_aggregate?: InputMaybe<Preference_Aggregate_Bool_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  proof_verified?: InputMaybe<Boolean_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  purchase_proposal_sent?: InputMaybe<Boolean_Comparison_Exp>;
  purchaser_solicitor_contact?: InputMaybe<Contact_Bool_Exp>;
  purchaser_solicitor_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  purchaser_solicitor_id?: InputMaybe<Uuid_Comparison_Exp>;
  purchaser_solicitor_organisation?: InputMaybe<Organisation_Bool_Exp>;
  reason_to_purchase?: InputMaybe<String_Comparison_Exp>;
  refund_account_bsb?: InputMaybe<String_Comparison_Exp>;
  refund_account_name?: InputMaybe<String_Comparison_Exp>;
  refund_account_number?: InputMaybe<String_Comparison_Exp>;
  refund_requested?: InputMaybe<Date_Comparison_Exp>;
  refund_swift_code?: InputMaybe<String_Comparison_Exp>;
  request_reference?: InputMaybe<String_Comparison_Exp>;
  reservation_finalised?: InputMaybe<Boolean_Comparison_Exp>;
  reserved_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  sa_received?: InputMaybe<Date_Comparison_Exp>;
  sa_sent_to_legal?: InputMaybe<Date_Comparison_Exp>;
  sale_price?: InputMaybe<Int_Comparison_Exp>;
  settlement?: InputMaybe<Timestamptz_Comparison_Exp>;
  settlement_booked?: InputMaybe<Date_Comparison_Exp>;
  settlement_defect_status?: InputMaybe<String_Comparison_Exp>;
  settlement_due_date?: InputMaybe<Date_Comparison_Exp>;
  settlement_extension_requested_date?: InputMaybe<Date_Comparison_Exp>;
  settlement_inspection?: InputMaybe<Timestamp_Comparison_Exp>;
  settlement_inspection_completed?: InputMaybe<Boolean_Comparison_Exp>;
  settlement_keys_collected_date?: InputMaybe<Date_Comparison_Exp>;
  settlement_outlook?: InputMaybe<String_Comparison_Exp>;
  settlement_story?: InputMaybe<String_Comparison_Exp>;
  settlement_summary?: InputMaybe<String_Comparison_Exp>;
  sf_down_sync_time?: InputMaybe<Bigint_Comparison_Exp>;
  sf_enquiry_id?: InputMaybe<String_Comparison_Exp>;
  sf_legal_id?: InputMaybe<String_Comparison_Exp>;
  solicitor_notes?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<Lead_Generation_Source_Enum_Comparison_Exp>;
  spoken_language?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
  spoken_language_aggregate?: InputMaybe<Deal_Spoken_Language_Aggregate_Bool_Exp>;
  status?: InputMaybe<Deal_Status_Enum_Comparison_Exp>;
  sub_agency?: InputMaybe<Organisation_Bool_Exp>;
  sub_agency_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  sub_agency_id?: InputMaybe<Uuid_Comparison_Exp>;
  sub_agent_contact?: InputMaybe<Contact_Bool_Exp>;
  terms?: InputMaybe<String_Comparison_Exp>;
  time_frame?: InputMaybe<String_Comparison_Exp>;
  total_deposit_extension_date?: InputMaybe<Date_Comparison_Exp>;
  total_deposit_outstanding?: InputMaybe<Int_Comparison_Exp>;
  total_deposit_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  total_deposit_selection?: InputMaybe<String_Comparison_Exp>;
  transactions?: InputMaybe<Transaction_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>;
  transfer_receipt_time?: InputMaybe<Date_Comparison_Exp>;
  transfer_status?: InputMaybe<Deal_Transfer_Status_Enum_Comparison_Exp>;
  transfer_to_vsol_requested?: InputMaybe<Boolean_Comparison_Exp>;
  transferred_to_vsol?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<Deal_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_id?: InputMaybe<Boolean_Comparison_Exp>;
  uploaded_id_count?: InputMaybe<Int_Comparison_Exp>;
  uploaded_receipt?: InputMaybe<Boolean_Comparison_Exp>;
  variations_calc?: InputMaybe<Numeric_Comparison_Exp>;
  vendor_solicitor_contact?: InputMaybe<Contact_Bool_Exp>;
  vendor_solicitor_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_solicitor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_solicitor_organisation?: InputMaybe<Organisation_Bool_Exp>;
  x66w_certificate_provided?: InputMaybe<Boolean_Comparison_Exp>;
  x66w_received_by_vsol?: InputMaybe<Date_Comparison_Exp>;
};

export type Deal_Contact_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Deal_Contact_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Deal_Contact_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Deal_Contact_Aggregate_Bool_Exp_Count>;
};

export type Deal_Contact_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deal_Contact_Select_Column_Deal_Contact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Contact_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deal_Contact_Select_Column_Deal_Contact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Contact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "deal_contact" */
export type Deal_Contact_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deal_Contact_Max_Order_By>;
  min?: InputMaybe<Deal_Contact_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deal_contact" */
export type Deal_Contact_Arr_Rel_Insert_Input = {
  data: Array<Deal_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Deal_Contact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deal_contact". All fields are combined with a logical 'AND'. */
export type Deal_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Contact_Bool_Exp>>;
  _not?: InputMaybe<Deal_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Contact_Bool_Exp>>;
  buyer_type?: InputMaybe<Buyer_Type_Enum_Comparison_Exp>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_existing?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "deal_contact" */
export type Deal_Contact_Insert_Input = {
  buyer_type?: InputMaybe<Buyer_Type_Enum>;
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_existing?: InputMaybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "deal_contact" */
export type Deal_Contact_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "deal_contact" */
export type Deal_Contact_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "deal_contact" */
export type Deal_Contact_Obj_Rel_Insert_Input = {
  data: Deal_Contact_Insert_Input;
  on_conflict?: InputMaybe<Deal_Contact_On_Conflict>;
};

/** on_conflict condition type for table "deal_contact" */
export type Deal_Contact_On_Conflict = {
  constraint: Deal_Contact_Constraint;
  update_columns: Array<Deal_Contact_Update_Column>;
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_contact". */
export type Deal_Contact_Order_By = {
  buyer_type?: InputMaybe<Order_By>;
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_existing?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_contact */
export type Deal_Contact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "deal_contact" */
export type Deal_Contact_Set_Input = {
  buyer_type?: InputMaybe<Buyer_Type_Enum>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_existing?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "deal_contact_status". All fields are combined with a logical 'AND'. */
export type Deal_Contact_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Contact_Status_Bool_Exp>>;
  _not?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Contact_Status_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_contact_status_enum". All fields are combined with logical 'AND'. */
export type Deal_Contact_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Contact_Status_Enum>;
  _in?: InputMaybe<Array<Deal_Contact_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Contact_Status_Enum>;
  _nin?: InputMaybe<Array<Deal_Contact_Status_Enum>>;
};

/** input type for inserting data into table "deal_contact_status" */
export type Deal_Contact_Status_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_contact_status" */
export type Deal_Contact_Status_Obj_Rel_Insert_Input = {
  data: Deal_Contact_Status_Insert_Input;
  on_conflict?: InputMaybe<Deal_Contact_Status_On_Conflict>;
};

/** on_conflict condition type for table "deal_contact_status" */
export type Deal_Contact_Status_On_Conflict = {
  constraint: Deal_Contact_Status_Constraint;
  update_columns: Array<Deal_Contact_Status_Update_Column>;
  where?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_contact_status". */
export type Deal_Contact_Status_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_contact_status */
export type Deal_Contact_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_contact_status" */
export type Deal_Contact_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_contact_status" */
export type Deal_Contact_Status_Stream_Cursor_Input = {
  initial_value: Deal_Contact_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Contact_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Contact_Status_Updates = {
  _set?: InputMaybe<Deal_Contact_Status_Set_Input>;
  where: Deal_Contact_Status_Bool_Exp;
};

/** Streaming cursor of the table "deal_contact" */
export type Deal_Contact_Stream_Cursor_Input = {
  initial_value: Deal_Contact_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Contact_Stream_Cursor_Value_Input = {
  buyer_type?: InputMaybe<Buyer_Type_Enum>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_existing?: InputMaybe<Scalars['Boolean']>;
};

export type Deal_Contact_Updates = {
  _set?: InputMaybe<Deal_Contact_Set_Input>;
  where: Deal_Contact_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_deposit_status". All fields are combined with a logical 'AND'. */
export type Deal_Deposit_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Deposit_Status_Bool_Exp>>;
  _not?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Deposit_Status_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_deposit_status_enum". All fields are combined with logical 'AND'. */
export type Deal_Deposit_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Deposit_Status_Enum>;
  _in?: InputMaybe<Array<Deal_Deposit_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Deposit_Status_Enum>;
  _nin?: InputMaybe<Array<Deal_Deposit_Status_Enum>>;
};

/** input type for inserting data into table "deal_deposit_status" */
export type Deal_Deposit_Status_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_deposit_status" */
export type Deal_Deposit_Status_Obj_Rel_Insert_Input = {
  data: Deal_Deposit_Status_Insert_Input;
  on_conflict?: InputMaybe<Deal_Deposit_Status_On_Conflict>;
};

/** on_conflict condition type for table "deal_deposit_status" */
export type Deal_Deposit_Status_On_Conflict = {
  constraint: Deal_Deposit_Status_Constraint;
  update_columns: Array<Deal_Deposit_Status_Update_Column>;
  where?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_deposit_status". */
export type Deal_Deposit_Status_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_deposit_status */
export type Deal_Deposit_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_deposit_status" */
export type Deal_Deposit_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_deposit_status" */
export type Deal_Deposit_Status_Stream_Cursor_Input = {
  initial_value: Deal_Deposit_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Deposit_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Deposit_Status_Updates = {
  _set?: InputMaybe<Deal_Deposit_Status_Set_Input>;
  where: Deal_Deposit_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_deposit_type". All fields are combined with a logical 'AND'. */
export type Deal_Deposit_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Deposit_Type_Bool_Exp>>;
  _not?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Deposit_Type_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_deposit_type_enum". All fields are combined with logical 'AND'. */
export type Deal_Deposit_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Deposit_Type_Enum>;
  _in?: InputMaybe<Array<Deal_Deposit_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Deposit_Type_Enum>;
  _nin?: InputMaybe<Array<Deal_Deposit_Type_Enum>>;
};

/** input type for inserting data into table "deal_deposit_type" */
export type Deal_Deposit_Type_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "deal_deposit_type" */
export type Deal_Deposit_Type_On_Conflict = {
  constraint: Deal_Deposit_Type_Constraint;
  update_columns: Array<Deal_Deposit_Type_Update_Column>;
  where?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_deposit_type". */
export type Deal_Deposit_Type_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_deposit_type */
export type Deal_Deposit_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_deposit_type" */
export type Deal_Deposit_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_deposit_type" */
export type Deal_Deposit_Type_Stream_Cursor_Input = {
  initial_value: Deal_Deposit_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Deposit_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Deposit_Type_Updates = {
  _set?: InputMaybe<Deal_Deposit_Type_Set_Input>;
  where: Deal_Deposit_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_enquiry_source". All fields are combined with a logical 'AND'. */
export type Deal_Enquiry_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Enquiry_Source_Bool_Exp>>;
  _not?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Enquiry_Source_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_enquiry_source_enum". All fields are combined with logical 'AND'. */
export type Deal_Enquiry_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Enquiry_Source_Enum>;
  _in?: InputMaybe<Array<Deal_Enquiry_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Enquiry_Source_Enum>;
  _nin?: InputMaybe<Array<Deal_Enquiry_Source_Enum>>;
};

/** input type for inserting data into table "deal_enquiry_source" */
export type Deal_Enquiry_Source_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_enquiry_source" */
export type Deal_Enquiry_Source_Obj_Rel_Insert_Input = {
  data: Deal_Enquiry_Source_Insert_Input;
  on_conflict?: InputMaybe<Deal_Enquiry_Source_On_Conflict>;
};

/** on_conflict condition type for table "deal_enquiry_source" */
export type Deal_Enquiry_Source_On_Conflict = {
  constraint: Deal_Enquiry_Source_Constraint;
  update_columns: Array<Deal_Enquiry_Source_Update_Column>;
  where?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_enquiry_source". */
export type Deal_Enquiry_Source_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_enquiry_source */
export type Deal_Enquiry_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_enquiry_source" */
export type Deal_Enquiry_Source_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_enquiry_source" */
export type Deal_Enquiry_Source_Stream_Cursor_Input = {
  initial_value: Deal_Enquiry_Source_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Enquiry_Source_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Enquiry_Source_Updates = {
  _set?: InputMaybe<Deal_Enquiry_Source_Set_Input>;
  where: Deal_Enquiry_Source_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_fallen_reason". All fields are combined with a logical 'AND'. */
export type Deal_Fallen_Reason_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Fallen_Reason_Bool_Exp>>;
  _not?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Fallen_Reason_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_fallen_reason_enum". All fields are combined with logical 'AND'. */
export type Deal_Fallen_Reason_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Fallen_Reason_Enum>;
  _in?: InputMaybe<Array<Deal_Fallen_Reason_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Fallen_Reason_Enum>;
  _nin?: InputMaybe<Array<Deal_Fallen_Reason_Enum>>;
};

/** input type for inserting data into table "deal_fallen_reason" */
export type Deal_Fallen_Reason_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_fallen_reason" */
export type Deal_Fallen_Reason_Obj_Rel_Insert_Input = {
  data: Deal_Fallen_Reason_Insert_Input;
  on_conflict?: InputMaybe<Deal_Fallen_Reason_On_Conflict>;
};

/** on_conflict condition type for table "deal_fallen_reason" */
export type Deal_Fallen_Reason_On_Conflict = {
  constraint: Deal_Fallen_Reason_Constraint;
  update_columns: Array<Deal_Fallen_Reason_Update_Column>;
  where?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_fallen_reason". */
export type Deal_Fallen_Reason_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_fallen_reason */
export type Deal_Fallen_Reason_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_fallen_reason" */
export type Deal_Fallen_Reason_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_fallen_reason" */
export type Deal_Fallen_Reason_Stream_Cursor_Input = {
  initial_value: Deal_Fallen_Reason_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Fallen_Reason_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Fallen_Reason_Updates = {
  _set?: InputMaybe<Deal_Fallen_Reason_Set_Input>;
  where: Deal_Fallen_Reason_Bool_Exp;
};

/** input type for incrementing numeric columns in table "deal" */
export type Deal_Inc_Input = {
  balance_of_deposit?: InputMaybe<Scalars['Int']>;
  bathrooms?: InputMaybe<Scalars['Int']>;
  bedrooms?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_max?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_min?: InputMaybe<Scalars['Int']>;
  buying_capacity?: InputMaybe<Scalars['Int']>;
  cars?: InputMaybe<Scalars['Int']>;
  contract_deposit_amount?: InputMaybe<Scalars['numeric']>;
  deposit_due?: InputMaybe<Scalars['Int']>;
  holding_deposit_amount?: InputMaybe<Scalars['numeric']>;
  holding_deposit_amount_received?: InputMaybe<Scalars['numeric']>;
  holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  parking_spaces?: InputMaybe<Scalars['Int']>;
  sale_price?: InputMaybe<Scalars['Int']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  total_deposit_outstanding?: InputMaybe<Scalars['Int']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  uploaded_id_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "deal" */
export type Deal_Insert_Input = {
  account_manager_id?: InputMaybe<Scalars['uuid']>;
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Arr_Rel_Insert_Input>;
  acl_deal_developer_member?: InputMaybe<Acl_Deal_Developer_Member_Arr_Rel_Insert_Input>;
  acl_deal_master_agent?: InputMaybe<Acl_Deal_Master_Agent_Arr_Rel_Insert_Input>;
  acl_deal_user_team?: InputMaybe<Acl_Deal_User_Team_Arr_Rel_Insert_Input>;
  advised_contract_ready_to_progress?: InputMaybe<Scalars['date']>;
  agency_contact_id?: InputMaybe<Scalars['uuid']>;
  agency_id?: InputMaybe<Scalars['uuid']>;
  agency_organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  agent_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  awaiting_enquiry_sf_id?: InputMaybe<Scalars['Boolean']>;
  awaiting_legal_sf_id?: InputMaybe<Scalars['Boolean']>;
  balance_due_by?: InputMaybe<Scalars['timestamptz']>;
  balance_of_deposit?: InputMaybe<Scalars['Int']>;
  bank_guarantee_expires?: InputMaybe<Scalars['date']>;
  bathrooms?: InputMaybe<Scalars['Int']>;
  bedrooms?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_max?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_min?: InputMaybe<Scalars['Int']>;
  bedrooms_requested?: InputMaybe<Scalars['String']>;
  bond_expires?: InputMaybe<Scalars['date']>;
  bpay_biller_code?: InputMaybe<Scalars['String']>;
  bpay_crn?: InputMaybe<Scalars['String']>;
  building_id?: InputMaybe<Scalars['uuid']>;
  buying_capacity?: InputMaybe<Scalars['Int']>;
  cars?: InputMaybe<Scalars['Int']>;
  changes_requested?: InputMaybe<Scalars['Boolean']>;
  colour_scheme?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  comment_for_buyer?: InputMaybe<Scalars['String']>;
  commission_split?: InputMaybe<Scalars['Boolean']>;
  commissions?: InputMaybe<Commission_Arr_Rel_Insert_Input>;
  confirmed_firb_status?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Deal_Contact_Status_Enum>;
  contacts?: InputMaybe<Deal_Contact_Arr_Rel_Insert_Input>;
  contract_conditional?: InputMaybe<Scalars['date']>;
  contract_deposit_amount?: InputMaybe<Scalars['numeric']>;
  contract_exchanged?: InputMaybe<Scalars['timestamptz']>;
  contract_issued?: InputMaybe<Scalars['timestamptz']>;
  contract_progress_update?: InputMaybe<Deal_Progress_Update_Enum>;
  contract_returned_to_vendor?: InputMaybe<Scalars['date']>;
  contract_sent_to_purchaser?: InputMaybe<Scalars['date']>;
  contract_unconditional?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_ends?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_starts?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_waived?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  deal_contact_status?: InputMaybe<Deal_Contact_Status_Obj_Rel_Insert_Input>;
  deal_contact_type?: InputMaybe<Deal_Contact_Obj_Rel_Insert_Input>;
  deal_deposit_status?: InputMaybe<Deal_Deposit_Status_Obj_Rel_Insert_Input>;
  deal_enquiry_source?: InputMaybe<Deal_Enquiry_Source_Obj_Rel_Insert_Input>;
  deal_fallen_reason?: InputMaybe<Deal_Fallen_Reason_Obj_Rel_Insert_Input>;
  deal_payment_method?: InputMaybe<Deal_Payment_Method_Obj_Rel_Insert_Input>;
  deal_progress_update?: InputMaybe<Deal_Progress_Update_Obj_Rel_Insert_Input>;
  deal_reason_to_purchase?: InputMaybe<Deal_Reason_Purchase_Obj_Rel_Insert_Input>;
  deal_status?: InputMaybe<Deal_Status_Obj_Rel_Insert_Input>;
  deal_transfer_status?: InputMaybe<Deal_Transfer_Status_Obj_Rel_Insert_Input>;
  deal_type?: InputMaybe<Deal_Type_Obj_Rel_Insert_Input>;
  deal_witnesses?: InputMaybe<Deal_Witness_Arr_Rel_Insert_Input>;
  deposit_due?: InputMaybe<Scalars['Int']>;
  deposit_received?: InputMaybe<Scalars['date']>;
  deposit_status?: InputMaybe<Deal_Deposit_Status_Enum>;
  deposit_type?: InputMaybe<Deal_Deposit_Type_Enum>;
  enquiry_source?: InputMaybe<Deal_Enquiry_Source_Enum>;
  enquiry_source_other?: InputMaybe<Scalars['String']>;
  envelope_opened_by_buyer?: InputMaybe<Scalars['timestamptz']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  eoi_approved_by_vendor?: InputMaybe<Scalars['timestamptz']>;
  fallen_date?: InputMaybe<Scalars['date']>;
  fallen_explanation?: InputMaybe<Scalars['String']>;
  fallen_reason?: InputMaybe<Deal_Fallen_Reason_Enum>;
  finance_status?: InputMaybe<Finance_Status_Enum>;
  firb_approved_vendor?: InputMaybe<Scalars['String']>;
  firb_confirmation_received?: InputMaybe<Scalars['date']>;
  firb_purchaser?: InputMaybe<Scalars['Boolean']>;
  forecast_exchange?: InputMaybe<Scalars['date']>;
  form_of_settlement?: InputMaybe<Scalars['String']>;
  holding_deposit_amount?: InputMaybe<Scalars['numeric']>;
  holding_deposit_amount_received?: InputMaybe<Scalars['numeric']>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Scalars['date']>;
  holding_deposit_number?: InputMaybe<Scalars['String']>;
  holding_deposit_paid_handled?: InputMaybe<Scalars['Boolean']>;
  holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  holding_deposit_received?: InputMaybe<Scalars['Boolean']>;
  holding_deposit_received_by_vsol?: InputMaybe<Scalars['date']>;
  how_did_you_hear_about_the_property?: InputMaybe<Scalars['String']>;
  hubspot?: InputMaybe<Hubspot_Deal_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  id_received?: InputMaybe<Scalars['Boolean']>;
  identity_doc1_number?: InputMaybe<Scalars['String']>;
  identity_doc1_region?: InputMaybe<Scalars['String']>;
  identity_doc1_type?: InputMaybe<Scalars['String']>;
  incl_in_deposit?: InputMaybe<Scalars['Boolean']>;
  last_sales_advice_generated_at?: InputMaybe<Scalars['timestamptz']>;
  lead_id?: InputMaybe<Scalars['uuid']>;
  lead_strength?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  name_on_contract?: InputMaybe<Scalars['String']>;
  on_hold?: InputMaybe<Scalars['timestamptz']>;
  on_hold_approved_by_client?: InputMaybe<Scalars['timestamptz']>;
  on_hold_approved_by_vendor?: InputMaybe<Scalars['timestamptz']>;
  on_hold_release?: InputMaybe<Scalars['date']>;
  paid_by?: InputMaybe<Scalars['String']>;
  parking_spaces?: InputMaybe<Scalars['Int']>;
  payable_on?: InputMaybe<Scalars['date']>;
  payment_method?: InputMaybe<Deal_Payment_Method_Enum>;
  pre_settlement_booked_by?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Preference_Arr_Rel_Insert_Input>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  proof_verified?: InputMaybe<Scalars['Boolean']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  purchase_proposal_sent?: InputMaybe<Scalars['Boolean']>;
  purchaser_solicitor_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  purchaser_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  purchaser_solicitor_id?: InputMaybe<Scalars['uuid']>;
  purchaser_solicitor_organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  reason_to_purchase?: InputMaybe<Scalars['String']>;
  refund_account_bsb?: InputMaybe<Scalars['String']>;
  refund_account_name?: InputMaybe<Scalars['String']>;
  refund_account_number?: InputMaybe<Scalars['String']>;
  refund_requested?: InputMaybe<Scalars['date']>;
  refund_swift_code?: InputMaybe<Scalars['String']>;
  request_reference?: InputMaybe<Scalars['String']>;
  reservation_finalised?: InputMaybe<Scalars['Boolean']>;
  reserved_on?: InputMaybe<Scalars['timestamptz']>;
  sa_received?: InputMaybe<Scalars['date']>;
  sa_sent_to_legal?: InputMaybe<Scalars['date']>;
  sale_price?: InputMaybe<Scalars['Int']>;
  settlement?: InputMaybe<Scalars['timestamptz']>;
  settlement_booked?: InputMaybe<Scalars['date']>;
  settlement_defect_status?: InputMaybe<Scalars['String']>;
  settlement_due_date?: InputMaybe<Scalars['date']>;
  settlement_extension_requested_date?: InputMaybe<Scalars['date']>;
  settlement_inspection?: InputMaybe<Scalars['timestamp']>;
  settlement_inspection_completed?: InputMaybe<Scalars['Boolean']>;
  settlement_keys_collected_date?: InputMaybe<Scalars['date']>;
  settlement_outlook?: InputMaybe<Scalars['String']>;
  settlement_story?: InputMaybe<Scalars['String']>;
  settlement_summary?: InputMaybe<Scalars['String']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  sf_enquiry_id?: InputMaybe<Scalars['String']>;
  sf_legal_id?: InputMaybe<Scalars['String']>;
  solicitor_notes?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Lead_Generation_Source_Enum>;
  spoken_language?: InputMaybe<Deal_Spoken_Language_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Deal_Status_Enum>;
  sub_agency?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  sub_agency_contact_id?: InputMaybe<Scalars['uuid']>;
  sub_agency_id?: InputMaybe<Scalars['uuid']>;
  sub_agent_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  terms?: InputMaybe<Scalars['String']>;
  time_frame?: InputMaybe<Scalars['String']>;
  total_deposit_extension_date?: InputMaybe<Scalars['date']>;
  total_deposit_outstanding?: InputMaybe<Scalars['Int']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  total_deposit_selection?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<Transaction_Arr_Rel_Insert_Input>;
  transfer_receipt_time?: InputMaybe<Scalars['date']>;
  transfer_status?: InputMaybe<Deal_Transfer_Status_Enum>;
  transfer_to_vsol_requested?: InputMaybe<Scalars['Boolean']>;
  transferred_to_vsol?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Deal_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_id?: InputMaybe<Scalars['Boolean']>;
  uploaded_id_count?: InputMaybe<Scalars['Int']>;
  uploaded_receipt?: InputMaybe<Scalars['Boolean']>;
  vendor_solicitor_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  vendor_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  x66w_certificate_provided?: InputMaybe<Scalars['Boolean']>;
  x66w_received_by_vsol?: InputMaybe<Scalars['date']>;
};

/** order by max() on columns of table "deal" */
export type Deal_Max_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  advised_contract_ready_to_progress?: InputMaybe<Order_By>;
  agency_contact_id?: InputMaybe<Order_By>;
  agency_id?: InputMaybe<Order_By>;
  balance_due_by?: InputMaybe<Order_By>;
  balance_of_deposit?: InputMaybe<Order_By>;
  bank_guarantee_expires?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  bedrooms_requested?: InputMaybe<Order_By>;
  bond_expires?: InputMaybe<Order_By>;
  bpay_biller_code?: InputMaybe<Order_By>;
  bpay_crn?: InputMaybe<Order_By>;
  building_id?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  colour_scheme?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  comment_for_buyer?: InputMaybe<Order_By>;
  confirmed_firb_status?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contract_conditional?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  contract_exchanged?: InputMaybe<Order_By>;
  contract_issued?: InputMaybe<Order_By>;
  contract_returned_to_vendor?: InputMaybe<Order_By>;
  contract_sent_to_purchaser?: InputMaybe<Order_By>;
  contract_unconditional?: InputMaybe<Order_By>;
  cooling_off_ends?: InputMaybe<Order_By>;
  cooling_off_starts?: InputMaybe<Order_By>;
  cooling_off_waived?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  deposit_received?: InputMaybe<Order_By>;
  enquiry_source_other?: InputMaybe<Order_By>;
  envelope_opened_by_buyer?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  eoi_approved_by_vendor?: InputMaybe<Order_By>;
  fallen_date?: InputMaybe<Order_By>;
  fallen_explanation?: InputMaybe<Order_By>;
  firb_approved_vendor?: InputMaybe<Order_By>;
  firb_confirmation_received?: InputMaybe<Order_By>;
  forecast_exchange?: InputMaybe<Order_By>;
  form_of_settlement?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Order_By>;
  holding_deposit_number?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  holding_deposit_received_by_vsol?: InputMaybe<Order_By>;
  how_did_you_hear_about_the_property?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_doc1_number?: InputMaybe<Order_By>;
  identity_doc1_region?: InputMaybe<Order_By>;
  identity_doc1_type?: InputMaybe<Order_By>;
  last_sales_advice_generated_at?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  lead_strength?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_on_contract?: InputMaybe<Order_By>;
  on_hold?: InputMaybe<Order_By>;
  on_hold_approved_by_client?: InputMaybe<Order_By>;
  on_hold_approved_by_vendor?: InputMaybe<Order_By>;
  on_hold_release?: InputMaybe<Order_By>;
  paid_by?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  payable_on?: InputMaybe<Order_By>;
  pre_settlement_booked_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  purchaser_solicitor_contact_id?: InputMaybe<Order_By>;
  purchaser_solicitor_id?: InputMaybe<Order_By>;
  reason_to_purchase?: InputMaybe<Order_By>;
  refund_account_bsb?: InputMaybe<Order_By>;
  refund_account_name?: InputMaybe<Order_By>;
  refund_account_number?: InputMaybe<Order_By>;
  refund_requested?: InputMaybe<Order_By>;
  refund_swift_code?: InputMaybe<Order_By>;
  request_reference?: InputMaybe<Order_By>;
  reserved_on?: InputMaybe<Order_By>;
  sa_received?: InputMaybe<Order_By>;
  sa_sent_to_legal?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  settlement?: InputMaybe<Order_By>;
  settlement_booked?: InputMaybe<Order_By>;
  settlement_defect_status?: InputMaybe<Order_By>;
  settlement_due_date?: InputMaybe<Order_By>;
  settlement_extension_requested_date?: InputMaybe<Order_By>;
  settlement_inspection?: InputMaybe<Order_By>;
  settlement_keys_collected_date?: InputMaybe<Order_By>;
  settlement_outlook?: InputMaybe<Order_By>;
  settlement_story?: InputMaybe<Order_By>;
  settlement_summary?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  sf_enquiry_id?: InputMaybe<Order_By>;
  sf_legal_id?: InputMaybe<Order_By>;
  solicitor_notes?: InputMaybe<Order_By>;
  sub_agency_contact_id?: InputMaybe<Order_By>;
  sub_agency_id?: InputMaybe<Order_By>;
  terms?: InputMaybe<Order_By>;
  time_frame?: InputMaybe<Order_By>;
  total_deposit_extension_date?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  total_deposit_selection?: InputMaybe<Order_By>;
  transfer_receipt_time?: InputMaybe<Order_By>;
  transferred_to_vsol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
  vendor_solicitor_contact_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
  x66w_received_by_vsol?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "deal" */
export type Deal_Min_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  advised_contract_ready_to_progress?: InputMaybe<Order_By>;
  agency_contact_id?: InputMaybe<Order_By>;
  agency_id?: InputMaybe<Order_By>;
  balance_due_by?: InputMaybe<Order_By>;
  balance_of_deposit?: InputMaybe<Order_By>;
  bank_guarantee_expires?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  bedrooms_requested?: InputMaybe<Order_By>;
  bond_expires?: InputMaybe<Order_By>;
  bpay_biller_code?: InputMaybe<Order_By>;
  bpay_crn?: InputMaybe<Order_By>;
  building_id?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  colour_scheme?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  comment_for_buyer?: InputMaybe<Order_By>;
  confirmed_firb_status?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contract_conditional?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  contract_exchanged?: InputMaybe<Order_By>;
  contract_issued?: InputMaybe<Order_By>;
  contract_returned_to_vendor?: InputMaybe<Order_By>;
  contract_sent_to_purchaser?: InputMaybe<Order_By>;
  contract_unconditional?: InputMaybe<Order_By>;
  cooling_off_ends?: InputMaybe<Order_By>;
  cooling_off_starts?: InputMaybe<Order_By>;
  cooling_off_waived?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  deposit_received?: InputMaybe<Order_By>;
  enquiry_source_other?: InputMaybe<Order_By>;
  envelope_opened_by_buyer?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  eoi_approved_by_vendor?: InputMaybe<Order_By>;
  fallen_date?: InputMaybe<Order_By>;
  fallen_explanation?: InputMaybe<Order_By>;
  firb_approved_vendor?: InputMaybe<Order_By>;
  firb_confirmation_received?: InputMaybe<Order_By>;
  forecast_exchange?: InputMaybe<Order_By>;
  form_of_settlement?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Order_By>;
  holding_deposit_number?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  holding_deposit_received_by_vsol?: InputMaybe<Order_By>;
  how_did_you_hear_about_the_property?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_doc1_number?: InputMaybe<Order_By>;
  identity_doc1_region?: InputMaybe<Order_By>;
  identity_doc1_type?: InputMaybe<Order_By>;
  last_sales_advice_generated_at?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  lead_strength?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_on_contract?: InputMaybe<Order_By>;
  on_hold?: InputMaybe<Order_By>;
  on_hold_approved_by_client?: InputMaybe<Order_By>;
  on_hold_approved_by_vendor?: InputMaybe<Order_By>;
  on_hold_release?: InputMaybe<Order_By>;
  paid_by?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  payable_on?: InputMaybe<Order_By>;
  pre_settlement_booked_by?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  purchaser_solicitor_contact_id?: InputMaybe<Order_By>;
  purchaser_solicitor_id?: InputMaybe<Order_By>;
  reason_to_purchase?: InputMaybe<Order_By>;
  refund_account_bsb?: InputMaybe<Order_By>;
  refund_account_name?: InputMaybe<Order_By>;
  refund_account_number?: InputMaybe<Order_By>;
  refund_requested?: InputMaybe<Order_By>;
  refund_swift_code?: InputMaybe<Order_By>;
  request_reference?: InputMaybe<Order_By>;
  reserved_on?: InputMaybe<Order_By>;
  sa_received?: InputMaybe<Order_By>;
  sa_sent_to_legal?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  settlement?: InputMaybe<Order_By>;
  settlement_booked?: InputMaybe<Order_By>;
  settlement_defect_status?: InputMaybe<Order_By>;
  settlement_due_date?: InputMaybe<Order_By>;
  settlement_extension_requested_date?: InputMaybe<Order_By>;
  settlement_inspection?: InputMaybe<Order_By>;
  settlement_keys_collected_date?: InputMaybe<Order_By>;
  settlement_outlook?: InputMaybe<Order_By>;
  settlement_story?: InputMaybe<Order_By>;
  settlement_summary?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  sf_enquiry_id?: InputMaybe<Order_By>;
  sf_legal_id?: InputMaybe<Order_By>;
  solicitor_notes?: InputMaybe<Order_By>;
  sub_agency_contact_id?: InputMaybe<Order_By>;
  sub_agency_id?: InputMaybe<Order_By>;
  terms?: InputMaybe<Order_By>;
  time_frame?: InputMaybe<Order_By>;
  total_deposit_extension_date?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  total_deposit_selection?: InputMaybe<Order_By>;
  transfer_receipt_time?: InputMaybe<Order_By>;
  transferred_to_vsol?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
  vendor_solicitor_contact_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
  x66w_received_by_vsol?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "deal" */
export type Deal_Obj_Rel_Insert_Input = {
  data: Deal_Insert_Input;
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

/** on_conflict condition type for table "deal" */
export type Deal_On_Conflict = {
  constraint: Deal_Constraint;
  update_columns: Array<Deal_Update_Column>;
  where?: InputMaybe<Deal_Bool_Exp>;
};

/** Ordering options when selecting data from "deal". */
export type Deal_Order_By = {
  account_manager_id?: InputMaybe<Order_By>;
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Order_By>;
  acl_deal_developer_member_aggregate?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Order_By>;
  acl_deal_master_agent_aggregate?: InputMaybe<Acl_Deal_Master_Agent_Aggregate_Order_By>;
  acl_deal_user_team_aggregate?: InputMaybe<Acl_Deal_User_Team_Aggregate_Order_By>;
  advised_contract_ready_to_progress?: InputMaybe<Order_By>;
  agency_contact_id?: InputMaybe<Order_By>;
  agency_id?: InputMaybe<Order_By>;
  agency_organisation?: InputMaybe<Organisation_Order_By>;
  agent_contact?: InputMaybe<Contact_Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  awaiting_enquiry_sf_id?: InputMaybe<Order_By>;
  awaiting_legal_sf_id?: InputMaybe<Order_By>;
  balance_due_by?: InputMaybe<Order_By>;
  balance_of_deposit?: InputMaybe<Order_By>;
  balance_of_deposit_calc?: InputMaybe<Order_By>;
  bank_guarantee_expires?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  bedrooms_requested?: InputMaybe<Order_By>;
  bond_expires?: InputMaybe<Order_By>;
  bpay_biller_code?: InputMaybe<Order_By>;
  bpay_crn?: InputMaybe<Order_By>;
  building_id?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  changes_requested?: InputMaybe<Order_By>;
  colour_scheme?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  comment_for_buyer?: InputMaybe<Order_By>;
  commission_split?: InputMaybe<Order_By>;
  commissions_aggregate?: InputMaybe<Commission_Aggregate_Order_By>;
  confirmed_firb_status?: InputMaybe<Order_By>;
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  contact_status?: InputMaybe<Order_By>;
  contacts_aggregate?: InputMaybe<Deal_Contact_Aggregate_Order_By>;
  contract_conditional?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  contract_deposit_amount_calc?: InputMaybe<Order_By>;
  contract_deposit_paid_calc?: InputMaybe<Order_By>;
  contract_deposit_paid_total_calc?: InputMaybe<Order_By>;
  contract_exchanged?: InputMaybe<Order_By>;
  contract_issued?: InputMaybe<Order_By>;
  contract_progress_update?: InputMaybe<Order_By>;
  contract_returned_to_vendor?: InputMaybe<Order_By>;
  contract_sent_to_purchaser?: InputMaybe<Order_By>;
  contract_unconditional?: InputMaybe<Order_By>;
  cooling_off_ends?: InputMaybe<Order_By>;
  cooling_off_starts?: InputMaybe<Order_By>;
  cooling_off_waived?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  deal_contact_status?: InputMaybe<Deal_Contact_Status_Order_By>;
  deal_contact_type?: InputMaybe<Deal_Contact_Order_By>;
  deal_deposit_status?: InputMaybe<Deal_Deposit_Status_Order_By>;
  deal_enquiry_source?: InputMaybe<Deal_Enquiry_Source_Order_By>;
  deal_fallen_reason?: InputMaybe<Deal_Fallen_Reason_Order_By>;
  deal_payment_method?: InputMaybe<Deal_Payment_Method_Order_By>;
  deal_price_inc_variations_calc?: InputMaybe<Order_By>;
  deal_progress_update?: InputMaybe<Deal_Progress_Update_Order_By>;
  deal_reason_to_purchase?: InputMaybe<Deal_Reason_Purchase_Order_By>;
  deal_status?: InputMaybe<Deal_Status_Order_By>;
  deal_transfer_status?: InputMaybe<Deal_Transfer_Status_Order_By>;
  deal_type?: InputMaybe<Deal_Type_Order_By>;
  deal_witnesses_aggregate?: InputMaybe<Deal_Witness_Aggregate_Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  deposit_received?: InputMaybe<Order_By>;
  deposit_status?: InputMaybe<Order_By>;
  deposit_type?: InputMaybe<Order_By>;
  enquiry_source?: InputMaybe<Order_By>;
  enquiry_source_other?: InputMaybe<Order_By>;
  envelope_opened_by_buyer?: InputMaybe<Order_By>;
  envelope_owner?: InputMaybe<Order_By>;
  eoi_approved_by_vendor?: InputMaybe<Order_By>;
  fallen_date?: InputMaybe<Order_By>;
  fallen_explanation?: InputMaybe<Order_By>;
  fallen_reason?: InputMaybe<Order_By>;
  finance_status?: InputMaybe<Order_By>;
  firb_approved_vendor?: InputMaybe<Order_By>;
  firb_confirmation_received?: InputMaybe<Order_By>;
  firb_purchaser?: InputMaybe<Order_By>;
  forecast_exchange?: InputMaybe<Order_By>;
  form_of_settlement?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Order_By>;
  holding_deposit_due_calc?: InputMaybe<Order_By>;
  holding_deposit_number?: InputMaybe<Order_By>;
  holding_deposit_paid_calc?: InputMaybe<Order_By>;
  holding_deposit_paid_handled?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  holding_deposit_received?: InputMaybe<Order_By>;
  holding_deposit_received_by_vsol?: InputMaybe<Order_By>;
  how_did_you_hear_about_the_property?: InputMaybe<Order_By>;
  hubspot_aggregate?: InputMaybe<Hubspot_Deal_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  id_received?: InputMaybe<Order_By>;
  identity_doc1_number?: InputMaybe<Order_By>;
  identity_doc1_region?: InputMaybe<Order_By>;
  identity_doc1_type?: InputMaybe<Order_By>;
  incl_in_deposit?: InputMaybe<Order_By>;
  last_sales_advice_generated_at?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  lead_strength?: InputMaybe<Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  listing_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_on_contract?: InputMaybe<Order_By>;
  on_hold?: InputMaybe<Order_By>;
  on_hold_approved_by_client?: InputMaybe<Order_By>;
  on_hold_approved_by_vendor?: InputMaybe<Order_By>;
  on_hold_release?: InputMaybe<Order_By>;
  paid_by?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  payable_on?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  pre_settlement_booked_by?: InputMaybe<Order_By>;
  preferences_aggregate?: InputMaybe<Preference_Aggregate_Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  proof_verified?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_id?: InputMaybe<Order_By>;
  purchase_proposal_sent?: InputMaybe<Order_By>;
  purchaser_solicitor_contact?: InputMaybe<Contact_Order_By>;
  purchaser_solicitor_contact_id?: InputMaybe<Order_By>;
  purchaser_solicitor_id?: InputMaybe<Order_By>;
  purchaser_solicitor_organisation?: InputMaybe<Organisation_Order_By>;
  reason_to_purchase?: InputMaybe<Order_By>;
  refund_account_bsb?: InputMaybe<Order_By>;
  refund_account_name?: InputMaybe<Order_By>;
  refund_account_number?: InputMaybe<Order_By>;
  refund_requested?: InputMaybe<Order_By>;
  refund_swift_code?: InputMaybe<Order_By>;
  request_reference?: InputMaybe<Order_By>;
  reservation_finalised?: InputMaybe<Order_By>;
  reserved_on?: InputMaybe<Order_By>;
  sa_received?: InputMaybe<Order_By>;
  sa_sent_to_legal?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  settlement?: InputMaybe<Order_By>;
  settlement_booked?: InputMaybe<Order_By>;
  settlement_defect_status?: InputMaybe<Order_By>;
  settlement_due_date?: InputMaybe<Order_By>;
  settlement_extension_requested_date?: InputMaybe<Order_By>;
  settlement_inspection?: InputMaybe<Order_By>;
  settlement_inspection_completed?: InputMaybe<Order_By>;
  settlement_keys_collected_date?: InputMaybe<Order_By>;
  settlement_outlook?: InputMaybe<Order_By>;
  settlement_story?: InputMaybe<Order_By>;
  settlement_summary?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  sf_enquiry_id?: InputMaybe<Order_By>;
  sf_legal_id?: InputMaybe<Order_By>;
  solicitor_notes?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  spoken_language_aggregate?: InputMaybe<Deal_Spoken_Language_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  sub_agency?: InputMaybe<Organisation_Order_By>;
  sub_agency_contact_id?: InputMaybe<Order_By>;
  sub_agency_id?: InputMaybe<Order_By>;
  sub_agent_contact?: InputMaybe<Contact_Order_By>;
  terms?: InputMaybe<Order_By>;
  time_frame?: InputMaybe<Order_By>;
  total_deposit_extension_date?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  total_deposit_selection?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>;
  transfer_receipt_time?: InputMaybe<Order_By>;
  transfer_status?: InputMaybe<Order_By>;
  transfer_to_vsol_requested?: InputMaybe<Order_By>;
  transferred_to_vsol?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_id?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
  uploaded_receipt?: InputMaybe<Order_By>;
  variations_calc?: InputMaybe<Order_By>;
  vendor_solicitor_contact?: InputMaybe<Contact_Order_By>;
  vendor_solicitor_contact_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
  vendor_solicitor_organisation?: InputMaybe<Organisation_Order_By>;
  x66w_certificate_provided?: InputMaybe<Order_By>;
  x66w_received_by_vsol?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal_payment_method". All fields are combined with a logical 'AND'. */
export type Deal_Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Payment_Method_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_payment_method_enum". All fields are combined with logical 'AND'. */
export type Deal_Payment_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Payment_Method_Enum>;
  _in?: InputMaybe<Array<Deal_Payment_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Payment_Method_Enum>;
  _nin?: InputMaybe<Array<Deal_Payment_Method_Enum>>;
};

/** input type for inserting data into table "deal_payment_method" */
export type Deal_Payment_Method_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_payment_method" */
export type Deal_Payment_Method_Obj_Rel_Insert_Input = {
  data: Deal_Payment_Method_Insert_Input;
  on_conflict?: InputMaybe<Deal_Payment_Method_On_Conflict>;
};

/** on_conflict condition type for table "deal_payment_method" */
export type Deal_Payment_Method_On_Conflict = {
  constraint: Deal_Payment_Method_Constraint;
  update_columns: Array<Deal_Payment_Method_Update_Column>;
  where?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_payment_method". */
export type Deal_Payment_Method_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_payment_method */
export type Deal_Payment_Method_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_payment_method" */
export type Deal_Payment_Method_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_payment_method" */
export type Deal_Payment_Method_Stream_Cursor_Input = {
  initial_value: Deal_Payment_Method_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Payment_Method_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Payment_Method_Updates = {
  _set?: InputMaybe<Deal_Payment_Method_Set_Input>;
  where: Deal_Payment_Method_Bool_Exp;
};

/** primary key columns input for table: deal */
export type Deal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "deal_progress_update". All fields are combined with a logical 'AND'. */
export type Deal_Progress_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Progress_Update_Bool_Exp>>;
  _not?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Progress_Update_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_progress_update_enum". All fields are combined with logical 'AND'. */
export type Deal_Progress_Update_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Progress_Update_Enum>;
  _in?: InputMaybe<Array<Deal_Progress_Update_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Progress_Update_Enum>;
  _nin?: InputMaybe<Array<Deal_Progress_Update_Enum>>;
};

/** input type for inserting data into table "deal_progress_update" */
export type Deal_Progress_Update_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_progress_update" */
export type Deal_Progress_Update_Obj_Rel_Insert_Input = {
  data: Deal_Progress_Update_Insert_Input;
  on_conflict?: InputMaybe<Deal_Progress_Update_On_Conflict>;
};

/** on_conflict condition type for table "deal_progress_update" */
export type Deal_Progress_Update_On_Conflict = {
  constraint: Deal_Progress_Update_Constraint;
  update_columns: Array<Deal_Progress_Update_Update_Column>;
  where?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_progress_update". */
export type Deal_Progress_Update_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_progress_update */
export type Deal_Progress_Update_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_progress_update" */
export type Deal_Progress_Update_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_progress_update" */
export type Deal_Progress_Update_Stream_Cursor_Input = {
  initial_value: Deal_Progress_Update_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Progress_Update_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Progress_Update_Updates = {
  _set?: InputMaybe<Deal_Progress_Update_Set_Input>;
  where: Deal_Progress_Update_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_reason_purchase". All fields are combined with a logical 'AND'. */
export type Deal_Reason_Purchase_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Reason_Purchase_Bool_Exp>>;
  _not?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Reason_Purchase_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "deal_reason_purchase" */
export type Deal_Reason_Purchase_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_reason_purchase" */
export type Deal_Reason_Purchase_Obj_Rel_Insert_Input = {
  data: Deal_Reason_Purchase_Insert_Input;
  on_conflict?: InputMaybe<Deal_Reason_Purchase_On_Conflict>;
};

/** on_conflict condition type for table "deal_reason_purchase" */
export type Deal_Reason_Purchase_On_Conflict = {
  constraint: Deal_Reason_Purchase_Constraint;
  update_columns: Array<Deal_Reason_Purchase_Update_Column>;
  where?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_reason_purchase". */
export type Deal_Reason_Purchase_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_reason_purchase */
export type Deal_Reason_Purchase_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_reason_purchase" */
export type Deal_Reason_Purchase_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_reason_purchase" */
export type Deal_Reason_Purchase_Stream_Cursor_Input = {
  initial_value: Deal_Reason_Purchase_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Reason_Purchase_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Reason_Purchase_Updates = {
  _set?: InputMaybe<Deal_Reason_Purchase_Set_Input>;
  where: Deal_Reason_Purchase_Bool_Exp;
};

/** input type for updating data in table "deal" */
export type Deal_Set_Input = {
  account_manager_id?: InputMaybe<Scalars['uuid']>;
  advised_contract_ready_to_progress?: InputMaybe<Scalars['date']>;
  agency_contact_id?: InputMaybe<Scalars['uuid']>;
  agency_id?: InputMaybe<Scalars['uuid']>;
  awaiting_enquiry_sf_id?: InputMaybe<Scalars['Boolean']>;
  awaiting_legal_sf_id?: InputMaybe<Scalars['Boolean']>;
  balance_due_by?: InputMaybe<Scalars['timestamptz']>;
  balance_of_deposit?: InputMaybe<Scalars['Int']>;
  bank_guarantee_expires?: InputMaybe<Scalars['date']>;
  bathrooms?: InputMaybe<Scalars['Int']>;
  bedrooms?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_max?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_min?: InputMaybe<Scalars['Int']>;
  bedrooms_requested?: InputMaybe<Scalars['String']>;
  bond_expires?: InputMaybe<Scalars['date']>;
  bpay_biller_code?: InputMaybe<Scalars['String']>;
  bpay_crn?: InputMaybe<Scalars['String']>;
  building_id?: InputMaybe<Scalars['uuid']>;
  buying_capacity?: InputMaybe<Scalars['Int']>;
  cars?: InputMaybe<Scalars['Int']>;
  changes_requested?: InputMaybe<Scalars['Boolean']>;
  colour_scheme?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  comment_for_buyer?: InputMaybe<Scalars['String']>;
  commission_split?: InputMaybe<Scalars['Boolean']>;
  confirmed_firb_status?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Deal_Contact_Status_Enum>;
  contract_conditional?: InputMaybe<Scalars['date']>;
  contract_deposit_amount?: InputMaybe<Scalars['numeric']>;
  contract_exchanged?: InputMaybe<Scalars['timestamptz']>;
  contract_issued?: InputMaybe<Scalars['timestamptz']>;
  contract_progress_update?: InputMaybe<Deal_Progress_Update_Enum>;
  contract_returned_to_vendor?: InputMaybe<Scalars['date']>;
  contract_sent_to_purchaser?: InputMaybe<Scalars['date']>;
  contract_unconditional?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_ends?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_starts?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_waived?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  deposit_due?: InputMaybe<Scalars['Int']>;
  deposit_received?: InputMaybe<Scalars['date']>;
  deposit_status?: InputMaybe<Deal_Deposit_Status_Enum>;
  deposit_type?: InputMaybe<Deal_Deposit_Type_Enum>;
  enquiry_source?: InputMaybe<Deal_Enquiry_Source_Enum>;
  enquiry_source_other?: InputMaybe<Scalars['String']>;
  envelope_opened_by_buyer?: InputMaybe<Scalars['timestamptz']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  eoi_approved_by_vendor?: InputMaybe<Scalars['timestamptz']>;
  fallen_date?: InputMaybe<Scalars['date']>;
  fallen_explanation?: InputMaybe<Scalars['String']>;
  fallen_reason?: InputMaybe<Deal_Fallen_Reason_Enum>;
  finance_status?: InputMaybe<Finance_Status_Enum>;
  firb_approved_vendor?: InputMaybe<Scalars['String']>;
  firb_confirmation_received?: InputMaybe<Scalars['date']>;
  firb_purchaser?: InputMaybe<Scalars['Boolean']>;
  forecast_exchange?: InputMaybe<Scalars['date']>;
  form_of_settlement?: InputMaybe<Scalars['String']>;
  holding_deposit_amount?: InputMaybe<Scalars['numeric']>;
  holding_deposit_amount_received?: InputMaybe<Scalars['numeric']>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Scalars['date']>;
  holding_deposit_number?: InputMaybe<Scalars['String']>;
  holding_deposit_paid_handled?: InputMaybe<Scalars['Boolean']>;
  holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  holding_deposit_received?: InputMaybe<Scalars['Boolean']>;
  holding_deposit_received_by_vsol?: InputMaybe<Scalars['date']>;
  how_did_you_hear_about_the_property?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_received?: InputMaybe<Scalars['Boolean']>;
  identity_doc1_number?: InputMaybe<Scalars['String']>;
  identity_doc1_region?: InputMaybe<Scalars['String']>;
  identity_doc1_type?: InputMaybe<Scalars['String']>;
  incl_in_deposit?: InputMaybe<Scalars['Boolean']>;
  last_sales_advice_generated_at?: InputMaybe<Scalars['timestamptz']>;
  lead_id?: InputMaybe<Scalars['uuid']>;
  lead_strength?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  name_on_contract?: InputMaybe<Scalars['String']>;
  on_hold?: InputMaybe<Scalars['timestamptz']>;
  on_hold_approved_by_client?: InputMaybe<Scalars['timestamptz']>;
  on_hold_approved_by_vendor?: InputMaybe<Scalars['timestamptz']>;
  on_hold_release?: InputMaybe<Scalars['date']>;
  paid_by?: InputMaybe<Scalars['String']>;
  parking_spaces?: InputMaybe<Scalars['Int']>;
  payable_on?: InputMaybe<Scalars['date']>;
  payment_method?: InputMaybe<Deal_Payment_Method_Enum>;
  pre_settlement_booked_by?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  proof_verified?: InputMaybe<Scalars['Boolean']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  purchase_proposal_sent?: InputMaybe<Scalars['Boolean']>;
  purchaser_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  purchaser_solicitor_id?: InputMaybe<Scalars['uuid']>;
  reason_to_purchase?: InputMaybe<Scalars['String']>;
  refund_account_bsb?: InputMaybe<Scalars['String']>;
  refund_account_name?: InputMaybe<Scalars['String']>;
  refund_account_number?: InputMaybe<Scalars['String']>;
  refund_requested?: InputMaybe<Scalars['date']>;
  refund_swift_code?: InputMaybe<Scalars['String']>;
  request_reference?: InputMaybe<Scalars['String']>;
  reservation_finalised?: InputMaybe<Scalars['Boolean']>;
  reserved_on?: InputMaybe<Scalars['timestamptz']>;
  sa_received?: InputMaybe<Scalars['date']>;
  sa_sent_to_legal?: InputMaybe<Scalars['date']>;
  sale_price?: InputMaybe<Scalars['Int']>;
  settlement?: InputMaybe<Scalars['timestamptz']>;
  settlement_booked?: InputMaybe<Scalars['date']>;
  settlement_defect_status?: InputMaybe<Scalars['String']>;
  settlement_due_date?: InputMaybe<Scalars['date']>;
  settlement_extension_requested_date?: InputMaybe<Scalars['date']>;
  settlement_inspection?: InputMaybe<Scalars['timestamp']>;
  settlement_inspection_completed?: InputMaybe<Scalars['Boolean']>;
  settlement_keys_collected_date?: InputMaybe<Scalars['date']>;
  settlement_outlook?: InputMaybe<Scalars['String']>;
  settlement_story?: InputMaybe<Scalars['String']>;
  settlement_summary?: InputMaybe<Scalars['String']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  sf_enquiry_id?: InputMaybe<Scalars['String']>;
  sf_legal_id?: InputMaybe<Scalars['String']>;
  solicitor_notes?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Lead_Generation_Source_Enum>;
  status?: InputMaybe<Deal_Status_Enum>;
  sub_agency_contact_id?: InputMaybe<Scalars['uuid']>;
  sub_agency_id?: InputMaybe<Scalars['uuid']>;
  terms?: InputMaybe<Scalars['String']>;
  time_frame?: InputMaybe<Scalars['String']>;
  total_deposit_extension_date?: InputMaybe<Scalars['date']>;
  total_deposit_outstanding?: InputMaybe<Scalars['Int']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  total_deposit_selection?: InputMaybe<Scalars['String']>;
  transfer_receipt_time?: InputMaybe<Scalars['date']>;
  transfer_status?: InputMaybe<Deal_Transfer_Status_Enum>;
  transfer_to_vsol_requested?: InputMaybe<Scalars['Boolean']>;
  transferred_to_vsol?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Deal_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_id?: InputMaybe<Scalars['Boolean']>;
  uploaded_id_count?: InputMaybe<Scalars['Int']>;
  uploaded_receipt?: InputMaybe<Scalars['Boolean']>;
  vendor_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
  x66w_certificate_provided?: InputMaybe<Scalars['Boolean']>;
  x66w_received_by_vsol?: InputMaybe<Scalars['date']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deal_Sf_Action_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "deal_sf_action". All fields are combined with a logical 'AND'. */
export type Deal_Sf_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Sf_Action_Bool_Exp>>;
  _not?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Sf_Action_Bool_Exp>>;
  action?: InputMaybe<Salesforce_Action_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crm_connection?: InputMaybe<Crm_Connection_Bool_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Salesforce_Action_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deal_Sf_Action_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deal_Sf_Action_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deal_Sf_Action_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "deal_sf_action" */
export type Deal_Sf_Action_Insert_Input = {
  action?: InputMaybe<Salesforce_Action_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crm_connection?: InputMaybe<Crm_Connection_Obj_Rel_Insert_Input>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Salesforce_Action_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "deal_sf_action" */
export type Deal_Sf_Action_On_Conflict = {
  constraint: Deal_Sf_Action_Constraint;
  update_columns: Array<Deal_Sf_Action_Update_Column>;
  where?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_sf_action". */
export type Deal_Sf_Action_Order_By = {
  action?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crm_connection?: InputMaybe<Crm_Connection_Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_sf_action */
export type Deal_Sf_Action_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deal_Sf_Action_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "deal_sf_action" */
export type Deal_Sf_Action_Set_Input = {
  action?: InputMaybe<Salesforce_Action_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Salesforce_Action_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "deal_sf_action" */
export type Deal_Sf_Action_Stream_Cursor_Input = {
  initial_value: Deal_Sf_Action_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Sf_Action_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Salesforce_Action_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Salesforce_Action_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Deal_Sf_Action_Updates = {
  _append?: InputMaybe<Deal_Sf_Action_Append_Input>;
  _delete_at_path?: InputMaybe<Deal_Sf_Action_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Deal_Sf_Action_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Deal_Sf_Action_Delete_Key_Input>;
  _prepend?: InputMaybe<Deal_Sf_Action_Prepend_Input>;
  _set?: InputMaybe<Deal_Sf_Action_Set_Input>;
  where: Deal_Sf_Action_Bool_Exp;
};

export type Deal_Spoken_Language_Aggregate_Bool_Exp = {
  count?: InputMaybe<Deal_Spoken_Language_Aggregate_Bool_Exp_Count>;
};

export type Deal_Spoken_Language_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "deal_spoken_language" */
export type Deal_Spoken_Language_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deal_Spoken_Language_Max_Order_By>;
  min?: InputMaybe<Deal_Spoken_Language_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deal_spoken_language" */
export type Deal_Spoken_Language_Arr_Rel_Insert_Input = {
  data: Array<Deal_Spoken_Language_Insert_Input>;
  on_conflict?: InputMaybe<Deal_Spoken_Language_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deal_spoken_language". All fields are combined with a logical 'AND'. */
export type Deal_Spoken_Language_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Spoken_Language_Bool_Exp>>;
  _not?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Spoken_Language_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<Spoken_Language_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "deal_spoken_language" */
export type Deal_Spoken_Language_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Spoken_Language_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "deal_spoken_language" */
export type Deal_Spoken_Language_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "deal_spoken_language" */
export type Deal_Spoken_Language_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "deal_spoken_language" */
export type Deal_Spoken_Language_On_Conflict = {
  constraint: Deal_Spoken_Language_Constraint;
  update_columns: Array<Deal_Spoken_Language_Update_Column>;
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_spoken_language". */
export type Deal_Spoken_Language_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_spoken_language */
export type Deal_Spoken_Language_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "deal_spoken_language" */
export type Deal_Spoken_Language_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Spoken_Language_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "deal_spoken_language" */
export type Deal_Spoken_Language_Stream_Cursor_Input = {
  initial_value: Deal_Spoken_Language_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Spoken_Language_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Spoken_Language_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Deal_Spoken_Language_Updates = {
  _set?: InputMaybe<Deal_Spoken_Language_Set_Input>;
  where: Deal_Spoken_Language_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_status". All fields are combined with a logical 'AND'. */
export type Deal_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Status_Bool_Exp>>;
  _not?: InputMaybe<Deal_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Status_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_status_enum". All fields are combined with logical 'AND'. */
export type Deal_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Status_Enum>;
  _in?: InputMaybe<Array<Deal_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Status_Enum>;
  _nin?: InputMaybe<Array<Deal_Status_Enum>>;
};

/** input type for inserting data into table "deal_status" */
export type Deal_Status_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_status" */
export type Deal_Status_Obj_Rel_Insert_Input = {
  data: Deal_Status_Insert_Input;
  on_conflict?: InputMaybe<Deal_Status_On_Conflict>;
};

/** on_conflict condition type for table "deal_status" */
export type Deal_Status_On_Conflict = {
  constraint: Deal_Status_Constraint;
  update_columns: Array<Deal_Status_Update_Column>;
  where?: InputMaybe<Deal_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_status". */
export type Deal_Status_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_status */
export type Deal_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_status" */
export type Deal_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_status" */
export type Deal_Status_Stream_Cursor_Input = {
  initial_value: Deal_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Status_Updates = {
  _set?: InputMaybe<Deal_Status_Set_Input>;
  where: Deal_Status_Bool_Exp;
};

/** order by stddev() on columns of table "deal" */
export type Deal_Stddev_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "deal" */
export type Deal_Stddev_Pop_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "deal" */
export type Deal_Stddev_Samp_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "deal" */
export type Deal_Stream_Cursor_Input = {
  initial_value: Deal_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Stream_Cursor_Value_Input = {
  account_manager_id?: InputMaybe<Scalars['uuid']>;
  advised_contract_ready_to_progress?: InputMaybe<Scalars['date']>;
  agency_contact_id?: InputMaybe<Scalars['uuid']>;
  agency_id?: InputMaybe<Scalars['uuid']>;
  awaiting_enquiry_sf_id?: InputMaybe<Scalars['Boolean']>;
  awaiting_legal_sf_id?: InputMaybe<Scalars['Boolean']>;
  balance_due_by?: InputMaybe<Scalars['timestamptz']>;
  balance_of_deposit?: InputMaybe<Scalars['Int']>;
  bank_guarantee_expires?: InputMaybe<Scalars['date']>;
  bathrooms?: InputMaybe<Scalars['Int']>;
  bedrooms?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_max?: InputMaybe<Scalars['Int']>;
  bedrooms_pb_min?: InputMaybe<Scalars['Int']>;
  bedrooms_requested?: InputMaybe<Scalars['String']>;
  bond_expires?: InputMaybe<Scalars['date']>;
  bpay_biller_code?: InputMaybe<Scalars['String']>;
  bpay_crn?: InputMaybe<Scalars['String']>;
  building_id?: InputMaybe<Scalars['uuid']>;
  buying_capacity?: InputMaybe<Scalars['Int']>;
  cars?: InputMaybe<Scalars['Int']>;
  changes_requested?: InputMaybe<Scalars['Boolean']>;
  colour_scheme?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  comment_for_buyer?: InputMaybe<Scalars['String']>;
  commission_split?: InputMaybe<Scalars['Boolean']>;
  confirmed_firb_status?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Deal_Contact_Status_Enum>;
  contract_conditional?: InputMaybe<Scalars['date']>;
  contract_deposit_amount?: InputMaybe<Scalars['numeric']>;
  contract_exchanged?: InputMaybe<Scalars['timestamptz']>;
  contract_issued?: InputMaybe<Scalars['timestamptz']>;
  contract_progress_update?: InputMaybe<Deal_Progress_Update_Enum>;
  contract_returned_to_vendor?: InputMaybe<Scalars['date']>;
  contract_sent_to_purchaser?: InputMaybe<Scalars['date']>;
  contract_unconditional?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_ends?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_starts?: InputMaybe<Scalars['timestamptz']>;
  cooling_off_waived?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  deposit_due?: InputMaybe<Scalars['Int']>;
  deposit_received?: InputMaybe<Scalars['date']>;
  deposit_status?: InputMaybe<Deal_Deposit_Status_Enum>;
  deposit_type?: InputMaybe<Deal_Deposit_Type_Enum>;
  enquiry_source?: InputMaybe<Deal_Enquiry_Source_Enum>;
  enquiry_source_other?: InputMaybe<Scalars['String']>;
  envelope_opened_by_buyer?: InputMaybe<Scalars['timestamptz']>;
  envelope_owner?: InputMaybe<Scalars['String']>;
  eoi_approved_by_vendor?: InputMaybe<Scalars['timestamptz']>;
  fallen_date?: InputMaybe<Scalars['date']>;
  fallen_explanation?: InputMaybe<Scalars['String']>;
  fallen_reason?: InputMaybe<Deal_Fallen_Reason_Enum>;
  finance_status?: InputMaybe<Finance_Status_Enum>;
  firb_approved_vendor?: InputMaybe<Scalars['String']>;
  firb_confirmation_received?: InputMaybe<Scalars['date']>;
  firb_purchaser?: InputMaybe<Scalars['Boolean']>;
  forecast_exchange?: InputMaybe<Scalars['date']>;
  form_of_settlement?: InputMaybe<Scalars['String']>;
  holding_deposit_amount?: InputMaybe<Scalars['numeric']>;
  holding_deposit_amount_received?: InputMaybe<Scalars['numeric']>;
  holding_deposit_confirmed_by_vendor?: InputMaybe<Scalars['date']>;
  holding_deposit_number?: InputMaybe<Scalars['String']>;
  holding_deposit_paid_handled?: InputMaybe<Scalars['Boolean']>;
  holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  holding_deposit_received?: InputMaybe<Scalars['Boolean']>;
  holding_deposit_received_by_vsol?: InputMaybe<Scalars['date']>;
  how_did_you_hear_about_the_property?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_received?: InputMaybe<Scalars['Boolean']>;
  identity_doc1_number?: InputMaybe<Scalars['String']>;
  identity_doc1_region?: InputMaybe<Scalars['String']>;
  identity_doc1_type?: InputMaybe<Scalars['String']>;
  incl_in_deposit?: InputMaybe<Scalars['Boolean']>;
  last_sales_advice_generated_at?: InputMaybe<Scalars['timestamptz']>;
  lead_id?: InputMaybe<Scalars['uuid']>;
  lead_strength?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  name_on_contract?: InputMaybe<Scalars['String']>;
  on_hold?: InputMaybe<Scalars['timestamptz']>;
  on_hold_approved_by_client?: InputMaybe<Scalars['timestamptz']>;
  on_hold_approved_by_vendor?: InputMaybe<Scalars['timestamptz']>;
  on_hold_release?: InputMaybe<Scalars['date']>;
  paid_by?: InputMaybe<Scalars['String']>;
  parking_spaces?: InputMaybe<Scalars['Int']>;
  payable_on?: InputMaybe<Scalars['date']>;
  payment_method?: InputMaybe<Deal_Payment_Method_Enum>;
  pre_settlement_booked_by?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  proof_verified?: InputMaybe<Scalars['Boolean']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  purchase_proposal_sent?: InputMaybe<Scalars['Boolean']>;
  purchaser_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  purchaser_solicitor_id?: InputMaybe<Scalars['uuid']>;
  reason_to_purchase?: InputMaybe<Scalars['String']>;
  refund_account_bsb?: InputMaybe<Scalars['String']>;
  refund_account_name?: InputMaybe<Scalars['String']>;
  refund_account_number?: InputMaybe<Scalars['String']>;
  refund_requested?: InputMaybe<Scalars['date']>;
  refund_swift_code?: InputMaybe<Scalars['String']>;
  request_reference?: InputMaybe<Scalars['String']>;
  reservation_finalised?: InputMaybe<Scalars['Boolean']>;
  reserved_on?: InputMaybe<Scalars['timestamptz']>;
  sa_received?: InputMaybe<Scalars['date']>;
  sa_sent_to_legal?: InputMaybe<Scalars['date']>;
  sale_price?: InputMaybe<Scalars['Int']>;
  settlement?: InputMaybe<Scalars['timestamptz']>;
  settlement_booked?: InputMaybe<Scalars['date']>;
  settlement_defect_status?: InputMaybe<Scalars['String']>;
  settlement_due_date?: InputMaybe<Scalars['date']>;
  settlement_extension_requested_date?: InputMaybe<Scalars['date']>;
  settlement_inspection?: InputMaybe<Scalars['timestamp']>;
  settlement_inspection_completed?: InputMaybe<Scalars['Boolean']>;
  settlement_keys_collected_date?: InputMaybe<Scalars['date']>;
  settlement_outlook?: InputMaybe<Scalars['String']>;
  settlement_story?: InputMaybe<Scalars['String']>;
  settlement_summary?: InputMaybe<Scalars['String']>;
  sf_down_sync_time?: InputMaybe<Scalars['bigint']>;
  sf_enquiry_id?: InputMaybe<Scalars['String']>;
  sf_legal_id?: InputMaybe<Scalars['String']>;
  solicitor_notes?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Lead_Generation_Source_Enum>;
  status?: InputMaybe<Deal_Status_Enum>;
  sub_agency_contact_id?: InputMaybe<Scalars['uuid']>;
  sub_agency_id?: InputMaybe<Scalars['uuid']>;
  terms?: InputMaybe<Scalars['String']>;
  time_frame?: InputMaybe<Scalars['String']>;
  total_deposit_extension_date?: InputMaybe<Scalars['date']>;
  total_deposit_outstanding?: InputMaybe<Scalars['Int']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  total_deposit_selection?: InputMaybe<Scalars['String']>;
  transfer_receipt_time?: InputMaybe<Scalars['date']>;
  transfer_status?: InputMaybe<Deal_Transfer_Status_Enum>;
  transfer_to_vsol_requested?: InputMaybe<Scalars['Boolean']>;
  transferred_to_vsol?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Deal_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_id?: InputMaybe<Scalars['Boolean']>;
  uploaded_id_count?: InputMaybe<Scalars['Int']>;
  uploaded_receipt?: InputMaybe<Scalars['Boolean']>;
  vendor_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
  x66w_certificate_provided?: InputMaybe<Scalars['Boolean']>;
  x66w_received_by_vsol?: InputMaybe<Scalars['date']>;
};

/** order by sum() on columns of table "deal" */
export type Deal_Sum_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal_transfer_status". All fields are combined with a logical 'AND'. */
export type Deal_Transfer_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Transfer_Status_Bool_Exp>>;
  _not?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Transfer_Status_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_transfer_status_enum". All fields are combined with logical 'AND'. */
export type Deal_Transfer_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Transfer_Status_Enum>;
  _in?: InputMaybe<Array<Deal_Transfer_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Transfer_Status_Enum>;
  _nin?: InputMaybe<Array<Deal_Transfer_Status_Enum>>;
};

/** input type for inserting data into table "deal_transfer_status" */
export type Deal_Transfer_Status_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_transfer_status" */
export type Deal_Transfer_Status_Obj_Rel_Insert_Input = {
  data: Deal_Transfer_Status_Insert_Input;
  on_conflict?: InputMaybe<Deal_Transfer_Status_On_Conflict>;
};

/** on_conflict condition type for table "deal_transfer_status" */
export type Deal_Transfer_Status_On_Conflict = {
  constraint: Deal_Transfer_Status_Constraint;
  update_columns: Array<Deal_Transfer_Status_Update_Column>;
  where?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_transfer_status". */
export type Deal_Transfer_Status_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_transfer_status */
export type Deal_Transfer_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_transfer_status" */
export type Deal_Transfer_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_transfer_status" */
export type Deal_Transfer_Status_Stream_Cursor_Input = {
  initial_value: Deal_Transfer_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Transfer_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Transfer_Status_Updates = {
  _set?: InputMaybe<Deal_Transfer_Status_Set_Input>;
  where: Deal_Transfer_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_type". All fields are combined with a logical 'AND'. */
export type Deal_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Type_Bool_Exp>>;
  _not?: InputMaybe<Deal_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Type_Bool_Exp>>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "deal_type_enum". All fields are combined with logical 'AND'. */
export type Deal_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Deal_Type_Enum>;
  _in?: InputMaybe<Array<Deal_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Deal_Type_Enum>;
  _nin?: InputMaybe<Array<Deal_Type_Enum>>;
};

/** input type for inserting data into table "deal_type" */
export type Deal_Type_Insert_Input = {
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "deal_type" */
export type Deal_Type_Obj_Rel_Insert_Input = {
  data: Deal_Type_Insert_Input;
  on_conflict?: InputMaybe<Deal_Type_On_Conflict>;
};

/** on_conflict condition type for table "deal_type" */
export type Deal_Type_On_Conflict = {
  constraint: Deal_Type_Constraint;
  update_columns: Array<Deal_Type_Update_Column>;
  where?: InputMaybe<Deal_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_type". */
export type Deal_Type_Order_By = {
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_type */
export type Deal_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "deal_type" */
export type Deal_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "deal_type" */
export type Deal_Type_Stream_Cursor_Input = {
  initial_value: Deal_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Deal_Type_Updates = {
  _set?: InputMaybe<Deal_Type_Set_Input>;
  where: Deal_Type_Bool_Exp;
};

export type Deal_Updates = {
  _inc?: InputMaybe<Deal_Inc_Input>;
  _set?: InputMaybe<Deal_Set_Input>;
  where: Deal_Bool_Exp;
};

/** Boolean expression to filter rows from the table "deal_valuation_booking". All fields are combined with a logical 'AND'. */
export type Deal_Valuation_Booking_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Valuation_Booking_Bool_Exp>>;
  _not?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Valuation_Booking_Bool_Exp>>;
  bank_requesting_valuation?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hubspot?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
  hubspot_aggregate?: InputMaybe<Hubspot_Valuation_Booking_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sync_to_hubspot?: InputMaybe<Boolean_Comparison_Exp>;
  sync_to_salesforce?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valuation_date?: InputMaybe<Timestamp_Comparison_Exp>;
  valuation_result?: InputMaybe<Numeric_Comparison_Exp>;
  valuer_contact?: InputMaybe<Contact_Bool_Exp>;
  valuer_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "deal_valuation_booking" */
export type Deal_Valuation_Booking_Inc_Input = {
  valuation_result?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "deal_valuation_booking" */
export type Deal_Valuation_Booking_Insert_Input = {
  bank_requesting_valuation?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hubspot?: InputMaybe<Hubspot_Valuation_Booking_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  sync_to_hubspot?: InputMaybe<Scalars['Boolean']>;
  sync_to_salesforce?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valuation_date?: InputMaybe<Scalars['timestamp']>;
  valuation_result?: InputMaybe<Scalars['numeric']>;
  valuer_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  valuer_contact_id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "deal_valuation_booking" */
export type Deal_Valuation_Booking_On_Conflict = {
  constraint: Deal_Valuation_Booking_Constraint;
  update_columns: Array<Deal_Valuation_Booking_Update_Column>;
  where?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_valuation_booking". */
export type Deal_Valuation_Booking_Order_By = {
  bank_requesting_valuation?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hubspot_aggregate?: InputMaybe<Hubspot_Valuation_Booking_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  sync_to_hubspot?: InputMaybe<Order_By>;
  sync_to_salesforce?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valuation_date?: InputMaybe<Order_By>;
  valuation_result?: InputMaybe<Order_By>;
  valuer_contact?: InputMaybe<Contact_Order_By>;
  valuer_contact_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_valuation_booking */
export type Deal_Valuation_Booking_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "deal_valuation_booking" */
export type Deal_Valuation_Booking_Set_Input = {
  bank_requesting_valuation?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  hs_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sync_to_hubspot?: InputMaybe<Scalars['Boolean']>;
  sync_to_salesforce?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valuation_date?: InputMaybe<Scalars['timestamp']>;
  valuation_result?: InputMaybe<Scalars['numeric']>;
  valuer_contact_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deal_valuation_booking" */
export type Deal_Valuation_Booking_Stream_Cursor_Input = {
  initial_value: Deal_Valuation_Booking_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Valuation_Booking_Stream_Cursor_Value_Input = {
  bank_requesting_valuation?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  hs_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sync_to_hubspot?: InputMaybe<Scalars['Boolean']>;
  sync_to_salesforce?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valuation_date?: InputMaybe<Scalars['timestamp']>;
  valuation_result?: InputMaybe<Scalars['numeric']>;
  valuer_contact_id?: InputMaybe<Scalars['uuid']>;
};

export type Deal_Valuation_Booking_Updates = {
  _inc?: InputMaybe<Deal_Valuation_Booking_Inc_Input>;
  _set?: InputMaybe<Deal_Valuation_Booking_Set_Input>;
  where: Deal_Valuation_Booking_Bool_Exp;
};

/** order by var_pop() on columns of table "deal" */
export type Deal_Var_Pop_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "deal" */
export type Deal_Var_Samp_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "deal" */
export type Deal_Variance_Order_By = {
  balance_of_deposit?: InputMaybe<Order_By>;
  bathrooms?: InputMaybe<Order_By>;
  bedrooms?: InputMaybe<Order_By>;
  bedrooms_pb_max?: InputMaybe<Order_By>;
  bedrooms_pb_min?: InputMaybe<Order_By>;
  buying_capacity?: InputMaybe<Order_By>;
  cars?: InputMaybe<Order_By>;
  contract_deposit_amount?: InputMaybe<Order_By>;
  deposit_due?: InputMaybe<Order_By>;
  holding_deposit_amount?: InputMaybe<Order_By>;
  holding_deposit_amount_received?: InputMaybe<Order_By>;
  holding_deposit_percentage?: InputMaybe<Order_By>;
  parking_spaces?: InputMaybe<Order_By>;
  sale_price?: InputMaybe<Order_By>;
  sf_down_sync_time?: InputMaybe<Order_By>;
  total_deposit_outstanding?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  uploaded_id_count?: InputMaybe<Order_By>;
};

export type Deal_Witness_Aggregate_Bool_Exp = {
  count?: InputMaybe<Deal_Witness_Aggregate_Bool_Exp_Count>;
};

export type Deal_Witness_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Witness_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "deal_witness" */
export type Deal_Witness_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deal_Witness_Max_Order_By>;
  min?: InputMaybe<Deal_Witness_Min_Order_By>;
};

/** input type for inserting array relation for remote table "deal_witness" */
export type Deal_Witness_Arr_Rel_Insert_Input = {
  data: Array<Deal_Witness_Insert_Input>;
  on_conflict?: InputMaybe<Deal_Witness_On_Conflict>;
};

/** Boolean expression to filter rows from the table "deal_witness". All fields are combined with a logical 'AND'. */
export type Deal_Witness_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Witness_Bool_Exp>>;
  _not?: InputMaybe<Deal_Witness_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Witness_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "deal_witness" */
export type Deal_Witness_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "deal_witness" */
export type Deal_Witness_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "deal_witness" */
export type Deal_Witness_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "deal_witness" */
export type Deal_Witness_On_Conflict = {
  constraint: Deal_Witness_Constraint;
  update_columns: Array<Deal_Witness_Update_Column>;
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

/** Ordering options when selecting data from "deal_witness". */
export type Deal_Witness_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deal_witness */
export type Deal_Witness_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "deal_witness" */
export type Deal_Witness_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "deal_witness" */
export type Deal_Witness_Stream_Cursor_Input = {
  initial_value: Deal_Witness_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Witness_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Deal_Witness_Updates = {
  _set?: InputMaybe<Deal_Witness_Set_Input>;
  where: Deal_Witness_Bool_Exp;
};

export type Developer_Agent_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Developer_Agent_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Developer_Agent_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Developer_Agent_Aggregate_Bool_Exp_Count>;
};

export type Developer_Agent_Aggregate_Bool_Exp_Bool_And = {
  arguments: Developer_Agent_Select_Column_Developer_Agent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Developer_Agent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Developer_Agent_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Developer_Agent_Select_Column_Developer_Agent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Developer_Agent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Developer_Agent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Developer_Agent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "developer_agent" */
export type Developer_Agent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Developer_Agent_Max_Order_By>;
  min?: InputMaybe<Developer_Agent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "developer_agent" */
export type Developer_Agent_Arr_Rel_Insert_Input = {
  data: Array<Developer_Agent_Insert_Input>;
  on_conflict?: InputMaybe<Developer_Agent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "developer_agent". All fields are combined with a logical 'AND'. */
export type Developer_Agent_Bool_Exp = {
  _and?: InputMaybe<Array<Developer_Agent_Bool_Exp>>;
  _not?: InputMaybe<Developer_Agent_Bool_Exp>;
  _or?: InputMaybe<Array<Developer_Agent_Bool_Exp>>;
  access_active?: InputMaybe<Boolean_Comparison_Exp>;
  agent?: InputMaybe<Organisation_Bool_Exp>;
  agent_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_salesforce?: InputMaybe<Boolean_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  developer?: InputMaybe<Organisation_Bool_Exp>;
  developer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "developer_agent" */
export type Developer_Agent_Insert_Input = {
  access_active?: InputMaybe<Scalars['Boolean']>;
  agent?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  agent_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_salesforce?: InputMaybe<Scalars['Boolean']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  developer?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  developer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "developer_agent" */
export type Developer_Agent_Max_Order_By = {
  agent_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  developer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "developer_agent" */
export type Developer_Agent_Min_Order_By = {
  agent_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  developer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "developer_agent" */
export type Developer_Agent_On_Conflict = {
  constraint: Developer_Agent_Constraint;
  update_columns: Array<Developer_Agent_Update_Column>;
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

/** Ordering options when selecting data from "developer_agent". */
export type Developer_Agent_Order_By = {
  access_active?: InputMaybe<Order_By>;
  agent?: InputMaybe<Organisation_Order_By>;
  agent_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_salesforce?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  developer?: InputMaybe<Organisation_Order_By>;
  developer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: developer_agent */
export type Developer_Agent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "developer_agent" */
export type Developer_Agent_Set_Input = {
  access_active?: InputMaybe<Scalars['Boolean']>;
  agent_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_salesforce?: InputMaybe<Scalars['Boolean']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  developer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "developer_agent" */
export type Developer_Agent_Stream_Cursor_Input = {
  initial_value: Developer_Agent_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Developer_Agent_Stream_Cursor_Value_Input = {
  access_active?: InputMaybe<Scalars['Boolean']>;
  agent_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_salesforce?: InputMaybe<Scalars['Boolean']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  developer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Developer_Agent_Updates = {
  _set?: InputMaybe<Developer_Agent_Set_Input>;
  where: Developer_Agent_Bool_Exp;
};

export type Document_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Document_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Document_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Document_Aggregate_Bool_Exp_Count>;
};

export type Document_Aggregate_Bool_Exp_Bool_And = {
  arguments: Document_Select_Column_Document_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Document_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Document_Select_Column_Document_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "document" */
export type Document_Aggregate_Order_By = {
  avg?: InputMaybe<Document_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Max_Order_By>;
  min?: InputMaybe<Document_Min_Order_By>;
  stddev?: InputMaybe<Document_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Document_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Document_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Document_Sum_Order_By>;
  var_pop?: InputMaybe<Document_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Document_Var_Samp_Order_By>;
  variance?: InputMaybe<Document_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Document_Append_Input = {
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "document" */
export type Document_Arr_Rel_Insert_Input = {
  data: Array<Document_Insert_Input>;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

/** order by avg() on columns of table "document" */
export type Document_Avg_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document". All fields are combined with a logical 'AND'. */
export type Document_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Bool_Exp>>;
  _not?: InputMaybe<Document_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Bool_Exp>>;
  acl_contact_agent_member?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
  acl_contact_agent_member_aggregate?: InputMaybe<Acl_Contact_Agent_Member_Aggregate_Bool_Exp>;
  acl_contact_developer_member?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
  acl_contact_developer_member_aggregate?: InputMaybe<Acl_Contact_Developer_Member_Aggregate_Bool_Exp>;
  attachment?: InputMaybe<Attachment_Bool_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  birth_date?: InputMaybe<Date_Comparison_Exp>;
  card_expiry?: InputMaybe<String_Comparison_Exp>;
  card_number?: InputMaybe<String_Comparison_Exp>;
  card_type?: InputMaybe<String_Comparison_Exp>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  expiry_date?: InputMaybe<Date_Comparison_Exp>;
  family_name?: InputMaybe<String_Comparison_Exp>;
  full_name1?: InputMaybe<String_Comparison_Exp>;
  full_name2?: InputMaybe<String_Comparison_Exp>;
  full_name3?: InputMaybe<String_Comparison_Exp>;
  full_name4?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  given_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  individual_reference_number?: InputMaybe<Int_Comparison_Exp>;
  is_valid?: InputMaybe<Boolean_Comparison_Exp>;
  licence_attempt?: InputMaybe<Int_Comparison_Exp>;
  licence_number?: InputMaybe<String_Comparison_Exp>;
  medicare_attempt?: InputMaybe<Int_Comparison_Exp>;
  middle_name?: InputMaybe<String_Comparison_Exp>;
  passport_attempt?: InputMaybe<Int_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  state_of_issue?: InputMaybe<String_Comparison_Exp>;
  travel_document_number?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Document_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  verification_request_number?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Document_Delete_At_Path_Input = {
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Document_Delete_Elem_Input = {
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Document_Delete_Key_Input = {
  response?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "document_expired". All fields are combined with a logical 'AND'. */
export type Document_Expired_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Expired_Bool_Exp>>;
  _not?: InputMaybe<Document_Expired_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Expired_Bool_Exp>>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiry_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "document_expired". */
export type Document_Expired_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "document_expired" */
export type Document_Expired_Stream_Cursor_Input = {
  initial_value: Document_Expired_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Expired_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  expiry_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for incrementing numeric columns in table "document" */
export type Document_Inc_Input = {
  individual_reference_number?: InputMaybe<Scalars['Int']>;
  licence_attempt?: InputMaybe<Scalars['Int']>;
  medicare_attempt?: InputMaybe<Scalars['Int']>;
  passport_attempt?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "document" */
export type Document_Insert_Input = {
  acl_contact_agent_member?: InputMaybe<Acl_Contact_Agent_Member_Arr_Rel_Insert_Input>;
  acl_contact_developer_member?: InputMaybe<Acl_Contact_Developer_Member_Arr_Rel_Insert_Input>;
  attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  birth_date?: InputMaybe<Scalars['date']>;
  card_expiry?: InputMaybe<Scalars['String']>;
  card_number?: InputMaybe<Scalars['String']>;
  card_type?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  expiry_date?: InputMaybe<Scalars['date']>;
  family_name?: InputMaybe<Scalars['String']>;
  full_name1?: InputMaybe<Scalars['String']>;
  full_name2?: InputMaybe<Scalars['String']>;
  full_name3?: InputMaybe<Scalars['String']>;
  full_name4?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  individual_reference_number?: InputMaybe<Scalars['Int']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  licence_attempt?: InputMaybe<Scalars['Int']>;
  licence_number?: InputMaybe<Scalars['String']>;
  medicare_attempt?: InputMaybe<Scalars['Int']>;
  middle_name?: InputMaybe<Scalars['String']>;
  passport_attempt?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['jsonb']>;
  state_of_issue?: InputMaybe<Scalars['String']>;
  travel_document_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Document_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verification_request_number?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "document" */
export type Document_Max_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  card_expiry?: InputMaybe<Order_By>;
  card_number?: InputMaybe<Order_By>;
  card_type?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  family_name?: InputMaybe<Order_By>;
  full_name1?: InputMaybe<Order_By>;
  full_name2?: InputMaybe<Order_By>;
  full_name3?: InputMaybe<Order_By>;
  full_name4?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
  state_of_issue?: InputMaybe<Order_By>;
  travel_document_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verification_request_number?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "document" */
export type Document_Min_Order_By = {
  attachment_id?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  card_expiry?: InputMaybe<Order_By>;
  card_number?: InputMaybe<Order_By>;
  card_type?: InputMaybe<Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  family_name?: InputMaybe<Order_By>;
  full_name1?: InputMaybe<Order_By>;
  full_name2?: InputMaybe<Order_By>;
  full_name3?: InputMaybe<Order_By>;
  full_name4?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
  state_of_issue?: InputMaybe<Order_By>;
  travel_document_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verification_request_number?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "document" */
export type Document_On_Conflict = {
  constraint: Document_Constraint;
  update_columns: Array<Document_Update_Column>;
  where?: InputMaybe<Document_Bool_Exp>;
};

/** Ordering options when selecting data from "document". */
export type Document_Order_By = {
  acl_contact_agent_member_aggregate?: InputMaybe<Acl_Contact_Agent_Member_Aggregate_Order_By>;
  acl_contact_developer_member_aggregate?: InputMaybe<Acl_Contact_Developer_Member_Aggregate_Order_By>;
  attachment?: InputMaybe<Attachment_Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  card_expiry?: InputMaybe<Order_By>;
  card_number?: InputMaybe<Order_By>;
  card_type?: InputMaybe<Order_By>;
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  family_name?: InputMaybe<Order_By>;
  full_name1?: InputMaybe<Order_By>;
  full_name2?: InputMaybe<Order_By>;
  full_name3?: InputMaybe<Order_By>;
  full_name4?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  individual_reference_number?: InputMaybe<Order_By>;
  is_valid?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  state_of_issue?: InputMaybe<Order_By>;
  travel_document_number?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verification_request_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document */
export type Document_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Document_Prepend_Input = {
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "document" */
export type Document_Set_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  birth_date?: InputMaybe<Scalars['date']>;
  card_expiry?: InputMaybe<Scalars['String']>;
  card_number?: InputMaybe<Scalars['String']>;
  card_type?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  expiry_date?: InputMaybe<Scalars['date']>;
  family_name?: InputMaybe<Scalars['String']>;
  full_name1?: InputMaybe<Scalars['String']>;
  full_name2?: InputMaybe<Scalars['String']>;
  full_name3?: InputMaybe<Scalars['String']>;
  full_name4?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  individual_reference_number?: InputMaybe<Scalars['Int']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  licence_attempt?: InputMaybe<Scalars['Int']>;
  licence_number?: InputMaybe<Scalars['String']>;
  medicare_attempt?: InputMaybe<Scalars['Int']>;
  middle_name?: InputMaybe<Scalars['String']>;
  passport_attempt?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['jsonb']>;
  state_of_issue?: InputMaybe<Scalars['String']>;
  travel_document_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Document_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verification_request_number?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "document" */
export type Document_Stddev_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "document" */
export type Document_Stddev_Pop_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "document" */
export type Document_Stddev_Samp_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "document" */
export type Document_Stream_Cursor_Input = {
  initial_value: Document_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Stream_Cursor_Value_Input = {
  attachment_id?: InputMaybe<Scalars['uuid']>;
  birth_date?: InputMaybe<Scalars['date']>;
  card_expiry?: InputMaybe<Scalars['String']>;
  card_number?: InputMaybe<Scalars['String']>;
  card_type?: InputMaybe<Scalars['String']>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  expiry_date?: InputMaybe<Scalars['date']>;
  family_name?: InputMaybe<Scalars['String']>;
  full_name1?: InputMaybe<Scalars['String']>;
  full_name2?: InputMaybe<Scalars['String']>;
  full_name3?: InputMaybe<Scalars['String']>;
  full_name4?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  individual_reference_number?: InputMaybe<Scalars['Int']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  licence_attempt?: InputMaybe<Scalars['Int']>;
  licence_number?: InputMaybe<Scalars['String']>;
  medicare_attempt?: InputMaybe<Scalars['Int']>;
  middle_name?: InputMaybe<Scalars['String']>;
  passport_attempt?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['jsonb']>;
  state_of_issue?: InputMaybe<Scalars['String']>;
  travel_document_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Document_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verification_request_number?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "document" */
export type Document_Sum_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document_type". All fields are combined with a logical 'AND'. */
export type Document_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Type_Bool_Exp>>;
  _not?: InputMaybe<Document_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "document_type_enum". All fields are combined with logical 'AND'. */
export type Document_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_Type_Enum>;
  _in?: InputMaybe<Array<Document_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Document_Type_Enum>;
  _nin?: InputMaybe<Array<Document_Type_Enum>>;
};

/** input type for inserting data into table "document_type" */
export type Document_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "document_type" */
export type Document_Type_On_Conflict = {
  constraint: Document_Type_Constraint;
  update_columns: Array<Document_Type_Update_Column>;
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "document_type". */
export type Document_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_type */
export type Document_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "document_type" */
export type Document_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "document_type" */
export type Document_Type_Stream_Cursor_Input = {
  initial_value: Document_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Document_Type_Updates = {
  _set?: InputMaybe<Document_Type_Set_Input>;
  where: Document_Type_Bool_Exp;
};

export type Document_Updates = {
  _append?: InputMaybe<Document_Append_Input>;
  _delete_at_path?: InputMaybe<Document_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Document_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Document_Delete_Key_Input>;
  _inc?: InputMaybe<Document_Inc_Input>;
  _prepend?: InputMaybe<Document_Prepend_Input>;
  _set?: InputMaybe<Document_Set_Input>;
  where: Document_Bool_Exp;
};

/** order by var_pop() on columns of table "document" */
export type Document_Var_Pop_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "document" */
export type Document_Var_Samp_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "document" */
export type Document_Variance_Order_By = {
  individual_reference_number?: InputMaybe<Order_By>;
  licence_attempt?: InputMaybe<Order_By>;
  medicare_attempt?: InputMaybe<Order_By>;
  passport_attempt?: InputMaybe<Order_By>;
};

export type Embed_Aggregate_Bool_Exp = {
  count?: InputMaybe<Embed_Aggregate_Bool_Exp_Count>;
};

export type Embed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Embed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Embed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "embed" */
export type Embed_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Embed_Max_Order_By>;
  min?: InputMaybe<Embed_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Embed_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "embed" */
export type Embed_Arr_Rel_Insert_Input = {
  data: Array<Embed_Insert_Input>;
  on_conflict?: InputMaybe<Embed_On_Conflict>;
};

/** Boolean expression to filter rows from the table "embed". All fields are combined with a logical 'AND'. */
export type Embed_Bool_Exp = {
  _and?: InputMaybe<Array<Embed_Bool_Exp>>;
  _not?: InputMaybe<Embed_Bool_Exp>;
  _or?: InputMaybe<Array<Embed_Bool_Exp>>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team?: InputMaybe<Organisation_Bool_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user?: InputMaybe<User_Bool_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  embed_user?: InputMaybe<User_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Embed_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Embed_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Embed_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "embed" */
export type Embed_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  embed_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "embed" */
export type Embed_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "embed" */
export type Embed_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "embed" */
export type Embed_Obj_Rel_Insert_Input = {
  data: Embed_Insert_Input;
  on_conflict?: InputMaybe<Embed_On_Conflict>;
};

/** on_conflict condition type for table "embed" */
export type Embed_On_Conflict = {
  constraint: Embed_Constraint;
  update_columns: Array<Embed_Update_Column>;
  where?: InputMaybe<Embed_Bool_Exp>;
};

/** Ordering options when selecting data from "embed". */
export type Embed_Order_By = {
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team?: InputMaybe<Organisation_Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user?: InputMaybe<User_Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  embed_user?: InputMaybe<User_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: embed */
export type Embed_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Embed_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "embed" */
export type Embed_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "embed" */
export type Embed_Stream_Cursor_Input = {
  initial_value: Embed_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Embed_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Embed_Updates = {
  _append?: InputMaybe<Embed_Append_Input>;
  _delete_at_path?: InputMaybe<Embed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Embed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Embed_Delete_Key_Input>;
  _prepend?: InputMaybe<Embed_Prepend_Input>;
  _set?: InputMaybe<Embed_Set_Input>;
  where: Embed_Bool_Exp;
};

export type Envelope_Aggregate_Bool_Exp = {
  count?: InputMaybe<Envelope_Aggregate_Bool_Exp_Count>;
};

export type Envelope_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Envelope_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Envelope_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "envelope" */
export type Envelope_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Envelope_Max_Order_By>;
  min?: InputMaybe<Envelope_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Envelope_Append_Input = {
  envelope_request?: InputMaybe<Scalars['jsonb']>;
  envelope_response?: InputMaybe<Scalars['jsonb']>;
  recipient_status?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "envelope" */
export type Envelope_Arr_Rel_Insert_Input = {
  data: Array<Envelope_Insert_Input>;
  on_conflict?: InputMaybe<Envelope_On_Conflict>;
};

/** Boolean expression to filter rows from the table "envelope". All fields are combined with a logical 'AND'. */
export type Envelope_Bool_Exp = {
  _and?: InputMaybe<Array<Envelope_Bool_Exp>>;
  _not?: InputMaybe<Envelope_Bool_Exp>;
  _or?: InputMaybe<Array<Envelope_Bool_Exp>>;
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Bool_Exp>;
  agency_agreement?: InputMaybe<Agency_Agreement_Bool_Exp>;
  completed_redirect_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  declined_redirect_url?: InputMaybe<String_Comparison_Exp>;
  envelope_id?: InputMaybe<String_Comparison_Exp>;
  envelope_request?: InputMaybe<Jsonb_Comparison_Exp>;
  envelope_response?: InputMaybe<Jsonb_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  issuer_organisation?: InputMaybe<Organisation_Bool_Exp>;
  owner?: InputMaybe<Envelope_Owner_Enum_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  recipient_status?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Envelope_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Envelope_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  void_reason?: InputMaybe<String_Comparison_Exp>;
  voided_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  voided_by_user?: InputMaybe<User_Bool_Exp>;
  voided_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Envelope_Delete_At_Path_Input = {
  envelope_request?: InputMaybe<Array<Scalars['String']>>;
  envelope_response?: InputMaybe<Array<Scalars['String']>>;
  recipient_status?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Envelope_Delete_Elem_Input = {
  envelope_request?: InputMaybe<Scalars['Int']>;
  envelope_response?: InputMaybe<Scalars['Int']>;
  recipient_status?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Envelope_Delete_Key_Input = {
  envelope_request?: InputMaybe<Scalars['String']>;
  envelope_response?: InputMaybe<Scalars['String']>;
  recipient_status?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "envelope" */
export type Envelope_Insert_Input = {
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Arr_Rel_Insert_Input>;
  agency_agreement?: InputMaybe<Agency_Agreement_Obj_Rel_Insert_Input>;
  completed_redirect_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  declined_redirect_url?: InputMaybe<Scalars['String']>;
  envelope_id?: InputMaybe<Scalars['String']>;
  envelope_request?: InputMaybe<Scalars['jsonb']>;
  envelope_response?: InputMaybe<Scalars['jsonb']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  issuer_organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  owner?: InputMaybe<Envelope_Owner_Enum>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  recipient_status?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Envelope_Status_Enum>;
  type?: InputMaybe<Envelope_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided_by_team_id?: InputMaybe<Scalars['uuid']>;
  voided_by_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  voided_by_user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "envelope" */
export type Envelope_Max_Order_By = {
  completed_redirect_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  declined_redirect_url?: InputMaybe<Order_By>;
  envelope_id?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
  voided_by_team_id?: InputMaybe<Order_By>;
  voided_by_user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "envelope" */
export type Envelope_Min_Order_By = {
  completed_redirect_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  declined_redirect_url?: InputMaybe<Order_By>;
  envelope_id?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
  voided_by_team_id?: InputMaybe<Order_By>;
  voided_by_user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "envelope" */
export type Envelope_On_Conflict = {
  constraint: Envelope_Constraint;
  update_columns: Array<Envelope_Update_Column>;
  where?: InputMaybe<Envelope_Bool_Exp>;
};

/** Ordering options when selecting data from "envelope". */
export type Envelope_Order_By = {
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Order_By>;
  agency_agreement?: InputMaybe<Agency_Agreement_Order_By>;
  completed_redirect_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  declined_redirect_url?: InputMaybe<Order_By>;
  envelope_id?: InputMaybe<Order_By>;
  envelope_request?: InputMaybe<Order_By>;
  envelope_response?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issuer_organisation?: InputMaybe<Organisation_Order_By>;
  owner?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  recipient_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
  voided_by_team_id?: InputMaybe<Order_By>;
  voided_by_user?: InputMaybe<User_Order_By>;
  voided_by_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "envelope_owner". All fields are combined with a logical 'AND'. */
export type Envelope_Owner_Bool_Exp = {
  _and?: InputMaybe<Array<Envelope_Owner_Bool_Exp>>;
  _not?: InputMaybe<Envelope_Owner_Bool_Exp>;
  _or?: InputMaybe<Array<Envelope_Owner_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "envelope_owner_enum". All fields are combined with logical 'AND'. */
export type Envelope_Owner_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Envelope_Owner_Enum>;
  _in?: InputMaybe<Array<Envelope_Owner_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Envelope_Owner_Enum>;
  _nin?: InputMaybe<Array<Envelope_Owner_Enum>>;
};

/** input type for inserting data into table "envelope_owner" */
export type Envelope_Owner_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "envelope_owner" */
export type Envelope_Owner_On_Conflict = {
  constraint: Envelope_Owner_Constraint;
  update_columns: Array<Envelope_Owner_Update_Column>;
  where?: InputMaybe<Envelope_Owner_Bool_Exp>;
};

/** Ordering options when selecting data from "envelope_owner". */
export type Envelope_Owner_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: envelope_owner */
export type Envelope_Owner_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "envelope_owner" */
export type Envelope_Owner_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "envelope_owner" */
export type Envelope_Owner_Stream_Cursor_Input = {
  initial_value: Envelope_Owner_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Envelope_Owner_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Envelope_Owner_Updates = {
  _set?: InputMaybe<Envelope_Owner_Set_Input>;
  where: Envelope_Owner_Bool_Exp;
};

/** primary key columns input for table: envelope */
export type Envelope_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Envelope_Prepend_Input = {
  envelope_request?: InputMaybe<Scalars['jsonb']>;
  envelope_response?: InputMaybe<Scalars['jsonb']>;
  recipient_status?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "envelope" */
export type Envelope_Set_Input = {
  completed_redirect_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  declined_redirect_url?: InputMaybe<Scalars['String']>;
  envelope_id?: InputMaybe<Scalars['String']>;
  envelope_request?: InputMaybe<Scalars['jsonb']>;
  envelope_response?: InputMaybe<Scalars['jsonb']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner?: InputMaybe<Envelope_Owner_Enum>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  recipient_status?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Envelope_Status_Enum>;
  type?: InputMaybe<Envelope_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided_by_team_id?: InputMaybe<Scalars['uuid']>;
  voided_by_user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "envelope_status". All fields are combined with a logical 'AND'. */
export type Envelope_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Envelope_Status_Bool_Exp>>;
  _not?: InputMaybe<Envelope_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Envelope_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "envelope_status_enum". All fields are combined with logical 'AND'. */
export type Envelope_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Envelope_Status_Enum>;
  _in?: InputMaybe<Array<Envelope_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Envelope_Status_Enum>;
  _nin?: InputMaybe<Array<Envelope_Status_Enum>>;
};

/** input type for inserting data into table "envelope_status" */
export type Envelope_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "envelope_status" */
export type Envelope_Status_On_Conflict = {
  constraint: Envelope_Status_Constraint;
  update_columns: Array<Envelope_Status_Update_Column>;
  where?: InputMaybe<Envelope_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "envelope_status". */
export type Envelope_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: envelope_status */
export type Envelope_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "envelope_status" */
export type Envelope_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "envelope_status" */
export type Envelope_Status_Stream_Cursor_Input = {
  initial_value: Envelope_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Envelope_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Envelope_Status_Updates = {
  _set?: InputMaybe<Envelope_Status_Set_Input>;
  where: Envelope_Status_Bool_Exp;
};

/** Streaming cursor of the table "envelope" */
export type Envelope_Stream_Cursor_Input = {
  initial_value: Envelope_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Envelope_Stream_Cursor_Value_Input = {
  completed_redirect_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  declined_redirect_url?: InputMaybe<Scalars['String']>;
  envelope_id?: InputMaybe<Scalars['String']>;
  envelope_request?: InputMaybe<Scalars['jsonb']>;
  envelope_response?: InputMaybe<Scalars['jsonb']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner?: InputMaybe<Envelope_Owner_Enum>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  recipient_status?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Envelope_Status_Enum>;
  type?: InputMaybe<Envelope_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided_by_team_id?: InputMaybe<Scalars['uuid']>;
  voided_by_user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "envelope_type". All fields are combined with a logical 'AND'. */
export type Envelope_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Envelope_Type_Bool_Exp>>;
  _not?: InputMaybe<Envelope_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Envelope_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "envelope_type_enum". All fields are combined with logical 'AND'. */
export type Envelope_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Envelope_Type_Enum>;
  _in?: InputMaybe<Array<Envelope_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Envelope_Type_Enum>;
  _nin?: InputMaybe<Array<Envelope_Type_Enum>>;
};

/** input type for inserting data into table "envelope_type" */
export type Envelope_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "envelope_type" */
export type Envelope_Type_On_Conflict = {
  constraint: Envelope_Type_Constraint;
  update_columns: Array<Envelope_Type_Update_Column>;
  where?: InputMaybe<Envelope_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "envelope_type". */
export type Envelope_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: envelope_type */
export type Envelope_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "envelope_type" */
export type Envelope_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "envelope_type" */
export type Envelope_Type_Stream_Cursor_Input = {
  initial_value: Envelope_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Envelope_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Envelope_Type_Updates = {
  _set?: InputMaybe<Envelope_Type_Set_Input>;
  where: Envelope_Type_Bool_Exp;
};

export type Envelope_Updates = {
  _append?: InputMaybe<Envelope_Append_Input>;
  _delete_at_path?: InputMaybe<Envelope_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Envelope_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Envelope_Delete_Key_Input>;
  _prepend?: InputMaybe<Envelope_Prepend_Input>;
  _set?: InputMaybe<Envelope_Set_Input>;
  where: Envelope_Bool_Exp;
};

/** Boolean expression to filter rows from the table "experiment". All fields are combined with a logical 'AND'. */
export type Experiment_Bool_Exp = {
  _and?: InputMaybe<Array<Experiment_Bool_Exp>>;
  _not?: InputMaybe<Experiment_Bool_Exp>;
  _or?: InputMaybe<Array<Experiment_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "experiment_enum". All fields are combined with logical 'AND'. */
export type Experiment_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Experiment_Enum>;
  _in?: InputMaybe<Array<Experiment_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Experiment_Enum>;
  _nin?: InputMaybe<Array<Experiment_Enum>>;
};

/** input type for inserting data into table "experiment" */
export type Experiment_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "experiment" */
export type Experiment_On_Conflict = {
  constraint: Experiment_Constraint;
  update_columns: Array<Experiment_Update_Column>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** Ordering options when selecting data from "experiment". */
export type Experiment_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: experiment */
export type Experiment_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "experiment" */
export type Experiment_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "experiment" */
export type Experiment_Stream_Cursor_Input = {
  initial_value: Experiment_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiment_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Experiment_Updates = {
  _set?: InputMaybe<Experiment_Set_Input>;
  where: Experiment_Bool_Exp;
};

export type Feature_Aggregate_Bool_Exp = {
  count?: InputMaybe<Feature_Aggregate_Bool_Exp_Count>;
};

export type Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "feature" */
export type Feature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Feature_Max_Order_By>;
  min?: InputMaybe<Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feature" */
export type Feature_Arr_Rel_Insert_Input = {
  data: Array<Feature_Insert_Input>;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feature". All fields are combined with a logical 'AND'. */
export type Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "feature_check". All fields are combined with a logical 'AND'. */
export type Feature_Check_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Check_Bool_Exp>>;
  _not?: InputMaybe<Feature_Check_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Check_Bool_Exp>>;
  result?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for inserting data into table "feature_check" */
export type Feature_Check_Insert_Input = {
  result?: InputMaybe<Scalars['Boolean']>;
};

/** on_conflict condition type for table "feature_check" */
export type Feature_Check_On_Conflict = {
  constraint: Feature_Check_Constraint;
  update_columns: Array<Feature_Check_Update_Column>;
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_check". */
export type Feature_Check_Order_By = {
  result?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature_check */
export type Feature_Check_Pk_Columns_Input = {
  result: Scalars['Boolean'];
};

/** input type for updating data in table "feature_check" */
export type Feature_Check_Set_Input = {
  result?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "feature_check" */
export type Feature_Check_Stream_Cursor_Input = {
  initial_value: Feature_Check_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Check_Stream_Cursor_Value_Input = {
  result?: InputMaybe<Scalars['Boolean']>;
};

export type Feature_Check_Updates = {
  _set?: InputMaybe<Feature_Check_Set_Input>;
  where: Feature_Check_Bool_Exp;
};

/** input type for inserting data into table "feature" */
export type Feature_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "feature" */
export type Feature_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "feature" */
export type Feature_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "feature" */
export type Feature_On_Conflict = {
  constraint: Feature_Constraint;
  update_columns: Array<Feature_Update_Column>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "feature". */
export type Feature_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feature_pack". All fields are combined with a logical 'AND'. */
export type Feature_Pack_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Pack_Bool_Exp>>;
  _not?: InputMaybe<Feature_Pack_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Pack_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  features?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
  features_aggregate?: InputMaybe<Feature_Pack_Feature_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org_feature_pack?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
  org_feature_pack_aggregate?: InputMaybe<Org_Feature_Pack_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export type Feature_Pack_Feature_Aggregate_Bool_Exp = {
  count?: InputMaybe<Feature_Pack_Feature_Aggregate_Bool_Exp_Count>;
};

export type Feature_Pack_Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "feature_pack_feature" */
export type Feature_Pack_Feature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Feature_Pack_Feature_Max_Order_By>;
  min?: InputMaybe<Feature_Pack_Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feature_pack_feature" */
export type Feature_Pack_Feature_Arr_Rel_Insert_Input = {
  data: Array<Feature_Pack_Feature_Insert_Input>;
  on_conflict?: InputMaybe<Feature_Pack_Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feature_pack_feature". All fields are combined with a logical 'AND'. */
export type Feature_Pack_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Pack_Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Pack_Feature_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature?: InputMaybe<Web_App_Feature_Enum_Comparison_Exp>;
  feature_pack_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  web_app_feature?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

/** input type for inserting data into table "feature_pack_feature" */
export type Feature_Pack_Feature_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Web_App_Feature_Enum>;
  feature_pack_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  web_app_feature?: InputMaybe<Web_App_Feature_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "feature_pack_feature" */
export type Feature_Pack_Feature_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_pack_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "feature_pack_feature" */
export type Feature_Pack_Feature_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_pack_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "feature_pack_feature" */
export type Feature_Pack_Feature_On_Conflict = {
  constraint: Feature_Pack_Feature_Constraint;
  update_columns: Array<Feature_Pack_Feature_Update_Column>;
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_pack_feature". */
export type Feature_Pack_Feature_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  feature_pack_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  web_app_feature?: InputMaybe<Web_App_Feature_Order_By>;
};

/** primary key columns input for table: feature_pack_feature */
export type Feature_Pack_Feature_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "feature_pack_feature" */
export type Feature_Pack_Feature_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Web_App_Feature_Enum>;
  feature_pack_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "feature_pack_feature" */
export type Feature_Pack_Feature_Stream_Cursor_Input = {
  initial_value: Feature_Pack_Feature_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Pack_Feature_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Web_App_Feature_Enum>;
  feature_pack_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Feature_Pack_Feature_Updates = {
  _set?: InputMaybe<Feature_Pack_Feature_Set_Input>;
  where: Feature_Pack_Feature_Bool_Exp;
};

/** input type for inserting data into table "feature_pack" */
export type Feature_Pack_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Feature_Pack_Feature_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org_feature_pack?: InputMaybe<Org_Feature_Pack_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "feature_pack" */
export type Feature_Pack_Obj_Rel_Insert_Input = {
  data: Feature_Pack_Insert_Input;
  on_conflict?: InputMaybe<Feature_Pack_On_Conflict>;
};

/** on_conflict condition type for table "feature_pack" */
export type Feature_Pack_On_Conflict = {
  constraint: Feature_Pack_Constraint;
  update_columns: Array<Feature_Pack_Update_Column>;
  where?: InputMaybe<Feature_Pack_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_pack". */
export type Feature_Pack_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  features_aggregate?: InputMaybe<Feature_Pack_Feature_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org_feature_pack_aggregate?: InputMaybe<Org_Feature_Pack_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature_pack */
export type Feature_Pack_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "feature_pack" */
export type Feature_Pack_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "feature_pack" */
export type Feature_Pack_Stream_Cursor_Input = {
  initial_value: Feature_Pack_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Pack_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Feature_Pack_Updates = {
  _set?: InputMaybe<Feature_Pack_Set_Input>;
  where: Feature_Pack_Bool_Exp;
};

/** primary key columns input for table: feature */
export type Feature_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "feature" */
export type Feature_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "feature" */
export type Feature_Stream_Cursor_Input = {
  initial_value: Feature_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Feature_Updates = {
  _set?: InputMaybe<Feature_Set_Input>;
  where: Feature_Bool_Exp;
};

/** Boolean expression to filter rows from the table "filter_page". All fields are combined with a logical 'AND'. */
export type Filter_Page_Bool_Exp = {
  _and?: InputMaybe<Array<Filter_Page_Bool_Exp>>;
  _not?: InputMaybe<Filter_Page_Bool_Exp>;
  _or?: InputMaybe<Array<Filter_Page_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "filter_page_enum". All fields are combined with logical 'AND'. */
export type Filter_Page_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Filter_Page_Enum>;
  _in?: InputMaybe<Array<Filter_Page_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Filter_Page_Enum>;
  _nin?: InputMaybe<Array<Filter_Page_Enum>>;
};

/** input type for inserting data into table "filter_page" */
export type Filter_Page_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "filter_page" */
export type Filter_Page_On_Conflict = {
  constraint: Filter_Page_Constraint;
  update_columns: Array<Filter_Page_Update_Column>;
  where?: InputMaybe<Filter_Page_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_page". */
export type Filter_Page_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: filter_page */
export type Filter_Page_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "filter_page" */
export type Filter_Page_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "filter_page" */
export type Filter_Page_Stream_Cursor_Input = {
  initial_value: Filter_Page_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filter_Page_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Filter_Page_Updates = {
  _set?: InputMaybe<Filter_Page_Set_Input>;
  where: Filter_Page_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Filter_State_Append_Input = {
  state?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "filter_state". All fields are combined with a logical 'AND'. */
export type Filter_State_Bool_Exp = {
  _and?: InputMaybe<Array<Filter_State_Bool_Exp>>;
  _not?: InputMaybe<Filter_State_Bool_Exp>;
  _or?: InputMaybe<Array<Filter_State_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  page?: InputMaybe<Filter_Page_Enum_Comparison_Exp>;
  state?: InputMaybe<Jsonb_Comparison_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Filter_State_Delete_At_Path_Input = {
  state?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Filter_State_Delete_Elem_Input = {
  state?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Filter_State_Delete_Key_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "filter_state" */
export type Filter_State_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "filter_state" */
export type Filter_State_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Filter_Page_Enum>;
  state?: InputMaybe<Scalars['jsonb']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "filter_state" */
export type Filter_State_On_Conflict = {
  constraint: Filter_State_Constraint;
  update_columns: Array<Filter_State_Update_Column>;
  where?: InputMaybe<Filter_State_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_state". */
export type Filter_State_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: filter_state */
export type Filter_State_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Filter_State_Prepend_Input = {
  state?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "filter_state" */
export type Filter_State_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Filter_Page_Enum>;
  state?: InputMaybe<Scalars['jsonb']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "filter_state" */
export type Filter_State_Stream_Cursor_Input = {
  initial_value: Filter_State_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Filter_State_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Filter_Page_Enum>;
  state?: InputMaybe<Scalars['jsonb']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Filter_State_Updates = {
  _append?: InputMaybe<Filter_State_Append_Input>;
  _delete_at_path?: InputMaybe<Filter_State_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Filter_State_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Filter_State_Delete_Key_Input>;
  _inc?: InputMaybe<Filter_State_Inc_Input>;
  _prepend?: InputMaybe<Filter_State_Prepend_Input>;
  _set?: InputMaybe<Filter_State_Set_Input>;
  where: Filter_State_Bool_Exp;
};

/** Boolean expression to filter rows from the table "finance_status". All fields are combined with a logical 'AND'. */
export type Finance_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Finance_Status_Bool_Exp>>;
  _not?: InputMaybe<Finance_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Finance_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "finance_status_enum". All fields are combined with logical 'AND'. */
export type Finance_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Finance_Status_Enum>;
  _in?: InputMaybe<Array<Finance_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Finance_Status_Enum>;
  _nin?: InputMaybe<Array<Finance_Status_Enum>>;
};

/** input type for inserting data into table "finance_status" */
export type Finance_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "finance_status" */
export type Finance_Status_On_Conflict = {
  constraint: Finance_Status_Constraint;
  update_columns: Array<Finance_Status_Update_Column>;
  where?: InputMaybe<Finance_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "finance_status". */
export type Finance_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: finance_status */
export type Finance_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "finance_status" */
export type Finance_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "finance_status" */
export type Finance_Status_Stream_Cursor_Input = {
  initial_value: Finance_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finance_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Finance_Status_Updates = {
  _set?: InputMaybe<Finance_Status_Set_Input>;
  where: Finance_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "graphql_query_log". All fields are combined with a logical 'AND'. */
export type Graphql_Query_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Graphql_Query_Log_Bool_Exp>>;
  _not?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Graphql_Query_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_role?: InputMaybe<String_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_team_role?: InputMaybe<String_Comparison_Exp>;
  created_by_team_type?: InputMaybe<String_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  finished_at?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  op_content?: InputMaybe<String_Comparison_Exp>;
  op_name?: InputMaybe<String_Comparison_Exp>;
  op_vars?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "graphql_query_log" */
export type Graphql_Query_Log_Inc_Input = {
  duration?: InputMaybe<Scalars['Int']>;
  finished_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "graphql_query_log" */
export type Graphql_Query_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_role?: InputMaybe<Scalars['String']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_team_role?: InputMaybe<Scalars['String']>;
  created_by_team_type?: InputMaybe<Scalars['String']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  duration?: InputMaybe<Scalars['Int']>;
  finished_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  op_content?: InputMaybe<Scalars['String']>;
  op_name?: InputMaybe<Scalars['String']>;
  op_vars?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['bigint']>;
};

/** on_conflict condition type for table "graphql_query_log" */
export type Graphql_Query_Log_On_Conflict = {
  constraint: Graphql_Query_Log_Constraint;
  update_columns: Array<Graphql_Query_Log_Update_Column>;
  where?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "graphql_query_log". */
export type Graphql_Query_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_role?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_team_role?: InputMaybe<Order_By>;
  created_by_team_type?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  finished_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  op_content?: InputMaybe<Order_By>;
  op_name?: InputMaybe<Order_By>;
  op_vars?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: graphql_query_log */
export type Graphql_Query_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** input type for updating data in table "graphql_query_log" */
export type Graphql_Query_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_role?: InputMaybe<Scalars['String']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_team_role?: InputMaybe<Scalars['String']>;
  created_by_team_type?: InputMaybe<Scalars['String']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  duration?: InputMaybe<Scalars['Int']>;
  finished_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  op_content?: InputMaybe<Scalars['String']>;
  op_name?: InputMaybe<Scalars['String']>;
  op_vars?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['bigint']>;
};

/** Streaming cursor of the table "graphql_query_log" */
export type Graphql_Query_Log_Stream_Cursor_Input = {
  initial_value: Graphql_Query_Log_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Graphql_Query_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_role?: InputMaybe<Scalars['String']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_team_role?: InputMaybe<Scalars['String']>;
  created_by_team_type?: InputMaybe<Scalars['String']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  duration?: InputMaybe<Scalars['Int']>;
  finished_at?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  op_content?: InputMaybe<Scalars['String']>;
  op_name?: InputMaybe<Scalars['String']>;
  op_vars?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['bigint']>;
};

export type Graphql_Query_Log_Updates = {
  _inc?: InputMaybe<Graphql_Query_Log_Inc_Input>;
  _set?: InputMaybe<Graphql_Query_Log_Set_Input>;
  where: Graphql_Query_Log_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hubspot_Action_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "hubspot_action". All fields are combined with a logical 'AND'. */
export type Hubspot_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Action_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Action_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Process_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Hubspot_Action_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hubspot_Action_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hubspot_Action_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hubspot_Action_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hubspot_action" */
export type Hubspot_Action_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Process_Status_Enum>;
  type?: InputMaybe<Hubspot_Action_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "hubspot_action" */
export type Hubspot_Action_On_Conflict = {
  constraint: Hubspot_Action_Constraint;
  update_columns: Array<Hubspot_Action_Update_Column>;
  where?: InputMaybe<Hubspot_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_action". */
export type Hubspot_Action_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_action */
export type Hubspot_Action_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hubspot_Action_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "hubspot_action" */
export type Hubspot_Action_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Process_Status_Enum>;
  type?: InputMaybe<Hubspot_Action_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "hubspot_action" */
export type Hubspot_Action_Stream_Cursor_Input = {
  initial_value: Hubspot_Action_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Action_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Process_Status_Enum>;
  type?: InputMaybe<Hubspot_Action_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "hubspot_action_type". All fields are combined with a logical 'AND'. */
export type Hubspot_Action_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Action_Type_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Action_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "hubspot_action_type_enum". All fields are combined with logical 'AND'. */
export type Hubspot_Action_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Hubspot_Action_Type_Enum>;
  _in?: InputMaybe<Array<Hubspot_Action_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Hubspot_Action_Type_Enum>;
  _nin?: InputMaybe<Array<Hubspot_Action_Type_Enum>>;
};

/** input type for inserting data into table "hubspot_action_type" */
export type Hubspot_Action_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "hubspot_action_type" */
export type Hubspot_Action_Type_On_Conflict = {
  constraint: Hubspot_Action_Type_Constraint;
  update_columns: Array<Hubspot_Action_Type_Update_Column>;
  where?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_action_type". */
export type Hubspot_Action_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_action_type */
export type Hubspot_Action_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "hubspot_action_type" */
export type Hubspot_Action_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "hubspot_action_type" */
export type Hubspot_Action_Type_Stream_Cursor_Input = {
  initial_value: Hubspot_Action_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Action_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Hubspot_Action_Type_Updates = {
  _set?: InputMaybe<Hubspot_Action_Type_Set_Input>;
  where: Hubspot_Action_Type_Bool_Exp;
};

export type Hubspot_Action_Updates = {
  _append?: InputMaybe<Hubspot_Action_Append_Input>;
  _delete_at_path?: InputMaybe<Hubspot_Action_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hubspot_Action_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hubspot_Action_Delete_Key_Input>;
  _prepend?: InputMaybe<Hubspot_Action_Prepend_Input>;
  _set?: InputMaybe<Hubspot_Action_Set_Input>;
  where: Hubspot_Action_Bool_Exp;
};

export type Hubspot_Contact_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hubspot_Contact_Aggregate_Bool_Exp_Count>;
};

export type Hubspot_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hubspot_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "hubspot_contact" */
export type Hubspot_Contact_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hubspot_Contact_Max_Order_By>;
  min?: InputMaybe<Hubspot_Contact_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspot_contact" */
export type Hubspot_Contact_Arr_Rel_Insert_Input = {
  data: Array<Hubspot_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Hubspot_Contact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspot_contact". All fields are combined with a logical 'AND'. */
export type Hubspot_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Contact_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Contact_Bool_Exp>>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "hubspot_contact" */
export type Hubspot_Contact_Insert_Input = {
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hubspot_contact" */
export type Hubspot_Contact_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "hubspot_contact" */
export type Hubspot_Contact_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "hubspot_contact" */
export type Hubspot_Contact_On_Conflict = {
  constraint: Hubspot_Contact_Constraint;
  update_columns: Array<Hubspot_Contact_Update_Column>;
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_contact". */
export type Hubspot_Contact_Order_By = {
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_contact */
export type Hubspot_Contact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "hubspot_contact" */
export type Hubspot_Contact_Set_Input = {
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "hubspot_contact" */
export type Hubspot_Contact_Stream_Cursor_Input = {
  initial_value: Hubspot_Contact_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Contact_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Hubspot_Contact_Updates = {
  _set?: InputMaybe<Hubspot_Contact_Set_Input>;
  where: Hubspot_Contact_Bool_Exp;
};

export type Hubspot_Deal_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hubspot_Deal_Aggregate_Bool_Exp_Count>;
};

export type Hubspot_Deal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hubspot_Deal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "hubspot_deal" */
export type Hubspot_Deal_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hubspot_Deal_Max_Order_By>;
  min?: InputMaybe<Hubspot_Deal_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspot_deal" */
export type Hubspot_Deal_Arr_Rel_Insert_Input = {
  data: Array<Hubspot_Deal_Insert_Input>;
  on_conflict?: InputMaybe<Hubspot_Deal_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspot_deal". All fields are combined with a logical 'AND'. */
export type Hubspot_Deal_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Deal_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Deal_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Deal_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "hubspot_deal" */
export type Hubspot_Deal_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hubspot_deal" */
export type Hubspot_Deal_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "hubspot_deal" */
export type Hubspot_Deal_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "hubspot_deal" */
export type Hubspot_Deal_On_Conflict = {
  constraint: Hubspot_Deal_Constraint;
  update_columns: Array<Hubspot_Deal_Update_Column>;
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_deal". */
export type Hubspot_Deal_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_deal */
export type Hubspot_Deal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "hubspot_deal" */
export type Hubspot_Deal_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "hubspot_deal" */
export type Hubspot_Deal_Stream_Cursor_Input = {
  initial_value: Hubspot_Deal_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Deal_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Hubspot_Deal_Updates = {
  _set?: InputMaybe<Hubspot_Deal_Set_Input>;
  where: Hubspot_Deal_Bool_Exp;
};

export type Hubspot_Organisation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hubspot_Organisation_Aggregate_Bool_Exp_Count>;
};

export type Hubspot_Organisation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "hubspot_organisation" */
export type Hubspot_Organisation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hubspot_Organisation_Max_Order_By>;
  min?: InputMaybe<Hubspot_Organisation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspot_organisation" */
export type Hubspot_Organisation_Arr_Rel_Insert_Input = {
  data: Array<Hubspot_Organisation_Insert_Input>;
  on_conflict?: InputMaybe<Hubspot_Organisation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspot_organisation". All fields are combined with a logical 'AND'. */
export type Hubspot_Organisation_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Organisation_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Organisation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "hubspot_organisation" */
export type Hubspot_Organisation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hubspot_organisation" */
export type Hubspot_Organisation_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "hubspot_organisation" */
export type Hubspot_Organisation_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "hubspot_organisation" */
export type Hubspot_Organisation_On_Conflict = {
  constraint: Hubspot_Organisation_Constraint;
  update_columns: Array<Hubspot_Organisation_Update_Column>;
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_organisation". */
export type Hubspot_Organisation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_organisation */
export type Hubspot_Organisation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "hubspot_organisation" */
export type Hubspot_Organisation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "hubspot_organisation" */
export type Hubspot_Organisation_Stream_Cursor_Input = {
  initial_value: Hubspot_Organisation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Organisation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Hubspot_Organisation_Updates = {
  _set?: InputMaybe<Hubspot_Organisation_Set_Input>;
  where: Hubspot_Organisation_Bool_Exp;
};

export type Hubspot_Project_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hubspot_Project_Aggregate_Bool_Exp_Count>;
};

export type Hubspot_Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hubspot_Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "hubspot_project" */
export type Hubspot_Project_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hubspot_Project_Max_Order_By>;
  min?: InputMaybe<Hubspot_Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspot_project" */
export type Hubspot_Project_Arr_Rel_Insert_Input = {
  data: Array<Hubspot_Project_Insert_Input>;
  on_conflict?: InputMaybe<Hubspot_Project_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspot_project". All fields are combined with a logical 'AND'. */
export type Hubspot_Project_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Project_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Project_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Project_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "hubspot_project" */
export type Hubspot_Project_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hubspot_project" */
export type Hubspot_Project_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "hubspot_project" */
export type Hubspot_Project_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "hubspot_project" */
export type Hubspot_Project_On_Conflict = {
  constraint: Hubspot_Project_Constraint;
  update_columns: Array<Hubspot_Project_Update_Column>;
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_project". */
export type Hubspot_Project_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_project */
export type Hubspot_Project_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "hubspot_project" */
export type Hubspot_Project_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "hubspot_project" */
export type Hubspot_Project_Stream_Cursor_Input = {
  initial_value: Hubspot_Project_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Project_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Hubspot_Project_Updates = {
  _set?: InputMaybe<Hubspot_Project_Set_Input>;
  where: Hubspot_Project_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hubspot_Schema_Error_Log_Append_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "hubspot_schema_error_log". All fields are combined with a logical 'AND'. */
export type Hubspot_Schema_Error_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Schema_Error_Log_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Schema_Error_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hubspot_Schema_Error_Log_Delete_At_Path_Input = {
  error?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hubspot_Schema_Error_Log_Delete_Elem_Input = {
  error?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hubspot_Schema_Error_Log_Delete_Key_Input = {
  error?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_On_Conflict = {
  constraint: Hubspot_Schema_Error_Log_Constraint;
  update_columns: Array<Hubspot_Schema_Error_Log_Update_Column>;
  where?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_schema_error_log". */
export type Hubspot_Schema_Error_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_schema_error_log */
export type Hubspot_Schema_Error_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hubspot_Schema_Error_Log_Prepend_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_Stream_Cursor_Input = {
  initial_value: Hubspot_Schema_Error_Log_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Schema_Error_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Hubspot_Schema_Error_Log_Updates = {
  _append?: InputMaybe<Hubspot_Schema_Error_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Hubspot_Schema_Error_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hubspot_Schema_Error_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hubspot_Schema_Error_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Hubspot_Schema_Error_Log_Prepend_Input>;
  _set?: InputMaybe<Hubspot_Schema_Error_Log_Set_Input>;
  where: Hubspot_Schema_Error_Log_Bool_Exp;
};

export type Hubspot_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hubspot_User_Aggregate_Bool_Exp_Count>;
};

export type Hubspot_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hubspot_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "hubspot_user" */
export type Hubspot_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hubspot_User_Max_Order_By>;
  min?: InputMaybe<Hubspot_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspot_user" */
export type Hubspot_User_Arr_Rel_Insert_Input = {
  data: Array<Hubspot_User_Insert_Input>;
  on_conflict?: InputMaybe<Hubspot_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspot_user". All fields are combined with a logical 'AND'. */
export type Hubspot_User_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_User_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_User_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "hubspot_user" */
export type Hubspot_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hubspot_user" */
export type Hubspot_User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "hubspot_user" */
export type Hubspot_User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "hubspot_user" */
export type Hubspot_User_On_Conflict = {
  constraint: Hubspot_User_Constraint;
  update_columns: Array<Hubspot_User_Update_Column>;
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_user". */
export type Hubspot_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_user */
export type Hubspot_User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "hubspot_user" */
export type Hubspot_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "hubspot_user" */
export type Hubspot_User_Stream_Cursor_Input = {
  initial_value: Hubspot_User_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Hubspot_User_Updates = {
  _set?: InputMaybe<Hubspot_User_Set_Input>;
  where: Hubspot_User_Bool_Exp;
};

export type Hubspot_Valuation_Booking_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hubspot_Valuation_Booking_Aggregate_Bool_Exp_Count>;
};

export type Hubspot_Valuation_Booking_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hubspot_Valuation_Booking_Max_Order_By>;
  min?: InputMaybe<Hubspot_Valuation_Booking_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Arr_Rel_Insert_Input = {
  data: Array<Hubspot_Valuation_Booking_Insert_Input>;
  on_conflict?: InputMaybe<Hubspot_Valuation_Booking_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspot_valuation_booking". All fields are combined with a logical 'AND'. */
export type Hubspot_Valuation_Booking_Bool_Exp = {
  _and?: InputMaybe<Array<Hubspot_Valuation_Booking_Bool_Exp>>;
  _not?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
  _or?: InputMaybe<Array<Hubspot_Valuation_Booking_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hs_id?: InputMaybe<String_Comparison_Exp>;
  hs_instance_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valuation_booking_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valuation_booking_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valuation_booking_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valuation_booking_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_On_Conflict = {
  constraint: Hubspot_Valuation_Booking_Constraint;
  update_columns: Array<Hubspot_Valuation_Booking_Update_Column>;
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspot_valuation_booking". */
export type Hubspot_Valuation_Booking_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hs_id?: InputMaybe<Order_By>;
  hs_instance_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valuation_booking_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspot_valuation_booking */
export type Hubspot_Valuation_Booking_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valuation_booking_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Stream_Cursor_Input = {
  initial_value: Hubspot_Valuation_Booking_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hubspot_Valuation_Booking_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hs_id?: InputMaybe<Scalars['String']>;
  hs_instance_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  valuation_booking_id?: InputMaybe<Scalars['uuid']>;
};

export type Hubspot_Valuation_Booking_Updates = {
  _set?: InputMaybe<Hubspot_Valuation_Booking_Set_Input>;
  where: Hubspot_Valuation_Booking_Bool_Exp;
};

export type Invitation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invitation_Aggregate_Bool_Exp_Count>;
};

export type Invitation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invitation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invitation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "invitation" */
export type Invitation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invitation_Max_Order_By>;
  min?: InputMaybe<Invitation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invitation" */
export type Invitation_Arr_Rel_Insert_Input = {
  data: Array<Invitation_Insert_Input>;
  on_conflict?: InputMaybe<Invitation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invitation". All fields are combined with a logical 'AND'. */
export type Invitation_Bool_Exp = {
  _and?: InputMaybe<Array<Invitation_Bool_Exp>>;
  _not?: InputMaybe<Invitation_Bool_Exp>;
  _or?: InputMaybe<Array<Invitation_Bool_Exp>>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitation_status?: InputMaybe<Invitation_Status_Bool_Exp>;
  invitation_type?: InputMaybe<Invitation_Type_Bool_Exp>;
  invitedByEmail?: InputMaybe<User_Bool_Exp>;
  invitedByEmail_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  invitee_firstname?: InputMaybe<String_Comparison_Exp>;
  invitee_lastname?: InputMaybe<String_Comparison_Exp>;
  invitee_phone_number?: InputMaybe<String_Comparison_Exp>;
  inviter?: InputMaybe<User_Bool_Exp>;
  inviter_firstname?: InputMaybe<String_Comparison_Exp>;
  inviter_lastname?: InputMaybe<String_Comparison_Exp>;
  member_role?: InputMaybe<Member_Role_Bool_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_name?: InputMaybe<String_Comparison_Exp>;
  partner_portal?: InputMaybe<Partner_Portal_Bool_Exp>;
  partner_portal_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Member_Role_Enum_Comparison_Exp>;
  status?: InputMaybe<Invitation_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Invitation_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "invitation" */
export type Invitation_Insert_Input = {
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitation_status?: InputMaybe<Invitation_Status_Obj_Rel_Insert_Input>;
  invitation_type?: InputMaybe<Invitation_Type_Obj_Rel_Insert_Input>;
  invitedByEmail?: InputMaybe<User_Arr_Rel_Insert_Input>;
  invitee_firstname?: InputMaybe<Scalars['String']>;
  invitee_lastname?: InputMaybe<Scalars['String']>;
  invitee_phone_number?: InputMaybe<Scalars['String']>;
  inviter?: InputMaybe<User_Obj_Rel_Insert_Input>;
  inviter_firstname?: InputMaybe<Scalars['String']>;
  inviter_lastname?: InputMaybe<Scalars['String']>;
  member_role?: InputMaybe<Member_Role_Obj_Rel_Insert_Input>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  organisation_name?: InputMaybe<Scalars['String']>;
  partner_portal?: InputMaybe<Partner_Portal_Obj_Rel_Insert_Input>;
  partner_portal_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Member_Role_Enum>;
  status?: InputMaybe<Invitation_Status_Enum>;
  type?: InputMaybe<Invitation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "invitation" */
export type Invitation_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitee_firstname?: InputMaybe<Order_By>;
  invitee_lastname?: InputMaybe<Order_By>;
  invitee_phone_number?: InputMaybe<Order_By>;
  inviter_firstname?: InputMaybe<Order_By>;
  inviter_lastname?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  organisation_name?: InputMaybe<Order_By>;
  partner_portal_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "invitation" */
export type Invitation_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitee_firstname?: InputMaybe<Order_By>;
  invitee_lastname?: InputMaybe<Order_By>;
  invitee_phone_number?: InputMaybe<Order_By>;
  inviter_firstname?: InputMaybe<Order_By>;
  inviter_lastname?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  organisation_name?: InputMaybe<Order_By>;
  partner_portal_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "invitation" */
export type Invitation_Obj_Rel_Insert_Input = {
  data: Invitation_Insert_Input;
  on_conflict?: InputMaybe<Invitation_On_Conflict>;
};

/** on_conflict condition type for table "invitation" */
export type Invitation_On_Conflict = {
  constraint: Invitation_Constraint;
  update_columns: Array<Invitation_Update_Column>;
  where?: InputMaybe<Invitation_Bool_Exp>;
};

/** Ordering options when selecting data from "invitation". */
export type Invitation_Order_By = {
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_status?: InputMaybe<Invitation_Status_Order_By>;
  invitation_type?: InputMaybe<Invitation_Type_Order_By>;
  invitedByEmail_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  invitee_firstname?: InputMaybe<Order_By>;
  invitee_lastname?: InputMaybe<Order_By>;
  invitee_phone_number?: InputMaybe<Order_By>;
  inviter?: InputMaybe<User_Order_By>;
  inviter_firstname?: InputMaybe<Order_By>;
  inviter_lastname?: InputMaybe<Order_By>;
  member_role?: InputMaybe<Member_Role_Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  organisation_name?: InputMaybe<Order_By>;
  partner_portal?: InputMaybe<Partner_Portal_Order_By>;
  partner_portal_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invitation */
export type Invitation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "invitation" */
export type Invitation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitee_firstname?: InputMaybe<Scalars['String']>;
  invitee_lastname?: InputMaybe<Scalars['String']>;
  invitee_phone_number?: InputMaybe<Scalars['String']>;
  inviter_firstname?: InputMaybe<Scalars['String']>;
  inviter_lastname?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  organisation_name?: InputMaybe<Scalars['String']>;
  partner_portal_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Member_Role_Enum>;
  status?: InputMaybe<Invitation_Status_Enum>;
  type?: InputMaybe<Invitation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "invitation_status". All fields are combined with a logical 'AND'. */
export type Invitation_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Invitation_Status_Bool_Exp>>;
  _not?: InputMaybe<Invitation_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Invitation_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "invitation_status_enum". All fields are combined with logical 'AND'. */
export type Invitation_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Invitation_Status_Enum>;
  _in?: InputMaybe<Array<Invitation_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Invitation_Status_Enum>;
  _nin?: InputMaybe<Array<Invitation_Status_Enum>>;
};

/** input type for inserting data into table "invitation_status" */
export type Invitation_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "invitation_status" */
export type Invitation_Status_Obj_Rel_Insert_Input = {
  data: Invitation_Status_Insert_Input;
  on_conflict?: InputMaybe<Invitation_Status_On_Conflict>;
};

/** on_conflict condition type for table "invitation_status" */
export type Invitation_Status_On_Conflict = {
  constraint: Invitation_Status_Constraint;
  update_columns: Array<Invitation_Status_Update_Column>;
  where?: InputMaybe<Invitation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "invitation_status". */
export type Invitation_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invitation_status */
export type Invitation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "invitation_status" */
export type Invitation_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "invitation_status" */
export type Invitation_Status_Stream_Cursor_Input = {
  initial_value: Invitation_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitation_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Invitation_Status_Updates = {
  _set?: InputMaybe<Invitation_Status_Set_Input>;
  where: Invitation_Status_Bool_Exp;
};

/** Streaming cursor of the table "invitation" */
export type Invitation_Stream_Cursor_Input = {
  initial_value: Invitation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitee_firstname?: InputMaybe<Scalars['String']>;
  invitee_lastname?: InputMaybe<Scalars['String']>;
  invitee_phone_number?: InputMaybe<Scalars['String']>;
  inviter_firstname?: InputMaybe<Scalars['String']>;
  inviter_lastname?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  organisation_name?: InputMaybe<Scalars['String']>;
  partner_portal_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Member_Role_Enum>;
  status?: InputMaybe<Invitation_Status_Enum>;
  type?: InputMaybe<Invitation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "invitation_type". All fields are combined with a logical 'AND'. */
export type Invitation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Invitation_Type_Bool_Exp>>;
  _not?: InputMaybe<Invitation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Invitation_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  invitations?: InputMaybe<Invitation_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "invitation_type_enum". All fields are combined with logical 'AND'. */
export type Invitation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Invitation_Type_Enum>;
  _in?: InputMaybe<Array<Invitation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Invitation_Type_Enum>;
  _nin?: InputMaybe<Array<Invitation_Type_Enum>>;
};

/** input type for inserting data into table "invitation_type" */
export type Invitation_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  invitations?: InputMaybe<Invitation_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "invitation_type" */
export type Invitation_Type_Obj_Rel_Insert_Input = {
  data: Invitation_Type_Insert_Input;
  on_conflict?: InputMaybe<Invitation_Type_On_Conflict>;
};

/** on_conflict condition type for table "invitation_type" */
export type Invitation_Type_On_Conflict = {
  constraint: Invitation_Type_Constraint;
  update_columns: Array<Invitation_Type_Update_Column>;
  where?: InputMaybe<Invitation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "invitation_type". */
export type Invitation_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invitation_type */
export type Invitation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "invitation_type" */
export type Invitation_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "invitation_type" */
export type Invitation_Type_Stream_Cursor_Input = {
  initial_value: Invitation_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitation_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Invitation_Type_Updates = {
  _set?: InputMaybe<Invitation_Type_Set_Input>;
  where: Invitation_Type_Bool_Exp;
};

export type Invitation_Updates = {
  _set?: InputMaybe<Invitation_Set_Input>;
  where: Invitation_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  _has_key?: InputMaybe<Scalars['String']>;
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lambda_Log_Append_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "lambda_log". All fields are combined with a logical 'AND'. */
export type Lambda_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Lambda_Log_Bool_Exp>>;
  _not?: InputMaybe<Lambda_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Lambda_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lambda_Log_Delete_At_Path_Input = {
  request?: InputMaybe<Array<Scalars['String']>>;
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lambda_Log_Delete_Elem_Input = {
  request?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lambda_Log_Delete_Key_Input = {
  request?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "lambda_log" */
export type Lambda_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "lambda_log" */
export type Lambda_Log_On_Conflict = {
  constraint: Lambda_Log_Constraint;
  update_columns: Array<Lambda_Log_Update_Column>;
  where?: InputMaybe<Lambda_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "lambda_log". */
export type Lambda_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lambda_log */
export type Lambda_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lambda_Log_Prepend_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "lambda_log" */
export type Lambda_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lambda_log" */
export type Lambda_Log_Stream_Cursor_Input = {
  initial_value: Lambda_Log_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lambda_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Lambda_Log_Updates = {
  _append?: InputMaybe<Lambda_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Lambda_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lambda_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lambda_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Lambda_Log_Prepend_Input>;
  _set?: InputMaybe<Lambda_Log_Set_Input>;
  where: Lambda_Log_Bool_Exp;
};

/** Boolean expression to filter rows from the table "lead_generation". All fields are combined with a logical 'AND'. */
export type Lead_Generation_Bool_Exp = {
  _and?: InputMaybe<Array<Lead_Generation_Bool_Exp>>;
  _not?: InputMaybe<Lead_Generation_Bool_Exp>;
  _or?: InputMaybe<Array<Lead_Generation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lead_generation_webhook_logs?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
  lead_generation_webhook_logs_aggregate?: InputMaybe<Lead_Generation_Webhook_Log_Aggregate_Bool_Exp>;
  lead_owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<Lead_Generation_Source_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** input type for inserting data into table "lead_generation" */
export type Lead_Generation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lead_generation_webhook_logs?: InputMaybe<Lead_Generation_Webhook_Log_Arr_Rel_Insert_Input>;
  lead_owner_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Lead_Generation_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** input type for inserting object relation for remote table "lead_generation" */
export type Lead_Generation_Obj_Rel_Insert_Input = {
  data: Lead_Generation_Insert_Input;
  on_conflict?: InputMaybe<Lead_Generation_On_Conflict>;
};

/** on_conflict condition type for table "lead_generation" */
export type Lead_Generation_On_Conflict = {
  constraint: Lead_Generation_Constraint;
  update_columns: Array<Lead_Generation_Update_Column>;
  where?: InputMaybe<Lead_Generation_Bool_Exp>;
};

/** Ordering options when selecting data from "lead_generation". */
export type Lead_Generation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_generation_webhook_logs_aggregate?: InputMaybe<Lead_Generation_Webhook_Log_Aggregate_Order_By>;
  lead_owner_id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: lead_generation */
export type Lead_Generation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "lead_generation" */
export type Lead_Generation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lead_owner_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Lead_Generation_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "lead_generation_source". All fields are combined with a logical 'AND'. */
export type Lead_Generation_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Lead_Generation_Source_Bool_Exp>>;
  _not?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Lead_Generation_Source_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "lead_generation_source_enum". All fields are combined with logical 'AND'. */
export type Lead_Generation_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Lead_Generation_Source_Enum>;
  _in?: InputMaybe<Array<Lead_Generation_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Lead_Generation_Source_Enum>;
  _nin?: InputMaybe<Array<Lead_Generation_Source_Enum>>;
};

/** input type for inserting data into table "lead_generation_source" */
export type Lead_Generation_Source_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "lead_generation_source" */
export type Lead_Generation_Source_On_Conflict = {
  constraint: Lead_Generation_Source_Constraint;
  update_columns: Array<Lead_Generation_Source_Update_Column>;
  where?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "lead_generation_source". */
export type Lead_Generation_Source_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lead_generation_source */
export type Lead_Generation_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "lead_generation_source" */
export type Lead_Generation_Source_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lead_generation_source" */
export type Lead_Generation_Source_Stream_Cursor_Input = {
  initial_value: Lead_Generation_Source_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lead_Generation_Source_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Lead_Generation_Source_Updates = {
  _set?: InputMaybe<Lead_Generation_Source_Set_Input>;
  where: Lead_Generation_Source_Bool_Exp;
};

/** Streaming cursor of the table "lead_generation" */
export type Lead_Generation_Stream_Cursor_Input = {
  initial_value: Lead_Generation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lead_Generation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lead_owner_id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Lead_Generation_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Lead_Generation_Updates = {
  _set?: InputMaybe<Lead_Generation_Set_Input>;
  where: Lead_Generation_Bool_Exp;
};

export type Lead_Generation_Webhook_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lead_Generation_Webhook_Log_Aggregate_Bool_Exp_Count>;
};

export type Lead_Generation_Webhook_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lead_Generation_Webhook_Log_Max_Order_By>;
  min?: InputMaybe<Lead_Generation_Webhook_Log_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lead_Generation_Webhook_Log_Append_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Arr_Rel_Insert_Input = {
  data: Array<Lead_Generation_Webhook_Log_Insert_Input>;
  on_conflict?: InputMaybe<Lead_Generation_Webhook_Log_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lead_generation_webhook_log". All fields are combined with a logical 'AND'. */
export type Lead_Generation_Webhook_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Lead_Generation_Webhook_Log_Bool_Exp>>;
  _not?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Lead_Generation_Webhook_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lead_generation?: InputMaybe<Lead_Generation_Bool_Exp>;
  lead_generation_id?: InputMaybe<Uuid_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Process_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lead_Generation_Webhook_Log_Delete_At_Path_Input = {
  request?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lead_Generation_Webhook_Log_Delete_Elem_Input = {
  request?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lead_Generation_Webhook_Log_Delete_Key_Input = {
  request?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lead_generation?: InputMaybe<Lead_Generation_Obj_Rel_Insert_Input>;
  lead_generation_id?: InputMaybe<Scalars['uuid']>;
  request?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Process_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_generation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_generation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_On_Conflict = {
  constraint: Lead_Generation_Webhook_Log_Constraint;
  update_columns: Array<Lead_Generation_Webhook_Log_Update_Column>;
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "lead_generation_webhook_log". */
export type Lead_Generation_Webhook_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_generation?: InputMaybe<Lead_Generation_Order_By>;
  lead_generation_id?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lead_generation_webhook_log */
export type Lead_Generation_Webhook_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lead_Generation_Webhook_Log_Prepend_Input = {
  request?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lead_generation_id?: InputMaybe<Scalars['uuid']>;
  request?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Process_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Stream_Cursor_Input = {
  initial_value: Lead_Generation_Webhook_Log_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lead_Generation_Webhook_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lead_generation_id?: InputMaybe<Scalars['uuid']>;
  request?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Process_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Lead_Generation_Webhook_Log_Updates = {
  _append?: InputMaybe<Lead_Generation_Webhook_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Lead_Generation_Webhook_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lead_Generation_Webhook_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lead_Generation_Webhook_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Lead_Generation_Webhook_Log_Prepend_Input>;
  _set?: InputMaybe<Lead_Generation_Webhook_Log_Set_Input>;
  where: Lead_Generation_Webhook_Log_Bool_Exp;
};

export type Listing_Aggregate_Bool_Exp = {
  count?: InputMaybe<Listing_Aggregate_Bool_Exp_Count>;
};

export type Listing_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Listing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Listing_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "listing" */
export type Listing_Aggregate_Order_By = {
  avg?: InputMaybe<Listing_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Listing_Max_Order_By>;
  min?: InputMaybe<Listing_Min_Order_By>;
  stddev?: InputMaybe<Listing_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Listing_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Listing_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Listing_Sum_Order_By>;
  var_pop?: InputMaybe<Listing_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Listing_Var_Samp_Order_By>;
  variance?: InputMaybe<Listing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "listing" */
export type Listing_Arr_Rel_Insert_Input = {
  data: Array<Listing_Insert_Input>;
  on_conflict?: InputMaybe<Listing_On_Conflict>;
};

/** order by avg() on columns of table "listing" */
export type Listing_Avg_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "listing". All fields are combined with a logical 'AND'. */
export type Listing_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Bool_Exp>>;
  _not?: InputMaybe<Listing_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Bool_Exp>>;
  acl_allocation_edit?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
  acl_allocation_edit_aggregate?: InputMaybe<Acl_Allocation_Edit_Aggregate_Bool_Exp>;
  acl_listing_agent_member?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
  acl_listing_agent_member_aggregate?: InputMaybe<Acl_Listing_Agent_Member_Aggregate_Bool_Exp>;
  acl_listing_master_agent?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
  acl_listing_master_agent_aggregate?: InputMaybe<Acl_Listing_Master_Agent_Aggregate_Bool_Exp>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Bool_Exp>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Bool_Exp>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  block_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  block_release_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  blocked_by_user?: InputMaybe<Uuid_Comparison_Exp>;
  blocked_by_user_rel?: InputMaybe<User_Bool_Exp>;
  blocked_for_user?: InputMaybe<Uuid_Comparison_Exp>;
  blocked_for_user_rel?: InputMaybe<User_Bool_Exp>;
  car_space?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_blocked?: InputMaybe<Boolean_Comparison_Exp>;
  listing_status?: InputMaybe<Listing_Status_Bool_Exp>;
  member_project_allocates?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
  member_project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Bool_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  price_per_sqmt?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
  price_range_from_calc?: InputMaybe<Int_Comparison_Exp>;
  price_range_to_calc?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  release_number?: InputMaybe<String_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Listing_Status_Enum_Comparison_Exp>;
  unit_allocation_count?: InputMaybe<Int_Comparison_Exp>;
  unit_allocation_count_master?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "listing" */
export type Listing_Inc_Input = {
  car_space?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  unit_allocation_count?: InputMaybe<Scalars['Int']>;
  unit_allocation_count_master?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "listing" */
export type Listing_Insert_Input = {
  acl_allocation_edit?: InputMaybe<Acl_Allocation_Edit_Arr_Rel_Insert_Input>;
  acl_listing_agent_member?: InputMaybe<Acl_Listing_Agent_Member_Arr_Rel_Insert_Input>;
  acl_listing_master_agent?: InputMaybe<Acl_Listing_Master_Agent_Arr_Rel_Insert_Input>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Arr_Rel_Insert_Input>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Arr_Rel_Insert_Input>;
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  block_date?: InputMaybe<Scalars['timestamptz']>;
  block_release_date?: InputMaybe<Scalars['timestamptz']>;
  blocked_by_user?: InputMaybe<Scalars['uuid']>;
  blocked_by_user_rel?: InputMaybe<User_Obj_Rel_Insert_Input>;
  blocked_for_user?: InputMaybe<Scalars['uuid']>;
  blocked_for_user_rel?: InputMaybe<User_Obj_Rel_Insert_Input>;
  car_space?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_status?: InputMaybe<Listing_Status_Obj_Rel_Insert_Input>;
  member_project_allocates?: InputMaybe<Member_Project_Allocate_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['Int']>;
  price_per_sqmt?: InputMaybe<Listing_Price_Per_Sqmt_Obj_Rel_Insert_Input>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_id?: InputMaybe<Scalars['uuid']>;
  release_number?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Listing_Status_Enum>;
  unit_allocation_count?: InputMaybe<Scalars['Int']>;
  unit_allocation_count_master?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "listing" */
export type Listing_Max_Order_By = {
  block_date?: InputMaybe<Order_By>;
  block_release_date?: InputMaybe<Order_By>;
  blocked_by_user?: InputMaybe<Order_By>;
  blocked_for_user?: InputMaybe<Order_By>;
  car_space?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  release_number?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "listing" */
export type Listing_Min_Order_By = {
  block_date?: InputMaybe<Order_By>;
  block_release_date?: InputMaybe<Order_By>;
  blocked_by_user?: InputMaybe<Order_By>;
  blocked_for_user?: InputMaybe<Order_By>;
  car_space?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  release_number?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "listing" */
export type Listing_Obj_Rel_Insert_Input = {
  data: Listing_Insert_Input;
  on_conflict?: InputMaybe<Listing_On_Conflict>;
};

/** on_conflict condition type for table "listing" */
export type Listing_On_Conflict = {
  constraint: Listing_Constraint;
  update_columns: Array<Listing_Update_Column>;
  where?: InputMaybe<Listing_Bool_Exp>;
};

/** Ordering options when selecting data from "listing". */
export type Listing_Order_By = {
  acl_allocation_edit_aggregate?: InputMaybe<Acl_Allocation_Edit_Aggregate_Order_By>;
  acl_listing_agent_member_aggregate?: InputMaybe<Acl_Listing_Agent_Member_Aggregate_Order_By>;
  acl_listing_master_agent_aggregate?: InputMaybe<Acl_Listing_Master_Agent_Aggregate_Order_By>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Order_By>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Order_By>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  block_date?: InputMaybe<Order_By>;
  block_release_date?: InputMaybe<Order_By>;
  blocked_by_user?: InputMaybe<Order_By>;
  blocked_by_user_rel?: InputMaybe<User_Order_By>;
  blocked_for_user?: InputMaybe<Order_By>;
  blocked_for_user_rel?: InputMaybe<User_Order_By>;
  car_space?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_blocked?: InputMaybe<Order_By>;
  listing_status?: InputMaybe<Listing_Status_Order_By>;
  member_project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  price_per_sqmt?: InputMaybe<Listing_Price_Per_Sqmt_Order_By>;
  price_range_from_calc?: InputMaybe<Order_By>;
  price_range_to_calc?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_id?: InputMaybe<Order_By>;
  release_number?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listing */
export type Listing_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "listing_price_per_sqmt". All fields are combined with a logical 'AND'. */
export type Listing_Price_Per_Sqmt_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Price_Per_Sqmt_Bool_Exp>>;
  _not?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Price_Per_Sqmt_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  price_per_sqmt?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "listing_price_per_sqmt" */
export type Listing_Price_Per_Sqmt_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  price_per_sqmt?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting object relation for remote table "listing_price_per_sqmt" */
export type Listing_Price_Per_Sqmt_Obj_Rel_Insert_Input = {
  data: Listing_Price_Per_Sqmt_Insert_Input;
};

/** Ordering options when selecting data from "listing_price_per_sqmt". */
export type Listing_Price_Per_Sqmt_Order_By = {
  id?: InputMaybe<Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  price_per_sqmt?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "listing_price_per_sqmt" */
export type Listing_Price_Per_Sqmt_Stream_Cursor_Input = {
  initial_value: Listing_Price_Per_Sqmt_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Price_Per_Sqmt_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  price_per_sqmt?: InputMaybe<Scalars['numeric']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Listing_Publish_Errors_Append_Input = {
  error_message?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "listing_publish_errors". All fields are combined with a logical 'AND'. */
export type Listing_Publish_Errors_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Publish_Errors_Bool_Exp>>;
  _not?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Publish_Errors_Bool_Exp>>;
  agency_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error_field?: InputMaybe<String_Comparison_Exp>;
  error_message?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Listing_Publish_Errors_Delete_At_Path_Input = {
  error_message?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Listing_Publish_Errors_Delete_Elem_Input = {
  error_message?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Listing_Publish_Errors_Delete_Key_Input = {
  error_message?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "listing_publish_errors" */
export type Listing_Publish_Errors_Inc_Input = {
  agency_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "listing_publish_errors" */
export type Listing_Publish_Errors_Insert_Input = {
  agency_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error_field?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "listing_publish_errors" */
export type Listing_Publish_Errors_On_Conflict = {
  constraint: Listing_Publish_Errors_Constraint;
  update_columns: Array<Listing_Publish_Errors_Update_Column>;
  where?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
};

/** Ordering options when selecting data from "listing_publish_errors". */
export type Listing_Publish_Errors_Order_By = {
  agency_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_field?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listing_id?: InputMaybe<Order_By>;
  portal_source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listing_publish_errors */
export type Listing_Publish_Errors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Listing_Publish_Errors_Prepend_Input = {
  error_message?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "listing_publish_errors" */
export type Listing_Publish_Errors_Set_Input = {
  agency_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error_field?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "listing_publish_errors" */
export type Listing_Publish_Errors_Stream_Cursor_Input = {
  initial_value: Listing_Publish_Errors_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Publish_Errors_Stream_Cursor_Value_Input = {
  agency_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error_field?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Listing_Publish_Errors_Updates = {
  _append?: InputMaybe<Listing_Publish_Errors_Append_Input>;
  _delete_at_path?: InputMaybe<Listing_Publish_Errors_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Listing_Publish_Errors_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Listing_Publish_Errors_Delete_Key_Input>;
  _inc?: InputMaybe<Listing_Publish_Errors_Inc_Input>;
  _prepend?: InputMaybe<Listing_Publish_Errors_Prepend_Input>;
  _set?: InputMaybe<Listing_Publish_Errors_Set_Input>;
  where: Listing_Publish_Errors_Bool_Exp;
};

/** Boolean expression to filter rows from the table "listing_publish_portal_source". All fields are combined with a logical 'AND'. */
export type Listing_Publish_Portal_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Publish_Portal_Source_Bool_Exp>>;
  _not?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Publish_Portal_Source_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "listing_publish_portal_source_enum". All fields are combined with logical 'AND'. */
export type Listing_Publish_Portal_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  _in?: InputMaybe<Array<Listing_Publish_Portal_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  _nin?: InputMaybe<Array<Listing_Publish_Portal_Source_Enum>>;
};

/** input type for inserting data into table "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_On_Conflict = {
  constraint: Listing_Publish_Portal_Source_Constraint;
  update_columns: Array<Listing_Publish_Portal_Source_Update_Column>;
  where?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "listing_publish_portal_source". */
export type Listing_Publish_Portal_Source_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listing_publish_portal_source */
export type Listing_Publish_Portal_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_Stream_Cursor_Input = {
  initial_value: Listing_Publish_Portal_Source_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Publish_Portal_Source_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Listing_Publish_Portal_Source_Updates = {
  _set?: InputMaybe<Listing_Publish_Portal_Source_Set_Input>;
  where: Listing_Publish_Portal_Source_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Listing_Publish_Status_Append_Input = {
  domain_data?: InputMaybe<Scalars['jsonb']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "listing_publish_status". All fields are combined with a logical 'AND'. */
export type Listing_Publish_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Publish_Status_Bool_Exp>>;
  _not?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Publish_Status_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain_data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_test?: InputMaybe<Boolean_Comparison_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum_Comparison_Exp>;
  process_job_id?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  publish_status?: InputMaybe<Listing_Publish_Status_Type_Enum_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  test_xml?: InputMaybe<String_Comparison_Exp>;
  test_xml_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  xml_file_name?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Listing_Publish_Status_Delete_At_Path_Input = {
  domain_data?: InputMaybe<Array<Scalars['String']>>;
  request?: InputMaybe<Array<Scalars['String']>>;
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Listing_Publish_Status_Delete_Elem_Input = {
  domain_data?: InputMaybe<Scalars['Int']>;
  request?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Listing_Publish_Status_Delete_Key_Input = {
  domain_data?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "listing_publish_status" */
export type Listing_Publish_Status_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  process_job_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  publish_status?: InputMaybe<Listing_Publish_Status_Type_Enum>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  test_xml?: InputMaybe<Scalars['String']>;
  test_xml_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  xml_file_name?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "listing_publish_status" */
export type Listing_Publish_Status_On_Conflict = {
  constraint: Listing_Publish_Status_Constraint;
  update_columns: Array<Listing_Publish_Status_Update_Column>;
  where?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "listing_publish_status". */
export type Listing_Publish_Status_Order_By = {
  created_at?: InputMaybe<Order_By>;
  domain_data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_test?: InputMaybe<Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  listing_id?: InputMaybe<Order_By>;
  portal_source?: InputMaybe<Order_By>;
  process_job_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  publish_status?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  test_xml?: InputMaybe<Order_By>;
  test_xml_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  xml_file_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listing_publish_status */
export type Listing_Publish_Status_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Listing_Publish_Status_Prepend_Input = {
  domain_data?: InputMaybe<Scalars['jsonb']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "listing_publish_status" */
export type Listing_Publish_Status_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  process_job_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  publish_status?: InputMaybe<Listing_Publish_Status_Type_Enum>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  test_xml?: InputMaybe<Scalars['String']>;
  test_xml_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  xml_file_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "listing_publish_status" */
export type Listing_Publish_Status_Stream_Cursor_Input = {
  initial_value: Listing_Publish_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Publish_Status_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  portal_source?: InputMaybe<Listing_Publish_Portal_Source_Enum>;
  process_job_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  publish_status?: InputMaybe<Listing_Publish_Status_Type_Enum>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  test_xml?: InputMaybe<Scalars['String']>;
  test_xml_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  xml_file_name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "listing_publish_status_type". All fields are combined with a logical 'AND'. */
export type Listing_Publish_Status_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Publish_Status_Type_Bool_Exp>>;
  _not?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Publish_Status_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "listing_publish_status_type_enum". All fields are combined with logical 'AND'. */
export type Listing_Publish_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Listing_Publish_Status_Type_Enum>;
  _in?: InputMaybe<Array<Listing_Publish_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Listing_Publish_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Listing_Publish_Status_Type_Enum>>;
};

/** input type for inserting data into table "listing_publish_status_type" */
export type Listing_Publish_Status_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "listing_publish_status_type" */
export type Listing_Publish_Status_Type_On_Conflict = {
  constraint: Listing_Publish_Status_Type_Constraint;
  update_columns: Array<Listing_Publish_Status_Type_Update_Column>;
  where?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "listing_publish_status_type". */
export type Listing_Publish_Status_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listing_publish_status_type */
export type Listing_Publish_Status_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "listing_publish_status_type" */
export type Listing_Publish_Status_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "listing_publish_status_type" */
export type Listing_Publish_Status_Type_Stream_Cursor_Input = {
  initial_value: Listing_Publish_Status_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Publish_Status_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Listing_Publish_Status_Type_Updates = {
  _set?: InputMaybe<Listing_Publish_Status_Type_Set_Input>;
  where: Listing_Publish_Status_Type_Bool_Exp;
};

export type Listing_Publish_Status_Updates = {
  _append?: InputMaybe<Listing_Publish_Status_Append_Input>;
  _delete_at_path?: InputMaybe<Listing_Publish_Status_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Listing_Publish_Status_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Listing_Publish_Status_Delete_Key_Input>;
  _prepend?: InputMaybe<Listing_Publish_Status_Prepend_Input>;
  _set?: InputMaybe<Listing_Publish_Status_Set_Input>;
  where: Listing_Publish_Status_Bool_Exp;
};

/** input type for updating data in table "listing" */
export type Listing_Set_Input = {
  block_date?: InputMaybe<Scalars['timestamptz']>;
  block_release_date?: InputMaybe<Scalars['timestamptz']>;
  blocked_by_user?: InputMaybe<Scalars['uuid']>;
  blocked_for_user?: InputMaybe<Scalars['uuid']>;
  car_space?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  release_number?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Listing_Status_Enum>;
  unit_allocation_count?: InputMaybe<Scalars['Int']>;
  unit_allocation_count_master?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "listing_status". All fields are combined with a logical 'AND'. */
export type Listing_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Listing_Status_Bool_Exp>>;
  _not?: InputMaybe<Listing_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Listing_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  listings?: InputMaybe<Listing_Bool_Exp>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "listing_status_enum". All fields are combined with logical 'AND'. */
export type Listing_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Listing_Status_Enum>;
  _in?: InputMaybe<Array<Listing_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Listing_Status_Enum>;
  _nin?: InputMaybe<Array<Listing_Status_Enum>>;
};

/** input type for inserting data into table "listing_status" */
export type Listing_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  listings?: InputMaybe<Listing_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "listing_status" */
export type Listing_Status_Obj_Rel_Insert_Input = {
  data: Listing_Status_Insert_Input;
  on_conflict?: InputMaybe<Listing_Status_On_Conflict>;
};

/** on_conflict condition type for table "listing_status" */
export type Listing_Status_On_Conflict = {
  constraint: Listing_Status_Constraint;
  update_columns: Array<Listing_Status_Update_Column>;
  where?: InputMaybe<Listing_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "listing_status". */
export type Listing_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: listing_status */
export type Listing_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "listing_status" */
export type Listing_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "listing_status" */
export type Listing_Status_Stream_Cursor_Input = {
  initial_value: Listing_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Listing_Status_Updates = {
  _set?: InputMaybe<Listing_Status_Set_Input>;
  where: Listing_Status_Bool_Exp;
};

/** order by stddev() on columns of table "listing" */
export type Listing_Stddev_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "listing" */
export type Listing_Stddev_Pop_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "listing" */
export type Listing_Stddev_Samp_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "listing" */
export type Listing_Stream_Cursor_Input = {
  initial_value: Listing_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Listing_Stream_Cursor_Value_Input = {
  block_date?: InputMaybe<Scalars['timestamptz']>;
  block_release_date?: InputMaybe<Scalars['timestamptz']>;
  blocked_by_user?: InputMaybe<Scalars['uuid']>;
  blocked_for_user?: InputMaybe<Scalars['uuid']>;
  car_space?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  release_number?: InputMaybe<Scalars['String']>;
  sf_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Listing_Status_Enum>;
  unit_allocation_count?: InputMaybe<Scalars['Int']>;
  unit_allocation_count_master?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "listing" */
export type Listing_Sum_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

export type Listing_Updates = {
  _inc?: InputMaybe<Listing_Inc_Input>;
  _set?: InputMaybe<Listing_Set_Input>;
  where: Listing_Bool_Exp;
};

/** order by var_pop() on columns of table "listing" */
export type Listing_Var_Pop_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "listing" */
export type Listing_Var_Samp_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "listing" */
export type Listing_Variance_Order_By = {
  car_space?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Order_By>;
  unit_allocation_count_master?: InputMaybe<Order_By>;
};

export type Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Member_Aggregate_Bool_Exp_Count>;
};

export type Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "member" */
export type Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Member_Max_Order_By>;
  min?: InputMaybe<Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "member" */
export type Member_Arr_Rel_Insert_Input = {
  data: Array<Member_Insert_Input>;
  on_conflict?: InputMaybe<Member_On_Conflict>;
};

/** Boolean expression to filter rows from the table "member". All fields are combined with a logical 'AND'. */
export type Member_Bool_Exp = {
  _and?: InputMaybe<Array<Member_Bool_Exp>>;
  _not?: InputMaybe<Member_Bool_Exp>;
  _or?: InputMaybe<Array<Member_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  features?: InputMaybe<Member_Feature_Bool_Exp>;
  features_aggregate?: InputMaybe<Member_Feature_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  member_role?: InputMaybe<Member_Role_Bool_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_allocates?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
  project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Bool_Exp>;
  role?: InputMaybe<Member_Role_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

export type Member_Feature_Aggregate_Bool_Exp = {
  count?: InputMaybe<Member_Feature_Aggregate_Bool_Exp_Count>;
};

export type Member_Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Member_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Member_Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "member_feature" */
export type Member_Feature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Member_Feature_Max_Order_By>;
  min?: InputMaybe<Member_Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "member_feature" */
export type Member_Feature_Arr_Rel_Insert_Input = {
  data: Array<Member_Feature_Insert_Input>;
  on_conflict?: InputMaybe<Member_Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "member_feature". All fields are combined with a logical 'AND'. */
export type Member_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Member_Feature_Bool_Exp>>;
  _not?: InputMaybe<Member_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Member_Feature_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature?: InputMaybe<User_Feature_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  member?: InputMaybe<Member_Bool_Exp>;
  member_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "member_feature" */
export type Member_Feature_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<User_Feature_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  member?: InputMaybe<Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_feature" */
export type Member_Feature_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "member_feature" */
export type Member_Feature_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "member_feature" */
export type Member_Feature_On_Conflict = {
  constraint: Member_Feature_Constraint;
  update_columns: Array<Member_Feature_Update_Column>;
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "member_feature". */
export type Member_Feature_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: member_feature */
export type Member_Feature_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "member_feature" */
export type Member_Feature_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<User_Feature_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  member_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "member_feature" */
export type Member_Feature_Stream_Cursor_Input = {
  initial_value: Member_Feature_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Member_Feature_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<User_Feature_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  member_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Member_Feature_Updates = {
  _set?: InputMaybe<Member_Feature_Set_Input>;
  where: Member_Feature_Bool_Exp;
};

/** input type for inserting data into table "member" */
export type Member_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  features?: InputMaybe<Member_Feature_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  member_role?: InputMaybe<Member_Role_Obj_Rel_Insert_Input>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_allocates?: InputMaybe<Member_Project_Allocate_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Member_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "member" */
export type Member_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "member" */
export type Member_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "member" */
export type Member_Obj_Rel_Insert_Input = {
  data: Member_Insert_Input;
  on_conflict?: InputMaybe<Member_On_Conflict>;
};

/** on_conflict condition type for table "member" */
export type Member_On_Conflict = {
  constraint: Member_Constraint;
  update_columns: Array<Member_Update_Column>;
  where?: InputMaybe<Member_Bool_Exp>;
};

/** Ordering options when selecting data from "member". */
export type Member_Order_By = {
  created_at?: InputMaybe<Order_By>;
  features_aggregate?: InputMaybe<Member_Feature_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  member_role?: InputMaybe<Member_Role_Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: member */
export type Member_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export type Member_Project_Allocate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Member_Project_Allocate_Aggregate_Bool_Exp_Count>;
};

export type Member_Project_Allocate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "member_project_allocate" */
export type Member_Project_Allocate_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Member_Project_Allocate_Max_Order_By>;
  min?: InputMaybe<Member_Project_Allocate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "member_project_allocate" */
export type Member_Project_Allocate_Arr_Rel_Insert_Input = {
  data: Array<Member_Project_Allocate_Insert_Input>;
  on_conflict?: InputMaybe<Member_Project_Allocate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "member_project_allocate". All fields are combined with a logical 'AND'. */
export type Member_Project_Allocate_Bool_Exp = {
  _and?: InputMaybe<Array<Member_Project_Allocate_Bool_Exp>>;
  _not?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
  _or?: InputMaybe<Array<Member_Project_Allocate_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  member?: InputMaybe<Member_Bool_Exp>;
  member_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "member_project_allocate" */
export type Member_Project_Allocate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  member?: InputMaybe<Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_project_allocate" */
export type Member_Project_Allocate_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "member_project_allocate" */
export type Member_Project_Allocate_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "member_project_allocate" */
export type Member_Project_Allocate_On_Conflict = {
  constraint: Member_Project_Allocate_Constraint;
  update_columns: Array<Member_Project_Allocate_Update_Column>;
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

/** Ordering options when selecting data from "member_project_allocate". */
export type Member_Project_Allocate_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: member_project_allocate */
export type Member_Project_Allocate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "member_project_allocate" */
export type Member_Project_Allocate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  member_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "member_project_allocate" */
export type Member_Project_Allocate_Stream_Cursor_Input = {
  initial_value: Member_Project_Allocate_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Member_Project_Allocate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  member_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Member_Project_Allocate_Updates = {
  _set?: InputMaybe<Member_Project_Allocate_Set_Input>;
  where: Member_Project_Allocate_Bool_Exp;
};

/** Boolean expression to filter rows from the table "member_role". All fields are combined with a logical 'AND'. */
export type Member_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Member_Role_Bool_Exp>>;
  _not?: InputMaybe<Member_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Member_Role_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  invitations?: InputMaybe<Invitation_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Bool_Exp>;
  members?: InputMaybe<Member_Bool_Exp>;
  members_aggregate?: InputMaybe<Member_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "member_role_enum". All fields are combined with logical 'AND'. */
export type Member_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Member_Role_Enum>;
  _in?: InputMaybe<Array<Member_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Member_Role_Enum>;
  _nin?: InputMaybe<Array<Member_Role_Enum>>;
};

/** input type for inserting data into table "member_role" */
export type Member_Role_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  invitations?: InputMaybe<Invitation_Arr_Rel_Insert_Input>;
  members?: InputMaybe<Member_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "member_role" */
export type Member_Role_Obj_Rel_Insert_Input = {
  data: Member_Role_Insert_Input;
  on_conflict?: InputMaybe<Member_Role_On_Conflict>;
};

/** on_conflict condition type for table "member_role" */
export type Member_Role_On_Conflict = {
  constraint: Member_Role_Constraint;
  update_columns: Array<Member_Role_Update_Column>;
  where?: InputMaybe<Member_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "member_role". */
export type Member_Role_Order_By = {
  description?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Order_By>;
  members_aggregate?: InputMaybe<Member_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: member_role */
export type Member_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "member_role" */
export type Member_Role_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "member_role" */
export type Member_Role_Stream_Cursor_Input = {
  initial_value: Member_Role_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Member_Role_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Member_Role_Updates = {
  _set?: InputMaybe<Member_Role_Set_Input>;
  where: Member_Role_Bool_Exp;
};

/** input type for updating data in table "member" */
export type Member_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Member_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "member" */
export type Member_Stream_Cursor_Input = {
  initial_value: Member_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Member_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Member_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Member_Updates = {
  _set?: InputMaybe<Member_Set_Input>;
  where: Member_Bool_Exp;
};

/** Boolean expression to filter rows from the table "nationality". All fields are combined with a logical 'AND'. */
export type Nationality_Bool_Exp = {
  _and?: InputMaybe<Array<Nationality_Bool_Exp>>;
  _not?: InputMaybe<Nationality_Bool_Exp>;
  _or?: InputMaybe<Array<Nationality_Bool_Exp>>;
  contacts?: InputMaybe<Contact_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "nationality" */
export type Nationality_Insert_Input = {
  contacts?: InputMaybe<Contact_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "nationality" */
export type Nationality_Obj_Rel_Insert_Input = {
  data: Nationality_Insert_Input;
  on_conflict?: InputMaybe<Nationality_On_Conflict>;
};

/** on_conflict condition type for table "nationality" */
export type Nationality_On_Conflict = {
  constraint: Nationality_Constraint;
  update_columns: Array<Nationality_Update_Column>;
  where?: InputMaybe<Nationality_Bool_Exp>;
};

/** Ordering options when selecting data from "nationality". */
export type Nationality_Order_By = {
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nationality */
export type Nationality_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "nationality" */
export type Nationality_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nationality" */
export type Nationality_Stream_Cursor_Input = {
  initial_value: Nationality_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nationality_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

export type Nationality_Updates = {
  _set?: InputMaybe<Nationality_Set_Input>;
  where: Nationality_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notification_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Bool_Exp>>;
  _not?: InputMaybe<Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Bool_Exp>>;
  channel?: InputMaybe<Notification_Channel_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dst_id?: InputMaybe<Uuid_Comparison_Exp>;
  dst_organisation?: InputMaybe<Organisation_Bool_Exp>;
  dst_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  dst_type?: InputMaybe<Notification_Owner_Enum_Comparison_Exp>;
  dst_user?: InputMaybe<User_Bool_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  member?: InputMaybe<Member_Bool_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  src_id?: InputMaybe<Uuid_Comparison_Exp>;
  src_type?: InputMaybe<Notification_Owner_Enum_Comparison_Exp>;
  status?: InputMaybe<Process_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Notification_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "notification_channel". All fields are combined with a logical 'AND'. */
export type Notification_Channel_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Channel_Bool_Exp>>;
  _not?: InputMaybe<Notification_Channel_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Channel_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "notification_channel_enum". All fields are combined with logical 'AND'. */
export type Notification_Channel_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Channel_Enum>;
  _in?: InputMaybe<Array<Notification_Channel_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Channel_Enum>;
  _nin?: InputMaybe<Array<Notification_Channel_Enum>>;
};

/** input type for inserting data into table "notification_channel" */
export type Notification_Channel_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "notification_channel" */
export type Notification_Channel_On_Conflict = {
  constraint: Notification_Channel_Constraint;
  update_columns: Array<Notification_Channel_Update_Column>;
  where?: InputMaybe<Notification_Channel_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_channel". */
export type Notification_Channel_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_channel */
export type Notification_Channel_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "notification_channel" */
export type Notification_Channel_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_channel" */
export type Notification_Channel_Stream_Cursor_Input = {
  initial_value: Notification_Channel_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Channel_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Notification_Channel_Updates = {
  _set?: InputMaybe<Notification_Channel_Set_Input>;
  where: Notification_Channel_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notification_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notification_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notification_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  channel?: InputMaybe<Notification_Channel_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dst_id?: InputMaybe<Scalars['uuid']>;
  dst_organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  dst_team_id?: InputMaybe<Scalars['uuid']>;
  dst_type?: InputMaybe<Notification_Owner_Enum>;
  dst_user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  member?: InputMaybe<Member_Obj_Rel_Insert_Input>;
  payload?: InputMaybe<Scalars['jsonb']>;
  read_at?: InputMaybe<Scalars['timestamptz']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  src_id?: InputMaybe<Scalars['uuid']>;
  src_type?: InputMaybe<Notification_Owner_Enum>;
  status?: InputMaybe<Process_Status_Enum>;
  type?: InputMaybe<Notification_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns: Array<Notification_Update_Column>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dst_id?: InputMaybe<Order_By>;
  dst_organisation?: InputMaybe<Organisation_Order_By>;
  dst_team_id?: InputMaybe<Order_By>;
  dst_type?: InputMaybe<Order_By>;
  dst_user?: InputMaybe<User_Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Member_Order_By>;
  payload?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  src_id?: InputMaybe<Order_By>;
  src_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "notification_owner". All fields are combined with a logical 'AND'. */
export type Notification_Owner_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Owner_Bool_Exp>>;
  _not?: InputMaybe<Notification_Owner_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Owner_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "notification_owner_enum". All fields are combined with logical 'AND'. */
export type Notification_Owner_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Owner_Enum>;
  _in?: InputMaybe<Array<Notification_Owner_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Owner_Enum>;
  _nin?: InputMaybe<Array<Notification_Owner_Enum>>;
};

/** input type for inserting data into table "notification_owner" */
export type Notification_Owner_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "notification_owner" */
export type Notification_Owner_On_Conflict = {
  constraint: Notification_Owner_Constraint;
  update_columns: Array<Notification_Owner_Update_Column>;
  where?: InputMaybe<Notification_Owner_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_owner". */
export type Notification_Owner_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_owner */
export type Notification_Owner_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "notification_owner" */
export type Notification_Owner_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_owner" */
export type Notification_Owner_Stream_Cursor_Input = {
  initial_value: Notification_Owner_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Owner_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Notification_Owner_Updates = {
  _set?: InputMaybe<Notification_Owner_Set_Input>;
  where: Notification_Owner_Bool_Exp;
};

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notification_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  channel?: InputMaybe<Notification_Channel_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dst_id?: InputMaybe<Scalars['uuid']>;
  dst_team_id?: InputMaybe<Scalars['uuid']>;
  dst_type?: InputMaybe<Notification_Owner_Enum>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  read_at?: InputMaybe<Scalars['timestamptz']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  src_id?: InputMaybe<Scalars['uuid']>;
  src_type?: InputMaybe<Notification_Owner_Enum>;
  status?: InputMaybe<Process_Status_Enum>;
  type?: InputMaybe<Notification_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "notification" */
export type Notification_Stream_Cursor_Input = {
  initial_value: Notification_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Stream_Cursor_Value_Input = {
  channel?: InputMaybe<Notification_Channel_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dst_id?: InputMaybe<Scalars['uuid']>;
  dst_team_id?: InputMaybe<Scalars['uuid']>;
  dst_type?: InputMaybe<Notification_Owner_Enum>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  read_at?: InputMaybe<Scalars['timestamptz']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  src_id?: InputMaybe<Scalars['uuid']>;
  src_type?: InputMaybe<Notification_Owner_Enum>;
  status?: InputMaybe<Process_Status_Enum>;
  type?: InputMaybe<Notification_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export type Notification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  _not?: InputMaybe<Notification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "notification_type_enum". All fields are combined with logical 'AND'. */
export type Notification_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Type_Enum>;
  _in?: InputMaybe<Array<Notification_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notification_Type_Enum>;
  _nin?: InputMaybe<Array<Notification_Type_Enum>>;
};

/** input type for inserting data into table "notification_type" */
export type Notification_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "notification_type" */
export type Notification_Type_On_Conflict = {
  constraint: Notification_Type_Constraint;
  update_columns: Array<Notification_Type_Update_Column>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_type". */
export type Notification_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_type */
export type Notification_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "notification_type" */
export type Notification_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_type" */
export type Notification_Type_Stream_Cursor_Input = {
  initial_value: Notification_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Notification_Type_Updates = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  where: Notification_Type_Bool_Exp;
};

export type Notification_Updates = {
  _append?: InputMaybe<Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  where: Notification_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

export type Org_Feature_Check_Args = {
  feature_arg?: InputMaybe<Scalars['String']>;
  org_id_arg?: InputMaybe<Scalars['uuid']>;
};

export type Org_Feature_Enabled_Aggregate_Bool_Exp = {
  count?: InputMaybe<Org_Feature_Enabled_Aggregate_Bool_Exp_Count>;
};

export type Org_Feature_Enabled_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "org_feature_enabled" */
export type Org_Feature_Enabled_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Org_Feature_Enabled_Max_Order_By>;
  min?: InputMaybe<Org_Feature_Enabled_Min_Order_By>;
};

/** input type for inserting array relation for remote table "org_feature_enabled" */
export type Org_Feature_Enabled_Arr_Rel_Insert_Input = {
  data: Array<Org_Feature_Enabled_Insert_Input>;
};

/** Boolean expression to filter rows from the table "org_feature_enabled". All fields are combined with a logical 'AND'. */
export type Org_Feature_Enabled_Bool_Exp = {
  _and?: InputMaybe<Array<Org_Feature_Enabled_Bool_Exp>>;
  _not?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
  _or?: InputMaybe<Array<Org_Feature_Enabled_Bool_Exp>>;
  feature?: InputMaybe<String_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "org_feature_enabled" */
export type Org_Feature_Enabled_Insert_Input = {
  feature?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "org_feature_enabled" */
export type Org_Feature_Enabled_Max_Order_By = {
  feature?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "org_feature_enabled" */
export type Org_Feature_Enabled_Min_Order_By = {
  feature?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "org_feature_enabled". */
export type Org_Feature_Enabled_Order_By = {
  feature?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "org_feature_enabled" */
export type Org_Feature_Enabled_Stream_Cursor_Input = {
  initial_value: Org_Feature_Enabled_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Org_Feature_Enabled_Stream_Cursor_Value_Input = {
  feature?: InputMaybe<Scalars['String']>;
  org_id?: InputMaybe<Scalars['uuid']>;
};

export type Org_Feature_Pack_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Org_Feature_Pack_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Org_Feature_Pack_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Org_Feature_Pack_Aggregate_Bool_Exp_Count>;
};

export type Org_Feature_Pack_Aggregate_Bool_Exp_Bool_And = {
  arguments: Org_Feature_Pack_Select_Column_Org_Feature_Pack_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Org_Feature_Pack_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Org_Feature_Pack_Select_Column_Org_Feature_Pack_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Org_Feature_Pack_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "org_feature_pack" */
export type Org_Feature_Pack_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Org_Feature_Pack_Max_Order_By>;
  min?: InputMaybe<Org_Feature_Pack_Min_Order_By>;
};

/** input type for inserting array relation for remote table "org_feature_pack" */
export type Org_Feature_Pack_Arr_Rel_Insert_Input = {
  data: Array<Org_Feature_Pack_Insert_Input>;
  on_conflict?: InputMaybe<Org_Feature_Pack_On_Conflict>;
};

/** Boolean expression to filter rows from the table "org_feature_pack". All fields are combined with a logical 'AND'. */
export type Org_Feature_Pack_Bool_Exp = {
  _and?: InputMaybe<Array<Org_Feature_Pack_Bool_Exp>>;
  _not?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
  _or?: InputMaybe<Array<Org_Feature_Pack_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature_pack?: InputMaybe<Feature_Pack_Bool_Exp>;
  feature_pack_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "org_feature_pack" */
export type Org_Feature_Pack_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature_pack?: InputMaybe<Feature_Pack_Obj_Rel_Insert_Input>;
  feature_pack_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "org_feature_pack" */
export type Org_Feature_Pack_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_pack_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "org_feature_pack" */
export type Org_Feature_Pack_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_pack_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "org_feature_pack" */
export type Org_Feature_Pack_On_Conflict = {
  constraint: Org_Feature_Pack_Constraint;
  update_columns: Array<Org_Feature_Pack_Update_Column>;
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

/** Ordering options when selecting data from "org_feature_pack". */
export type Org_Feature_Pack_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature_pack?: InputMaybe<Feature_Pack_Order_By>;
  feature_pack_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: org_feature_pack */
export type Org_Feature_Pack_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "org_feature_pack" */
export type Org_Feature_Pack_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature_pack_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "org_feature_pack" */
export type Org_Feature_Pack_Stream_Cursor_Input = {
  initial_value: Org_Feature_Pack_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Org_Feature_Pack_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature_pack_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Org_Feature_Pack_Updates = {
  _set?: InputMaybe<Org_Feature_Pack_Set_Input>;
  where: Org_Feature_Pack_Bool_Exp;
};

export type Organisation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organisation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organisation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organisation_Aggregate_Bool_Exp_Count>;
};

export type Organisation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "organisation" */
export type Organisation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organisation_Max_Order_By>;
  min?: InputMaybe<Organisation_Min_Order_By>;
};

export type Organisation_Allocation_Counts_View_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organisation_Allocation_Counts_View_Aggregate_Bool_Exp_Count>;
};

export type Organisation_Allocation_Counts_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Aggregate_Order_By = {
  avg?: InputMaybe<Organisation_Allocation_Counts_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organisation_Allocation_Counts_View_Max_Order_By>;
  min?: InputMaybe<Organisation_Allocation_Counts_View_Min_Order_By>;
  stddev?: InputMaybe<Organisation_Allocation_Counts_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organisation_Allocation_Counts_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organisation_Allocation_Counts_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organisation_Allocation_Counts_View_Sum_Order_By>;
  var_pop?: InputMaybe<Organisation_Allocation_Counts_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organisation_Allocation_Counts_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Organisation_Allocation_Counts_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Arr_Rel_Insert_Input = {
  data: Array<Organisation_Allocation_Counts_View_Insert_Input>;
};

/** order by avg() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Avg_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organisation_allocation_counts_view". All fields are combined with a logical 'AND'. */
export type Organisation_Allocation_Counts_View_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Allocation_Counts_View_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Allocation_Counts_View_Bool_Exp>>;
  exclusive_count?: InputMaybe<Bigint_Comparison_Exp>;
  open_count?: InputMaybe<Bigint_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  shared_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Insert_Input = {
  exclusive_count?: InputMaybe<Scalars['bigint']>;
  open_count?: InputMaybe<Scalars['bigint']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  shared_count?: InputMaybe<Scalars['bigint']>;
};

/** order by max() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Max_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Min_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "organisation_allocation_counts_view". */
export type Organisation_Allocation_Counts_View_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by stddev() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Stddev_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Stddev_Pop_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Stddev_Samp_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Stream_Cursor_Input = {
  initial_value: Organisation_Allocation_Counts_View_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Allocation_Counts_View_Stream_Cursor_Value_Input = {
  exclusive_count?: InputMaybe<Scalars['bigint']>;
  open_count?: InputMaybe<Scalars['bigint']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  shared_count?: InputMaybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Sum_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Var_Pop_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Var_Samp_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Variance_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organisation_Append_Input = {
  nsw_api_verify?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organisation" */
export type Organisation_Arr_Rel_Insert_Input = {
  data: Array<Organisation_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organisation". All fields are combined with a logical 'AND'. */
export type Organisation_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Bool_Exp>>;
  abn?: InputMaybe<String_Comparison_Exp>;
  acl_features?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
  acl_features_aggregate?: InputMaybe<Acl_Org_Feature_Enabled_Aggregate_Bool_Exp>;
  acl_org_agent_member?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
  acl_org_agent_member_aggregate?: InputMaybe<Acl_Org_Agent_Member_Aggregate_Bool_Exp>;
  acl_org_developer_member?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
  acl_org_developer_member_aggregate?: InputMaybe<Acl_Org_Developer_Member_Aggregate_Bool_Exp>;
  acl_org_master_agent?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
  acl_org_master_agent_aggregate?: InputMaybe<Acl_Org_Master_Agent_Aggregate_Bool_Exp>;
  acn?: InputMaybe<String_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  address_city_suburb?: InputMaybe<String_Comparison_Exp>;
  address_country?: InputMaybe<String_Comparison_Exp>;
  address_line_1?: InputMaybe<String_Comparison_Exp>;
  address_postcode?: InputMaybe<String_Comparison_Exp>;
  address_state?: InputMaybe<String_Comparison_Exp>;
  agency_agreements?: InputMaybe<Agency_Agreement_Bool_Exp>;
  agency_agreements_aggregate?: InputMaybe<Agency_Agreement_Aggregate_Bool_Exp>;
  agreements?: InputMaybe<Agreement_Bool_Exp>;
  agreements_aggregate?: InputMaybe<Agreement_Aggregate_Bool_Exp>;
  allocation_counts?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
  allocation_counts_aggregate?: InputMaybe<Organisation_Allocation_Counts_View_Aggregate_Bool_Exp>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  contacts?: InputMaybe<Contact_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  created_by_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_contacts?: InputMaybe<Contact_Bool_Exp>;
  created_contacts_aggregate?: InputMaybe<Contact_Aggregate_Bool_Exp>;
  dealsByAgencyId?: InputMaybe<Deal_Bool_Exp>;
  dealsByAgencyId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsByPurchaserSolicitorId?: InputMaybe<Deal_Bool_Exp>;
  dealsByPurchaserSolicitorId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsBySubAgencyId?: InputMaybe<Deal_Bool_Exp>;
  dealsBySubAgencyId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  dealsByVendorSolicitorId?: InputMaybe<Deal_Bool_Exp>;
  dealsByVendorSolicitorId_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  delegated_projects?: InputMaybe<Project_Bool_Exp>;
  delegated_projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  developer_agents_by_agent?: InputMaybe<Developer_Agent_Bool_Exp>;
  developer_agents_by_agent_aggregate?: InputMaybe<Developer_Agent_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  enabled_features?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
  enabled_features_aggregate?: InputMaybe<Org_Feature_Enabled_Aggregate_Bool_Exp>;
  favicon_id?: InputMaybe<Uuid_Comparison_Exp>;
  feature_packs?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
  feature_packs_aggregate?: InputMaybe<Org_Feature_Pack_Aggregate_Bool_Exp>;
  features?: InputMaybe<Organisation_Feature_Bool_Exp>;
  features_aggregate?: InputMaybe<Organisation_Feature_Aggregate_Bool_Exp>;
  full_address?: InputMaybe<String_Comparison_Exp>;
  hubspot?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
  hubspot_aggregate?: InputMaybe<Hubspot_Organisation_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitation_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Bool_Exp>;
  is_setup?: InputMaybe<Boolean_Comparison_Exp>;
  is_user_team?: InputMaybe<Boolean_Comparison_Exp>;
  licence_number?: InputMaybe<String_Comparison_Exp>;
  licence_state_of_issue?: InputMaybe<String_Comparison_Exp>;
  licensee_contact?: InputMaybe<Contact_Bool_Exp>;
  licensee_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  logo_id?: InputMaybe<Uuid_Comparison_Exp>;
  logo_url?: InputMaybe<String_Comparison_Exp>;
  main_contact?: InputMaybe<Contact_Bool_Exp>;
  main_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Member_Bool_Exp>;
  members_aggregate?: InputMaybe<Member_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nsw_api_verify?: InputMaybe<Jsonb_Comparison_Exp>;
  organisation_developer_agents?: InputMaybe<Developer_Agent_Bool_Exp>;
  organisation_developer_agents_aggregate?: InputMaybe<Developer_Agent_Aggregate_Bool_Exp>;
  organisation_favicon?: InputMaybe<Attachment_Bool_Exp>;
  organisation_logo?: InputMaybe<Attachment_Bool_Exp>;
  organisation_type?: InputMaybe<Organisation_Type_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  primary_contact_sf?: InputMaybe<Sf_Contact_Bool_Exp>;
  primary_contact_sf_id?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projectsByVendorSolicitorId?: InputMaybe<Project_Bool_Exp>;
  projectsByVendorSolicitorId_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  propertyGroupsByVendorSolicitor?: InputMaybe<Property_Group_Bool_Exp>;
  propertyGroupsByVendorSolicitor_aggregate?: InputMaybe<Property_Group_Aggregate_Bool_Exp>;
  property_allocations?: InputMaybe<Property_Allocation_Bool_Exp>;
  property_allocations_aggregate?: InputMaybe<Property_Allocation_Aggregate_Bool_Exp>;
  property_groups?: InputMaybe<Property_Group_Bool_Exp>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Bool_Exp>;
  registered_for_gst?: InputMaybe<Boolean_Comparison_Exp>;
  salesforce?: InputMaybe<Sf_Organisation_Bool_Exp>;
  salesforce_aggregate?: InputMaybe<Sf_Organisation_Aggregate_Bool_Exp>;
  type?: InputMaybe<Organisation_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organisation_Delete_At_Path_Input = {
  nsw_api_verify?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organisation_Delete_Elem_Input = {
  nsw_api_verify?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organisation_Delete_Key_Input = {
  nsw_api_verify?: InputMaybe<Scalars['String']>;
};

export type Organisation_Feature_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organisation_Feature_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organisation_Feature_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organisation_Feature_Aggregate_Bool_Exp_Count>;
};

export type Organisation_Feature_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organisation_Feature_Select_Column_Organisation_Feature_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Feature_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Feature_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organisation_Feature_Select_Column_Organisation_Feature_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Feature_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Feature_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Organisation_Feature_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "organisation_feature" */
export type Organisation_Feature_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organisation_Feature_Max_Order_By>;
  min?: InputMaybe<Organisation_Feature_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organisation_feature" */
export type Organisation_Feature_Arr_Rel_Insert_Input = {
  data: Array<Organisation_Feature_Insert_Input>;
  on_conflict?: InputMaybe<Organisation_Feature_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organisation_feature". All fields are combined with a logical 'AND'. */
export type Organisation_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Feature_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Feature_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature?: InputMaybe<Web_App_Feature_Enum_Comparison_Exp>;
  feature_obj?: InputMaybe<Web_App_Feature_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "organisation_feature" */
export type Organisation_Feature_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Web_App_Feature_Enum>;
  feature_obj?: InputMaybe<Web_App_Feature_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organisation_feature" */
export type Organisation_Feature_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organisation_feature" */
export type Organisation_Feature_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "organisation_feature" */
export type Organisation_Feature_On_Conflict = {
  constraint: Organisation_Feature_Constraint;
  update_columns: Array<Organisation_Feature_Update_Column>;
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_feature". */
export type Organisation_Feature_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  feature_obj?: InputMaybe<Web_App_Feature_Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_feature */
export type Organisation_Feature_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "organisation_feature" */
export type Organisation_Feature_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Web_App_Feature_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "organisation_feature" */
export type Organisation_Feature_Stream_Cursor_Input = {
  initial_value: Organisation_Feature_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Feature_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Web_App_Feature_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Organisation_Feature_Updates = {
  _set?: InputMaybe<Organisation_Feature_Set_Input>;
  where: Organisation_Feature_Bool_Exp;
};

/** input type for inserting data into table "organisation" */
export type Organisation_Insert_Input = {
  abn?: InputMaybe<Scalars['String']>;
  acl_features?: InputMaybe<Acl_Org_Feature_Enabled_Arr_Rel_Insert_Input>;
  acl_org_agent_member?: InputMaybe<Acl_Org_Agent_Member_Arr_Rel_Insert_Input>;
  acl_org_developer_member?: InputMaybe<Acl_Org_Developer_Member_Arr_Rel_Insert_Input>;
  acl_org_master_agent?: InputMaybe<Acl_Org_Master_Agent_Arr_Rel_Insert_Input>;
  acn?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  address_city_suburb?: InputMaybe<Scalars['String']>;
  address_country?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_postcode?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  agency_agreements?: InputMaybe<Agency_Agreement_Arr_Rel_Insert_Input>;
  agreements?: InputMaybe<Agreement_Arr_Rel_Insert_Input>;
  allocation_counts?: InputMaybe<Organisation_Allocation_Counts_View_Arr_Rel_Insert_Input>;
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  contacts?: InputMaybe<Contact_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  created_contacts?: InputMaybe<Contact_Arr_Rel_Insert_Input>;
  dealsByAgencyId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsByPurchaserSolicitorId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsBySubAgencyId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  dealsByVendorSolicitorId?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  delegated_projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  developer_agents_by_agent?: InputMaybe<Developer_Agent_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  enabled_features?: InputMaybe<Org_Feature_Enabled_Arr_Rel_Insert_Input>;
  favicon_id?: InputMaybe<Scalars['uuid']>;
  feature_packs?: InputMaybe<Org_Feature_Pack_Arr_Rel_Insert_Input>;
  features?: InputMaybe<Organisation_Feature_Arr_Rel_Insert_Input>;
  hubspot?: InputMaybe<Hubspot_Organisation_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  invitations?: InputMaybe<Invitation_Arr_Rel_Insert_Input>;
  is_setup?: InputMaybe<Scalars['Boolean']>;
  is_user_team?: InputMaybe<Scalars['Boolean']>;
  licence_number?: InputMaybe<Scalars['String']>;
  licence_state_of_issue?: InputMaybe<Scalars['String']>;
  licensee_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  licensee_contact_id?: InputMaybe<Scalars['uuid']>;
  logo_id?: InputMaybe<Scalars['uuid']>;
  logo_url?: InputMaybe<Scalars['String']>;
  main_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  main_contact_id?: InputMaybe<Scalars['uuid']>;
  members?: InputMaybe<Member_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  nsw_api_verify?: InputMaybe<Scalars['jsonb']>;
  organisation_developer_agents?: InputMaybe<Developer_Agent_Arr_Rel_Insert_Input>;
  organisation_favicon?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  organisation_logo?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  organisation_type?: InputMaybe<Organisation_Type_Obj_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  primary_contact_sf?: InputMaybe<Sf_Contact_Obj_Rel_Insert_Input>;
  primary_contact_sf_id?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  projectsByVendorSolicitorId?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  propertyGroupsByVendorSolicitor?: InputMaybe<Property_Group_Arr_Rel_Insert_Input>;
  property_allocations?: InputMaybe<Property_Allocation_Arr_Rel_Insert_Input>;
  property_groups?: InputMaybe<Property_Group_Arr_Rel_Insert_Input>;
  registered_for_gst?: InputMaybe<Scalars['Boolean']>;
  salesforce?: InputMaybe<Sf_Organisation_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Organisation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "organisation" */
export type Organisation_Max_Order_By = {
  abn?: InputMaybe<Order_By>;
  acn?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  address_city_suburb?: InputMaybe<Order_By>;
  address_country?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_postcode?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  favicon_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  licence_state_of_issue?: InputMaybe<Order_By>;
  licensee_contact_id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  main_contact_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  primary_contact_sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organisation" */
export type Organisation_Min_Order_By = {
  abn?: InputMaybe<Order_By>;
  acn?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  address_city_suburb?: InputMaybe<Order_By>;
  address_country?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_postcode?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  favicon_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  licence_state_of_issue?: InputMaybe<Order_By>;
  licensee_contact_id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  main_contact_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  primary_contact_sf_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "organisation" */
export type Organisation_Obj_Rel_Insert_Input = {
  data: Organisation_Insert_Input;
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};

/** on_conflict condition type for table "organisation" */
export type Organisation_On_Conflict = {
  constraint: Organisation_Constraint;
  update_columns: Array<Organisation_Update_Column>;
  where?: InputMaybe<Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation". */
export type Organisation_Order_By = {
  abn?: InputMaybe<Order_By>;
  acl_features_aggregate?: InputMaybe<Acl_Org_Feature_Enabled_Aggregate_Order_By>;
  acl_org_agent_member_aggregate?: InputMaybe<Acl_Org_Agent_Member_Aggregate_Order_By>;
  acl_org_developer_member_aggregate?: InputMaybe<Acl_Org_Developer_Member_Aggregate_Order_By>;
  acl_org_master_agent_aggregate?: InputMaybe<Acl_Org_Master_Agent_Aggregate_Order_By>;
  acn?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  address_city_suburb?: InputMaybe<Order_By>;
  address_country?: InputMaybe<Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_postcode?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  agency_agreements_aggregate?: InputMaybe<Agency_Agreement_Aggregate_Order_By>;
  agreements_aggregate?: InputMaybe<Agreement_Aggregate_Order_By>;
  allocation_counts_aggregate?: InputMaybe<Organisation_Allocation_Counts_View_Aggregate_Order_By>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  created_by_team_id?: InputMaybe<Order_By>;
  created_contacts_aggregate?: InputMaybe<Contact_Aggregate_Order_By>;
  dealsByAgencyId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsByPurchaserSolicitorId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsBySubAgencyId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  dealsByVendorSolicitorId_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  delegated_projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  developer_agents_by_agent_aggregate?: InputMaybe<Developer_Agent_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  enabled_features_aggregate?: InputMaybe<Org_Feature_Enabled_Aggregate_Order_By>;
  favicon_id?: InputMaybe<Order_By>;
  feature_packs_aggregate?: InputMaybe<Org_Feature_Pack_Aggregate_Order_By>;
  features_aggregate?: InputMaybe<Organisation_Feature_Aggregate_Order_By>;
  full_address?: InputMaybe<Order_By>;
  hubspot_aggregate?: InputMaybe<Hubspot_Organisation_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Order_By>;
  is_setup?: InputMaybe<Order_By>;
  is_user_team?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  licence_state_of_issue?: InputMaybe<Order_By>;
  licensee_contact?: InputMaybe<Contact_Order_By>;
  licensee_contact_id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  main_contact?: InputMaybe<Contact_Order_By>;
  main_contact_id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Member_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  nsw_api_verify?: InputMaybe<Order_By>;
  organisation_developer_agents_aggregate?: InputMaybe<Developer_Agent_Aggregate_Order_By>;
  organisation_favicon?: InputMaybe<Attachment_Order_By>;
  organisation_logo?: InputMaybe<Attachment_Order_By>;
  organisation_type?: InputMaybe<Organisation_Type_Order_By>;
  phone?: InputMaybe<Order_By>;
  primary_contact_sf?: InputMaybe<Sf_Contact_Order_By>;
  primary_contact_sf_id?: InputMaybe<Order_By>;
  projectsByVendorSolicitorId_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  propertyGroupsByVendorSolicitor_aggregate?: InputMaybe<Property_Group_Aggregate_Order_By>;
  property_allocations_aggregate?: InputMaybe<Property_Allocation_Aggregate_Order_By>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Order_By>;
  registered_for_gst?: InputMaybe<Order_By>;
  salesforce_aggregate?: InputMaybe<Sf_Organisation_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation */
export type Organisation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organisation_Prepend_Input = {
  nsw_api_verify?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "organisation" */
export type Organisation_Set_Input = {
  abn?: InputMaybe<Scalars['String']>;
  acn?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  address_city_suburb?: InputMaybe<Scalars['String']>;
  address_country?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_postcode?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  favicon_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_setup?: InputMaybe<Scalars['Boolean']>;
  is_user_team?: InputMaybe<Scalars['Boolean']>;
  licence_number?: InputMaybe<Scalars['String']>;
  licence_state_of_issue?: InputMaybe<Scalars['String']>;
  licensee_contact_id?: InputMaybe<Scalars['uuid']>;
  logo_id?: InputMaybe<Scalars['uuid']>;
  logo_url?: InputMaybe<Scalars['String']>;
  main_contact_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nsw_api_verify?: InputMaybe<Scalars['jsonb']>;
  phone?: InputMaybe<Scalars['String']>;
  primary_contact_sf_id?: InputMaybe<Scalars['String']>;
  registered_for_gst?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Organisation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "organisation" */
export type Organisation_Stream_Cursor_Input = {
  initial_value: Organisation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Stream_Cursor_Value_Input = {
  abn?: InputMaybe<Scalars['String']>;
  acn?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  address_city_suburb?: InputMaybe<Scalars['String']>;
  address_country?: InputMaybe<Scalars['String']>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_postcode?: InputMaybe<Scalars['String']>;
  address_state?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  created_by_team_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  favicon_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_setup?: InputMaybe<Scalars['Boolean']>;
  is_user_team?: InputMaybe<Scalars['Boolean']>;
  licence_number?: InputMaybe<Scalars['String']>;
  licence_state_of_issue?: InputMaybe<Scalars['String']>;
  licensee_contact_id?: InputMaybe<Scalars['uuid']>;
  logo_id?: InputMaybe<Scalars['uuid']>;
  logo_url?: InputMaybe<Scalars['String']>;
  main_contact_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nsw_api_verify?: InputMaybe<Scalars['jsonb']>;
  phone?: InputMaybe<Scalars['String']>;
  primary_contact_sf_id?: InputMaybe<Scalars['String']>;
  registered_for_gst?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Organisation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "organisation_type". All fields are combined with a logical 'AND'. */
export type Organisation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Organisation_Type_Bool_Exp>>;
  _not?: InputMaybe<Organisation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Organisation_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  organisations?: InputMaybe<Organisation_Bool_Exp>;
  organisations_aggregate?: InputMaybe<Organisation_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "organisation_type_enum". All fields are combined with logical 'AND'. */
export type Organisation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Organisation_Type_Enum>;
  _in?: InputMaybe<Array<Organisation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Organisation_Type_Enum>;
  _nin?: InputMaybe<Array<Organisation_Type_Enum>>;
};

/** input type for inserting data into table "organisation_type" */
export type Organisation_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  organisations?: InputMaybe<Organisation_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "organisation_type" */
export type Organisation_Type_Obj_Rel_Insert_Input = {
  data: Organisation_Type_Insert_Input;
  on_conflict?: InputMaybe<Organisation_Type_On_Conflict>;
};

/** on_conflict condition type for table "organisation_type" */
export type Organisation_Type_On_Conflict = {
  constraint: Organisation_Type_Constraint;
  update_columns: Array<Organisation_Type_Update_Column>;
  where?: InputMaybe<Organisation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation_type". */
export type Organisation_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  organisations_aggregate?: InputMaybe<Organisation_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organisation_type */
export type Organisation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "organisation_type" */
export type Organisation_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "organisation_type" */
export type Organisation_Type_Stream_Cursor_Input = {
  initial_value: Organisation_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Organisation_Type_Updates = {
  _set?: InputMaybe<Organisation_Type_Set_Input>;
  where: Organisation_Type_Bool_Exp;
};

export type Organisation_Updates = {
  _append?: InputMaybe<Organisation_Append_Input>;
  _delete_at_path?: InputMaybe<Organisation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organisation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organisation_Delete_Key_Input>;
  _prepend?: InputMaybe<Organisation_Prepend_Input>;
  _set?: InputMaybe<Organisation_Set_Input>;
  where: Organisation_Bool_Exp;
};

/** Boolean expression to filter rows from the table "partner_portal". All fields are combined with a logical 'AND'. */
export type Partner_Portal_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Portal_Bool_Exp>>;
  _not?: InputMaybe<Partner_Portal_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Portal_Bool_Exp>>;
  abn?: InputMaybe<String_Comparison_Exp>;
  agency_org_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_address_city_suburb?: InputMaybe<String_Comparison_Exp>;
  company_address_country?: InputMaybe<String_Comparison_Exp>;
  company_address_line_1?: InputMaybe<String_Comparison_Exp>;
  company_address_postcode?: InputMaybe<String_Comparison_Exp>;
  company_address_state?: InputMaybe<String_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitation?: InputMaybe<Invitation_Bool_Exp>;
  invitation_id?: InputMaybe<Uuid_Comparison_Exp>;
  licence_holder_address_city_suburb?: InputMaybe<String_Comparison_Exp>;
  licence_holder_address_country?: InputMaybe<String_Comparison_Exp>;
  licence_holder_address_line_1?: InputMaybe<String_Comparison_Exp>;
  licence_holder_address_postcode?: InputMaybe<String_Comparison_Exp>;
  licence_holder_address_state?: InputMaybe<String_Comparison_Exp>;
  licence_holder_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  licence_holder_email?: InputMaybe<String_Comparison_Exp>;
  licence_holder_first_name?: InputMaybe<String_Comparison_Exp>;
  licence_holder_last_name?: InputMaybe<String_Comparison_Exp>;
  licence_holder_phone?: InputMaybe<String_Comparison_Exp>;
  licence_number?: InputMaybe<String_Comparison_Exp>;
  main_contact_email?: InputMaybe<String_Comparison_Exp>;
  main_contact_first_name?: InputMaybe<String_Comparison_Exp>;
  main_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  main_contact_last_name?: InputMaybe<String_Comparison_Exp>;
  main_contact_phone?: InputMaybe<String_Comparison_Exp>;
  new_agency_org_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "partner_portal" */
export type Partner_Portal_Insert_Input = {
  abn?: InputMaybe<Scalars['String']>;
  agency_org_id?: InputMaybe<Scalars['uuid']>;
  company_address_city_suburb?: InputMaybe<Scalars['String']>;
  company_address_country?: InputMaybe<Scalars['String']>;
  company_address_line_1?: InputMaybe<Scalars['String']>;
  company_address_postcode?: InputMaybe<Scalars['String']>;
  company_address_state?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitation?: InputMaybe<Invitation_Obj_Rel_Insert_Input>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  licence_holder_address_city_suburb?: InputMaybe<Scalars['String']>;
  licence_holder_address_country?: InputMaybe<Scalars['String']>;
  licence_holder_address_line_1?: InputMaybe<Scalars['String']>;
  licence_holder_address_postcode?: InputMaybe<Scalars['String']>;
  licence_holder_address_state?: InputMaybe<Scalars['String']>;
  licence_holder_contact_id?: InputMaybe<Scalars['uuid']>;
  licence_holder_email?: InputMaybe<Scalars['String']>;
  licence_holder_first_name?: InputMaybe<Scalars['String']>;
  licence_holder_last_name?: InputMaybe<Scalars['String']>;
  licence_holder_phone?: InputMaybe<Scalars['String']>;
  licence_number?: InputMaybe<Scalars['String']>;
  main_contact_email?: InputMaybe<Scalars['String']>;
  main_contact_first_name?: InputMaybe<Scalars['String']>;
  main_contact_id?: InputMaybe<Scalars['uuid']>;
  main_contact_last_name?: InputMaybe<Scalars['String']>;
  main_contact_phone?: InputMaybe<Scalars['String']>;
  new_agency_org_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "partner_portal" */
export type Partner_Portal_Obj_Rel_Insert_Input = {
  data: Partner_Portal_Insert_Input;
  on_conflict?: InputMaybe<Partner_Portal_On_Conflict>;
};

/** on_conflict condition type for table "partner_portal" */
export type Partner_Portal_On_Conflict = {
  constraint: Partner_Portal_Constraint;
  update_columns: Array<Partner_Portal_Update_Column>;
  where?: InputMaybe<Partner_Portal_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_portal". */
export type Partner_Portal_Order_By = {
  abn?: InputMaybe<Order_By>;
  agency_org_id?: InputMaybe<Order_By>;
  company_address_city_suburb?: InputMaybe<Order_By>;
  company_address_country?: InputMaybe<Order_By>;
  company_address_line_1?: InputMaybe<Order_By>;
  company_address_postcode?: InputMaybe<Order_By>;
  company_address_state?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation?: InputMaybe<Invitation_Order_By>;
  invitation_id?: InputMaybe<Order_By>;
  licence_holder_address_city_suburb?: InputMaybe<Order_By>;
  licence_holder_address_country?: InputMaybe<Order_By>;
  licence_holder_address_line_1?: InputMaybe<Order_By>;
  licence_holder_address_postcode?: InputMaybe<Order_By>;
  licence_holder_address_state?: InputMaybe<Order_By>;
  licence_holder_contact_id?: InputMaybe<Order_By>;
  licence_holder_email?: InputMaybe<Order_By>;
  licence_holder_first_name?: InputMaybe<Order_By>;
  licence_holder_last_name?: InputMaybe<Order_By>;
  licence_holder_phone?: InputMaybe<Order_By>;
  licence_number?: InputMaybe<Order_By>;
  main_contact_email?: InputMaybe<Order_By>;
  main_contact_first_name?: InputMaybe<Order_By>;
  main_contact_id?: InputMaybe<Order_By>;
  main_contact_last_name?: InputMaybe<Order_By>;
  main_contact_phone?: InputMaybe<Order_By>;
  new_agency_org_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_portal */
export type Partner_Portal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "partner_portal" */
export type Partner_Portal_Set_Input = {
  abn?: InputMaybe<Scalars['String']>;
  agency_org_id?: InputMaybe<Scalars['uuid']>;
  company_address_city_suburb?: InputMaybe<Scalars['String']>;
  company_address_country?: InputMaybe<Scalars['String']>;
  company_address_line_1?: InputMaybe<Scalars['String']>;
  company_address_postcode?: InputMaybe<Scalars['String']>;
  company_address_state?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  licence_holder_address_city_suburb?: InputMaybe<Scalars['String']>;
  licence_holder_address_country?: InputMaybe<Scalars['String']>;
  licence_holder_address_line_1?: InputMaybe<Scalars['String']>;
  licence_holder_address_postcode?: InputMaybe<Scalars['String']>;
  licence_holder_address_state?: InputMaybe<Scalars['String']>;
  licence_holder_contact_id?: InputMaybe<Scalars['uuid']>;
  licence_holder_email?: InputMaybe<Scalars['String']>;
  licence_holder_first_name?: InputMaybe<Scalars['String']>;
  licence_holder_last_name?: InputMaybe<Scalars['String']>;
  licence_holder_phone?: InputMaybe<Scalars['String']>;
  licence_number?: InputMaybe<Scalars['String']>;
  main_contact_email?: InputMaybe<Scalars['String']>;
  main_contact_first_name?: InputMaybe<Scalars['String']>;
  main_contact_id?: InputMaybe<Scalars['uuid']>;
  main_contact_last_name?: InputMaybe<Scalars['String']>;
  main_contact_phone?: InputMaybe<Scalars['String']>;
  new_agency_org_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "partner_portal" */
export type Partner_Portal_Stream_Cursor_Input = {
  initial_value: Partner_Portal_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Portal_Stream_Cursor_Value_Input = {
  abn?: InputMaybe<Scalars['String']>;
  agency_org_id?: InputMaybe<Scalars['uuid']>;
  company_address_city_suburb?: InputMaybe<Scalars['String']>;
  company_address_country?: InputMaybe<Scalars['String']>;
  company_address_line_1?: InputMaybe<Scalars['String']>;
  company_address_postcode?: InputMaybe<Scalars['String']>;
  company_address_state?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invitation_id?: InputMaybe<Scalars['uuid']>;
  licence_holder_address_city_suburb?: InputMaybe<Scalars['String']>;
  licence_holder_address_country?: InputMaybe<Scalars['String']>;
  licence_holder_address_line_1?: InputMaybe<Scalars['String']>;
  licence_holder_address_postcode?: InputMaybe<Scalars['String']>;
  licence_holder_address_state?: InputMaybe<Scalars['String']>;
  licence_holder_contact_id?: InputMaybe<Scalars['uuid']>;
  licence_holder_email?: InputMaybe<Scalars['String']>;
  licence_holder_first_name?: InputMaybe<Scalars['String']>;
  licence_holder_last_name?: InputMaybe<Scalars['String']>;
  licence_holder_phone?: InputMaybe<Scalars['String']>;
  licence_number?: InputMaybe<Scalars['String']>;
  main_contact_email?: InputMaybe<Scalars['String']>;
  main_contact_first_name?: InputMaybe<Scalars['String']>;
  main_contact_id?: InputMaybe<Scalars['uuid']>;
  main_contact_last_name?: InputMaybe<Scalars['String']>;
  main_contact_phone?: InputMaybe<Scalars['String']>;
  new_agency_org_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Partner_Portal_Updates = {
  _set?: InputMaybe<Partner_Portal_Set_Input>;
  where: Partner_Portal_Bool_Exp;
};

/** Boolean expression to filter rows from the table "payment_method". All fields are combined with a logical 'AND'. */
export type Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Method_Bool_Exp>>;
  biller_code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_description?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  type?: InputMaybe<Payment_Method_Type_Enum_Comparison_Exp>;
};

/** input type for inserting data into table "payment_method" */
export type Payment_Method_Insert_Input = {
  biller_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payment_description?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Payment_Method_Type_Enum>;
};

/** input type for inserting object relation for remote table "payment_method" */
export type Payment_Method_Obj_Rel_Insert_Input = {
  data: Payment_Method_Insert_Input;
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

/** on_conflict condition type for table "payment_method" */
export type Payment_Method_On_Conflict = {
  constraint: Payment_Method_Constraint;
  update_columns: Array<Payment_Method_Update_Column>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_method". */
export type Payment_Method_Order_By = {
  biller_code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_description?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_method */
export type Payment_Method_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "payment_method" */
export type Payment_Method_Set_Input = {
  biller_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payment_description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Payment_Method_Type_Enum>;
};

/** Streaming cursor of the table "payment_method" */
export type Payment_Method_Stream_Cursor_Input = {
  initial_value: Payment_Method_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Stream_Cursor_Value_Input = {
  biller_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payment_description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Payment_Method_Type_Enum>;
};

/** Boolean expression to filter rows from the table "payment_method_type". All fields are combined with a logical 'AND'. */
export type Payment_Method_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Method_Type_Bool_Exp>>;
  _not?: InputMaybe<Payment_Method_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Method_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "payment_method_type_enum". All fields are combined with logical 'AND'. */
export type Payment_Method_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Method_Type_Enum>;
  _in?: InputMaybe<Array<Payment_Method_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Payment_Method_Type_Enum>;
  _nin?: InputMaybe<Array<Payment_Method_Type_Enum>>;
};

/** input type for inserting data into table "payment_method_type" */
export type Payment_Method_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "payment_method_type" */
export type Payment_Method_Type_Obj_Rel_Insert_Input = {
  data: Payment_Method_Type_Insert_Input;
  on_conflict?: InputMaybe<Payment_Method_Type_On_Conflict>;
};

/** on_conflict condition type for table "payment_method_type" */
export type Payment_Method_Type_On_Conflict = {
  constraint: Payment_Method_Type_Constraint;
  update_columns: Array<Payment_Method_Type_Update_Column>;
  where?: InputMaybe<Payment_Method_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_method_type". */
export type Payment_Method_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_method_type */
export type Payment_Method_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "payment_method_type" */
export type Payment_Method_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_method_type" */
export type Payment_Method_Type_Stream_Cursor_Input = {
  initial_value: Payment_Method_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Payment_Method_Type_Updates = {
  _set?: InputMaybe<Payment_Method_Type_Set_Input>;
  where: Payment_Method_Type_Bool_Exp;
};

export type Payment_Method_Updates = {
  _set?: InputMaybe<Payment_Method_Set_Input>;
  where: Payment_Method_Bool_Exp;
};

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type Point_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['point']>;
  _gt?: InputMaybe<Scalars['point']>;
  _gte?: InputMaybe<Scalars['point']>;
  _in?: InputMaybe<Array<Scalars['point']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['point']>;
  _lte?: InputMaybe<Scalars['point']>;
  _neq?: InputMaybe<Scalars['point']>;
  _nin?: InputMaybe<Array<Scalars['point']>>;
};

/** Boolean expression to compare columns of type "polygon". All fields are combined with logical 'AND'. */
export type Polygon_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['polygon']>;
  _gt?: InputMaybe<Scalars['polygon']>;
  _gte?: InputMaybe<Scalars['polygon']>;
  _in?: InputMaybe<Array<Scalars['polygon']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['polygon']>;
  _lte?: InputMaybe<Scalars['polygon']>;
  _neq?: InputMaybe<Scalars['polygon']>;
  _nin?: InputMaybe<Array<Scalars['polygon']>>;
};

export type Preference_Aggregate_Bool_Exp = {
  count?: InputMaybe<Preference_Aggregate_Bool_Exp_Count>;
};

export type Preference_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Preference_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Preference_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "preference" */
export type Preference_Aggregate_Order_By = {
  avg?: InputMaybe<Preference_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Preference_Max_Order_By>;
  min?: InputMaybe<Preference_Min_Order_By>;
  stddev?: InputMaybe<Preference_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Preference_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Preference_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Preference_Sum_Order_By>;
  var_pop?: InputMaybe<Preference_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Preference_Var_Samp_Order_By>;
  variance?: InputMaybe<Preference_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "preference" */
export type Preference_Arr_Rel_Insert_Input = {
  data: Array<Preference_Insert_Input>;
  on_conflict?: InputMaybe<Preference_On_Conflict>;
};

/** order by avg() on columns of table "preference" */
export type Preference_Avg_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "preference". All fields are combined with a logical 'AND'. */
export type Preference_Bool_Exp = {
  _and?: InputMaybe<Array<Preference_Bool_Exp>>;
  _not?: InputMaybe<Preference_Bool_Exp>;
  _or?: InputMaybe<Array<Preference_Bool_Exp>>;
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Bool_Exp>;
  acl_deal_developer_member?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
  acl_deal_developer_member_aggregate?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Bool_Exp>;
  acl_deal_master_agent?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
  acl_deal_master_agent_aggregate?: InputMaybe<Acl_Deal_Master_Agent_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  property?: InputMaybe<Property_Bool_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "preference" */
export type Preference_Inc_Input = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "preference" */
export type Preference_Insert_Input = {
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Arr_Rel_Insert_Input>;
  acl_deal_developer_member?: InputMaybe<Acl_Deal_Developer_Member_Arr_Rel_Insert_Input>;
  acl_deal_master_agent?: InputMaybe<Acl_Deal_Master_Agent_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  property?: InputMaybe<Property_Obj_Rel_Insert_Input>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "preference" */
export type Preference_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "preference" */
export type Preference_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "preference" */
export type Preference_On_Conflict = {
  constraint: Preference_Constraint;
  update_columns: Array<Preference_Update_Column>;
  where?: InputMaybe<Preference_Bool_Exp>;
};

/** Ordering options when selecting data from "preference". */
export type Preference_Order_By = {
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Order_By>;
  acl_deal_developer_member_aggregate?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Order_By>;
  acl_deal_master_agent_aggregate?: InputMaybe<Acl_Deal_Master_Agent_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  property?: InputMaybe<Property_Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: preference */
export type Preference_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "preference" */
export type Preference_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "preference" */
export type Preference_Stddev_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "preference" */
export type Preference_Stddev_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "preference" */
export type Preference_Stddev_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "preference" */
export type Preference_Stream_Cursor_Input = {
  initial_value: Preference_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Preference_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "preference" */
export type Preference_Sum_Order_By = {
  priority?: InputMaybe<Order_By>;
};

export type Preference_Updates = {
  _inc?: InputMaybe<Preference_Inc_Input>;
  _set?: InputMaybe<Preference_Set_Input>;
  where: Preference_Bool_Exp;
};

/** order by var_pop() on columns of table "preference" */
export type Preference_Var_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "preference" */
export type Preference_Var_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "preference" */
export type Preference_Variance_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "process". All fields are combined with a logical 'AND'. */
export type Process_Bool_Exp = {
  _and?: InputMaybe<Array<Process_Bool_Exp>>;
  _not?: InputMaybe<Process_Bool_Exp>;
  _or?: InputMaybe<Array<Process_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_running?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at_unix?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "process" */
export type Process_Inc_Input = {
  updated_at_unix?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "process" */
export type Process_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  is_running?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_at_unix?: InputMaybe<Scalars['bigint']>;
};

/** on_conflict condition type for table "process" */
export type Process_On_Conflict = {
  constraint: Process_Constraint;
  update_columns: Array<Process_Update_Column>;
  where?: InputMaybe<Process_Bool_Exp>;
};

/** Ordering options when selecting data from "process". */
export type Process_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_running?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_at_unix?: InputMaybe<Order_By>;
};

/** primary key columns input for table: process */
export type Process_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** input type for updating data in table "process" */
export type Process_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  is_running?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_at_unix?: InputMaybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "process_status". All fields are combined with a logical 'AND'. */
export type Process_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Process_Status_Bool_Exp>>;
  _not?: InputMaybe<Process_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Process_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "process_status_enum". All fields are combined with logical 'AND'. */
export type Process_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Process_Status_Enum>;
  _in?: InputMaybe<Array<Process_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Process_Status_Enum>;
  _nin?: InputMaybe<Array<Process_Status_Enum>>;
};

/** input type for inserting data into table "process_status" */
export type Process_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "process_status" */
export type Process_Status_On_Conflict = {
  constraint: Process_Status_Constraint;
  update_columns: Array<Process_Status_Update_Column>;
  where?: InputMaybe<Process_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "process_status". */
export type Process_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: process_status */
export type Process_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "process_status" */
export type Process_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "process_status" */
export type Process_Status_Stream_Cursor_Input = {
  initial_value: Process_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Process_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Process_Status_Updates = {
  _set?: InputMaybe<Process_Status_Set_Input>;
  where: Process_Status_Bool_Exp;
};

/** Streaming cursor of the table "process" */
export type Process_Stream_Cursor_Input = {
  initial_value: Process_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Process_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  is_running?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_at_unix?: InputMaybe<Scalars['bigint']>;
};

export type Process_Updates = {
  _inc?: InputMaybe<Process_Inc_Input>;
  _set?: InputMaybe<Process_Set_Input>;
  where: Process_Bool_Exp;
};

export type Project_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Aggregate_Bool_Exp_Count>;
};

export type Project_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "project" */
export type Project_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
  stddev?: InputMaybe<Project_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Variance_Order_By>;
};

export type Project_Apartment_Level_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Apartment_Level_Aggregate_Bool_Exp_Count>;
};

export type Project_Apartment_Level_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "project_apartment_level" */
export type Project_Apartment_Level_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Apartment_Level_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Apartment_Level_Max_Order_By>;
  min?: InputMaybe<Project_Apartment_Level_Min_Order_By>;
  stddev?: InputMaybe<Project_Apartment_Level_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Apartment_Level_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Apartment_Level_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Apartment_Level_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Apartment_Level_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Apartment_Level_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Apartment_Level_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_apartment_level" */
export type Project_Apartment_Level_Arr_Rel_Insert_Input = {
  data: Array<Project_Apartment_Level_Insert_Input>;
};

/** order by avg() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Avg_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_apartment_level". All fields are combined with a logical 'AND'. */
export type Project_Apartment_Level_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Apartment_Level_Bool_Exp>>;
  _not?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Apartment_Level_Bool_Exp>>;
  apartment_count?: InputMaybe<Bigint_Comparison_Exp>;
  attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  building_id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  level_plan_url?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "project_apartment_level" */
export type Project_Apartment_Level_Insert_Input = {
  apartment_count?: InputMaybe<Scalars['bigint']>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  building_id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['String']>;
  level_plan_url?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Max_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  building_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_plan_url?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Min_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  building_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_plan_url?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "project_apartment_level". */
export type Project_Apartment_Level_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
  attachment_id?: InputMaybe<Order_By>;
  building_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_plan_url?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** order by stddev() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Stddev_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Stddev_Pop_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Stddev_Samp_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_apartment_level" */
export type Project_Apartment_Level_Stream_Cursor_Input = {
  initial_value: Project_Apartment_Level_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Apartment_Level_Stream_Cursor_Value_Input = {
  apartment_count?: InputMaybe<Scalars['bigint']>;
  attachment_id?: InputMaybe<Scalars['uuid']>;
  building_id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['String']>;
  level_plan_url?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Sum_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Var_Pop_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Var_Samp_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "project_apartment_level" */
export type Project_Apartment_Level_Variance_Order_By = {
  apartment_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_apartment_stat". All fields are combined with a logical 'AND'. */
export type Project_Apartment_Stat_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Apartment_Stat_Bool_Exp>>;
  _not?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Apartment_Stat_Bool_Exp>>;
  building_id?: InputMaybe<Uuid_Comparison_Exp>;
  building_name?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_name?: InputMaybe<String_Comparison_Exp>;
  total_lots?: InputMaybe<Bigint_Comparison_Exp>;
  total_price?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "project_apartment_stat" */
export type Project_Apartment_Stat_Insert_Input = {
  building_id?: InputMaybe<Scalars['uuid']>;
  building_name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  total_lots?: InputMaybe<Scalars['bigint']>;
  total_price?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "project_apartment_stat" */
export type Project_Apartment_Stat_Obj_Rel_Insert_Input = {
  data: Project_Apartment_Stat_Insert_Input;
};

/** Ordering options when selecting data from "project_apartment_stat". */
export type Project_Apartment_Stat_Order_By = {
  building_id?: InputMaybe<Order_By>;
  building_name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  total_lots?: InputMaybe<Order_By>;
  total_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_apartment_stat" */
export type Project_Apartment_Stat_Stream_Cursor_Input = {
  initial_value: Project_Apartment_Stat_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Apartment_Stat_Stream_Cursor_Value_Input = {
  building_id?: InputMaybe<Scalars['uuid']>;
  building_name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  total_lots?: InputMaybe<Scalars['bigint']>;
  total_price?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting array relation for remote table "project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** order by avg() on columns of table "project" */
export type Project_Avg_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Bool_Exp>>;
  _not?: InputMaybe<Project_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Bool_Exp>>;
  acl_allocation_edit?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
  acl_allocation_edit_aggregate?: InputMaybe<Acl_Allocation_Edit_Aggregate_Bool_Exp>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Bool_Exp>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Bool_Exp>;
  active_project?: InputMaybe<Boolean_Comparison_Exp>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  area_plots?: InputMaybe<Area_Plot_Bool_Exp>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Bool_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  background_attachment?: InputMaybe<Attachment_Bool_Exp>;
  background_attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  brand_bg_color?: InputMaybe<String_Comparison_Exp>;
  brand_fg_color?: InputMaybe<String_Comparison_Exp>;
  buyer_projects?: InputMaybe<Buyer_Project_Bool_Exp>;
  buyer_projects_aggregate?: InputMaybe<Buyer_Project_Aggregate_Bool_Exp>;
  can_publish?: InputMaybe<Boolean_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  crm_connection?: InputMaybe<Crm_Connection_Bool_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  default_head_agency?: InputMaybe<Organisation_Bool_Exp>;
  default_head_agency_id?: InputMaybe<Uuid_Comparison_Exp>;
  delegated_by?: InputMaybe<Uuid_Comparison_Exp>;
  delegated_by_team?: InputMaybe<Organisation_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  domain_agency_id?: InputMaybe<Int_Comparison_Exp>;
  domain_project_id?: InputMaybe<Int_Comparison_Exp>;
  domain_verification_code?: InputMaybe<String_Comparison_Exp>;
  embeds?: InputMaybe<Embed_Bool_Exp>;
  embeds_aggregate?: InputMaybe<Embed_Aggregate_Bool_Exp>;
  eoi_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  fake?: InputMaybe<Boolean_Comparison_Exp>;
  features?: InputMaybe<Project_Features_Bool_Exp>;
  features_aggregate?: InputMaybe<Project_Features_Aggregate_Bool_Exp>;
  holding_deposit?: InputMaybe<Numeric_Comparison_Exp>;
  holding_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  hubspot?: InputMaybe<Hubspot_Project_Bool_Exp>;
  hubspot_aggregate?: InputMaybe<Hubspot_Project_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_use_variable_holding_deposit?: InputMaybe<Boolean_Comparison_Exp>;
  listings?: InputMaybe<Listing_Bool_Exp>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Bool_Exp>;
  logo_attachment?: InputMaybe<Attachment_Bool_Exp>;
  logo_attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  map_lat_lng?: InputMaybe<Point_Comparison_Exp>;
  master_plan_attachment?: InputMaybe<Attachment_Bool_Exp>;
  master_plan_attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  member_project_allocates?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
  member_project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisationByVendorSolicitorId?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_info?: InputMaybe<String_Comparison_Exp>;
  payment_method?: InputMaybe<Payment_Method_Bool_Exp>;
  payment_method_id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_methods?: InputMaybe<Project_Payment_Method_Bool_Exp>;
  payment_methods_aggregate?: InputMaybe<Project_Payment_Method_Aggregate_Bool_Exp>;
  postcode?: InputMaybe<String_Comparison_Exp>;
  project_detail?: InputMaybe<Project_Detail_Bool_Exp>;
  project_director_contact?: InputMaybe<Contact_Bool_Exp>;
  project_director_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_type?: InputMaybe<Project_Type_Bool_Exp>;
  project_units?: InputMaybe<Project_Units_Bool_Exp>;
  properties?: InputMaybe<Property_Bool_Exp>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  property_groups?: InputMaybe<Property_Group_Bool_Exp>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Bool_Exp>;
  reservation_setting?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
  sales_advice_vendor?: InputMaybe<String_Comparison_Exp>;
  setting_holding_hours?: InputMaybe<Int_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Project_Status_Enum_Comparison_Exp>;
  sub_description?: InputMaybe<String_Comparison_Exp>;
  suburb?: InputMaybe<String_Comparison_Exp>;
  total_deposit_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<Project_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  variable_holding_deposit_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  vendor_solicitor_contact?: InputMaybe<Contact_Bool_Exp>;
  vendor_solicitor_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_solicitor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "project_client_count". All fields are combined with a logical 'AND'. */
export type Project_Client_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Client_Count_Bool_Exp>>;
  _not?: InputMaybe<Project_Client_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Client_Count_Bool_Exp>>;
  client_count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

export type Project_Client_Count_For_User_Args = {
  for_team_id?: InputMaybe<Scalars['uuid']>;
  for_user_id?: InputMaybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "project_client_count". */
export type Project_Client_Count_Order_By = {
  client_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_client_count" */
export type Project_Client_Count_Stream_Cursor_Input = {
  initial_value: Project_Client_Count_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Client_Count_Stream_Cursor_Value_Input = {
  client_count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "project_detail". All fields are combined with a logical 'AND'. */
export type Project_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Detail_Bool_Exp>>;
  _not?: InputMaybe<Project_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Detail_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  development_address?: InputMaybe<String_Comparison_Exp>;
  development_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  development_address_state?: InputMaybe<String_Comparison_Exp>;
  development_address_suburb?: InputMaybe<String_Comparison_Exp>;
  development_country?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  suite_address?: InputMaybe<String_Comparison_Exp>;
  suite_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  suite_address_state?: InputMaybe<String_Comparison_Exp>;
  suite_address_suburb?: InputMaybe<String_Comparison_Exp>;
  suite_country?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  website_address?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "project_detail" */
export type Project_Detail_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  development_address?: InputMaybe<Scalars['String']>;
  development_address_postal_code?: InputMaybe<Scalars['String']>;
  development_address_state?: InputMaybe<Scalars['String']>;
  development_address_suburb?: InputMaybe<Scalars['String']>;
  development_country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  suite_address?: InputMaybe<Scalars['String']>;
  suite_address_postal_code?: InputMaybe<Scalars['String']>;
  suite_address_state?: InputMaybe<Scalars['String']>;
  suite_address_suburb?: InputMaybe<Scalars['String']>;
  suite_country?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website_address?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "project_detail" */
export type Project_Detail_Obj_Rel_Insert_Input = {
  data: Project_Detail_Insert_Input;
  on_conflict?: InputMaybe<Project_Detail_On_Conflict>;
};

/** on_conflict condition type for table "project_detail" */
export type Project_Detail_On_Conflict = {
  constraint: Project_Detail_Constraint;
  update_columns: Array<Project_Detail_Update_Column>;
  where?: InputMaybe<Project_Detail_Bool_Exp>;
};

/** Ordering options when selecting data from "project_detail". */
export type Project_Detail_Order_By = {
  created_at?: InputMaybe<Order_By>;
  development_address?: InputMaybe<Order_By>;
  development_address_postal_code?: InputMaybe<Order_By>;
  development_address_state?: InputMaybe<Order_By>;
  development_address_suburb?: InputMaybe<Order_By>;
  development_country?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  suite_address?: InputMaybe<Order_By>;
  suite_address_postal_code?: InputMaybe<Order_By>;
  suite_address_state?: InputMaybe<Order_By>;
  suite_address_suburb?: InputMaybe<Order_By>;
  suite_country?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  website_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_detail */
export type Project_Detail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "project_detail" */
export type Project_Detail_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  development_address?: InputMaybe<Scalars['String']>;
  development_address_postal_code?: InputMaybe<Scalars['String']>;
  development_address_state?: InputMaybe<Scalars['String']>;
  development_address_suburb?: InputMaybe<Scalars['String']>;
  development_country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  suite_address?: InputMaybe<Scalars['String']>;
  suite_address_postal_code?: InputMaybe<Scalars['String']>;
  suite_address_state?: InputMaybe<Scalars['String']>;
  suite_address_suburb?: InputMaybe<Scalars['String']>;
  suite_country?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website_address?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_detail" */
export type Project_Detail_Stream_Cursor_Input = {
  initial_value: Project_Detail_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Detail_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  development_address?: InputMaybe<Scalars['String']>;
  development_address_postal_code?: InputMaybe<Scalars['String']>;
  development_address_state?: InputMaybe<Scalars['String']>;
  development_address_suburb?: InputMaybe<Scalars['String']>;
  development_country?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  suite_address?: InputMaybe<Scalars['String']>;
  suite_address_postal_code?: InputMaybe<Scalars['String']>;
  suite_address_state?: InputMaybe<Scalars['String']>;
  suite_address_suburb?: InputMaybe<Scalars['String']>;
  suite_country?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  website_address?: InputMaybe<Scalars['String']>;
};

export type Project_Detail_Updates = {
  _set?: InputMaybe<Project_Detail_Set_Input>;
  where: Project_Detail_Bool_Exp;
};

export type Project_Features_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Features_Aggregate_Bool_Exp_Count>;
};

export type Project_Features_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Features_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "project_features" */
export type Project_Features_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Features_Max_Order_By>;
  min?: InputMaybe<Project_Features_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_features" */
export type Project_Features_Arr_Rel_Insert_Input = {
  data: Array<Project_Features_Insert_Input>;
  on_conflict?: InputMaybe<Project_Features_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_features". All fields are combined with a logical 'AND'. */
export type Project_Features_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Features_Bool_Exp>>;
  _not?: InputMaybe<Project_Features_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Features_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "project_features" */
export type Project_Features_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "project_features" */
export type Project_Features_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "project_features" */
export type Project_Features_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "project_features" */
export type Project_Features_On_Conflict = {
  constraint: Project_Features_Constraint;
  update_columns: Array<Project_Features_Update_Column>;
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "project_features". */
export type Project_Features_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_features */
export type Project_Features_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "project_features" */
export type Project_Features_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "project_features" */
export type Project_Features_Stream_Cursor_Input = {
  initial_value: Project_Features_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Features_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Project_Features_Updates = {
  _set?: InputMaybe<Project_Features_Set_Input>;
  where: Project_Features_Bool_Exp;
};

/** input type for incrementing numeric columns in table "project" */
export type Project_Inc_Input = {
  domain_agency_id?: InputMaybe<Scalars['Int']>;
  domain_project_id?: InputMaybe<Scalars['Int']>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  setting_holding_hours?: InputMaybe<Scalars['Int']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  variable_holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
  acl_allocation_edit?: InputMaybe<Acl_Allocation_Edit_Arr_Rel_Insert_Input>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Arr_Rel_Insert_Input>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Arr_Rel_Insert_Input>;
  active_project?: InputMaybe<Scalars['Boolean']>;
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  area_plots?: InputMaybe<Area_Plot_Arr_Rel_Insert_Input>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  background_attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  background_attachment_id?: InputMaybe<Scalars['uuid']>;
  brand_bg_color?: InputMaybe<Scalars['String']>;
  brand_fg_color?: InputMaybe<Scalars['String']>;
  buyer_projects?: InputMaybe<Buyer_Project_Arr_Rel_Insert_Input>;
  can_publish?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  crm_connection?: InputMaybe<Crm_Connection_Obj_Rel_Insert_Input>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  default_head_agency?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  default_head_agency_id?: InputMaybe<Scalars['uuid']>;
  delegated_by?: InputMaybe<Scalars['uuid']>;
  delegated_by_team?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  domain_agency_id?: InputMaybe<Scalars['Int']>;
  domain_project_id?: InputMaybe<Scalars['Int']>;
  domain_verification_code?: InputMaybe<Scalars['String']>;
  embeds?: InputMaybe<Embed_Arr_Rel_Insert_Input>;
  eoi_enabled?: InputMaybe<Scalars['Boolean']>;
  fake?: InputMaybe<Scalars['Boolean']>;
  features?: InputMaybe<Project_Features_Arr_Rel_Insert_Input>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  holding_enabled?: InputMaybe<Scalars['Boolean']>;
  hubspot?: InputMaybe<Hubspot_Project_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_use_variable_holding_deposit?: InputMaybe<Scalars['Boolean']>;
  listings?: InputMaybe<Listing_Arr_Rel_Insert_Input>;
  logo_attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  logo_attachment_id?: InputMaybe<Scalars['uuid']>;
  map_lat_lng?: InputMaybe<Scalars['point']>;
  master_plan_attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  master_plan_attachment_id?: InputMaybe<Scalars['uuid']>;
  member_project_allocates?: InputMaybe<Member_Project_Allocate_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisationByVendorSolicitorId?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  payment_info?: InputMaybe<Scalars['String']>;
  payment_method?: InputMaybe<Payment_Method_Obj_Rel_Insert_Input>;
  payment_method_id?: InputMaybe<Scalars['uuid']>;
  payment_methods?: InputMaybe<Project_Payment_Method_Arr_Rel_Insert_Input>;
  postcode?: InputMaybe<Scalars['String']>;
  project_detail?: InputMaybe<Project_Detail_Obj_Rel_Insert_Input>;
  project_director_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  project_director_contact_id?: InputMaybe<Scalars['uuid']>;
  project_type?: InputMaybe<Project_Type_Obj_Rel_Insert_Input>;
  project_units?: InputMaybe<Project_Units_Obj_Rel_Insert_Input>;
  properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  property_groups?: InputMaybe<Property_Group_Arr_Rel_Insert_Input>;
  reservation_setting?: InputMaybe<Project_Reservation_Setting_Obj_Rel_Insert_Input>;
  sales_advice_vendor?: InputMaybe<Scalars['String']>;
  setting_holding_hours?: InputMaybe<Scalars['Int']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Project_Status_Enum>;
  sub_description?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Project_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  variable_holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  vendor_solicitor_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  vendor_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project" */
export type Project_Max_Order_By = {
  background_attachment_id?: InputMaybe<Order_By>;
  brand_bg_color?: InputMaybe<Order_By>;
  brand_fg_color?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_head_agency_id?: InputMaybe<Order_By>;
  delegated_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  domain_verification_code?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_attachment_id?: InputMaybe<Order_By>;
  master_plan_attachment_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  payment_info?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  project_director_contact_id?: InputMaybe<Order_By>;
  sales_advice_vendor?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  sub_description?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
  vendor_solicitor_contact_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "project" */
export type Project_Min_Order_By = {
  background_attachment_id?: InputMaybe<Order_By>;
  brand_bg_color?: InputMaybe<Order_By>;
  brand_fg_color?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_head_agency_id?: InputMaybe<Order_By>;
  delegated_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  domain_verification_code?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_attachment_id?: InputMaybe<Order_By>;
  master_plan_attachment_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  payment_info?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  project_director_contact_id?: InputMaybe<Order_By>;
  sales_advice_vendor?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  sub_description?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
  vendor_solicitor_contact_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** on_conflict condition type for table "project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns: Array<Project_Update_Column>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
  acl_allocation_edit_aggregate?: InputMaybe<Acl_Allocation_Edit_Aggregate_Order_By>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Order_By>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Order_By>;
  active_project?: InputMaybe<Order_By>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  background_attachment?: InputMaybe<Attachment_Order_By>;
  background_attachment_id?: InputMaybe<Order_By>;
  brand_bg_color?: InputMaybe<Order_By>;
  brand_fg_color?: InputMaybe<Order_By>;
  buyer_projects_aggregate?: InputMaybe<Buyer_Project_Aggregate_Order_By>;
  can_publish?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  crm_connection?: InputMaybe<Crm_Connection_Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  default_head_agency?: InputMaybe<Organisation_Order_By>;
  default_head_agency_id?: InputMaybe<Order_By>;
  delegated_by?: InputMaybe<Order_By>;
  delegated_by_team?: InputMaybe<Organisation_Order_By>;
  description?: InputMaybe<Order_By>;
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  domain_verification_code?: InputMaybe<Order_By>;
  embeds_aggregate?: InputMaybe<Embed_Aggregate_Order_By>;
  eoi_enabled?: InputMaybe<Order_By>;
  fake?: InputMaybe<Order_By>;
  features_aggregate?: InputMaybe<Project_Features_Aggregate_Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  holding_enabled?: InputMaybe<Order_By>;
  hubspot_aggregate?: InputMaybe<Hubspot_Project_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_use_variable_holding_deposit?: InputMaybe<Order_By>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Order_By>;
  logo_attachment?: InputMaybe<Attachment_Order_By>;
  logo_attachment_id?: InputMaybe<Order_By>;
  map_lat_lng?: InputMaybe<Order_By>;
  master_plan_attachment?: InputMaybe<Attachment_Order_By>;
  master_plan_attachment_id?: InputMaybe<Order_By>;
  member_project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisationByVendorSolicitorId?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  payment_info?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Payment_Method_Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  payment_methods_aggregate?: InputMaybe<Project_Payment_Method_Aggregate_Order_By>;
  postcode?: InputMaybe<Order_By>;
  project_detail?: InputMaybe<Project_Detail_Order_By>;
  project_director_contact?: InputMaybe<Contact_Order_By>;
  project_director_contact_id?: InputMaybe<Order_By>;
  project_type?: InputMaybe<Project_Type_Order_By>;
  project_units?: InputMaybe<Project_Units_Order_By>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Order_By>;
  reservation_setting?: InputMaybe<Project_Reservation_Setting_Order_By>;
  sales_advice_vendor?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_description?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
  vendor_solicitor_contact?: InputMaybe<Contact_Order_By>;
  vendor_solicitor_contact_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
};

export type Project_Payment_Method_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Payment_Method_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Payment_Method_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Payment_Method_Aggregate_Bool_Exp_Count>;
};

export type Project_Payment_Method_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Payment_Method_Select_Column_Project_Payment_Method_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Payment_Method_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Payment_Method_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Payment_Method_Select_Column_Project_Payment_Method_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Payment_Method_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Payment_Method_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Payment_Method_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "project_payment_method" */
export type Project_Payment_Method_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Payment_Method_Max_Order_By>;
  min?: InputMaybe<Project_Payment_Method_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Project_Payment_Method_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "project_payment_method" */
export type Project_Payment_Method_Arr_Rel_Insert_Input = {
  data: Array<Project_Payment_Method_Insert_Input>;
  on_conflict?: InputMaybe<Project_Payment_Method_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_payment_method". All fields are combined with a logical 'AND'. */
export type Project_Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Project_Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Payment_Method_Bool_Exp>>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  method?: InputMaybe<Payment_Method_Type_Enum_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Project_Payment_Method_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Project_Payment_Method_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Project_Payment_Method_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "project_payment_method" */
export type Project_Payment_Method_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Payment_Method_Type_Enum>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_payment_method" */
export type Project_Payment_Method_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "project_payment_method" */
export type Project_Payment_Method_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "project_payment_method" */
export type Project_Payment_Method_On_Conflict = {
  constraint: Project_Payment_Method_Constraint;
  update_columns: Array<Project_Payment_Method_Update_Column>;
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "project_payment_method". */
export type Project_Payment_Method_Order_By = {
  config?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_payment_method */
export type Project_Payment_Method_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Project_Payment_Method_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "project_payment_method" */
export type Project_Payment_Method_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Payment_Method_Type_Enum>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_payment_method" */
export type Project_Payment_Method_Stream_Cursor_Input = {
  initial_value: Project_Payment_Method_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Payment_Method_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Payment_Method_Type_Enum>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

export type Project_Payment_Method_Updates = {
  _append?: InputMaybe<Project_Payment_Method_Append_Input>;
  _delete_at_path?: InputMaybe<Project_Payment_Method_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Project_Payment_Method_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Payment_Method_Delete_Key_Input>;
  _prepend?: InputMaybe<Project_Payment_Method_Prepend_Input>;
  _set?: InputMaybe<Project_Payment_Method_Set_Input>;
  where: Project_Payment_Method_Bool_Exp;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "project_qr_code_for_buyer". All fields are combined with a logical 'AND'. */
export type Project_Qr_Code_For_Buyer_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Bool_Exp>>;
  _not?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Bool_Exp>>;
  agent?: InputMaybe<User_Bool_Exp>;
  agent_team?: InputMaybe<Organisation_Bool_Exp>;
  agent_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  agent_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_Insert_Input = {
  agent?: InputMaybe<User_Obj_Rel_Insert_Input>;
  agent_team?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  agent_team_id?: InputMaybe<Scalars['uuid']>;
  agent_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_On_Conflict = {
  constraint: Project_Qr_Code_For_Buyer_Constraint;
  update_columns: Array<Project_Qr_Code_For_Buyer_Update_Column>;
  where?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
};

/** Ordering options when selecting data from "project_qr_code_for_buyer". */
export type Project_Qr_Code_For_Buyer_Order_By = {
  agent?: InputMaybe<User_Order_By>;
  agent_team?: InputMaybe<Organisation_Order_By>;
  agent_team_id?: InputMaybe<Order_By>;
  agent_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_qr_code_for_buyer */
export type Project_Qr_Code_For_Buyer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_Set_Input = {
  agent_team_id?: InputMaybe<Scalars['uuid']>;
  agent_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_Stream_Cursor_Input = {
  initial_value: Project_Qr_Code_For_Buyer_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Qr_Code_For_Buyer_Stream_Cursor_Value_Input = {
  agent_team_id?: InputMaybe<Scalars['uuid']>;
  agent_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Project_Qr_Code_For_Buyer_Updates = {
  _set?: InputMaybe<Project_Qr_Code_For_Buyer_Set_Input>;
  where: Project_Qr_Code_For_Buyer_Bool_Exp;
};

/** Boolean expression to filter rows from the table "project_reservation_setting". All fields are combined with a logical 'AND'. */
export type Project_Reservation_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Reservation_Setting_Bool_Exp>>;
  _not?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Reservation_Setting_Bool_Exp>>;
  agent_holding_deposit_content?: InputMaybe<String_Comparison_Exp>;
  agent_review_summary_content?: InputMaybe<String_Comparison_Exp>;
  buyer_holding_deposit_content?: InputMaybe<String_Comparison_Exp>;
  buyer_review_summary_content?: InputMaybe<String_Comparison_Exp>;
  buyer_sign_contract_content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "project_reservation_setting" */
export type Project_Reservation_Setting_Insert_Input = {
  agent_holding_deposit_content?: InputMaybe<Scalars['String']>;
  agent_review_summary_content?: InputMaybe<Scalars['String']>;
  buyer_holding_deposit_content?: InputMaybe<Scalars['String']>;
  buyer_review_summary_content?: InputMaybe<Scalars['String']>;
  buyer_sign_contract_content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for inserting object relation for remote table "project_reservation_setting" */
export type Project_Reservation_Setting_Obj_Rel_Insert_Input = {
  data: Project_Reservation_Setting_Insert_Input;
  on_conflict?: InputMaybe<Project_Reservation_Setting_On_Conflict>;
};

/** on_conflict condition type for table "project_reservation_setting" */
export type Project_Reservation_Setting_On_Conflict = {
  constraint: Project_Reservation_Setting_Constraint;
  update_columns: Array<Project_Reservation_Setting_Update_Column>;
  where?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "project_reservation_setting". */
export type Project_Reservation_Setting_Order_By = {
  agent_holding_deposit_content?: InputMaybe<Order_By>;
  agent_review_summary_content?: InputMaybe<Order_By>;
  buyer_holding_deposit_content?: InputMaybe<Order_By>;
  buyer_review_summary_content?: InputMaybe<Order_By>;
  buyer_sign_contract_content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_reservation_setting */
export type Project_Reservation_Setting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "project_reservation_setting" */
export type Project_Reservation_Setting_Set_Input = {
  agent_holding_deposit_content?: InputMaybe<Scalars['String']>;
  agent_review_summary_content?: InputMaybe<Scalars['String']>;
  buyer_holding_deposit_content?: InputMaybe<Scalars['String']>;
  buyer_review_summary_content?: InputMaybe<Scalars['String']>;
  buyer_sign_contract_content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "project_reservation_setting" */
export type Project_Reservation_Setting_Stream_Cursor_Input = {
  initial_value: Project_Reservation_Setting_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Reservation_Setting_Stream_Cursor_Value_Input = {
  agent_holding_deposit_content?: InputMaybe<Scalars['String']>;
  agent_review_summary_content?: InputMaybe<Scalars['String']>;
  buyer_holding_deposit_content?: InputMaybe<Scalars['String']>;
  buyer_review_summary_content?: InputMaybe<Scalars['String']>;
  buyer_sign_contract_content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Project_Reservation_Setting_Updates = {
  _set?: InputMaybe<Project_Reservation_Setting_Set_Input>;
  where: Project_Reservation_Setting_Bool_Exp;
};

/** input type for updating data in table "project" */
export type Project_Set_Input = {
  active_project?: InputMaybe<Scalars['Boolean']>;
  background_attachment_id?: InputMaybe<Scalars['uuid']>;
  brand_bg_color?: InputMaybe<Scalars['String']>;
  brand_fg_color?: InputMaybe<Scalars['String']>;
  can_publish?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  default_head_agency_id?: InputMaybe<Scalars['uuid']>;
  delegated_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  domain_agency_id?: InputMaybe<Scalars['Int']>;
  domain_project_id?: InputMaybe<Scalars['Int']>;
  domain_verification_code?: InputMaybe<Scalars['String']>;
  eoi_enabled?: InputMaybe<Scalars['Boolean']>;
  fake?: InputMaybe<Scalars['Boolean']>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  holding_enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_use_variable_holding_deposit?: InputMaybe<Scalars['Boolean']>;
  logo_attachment_id?: InputMaybe<Scalars['uuid']>;
  map_lat_lng?: InputMaybe<Scalars['point']>;
  master_plan_attachment_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  payment_info?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['uuid']>;
  postcode?: InputMaybe<Scalars['String']>;
  project_director_contact_id?: InputMaybe<Scalars['uuid']>;
  sales_advice_vendor?: InputMaybe<Scalars['String']>;
  setting_holding_hours?: InputMaybe<Scalars['Int']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Project_Status_Enum>;
  sub_description?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Project_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  variable_holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  vendor_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "project_sources". All fields are combined with a logical 'AND'. */
export type Project_Sources_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Sources_Bool_Exp>>;
  _not?: InputMaybe<Project_Sources_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Sources_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "project_sources" */
export type Project_Sources_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "project_sources" */
export type Project_Sources_On_Conflict = {
  constraint: Project_Sources_Constraint;
  update_columns: Array<Project_Sources_Update_Column>;
  where?: InputMaybe<Project_Sources_Bool_Exp>;
};

/** Ordering options when selecting data from "project_sources". */
export type Project_Sources_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_sources */
export type Project_Sources_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "project_sources" */
export type Project_Sources_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_sources" */
export type Project_Sources_Stream_Cursor_Input = {
  initial_value: Project_Sources_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Sources_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Project_Sources_Updates = {
  _set?: InputMaybe<Project_Sources_Set_Input>;
  where: Project_Sources_Bool_Exp;
};

/** Boolean expression to filter rows from the table "project_status". All fields are combined with a logical 'AND'. */
export type Project_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Status_Bool_Exp>>;
  _not?: InputMaybe<Project_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "project_status_enum". All fields are combined with logical 'AND'. */
export type Project_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_Status_Enum>;
  _in?: InputMaybe<Array<Project_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_Status_Enum>;
  _nin?: InputMaybe<Array<Project_Status_Enum>>;
};

/** input type for inserting data into table "project_status" */
export type Project_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "project_status" */
export type Project_Status_On_Conflict = {
  constraint: Project_Status_Constraint;
  update_columns: Array<Project_Status_Update_Column>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "project_status". */
export type Project_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_status */
export type Project_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "project_status" */
export type Project_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_status" */
export type Project_Status_Stream_Cursor_Input = {
  initial_value: Project_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Project_Status_Updates = {
  _set?: InputMaybe<Project_Status_Set_Input>;
  where: Project_Status_Bool_Exp;
};

/** order by stddev() on columns of table "project" */
export type Project_Stddev_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "project" */
export type Project_Stddev_Pop_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "project" */
export type Project_Stddev_Samp_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project" */
export type Project_Stream_Cursor_Input = {
  initial_value: Project_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stream_Cursor_Value_Input = {
  active_project?: InputMaybe<Scalars['Boolean']>;
  background_attachment_id?: InputMaybe<Scalars['uuid']>;
  brand_bg_color?: InputMaybe<Scalars['String']>;
  brand_fg_color?: InputMaybe<Scalars['String']>;
  can_publish?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  default_head_agency_id?: InputMaybe<Scalars['uuid']>;
  delegated_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  domain_agency_id?: InputMaybe<Scalars['Int']>;
  domain_project_id?: InputMaybe<Scalars['Int']>;
  domain_verification_code?: InputMaybe<Scalars['String']>;
  eoi_enabled?: InputMaybe<Scalars['Boolean']>;
  fake?: InputMaybe<Scalars['Boolean']>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  holding_enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_use_variable_holding_deposit?: InputMaybe<Scalars['Boolean']>;
  logo_attachment_id?: InputMaybe<Scalars['uuid']>;
  map_lat_lng?: InputMaybe<Scalars['point']>;
  master_plan_attachment_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  payment_info?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['uuid']>;
  postcode?: InputMaybe<Scalars['String']>;
  project_director_contact_id?: InputMaybe<Scalars['uuid']>;
  sales_advice_vendor?: InputMaybe<Scalars['String']>;
  setting_holding_hours?: InputMaybe<Scalars['Int']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Project_Status_Enum>;
  sub_description?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
  total_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Project_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  variable_holding_deposit_percentage?: InputMaybe<Scalars['numeric']>;
  vendor_solicitor_contact_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "project" */
export type Project_Sum_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_type". All fields are combined with a logical 'AND'. */
export type Project_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Type_Bool_Exp>>;
  _not?: InputMaybe<Project_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "project_type_enum". All fields are combined with logical 'AND'. */
export type Project_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_Type_Enum>;
  _in?: InputMaybe<Array<Project_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_Type_Enum>;
  _nin?: InputMaybe<Array<Project_Type_Enum>>;
};

/** input type for inserting data into table "project_type" */
export type Project_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "project_type" */
export type Project_Type_Obj_Rel_Insert_Input = {
  data: Project_Type_Insert_Input;
  on_conflict?: InputMaybe<Project_Type_On_Conflict>;
};

/** on_conflict condition type for table "project_type" */
export type Project_Type_On_Conflict = {
  constraint: Project_Type_Constraint;
  update_columns: Array<Project_Type_Update_Column>;
  where?: InputMaybe<Project_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "project_type". */
export type Project_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_type */
export type Project_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "project_type" */
export type Project_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_type" */
export type Project_Type_Stream_Cursor_Input = {
  initial_value: Project_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Project_Type_Updates = {
  _set?: InputMaybe<Project_Type_Set_Input>;
  where: Project_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "project_units". All fields are combined with a logical 'AND'. */
export type Project_Units_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Units_Bool_Exp>>;
  _not?: InputMaybe<Project_Units_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Units_Bool_Exp>>;
  bg_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  bg_url?: InputMaybe<String_Comparison_Exp>;
  brand_bg_color?: InputMaybe<String_Comparison_Exp>;
  brand_fg_color?: InputMaybe<String_Comparison_Exp>;
  delegated_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  dev_org_logo_url?: InputMaybe<String_Comparison_Exp>;
  dev_org_name?: InputMaybe<String_Comparison_Exp>;
  listing_available_count?: InputMaybe<Bigint_Comparison_Exp>;
  listing_count?: InputMaybe<Bigint_Comparison_Exp>;
  logo_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  logo_thumbnail_url_x2?: InputMaybe<String_Comparison_Exp>;
  logo_url?: InputMaybe<String_Comparison_Exp>;
  managed_dev_logo_url?: InputMaybe<String_Comparison_Exp>;
  managed_dev_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

export type Project_Units_For_User_Args = {
  for_user_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting data into table "project_units" */
export type Project_Units_Insert_Input = {
  bg_thumbnail_url?: InputMaybe<Scalars['String']>;
  bg_url?: InputMaybe<Scalars['String']>;
  brand_bg_color?: InputMaybe<Scalars['String']>;
  brand_fg_color?: InputMaybe<Scalars['String']>;
  delegated_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  dev_org_logo_url?: InputMaybe<Scalars['String']>;
  dev_org_name?: InputMaybe<Scalars['String']>;
  listing_available_count?: InputMaybe<Scalars['bigint']>;
  listing_count?: InputMaybe<Scalars['bigint']>;
  logo_thumbnail_url?: InputMaybe<Scalars['String']>;
  logo_thumbnail_url_x2?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  managed_dev_logo_url?: InputMaybe<Scalars['String']>;
  managed_dev_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "project_units" */
export type Project_Units_Obj_Rel_Insert_Input = {
  data: Project_Units_Insert_Input;
};

/** Ordering options when selecting data from "project_units". */
export type Project_Units_Order_By = {
  bg_thumbnail_url?: InputMaybe<Order_By>;
  bg_url?: InputMaybe<Order_By>;
  brand_bg_color?: InputMaybe<Order_By>;
  brand_fg_color?: InputMaybe<Order_By>;
  delegated_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dev_org_logo_url?: InputMaybe<Order_By>;
  dev_org_name?: InputMaybe<Order_By>;
  listing_available_count?: InputMaybe<Order_By>;
  listing_count?: InputMaybe<Order_By>;
  logo_thumbnail_url?: InputMaybe<Order_By>;
  logo_thumbnail_url_x2?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  managed_dev_logo_url?: InputMaybe<Order_By>;
  managed_dev_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_units" */
export type Project_Units_Stream_Cursor_Input = {
  initial_value: Project_Units_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Units_Stream_Cursor_Value_Input = {
  bg_thumbnail_url?: InputMaybe<Scalars['String']>;
  bg_url?: InputMaybe<Scalars['String']>;
  brand_bg_color?: InputMaybe<Scalars['String']>;
  brand_fg_color?: InputMaybe<Scalars['String']>;
  delegated_by?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  dev_org_logo_url?: InputMaybe<Scalars['String']>;
  dev_org_name?: InputMaybe<Scalars['String']>;
  listing_available_count?: InputMaybe<Scalars['bigint']>;
  listing_count?: InputMaybe<Scalars['bigint']>;
  logo_thumbnail_url?: InputMaybe<Scalars['String']>;
  logo_thumbnail_url_x2?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  managed_dev_logo_url?: InputMaybe<Scalars['String']>;
  managed_dev_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Project_Updates = {
  _inc?: InputMaybe<Project_Inc_Input>;
  _set?: InputMaybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};

/** order by var_pop() on columns of table "project" */
export type Project_Var_Pop_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "project" */
export type Project_Var_Samp_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "project" */
export type Project_Variance_Order_By = {
  domain_agency_id?: InputMaybe<Order_By>;
  domain_project_id?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  setting_holding_hours?: InputMaybe<Order_By>;
  total_deposit_percentage?: InputMaybe<Order_By>;
  variable_holding_deposit_percentage?: InputMaybe<Order_By>;
};

export type Property_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Property_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Property_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Property_Aggregate_Bool_Exp_Count>;
};

export type Property_Aggregate_Bool_Exp_Bool_And = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Property_Select_Column_Property_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "property" */
export type Property_Aggregate_Order_By = {
  avg?: InputMaybe<Property_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Max_Order_By>;
  min?: InputMaybe<Property_Min_Order_By>;
  stddev?: InputMaybe<Property_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Property_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Property_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Property_Sum_Order_By>;
  var_pop?: InputMaybe<Property_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Property_Var_Samp_Order_By>;
  variance?: InputMaybe<Property_Variance_Order_By>;
};

export type Property_Allocation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Property_Allocation_Aggregate_Bool_Exp_Count>;
};

export type Property_Allocation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Allocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "property_allocation" */
export type Property_Allocation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Allocation_Max_Order_By>;
  min?: InputMaybe<Property_Allocation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "property_allocation" */
export type Property_Allocation_Arr_Rel_Insert_Input = {
  data: Array<Property_Allocation_Insert_Input>;
};

/** Boolean expression to filter rows from the table "property_allocation". All fields are combined with a logical 'AND'. */
export type Property_Allocation_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Allocation_Bool_Exp>>;
  _not?: InputMaybe<Property_Allocation_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Allocation_Bool_Exp>>;
  allocated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  allocated_to_org_id?: InputMaybe<Uuid_Comparison_Exp>;
  allocated_to_org_name?: InputMaybe<String_Comparison_Exp>;
  allocation_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  unit_allocation_count?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "property_allocation_count". All fields are combined with a logical 'AND'. */
export type Property_Allocation_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Allocation_Count_Bool_Exp>>;
  _not?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Allocation_Count_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "property_allocation_count" */
export type Property_Allocation_Count_Insert_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting object relation for remote table "property_allocation_count" */
export type Property_Allocation_Count_Obj_Rel_Insert_Input = {
  data: Property_Allocation_Count_Insert_Input;
};

/** Ordering options when selecting data from "property_allocation_count". */
export type Property_Allocation_Count_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_allocation_count" */
export type Property_Allocation_Count_Stream_Cursor_Input = {
  initial_value: Property_Allocation_Count_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Allocation_Count_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting data into table "property_allocation" */
export type Property_Allocation_Insert_Input = {
  allocated_at?: InputMaybe<Scalars['timestamptz']>;
  allocated_to_org_id?: InputMaybe<Scalars['uuid']>;
  allocated_to_org_name?: InputMaybe<Scalars['String']>;
  allocation_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  unit_allocation_count?: InputMaybe<Unit_Allocation_Count_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "property_allocation" */
export type Property_Allocation_Max_Order_By = {
  allocated_at?: InputMaybe<Order_By>;
  allocated_to_org_id?: InputMaybe<Order_By>;
  allocated_to_org_name?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "property_allocation" */
export type Property_Allocation_Min_Order_By = {
  allocated_at?: InputMaybe<Order_By>;
  allocated_to_org_id?: InputMaybe<Order_By>;
  allocated_to_org_name?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "property_allocation". */
export type Property_Allocation_Order_By = {
  allocated_at?: InputMaybe<Order_By>;
  allocated_to_org_id?: InputMaybe<Order_By>;
  allocated_to_org_name?: InputMaybe<Order_By>;
  allocation_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unit_allocation_count?: InputMaybe<Unit_Allocation_Count_Order_By>;
};

/** Streaming cursor of the table "property_allocation" */
export type Property_Allocation_Stream_Cursor_Input = {
  initial_value: Property_Allocation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Allocation_Stream_Cursor_Value_Input = {
  allocated_at?: InputMaybe<Scalars['timestamptz']>;
  allocated_to_org_id?: InputMaybe<Scalars['uuid']>;
  allocated_to_org_name?: InputMaybe<Scalars['String']>;
  allocation_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** input type for inserting array relation for remote table "property" */
export type Property_Arr_Rel_Insert_Input = {
  data: Array<Property_Insert_Input>;
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

/** order by avg() on columns of table "property" */
export type Property_Avg_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
export type Property_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Bool_Exp>>;
  _not?: InputMaybe<Property_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Bool_Exp>>;
  acl_listing_agent_member?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
  acl_listing_agent_member_aggregate?: InputMaybe<Acl_Listing_Agent_Member_Aggregate_Bool_Exp>;
  acl_listing_master_agent?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
  acl_listing_master_agent_aggregate?: InputMaybe<Acl_Listing_Master_Agent_Aggregate_Bool_Exp>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Bool_Exp>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Bool_Exp>;
  acl_property_allocation?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
  acl_property_allocation_aggregate?: InputMaybe<Acl_Property_Allocation_Aggregate_Bool_Exp>;
  acl_property_allocation_count?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  apartment_plan_object?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
  area_plots?: InputMaybe<Area_Plot_Bool_Exp>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Bool_Exp>;
  aspect?: InputMaybe<String_Comparison_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  bathroom?: InputMaybe<Numeric_Comparison_Exp>;
  bedroom?: InputMaybe<Numeric_Comparison_Exp>;
  completion_status?: InputMaybe<Property_Completion_Status_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  enable_unit_preferences?: InputMaybe<Boolean_Comparison_Exp>;
  external_area?: InputMaybe<Numeric_Comparison_Exp>;
  features?: InputMaybe<Feature_Bool_Exp>;
  features_aggregate?: InputMaybe<Feature_Aggregate_Bool_Exp>;
  floor_plan?: InputMaybe<Attachment_Bool_Exp>;
  floor_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  garage?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_area?: InputMaybe<Numeric_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  level_number?: InputMaybe<Numeric_Comparison_Exp>;
  linen_plan?: InputMaybe<Attachment_Bool_Exp>;
  linen_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  listings?: InputMaybe<Listing_Bool_Exp>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Bool_Exp>;
  lot?: InputMaybe<Int_Comparison_Exp>;
  lot_area?: InputMaybe<Numeric_Comparison_Exp>;
  lot_depth?: InputMaybe<Numeric_Comparison_Exp>;
  lot_suffix?: InputMaybe<String_Comparison_Exp>;
  lot_width?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  preferences?: InputMaybe<Preference_Bool_Exp>;
  preferences_aggregate?: InputMaybe<Preference_Aggregate_Bool_Exp>;
  product_type?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_allocation?: InputMaybe<Property_Allocation_Bool_Exp>;
  property_allocation_aggregate?: InputMaybe<Property_Allocation_Aggregate_Bool_Exp>;
  property_allocation_count?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
  property_completion_status?: InputMaybe<Property_Completion_Status_Bool_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_type?: InputMaybe<Property_Type_Bool_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  storage?: InputMaybe<Boolean_Comparison_Exp>;
  storeys?: InputMaybe<Int_Comparison_Exp>;
  street_name?: InputMaybe<String_Comparison_Exp>;
  street_number?: InputMaybe<String_Comparison_Exp>;
  total_area?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<Property_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_completion_status". All fields are combined with a logical 'AND'. */
export type Property_Completion_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Completion_Status_Bool_Exp>>;
  _not?: InputMaybe<Property_Completion_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Completion_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  properties?: InputMaybe<Property_Bool_Exp>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "property_completion_status_enum". All fields are combined with logical 'AND'. */
export type Property_Completion_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Completion_Status_Enum>;
  _in?: InputMaybe<Array<Property_Completion_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Property_Completion_Status_Enum>;
  _nin?: InputMaybe<Array<Property_Completion_Status_Enum>>;
};

/** input type for inserting data into table "property_completion_status" */
export type Property_Completion_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "property_completion_status" */
export type Property_Completion_Status_Obj_Rel_Insert_Input = {
  data: Property_Completion_Status_Insert_Input;
  on_conflict?: InputMaybe<Property_Completion_Status_On_Conflict>;
};

/** on_conflict condition type for table "property_completion_status" */
export type Property_Completion_Status_On_Conflict = {
  constraint: Property_Completion_Status_Constraint;
  update_columns: Array<Property_Completion_Status_Update_Column>;
  where?: InputMaybe<Property_Completion_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "property_completion_status". */
export type Property_Completion_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_completion_status */
export type Property_Completion_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "property_completion_status" */
export type Property_Completion_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "property_completion_status" */
export type Property_Completion_Status_Stream_Cursor_Input = {
  initial_value: Property_Completion_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Completion_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Property_Completion_Status_Updates = {
  _set?: InputMaybe<Property_Completion_Status_Set_Input>;
  where: Property_Completion_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "property_detail". All fields are combined with a logical 'AND'. */
export type Property_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Detail_Bool_Exp>>;
  _not?: InputMaybe<Property_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Detail_Bool_Exp>>;
  access_type?: InputMaybe<String_Comparison_Exp>;
  allocation_agency_count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  listings?: InputMaybe<Listing_Bool_Exp>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  property_group?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_type?: InputMaybe<Property_Type_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "property_detail". */
export type Property_Detail_Order_By = {
  access_type?: InputMaybe<Order_By>;
  allocation_agency_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Property_Type_Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_detail" */
export type Property_Detail_Stream_Cursor_Input = {
  initial_value: Property_Detail_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Detail_Stream_Cursor_Value_Input = {
  access_type?: InputMaybe<Scalars['String']>;
  allocation_agency_count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Property_Group_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Property_Group_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Property_Group_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Property_Group_Aggregate_Bool_Exp_Count>;
};

export type Property_Group_Aggregate_Bool_Exp_Bool_And = {
  arguments: Property_Group_Select_Column_Property_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Group_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_Group_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Property_Group_Select_Column_Property_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Group_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Property_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Property_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Property_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "property_group" */
export type Property_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Property_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Property_Group_Max_Order_By>;
  min?: InputMaybe<Property_Group_Min_Order_By>;
  stddev?: InputMaybe<Property_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Property_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Property_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Property_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Property_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Property_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Property_Group_Variance_Order_By>;
};

/** Boolean expression to filter rows from the table "property_group_allocation_detail_view". All fields are combined with a logical 'AND'. */
export type Property_Group_Allocation_Detail_View_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Bool_Exp>>;
  _not?: InputMaybe<Property_Group_Allocation_Detail_View_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Bool_Exp>>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_access_active?: InputMaybe<Boolean_Comparison_Exp>;
  project_allocation_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  property_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_access_active?: InputMaybe<Boolean_Comparison_Exp>;
  stage_allocation_id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_include_listings?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "property_group_allocation_detail_view". */
export type Property_Group_Allocation_Detail_View_Order_By = {
  organisation_id?: InputMaybe<Order_By>;
  project_access_active?: InputMaybe<Order_By>;
  project_allocation_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  stage_access_active?: InputMaybe<Order_By>;
  stage_allocation_id?: InputMaybe<Order_By>;
  stage_include_listings?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_group_allocation_detail_view" */
export type Property_Group_Allocation_Detail_View_Stream_Cursor_Input = {
  initial_value: Property_Group_Allocation_Detail_View_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Group_Allocation_Detail_View_Stream_Cursor_Value_Input = {
  organisation_id?: InputMaybe<Scalars['uuid']>;
  project_access_active?: InputMaybe<Scalars['Boolean']>;
  project_allocation_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  stage_access_active?: InputMaybe<Scalars['Boolean']>;
  stage_allocation_id?: InputMaybe<Scalars['uuid']>;
  stage_include_listings?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "property_group_allocation_view". All fields are combined with a logical 'AND'. */
export type Property_Group_Allocation_View_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Group_Allocation_View_Bool_Exp>>;
  _not?: InputMaybe<Property_Group_Allocation_View_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Group_Allocation_View_Bool_Exp>>;
  exclusive_count?: InputMaybe<Bigint_Comparison_Exp>;
  open_count?: InputMaybe<Bigint_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  shared_count?: InputMaybe<Bigint_Comparison_Exp>;
  stage_id?: InputMaybe<Uuid_Comparison_Exp>;
  stage_name?: InputMaybe<String_Comparison_Exp>;
  stage_parent_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "property_group_allocation_view". */
export type Property_Group_Allocation_View_Order_By = {
  exclusive_count?: InputMaybe<Order_By>;
  open_count?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  shared_count?: InputMaybe<Order_By>;
  stage_id?: InputMaybe<Order_By>;
  stage_name?: InputMaybe<Order_By>;
  stage_parent_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_group_allocation_view" */
export type Property_Group_Allocation_View_Stream_Cursor_Input = {
  initial_value: Property_Group_Allocation_View_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Group_Allocation_View_Stream_Cursor_Value_Input = {
  exclusive_count?: InputMaybe<Scalars['bigint']>;
  open_count?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  shared_count?: InputMaybe<Scalars['bigint']>;
  stage_id?: InputMaybe<Scalars['uuid']>;
  stage_name?: InputMaybe<Scalars['String']>;
  stage_parent_id?: InputMaybe<Scalars['uuid']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Property_Group_Append_Input = {
  agency_agreement_settings?: InputMaybe<Scalars['jsonb']>;
  digital_contract_settings?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "property_group" */
export type Property_Group_Arr_Rel_Insert_Input = {
  data: Array<Property_Group_Insert_Input>;
  on_conflict?: InputMaybe<Property_Group_On_Conflict>;
};

/** order by avg() on columns of table "property_group" */
export type Property_Group_Avg_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property_group". All fields are combined with a logical 'AND'. */
export type Property_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Group_Bool_Exp>>;
  _not?: InputMaybe<Property_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Group_Bool_Exp>>;
  acl_allocation_edit?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
  acl_allocation_edit_aggregate?: InputMaybe<Acl_Allocation_Edit_Aggregate_Bool_Exp>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Bool_Exp>;
  acl_listing_property_parent?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
  acl_listing_property_parent_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Bool_Exp>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Bool_Exp>;
  acl_listing_property_team_parent?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
  acl_listing_property_team_parent_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Bool_Exp>;
  acl_stage_agent_member?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
  acl_stage_agent_member_aggregate?: InputMaybe<Acl_Stage_Agent_Member_Aggregate_Bool_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  agency_agreement_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  allocations?: InputMaybe<Allocation_Bool_Exp>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  apartment_plan_building?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
  apartment_stat?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
  area_plots?: InputMaybe<Area_Plot_Bool_Exp>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Bool_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  can_publish?: InputMaybe<Boolean_Comparison_Exp>;
  completion?: InputMaybe<Date_Comparison_Exp>;
  completion_status?: InputMaybe<Property_Group_Completion_Status_Enum_Comparison_Exp>;
  construction_start?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  digital_contract_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  enable_reservation?: InputMaybe<Boolean_Comparison_Exp>;
  enable_settlement_status?: InputMaybe<Boolean_Comparison_Exp>;
  enabled_registration?: InputMaybe<Boolean_Comparison_Exp>;
  eoi_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  holding_deposit?: InputMaybe<Numeric_Comparison_Exp>;
  holding_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latitude?: InputMaybe<String_Comparison_Exp>;
  longitude?: InputMaybe<String_Comparison_Exp>;
  member_project_allocates?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
  member_project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_allocations?: InputMaybe<Allocation_Bool_Exp>;
  parent_allocations_aggregate?: InputMaybe<Allocation_Aggregate_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_attachment?: InputMaybe<Attachment_Bool_Exp>;
  plan_attachment_id?: InputMaybe<Uuid_Comparison_Exp>;
  preference_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  preferences?: InputMaybe<Preference_Bool_Exp>;
  preferences_aggregate?: InputMaybe<Preference_Aggregate_Bool_Exp>;
  price_from_range?: InputMaybe<Numeric_Comparison_Exp>;
  price_to_range?: InputMaybe<Numeric_Comparison_Exp>;
  product_type?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_apartment_levels?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
  project_apartment_levels_aggregate?: InputMaybe<Project_Apartment_Level_Aggregate_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  properties?: InputMaybe<Property_Bool_Exp>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  property_group_children?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_children_aggregate?: InputMaybe<Property_Group_Aggregate_Bool_Exp>;
  property_group_completion_status?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
  property_group_parent?: InputMaybe<Property_Group_Bool_Exp>;
  property_group_type?: InputMaybe<Property_Group_Type_Bool_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  show_price_range?: InputMaybe<Boolean_Comparison_Exp>;
  show_pricing?: InputMaybe<Boolean_Comparison_Exp>;
  street_name?: InputMaybe<String_Comparison_Exp>;
  street_number?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Property_Group_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_solicitor?: InputMaybe<Organisation_Bool_Exp>;
  vendor_solicitor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "property_group_completion_status". All fields are combined with a logical 'AND'. */
export type Property_Group_Completion_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Group_Completion_Status_Bool_Exp>>;
  _not?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Group_Completion_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  property_groups?: InputMaybe<Property_Group_Bool_Exp>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "property_group_completion_status_enum". All fields are combined with logical 'AND'. */
export type Property_Group_Completion_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Group_Completion_Status_Enum>;
  _in?: InputMaybe<Array<Property_Group_Completion_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Property_Group_Completion_Status_Enum>;
  _nin?: InputMaybe<Array<Property_Group_Completion_Status_Enum>>;
};

/** input type for inserting data into table "property_group_completion_status" */
export type Property_Group_Completion_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  property_groups?: InputMaybe<Property_Group_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "property_group_completion_status" */
export type Property_Group_Completion_Status_Obj_Rel_Insert_Input = {
  data: Property_Group_Completion_Status_Insert_Input;
  on_conflict?: InputMaybe<Property_Group_Completion_Status_On_Conflict>;
};

/** on_conflict condition type for table "property_group_completion_status" */
export type Property_Group_Completion_Status_On_Conflict = {
  constraint: Property_Group_Completion_Status_Constraint;
  update_columns: Array<Property_Group_Completion_Status_Update_Column>;
  where?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "property_group_completion_status". */
export type Property_Group_Completion_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_group_completion_status */
export type Property_Group_Completion_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "property_group_completion_status" */
export type Property_Group_Completion_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "property_group_completion_status" */
export type Property_Group_Completion_Status_Stream_Cursor_Input = {
  initial_value: Property_Group_Completion_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Group_Completion_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Property_Group_Completion_Status_Updates = {
  _set?: InputMaybe<Property_Group_Completion_Status_Set_Input>;
  where: Property_Group_Completion_Status_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Property_Group_Delete_At_Path_Input = {
  agency_agreement_settings?: InputMaybe<Array<Scalars['String']>>;
  digital_contract_settings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Property_Group_Delete_Elem_Input = {
  agency_agreement_settings?: InputMaybe<Scalars['Int']>;
  digital_contract_settings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Property_Group_Delete_Key_Input = {
  agency_agreement_settings?: InputMaybe<Scalars['String']>;
  digital_contract_settings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "property_group" */
export type Property_Group_Inc_Input = {
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  price_from_range?: InputMaybe<Scalars['numeric']>;
  price_to_range?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "property_group" */
export type Property_Group_Insert_Input = {
  acl_allocation_edit?: InputMaybe<Acl_Allocation_Edit_Arr_Rel_Insert_Input>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Arr_Rel_Insert_Input>;
  acl_listing_property_parent?: InputMaybe<Acl_Listing_Property_Arr_Rel_Insert_Input>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Arr_Rel_Insert_Input>;
  acl_listing_property_team_parent?: InputMaybe<Acl_Listing_Property_Team_Arr_Rel_Insert_Input>;
  acl_stage_agent_member?: InputMaybe<Acl_Stage_Agent_Member_Arr_Rel_Insert_Input>;
  address?: InputMaybe<Scalars['String']>;
  agency_agreement_settings?: InputMaybe<Scalars['jsonb']>;
  allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  apartment_plan_building?: InputMaybe<Apartment_Plan_Building_Obj_Rel_Insert_Input>;
  apartment_stat?: InputMaybe<Project_Apartment_Stat_Obj_Rel_Insert_Input>;
  area_plots?: InputMaybe<Area_Plot_Arr_Rel_Insert_Input>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  can_publish?: InputMaybe<Scalars['Boolean']>;
  completion?: InputMaybe<Scalars['date']>;
  completion_status?: InputMaybe<Property_Group_Completion_Status_Enum>;
  construction_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  digital_contract_settings?: InputMaybe<Scalars['jsonb']>;
  enable_reservation?: InputMaybe<Scalars['Boolean']>;
  enable_settlement_status?: InputMaybe<Scalars['Boolean']>;
  enabled_registration?: InputMaybe<Scalars['Boolean']>;
  eoi_enabled?: InputMaybe<Scalars['Boolean']>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  holding_enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  member_project_allocates?: InputMaybe<Member_Project_Allocate_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  parent_allocations?: InputMaybe<Allocation_Arr_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  plan_attachment?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  plan_attachment_id?: InputMaybe<Scalars['uuid']>;
  preference_enabled?: InputMaybe<Scalars['Boolean']>;
  preferences?: InputMaybe<Preference_Arr_Rel_Insert_Input>;
  price_from_range?: InputMaybe<Scalars['numeric']>;
  price_to_range?: InputMaybe<Scalars['numeric']>;
  product_type?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_apartment_levels?: InputMaybe<Project_Apartment_Level_Arr_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  property_group_children?: InputMaybe<Property_Group_Arr_Rel_Insert_Input>;
  property_group_completion_status?: InputMaybe<Property_Group_Completion_Status_Obj_Rel_Insert_Input>;
  property_group_parent?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_type?: InputMaybe<Property_Group_Type_Obj_Rel_Insert_Input>;
  sf_id?: InputMaybe<Scalars['String']>;
  show_price_range?: InputMaybe<Scalars['Boolean']>;
  show_pricing?: InputMaybe<Scalars['Boolean']>;
  street_name?: InputMaybe<Scalars['String']>;
  street_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Group_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "property_group" */
export type Property_Group_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  completion?: InputMaybe<Order_By>;
  construction_start?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  plan_attachment_id?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  street_name?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "property_group" */
export type Property_Group_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  completion?: InputMaybe<Order_By>;
  construction_start?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  plan_attachment_id?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  street_name?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "property_group" */
export type Property_Group_Obj_Rel_Insert_Input = {
  data: Property_Group_Insert_Input;
  on_conflict?: InputMaybe<Property_Group_On_Conflict>;
};

/** on_conflict condition type for table "property_group" */
export type Property_Group_On_Conflict = {
  constraint: Property_Group_Constraint;
  update_columns: Array<Property_Group_Update_Column>;
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "property_group". */
export type Property_Group_Order_By = {
  acl_allocation_edit_aggregate?: InputMaybe<Acl_Allocation_Edit_Aggregate_Order_By>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Order_By>;
  acl_listing_property_parent_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Order_By>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Order_By>;
  acl_listing_property_team_parent_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Order_By>;
  acl_stage_agent_member_aggregate?: InputMaybe<Acl_Stage_Agent_Member_Aggregate_Order_By>;
  address?: InputMaybe<Order_By>;
  agency_agreement_settings?: InputMaybe<Order_By>;
  allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  apartment_plan_building?: InputMaybe<Apartment_Plan_Building_Order_By>;
  apartment_stat?: InputMaybe<Project_Apartment_Stat_Order_By>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  can_publish?: InputMaybe<Order_By>;
  completion?: InputMaybe<Order_By>;
  completion_status?: InputMaybe<Order_By>;
  construction_start?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  digital_contract_settings?: InputMaybe<Order_By>;
  enable_reservation?: InputMaybe<Order_By>;
  enable_settlement_status?: InputMaybe<Order_By>;
  enabled_registration?: InputMaybe<Order_By>;
  eoi_enabled?: InputMaybe<Order_By>;
  holding_deposit?: InputMaybe<Order_By>;
  holding_enabled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  member_project_allocates_aggregate?: InputMaybe<Member_Project_Allocate_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  parent_allocations_aggregate?: InputMaybe<Allocation_Aggregate_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  plan_attachment?: InputMaybe<Attachment_Order_By>;
  plan_attachment_id?: InputMaybe<Order_By>;
  preference_enabled?: InputMaybe<Order_By>;
  preferences_aggregate?: InputMaybe<Preference_Aggregate_Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_apartment_levels_aggregate?: InputMaybe<Project_Apartment_Level_Aggregate_Order_By>;
  project_id?: InputMaybe<Order_By>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  property_group_children_aggregate?: InputMaybe<Property_Group_Aggregate_Order_By>;
  property_group_completion_status?: InputMaybe<Property_Group_Completion_Status_Order_By>;
  property_group_parent?: InputMaybe<Property_Group_Order_By>;
  property_group_type?: InputMaybe<Property_Group_Type_Order_By>;
  sf_id?: InputMaybe<Order_By>;
  show_price_range?: InputMaybe<Order_By>;
  show_pricing?: InputMaybe<Order_By>;
  street_name?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Organisation_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_solicitor?: InputMaybe<Organisation_Order_By>;
  vendor_solicitor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_group */
export type Property_Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Property_Group_Prepend_Input = {
  agency_agreement_settings?: InputMaybe<Scalars['jsonb']>;
  digital_contract_settings?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "property_group" */
export type Property_Group_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  agency_agreement_settings?: InputMaybe<Scalars['jsonb']>;
  can_publish?: InputMaybe<Scalars['Boolean']>;
  completion?: InputMaybe<Scalars['date']>;
  completion_status?: InputMaybe<Property_Group_Completion_Status_Enum>;
  construction_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_contract_settings?: InputMaybe<Scalars['jsonb']>;
  enable_reservation?: InputMaybe<Scalars['Boolean']>;
  enable_settlement_status?: InputMaybe<Scalars['Boolean']>;
  enabled_registration?: InputMaybe<Scalars['Boolean']>;
  eoi_enabled?: InputMaybe<Scalars['Boolean']>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  holding_enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  plan_attachment_id?: InputMaybe<Scalars['uuid']>;
  preference_enabled?: InputMaybe<Scalars['Boolean']>;
  price_from_range?: InputMaybe<Scalars['numeric']>;
  price_to_range?: InputMaybe<Scalars['numeric']>;
  product_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  show_price_range?: InputMaybe<Scalars['Boolean']>;
  show_pricing?: InputMaybe<Scalars['Boolean']>;
  street_name?: InputMaybe<Scalars['String']>;
  street_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Group_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "property_group" */
export type Property_Group_Stddev_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "property_group" */
export type Property_Group_Stddev_Pop_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "property_group" */
export type Property_Group_Stddev_Samp_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property_group" */
export type Property_Group_Stream_Cursor_Input = {
  initial_value: Property_Group_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Group_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  agency_agreement_settings?: InputMaybe<Scalars['jsonb']>;
  can_publish?: InputMaybe<Scalars['Boolean']>;
  completion?: InputMaybe<Scalars['date']>;
  completion_status?: InputMaybe<Property_Group_Completion_Status_Enum>;
  construction_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  digital_contract_settings?: InputMaybe<Scalars['jsonb']>;
  enable_reservation?: InputMaybe<Scalars['Boolean']>;
  enable_settlement_status?: InputMaybe<Scalars['Boolean']>;
  enabled_registration?: InputMaybe<Scalars['Boolean']>;
  eoi_enabled?: InputMaybe<Scalars['Boolean']>;
  holding_deposit?: InputMaybe<Scalars['numeric']>;
  holding_enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['uuid']>;
  plan_attachment_id?: InputMaybe<Scalars['uuid']>;
  preference_enabled?: InputMaybe<Scalars['Boolean']>;
  price_from_range?: InputMaybe<Scalars['numeric']>;
  price_to_range?: InputMaybe<Scalars['numeric']>;
  product_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  show_price_range?: InputMaybe<Scalars['Boolean']>;
  show_pricing?: InputMaybe<Scalars['Boolean']>;
  street_name?: InputMaybe<Scalars['String']>;
  street_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Group_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['uuid']>;
  vendor_solicitor_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "property_group" */
export type Property_Group_Sum_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property_group_type". All fields are combined with a logical 'AND'. */
export type Property_Group_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Group_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Group_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Group_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  property_groups?: InputMaybe<Property_Group_Bool_Exp>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "property_group_type_enum". All fields are combined with logical 'AND'. */
export type Property_Group_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Group_Type_Enum>;
  _in?: InputMaybe<Array<Property_Group_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Property_Group_Type_Enum>;
  _nin?: InputMaybe<Array<Property_Group_Type_Enum>>;
};

/** input type for inserting data into table "property_group_type" */
export type Property_Group_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  property_groups?: InputMaybe<Property_Group_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "property_group_type" */
export type Property_Group_Type_Obj_Rel_Insert_Input = {
  data: Property_Group_Type_Insert_Input;
  on_conflict?: InputMaybe<Property_Group_Type_On_Conflict>;
};

/** on_conflict condition type for table "property_group_type" */
export type Property_Group_Type_On_Conflict = {
  constraint: Property_Group_Type_Constraint;
  update_columns: Array<Property_Group_Type_Update_Column>;
  where?: InputMaybe<Property_Group_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "property_group_type". */
export type Property_Group_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  property_groups_aggregate?: InputMaybe<Property_Group_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_group_type */
export type Property_Group_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "property_group_type" */
export type Property_Group_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "property_group_type" */
export type Property_Group_Type_Stream_Cursor_Input = {
  initial_value: Property_Group_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Group_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Property_Group_Type_Updates = {
  _set?: InputMaybe<Property_Group_Type_Set_Input>;
  where: Property_Group_Type_Bool_Exp;
};

export type Property_Group_Updates = {
  _append?: InputMaybe<Property_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Property_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Property_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Property_Group_Delete_Key_Input>;
  _inc?: InputMaybe<Property_Group_Inc_Input>;
  _prepend?: InputMaybe<Property_Group_Prepend_Input>;
  _set?: InputMaybe<Property_Group_Set_Input>;
  where: Property_Group_Bool_Exp;
};

/** order by var_pop() on columns of table "property_group" */
export type Property_Group_Var_Pop_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "property_group" */
export type Property_Group_Var_Samp_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "property_group" */
export type Property_Group_Variance_Order_By = {
  holding_deposit?: InputMaybe<Order_By>;
  price_from_range?: InputMaybe<Order_By>;
  price_to_range?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "property" */
export type Property_Inc_Input = {
  bathroom?: InputMaybe<Scalars['numeric']>;
  bedroom?: InputMaybe<Scalars['numeric']>;
  external_area?: InputMaybe<Scalars['numeric']>;
  garage?: InputMaybe<Scalars['numeric']>;
  internal_area?: InputMaybe<Scalars['numeric']>;
  level_number?: InputMaybe<Scalars['numeric']>;
  lot?: InputMaybe<Scalars['Int']>;
  lot_area?: InputMaybe<Scalars['numeric']>;
  lot_depth?: InputMaybe<Scalars['numeric']>;
  lot_width?: InputMaybe<Scalars['numeric']>;
  storeys?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "property" */
export type Property_Insert_Input = {
  acl_listing_agent_member?: InputMaybe<Acl_Listing_Agent_Member_Arr_Rel_Insert_Input>;
  acl_listing_master_agent?: InputMaybe<Acl_Listing_Master_Agent_Arr_Rel_Insert_Input>;
  acl_listing_property?: InputMaybe<Acl_Listing_Property_Arr_Rel_Insert_Input>;
  acl_listing_property_team?: InputMaybe<Acl_Listing_Property_Team_Arr_Rel_Insert_Input>;
  acl_property_allocation?: InputMaybe<Acl_Property_Allocation_Arr_Rel_Insert_Input>;
  acl_property_allocation_count?: InputMaybe<Acl_Property_Allocation_Count_Obj_Rel_Insert_Input>;
  address?: InputMaybe<Scalars['String']>;
  apartment_plan_object?: InputMaybe<Apartment_Plan_Property_Obj_Rel_Insert_Input>;
  area_plots?: InputMaybe<Area_Plot_Arr_Rel_Insert_Input>;
  aspect?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  bathroom?: InputMaybe<Scalars['numeric']>;
  bedroom?: InputMaybe<Scalars['numeric']>;
  completion_status?: InputMaybe<Property_Completion_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  enable_unit_preferences?: InputMaybe<Scalars['Boolean']>;
  external_area?: InputMaybe<Scalars['numeric']>;
  features?: InputMaybe<Feature_Arr_Rel_Insert_Input>;
  floor_plan?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  floor_plan_id?: InputMaybe<Scalars['uuid']>;
  garage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_area?: InputMaybe<Scalars['numeric']>;
  level?: InputMaybe<Scalars['String']>;
  level_number?: InputMaybe<Scalars['numeric']>;
  linen_plan?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  linen_plan_id?: InputMaybe<Scalars['uuid']>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  listings?: InputMaybe<Listing_Arr_Rel_Insert_Input>;
  lot?: InputMaybe<Scalars['Int']>;
  lot_area?: InputMaybe<Scalars['numeric']>;
  lot_depth?: InputMaybe<Scalars['numeric']>;
  lot_suffix?: InputMaybe<Scalars['String']>;
  lot_width?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<Preference_Arr_Rel_Insert_Input>;
  product_type?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_allocation?: InputMaybe<Property_Allocation_Arr_Rel_Insert_Input>;
  property_allocation_count?: InputMaybe<Property_Allocation_Count_Obj_Rel_Insert_Input>;
  property_completion_status?: InputMaybe<Property_Completion_Status_Obj_Rel_Insert_Input>;
  property_group?: InputMaybe<Property_Group_Obj_Rel_Insert_Input>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  property_type?: InputMaybe<Property_Type_Obj_Rel_Insert_Input>;
  sf_id?: InputMaybe<Scalars['String']>;
  storage?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Int']>;
  street_name?: InputMaybe<Scalars['String']>;
  street_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "property" */
export type Property_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  aspect?: InputMaybe<Order_By>;
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  floor_plan_id?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  linen_plan_id?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_suffix?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
  street_name?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "property" */
export type Property_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  aspect?: InputMaybe<Order_By>;
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  floor_plan_id?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  linen_plan_id?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_suffix?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
  street_name?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "property" */
export type Property_Obj_Rel_Insert_Input = {
  data: Property_Insert_Input;
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

/** on_conflict condition type for table "property" */
export type Property_On_Conflict = {
  constraint: Property_Constraint;
  update_columns: Array<Property_Update_Column>;
  where?: InputMaybe<Property_Bool_Exp>;
};

/** Ordering options when selecting data from "property". */
export type Property_Order_By = {
  acl_listing_agent_member_aggregate?: InputMaybe<Acl_Listing_Agent_Member_Aggregate_Order_By>;
  acl_listing_master_agent_aggregate?: InputMaybe<Acl_Listing_Master_Agent_Aggregate_Order_By>;
  acl_listing_property_aggregate?: InputMaybe<Acl_Listing_Property_Aggregate_Order_By>;
  acl_listing_property_team_aggregate?: InputMaybe<Acl_Listing_Property_Team_Aggregate_Order_By>;
  acl_property_allocation_aggregate?: InputMaybe<Acl_Property_Allocation_Aggregate_Order_By>;
  acl_property_allocation_count?: InputMaybe<Acl_Property_Allocation_Count_Order_By>;
  address?: InputMaybe<Order_By>;
  apartment_plan_object?: InputMaybe<Apartment_Plan_Property_Order_By>;
  area_plots_aggregate?: InputMaybe<Area_Plot_Aggregate_Order_By>;
  aspect?: InputMaybe<Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  completion_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  enable_unit_preferences?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  features_aggregate?: InputMaybe<Feature_Aggregate_Order_By>;
  floor_plan?: InputMaybe<Attachment_Order_By>;
  floor_plan_id?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  linen_plan?: InputMaybe<Attachment_Order_By>;
  linen_plan_id?: InputMaybe<Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  listings_aggregate?: InputMaybe<Listing_Aggregate_Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_suffix?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  preferences_aggregate?: InputMaybe<Preference_Aggregate_Order_By>;
  product_type?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  property_allocation_aggregate?: InputMaybe<Property_Allocation_Aggregate_Order_By>;
  property_allocation_count?: InputMaybe<Property_Allocation_Count_Order_By>;
  property_completion_status?: InputMaybe<Property_Completion_Status_Order_By>;
  property_group?: InputMaybe<Property_Group_Order_By>;
  property_group_id?: InputMaybe<Order_By>;
  property_type?: InputMaybe<Property_Type_Order_By>;
  sf_id?: InputMaybe<Order_By>;
  storage?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
  street_name?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  total_area?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property */
export type Property_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "property" */
export type Property_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  aspect?: InputMaybe<Scalars['String']>;
  bathroom?: InputMaybe<Scalars['numeric']>;
  bedroom?: InputMaybe<Scalars['numeric']>;
  completion_status?: InputMaybe<Property_Completion_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enable_unit_preferences?: InputMaybe<Scalars['Boolean']>;
  external_area?: InputMaybe<Scalars['numeric']>;
  floor_plan_id?: InputMaybe<Scalars['uuid']>;
  garage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_area?: InputMaybe<Scalars['numeric']>;
  level?: InputMaybe<Scalars['String']>;
  level_number?: InputMaybe<Scalars['numeric']>;
  linen_plan_id?: InputMaybe<Scalars['uuid']>;
  lot?: InputMaybe<Scalars['Int']>;
  lot_area?: InputMaybe<Scalars['numeric']>;
  lot_depth?: InputMaybe<Scalars['numeric']>;
  lot_suffix?: InputMaybe<Scalars['String']>;
  lot_width?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  product_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  storage?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Int']>;
  street_name?: InputMaybe<Scalars['String']>;
  street_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "property" */
export type Property_Stddev_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "property" */
export type Property_Stddev_Pop_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "property" */
export type Property_Stddev_Samp_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "property" */
export type Property_Stream_Cursor_Input = {
  initial_value: Property_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  aspect?: InputMaybe<Scalars['String']>;
  bathroom?: InputMaybe<Scalars['numeric']>;
  bedroom?: InputMaybe<Scalars['numeric']>;
  completion_status?: InputMaybe<Property_Completion_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enable_unit_preferences?: InputMaybe<Scalars['Boolean']>;
  external_area?: InputMaybe<Scalars['numeric']>;
  floor_plan_id?: InputMaybe<Scalars['uuid']>;
  garage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_area?: InputMaybe<Scalars['numeric']>;
  level?: InputMaybe<Scalars['String']>;
  level_number?: InputMaybe<Scalars['numeric']>;
  linen_plan_id?: InputMaybe<Scalars['uuid']>;
  lot?: InputMaybe<Scalars['Int']>;
  lot_area?: InputMaybe<Scalars['numeric']>;
  lot_depth?: InputMaybe<Scalars['numeric']>;
  lot_suffix?: InputMaybe<Scalars['String']>;
  lot_width?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  product_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  property_group_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  storage?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Int']>;
  street_name?: InputMaybe<Scalars['String']>;
  street_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "property" */
export type Property_Sum_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
export type Property_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  _not?: InputMaybe<Property_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Property_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  properties?: InputMaybe<Property_Bool_Exp>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "property_type_enum". All fields are combined with logical 'AND'. */
export type Property_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Property_Type_Enum>;
  _in?: InputMaybe<Array<Property_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Property_Type_Enum>;
  _nin?: InputMaybe<Array<Property_Type_Enum>>;
};

/** input type for inserting data into table "property_type" */
export type Property_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Property_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "property_type" */
export type Property_Type_Obj_Rel_Insert_Input = {
  data: Property_Type_Insert_Input;
  on_conflict?: InputMaybe<Property_Type_On_Conflict>;
};

/** on_conflict condition type for table "property_type" */
export type Property_Type_On_Conflict = {
  constraint: Property_Type_Constraint;
  update_columns: Array<Property_Type_Update_Column>;
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "property_type". */
export type Property_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  properties_aggregate?: InputMaybe<Property_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: property_type */
export type Property_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "property_type" */
export type Property_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "property_type" */
export type Property_Type_Stream_Cursor_Input = {
  initial_value: Property_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Property_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Property_Type_Updates = {
  _set?: InputMaybe<Property_Type_Set_Input>;
  where: Property_Type_Bool_Exp;
};

export type Property_Updates = {
  _inc?: InputMaybe<Property_Inc_Input>;
  _set?: InputMaybe<Property_Set_Input>;
  where: Property_Bool_Exp;
};

/** order by var_pop() on columns of table "property" */
export type Property_Var_Pop_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "property" */
export type Property_Var_Samp_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "property" */
export type Property_Variance_Order_By = {
  bathroom?: InputMaybe<Order_By>;
  bedroom?: InputMaybe<Order_By>;
  external_area?: InputMaybe<Order_By>;
  garage?: InputMaybe<Order_By>;
  internal_area?: InputMaybe<Order_By>;
  level_number?: InputMaybe<Order_By>;
  lot?: InputMaybe<Order_By>;
  lot_area?: InputMaybe<Order_By>;
  lot_depth?: InputMaybe<Order_By>;
  lot_width?: InputMaybe<Order_By>;
  storeys?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reservation_Append_Input = {
  step_config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "reservation". All fields are combined with a logical 'AND'. */
export type Reservation_Bool_Exp = {
  _and?: InputMaybe<Array<Reservation_Bool_Exp>>;
  _not?: InputMaybe<Reservation_Bool_Exp>;
  _or?: InputMaybe<Array<Reservation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  listing?: InputMaybe<Listing_Bool_Exp>;
  listing_id?: InputMaybe<Uuid_Comparison_Exp>;
  step_config?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reservation_Delete_At_Path_Input = {
  step_config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reservation_Delete_Elem_Input = {
  step_config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reservation_Delete_Key_Input = {
  step_config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "reservation" */
export type Reservation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  listing?: InputMaybe<Listing_Obj_Rel_Insert_Input>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "reservation" */
export type Reservation_On_Conflict = {
  constraint: Reservation_Constraint;
  update_columns: Array<Reservation_Update_Column>;
  where?: InputMaybe<Reservation_Bool_Exp>;
};

/** Ordering options when selecting data from "reservation". */
export type Reservation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  listing?: InputMaybe<Listing_Order_By>;
  listing_id?: InputMaybe<Order_By>;
  step_config?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reservation */
export type Reservation_Pk_Columns_Input = {
  deal_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reservation_Prepend_Input = {
  step_config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "reservation" */
export type Reservation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "reservation" */
export type Reservation_Stream_Cursor_Input = {
  initial_value: Reservation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  listing_id?: InputMaybe<Scalars['uuid']>;
  step_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Reservation_Updates = {
  _append?: InputMaybe<Reservation_Append_Input>;
  _delete_at_path?: InputMaybe<Reservation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reservation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reservation_Delete_Key_Input>;
  _prepend?: InputMaybe<Reservation_Prepend_Input>;
  _set?: InputMaybe<Reservation_Set_Input>;
  where: Reservation_Bool_Exp;
};

/** Boolean expression to filter rows from the table "salesforce_action". All fields are combined with a logical 'AND'. */
export type Salesforce_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Action_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Action_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "salesforce_action_enum". All fields are combined with logical 'AND'. */
export type Salesforce_Action_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Salesforce_Action_Enum>;
  _in?: InputMaybe<Array<Salesforce_Action_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Salesforce_Action_Enum>;
  _nin?: InputMaybe<Array<Salesforce_Action_Enum>>;
};

/** input type for inserting data into table "salesforce_action" */
export type Salesforce_Action_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "salesforce_action" */
export type Salesforce_Action_On_Conflict = {
  constraint: Salesforce_Action_Constraint;
  update_columns: Array<Salesforce_Action_Update_Column>;
  where?: InputMaybe<Salesforce_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_action". */
export type Salesforce_Action_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_action */
export type Salesforce_Action_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "salesforce_action" */
export type Salesforce_Action_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "salesforce_action_status". All fields are combined with a logical 'AND'. */
export type Salesforce_Action_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Action_Status_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Action_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "salesforce_action_status_enum". All fields are combined with logical 'AND'. */
export type Salesforce_Action_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Salesforce_Action_Status_Enum>;
  _in?: InputMaybe<Array<Salesforce_Action_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Salesforce_Action_Status_Enum>;
  _nin?: InputMaybe<Array<Salesforce_Action_Status_Enum>>;
};

/** input type for inserting data into table "salesforce_action_status" */
export type Salesforce_Action_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "salesforce_action_status" */
export type Salesforce_Action_Status_On_Conflict = {
  constraint: Salesforce_Action_Status_Constraint;
  update_columns: Array<Salesforce_Action_Status_Update_Column>;
  where?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_action_status". */
export type Salesforce_Action_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_action_status */
export type Salesforce_Action_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "salesforce_action_status" */
export type Salesforce_Action_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salesforce_action_status" */
export type Salesforce_Action_Status_Stream_Cursor_Input = {
  initial_value: Salesforce_Action_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Action_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Salesforce_Action_Status_Updates = {
  _set?: InputMaybe<Salesforce_Action_Status_Set_Input>;
  where: Salesforce_Action_Status_Bool_Exp;
};

/** Streaming cursor of the table "salesforce_action" */
export type Salesforce_Action_Stream_Cursor_Input = {
  initial_value: Salesforce_Action_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Action_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Salesforce_Action_Updates = {
  _set?: InputMaybe<Salesforce_Action_Set_Input>;
  where: Salesforce_Action_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Salesforce_Bulk_Import_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "salesforce_bulk_import". All fields are combined with a logical 'AND'. */
export type Salesforce_Bulk_Import_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Bulk_Import_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Bulk_Import_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  page_size?: InputMaybe<Int_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  sf_object_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Salesforce_Bulk_Import_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Salesforce_Bulk_Import_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Salesforce_Bulk_Import_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Inc_Input = {
  page_size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  page_size?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  sf_object_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_On_Conflict = {
  constraint: Salesforce_Bulk_Import_Constraint;
  update_columns: Array<Salesforce_Bulk_Import_Update_Column>;
  where?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_bulk_import". */
export type Salesforce_Bulk_Import_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  page_size?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  sf_object_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_bulk_import */
export type Salesforce_Bulk_Import_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Salesforce_Bulk_Import_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  page_size?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  sf_object_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Stream_Cursor_Input = {
  initial_value: Salesforce_Bulk_Import_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Bulk_Import_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  page_size?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  sf_object_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Salesforce_Bulk_Import_Updates = {
  _append?: InputMaybe<Salesforce_Bulk_Import_Append_Input>;
  _delete_at_path?: InputMaybe<Salesforce_Bulk_Import_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Salesforce_Bulk_Import_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Salesforce_Bulk_Import_Delete_Key_Input>;
  _inc?: InputMaybe<Salesforce_Bulk_Import_Inc_Input>;
  _prepend?: InputMaybe<Salesforce_Bulk_Import_Prepend_Input>;
  _set?: InputMaybe<Salesforce_Bulk_Import_Set_Input>;
  where: Salesforce_Bulk_Import_Bool_Exp;
};

/** Boolean expression to filter rows from the table "salesforce_connection". All fields are combined with a logical 'AND'. */
export type Salesforce_Connection_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Connection_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Connection_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Connection_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  instance_url?: InputMaybe<String_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "salesforce_connection" */
export type Salesforce_Connection_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  instance_url?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "salesforce_connection" */
export type Salesforce_Connection_On_Conflict = {
  constraint: Salesforce_Connection_Constraint;
  update_columns: Array<Salesforce_Connection_Update_Column>;
  where?: InputMaybe<Salesforce_Connection_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_connection". */
export type Salesforce_Connection_Order_By = {
  access_token?: InputMaybe<Order_By>;
  instance_url?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_connection */
export type Salesforce_Connection_Pk_Columns_Input = {
  access_token: Scalars['String'];
};

/** input type for updating data in table "salesforce_connection" */
export type Salesforce_Connection_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  instance_url?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salesforce_connection" */
export type Salesforce_Connection_Stream_Cursor_Input = {
  initial_value: Salesforce_Connection_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Connection_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  instance_url?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['String']>;
};

export type Salesforce_Connection_Updates = {
  _set?: InputMaybe<Salesforce_Connection_Set_Input>;
  where: Salesforce_Connection_Bool_Exp;
};

/** Boolean expression to filter rows from the table "salesforce_sync_status". All fields are combined with a logical 'AND'. */
export type Salesforce_Sync_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Salesforce_Sync_Status_Bool_Exp>>;
  _not?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Salesforce_Sync_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "salesforce_sync_status" */
export type Salesforce_Sync_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "salesforce_sync_status" */
export type Salesforce_Sync_Status_On_Conflict = {
  constraint: Salesforce_Sync_Status_Constraint;
  update_columns: Array<Salesforce_Sync_Status_Update_Column>;
  where?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforce_sync_status". */
export type Salesforce_Sync_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforce_sync_status */
export type Salesforce_Sync_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "salesforce_sync_status" */
export type Salesforce_Sync_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salesforce_sync_status" */
export type Salesforce_Sync_Status_Stream_Cursor_Input = {
  initial_value: Salesforce_Sync_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salesforce_Sync_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Salesforce_Sync_Status_Updates = {
  _set?: InputMaybe<Salesforce_Sync_Status_Set_Input>;
  where: Salesforce_Sync_Status_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Settings_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "settings". All fields are combined with a logical 'AND'. */
export type Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Settings_Bool_Exp>>;
  _not?: InputMaybe<Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Settings_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Settings_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Settings_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "settings" */
export type Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** on_conflict condition type for table "settings" */
export type Settings_On_Conflict = {
  constraint: Settings_Constraint;
  update_columns: Array<Settings_Update_Column>;
  where?: InputMaybe<Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "settings". */
export type Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: settings */
export type Settings_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Settings_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "settings" */
export type Settings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "settings" */
export type Settings_Stream_Cursor_Input = {
  initial_value: Settings_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

export type Settings_Updates = {
  _append?: InputMaybe<Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Settings_Prepend_Input>;
  _set?: InputMaybe<Settings_Set_Input>;
  where: Settings_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sf_Action_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "sf_action". All fields are combined with a logical 'AND'. */
export type Sf_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Sf_Action_Bool_Exp>>;
  _not?: InputMaybe<Sf_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Sf_Action_Bool_Exp>>;
  action?: InputMaybe<Salesforce_Action_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Salesforce_Action_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sf_Action_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sf_Action_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sf_Action_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "sf_action" */
export type Sf_Action_Insert_Input = {
  action?: InputMaybe<Salesforce_Action_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Salesforce_Action_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "sf_action" */
export type Sf_Action_On_Conflict = {
  constraint: Sf_Action_Constraint;
  update_columns: Array<Sf_Action_Update_Column>;
  where?: InputMaybe<Sf_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "sf_action". */
export type Sf_Action_Order_By = {
  action?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sf_action */
export type Sf_Action_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sf_Action_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "sf_action" */
export type Sf_Action_Set_Input = {
  action?: InputMaybe<Salesforce_Action_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Salesforce_Action_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "sf_action" */
export type Sf_Action_Stream_Cursor_Input = {
  initial_value: Sf_Action_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sf_Action_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Salesforce_Action_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Salesforce_Action_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Sf_Action_Updates = {
  _append?: InputMaybe<Sf_Action_Append_Input>;
  _delete_at_path?: InputMaybe<Sf_Action_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sf_Action_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sf_Action_Delete_Key_Input>;
  _prepend?: InputMaybe<Sf_Action_Prepend_Input>;
  _set?: InputMaybe<Sf_Action_Set_Input>;
  where: Sf_Action_Bool_Exp;
};

export type Sf_Contact_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sf_Contact_Aggregate_Bool_Exp_Count>;
};

export type Sf_Contact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sf_Contact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "sf_contact" */
export type Sf_Contact_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sf_Contact_Max_Order_By>;
  min?: InputMaybe<Sf_Contact_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sf_contact" */
export type Sf_Contact_Arr_Rel_Insert_Input = {
  data: Array<Sf_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Sf_Contact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sf_contact". All fields are combined with a logical 'AND'. */
export type Sf_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Sf_Contact_Bool_Exp>>;
  _not?: InputMaybe<Sf_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Sf_Contact_Bool_Exp>>;
  contact?: InputMaybe<Contact_Bool_Exp>;
  contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crm_connection?: InputMaybe<Crm_Connection_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "sf_contact" */
export type Sf_Contact_Insert_Input = {
  contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crm_connection?: InputMaybe<Crm_Connection_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sf_contact" */
export type Sf_Contact_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "sf_contact" */
export type Sf_Contact_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "sf_contact" */
export type Sf_Contact_Obj_Rel_Insert_Input = {
  data: Sf_Contact_Insert_Input;
  on_conflict?: InputMaybe<Sf_Contact_On_Conflict>;
};

/** on_conflict condition type for table "sf_contact" */
export type Sf_Contact_On_Conflict = {
  constraint: Sf_Contact_Constraint;
  update_columns: Array<Sf_Contact_Update_Column>;
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "sf_contact". */
export type Sf_Contact_Order_By = {
  contact?: InputMaybe<Contact_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crm_connection?: InputMaybe<Crm_Connection_Order_By>;
  id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sf_contact */
export type Sf_Contact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "sf_contact" */
export type Sf_Contact_Set_Input = {
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "sf_contact" */
export type Sf_Contact_Stream_Cursor_Input = {
  initial_value: Sf_Contact_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sf_Contact_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Sf_Contact_Updates = {
  _set?: InputMaybe<Sf_Contact_Set_Input>;
  where: Sf_Contact_Bool_Exp;
};

export type Sf_Organisation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sf_Organisation_Aggregate_Bool_Exp_Count>;
};

export type Sf_Organisation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sf_Organisation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "sf_organisation" */
export type Sf_Organisation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sf_Organisation_Max_Order_By>;
  min?: InputMaybe<Sf_Organisation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sf_organisation" */
export type Sf_Organisation_Arr_Rel_Insert_Input = {
  data: Array<Sf_Organisation_Insert_Input>;
  on_conflict?: InputMaybe<Sf_Organisation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sf_organisation". All fields are combined with a logical 'AND'. */
export type Sf_Organisation_Bool_Exp = {
  _and?: InputMaybe<Array<Sf_Organisation_Bool_Exp>>;
  _not?: InputMaybe<Sf_Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Sf_Organisation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crm_connection?: InputMaybe<Crm_Connection_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation?: InputMaybe<Organisation_Bool_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "sf_organisation" */
export type Sf_Organisation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crm_connection?: InputMaybe<Crm_Connection_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sf_organisation" */
export type Sf_Organisation_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "sf_organisation" */
export type Sf_Organisation_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "sf_organisation" */
export type Sf_Organisation_On_Conflict = {
  constraint: Sf_Organisation_Constraint;
  update_columns: Array<Sf_Organisation_Update_Column>;
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "sf_organisation". */
export type Sf_Organisation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crm_connection?: InputMaybe<Crm_Connection_Order_By>;
  id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organisation_Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sf_organisation */
export type Sf_Organisation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "sf_organisation" */
export type Sf_Organisation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "sf_organisation" */
export type Sf_Organisation_Stream_Cursor_Input = {
  initial_value: Sf_Organisation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sf_Organisation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  organisation_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  sf_instance_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Sf_Organisation_Updates = {
  _set?: InputMaybe<Sf_Organisation_Set_Input>;
  where: Sf_Organisation_Bool_Exp;
};

/** Boolean expression to filter rows from the logical model for "solicitor_orgs". All fields are combined with a logical 'AND'. */
export type Solicitor_Orgs_Bool_Exp_Bool_Exp = {
  _and?: InputMaybe<Array<Solicitor_Orgs_Bool_Exp_Bool_Exp>>;
  _not?: InputMaybe<Solicitor_Orgs_Bool_Exp_Bool_Exp>;
  _or?: InputMaybe<Array<Solicitor_Orgs_Bool_Exp_Bool_Exp>>;
  active_deal_count?: InputMaybe<Bigint_Comparison_Exp>;
  contact_count?: InputMaybe<Bigint_Comparison_Exp>;
  crm_name?: InputMaybe<String_Comparison_Exp>;
  deal_count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_count?: InputMaybe<Bigint_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  sf_instance_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "solicitor_orgs". */
export type Solicitor_Orgs_Order_By = {
  active_deal_count?: InputMaybe<Order_By>;
  contact_count?: InputMaybe<Order_By>;
  crm_name?: InputMaybe<Order_By>;
  deal_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_count?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  sf_instance_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "spoken_language". All fields are combined with a logical 'AND'. */
export type Spoken_Language_Bool_Exp = {
  _and?: InputMaybe<Array<Spoken_Language_Bool_Exp>>;
  _not?: InputMaybe<Spoken_Language_Bool_Exp>;
  _or?: InputMaybe<Array<Spoken_Language_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "spoken_language_enum". All fields are combined with logical 'AND'. */
export type Spoken_Language_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Spoken_Language_Enum>;
  _in?: InputMaybe<Array<Spoken_Language_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Spoken_Language_Enum>;
  _nin?: InputMaybe<Array<Spoken_Language_Enum>>;
};

/** input type for inserting data into table "spoken_language" */
export type Spoken_Language_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "spoken_language" */
export type Spoken_Language_On_Conflict = {
  constraint: Spoken_Language_Constraint;
  update_columns: Array<Spoken_Language_Update_Column>;
  where?: InputMaybe<Spoken_Language_Bool_Exp>;
};

/** Ordering options when selecting data from "spoken_language". */
export type Spoken_Language_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spoken_language */
export type Spoken_Language_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "spoken_language" */
export type Spoken_Language_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "spoken_language" */
export type Spoken_Language_Stream_Cursor_Input = {
  initial_value: Spoken_Language_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spoken_Language_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Spoken_Language_Updates = {
  _set?: InputMaybe<Spoken_Language_Set_Input>;
  where: Spoken_Language_Bool_Exp;
};

/** Boolean expression to filter rows from the table "system". All fields are combined with a logical 'AND'. */
export type System_Bool_Exp = {
  _and?: InputMaybe<Array<System_Bool_Exp>>;
  _not?: InputMaybe<System_Bool_Exp>;
  _or?: InputMaybe<Array<System_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "system" */
export type System_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "system" */
export type System_On_Conflict = {
  constraint: System_Constraint;
  update_columns: Array<System_Update_Column>;
  where?: InputMaybe<System_Bool_Exp>;
};

/** Ordering options when selecting data from "system". */
export type System_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: system */
export type System_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "system" */
export type System_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "system" */
export type System_Stream_Cursor_Input = {
  initial_value: System_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type System_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type System_Updates = {
  _set?: InputMaybe<System_Set_Input>;
  where: System_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type Transaction_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Transaction_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Transaction_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Transaction_Aggregate_Bool_Exp_Count>;
};

export type Transaction_Aggregate_Bool_Exp_Bool_And = {
  arguments: Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transaction_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "transaction" */
export type Transaction_Aggregate_Order_By = {
  avg?: InputMaybe<Transaction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transaction_Max_Order_By>;
  min?: InputMaybe<Transaction_Min_Order_By>;
  stddev?: InputMaybe<Transaction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transaction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transaction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transaction_Sum_Order_By>;
  var_pop?: InputMaybe<Transaction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transaction_Var_Samp_Order_By>;
  variance?: InputMaybe<Transaction_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "transaction" */
export type Transaction_Arr_Rel_Insert_Input = {
  data: Array<Transaction_Insert_Input>;
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

/** order by avg() on columns of table "transaction" */
export type Transaction_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Bool_Exp>>;
  _not?: InputMaybe<Transaction_Bool_Exp>;
  _or?: InputMaybe<Array<Transaction_Bool_Exp>>;
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Bool_Exp>;
  acl_deal_developer_member?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
  acl_deal_developer_member_aggregate?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Bool_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  attachment_owner?: InputMaybe<String_Comparison_Exp>;
  attachments?: InputMaybe<Attachment_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_bulk_import?: InputMaybe<Boolean_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  off_platform?: InputMaybe<Boolean_Comparison_Exp>;
  origin_id?: InputMaybe<String_Comparison_Exp>;
  origin_status?: InputMaybe<String_Comparison_Exp>;
  origin_type?: InputMaybe<String_Comparison_Exp>;
  paid_by_contact?: InputMaybe<Contact_Bool_Exp>;
  paid_by_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  paid_to_contact?: InputMaybe<Contact_Bool_Exp>;
  paid_to_contact_id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_method?: InputMaybe<Payment_Method_Type_Enum_Comparison_Exp>;
  payment_method_type?: InputMaybe<Payment_Method_Type_Bool_Exp>;
  payment_url?: InputMaybe<String_Comparison_Exp>;
  receipt?: InputMaybe<Attachment_Bool_Exp>;
  receipt_id?: InputMaybe<Uuid_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Transaction_Status_Enum_Comparison_Exp>;
  surcharge_fee?: InputMaybe<Numeric_Comparison_Exp>;
  transaction_type?: InputMaybe<Transaction_Type_Bool_Exp>;
  type?: InputMaybe<Transaction_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transaction_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transaction_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transaction_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transaction" */
export type Transaction_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  surcharge_fee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "transaction" */
export type Transaction_Insert_Input = {
  acl_deal_agent_member?: InputMaybe<Acl_Deal_Agent_Member_Arr_Rel_Insert_Input>;
  acl_deal_developer_member?: InputMaybe<Acl_Deal_Developer_Member_Arr_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['numeric']>;
  attachment_owner?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Attachment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_time?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bulk_import?: InputMaybe<Scalars['Boolean']>;
  json?: InputMaybe<Scalars['jsonb']>;
  note?: InputMaybe<Scalars['String']>;
  off_platform?: InputMaybe<Scalars['Boolean']>;
  origin_id?: InputMaybe<Scalars['String']>;
  origin_status?: InputMaybe<Scalars['String']>;
  origin_type?: InputMaybe<Scalars['String']>;
  paid_by_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  paid_by_contact_id?: InputMaybe<Scalars['uuid']>;
  paid_to_contact?: InputMaybe<Contact_Obj_Rel_Insert_Input>;
  paid_to_contact_id?: InputMaybe<Scalars['uuid']>;
  payment_method?: InputMaybe<Payment_Method_Type_Enum>;
  payment_method_type?: InputMaybe<Payment_Method_Type_Obj_Rel_Insert_Input>;
  payment_url?: InputMaybe<Scalars['String']>;
  receipt?: InputMaybe<Attachment_Obj_Rel_Insert_Input>;
  receipt_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Transaction_Status_Enum>;
  surcharge_fee?: InputMaybe<Scalars['numeric']>;
  transaction_type?: InputMaybe<Transaction_Type_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transaction" */
export type Transaction_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  attachment_owner?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_time?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  origin_status?: InputMaybe<Order_By>;
  origin_type?: InputMaybe<Order_By>;
  paid_by_contact_id?: InputMaybe<Order_By>;
  paid_to_contact_id?: InputMaybe<Order_By>;
  payment_url?: InputMaybe<Order_By>;
  receipt_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "transaction" */
export type Transaction_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  attachment_owner?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_time?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  origin_status?: InputMaybe<Order_By>;
  origin_type?: InputMaybe<Order_By>;
  paid_by_contact_id?: InputMaybe<Order_By>;
  paid_to_contact_id?: InputMaybe<Order_By>;
  payment_url?: InputMaybe<Order_By>;
  receipt_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "transaction" */
export type Transaction_On_Conflict = {
  constraint: Transaction_Constraint;
  update_columns: Array<Transaction_Update_Column>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction". */
export type Transaction_Order_By = {
  acl_deal_agent_member_aggregate?: InputMaybe<Acl_Deal_Agent_Member_Aggregate_Order_By>;
  acl_deal_developer_member_aggregate?: InputMaybe<Acl_Deal_Developer_Member_Aggregate_Order_By>;
  amount?: InputMaybe<Order_By>;
  attachment_owner?: InputMaybe<Order_By>;
  attachments_aggregate?: InputMaybe<Attachment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_time?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bulk_import?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  off_platform?: InputMaybe<Order_By>;
  origin_id?: InputMaybe<Order_By>;
  origin_status?: InputMaybe<Order_By>;
  origin_type?: InputMaybe<Order_By>;
  paid_by_contact?: InputMaybe<Contact_Order_By>;
  paid_by_contact_id?: InputMaybe<Order_By>;
  paid_to_contact?: InputMaybe<Contact_Order_By>;
  paid_to_contact_id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_method_type?: InputMaybe<Payment_Method_Type_Order_By>;
  payment_url?: InputMaybe<Order_By>;
  receipt?: InputMaybe<Attachment_Order_By>;
  receipt_id?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Transaction_Type_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transaction */
export type Transaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transaction_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for updating data in table "transaction" */
export type Transaction_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  attachment_owner?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_time?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bulk_import?: InputMaybe<Scalars['Boolean']>;
  json?: InputMaybe<Scalars['jsonb']>;
  note?: InputMaybe<Scalars['String']>;
  off_platform?: InputMaybe<Scalars['Boolean']>;
  origin_id?: InputMaybe<Scalars['String']>;
  origin_status?: InputMaybe<Scalars['String']>;
  origin_type?: InputMaybe<Scalars['String']>;
  paid_by_contact_id?: InputMaybe<Scalars['uuid']>;
  paid_to_contact_id?: InputMaybe<Scalars['uuid']>;
  payment_method?: InputMaybe<Payment_Method_Type_Enum>;
  payment_url?: InputMaybe<Scalars['String']>;
  receipt_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Transaction_Status_Enum>;
  surcharge_fee?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "transaction_status". All fields are combined with a logical 'AND'. */
export type Transaction_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Status_Bool_Exp>>;
  _not?: InputMaybe<Transaction_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Transaction_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "transaction_status_enum". All fields are combined with logical 'AND'. */
export type Transaction_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Transaction_Status_Enum>;
  _in?: InputMaybe<Array<Transaction_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Transaction_Status_Enum>;
  _nin?: InputMaybe<Array<Transaction_Status_Enum>>;
};

/** input type for inserting data into table "transaction_status" */
export type Transaction_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "transaction_status" */
export type Transaction_Status_On_Conflict = {
  constraint: Transaction_Status_Constraint;
  update_columns: Array<Transaction_Status_Update_Column>;
  where?: InputMaybe<Transaction_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction_status". */
export type Transaction_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transaction_status */
export type Transaction_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "transaction_status" */
export type Transaction_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "transaction_status" */
export type Transaction_Status_Stream_Cursor_Input = {
  initial_value: Transaction_Status_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transaction_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Transaction_Status_Updates = {
  _set?: InputMaybe<Transaction_Status_Set_Input>;
  where: Transaction_Status_Bool_Exp;
};

/** order by stddev() on columns of table "transaction" */
export type Transaction_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "transaction" */
export type Transaction_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "transaction" */
export type Transaction_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transaction" */
export type Transaction_Stream_Cursor_Input = {
  initial_value: Transaction_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transaction_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  attachment_owner?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_time?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_bulk_import?: InputMaybe<Scalars['Boolean']>;
  json?: InputMaybe<Scalars['jsonb']>;
  note?: InputMaybe<Scalars['String']>;
  off_platform?: InputMaybe<Scalars['Boolean']>;
  origin_id?: InputMaybe<Scalars['String']>;
  origin_status?: InputMaybe<Scalars['String']>;
  origin_type?: InputMaybe<Scalars['String']>;
  paid_by_contact_id?: InputMaybe<Scalars['uuid']>;
  paid_to_contact_id?: InputMaybe<Scalars['uuid']>;
  payment_method?: InputMaybe<Payment_Method_Type_Enum>;
  payment_url?: InputMaybe<Scalars['String']>;
  receipt_id?: InputMaybe<Scalars['uuid']>;
  sf_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Transaction_Status_Enum>;
  surcharge_fee?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Transaction_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "transaction" */
export type Transaction_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transaction_type". All fields are combined with a logical 'AND'. */
export type Transaction_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Type_Bool_Exp>>;
  _not?: InputMaybe<Transaction_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Transaction_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "transaction_type_enum". All fields are combined with logical 'AND'. */
export type Transaction_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Transaction_Type_Enum>;
  _in?: InputMaybe<Array<Transaction_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Transaction_Type_Enum>;
  _nin?: InputMaybe<Array<Transaction_Type_Enum>>;
};

/** input type for inserting data into table "transaction_type" */
export type Transaction_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "transaction_type" */
export type Transaction_Type_Obj_Rel_Insert_Input = {
  data: Transaction_Type_Insert_Input;
  on_conflict?: InputMaybe<Transaction_Type_On_Conflict>;
};

/** on_conflict condition type for table "transaction_type" */
export type Transaction_Type_On_Conflict = {
  constraint: Transaction_Type_Constraint;
  update_columns: Array<Transaction_Type_Update_Column>;
  where?: InputMaybe<Transaction_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction_type". */
export type Transaction_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transaction_type */
export type Transaction_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "transaction_type" */
export type Transaction_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "transaction_type" */
export type Transaction_Type_Stream_Cursor_Input = {
  initial_value: Transaction_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transaction_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Transaction_Type_Updates = {
  _set?: InputMaybe<Transaction_Type_Set_Input>;
  where: Transaction_Type_Bool_Exp;
};

export type Transaction_Updates = {
  _append?: InputMaybe<Transaction_Append_Input>;
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>;
  _inc?: InputMaybe<Transaction_Inc_Input>;
  _prepend?: InputMaybe<Transaction_Prepend_Input>;
  _set?: InputMaybe<Transaction_Set_Input>;
  where: Transaction_Bool_Exp;
};

/** order by var_pop() on columns of table "transaction" */
export type Transaction_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "transaction" */
export type Transaction_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "transaction" */
export type Transaction_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  surcharge_fee?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unit_allocation_count". All fields are combined with a logical 'AND'. */
export type Unit_Allocation_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Unit_Allocation_Count_Bool_Exp>>;
  _not?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Unit_Allocation_Count_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "unit_allocation_count" */
export type Unit_Allocation_Count_Insert_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** input type for inserting object relation for remote table "unit_allocation_count" */
export type Unit_Allocation_Count_Obj_Rel_Insert_Input = {
  data: Unit_Allocation_Count_Insert_Input;
};

/** Ordering options when selecting data from "unit_allocation_count". */
export type Unit_Allocation_Count_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "unit_allocation_count" */
export type Unit_Allocation_Count_Stream_Cursor_Input = {
  initial_value: Unit_Allocation_Count_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unit_Allocation_Count_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  avg?: InputMaybe<User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
  stddev?: InputMaybe<User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Sum_Order_By>;
  var_pop?: InputMaybe<User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** order by avg() on columns of table "user" */
export type User_Avg_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  allowed_roles?: InputMaybe<String_Comparison_Exp>;
  buyerProjects?: InputMaybe<Buyer_Project_Bool_Exp>;
  buyerProjectsByAgent?: InputMaybe<Buyer_Project_Bool_Exp>;
  buyerProjectsByAgent_aggregate?: InputMaybe<Buyer_Project_Aggregate_Bool_Exp>;
  buyerProjects_aggregate?: InputMaybe<Buyer_Project_Aggregate_Bool_Exp>;
  code_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  cognito_id?: InputMaybe<Uuid_Comparison_Exp>;
  contacts?: InputMaybe<Contact_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_role?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  embed?: InputMaybe<Embed_Bool_Exp>;
  experiments?: InputMaybe<User_Experiment_Bool_Exp>;
  experiments_aggregate?: InputMaybe<User_Experiment_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  hubspot?: InputMaybe<Hubspot_User_Bool_Exp>;
  hubspot_aggregate?: InputMaybe<Hubspot_User_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  impersonation_requested_at?: InputMaybe<Bigint_Comparison_Exp>;
  invitations?: InputMaybe<Invitation_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Bool_Exp>;
  is_enable?: InputMaybe<Boolean_Comparison_Exp>;
  is_sign_up_notification_sent?: InputMaybe<Boolean_Comparison_Exp>;
  last_login_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  link_login_requested_at?: InputMaybe<Bigint_Comparison_Exp>;
  login_secret?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Member_Bool_Exp>;
  members_aggregate?: InputMaybe<Member_Aggregate_Bool_Exp>;
  middle_name?: InputMaybe<String_Comparison_Exp>;
  notification_owner?: InputMaybe<String_Comparison_Exp>;
  password_hash?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  phone_number_verified?: InputMaybe<Boolean_Comparison_Exp>;
  push_tokens?: InputMaybe<User_Push_Token_Bool_Exp>;
  push_tokens_aggregate?: InputMaybe<User_Push_Token_Aggregate_Bool_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Organisation_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_switch_requested_at?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export type User_Created_Count_And_Rolling_Avg_Args = {
  interval_arg?: InputMaybe<Scalars['String']>;
  window_size_arg?: InputMaybe<Scalars['Int']>;
};

export type User_Experiment_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Experiment_Aggregate_Bool_Exp_Count>;
};

export type User_Experiment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Experiment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "user_experiment" */
export type User_Experiment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Experiment_Max_Order_By>;
  min?: InputMaybe<User_Experiment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_experiment" */
export type User_Experiment_Arr_Rel_Insert_Input = {
  data: Array<User_Experiment_Insert_Input>;
  on_conflict?: InputMaybe<User_Experiment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_experiment". All fields are combined with a logical 'AND'. */
export type User_Experiment_Bool_Exp = {
  _and?: InputMaybe<Array<User_Experiment_Bool_Exp>>;
  _not?: InputMaybe<User_Experiment_Bool_Exp>;
  _or?: InputMaybe<Array<User_Experiment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment?: InputMaybe<Experiment_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "user_experiment" */
export type User_Experiment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiment_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_experiment" */
export type User_Experiment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_experiment" */
export type User_Experiment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "user_experiment" */
export type User_Experiment_On_Conflict = {
  constraint: User_Experiment_Constraint;
  update_columns: Array<User_Experiment_Update_Column>;
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

/** Ordering options when selecting data from "user_experiment". */
export type User_Experiment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_experiment */
export type User_Experiment_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** input type for updating data in table "user_experiment" */
export type User_Experiment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiment_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_experiment" */
export type User_Experiment_Stream_Cursor_Input = {
  initial_value: User_Experiment_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Experiment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiment_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type User_Experiment_Updates = {
  _set?: InputMaybe<User_Experiment_Set_Input>;
  where: User_Experiment_Bool_Exp;
};

/** Boolean expression to filter rows from the table "user_feature". All fields are combined with a logical 'AND'. */
export type User_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<User_Feature_Bool_Exp>>;
  _not?: InputMaybe<User_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<User_Feature_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "user_feature_enum". All fields are combined with logical 'AND'. */
export type User_Feature_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Feature_Enum>;
  _in?: InputMaybe<Array<User_Feature_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Feature_Enum>;
  _nin?: InputMaybe<Array<User_Feature_Enum>>;
};

/** input type for inserting data into table "user_feature" */
export type User_Feature_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "user_feature" */
export type User_Feature_On_Conflict = {
  constraint: User_Feature_Constraint;
  update_columns: Array<User_Feature_Update_Column>;
  where?: InputMaybe<User_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "user_feature". */
export type User_Feature_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_feature */
export type User_Feature_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "user_feature" */
export type User_Feature_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_feature" */
export type User_Feature_Stream_Cursor_Input = {
  initial_value: User_Feature_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Feature_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type User_Feature_Updates = {
  _set?: InputMaybe<User_Feature_Set_Input>;
  where: User_Feature_Bool_Exp;
};

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  impersonation_requested_at?: InputMaybe<Scalars['bigint']>;
  link_login_requested_at?: InputMaybe<Scalars['bigint']>;
  team_switch_requested_at?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  allowed_roles?: InputMaybe<Scalars['String']>;
  buyerProjects?: InputMaybe<Buyer_Project_Arr_Rel_Insert_Input>;
  buyerProjectsByAgent?: InputMaybe<Buyer_Project_Arr_Rel_Insert_Input>;
  code_timestamp?: InputMaybe<Scalars['timestamptz']>;
  cognito_id?: InputMaybe<Scalars['uuid']>;
  contacts?: InputMaybe<Contact_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  embed?: InputMaybe<Embed_Obj_Rel_Insert_Input>;
  experiments?: InputMaybe<User_Experiment_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  hubspot?: InputMaybe<Hubspot_User_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  impersonation_requested_at?: InputMaybe<Scalars['bigint']>;
  invitations?: InputMaybe<Invitation_Arr_Rel_Insert_Input>;
  is_enable?: InputMaybe<Scalars['Boolean']>;
  is_sign_up_notification_sent?: InputMaybe<Scalars['Boolean']>;
  last_login_at?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  link_login_requested_at?: InputMaybe<Scalars['bigint']>;
  login_secret?: InputMaybe<Scalars['uuid']>;
  members?: InputMaybe<Member_Arr_Rel_Insert_Input>;
  middle_name?: InputMaybe<Scalars['String']>;
  notification_owner?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_number_verified?: InputMaybe<Scalars['Boolean']>;
  push_tokens?: InputMaybe<User_Push_Token_Arr_Rel_Insert_Input>;
  sf_id?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Organisation_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_switch_requested_at?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  allowed_roles?: InputMaybe<Order_By>;
  code_timestamp?: InputMaybe<Order_By>;
  cognito_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impersonation_requested_at?: InputMaybe<Order_By>;
  last_login_at?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  login_secret?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  notification_owner?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  allowed_roles?: InputMaybe<Order_By>;
  code_timestamp?: InputMaybe<Order_By>;
  cognito_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impersonation_requested_at?: InputMaybe<Order_By>;
  last_login_at?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  login_secret?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  notification_owner?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  allowed_roles?: InputMaybe<Order_By>;
  buyerProjectsByAgent_aggregate?: InputMaybe<Buyer_Project_Aggregate_Order_By>;
  buyerProjects_aggregate?: InputMaybe<Buyer_Project_Aggregate_Order_By>;
  code_timestamp?: InputMaybe<Order_By>;
  cognito_id?: InputMaybe<Order_By>;
  contacts_aggregate?: InputMaybe<Contact_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  embed?: InputMaybe<Embed_Order_By>;
  experiments_aggregate?: InputMaybe<User_Experiment_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  hubspot_aggregate?: InputMaybe<Hubspot_User_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  impersonation_requested_at?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitation_Aggregate_Order_By>;
  is_enable?: InputMaybe<Order_By>;
  is_sign_up_notification_sent?: InputMaybe<Order_By>;
  last_login_at?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  login_secret?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Member_Aggregate_Order_By>;
  middle_name?: InputMaybe<Order_By>;
  notification_owner?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_number_verified?: InputMaybe<Order_By>;
  push_tokens_aggregate?: InputMaybe<User_Push_Token_Aggregate_Order_By>;
  sf_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Organisation_Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export type User_Push_Token_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Push_Token_Aggregate_Bool_Exp_Count>;
};

export type User_Push_Token_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Push_Token_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "user_push_token" */
export type User_Push_Token_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Push_Token_Max_Order_By>;
  min?: InputMaybe<User_Push_Token_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_push_token" */
export type User_Push_Token_Arr_Rel_Insert_Input = {
  data: Array<User_Push_Token_Insert_Input>;
  on_conflict?: InputMaybe<User_Push_Token_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_push_token". All fields are combined with a logical 'AND'. */
export type User_Push_Token_Bool_Exp = {
  _and?: InputMaybe<Array<User_Push_Token_Bool_Exp>>;
  _not?: InputMaybe<User_Push_Token_Bool_Exp>;
  _or?: InputMaybe<Array<User_Push_Token_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "user_push_token" */
export type User_Push_Token_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_push_token" */
export type User_Push_Token_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_push_token" */
export type User_Push_Token_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "user_push_token" */
export type User_Push_Token_On_Conflict = {
  constraint: User_Push_Token_Constraint;
  update_columns: Array<User_Push_Token_Update_Column>;
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "user_push_token". */
export type User_Push_Token_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_push_token */
export type User_Push_Token_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "user_push_token" */
export type User_Push_Token_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_push_token" */
export type User_Push_Token_Stream_Cursor_Input = {
  initial_value: User_Push_Token_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Push_Token_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type User_Push_Token_Updates = {
  _set?: InputMaybe<User_Push_Token_Set_Input>;
  where: User_Push_Token_Bool_Exp;
};

/** input type for updating data in table "user" */
export type User_Set_Input = {
  allowed_roles?: InputMaybe<Scalars['String']>;
  code_timestamp?: InputMaybe<Scalars['timestamptz']>;
  cognito_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  impersonation_requested_at?: InputMaybe<Scalars['bigint']>;
  is_enable?: InputMaybe<Scalars['Boolean']>;
  is_sign_up_notification_sent?: InputMaybe<Scalars['Boolean']>;
  last_login_at?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  link_login_requested_at?: InputMaybe<Scalars['bigint']>;
  login_secret?: InputMaybe<Scalars['uuid']>;
  middle_name?: InputMaybe<Scalars['String']>;
  notification_owner?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_number_verified?: InputMaybe<Scalars['Boolean']>;
  sf_id?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_switch_requested_at?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "user" */
export type User_Stddev_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "user" */
export type User_Stddev_Pop_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "user" */
export type User_Stddev_Samp_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  initial_value: User_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  allowed_roles?: InputMaybe<Scalars['String']>;
  code_timestamp?: InputMaybe<Scalars['timestamptz']>;
  cognito_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  impersonation_requested_at?: InputMaybe<Scalars['bigint']>;
  is_enable?: InputMaybe<Scalars['Boolean']>;
  is_sign_up_notification_sent?: InputMaybe<Scalars['Boolean']>;
  last_login_at?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  link_login_requested_at?: InputMaybe<Scalars['bigint']>;
  login_secret?: InputMaybe<Scalars['uuid']>;
  middle_name?: InputMaybe<Scalars['String']>;
  notification_owner?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_number_verified?: InputMaybe<Scalars['Boolean']>;
  sf_id?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_switch_requested_at?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "user" */
export type User_Sum_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

export type User_Updates = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** order by var_pop() on columns of table "user" */
export type User_Var_Pop_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "user" */
export type User_Var_Samp_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "user" */
export type User_Variance_Order_By = {
  impersonation_requested_at?: InputMaybe<Order_By>;
  link_login_requested_at?: InputMaybe<Order_By>;
  team_switch_requested_at?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** Boolean expression to filter rows from the table "variation". All fields are combined with a logical 'AND'. */
export type Variation_Bool_Exp = {
  _and?: InputMaybe<Array<Variation_Bool_Exp>>;
  _not?: InputMaybe<Variation_Bool_Exp>;
  _or?: InputMaybe<Array<Variation_Bool_Exp>>;
  approved?: InputMaybe<Boolean_Comparison_Exp>;
  category?: InputMaybe<Variation_Category_Enum_Comparison_Exp>;
  commission_payable?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_applied?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Deal_Bool_Exp>;
  deal_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_pass_on_contract_deposit?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pass_on_cost?: InputMaybe<Boolean_Comparison_Exp>;
  sf_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Variation_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
  variation_category?: InputMaybe<Variation_Category_Bool_Exp>;
  variation_type?: InputMaybe<Variation_Type_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "variation_category". All fields are combined with a logical 'AND'. */
export type Variation_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Variation_Category_Bool_Exp>>;
  _not?: InputMaybe<Variation_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Variation_Category_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "variation_category_enum". All fields are combined with logical 'AND'. */
export type Variation_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Variation_Category_Enum>;
  _in?: InputMaybe<Array<Variation_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Variation_Category_Enum>;
  _nin?: InputMaybe<Array<Variation_Category_Enum>>;
};

/** input type for inserting data into table "variation_category" */
export type Variation_Category_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "variation_category" */
export type Variation_Category_Obj_Rel_Insert_Input = {
  data: Variation_Category_Insert_Input;
  on_conflict?: InputMaybe<Variation_Category_On_Conflict>;
};

/** on_conflict condition type for table "variation_category" */
export type Variation_Category_On_Conflict = {
  constraint: Variation_Category_Constraint;
  update_columns: Array<Variation_Category_Update_Column>;
  where?: InputMaybe<Variation_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "variation_category". */
export type Variation_Category_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: variation_category */
export type Variation_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "variation_category" */
export type Variation_Category_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "variation_category" */
export type Variation_Category_Stream_Cursor_Input = {
  initial_value: Variation_Category_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Variation_Category_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Variation_Category_Updates = {
  _set?: InputMaybe<Variation_Category_Set_Input>;
  where: Variation_Category_Bool_Exp;
};

/** input type for incrementing numeric columns in table "variation" */
export type Variation_Inc_Input = {
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "variation" */
export type Variation_Insert_Input = {
  approved?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Variation_Category_Enum>;
  commission_payable?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_applied?: InputMaybe<Scalars['timestamptz']>;
  deal?: InputMaybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_pass_on_contract_deposit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pass_on_cost?: InputMaybe<Scalars['Boolean']>;
  sf_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Variation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
  variation_category?: InputMaybe<Variation_Category_Obj_Rel_Insert_Input>;
  variation_type?: InputMaybe<Variation_Type_Obj_Rel_Insert_Input>;
};

/** on_conflict condition type for table "variation" */
export type Variation_On_Conflict = {
  constraint: Variation_Constraint;
  update_columns: Array<Variation_Update_Column>;
  where?: InputMaybe<Variation_Bool_Exp>;
};

/** Ordering options when selecting data from "variation". */
export type Variation_Order_By = {
  approved?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  commission_payable?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_applied?: InputMaybe<Order_By>;
  deal?: InputMaybe<Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_pass_on_contract_deposit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pass_on_cost?: InputMaybe<Order_By>;
  sf_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  variation_category?: InputMaybe<Variation_Category_Order_By>;
  variation_type?: InputMaybe<Variation_Type_Order_By>;
};

/** primary key columns input for table: variation */
export type Variation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "variation" */
export type Variation_Set_Input = {
  approved?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Variation_Category_Enum>;
  commission_payable?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_applied?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_pass_on_contract_deposit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pass_on_cost?: InputMaybe<Scalars['Boolean']>;
  sf_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Variation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "variation" */
export type Variation_Stream_Cursor_Input = {
  initial_value: Variation_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Variation_Stream_Cursor_Value_Input = {
  approved?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Variation_Category_Enum>;
  commission_payable?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date_applied?: InputMaybe<Scalars['timestamptz']>;
  deal_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_pass_on_contract_deposit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pass_on_cost?: InputMaybe<Scalars['Boolean']>;
  sf_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Variation_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "variation_type". All fields are combined with a logical 'AND'. */
export type Variation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Variation_Type_Bool_Exp>>;
  _not?: InputMaybe<Variation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Variation_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "variation_type_category". All fields are combined with a logical 'AND'. */
export type Variation_Type_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Variation_Type_Category_Bool_Exp>>;
  _not?: InputMaybe<Variation_Type_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Variation_Type_Category_Bool_Exp>>;
  category_value?: InputMaybe<Variation_Category_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type_value?: InputMaybe<Variation_Type_Enum_Comparison_Exp>;
};

/** input type for inserting data into table "variation_type_category" */
export type Variation_Type_Category_Insert_Input = {
  category_value?: InputMaybe<Variation_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  type_value?: InputMaybe<Variation_Type_Enum>;
};

/** on_conflict condition type for table "variation_type_category" */
export type Variation_Type_Category_On_Conflict = {
  constraint: Variation_Type_Category_Constraint;
  update_columns: Array<Variation_Type_Category_Update_Column>;
  where?: InputMaybe<Variation_Type_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "variation_type_category". */
export type Variation_Type_Category_Order_By = {
  category_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: variation_type_category */
export type Variation_Type_Category_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** input type for updating data in table "variation_type_category" */
export type Variation_Type_Category_Set_Input = {
  category_value?: InputMaybe<Variation_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  type_value?: InputMaybe<Variation_Type_Enum>;
};

/** Streaming cursor of the table "variation_type_category" */
export type Variation_Type_Category_Stream_Cursor_Input = {
  initial_value: Variation_Type_Category_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Variation_Type_Category_Stream_Cursor_Value_Input = {
  category_value?: InputMaybe<Variation_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  type_value?: InputMaybe<Variation_Type_Enum>;
};

export type Variation_Type_Category_Updates = {
  _set?: InputMaybe<Variation_Type_Category_Set_Input>;
  where: Variation_Type_Category_Bool_Exp;
};

/** Boolean expression to compare columns of type "variation_type_enum". All fields are combined with logical 'AND'. */
export type Variation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Variation_Type_Enum>;
  _in?: InputMaybe<Array<Variation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Variation_Type_Enum>;
  _nin?: InputMaybe<Array<Variation_Type_Enum>>;
};

/** input type for inserting data into table "variation_type" */
export type Variation_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "variation_type" */
export type Variation_Type_Obj_Rel_Insert_Input = {
  data: Variation_Type_Insert_Input;
  on_conflict?: InputMaybe<Variation_Type_On_Conflict>;
};

/** on_conflict condition type for table "variation_type" */
export type Variation_Type_On_Conflict = {
  constraint: Variation_Type_Constraint;
  update_columns: Array<Variation_Type_Update_Column>;
  where?: InputMaybe<Variation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "variation_type". */
export type Variation_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: variation_type */
export type Variation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "variation_type" */
export type Variation_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "variation_type" */
export type Variation_Type_Stream_Cursor_Input = {
  initial_value: Variation_Type_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Variation_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Variation_Type_Updates = {
  _set?: InputMaybe<Variation_Type_Set_Input>;
  where: Variation_Type_Bool_Exp;
};

export type Variation_Updates = {
  _inc?: InputMaybe<Variation_Inc_Input>;
  _set?: InputMaybe<Variation_Set_Input>;
  where: Variation_Bool_Exp;
};

/** Boolean expression to filter rows from the table "web_app_feature". All fields are combined with a logical 'AND'. */
export type Web_App_Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Web_App_Feature_Bool_Exp>>;
  _not?: InputMaybe<Web_App_Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Web_App_Feature_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "web_app_feature_enum". All fields are combined with logical 'AND'. */
export type Web_App_Feature_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Web_App_Feature_Enum>;
  _in?: InputMaybe<Array<Web_App_Feature_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Web_App_Feature_Enum>;
  _nin?: InputMaybe<Array<Web_App_Feature_Enum>>;
};

/** input type for inserting data into table "web_app_feature" */
export type Web_App_Feature_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "web_app_feature" */
export type Web_App_Feature_Obj_Rel_Insert_Input = {
  data: Web_App_Feature_Insert_Input;
  on_conflict?: InputMaybe<Web_App_Feature_On_Conflict>;
};

/** on_conflict condition type for table "web_app_feature" */
export type Web_App_Feature_On_Conflict = {
  constraint: Web_App_Feature_Constraint;
  update_columns: Array<Web_App_Feature_Update_Column>;
  where?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "web_app_feature". */
export type Web_App_Feature_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: web_app_feature */
export type Web_App_Feature_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** input type for updating data in table "web_app_feature" */
export type Web_App_Feature_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "web_app_feature" */
export type Web_App_Feature_Stream_Cursor_Input = {
  initial_value: Web_App_Feature_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Web_App_Feature_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Web_App_Feature_Updates = {
  _set?: InputMaybe<Web_App_Feature_Set_Input>;
  where: Web_App_Feature_Bool_Exp;
};

/** Boolean expression to filter rows from the table "websocket_log". All fields are combined with a logical 'AND'. */
export type Websocket_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Websocket_Log_Bool_Exp>>;
  _not?: InputMaybe<Websocket_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Websocket_Log_Bool_Exp>>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Numeric_Comparison_Exp>;
  operation_name?: InputMaybe<String_Comparison_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_role?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "websocket_log" */
export type Websocket_Log_Inc_Input = {
  duration?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "websocket_log" */
export type Websocket_Log_Insert_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['numeric']>;
  operation_name?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_role?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "websocket_log" */
export type Websocket_Log_On_Conflict = {
  constraint: Websocket_Log_Constraint;
  update_columns: Array<Websocket_Log_Update_Column>;
  where?: InputMaybe<Websocket_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "websocket_log". */
export type Websocket_Log_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  operation_name?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: websocket_log */
export type Websocket_Log_Pk_Columns_Input = {
  request_id: Scalars['uuid'];
};

/** input type for updating data in table "websocket_log" */
export type Websocket_Log_Set_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['numeric']>;
  operation_name?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_role?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "websocket_log" */
export type Websocket_Log_Stream_Cursor_Input = {
  initial_value: Websocket_Log_Stream_Cursor_Value_Input;
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Websocket_Log_Stream_Cursor_Value_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['numeric']>;
  operation_name?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_role?: InputMaybe<Scalars['String']>;
};

export type Websocket_Log_Updates = {
  _inc?: InputMaybe<Websocket_Log_Inc_Input>;
  _set?: InputMaybe<Websocket_Log_Set_Input>;
  where: Websocket_Log_Bool_Exp;
};


export type AbnAcnLookupResp = {
__typename?: 'AbnAcnLookupResp';

  Abn?: Maybe<Scalars['String']>;
  AbnStatus?: Maybe<Scalars['String']>;
  AbnStatusEffectiveFrom?: Maybe<Scalars['String']>;
  Acn?: Maybe<Scalars['String']>;
  AddressDate?: Maybe<Scalars['String']>;
  AddressPostcode?: Maybe<Scalars['String']>;
  AddressState?: Maybe<Scalars['String']>;
  BusinessName: Array<Scalars['String']>;
  EntityName?: Maybe<Scalars['String']>;
  EntityTypeCode?: Maybe<Scalars['String']>;
  EntityTypeName?: Maybe<Scalars['String']>;
  Gst?: Maybe<Scalars['String']>;
  Message: Scalars['String'];
  Success: Scalars['Boolean'];
};

export type AcceptInviteResponse = {
__typename?: 'AcceptInviteResponse';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ActionResp = {
__typename?: 'ActionResp';

  data: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AddressAutoCompleteGroupOutput = {
__typename?: 'AddressAutoCompleteGroupOutput';

  completions?: Maybe<Array<AddressAutoCompleteOutput>>;
};

export type AddressAutoCompleteOutput = {
__typename?: 'AddressAutoCompleteOutput';

  canonical_address_id?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  highlighted_full_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type AgencyByEmail = {
__typename?: 'AgencyByEmail';

  address_city_suburb: Scalars['String'];
  address_state: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  is_partner: Scalars['Boolean'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  website: Scalars['String'];
};

export type AllocateProjectStagesOpenResp = {
__typename?: 'AllocateProjectStagesOpenResp';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AllocateProjectStagesResp = {
__typename?: 'AllocateProjectStagesResp';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureCopyEnvelopeResp = {
__typename?: 'AnnatureCopyEnvelopeResp';

  dstEnvelopeId: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureCreateAccountResp = {
__typename?: 'AnnatureCreateAccountResp';

  error: Scalars['String'];
  isVerified: Scalars['Boolean'];
  success: Scalars['Boolean'];
};

export type AnnatureCreateEnvelopeResp = {
__typename?: 'AnnatureCreateEnvelopeResp';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  url: Scalars['String'];
};

export type AnnatureDeleteEnvelopeResp = {
__typename?: 'AnnatureDeleteEnvelopeResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureEnvelopeResp = {
__typename?: 'AnnatureEnvelopeResp';

  id: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type AnnatureGetEnvelopeDoc = {
__typename?: 'AnnatureGetEnvelopeDoc';

  created: Scalars['String'];
  id: Scalars['String'];
  master?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  original?: Maybe<Scalars['String']>;
  pages: Scalars['Int'];
};

export type AnnatureGetEnvelopeResp = {
__typename?: 'AnnatureGetEnvelopeResp';

  documents: Array<AnnatureGetEnvelopeDoc>;
  error: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureGroupHasura = {
__typename?: 'AnnatureGroupHasura';

  address: Scalars['String'];
  business: Scalars['String'];
  colour: Scalars['String'];
  created: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  website: Scalars['String'];
};

export type AnnatureGroupsResp = {
__typename?: 'AnnatureGroupsResp';

  groups: Array<AnnatureGroupHasura>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureResendSigningEmailResp = {
__typename?: 'AnnatureResendSigningEmailResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureSearchEnvelopeResp = {
__typename?: 'AnnatureSearchEnvelopeResp';

  envelopes?: Maybe<Array<AnnatureEnvelopeResp>>;
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AnnatureValidateEnvelopeResp = {
__typename?: 'AnnatureValidateEnvelopeResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
  url: Scalars['String'];
};

export type AnnatureVoidEnvelopeResp = {
__typename?: 'AnnatureVoidEnvelopeResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type BuyerDealContactResp = {
__typename?: 'BuyerDealContactResp';

  agentOrganisationId: Scalars['String'];
  citySuburb: Scalars['String'];
  contactId: Scalars['String'];
  country: Scalars['String'];
  dealContactId: Scalars['String'];
  email: Scalars['String'];
  firb_purchaser?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  isExisting: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  lastName: Scalars['String'];
  name_on_contract: Scalars['String'];
  nationality: Scalars['String'];
  organization_id: Scalars['String'];
  phoneNo: Scalars['String'];
  postcode: Scalars['String'];
  purchase_proposal_sent: Scalars['Boolean'];
  state: Scalars['String'];
  street: Scalars['String'];
  tax_file_number?: Maybe<Scalars['String']>;
};

export type BuyerPropertyDetailResp = {
__typename?: 'BuyerPropertyDetailResp';

  area: Scalars['String'];
  aspect: Scalars['String'];
  bath: Scalars['String'];
  bed: Scalars['String'];
  car: Scalars['String'];
  delegatedById: Scalars['String'];
  delegatedByLogoUrl: Scalars['String'];
  delegatedByName: Scalars['String'];
  developerId: Scalars['String'];
  developerLogoUrl: Scalars['String'];
  developerName: Scalars['String'];
  error: Scalars['String'];
  externalArea: Scalars['Int'];
  id: Scalars['String'];
  images?: Maybe<Array<BuyerPropertyImage>>;
  internalArea: Scalars['Int'];
  isShowPricing: Scalars['Boolean'];
  level: Scalars['String'];
  lot: Scalars['Int'];
  lotArea: Scalars['Int'];
  lotDepth: Scalars['String'];
  lotWidth: Scalars['String'];
  name: Scalars['String'];
  orgLogoDownloadUrl: Scalars['String'];
  price: Scalars['String'];
  projectBrandBgColor: Scalars['String'];
  projectBrandFgColor: Scalars['String'];
  projectLogo: Scalars['String'];
  projectName: Scalars['String'];
  projectType: Scalars['String'];
  releaseNumber: Scalars['String'];
  stageName: Scalars['String'];
  status: Scalars['String'];
  storage: Scalars['Boolean'];
  subStageName: Scalars['String'];
  type: Scalars['String'];
};

export type BuyerPropertyImage = {
__typename?: 'BuyerPropertyImage';

  contentType?: Maybe<Scalars['String']>;
  downloadURL: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  logoCardURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productTypes?: Maybe<Array<Scalars['String']>>;
  projectCardURL?: Maybe<Scalars['String']>;
  thumbnailCardURL?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploadedByTeamId?: Maybe<Scalars['String']>;
  uploadedByUserId?: Maybe<Scalars['String']>;
};

export type BuyerPropertyListResp = {
__typename?: 'BuyerPropertyListResp';

  area: Scalars['String'];
  aspect: Scalars['String'];
  bath: Scalars['String'];
  bed: Scalars['String'];
  car: Scalars['String'];
  error: Scalars['String'];
  id: Scalars['String'];
  isShowPricing: Scalars['Boolean'];
  level: Scalars['String'];
  lotDepth: Scalars['String'];
  lotWidth: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['String'];
  projectType: Scalars['String'];
  stageName: Scalars['String'];
};

export type BuyerQrCodeSigInResp = {
__typename?: 'BuyerQrCodeSigInResp';

  buyerContactId?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  isEoi: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type BuyerQrCodeSignUpResp = {
__typename?: 'BuyerQrCodeSignUpResp';

  buyerContactId: Scalars['String'];
  error: Scalars['String'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type CancelDealResponse = {
__typename?: 'CancelDealResponse';

  error?: Maybe<Scalars['String']>;
  newDealId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CheckTransactionResponse = {
__typename?: 'CheckTransactionResponse';

  origin_status?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ClientRegistrationDealResponse = {
__typename?: 'ClientRegistrationDealResponse';

  dealId: Scalars['String'];
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ClientReservationResponse = {
__typename?: 'ClientReservationResponse';

  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CloudEndpoint = {
__typename?: 'CloudEndpoint';

  success: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type CodeVerificationResponse = {
__typename?: 'CodeVerificationResponse';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CognitoRecoverUserResp = {
__typename?: 'CognitoRecoverUserResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ContactResponse = {
__typename?: 'ContactResponse';

  contactId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateCodeResp = {
__typename?: 'CreateCodeResp';

  error: Scalars['String'];
  id: Scalars['String'];
};

export type CreateEmbedResponse = {
__typename?: 'CreateEmbedResponse';

  embedId: Scalars['String'];
  embedUserId: Scalars['String'];
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateSandboxDomainAgencyResponse = {
__typename?: 'CreateSandboxDomainAgencyResponse';

  agencyId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CypressDropResponse = {
__typename?: 'CypressDropResponse';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CypressLoginResponse = {
__typename?: 'CypressLoginResponse';

  error: Scalars['String'];
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export type CypressSignUpResponse = {
__typename?: 'CypressSignUpResponse';

  error: Scalars['String'];
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export type DealContactResp = {
__typename?: 'DealContactResp';

  attachment_id?: Maybe<Scalars['String']>;
  attachment_name?: Maybe<Scalars['String']>;
  attachment_type?: Maybe<Scalars['String']>;
  content_type?: Maybe<Scalars['String']>;
  document_id?: Maybe<Scalars['String']>;
  download_url?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firb_purchaser?: Maybe<Scalars['Boolean']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  id_proof_type?: Maybe<Scalars['String']>;
  id_verified: Scalars['Boolean'];
  is_valid?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  tax_file_number?: Maybe<Scalars['String']>;
};

export type DealContractResponse = {
__typename?: 'DealContractResponse';

  dealId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DealResponse = {
__typename?: 'DealResponse';

  contactId?: Maybe<Scalars['String']>;
  dealContactId?: Maybe<Scalars['String']>;
  dealId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DealSolicitorResponse = {
__typename?: 'DealSolicitorResponse';

  dealId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DealStatusUpdateResp = {
__typename?: 'DealStatusUpdateResp';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeletePropertyResp = {
__typename?: 'DeletePropertyResp';

  data: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DigitalContractSettingsResp = {
__typename?: 'DigitalContractSettingsResp';

  annatureMasterEnvelopeId: Scalars['String'];
  developerToIssueContracts: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  envelopeSenderUserId: Scalars['String'];
  inheritFromStageId: Scalars['String'];
  psolToReceiveCarbonCopy: Scalars['Boolean'];
  vendorSignatureRequired?: Maybe<Scalars['Boolean']>;
  vendorSignersUserIds: Array<Scalars['String']>;
  vsolToReceiveCarbonCopy: Scalars['Boolean'];
  waiveCoolingOff: Scalars['Boolean'];
  witnessSignatureRequired?: Maybe<Scalars['Boolean']>;
};

export type DropEmbedResponse = {
__typename?: 'DropEmbedResponse';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type EditListingResp = {
__typename?: 'EditListingResp';

  data: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type EditPropertyResp = {
__typename?: 'EditPropertyResp';

  data: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type EnquiryResponse = {
__typename?: 'EnquiryResponse';

  dealId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EoiDealResponse = {
__typename?: 'EoiDealResponse';

  error: Scalars['String'];
  listingId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type FlatFileTokenResponse = {
__typename?: 'FlatFileTokenResponse';

  accessToken: Scalars['String'];
  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetAddressDetailOutput = {
__typename?: 'GetAddressDetailOutput';

  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  box_identifier?: Maybe<Scalars['String']>;
  box_type?: Maybe<Scalars['String']>;
  canonical_address?: Maybe<Scalars['String']>;
  canonical_address_id?: Maybe<Scalars['String']>;
  dpid?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  gnaf_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  legal_parcel_id?: Maybe<Scalars['String']>;
  level_number?: Maybe<Scalars['String']>;
  level_type?: Maybe<Scalars['String']>;
  locality_name?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  lot_identifier?: Maybe<Scalars['String']>;
  meshblock?: Maybe<Scalars['String']>;
  meshblock_2016?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  primary_point_indicator?: Maybe<Scalars['String']>;
  sa1_id?: Maybe<Scalars['String']>;
  sa2_id?: Maybe<Scalars['String']>;
  site_name?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_name?: Maybe<Scalars['String']>;
  street_number_1?: Maybe<Scalars['String']>;
  street_number_2?: Maybe<Scalars['String']>;
  street_suffix?: Maybe<Scalars['String']>;
  street_type?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  unit_identifier?: Maybe<Scalars['String']>;
  unit_type?: Maybe<Scalars['String']>;
};

export type GetAgencyByEmailResp = {
__typename?: 'GetAgencyByEmailResp';

  agencies: Array<AgencyByEmail>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetAttachmentUploadRequestResp = {
__typename?: 'GetAttachmentUploadRequestResp';

  request: Scalars['String'];
};

export type GetEmbedCodeResponse = {
__typename?: 'GetEmbedCodeResponse';

  code: Scalars['String'];
  error: Scalars['String'];
  success: Scalars['Boolean'];
  url: Scalars['String'];
};

export type GetInvitationResponse = {
__typename?: 'GetInvitationResponse';

  allocation_projects?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  invitation_id?: Maybe<Scalars['uuid']>;
  invitation_role?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Scalars['String']>;
  invitation_type?: Maybe<Scalars['String']>;
  invitee_firstname?: Maybe<Scalars['String']>;
  invitee_lastname?: Maybe<Scalars['String']>;
  invitee_organisation_name?: Maybe<Scalars['String']>;
  invitee_phone_number?: Maybe<Scalars['String']>;
  inviter_firstname?: Maybe<Scalars['String']>;
  inviter_lastname?: Maybe<Scalars['String']>;
  inviter_organisation_id?: Maybe<Scalars['uuid']>;
  inviter_organisation_name?: Maybe<Scalars['String']>;
  inviter_organisation_sf_instance_id?: Maybe<Scalars['String']>;
  inviter_organisation_sf_org_id?: Maybe<Scalars['String']>;
  inviter_organisation_type?: Maybe<Scalars['String']>;
  is_existing: Scalars['Boolean'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetProjectByDealIdResponse = {
__typename?: 'GetProjectByDealIdResponse';

  brandBgColor: Scalars['String'];
  brandFgColor: Scalars['String'];
  error: Scalars['String'];
  projectBgURL: Scalars['String'];
  projectLogoURL: Scalars['String'];
  projectName: Scalars['String'];
  teamLogoURL: Scalars['String'];
  teamName: Scalars['String'];
  type: Scalars['String'];
};

export type GetProjectQrCodeResp = {
__typename?: 'GetProjectQrCodeResp';

  agentEmail: Scalars['String'];
  agentFirstName: Scalars['String'];
  agentLastName: Scalars['String'];
  agentLogoURL: Scalars['String'];
  agentPhone: Scalars['String'];
  canRegister: Scalars['Boolean'];
  error: Scalars['String'];
  projectBgURL: Scalars['String'];
  projectId: Scalars['String'];
  projectLogoURL1x: Scalars['String'];
  projectLogoURL2x: Scalars['String'];
  projectName: Scalars['String'];
  projectType: Scalars['String'];
  qrCodeId: Scalars['String'];
  teamId: Scalars['String'];
  teamLogoURL: Scalars['String'];
  teamName: Scalars['String'];
};

export type GetTestsResp = {
__typename?: 'GetTestsResp';

  group: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type HasuraActionResp = {
__typename?: 'HasuraActionResp';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type HasuraGeneratedThumbnail = {
__typename?: 'HasuraGeneratedThumbnail';

  error: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type HasuraJsonResponse = {
__typename?: 'HasuraJsonResponse';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type HerokuConnectBulkImportResp = {
__typename?: 'HerokuConnectBulkImportResp';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type HoldListingActionResponse = {
__typename?: 'HoldListingActionResponse';

  dealId: Scalars['String'];
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type HoldingDepositPaidResp = {
__typename?: 'HoldingDepositPaidResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type HubspotSyncResponse = {
__typename?: 'HubspotSyncResponse';

  data: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ImpersonateTokenResponse = {
__typename?: 'ImpersonateTokenResponse';

  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type IsExistingUserResp = {
__typename?: 'IsExistingUserResp';

  error: Scalars['String'];
  id: Scalars['String'];
  phone: Scalars['String'];
  phone_exists: Scalars['Boolean'];
  success: Scalars['Boolean'];
};

export type JsonResponse = {
__typename?: 'JsonResponse';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type LinkLoginResponse = {
__typename?: 'LinkLoginResponse';

  error: Scalars['String'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user_id: Scalars['String'];
};

export type MailResponse = {
__typename?: 'MailResponse';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MailSentResponse = {
__typename?: 'MailSentResponse';

  email?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type NotificationResponse = {
__typename?: 'NotificationResponse';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type NswApiBrowseResp = {
__typename?: 'NswApiBrowseResp';

  json: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type NswApiDetailsResp = {
__typename?: 'NswApiDetailsResp';

  json: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type NswApiGetTokenResp = {
__typename?: 'NswApiGetTokenResp';

  message: Scalars['String'];
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export type NswApiVerifyResp = {
__typename?: 'NswApiVerifyResp';

  json: Scalars['jsonb'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type OrgSearchResp = {
__typename?: 'OrgSearchResp';

  id: Scalars['String'];
  name: Scalars['String'];
};

export type OrganisationResponse = {
__typename?: 'OrganisationResponse';

  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type PartnerPortalAbnCheckResp = {
__typename?: 'PartnerPortalAbnCheckResp';

  has_users: Scalars['Boolean'];
  message: Scalars['String'];
  org_id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PartnerPortalIsLoginTakenResp = {
__typename?: 'PartnerPortalIsLoginTakenResp';

  isTaken?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PayResponse = {
__typename?: 'PayResponse';

  url: Scalars['String'];
};

export type ProjectDetail = {
__typename?: 'ProjectDetail';

  documents: Array<ProjectMedia>;
  id: Scalars['String'];
  images: Array<ProjectMedia>;
  projectBg: Scalars['String'];
  projectBgColor: Scalars['String'];
  projectColor: Scalars['String'];
  projectDescription: Scalars['String'];
  projectLogo: Scalars['String'];
  projectName: Scalars['String'];
  video: Array<ProjectMedia>;
};

export type ProjectMedia = {
__typename?: 'ProjectMedia';

  contentType?: Maybe<Scalars['String']>;
  downloadURL: Scalars['String'];
  id: Scalars['String'];
  imageURL: Scalars['String'];
  logoCardURL?: Maybe<Scalars['String']>;
  logoMdUrl?: Maybe<Scalars['String']>;
  logoMdUrl2?: Maybe<Scalars['String']>;
  logoSmUrl?: Maybe<Scalars['String']>;
  logoSmUrl2?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productTypes?: Maybe<Array<Scalars['String']>>;
  thumbnailCardURL?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  viewImageUrl?: Maybe<Scalars['String']>;
};

export type ProjectResponse = {
__typename?: 'ProjectResponse';

  data?: Maybe<ProjectDetail>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PublishListingResponse = {
__typename?: 'PublishListingResponse';

  data: Scalars['String'];
  messages: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RefreshAclResponse = {
__typename?: 'RefreshAclResponse';

  data?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ReserveDealResponse = {
__typename?: 'ReserveDealResponse';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RunTestResp = {
__typename?: 'RunTestResp';

  duration: Scalars['Int'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SalesforceDeleteDealResp = {
__typename?: 'SalesforceDeleteDealResp';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SalesforceSyncStatResponse = {
__typename?: 'SalesforceSyncStatResponse';

  env: Scalars['String'];
  error: Scalars['String'];
  json: Scalars['String'];
  name: Scalars['String'];
  sf_instance_id: Scalars['String'];
  totalCount: Scalars['Int'];
  totalErrorCount: Scalars['Int'];
  totalErrorRate: Scalars['Float'];
  totalPendingCount: Scalars['Int'];
  totalSuccessCount: Scalars['Int'];
  totalSuccessRate: Scalars['Float'];
};

export type SeedCreateResponse = {
__typename?: 'SeedCreateResponse';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SeedDropResponse = {
__typename?: 'SeedDropResponse';

  error: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SendCodeResp = {
__typename?: 'SendCodeResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SendPaymentReceiptToBuyerResp = {
__typename?: 'SendPaymentReceiptToBuyerResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SendReservationInvitationToBuyerResp = {
__typename?: 'SendReservationInvitationToBuyerResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SwitchTeamResp = {
__typename?: 'SwitchTeamResp';

  message: Scalars['String'];
  refreshToken: Scalars['String'];
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export type TokenVerificationLoginResp = {
__typename?: 'TokenVerificationLoginResp';

  error: Scalars['String'];
  refreshToken: Scalars['String'];
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export type TwilioMessagesResp = {
__typename?: 'TwilioMessagesResp';

  from: Scalars['String'];
  message: Scalars['String'];
  to: Scalars['String'];
};

export type TwilioSendMessageResp = {
__typename?: 'TwilioSendMessageResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UserAppConfigResp = {
__typename?: 'UserAppConfigResp';

  cdn: Scalars['String'];
  extraFeatures: Array<Scalars['String']>;
  featurePacks: Array<Scalars['String']>;
  graphQlQueryLog: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  isPhoneVerified: Scalars['Boolean'];
  memberFeatures: Array<Scalars['String']>;
  message: Scalars['String'];
  projectIdsToAllocate: Array<Scalars['String']>;
  salesforce: Array<UserAppConfigSalesforceResp>;
  success: Scalars['Boolean'];
  teamFavIconUrl: Scalars['String'];
  teamLogoUrl: Scalars['String'];
  teams: Array<UserAppConfigTeamResp>;
  webAppFeatures: Array<Scalars['String']>;
};

export type UserAppConfigSalesforceResp = {
__typename?: 'UserAppConfigSalesforceResp';

  isCrmOwner: Scalars['Boolean'];
  name: Scalars['String'];
  sfInstanceId: Scalars['String'];
};

export type UserAppConfigTeamResp = {
__typename?: 'UserAppConfigTeamResp';

  id: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type ValidateDriverLicenseOutput = {
__typename?: 'ValidateDriverLicenseOutput';

  id?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  verification_request_number?: Maybe<Scalars['String']>;
};

export type ValidateMedicareOutput = {
__typename?: 'ValidateMedicareOutput';

  id?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  verification_request_number?: Maybe<Scalars['String']>;
};

export type ValidatePassportOutput = {
__typename?: 'ValidatePassportOutput';

  id?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  verification_request_number?: Maybe<Scalars['String']>;
};

export type VerifyCodeResp = {
__typename?: 'VerifyCodeResp';

  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type VerifyMobileResponse = {
__typename?: 'VerifyMobileResponse';

  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type __Directive = {
__typename?: '__Directive';

  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  locations: Array<__DirectiveLocation>;
  args: Array<__InputValue>;
  isRepeatable: Scalars['Boolean'];
};

export type __EnumValue = {
__typename?: '__EnumValue';

  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

export type __Field = {
__typename?: '__Field';

  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

export type __InputValue = {
__typename?: '__InputValue';

  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  defaultValue?: Maybe<Scalars['String']>;
};

export type __Schema = {
__typename?: '__Schema';

  description?: Maybe<Scalars['String']>;
  types: Array<__Type>;
  queryType: __Type;
  mutationType?: Maybe<__Type>;
  subscriptionType?: Maybe<__Type>;
  directives: Array<__Directive>;
};

export type __Type = {
__typename?: '__Type';

  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
  specifiedByURL?: Maybe<Scalars['String']>;
};
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};


/** View with users who can access allocation based on team (organisation) membership */
export type Acl_Allocation = {
__typename?: 'acl_allocation';

  allocation_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_allocation" */
export type Acl_Allocation_Aggregate = {
__typename?: 'acl_allocation_aggregate';

  aggregate?: Maybe<Acl_Allocation_Aggregate_Fields>;
  nodes: Array<Acl_Allocation>;
};

/** aggregate fields of "acl_allocation" */
export type Acl_Allocation_Aggregate_Fields = {
__typename?: 'acl_allocation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Allocation_Max_Fields>;
  min?: Maybe<Acl_Allocation_Min_Fields>;
};
export type Acl_Allocation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** Used in permission checks. ACL contains users who can insert/update/delete allocations */
export type Acl_Allocation_Edit = {
__typename?: 'acl_allocation_edit';

  description?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_allocation_edit" */
export type Acl_Allocation_Edit_Aggregate = {
__typename?: 'acl_allocation_edit_aggregate';

  aggregate?: Maybe<Acl_Allocation_Edit_Aggregate_Fields>;
  nodes: Array<Acl_Allocation_Edit>;
};

/** aggregate fields of "acl_allocation_edit" */
export type Acl_Allocation_Edit_Aggregate_Fields = {
__typename?: 'acl_allocation_edit_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Allocation_Edit_Max_Fields>;
  min?: Maybe<Acl_Allocation_Edit_Min_Fields>;
};
export type Acl_Allocation_Edit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Allocation_Edit_Max_Fields = {
__typename?: 'acl_allocation_edit_max_fields';

  description?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Allocation_Edit_Min_Fields = {
__typename?: 'acl_allocation_edit_min_fields';

  description?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Acl_Allocation_Max_Fields = {
__typename?: 'acl_allocation_max_fields';

  allocation_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Allocation_Min_Fields = {
__typename?: 'acl_allocation_min_fields';

  allocation_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member = {
__typename?: 'acl_contact_agent_member';

  contact_email?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Aggregate = {
__typename?: 'acl_contact_agent_member_aggregate';

  aggregate?: Maybe<Acl_Contact_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Contact_Agent_Member>;
};

/** aggregate fields of "acl_contact_agent_member" */
export type Acl_Contact_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_contact_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Contact_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Contact_Agent_Member_Min_Fields>;
};
export type Acl_Contact_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Contact_Agent_Member_Max_Fields = {
__typename?: 'acl_contact_agent_member_max_fields';

  contact_email?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Contact_Agent_Member_Min_Fields = {
__typename?: 'acl_contact_agent_member_min_fields';

  contact_email?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member = {
__typename?: 'acl_contact_developer_member';

  contact_email?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Aggregate = {
__typename?: 'acl_contact_developer_member_aggregate';

  aggregate?: Maybe<Acl_Contact_Developer_Member_Aggregate_Fields>;
  nodes: Array<Acl_Contact_Developer_Member>;
};

/** aggregate fields of "acl_contact_developer_member" */
export type Acl_Contact_Developer_Member_Aggregate_Fields = {
__typename?: 'acl_contact_developer_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Contact_Developer_Member_Max_Fields>;
  min?: Maybe<Acl_Contact_Developer_Member_Min_Fields>;
};
export type Acl_Contact_Developer_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Contact_Developer_Member_Max_Fields = {
__typename?: 'acl_contact_developer_member_max_fields';

  contact_email?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Contact_Developer_Member_Min_Fields = {
__typename?: 'acl_contact_developer_member_min_fields';

  contact_email?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent = {
__typename?: 'acl_contact_master_agent';

  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Aggregate = {
__typename?: 'acl_contact_master_agent_aggregate';

  aggregate?: Maybe<Acl_Contact_Master_Agent_Aggregate_Fields>;
  nodes: Array<Acl_Contact_Master_Agent>;
};

/** aggregate fields of "acl_contact_master_agent" */
export type Acl_Contact_Master_Agent_Aggregate_Fields = {
__typename?: 'acl_contact_master_agent_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Contact_Master_Agent_Max_Fields>;
  min?: Maybe<Acl_Contact_Master_Agent_Min_Fields>;
};
export type Acl_Contact_Master_Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Contact_Master_Agent_Max_Fields = {
__typename?: 'acl_contact_master_agent_max_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Contact_Master_Agent_Min_Fields = {
__typename?: 'acl_contact_master_agent_min_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member = {
__typename?: 'acl_deal_agent_member';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  envelope_owner?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Aggregate = {
__typename?: 'acl_deal_agent_member_aggregate';

  aggregate?: Maybe<Acl_Deal_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Deal_Agent_Member>;
};

/** aggregate fields of "acl_deal_agent_member" */
export type Acl_Deal_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_deal_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Deal_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Deal_Agent_Member_Min_Fields>;
};
export type Acl_Deal_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Deal_Agent_Member_Max_Fields = {
__typename?: 'acl_deal_agent_member_max_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  envelope_owner?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Deal_Agent_Member_Min_Fields = {
__typename?: 'acl_deal_agent_member_min_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  envelope_owner?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member = {
__typename?: 'acl_deal_developer_member';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Aggregate = {
__typename?: 'acl_deal_developer_member_aggregate';

  aggregate?: Maybe<Acl_Deal_Developer_Member_Aggregate_Fields>;
  nodes: Array<Acl_Deal_Developer_Member>;
};

/** aggregate fields of "acl_deal_developer_member" */
export type Acl_Deal_Developer_Member_Aggregate_Fields = {
__typename?: 'acl_deal_developer_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Deal_Developer_Member_Max_Fields>;
  min?: Maybe<Acl_Deal_Developer_Member_Min_Fields>;
};
export type Acl_Deal_Developer_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Deal_Developer_Member_Max_Fields = {
__typename?: 'acl_deal_developer_member_max_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Deal_Developer_Member_Min_Fields = {
__typename?: 'acl_deal_developer_member_min_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent = {
__typename?: 'acl_deal_master_agent';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Aggregate = {
__typename?: 'acl_deal_master_agent_aggregate';

  aggregate?: Maybe<Acl_Deal_Master_Agent_Aggregate_Fields>;
  nodes: Array<Acl_Deal_Master_Agent>;
};

/** aggregate fields of "acl_deal_master_agent" */
export type Acl_Deal_Master_Agent_Aggregate_Fields = {
__typename?: 'acl_deal_master_agent_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Deal_Master_Agent_Max_Fields>;
  min?: Maybe<Acl_Deal_Master_Agent_Min_Fields>;
};
export type Acl_Deal_Master_Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Deal_Master_Agent_Max_Fields = {
__typename?: 'acl_deal_master_agent_max_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Deal_Master_Agent_Min_Fields = {
__typename?: 'acl_deal_master_agent_min_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** View with users who can access deal based on team (organisation) membership */
export type Acl_Deal_User_Team = {
__typename?: 'acl_deal_user_team';

  /** An object relationship */
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_deal_user_team" */
export type Acl_Deal_User_Team_Aggregate = {
__typename?: 'acl_deal_user_team_aggregate';

  aggregate?: Maybe<Acl_Deal_User_Team_Aggregate_Fields>;
  nodes: Array<Acl_Deal_User_Team>;
};

/** aggregate fields of "acl_deal_user_team" */
export type Acl_Deal_User_Team_Aggregate_Fields = {
__typename?: 'acl_deal_user_team_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Deal_User_Team_Max_Fields>;
  min?: Maybe<Acl_Deal_User_Team_Min_Fields>;
};
export type Acl_Deal_User_Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "acl_deal_user_team_desc" */
export type Acl_Deal_User_Team_Desc = {
__typename?: 'acl_deal_user_team_desc';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_deal_user_team_desc" */
export type Acl_Deal_User_Team_Desc_Aggregate = {
__typename?: 'acl_deal_user_team_desc_aggregate';

  aggregate?: Maybe<Acl_Deal_User_Team_Desc_Aggregate_Fields>;
  nodes: Array<Acl_Deal_User_Team_Desc>;
};

/** aggregate fields of "acl_deal_user_team_desc" */
export type Acl_Deal_User_Team_Desc_Aggregate_Fields = {
__typename?: 'acl_deal_user_team_desc_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Deal_User_Team_Desc_Max_Fields>;
  min?: Maybe<Acl_Deal_User_Team_Desc_Min_Fields>;
};
export type Acl_Deal_User_Team_Desc_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Deal_User_Team_Desc_Max_Fields = {
__typename?: 'acl_deal_user_team_desc_max_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Deal_User_Team_Desc_Min_Fields = {
__typename?: 'acl_deal_user_team_desc_min_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Acl_Deal_User_Team_Max_Fields = {
__typename?: 'acl_deal_user_team_max_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Deal_User_Team_Min_Fields = {
__typename?: 'acl_deal_user_team_min_fields';

  deal_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member = {
__typename?: 'acl_listing_agent_member';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Aggregate = {
__typename?: 'acl_listing_agent_member_aggregate';

  aggregate?: Maybe<Acl_Listing_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Agent_Member>;
};

/** aggregate fields of "acl_listing_agent_member" */
export type Acl_Listing_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_listing_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Listing_Agent_Member_Min_Fields>;
};
export type Acl_Listing_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Agent_Member_Max_Fields = {
__typename?: 'acl_listing_agent_member_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Agent_Member_Min_Fields = {
__typename?: 'acl_listing_agent_member_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_listing_developer_member" */
export type Acl_Listing_Developer_Member = {
__typename?: 'acl_listing_developer_member';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_developer_member" */
export type Acl_Listing_Developer_Member_Aggregate = {
__typename?: 'acl_listing_developer_member_aggregate';

  aggregate?: Maybe<Acl_Listing_Developer_Member_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Developer_Member>;
};

/** aggregate fields of "acl_listing_developer_member" */
export type Acl_Listing_Developer_Member_Aggregate_Fields = {
__typename?: 'acl_listing_developer_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Developer_Member_Max_Fields>;
  min?: Maybe<Acl_Listing_Developer_Member_Min_Fields>;
};
export type Acl_Listing_Developer_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Developer_Member_Max_Fields = {
__typename?: 'acl_listing_developer_member_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Developer_Member_Min_Fields = {
__typename?: 'acl_listing_developer_member_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent = {
__typename?: 'acl_listing_master_agent';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Aggregate = {
__typename?: 'acl_listing_master_agent_aggregate';

  aggregate?: Maybe<Acl_Listing_Master_Agent_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Master_Agent>;
};

/** aggregate fields of "acl_listing_master_agent" */
export type Acl_Listing_Master_Agent_Aggregate_Fields = {
__typename?: 'acl_listing_master_agent_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Master_Agent_Max_Fields>;
  min?: Maybe<Acl_Listing_Master_Agent_Min_Fields>;
};
export type Acl_Listing_Master_Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Master_Agent_Max_Fields = {
__typename?: 'acl_listing_master_agent_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Master_Agent_Min_Fields = {
__typename?: 'acl_listing_master_agent_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** View with users who can access any particular listing based on non team membership conditions */
export type Acl_Listing_Property = {
__typename?: 'acl_listing_property';

  listing_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_property" */
export type Acl_Listing_Property_Aggregate = {
__typename?: 'acl_listing_property_aggregate';

  aggregate?: Maybe<Acl_Listing_Property_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Property>;
};

/** aggregate fields of "acl_listing_property" */
export type Acl_Listing_Property_Aggregate_Fields = {
__typename?: 'acl_listing_property_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Property_Max_Fields>;
  min?: Maybe<Acl_Listing_Property_Min_Fields>;
};
export type Acl_Listing_Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** View with users who can access any particular listing based on non team membership conditions along with description */
export type Acl_Listing_Property_Desc = {
__typename?: 'acl_listing_property_desc';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_property_desc" */
export type Acl_Listing_Property_Desc_Aggregate = {
__typename?: 'acl_listing_property_desc_aggregate';

  aggregate?: Maybe<Acl_Listing_Property_Desc_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Property_Desc>;
};

/** aggregate fields of "acl_listing_property_desc" */
export type Acl_Listing_Property_Desc_Aggregate_Fields = {
__typename?: 'acl_listing_property_desc_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Property_Desc_Max_Fields>;
  min?: Maybe<Acl_Listing_Property_Desc_Min_Fields>;
};
export type Acl_Listing_Property_Desc_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Property_Desc_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Property_Desc_Max_Fields = {
__typename?: 'acl_listing_property_desc_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Property_Desc_Min_Fields = {
__typename?: 'acl_listing_property_desc_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Acl_Listing_Property_Max_Fields = {
__typename?: 'acl_listing_property_max_fields';

  listing_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** This view used for getting or checking who can access property and listing agency, developer etc. */
export type Acl_Listing_Property_Member = {
__typename?: 'acl_listing_property_member';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_property_member" */
export type Acl_Listing_Property_Member_Aggregate = {
__typename?: 'acl_listing_property_member_aggregate';

  aggregate?: Maybe<Acl_Listing_Property_Member_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Property_Member>;
};

/** aggregate fields of "acl_listing_property_member" */
export type Acl_Listing_Property_Member_Aggregate_Fields = {
__typename?: 'acl_listing_property_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Property_Member_Max_Fields>;
  min?: Maybe<Acl_Listing_Property_Member_Min_Fields>;
};
export type Acl_Listing_Property_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Property_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Property_Member_Max_Fields = {
__typename?: 'acl_listing_property_member_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Property_Member_Min_Fields = {
__typename?: 'acl_listing_property_member_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Property_Min_Fields = {
__typename?: 'acl_listing_property_min_fields';

  listing_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** View which explains why user of given team can access any particular listing */
export type Acl_Listing_Property_Team = {
__typename?: 'acl_listing_property_team';

  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Aggregate = {
__typename?: 'acl_listing_property_team_aggregate';

  aggregate?: Maybe<Acl_Listing_Property_Team_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Property_Team>;
};

/** aggregate fields of "acl_listing_property_team" */
export type Acl_Listing_Property_Team_Aggregate_Fields = {
__typename?: 'acl_listing_property_team_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Property_Team_Max_Fields>;
  min?: Maybe<Acl_Listing_Property_Team_Min_Fields>;
};
export type Acl_Listing_Property_Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** View which explains why user can access any particular listing, clone of acl_listing_property_team and acl_listing_property but with description. Works a bit slower than acl_listing_property_mat */
export type Acl_Listing_Property_Team_Desc = {
__typename?: 'acl_listing_property_team_desc';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  organisation_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_property_team_desc" */
export type Acl_Listing_Property_Team_Desc_Aggregate = {
__typename?: 'acl_listing_property_team_desc_aggregate';

  aggregate?: Maybe<Acl_Listing_Property_Team_Desc_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Property_Team_Desc>;
};

/** aggregate fields of "acl_listing_property_team_desc" */
export type Acl_Listing_Property_Team_Desc_Aggregate_Fields = {
__typename?: 'acl_listing_property_team_desc_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Property_Team_Desc_Max_Fields>;
  min?: Maybe<Acl_Listing_Property_Team_Desc_Min_Fields>;
};
export type Acl_Listing_Property_Team_Desc_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Property_Team_Desc_Max_Fields = {
__typename?: 'acl_listing_property_team_desc_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Property_Team_Desc_Min_Fields = {
__typename?: 'acl_listing_property_team_desc_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Acl_Listing_Property_Team_Max_Fields = {
__typename?: 'acl_listing_property_team_max_fields';

  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Property_Team_Min_Fields = {
__typename?: 'acl_listing_property_team_min_fields';

  listing_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  parent_property_group_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_listing_via_listing_agent_member" */
export type Acl_Listing_Via_Listing_Agent_Member = {
__typename?: 'acl_listing_via_listing_agent_member';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_via_listing_agent_member" */
export type Acl_Listing_Via_Listing_Agent_Member_Aggregate = {
__typename?: 'acl_listing_via_listing_agent_member_aggregate';

  aggregate?: Maybe<Acl_Listing_Via_Listing_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Via_Listing_Agent_Member>;
};

/** aggregate fields of "acl_listing_via_listing_agent_member" */
export type Acl_Listing_Via_Listing_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_listing_via_listing_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Via_Listing_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Listing_Via_Listing_Agent_Member_Min_Fields>;
};
export type Acl_Listing_Via_Listing_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Via_Listing_Agent_Member_Max_Fields = {
__typename?: 'acl_listing_via_listing_agent_member_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Via_Listing_Agent_Member_Min_Fields = {
__typename?: 'acl_listing_via_listing_agent_member_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_listing_via_stage_agent_member" */
export type Acl_Listing_Via_Stage_Agent_Member = {
__typename?: 'acl_listing_via_stage_agent_member';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_listing_via_stage_agent_member" */
export type Acl_Listing_Via_Stage_Agent_Member_Aggregate = {
__typename?: 'acl_listing_via_stage_agent_member_aggregate';

  aggregate?: Maybe<Acl_Listing_Via_Stage_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Listing_Via_Stage_Agent_Member>;
};

/** aggregate fields of "acl_listing_via_stage_agent_member" */
export type Acl_Listing_Via_Stage_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_listing_via_stage_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Listing_Via_Stage_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Listing_Via_Stage_Agent_Member_Min_Fields>;
};
export type Acl_Listing_Via_Stage_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Listing_Via_Stage_Agent_Member_Max_Fields = {
__typename?: 'acl_listing_via_stage_agent_member_max_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Listing_Via_Stage_Agent_Member_Min_Fields = {
__typename?: 'acl_listing_via_stage_agent_member_min_fields';

  description?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_org_agent_member" */
export type Acl_Org_Agent_Member = {
__typename?: 'acl_org_agent_member';

  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Aggregate = {
__typename?: 'acl_org_agent_member_aggregate';

  aggregate?: Maybe<Acl_Org_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Org_Agent_Member>;
};

/** aggregate fields of "acl_org_agent_member" */
export type Acl_Org_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_org_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Org_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Org_Agent_Member_Min_Fields>;
};
export type Acl_Org_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Org_Agent_Member_Max_Fields = {
__typename?: 'acl_org_agent_member_max_fields';

  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Org_Agent_Member_Min_Fields = {
__typename?: 'acl_org_agent_member_min_fields';

  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_org_developer_member" */
export type Acl_Org_Developer_Member = {
__typename?: 'acl_org_developer_member';

  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Aggregate = {
__typename?: 'acl_org_developer_member_aggregate';

  aggregate?: Maybe<Acl_Org_Developer_Member_Aggregate_Fields>;
  nodes: Array<Acl_Org_Developer_Member>;
};

/** aggregate fields of "acl_org_developer_member" */
export type Acl_Org_Developer_Member_Aggregate_Fields = {
__typename?: 'acl_org_developer_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Org_Developer_Member_Max_Fields>;
  min?: Maybe<Acl_Org_Developer_Member_Min_Fields>;
};
export type Acl_Org_Developer_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Org_Developer_Member_Max_Fields = {
__typename?: 'acl_org_developer_member_max_fields';

  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Org_Developer_Member_Min_Fields = {
__typename?: 'acl_org_developer_member_min_fields';

  description?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled = {
__typename?: 'acl_org_feature_enabled';

  feature?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Aggregate = {
__typename?: 'acl_org_feature_enabled_aggregate';

  aggregate?: Maybe<Acl_Org_Feature_Enabled_Aggregate_Fields>;
  nodes: Array<Acl_Org_Feature_Enabled>;
};

/** aggregate fields of "acl_org_feature_enabled" */
export type Acl_Org_Feature_Enabled_Aggregate_Fields = {
__typename?: 'acl_org_feature_enabled_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Org_Feature_Enabled_Max_Fields>;
  min?: Maybe<Acl_Org_Feature_Enabled_Min_Fields>;
};
export type Acl_Org_Feature_Enabled_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Org_Feature_Enabled_Max_Fields = {
__typename?: 'acl_org_feature_enabled_max_fields';

  feature?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Org_Feature_Enabled_Min_Fields = {
__typename?: 'acl_org_feature_enabled_min_fields';

  feature?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_org_master_agent" */
export type Acl_Org_Master_Agent = {
__typename?: 'acl_org_master_agent';

  description?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Aggregate = {
__typename?: 'acl_org_master_agent_aggregate';

  aggregate?: Maybe<Acl_Org_Master_Agent_Aggregate_Fields>;
  nodes: Array<Acl_Org_Master_Agent>;
};

/** aggregate fields of "acl_org_master_agent" */
export type Acl_Org_Master_Agent_Aggregate_Fields = {
__typename?: 'acl_org_master_agent_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Org_Master_Agent_Max_Fields>;
  min?: Maybe<Acl_Org_Master_Agent_Min_Fields>;
};
export type Acl_Org_Master_Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Org_Master_Agent_Max_Fields = {
__typename?: 'acl_org_master_agent_max_fields';

  description?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Org_Master_Agent_Min_Fields = {
__typename?: 'acl_org_master_agent_min_fields';

  description?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "acl_property_allocation" */
export type Acl_Property_Allocation = {
__typename?: 'acl_property_allocation';

  allocated_at?: Maybe<Scalars['timestamptz']>;
  allocated_to_org_id?: Maybe<Scalars['uuid']>;
  allocated_to_org_name?: Maybe<Scalars['String']>;
  allocation_type?: Maybe<Scalars['String']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  property_name?: Maybe<Scalars['String']>;
  property_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "acl_property_allocation" */
export type Acl_Property_Allocation_Aggregate = {
__typename?: 'acl_property_allocation_aggregate';

  aggregate?: Maybe<Acl_Property_Allocation_Aggregate_Fields>;
  nodes: Array<Acl_Property_Allocation>;
};

/** aggregate fields of "acl_property_allocation" */
export type Acl_Property_Allocation_Aggregate_Fields = {
__typename?: 'acl_property_allocation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Property_Allocation_Max_Fields>;
  min?: Maybe<Acl_Property_Allocation_Min_Fields>;
};
export type Acl_Property_Allocation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count = {
__typename?: 'acl_property_allocation_count';

  alloc_count?: Maybe<Scalars['bigint']>;
  allocated_to?: Maybe<Scalars['String']>;
  group_alloc_count?: Maybe<Scalars['bigint']>;
  last_allocated_at?: Maybe<Scalars['timestamptz']>;
  last_group_allocated_at?: Maybe<Scalars['timestamptz']>;
  last_listing_allocated_at?: Maybe<Scalars['timestamptz']>;
  listing_allocation_count?: Maybe<Scalars['bigint']>;
  property_id?: Maybe<Scalars['uuid']>;
  sort_alloc_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count_Aggregate = {
__typename?: 'acl_property_allocation_count_aggregate';

  aggregate?: Maybe<Acl_Property_Allocation_Count_Aggregate_Fields>;
  nodes: Array<Acl_Property_Allocation_Count>;
};

/** aggregate fields of "acl_property_allocation_count" */
export type Acl_Property_Allocation_Count_Aggregate_Fields = {
__typename?: 'acl_property_allocation_count_aggregate_fields';

  avg?: Maybe<Acl_Property_Allocation_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Acl_Property_Allocation_Count_Max_Fields>;
  min?: Maybe<Acl_Property_Allocation_Count_Min_Fields>;
  stddev?: Maybe<Acl_Property_Allocation_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Acl_Property_Allocation_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Acl_Property_Allocation_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Acl_Property_Allocation_Count_Sum_Fields>;
  var_pop?: Maybe<Acl_Property_Allocation_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Acl_Property_Allocation_Count_Var_Samp_Fields>;
  variance?: Maybe<Acl_Property_Allocation_Count_Variance_Fields>;
};
export type Acl_Property_Allocation_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Property_Allocation_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Acl_Property_Allocation_Count_Avg_Fields = {
__typename?: 'acl_property_allocation_count_avg_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Acl_Property_Allocation_Count_Max_Fields = {
__typename?: 'acl_property_allocation_count_max_fields';

  alloc_count?: Maybe<Scalars['bigint']>;
  allocated_to?: Maybe<Scalars['String']>;
  group_alloc_count?: Maybe<Scalars['bigint']>;
  last_allocated_at?: Maybe<Scalars['timestamptz']>;
  last_group_allocated_at?: Maybe<Scalars['timestamptz']>;
  last_listing_allocated_at?: Maybe<Scalars['timestamptz']>;
  listing_allocation_count?: Maybe<Scalars['bigint']>;
  property_id?: Maybe<Scalars['uuid']>;
  sort_alloc_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Acl_Property_Allocation_Count_Min_Fields = {
__typename?: 'acl_property_allocation_count_min_fields';

  alloc_count?: Maybe<Scalars['bigint']>;
  allocated_to?: Maybe<Scalars['String']>;
  group_alloc_count?: Maybe<Scalars['bigint']>;
  last_allocated_at?: Maybe<Scalars['timestamptz']>;
  last_group_allocated_at?: Maybe<Scalars['timestamptz']>;
  last_listing_allocated_at?: Maybe<Scalars['timestamptz']>;
  listing_allocation_count?: Maybe<Scalars['bigint']>;
  property_id?: Maybe<Scalars['uuid']>;
  sort_alloc_count?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Acl_Property_Allocation_Count_Stddev_Fields = {
__typename?: 'acl_property_allocation_count_stddev_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Acl_Property_Allocation_Count_Stddev_Pop_Fields = {
__typename?: 'acl_property_allocation_count_stddev_pop_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Acl_Property_Allocation_Count_Stddev_Samp_Fields = {
__typename?: 'acl_property_allocation_count_stddev_samp_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Acl_Property_Allocation_Count_Sum_Fields = {
__typename?: 'acl_property_allocation_count_sum_fields';

  alloc_count?: Maybe<Scalars['bigint']>;
  group_alloc_count?: Maybe<Scalars['bigint']>;
  listing_allocation_count?: Maybe<Scalars['bigint']>;
  sort_alloc_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Acl_Property_Allocation_Count_Var_Pop_Fields = {
__typename?: 'acl_property_allocation_count_var_pop_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Acl_Property_Allocation_Count_Var_Samp_Fields = {
__typename?: 'acl_property_allocation_count_var_samp_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Acl_Property_Allocation_Count_Variance_Fields = {
__typename?: 'acl_property_allocation_count_variance_fields';

  alloc_count?: Maybe<Scalars['Float']>;
  group_alloc_count?: Maybe<Scalars['Float']>;
  listing_allocation_count?: Maybe<Scalars['Float']>;
  sort_alloc_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Acl_Property_Allocation_Max_Fields = {
__typename?: 'acl_property_allocation_max_fields';

  allocated_at?: Maybe<Scalars['timestamptz']>;
  allocated_to_org_id?: Maybe<Scalars['uuid']>;
  allocated_to_org_name?: Maybe<Scalars['String']>;
  allocation_type?: Maybe<Scalars['String']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  property_name?: Maybe<Scalars['String']>;
  property_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Acl_Property_Allocation_Min_Fields = {
__typename?: 'acl_property_allocation_min_fields';

  allocated_at?: Maybe<Scalars['timestamptz']>;
  allocated_to_org_id?: Maybe<Scalars['uuid']>;
  allocated_to_org_name?: Maybe<Scalars['String']>;
  allocation_type?: Maybe<Scalars['String']>;
  listing_status?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  property_name?: Maybe<Scalars['String']>;
  property_type?: Maybe<Scalars['String']>;
};

/** columns and relationships of "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member = {
__typename?: 'acl_stage_agent_member';

  description?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Aggregate = {
__typename?: 'acl_stage_agent_member_aggregate';

  aggregate?: Maybe<Acl_Stage_Agent_Member_Aggregate_Fields>;
  nodes: Array<Acl_Stage_Agent_Member>;
};

/** aggregate fields of "acl_stage_agent_member" */
export type Acl_Stage_Agent_Member_Aggregate_Fields = {
__typename?: 'acl_stage_agent_member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Stage_Agent_Member_Max_Fields>;
  min?: Maybe<Acl_Stage_Agent_Member_Min_Fields>;
};
export type Acl_Stage_Agent_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Stage_Agent_Member_Max_Fields = {
__typename?: 'acl_stage_agent_member_max_fields';

  description?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Acl_Stage_Agent_Member_Min_Fields = {
__typename?: 'acl_stage_agent_member_min_fields';

  description?: Maybe<Scalars['String']>;
  stage_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Tracks requests to update ACLs and their status */
export type Acl_Update = {
__typename?: 'acl_update';

  acl: Scalars['String'];
  created_at: Scalars['timestamptz'];
  message: Scalars['String'];
  request_count: Scalars['Int'];
  status: Acl_Update_Status_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "acl_update" */
export type Acl_Update_Aggregate = {
__typename?: 'acl_update_aggregate';

  aggregate?: Maybe<Acl_Update_Aggregate_Fields>;
  nodes: Array<Acl_Update>;
};

/** aggregate fields of "acl_update" */
export type Acl_Update_Aggregate_Fields = {
__typename?: 'acl_update_aggregate_fields';

  avg?: Maybe<Acl_Update_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Acl_Update_Max_Fields>;
  min?: Maybe<Acl_Update_Min_Fields>;
  stddev?: Maybe<Acl_Update_Stddev_Fields>;
  stddev_pop?: Maybe<Acl_Update_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Acl_Update_Stddev_Samp_Fields>;
  sum?: Maybe<Acl_Update_Sum_Fields>;
  var_pop?: Maybe<Acl_Update_Var_Pop_Fields>;
  var_samp?: Maybe<Acl_Update_Var_Samp_Fields>;
  variance?: Maybe<Acl_Update_Variance_Fields>;
};
export type Acl_Update_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Update_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Acl_Update_Avg_Fields = {
__typename?: 'acl_update_avg_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Acl_Update_Max_Fields = {
__typename?: 'acl_update_max_fields';

  acl?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  request_count?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Acl_Update_Min_Fields = {
__typename?: 'acl_update_min_fields';

  acl?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  request_count?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "acl_update" */
export type Acl_Update_Mutation_Response = {
__typename?: 'acl_update_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Acl_Update>;
};

/** ACL update statuses */
export type Acl_Update_Status = {
__typename?: 'acl_update_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "acl_update_status" */
export type Acl_Update_Status_Aggregate = {
__typename?: 'acl_update_status_aggregate';

  aggregate?: Maybe<Acl_Update_Status_Aggregate_Fields>;
  nodes: Array<Acl_Update_Status>;
};

/** aggregate fields of "acl_update_status" */
export type Acl_Update_Status_Aggregate_Fields = {
__typename?: 'acl_update_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Acl_Update_Status_Max_Fields>;
  min?: Maybe<Acl_Update_Status_Min_Fields>;
};
export type Acl_Update_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acl_Update_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Acl_Update_Status_Max_Fields = {
__typename?: 'acl_update_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Acl_Update_Status_Min_Fields = {
__typename?: 'acl_update_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "acl_update_status" */
export type Acl_Update_Status_Mutation_Response = {
__typename?: 'acl_update_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Acl_Update_Status>;
};

/** aggregate stddev on columns */
export type Acl_Update_Stddev_Fields = {
__typename?: 'acl_update_stddev_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Acl_Update_Stddev_Pop_Fields = {
__typename?: 'acl_update_stddev_pop_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Acl_Update_Stddev_Samp_Fields = {
__typename?: 'acl_update_stddev_samp_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Acl_Update_Sum_Fields = {
__typename?: 'acl_update_sum_fields';

  request_count?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Acl_Update_Var_Pop_Fields = {
__typename?: 'acl_update_var_pop_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Acl_Update_Var_Samp_Fields = {
__typename?: 'acl_update_var_samp_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Acl_Update_Variance_Fields = {
__typename?: 'acl_update_variance_fields';

  request_count?: Maybe<Scalars['Float']>;
};

/** Agency agreement between developer and agent */
export type Agency_Agreement = {
__typename?: 'agency_agreement';

  /** An object relationship */
  agency: Organisation;
  agency_abn_acn: Scalars['String'];
  agency_address_city_suburb: Scalars['String'];
  agency_address_country: Scalars['String'];
  agency_address_line_1: Scalars['String'];
  agency_address_postcode: Scalars['String'];
  agency_address_state: Scalars['String'];
  /** Full agency address from agency_address_* fields */
  agency_full_address?: Maybe<Scalars['String']>;
  agency_id: Scalars['uuid'];
  agency_name: Scalars['String'];
  agency_registered_for_gst: Scalars['Boolean'];
  agency_trading_name: Scalars['String'];
  /** An object relationship */
  agency_user?: Maybe<User>;
  agency_user_id?: Maybe<Scalars['uuid']>;
  commission_rate: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by_team_id: Scalars['uuid'];
  created_by_user_id: Scalars['uuid'];
  /** An object relationship */
  dev_user: User;
  dev_user_id: Scalars['uuid'];
  envelope_owner: Scalars['String'];
  /** An array relationship */
  envelopes: Array<Envelope>;
  /** An aggregate relationship */
  envelopes_aggregate: Envelope_Aggregate;
  id: Scalars['uuid'];
  licence_number: Scalars['String'];
  licensee_address_city_suburb: Scalars['String'];
  licensee_address_country: Scalars['String'];
  licensee_address_line_1: Scalars['String'];
  licensee_address_postcode: Scalars['String'];
  licensee_address_state: Scalars['String'];
  licensee_email: Scalars['String'];
  /** Full licensee address from licensee_address_* fields */
  licensee_full_address?: Maybe<Scalars['String']>;
  licensee_name: Scalars['String'];
  payload: Scalars['jsonb'];
  /** An object relationship */
  property_group: Property_Group;
  property_group_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};
export type Agency_AgreementEnvelopesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Agency_AgreementEnvelopes_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Agency_AgreementPayloadArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "agency_agreement" */
export type Agency_Agreement_Aggregate = {
__typename?: 'agency_agreement_aggregate';

  aggregate?: Maybe<Agency_Agreement_Aggregate_Fields>;
  nodes: Array<Agency_Agreement>;
};

/** aggregate fields of "agency_agreement" */
export type Agency_Agreement_Aggregate_Fields = {
__typename?: 'agency_agreement_aggregate_fields';

  avg?: Maybe<Agency_Agreement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agency_Agreement_Max_Fields>;
  min?: Maybe<Agency_Agreement_Min_Fields>;
  stddev?: Maybe<Agency_Agreement_Stddev_Fields>;
  stddev_pop?: Maybe<Agency_Agreement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agency_Agreement_Stddev_Samp_Fields>;
  sum?: Maybe<Agency_Agreement_Sum_Fields>;
  var_pop?: Maybe<Agency_Agreement_Var_Pop_Fields>;
  var_samp?: Maybe<Agency_Agreement_Var_Samp_Fields>;
  variance?: Maybe<Agency_Agreement_Variance_Fields>;
};
export type Agency_Agreement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Agency_Agreement_Avg_Fields = {
__typename?: 'agency_agreement_avg_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Agency_Agreement_Max_Fields = {
__typename?: 'agency_agreement_max_fields';

  agency_abn_acn?: Maybe<Scalars['String']>;
  agency_address_city_suburb?: Maybe<Scalars['String']>;
  agency_address_country?: Maybe<Scalars['String']>;
  agency_address_line_1?: Maybe<Scalars['String']>;
  agency_address_postcode?: Maybe<Scalars['String']>;
  agency_address_state?: Maybe<Scalars['String']>;
  /** Full agency address from agency_address_* fields */
  agency_full_address?: Maybe<Scalars['String']>;
  agency_id?: Maybe<Scalars['uuid']>;
  agency_name?: Maybe<Scalars['String']>;
  agency_trading_name?: Maybe<Scalars['String']>;
  agency_user_id?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  dev_user_id?: Maybe<Scalars['uuid']>;
  envelope_owner?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  licence_number?: Maybe<Scalars['String']>;
  licensee_address_city_suburb?: Maybe<Scalars['String']>;
  licensee_address_country?: Maybe<Scalars['String']>;
  licensee_address_line_1?: Maybe<Scalars['String']>;
  licensee_address_postcode?: Maybe<Scalars['String']>;
  licensee_address_state?: Maybe<Scalars['String']>;
  licensee_email?: Maybe<Scalars['String']>;
  /** Full licensee address from licensee_address_* fields */
  licensee_full_address?: Maybe<Scalars['String']>;
  licensee_name?: Maybe<Scalars['String']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Agency_Agreement_Min_Fields = {
__typename?: 'agency_agreement_min_fields';

  agency_abn_acn?: Maybe<Scalars['String']>;
  agency_address_city_suburb?: Maybe<Scalars['String']>;
  agency_address_country?: Maybe<Scalars['String']>;
  agency_address_line_1?: Maybe<Scalars['String']>;
  agency_address_postcode?: Maybe<Scalars['String']>;
  agency_address_state?: Maybe<Scalars['String']>;
  /** Full agency address from agency_address_* fields */
  agency_full_address?: Maybe<Scalars['String']>;
  agency_id?: Maybe<Scalars['uuid']>;
  agency_name?: Maybe<Scalars['String']>;
  agency_trading_name?: Maybe<Scalars['String']>;
  agency_user_id?: Maybe<Scalars['uuid']>;
  commission_rate?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  dev_user_id?: Maybe<Scalars['uuid']>;
  envelope_owner?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  licence_number?: Maybe<Scalars['String']>;
  licensee_address_city_suburb?: Maybe<Scalars['String']>;
  licensee_address_country?: Maybe<Scalars['String']>;
  licensee_address_line_1?: Maybe<Scalars['String']>;
  licensee_address_postcode?: Maybe<Scalars['String']>;
  licensee_address_state?: Maybe<Scalars['String']>;
  licensee_email?: Maybe<Scalars['String']>;
  /** Full licensee address from licensee_address_* fields */
  licensee_full_address?: Maybe<Scalars['String']>;
  licensee_name?: Maybe<Scalars['String']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "agency_agreement" */
export type Agency_Agreement_Mutation_Response = {
__typename?: 'agency_agreement_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agency_Agreement>;
};

/** aggregate stddev on columns */
export type Agency_Agreement_Stddev_Fields = {
__typename?: 'agency_agreement_stddev_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Agency_Agreement_Stddev_Pop_Fields = {
__typename?: 'agency_agreement_stddev_pop_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Agency_Agreement_Stddev_Samp_Fields = {
__typename?: 'agency_agreement_stddev_samp_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Agency_Agreement_Sum_Fields = {
__typename?: 'agency_agreement_sum_fields';

  commission_rate?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Agency_Agreement_Var_Pop_Fields = {
__typename?: 'agency_agreement_var_pop_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Agency_Agreement_Var_Samp_Fields = {
__typename?: 'agency_agreement_var_samp_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Agency_Agreement_Variance_Fields = {
__typename?: 'agency_agreement_variance_fields';

  commission_rate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "agreement" */
export type Agreement = {
__typename?: 'agreement';

  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  /** An array relationship */
  commissions: Array<Commission>;
  /** An aggregate relationship */
  commissions_aggregate: Commission_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  exchange_payable_amount?: Maybe<Scalars['numeric']>;
  exchange_payable_percentage: Scalars['numeric'];
  id: Scalars['uuid'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['uuid'];
  settlement_payable_amount?: Maybe<Scalars['numeric']>;
  settlement_payable_percentage: Scalars['numeric'];
  start_date: Scalars['date'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type AgreementAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type AgreementAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type AgreementCommissionsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type AgreementCommissions_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};


/** aggregated selection of "agreement" */
export type Agreement_Aggregate = {
__typename?: 'agreement_aggregate';

  aggregate?: Maybe<Agreement_Aggregate_Fields>;
  nodes: Array<Agreement>;
};

/** aggregate fields of "agreement" */
export type Agreement_Aggregate_Fields = {
__typename?: 'agreement_aggregate_fields';

  avg?: Maybe<Agreement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Agreement_Max_Fields>;
  min?: Maybe<Agreement_Min_Fields>;
  stddev?: Maybe<Agreement_Stddev_Fields>;
  stddev_pop?: Maybe<Agreement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Agreement_Stddev_Samp_Fields>;
  sum?: Maybe<Agreement_Sum_Fields>;
  var_pop?: Maybe<Agreement_Var_Pop_Fields>;
  var_samp?: Maybe<Agreement_Var_Samp_Fields>;
  variance?: Maybe<Agreement_Variance_Fields>;
};
export type Agreement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Agreement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Agreement_Avg_Fields = {
__typename?: 'agreement_avg_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Agreement_Max_Fields = {
__typename?: 'agreement_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  exchange_payable_amount?: Maybe<Scalars['numeric']>;
  exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  settlement_payable_amount?: Maybe<Scalars['numeric']>;
  settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Agreement_Min_Fields = {
__typename?: 'agreement_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  exchange_payable_amount?: Maybe<Scalars['numeric']>;
  exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  settlement_payable_amount?: Maybe<Scalars['numeric']>;
  settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['date']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "agreement" */
export type Agreement_Mutation_Response = {
__typename?: 'agreement_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Agreement>;
};

/** aggregate stddev on columns */
export type Agreement_Stddev_Fields = {
__typename?: 'agreement_stddev_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Agreement_Stddev_Pop_Fields = {
__typename?: 'agreement_stddev_pop_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Agreement_Stddev_Samp_Fields = {
__typename?: 'agreement_stddev_samp_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Agreement_Sum_Fields = {
__typename?: 'agreement_sum_fields';

  exchange_payable_amount?: Maybe<Scalars['numeric']>;
  exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  settlement_payable_amount?: Maybe<Scalars['numeric']>;
  settlement_payable_percentage?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Agreement_Var_Pop_Fields = {
__typename?: 'agreement_var_pop_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Agreement_Var_Samp_Fields = {
__typename?: 'agreement_var_samp_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Agreement_Variance_Fields = {
__typename?: 'agreement_variance_fields';

  exchange_payable_amount?: Maybe<Scalars['Float']>;
  exchange_payable_percentage?: Maybe<Scalars['Float']>;
  settlement_payable_amount?: Maybe<Scalars['Float']>;
  settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "allocation" */
export type Allocation = {
__typename?: 'allocation';

  access_active: Scalars['Boolean'];
  /** An array relationship */
  acl_allocation: Array<Acl_Allocation>;
  /** An aggregate relationship */
  acl_allocation_aggregate: Acl_Allocation_Aggregate;
  /** An object relationship */
  agreement?: Maybe<Agreement>;
  agreement_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  allocation_type: Allocation_Type;
  /** An object relationship */
  createdByOrganisation?: Maybe<Organisation>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  include_listings: Scalars['Boolean'];
  inherited_from?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  invitation?: Maybe<Invitation>;
  invitation_id?: Maybe<Scalars['uuid']>;
  is_frontend_updated?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  listing?: Maybe<Listing>;
  listing_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  organisation_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  property_group?: Maybe<Property_Group>;
  property_group_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  skip_up_sync: Scalars['Boolean'];
  type: Allocation_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
};
export type AllocationAcl_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};

export type AllocationAcl_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};


/** aggregated selection of "allocation" */
export type Allocation_Aggregate = {
__typename?: 'allocation_aggregate';

  aggregate?: Maybe<Allocation_Aggregate_Fields>;
  nodes: Array<Allocation>;
};

/** aggregate fields of "allocation" */
export type Allocation_Aggregate_Fields = {
__typename?: 'allocation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Allocation_Max_Fields>;
  min?: Maybe<Allocation_Min_Fields>;
};
export type Allocation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** store allocations changes history */
export type Allocation_Log = {
__typename?: 'allocation_log';

  action: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  listing_id: Scalars['uuid'];
  org_id: Scalars['uuid'];
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "allocation_log" */
export type Allocation_Log_Aggregate = {
__typename?: 'allocation_log_aggregate';

  aggregate?: Maybe<Allocation_Log_Aggregate_Fields>;
  nodes: Array<Allocation_Log>;
};

/** aggregate fields of "allocation_log" */
export type Allocation_Log_Aggregate_Fields = {
__typename?: 'allocation_log_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Allocation_Log_Max_Fields>;
  min?: Maybe<Allocation_Log_Min_Fields>;
};
export type Allocation_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allocation_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Allocation_Log_Max_Fields = {
__typename?: 'allocation_log_max_fields';

  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Allocation_Log_Min_Fields = {
__typename?: 'allocation_log_min_fields';

  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "allocation_log" */
export type Allocation_Log_Mutation_Response = {
__typename?: 'allocation_log_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allocation_Log>;
};

/** aggregate max on columns */
export type Allocation_Max_Fields = {
__typename?: 'allocation_max_fields';

  agreement_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  invitation_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Allocation_Min_Fields = {
__typename?: 'allocation_min_fields';

  agreement_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inherited_from?: Maybe<Scalars['uuid']>;
  invitation_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "allocation" */
export type Allocation_Mutation_Response = {
__typename?: 'allocation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allocation>;
};

/** columns and relationships of "allocation_type" */
export type Allocation_Type = {
__typename?: 'allocation_type';

  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Allocation_TypeAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Allocation_TypeAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};


/** aggregated selection of "allocation_type" */
export type Allocation_Type_Aggregate = {
__typename?: 'allocation_type_aggregate';

  aggregate?: Maybe<Allocation_Type_Aggregate_Fields>;
  nodes: Array<Allocation_Type>;
};

/** aggregate fields of "allocation_type" */
export type Allocation_Type_Aggregate_Fields = {
__typename?: 'allocation_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Allocation_Type_Max_Fields>;
  min?: Maybe<Allocation_Type_Min_Fields>;
};
export type Allocation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allocation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Allocation_Type_Max_Fields = {
__typename?: 'allocation_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Allocation_Type_Min_Fields = {
__typename?: 'allocation_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "allocation_type" */
export type Allocation_Type_Mutation_Response = {
__typename?: 'allocation_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allocation_Type>;
};

/** Buildings which appear on the apartment plans */
export type Apartment_Plan_Building = {
__typename?: 'apartment_plan_building';

  building_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  plan_object: Scalars['jsonb'];
  /** An object relationship */
  property_group: Property_Group;
  updated_at: Scalars['timestamptz'];
};
export type Apartment_Plan_BuildingPlan_ObjectArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "apartment_plan_building" */
export type Apartment_Plan_Building_Aggregate = {
__typename?: 'apartment_plan_building_aggregate';

  aggregate?: Maybe<Apartment_Plan_Building_Aggregate_Fields>;
  nodes: Array<Apartment_Plan_Building>;
};

/** aggregate fields of "apartment_plan_building" */
export type Apartment_Plan_Building_Aggregate_Fields = {
__typename?: 'apartment_plan_building_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Apartment_Plan_Building_Max_Fields>;
  min?: Maybe<Apartment_Plan_Building_Min_Fields>;
};
export type Apartment_Plan_Building_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Plan_Building_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Apartment_Plan_Building_Max_Fields = {
__typename?: 'apartment_plan_building_max_fields';

  building_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Apartment_Plan_Building_Min_Fields = {
__typename?: 'apartment_plan_building_min_fields';

  building_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "apartment_plan_building" */
export type Apartment_Plan_Building_Mutation_Response = {
__typename?: 'apartment_plan_building_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Apartment_Plan_Building>;
};

/** Apartments which appear on the Apartment plan */
export type Apartment_Plan_Property = {
__typename?: 'apartment_plan_property';

  apartment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  plan_object: Scalars['jsonb'];
  /** An object relationship */
  property: Property;
  updated_at: Scalars['timestamptz'];
};
export type Apartment_Plan_PropertyPlan_ObjectArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "apartment_plan_property" */
export type Apartment_Plan_Property_Aggregate = {
__typename?: 'apartment_plan_property_aggregate';

  aggregate?: Maybe<Apartment_Plan_Property_Aggregate_Fields>;
  nodes: Array<Apartment_Plan_Property>;
};

/** aggregate fields of "apartment_plan_property" */
export type Apartment_Plan_Property_Aggregate_Fields = {
__typename?: 'apartment_plan_property_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Apartment_Plan_Property_Max_Fields>;
  min?: Maybe<Apartment_Plan_Property_Min_Fields>;
};
export type Apartment_Plan_Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Plan_Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Apartment_Plan_Property_Max_Fields = {
__typename?: 'apartment_plan_property_max_fields';

  apartment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Apartment_Plan_Property_Min_Fields = {
__typename?: 'apartment_plan_property_min_fields';

  apartment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "apartment_plan_property" */
export type Apartment_Plan_Property_Mutation_Response = {
__typename?: 'apartment_plan_property_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Apartment_Plan_Property>;
};

/** columns and relationships of "area_plot" */
export type Area_Plot = {
__typename?: 'area_plot';

  /** An object relationship */
  area_plot_type: Area_Plot_Type;
  /** An object relationship */
  attachment: Attachment;
  attachment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  plot_polygon: Scalars['polygon'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  /** An object relationship */
  property?: Maybe<Property>;
  /** An object relationship */
  property_group?: Maybe<Property_Group>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  text_styling?: Maybe<Scalars['json']>;
  type: Area_Plot_Type_Enum;
  updated_at: Scalars['timestamptz'];
};
export type Area_PlotText_StylingArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "area_plot" */
export type Area_Plot_Aggregate = {
__typename?: 'area_plot_aggregate';

  aggregate?: Maybe<Area_Plot_Aggregate_Fields>;
  nodes: Array<Area_Plot>;
};

/** aggregate fields of "area_plot" */
export type Area_Plot_Aggregate_Fields = {
__typename?: 'area_plot_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Area_Plot_Max_Fields>;
  min?: Maybe<Area_Plot_Min_Fields>;
};
export type Area_Plot_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Area_Plot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Area_Plot_Max_Fields = {
__typename?: 'area_plot_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Area_Plot_Min_Fields = {
__typename?: 'area_plot_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "area_plot" */
export type Area_Plot_Mutation_Response = {
__typename?: 'area_plot_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Area_Plot>;
};

/** columns and relationships of "area_plot_type" */
export type Area_Plot_Type = {
__typename?: 'area_plot_type';

  /** An array relationship */
  area_plots: Array<Area_Plot>;
  /** An aggregate relationship */
  area_plots_aggregate: Area_Plot_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Area_Plot_TypeArea_PlotsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Area_Plot_TypeArea_Plots_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};


/** aggregated selection of "area_plot_type" */
export type Area_Plot_Type_Aggregate = {
__typename?: 'area_plot_type_aggregate';

  aggregate?: Maybe<Area_Plot_Type_Aggregate_Fields>;
  nodes: Array<Area_Plot_Type>;
};

/** aggregate fields of "area_plot_type" */
export type Area_Plot_Type_Aggregate_Fields = {
__typename?: 'area_plot_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Area_Plot_Type_Max_Fields>;
  min?: Maybe<Area_Plot_Type_Min_Fields>;
};
export type Area_Plot_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Area_Plot_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Area_Plot_Type_Max_Fields = {
__typename?: 'area_plot_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Area_Plot_Type_Min_Fields = {
__typename?: 'area_plot_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "area_plot_type" */
export type Area_Plot_Type_Mutation_Response = {
__typename?: 'area_plot_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Area_Plot_Type>;
};

/** columns and relationships of "attachment" */
export type Attachment = {
__typename?: 'attachment';

  add_to_contract?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  area_plots: Array<Area_Plot>;
  /** An aggregate relationship */
  area_plots_aggregate: Area_Plot_Aggregate;
  /** An array relationship */
  attachment_config: Array<Attachment_Config>;
  /** An aggregate relationship */
  attachment_config_aggregate: Attachment_Config_Aggregate;
  /** An array relationship */
  attachment_product_types: Array<Attachment_Product_Type>;
  /** An aggregate relationship */
  attachment_product_types_aggregate: Attachment_Product_Type_Aggregate;
  /** An object relationship */
  attachment_type: Attachment_Type;
  /** An object relationship */
  buyer_project?: Maybe<Buyer_Project>;
  /** An object relationship */
  contact?: Maybe<Contact>;
  contact_id?: Maybe<Scalars['uuid']>;
  content_type: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['uuid']>;
  download_url: Scalars['String'];
  /** An object relationship */
  edit_project_order?: Maybe<Attachment_Edit_Project_Order>;
  /** An array relationship */
  floor_properties: Array<Property>;
  /** An aggregate relationship */
  floor_properties_aggregate: Property_Aggregate;
  id: Scalars['uuid'];
  is_thumbnail_generate: Scalars['Boolean'];
  level?: Maybe<Scalars['String']>;
  /** An array relationship */
  linen_properties: Array<Property>;
  /** An aggregate relationship */
  linen_properties_aggregate: Property_Aggregate;
  /** An object relationship */
  listing?: Maybe<Listing>;
  listing_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  logo_orgs: Array<Organisation>;
  /** An aggregate relationship */
  logo_orgs_aggregate: Organisation_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organisation>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_type?: Maybe<Attachment_Owner_Enum>;
  path: Scalars['String'];
  /** An object relationship */
  project?: Maybe<Project>;
  /** An object relationship */
  projectByBackgroudAttachmentId?: Maybe<Project>;
  /** An object relationship */
  projectByLogoAttachmentId?: Maybe<Project>;
  /** An object relationship */
  projectByMasterPlanId?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  property?: Maybe<Property>;
  /** An object relationship */
  property_group?: Maybe<Property_Group>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  require_sign?: Maybe<Scalars['Boolean']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  sf_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  stage_order?: Maybe<Attachment_Stage_Order>;
  /** An array relationship */
  thumbnails: Array<Attachment_Thumbnail>;
  /** An aggregate relationship */
  thumbnails_aggregate: Attachment_Thumbnail_Aggregate;
  type: Attachment_Type_Enum;
  updated_at: Scalars['timestamptz'];
  uploaded_by?: Maybe<Scalars['uuid']>;
  uploaded_by_team_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  uploaded_by_user?: Maybe<User>;
};
export type AttachmentArea_PlotsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type AttachmentArea_Plots_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type AttachmentAttachment_ConfigArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Config_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type AttachmentAttachment_Config_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Config_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type AttachmentAttachment_Product_TypesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Product_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type AttachmentAttachment_Product_Types_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Product_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type AttachmentFloor_PropertiesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type AttachmentFloor_Properties_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type AttachmentLinen_PropertiesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type AttachmentLinen_Properties_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type AttachmentLogo_OrgsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type AttachmentLogo_Orgs_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type AttachmentThumbnailsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

export type AttachmentThumbnails_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};


/** aggregated selection of "attachment" */
export type Attachment_Aggregate = {
__typename?: 'attachment_aggregate';

  aggregate?: Maybe<Attachment_Aggregate_Fields>;
  nodes: Array<Attachment>;
};

/** aggregate fields of "attachment" */
export type Attachment_Aggregate_Fields = {
__typename?: 'attachment_aggregate_fields';

  avg?: Maybe<Attachment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Max_Fields>;
  min?: Maybe<Attachment_Min_Fields>;
  stddev?: Maybe<Attachment_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Sum_Fields>;
  var_pop?: Maybe<Attachment_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Variance_Fields>;
};
export type Attachment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Attachment_Avg_Fields = {
__typename?: 'attachment_avg_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** store various thumbnail configuration */
export type Attachment_Config = {
__typename?: 'attachment_config';

  attachment_thumbnail_type: Attachment_Thumbnail_Type_Enum;
  attachment_type: Attachment_Type_Enum;
  created_at: Scalars['timestamptz'];
  fit?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  position?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  width?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "attachment_config" */
export type Attachment_Config_Aggregate = {
__typename?: 'attachment_config_aggregate';

  aggregate?: Maybe<Attachment_Config_Aggregate_Fields>;
  nodes: Array<Attachment_Config>;
};

/** aggregate fields of "attachment_config" */
export type Attachment_Config_Aggregate_Fields = {
__typename?: 'attachment_config_aggregate_fields';

  avg?: Maybe<Attachment_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Config_Max_Fields>;
  min?: Maybe<Attachment_Config_Min_Fields>;
  stddev?: Maybe<Attachment_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Config_Sum_Fields>;
  var_pop?: Maybe<Attachment_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Config_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Config_Variance_Fields>;
};
export type Attachment_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Attachment_Config_Avg_Fields = {
__typename?: 'attachment_config_avg_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Attachment_Config_Max_Fields = {
__typename?: 'attachment_config_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  fit?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Attachment_Config_Min_Fields = {
__typename?: 'attachment_config_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  fit?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "attachment_config" */
export type Attachment_Config_Mutation_Response = {
__typename?: 'attachment_config_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Config>;
};

/** aggregate stddev on columns */
export type Attachment_Config_Stddev_Fields = {
__typename?: 'attachment_config_stddev_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Config_Stddev_Pop_Fields = {
__typename?: 'attachment_config_stddev_pop_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Config_Stddev_Samp_Fields = {
__typename?: 'attachment_config_stddev_samp_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Config_Sum_Fields = {
__typename?: 'attachment_config_sum_fields';

  height?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Attachment_Config_Var_Pop_Fields = {
__typename?: 'attachment_config_var_pop_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Config_Var_Samp_Fields = {
__typename?: 'attachment_config_var_samp_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Config_Variance_Fields = {
__typename?: 'attachment_config_variance_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Define the order  of attachments on the project page */
export type Attachment_Edit_Project_Order = {
__typename?: 'attachment_edit_project_order';

  /** An object relationship */
  attachment: Attachment;
  attachment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Aggregate = {
__typename?: 'attachment_edit_project_order_aggregate';

  aggregate?: Maybe<Attachment_Edit_Project_Order_Aggregate_Fields>;
  nodes: Array<Attachment_Edit_Project_Order>;
};

/** aggregate fields of "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Aggregate_Fields = {
__typename?: 'attachment_edit_project_order_aggregate_fields';

  avg?: Maybe<Attachment_Edit_Project_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Edit_Project_Order_Max_Fields>;
  min?: Maybe<Attachment_Edit_Project_Order_Min_Fields>;
  stddev?: Maybe<Attachment_Edit_Project_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Edit_Project_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Edit_Project_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Edit_Project_Order_Sum_Fields>;
  var_pop?: Maybe<Attachment_Edit_Project_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Edit_Project_Order_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Edit_Project_Order_Variance_Fields>;
};
export type Attachment_Edit_Project_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Edit_Project_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Attachment_Edit_Project_Order_Avg_Fields = {
__typename?: 'attachment_edit_project_order_avg_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Attachment_Edit_Project_Order_Max_Fields = {
__typename?: 'attachment_edit_project_order_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Attachment_Edit_Project_Order_Min_Fields = {
__typename?: 'attachment_edit_project_order_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "attachment_edit_project_order" */
export type Attachment_Edit_Project_Order_Mutation_Response = {
__typename?: 'attachment_edit_project_order_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Edit_Project_Order>;
};

/** aggregate stddev on columns */
export type Attachment_Edit_Project_Order_Stddev_Fields = {
__typename?: 'attachment_edit_project_order_stddev_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Edit_Project_Order_Stddev_Pop_Fields = {
__typename?: 'attachment_edit_project_order_stddev_pop_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Edit_Project_Order_Stddev_Samp_Fields = {
__typename?: 'attachment_edit_project_order_stddev_samp_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Edit_Project_Order_Sum_Fields = {
__typename?: 'attachment_edit_project_order_sum_fields';

  order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Attachment_Edit_Project_Order_Var_Pop_Fields = {
__typename?: 'attachment_edit_project_order_var_pop_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Edit_Project_Order_Var_Samp_Fields = {
__typename?: 'attachment_edit_project_order_var_samp_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Edit_Project_Order_Variance_Fields = {
__typename?: 'attachment_edit_project_order_variance_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Attachment_Max_Fields = {
__typename?: 'attachment_max_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  download_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
  uploaded_by_team_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Attachment_Min_Fields = {
__typename?: 'attachment_min_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  download_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
  uploaded_by_team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "attachment" */
export type Attachment_Mutation_Response = {
__typename?: 'attachment_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment>;
};

/** values which can be used in attachment.owner_type */
export type Attachment_Owner = {
__typename?: 'attachment_owner';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "attachment_owner" */
export type Attachment_Owner_Aggregate = {
__typename?: 'attachment_owner_aggregate';

  aggregate?: Maybe<Attachment_Owner_Aggregate_Fields>;
  nodes: Array<Attachment_Owner>;
};

/** aggregate fields of "attachment_owner" */
export type Attachment_Owner_Aggregate_Fields = {
__typename?: 'attachment_owner_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Attachment_Owner_Max_Fields>;
  min?: Maybe<Attachment_Owner_Min_Fields>;
};
export type Attachment_Owner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Attachment_Owner_Max_Fields = {
__typename?: 'attachment_owner_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Attachment_Owner_Min_Fields = {
__typename?: 'attachment_owner_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "attachment_owner" */
export type Attachment_Owner_Mutation_Response = {
__typename?: 'attachment_owner_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Owner>;
};

/** columns and relationships of "attachment_product_type" */
export type Attachment_Product_Type = {
__typename?: 'attachment_product_type';

  /** An object relationship */
  attachment: Attachment;
  attachment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  product_type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "attachment_product_type" */
export type Attachment_Product_Type_Aggregate = {
__typename?: 'attachment_product_type_aggregate';

  aggregate?: Maybe<Attachment_Product_Type_Aggregate_Fields>;
  nodes: Array<Attachment_Product_Type>;
};

/** aggregate fields of "attachment_product_type" */
export type Attachment_Product_Type_Aggregate_Fields = {
__typename?: 'attachment_product_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Attachment_Product_Type_Max_Fields>;
  min?: Maybe<Attachment_Product_Type_Min_Fields>;
};
export type Attachment_Product_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Attachment_Product_Type_Max_Fields = {
__typename?: 'attachment_product_type_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Attachment_Product_Type_Min_Fields = {
__typename?: 'attachment_product_type_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  product_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "attachment_product_type" */
export type Attachment_Product_Type_Mutation_Response = {
__typename?: 'attachment_product_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Product_Type>;
};

/** Define the order of attachments on the stage page */
export type Attachment_Stage_Order = {
__typename?: 'attachment_stage_order';

  /** An object relationship */
  attachment: Attachment;
  attachment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "attachment_stage_order" */
export type Attachment_Stage_Order_Aggregate = {
__typename?: 'attachment_stage_order_aggregate';

  aggregate?: Maybe<Attachment_Stage_Order_Aggregate_Fields>;
  nodes: Array<Attachment_Stage_Order>;
};

/** aggregate fields of "attachment_stage_order" */
export type Attachment_Stage_Order_Aggregate_Fields = {
__typename?: 'attachment_stage_order_aggregate_fields';

  avg?: Maybe<Attachment_Stage_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Stage_Order_Max_Fields>;
  min?: Maybe<Attachment_Stage_Order_Min_Fields>;
  stddev?: Maybe<Attachment_Stage_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Stage_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Stage_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Stage_Order_Sum_Fields>;
  var_pop?: Maybe<Attachment_Stage_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Stage_Order_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Stage_Order_Variance_Fields>;
};
export type Attachment_Stage_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Stage_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Attachment_Stage_Order_Avg_Fields = {
__typename?: 'attachment_stage_order_avg_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Attachment_Stage_Order_Max_Fields = {
__typename?: 'attachment_stage_order_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Attachment_Stage_Order_Min_Fields = {
__typename?: 'attachment_stage_order_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "attachment_stage_order" */
export type Attachment_Stage_Order_Mutation_Response = {
__typename?: 'attachment_stage_order_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Stage_Order>;
};

/** aggregate stddev on columns */
export type Attachment_Stage_Order_Stddev_Fields = {
__typename?: 'attachment_stage_order_stddev_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Stage_Order_Stddev_Pop_Fields = {
__typename?: 'attachment_stage_order_stddev_pop_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Stage_Order_Stddev_Samp_Fields = {
__typename?: 'attachment_stage_order_stddev_samp_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Stage_Order_Sum_Fields = {
__typename?: 'attachment_stage_order_sum_fields';

  order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Attachment_Stage_Order_Var_Pop_Fields = {
__typename?: 'attachment_stage_order_var_pop_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Stage_Order_Var_Samp_Fields = {
__typename?: 'attachment_stage_order_var_samp_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Stage_Order_Variance_Fields = {
__typename?: 'attachment_stage_order_variance_fields';

  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Attachment_Stddev_Fields = {
__typename?: 'attachment_stddev_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Stddev_Pop_Fields = {
__typename?: 'attachment_stddev_pop_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Stddev_Samp_Fields = {
__typename?: 'attachment_stddev_samp_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Sum_Fields = {
__typename?: 'attachment_sum_fields';

  sf_down_sync_time?: Maybe<Scalars['bigint']>;
};

/** store all thumbnail of the attachment */
export type Attachment_Thumbnail = {
__typename?: 'attachment_thumbnail';

  /** An object relationship */
  attachment: Attachment;
  attachment_id: Scalars['uuid'];
  attachment_thumbnail_type: Attachment_Thumbnail_Type_Enum;
  content_type: Scalars['String'];
  created_at: Scalars['timestamptz'];
  fit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  path: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  thumbnail_url: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  width?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "attachment_thumbnail" */
export type Attachment_Thumbnail_Aggregate = {
__typename?: 'attachment_thumbnail_aggregate';

  aggregate?: Maybe<Attachment_Thumbnail_Aggregate_Fields>;
  nodes: Array<Attachment_Thumbnail>;
};

/** aggregate fields of "attachment_thumbnail" */
export type Attachment_Thumbnail_Aggregate_Fields = {
__typename?: 'attachment_thumbnail_aggregate_fields';

  avg?: Maybe<Attachment_Thumbnail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Thumbnail_Max_Fields>;
  min?: Maybe<Attachment_Thumbnail_Min_Fields>;
  stddev?: Maybe<Attachment_Thumbnail_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Thumbnail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Thumbnail_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Thumbnail_Sum_Fields>;
  var_pop?: Maybe<Attachment_Thumbnail_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Thumbnail_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Thumbnail_Variance_Fields>;
};
export type Attachment_Thumbnail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Attachment_Thumbnail_Avg_Fields = {
__typename?: 'attachment_thumbnail_avg_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "attachment_thumbnail_count" */
export type Attachment_Thumbnail_Count = {
__typename?: 'attachment_thumbnail_count';

  id?: Maybe<Scalars['uuid']>;
  thumbnail_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "attachment_thumbnail_count" */
export type Attachment_Thumbnail_Count_Aggregate = {
__typename?: 'attachment_thumbnail_count_aggregate';

  aggregate?: Maybe<Attachment_Thumbnail_Count_Aggregate_Fields>;
  nodes: Array<Attachment_Thumbnail_Count>;
};

/** aggregate fields of "attachment_thumbnail_count" */
export type Attachment_Thumbnail_Count_Aggregate_Fields = {
__typename?: 'attachment_thumbnail_count_aggregate_fields';

  avg?: Maybe<Attachment_Thumbnail_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Thumbnail_Count_Max_Fields>;
  min?: Maybe<Attachment_Thumbnail_Count_Min_Fields>;
  stddev?: Maybe<Attachment_Thumbnail_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Thumbnail_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Thumbnail_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Thumbnail_Count_Sum_Fields>;
  var_pop?: Maybe<Attachment_Thumbnail_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Thumbnail_Count_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Thumbnail_Count_Variance_Fields>;
};
export type Attachment_Thumbnail_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Thumbnail_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Attachment_Thumbnail_Count_Avg_Fields = {
__typename?: 'attachment_thumbnail_count_avg_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Attachment_Thumbnail_Count_Max_Fields = {
__typename?: 'attachment_thumbnail_count_max_fields';

  id?: Maybe<Scalars['uuid']>;
  thumbnail_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Attachment_Thumbnail_Count_Min_Fields = {
__typename?: 'attachment_thumbnail_count_min_fields';

  id?: Maybe<Scalars['uuid']>;
  thumbnail_count?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Attachment_Thumbnail_Count_Stddev_Fields = {
__typename?: 'attachment_thumbnail_count_stddev_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Thumbnail_Count_Stddev_Pop_Fields = {
__typename?: 'attachment_thumbnail_count_stddev_pop_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Thumbnail_Count_Stddev_Samp_Fields = {
__typename?: 'attachment_thumbnail_count_stddev_samp_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Thumbnail_Count_Sum_Fields = {
__typename?: 'attachment_thumbnail_count_sum_fields';

  thumbnail_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Attachment_Thumbnail_Count_Var_Pop_Fields = {
__typename?: 'attachment_thumbnail_count_var_pop_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Thumbnail_Count_Var_Samp_Fields = {
__typename?: 'attachment_thumbnail_count_var_samp_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Thumbnail_Count_Variance_Fields = {
__typename?: 'attachment_thumbnail_count_variance_fields';

  thumbnail_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Attachment_Thumbnail_Max_Fields = {
__typename?: 'attachment_thumbnail_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Attachment_Thumbnail_Min_Fields = {
__typename?: 'attachment_thumbnail_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "attachment_thumbnail" */
export type Attachment_Thumbnail_Mutation_Response = {
__typename?: 'attachment_thumbnail_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Thumbnail>;
};

/** aggregate stddev on columns */
export type Attachment_Thumbnail_Stddev_Fields = {
__typename?: 'attachment_thumbnail_stddev_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Thumbnail_Stddev_Pop_Fields = {
__typename?: 'attachment_thumbnail_stddev_pop_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Thumbnail_Stddev_Samp_Fields = {
__typename?: 'attachment_thumbnail_stddev_samp_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Attachment_Thumbnail_Sum_Fields = {
__typename?: 'attachment_thumbnail_sum_fields';

  height?: Maybe<Scalars['numeric']>;
  width?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type = {
__typename?: 'attachment_thumbnail_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_Aggregate = {
__typename?: 'attachment_thumbnail_type_aggregate';

  aggregate?: Maybe<Attachment_Thumbnail_Type_Aggregate_Fields>;
  nodes: Array<Attachment_Thumbnail_Type>;
};

/** aggregate fields of "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_Aggregate_Fields = {
__typename?: 'attachment_thumbnail_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Attachment_Thumbnail_Type_Max_Fields>;
  min?: Maybe<Attachment_Thumbnail_Type_Min_Fields>;
};
export type Attachment_Thumbnail_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Thumbnail_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Attachment_Thumbnail_Type_Max_Fields = {
__typename?: 'attachment_thumbnail_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Attachment_Thumbnail_Type_Min_Fields = {
__typename?: 'attachment_thumbnail_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "attachment_thumbnail_type" */
export type Attachment_Thumbnail_Type_Mutation_Response = {
__typename?: 'attachment_thumbnail_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Thumbnail_Type>;
};

/** aggregate var_pop on columns */
export type Attachment_Thumbnail_Var_Pop_Fields = {
__typename?: 'attachment_thumbnail_var_pop_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Thumbnail_Var_Samp_Fields = {
__typename?: 'attachment_thumbnail_var_samp_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Thumbnail_Variance_Fields = {
__typename?: 'attachment_thumbnail_variance_fields';

  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "attachment_type" */
export type Attachment_Type = {
__typename?: 'attachment_type';

  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Attachment_TypeAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Attachment_TypeAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};


/** aggregated selection of "attachment_type" */
export type Attachment_Type_Aggregate = {
__typename?: 'attachment_type_aggregate';

  aggregate?: Maybe<Attachment_Type_Aggregate_Fields>;
  nodes: Array<Attachment_Type>;
};

/** aggregate fields of "attachment_type" */
export type Attachment_Type_Aggregate_Fields = {
__typename?: 'attachment_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Attachment_Type_Max_Fields>;
  min?: Maybe<Attachment_Type_Min_Fields>;
};
export type Attachment_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Attachment_Type_Max_Fields = {
__typename?: 'attachment_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Attachment_Type_Min_Fields = {
__typename?: 'attachment_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "attachment_type" */
export type Attachment_Type_Mutation_Response = {
__typename?: 'attachment_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Type>;
};

/** aggregate var_pop on columns */
export type Attachment_Var_Pop_Fields = {
__typename?: 'attachment_var_pop_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Attachment_Var_Samp_Fields = {
__typename?: 'attachment_var_samp_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Attachment_Variance_Fields = {
__typename?: 'attachment_variance_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** State of Keys Steps nav in buyer portal */
export type Buyer_Portal = {
__typename?: 'buyer_portal';

  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** An array relationship */
  acl_deal_user_team: Array<Acl_Deal_User_Team>;
  /** An aggregate relationship */
  acl_deal_user_team_aggregate: Acl_Deal_User_Team_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['uuid'];
  sign_up_config: Scalars['jsonb'];
  step_config?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this field not in use any more */
  user_id?: Maybe<Scalars['uuid']>;
};
export type Buyer_PortalAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Buyer_PortalAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Buyer_PortalAcl_Deal_User_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Buyer_PortalAcl_Deal_User_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Buyer_PortalSign_Up_ConfigArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type Buyer_PortalStep_ConfigArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "buyer_portal" */
export type Buyer_Portal_Aggregate = {
__typename?: 'buyer_portal_aggregate';

  aggregate?: Maybe<Buyer_Portal_Aggregate_Fields>;
  nodes: Array<Buyer_Portal>;
};

/** aggregate fields of "buyer_portal" */
export type Buyer_Portal_Aggregate_Fields = {
__typename?: 'buyer_portal_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Buyer_Portal_Max_Fields>;
  min?: Maybe<Buyer_Portal_Min_Fields>;
};
export type Buyer_Portal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buyer_Portal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Buyer_Portal_Max_Fields = {
__typename?: 'buyer_portal_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this field not in use any more */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Buyer_Portal_Min_Fields = {
__typename?: 'buyer_portal_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this field not in use any more */
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "buyer_portal" */
export type Buyer_Portal_Mutation_Response = {
__typename?: 'buyer_portal_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Buyer_Portal>;
};

/** Projects which can be accessed by the buyer, and agent who invited buyer */
export type Buyer_Project = {
__typename?: 'buyer_project';

  /** An object relationship */
  agent_team: Organisation;
  agent_team_id: Scalars['uuid'];
  /** An object relationship */
  agent_user: User;
  agent_user_id: Scalars['uuid'];
  buyer_id: Scalars['uuid'];
  /** An object relationship */
  buyer_user: User;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "buyer_project" */
export type Buyer_Project_Aggregate = {
__typename?: 'buyer_project_aggregate';

  aggregate?: Maybe<Buyer_Project_Aggregate_Fields>;
  nodes: Array<Buyer_Project>;
};

/** aggregate fields of "buyer_project" */
export type Buyer_Project_Aggregate_Fields = {
__typename?: 'buyer_project_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Buyer_Project_Max_Fields>;
  min?: Maybe<Buyer_Project_Min_Fields>;
};
export type Buyer_Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Buyer_Project_Max_Fields = {
__typename?: 'buyer_project_max_fields';

  agent_team_id?: Maybe<Scalars['uuid']>;
  agent_user_id?: Maybe<Scalars['uuid']>;
  buyer_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Buyer_Project_Min_Fields = {
__typename?: 'buyer_project_min_fields';

  agent_team_id?: Maybe<Scalars['uuid']>;
  agent_user_id?: Maybe<Scalars['uuid']>;
  buyer_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "buyer_project" */
export type Buyer_Project_Mutation_Response = {
__typename?: 'buyer_project_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Buyer_Project>;
};

/** Type of the buyer who is going to make a purchase */
export type Buyer_Type = {
__typename?: 'buyer_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "buyer_type" */
export type Buyer_Type_Aggregate = {
__typename?: 'buyer_type_aggregate';

  aggregate?: Maybe<Buyer_Type_Aggregate_Fields>;
  nodes: Array<Buyer_Type>;
};

/** aggregate fields of "buyer_type" */
export type Buyer_Type_Aggregate_Fields = {
__typename?: 'buyer_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Buyer_Type_Max_Fields>;
  min?: Maybe<Buyer_Type_Min_Fields>;
};
export type Buyer_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buyer_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Buyer_Type_Max_Fields = {
__typename?: 'buyer_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Buyer_Type_Min_Fields = {
__typename?: 'buyer_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "buyer_type" */
export type Buyer_Type_Mutation_Response = {
__typename?: 'buyer_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Buyer_Type>;
};

/** Cache certain data in db with expiration unix time */
export type Cache = {
__typename?: 'cache';

  created_at: Scalars['timestamptz'];
  expires_at: Scalars['bigint'];
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['jsonb'];
};
export type CacheValueArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "cache" */
export type Cache_Aggregate = {
__typename?: 'cache_aggregate';

  aggregate?: Maybe<Cache_Aggregate_Fields>;
  nodes: Array<Cache>;
};

/** aggregate fields of "cache" */
export type Cache_Aggregate_Fields = {
__typename?: 'cache_aggregate_fields';

  avg?: Maybe<Cache_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Cache_Max_Fields>;
  min?: Maybe<Cache_Min_Fields>;
  stddev?: Maybe<Cache_Stddev_Fields>;
  stddev_pop?: Maybe<Cache_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cache_Stddev_Samp_Fields>;
  sum?: Maybe<Cache_Sum_Fields>;
  var_pop?: Maybe<Cache_Var_Pop_Fields>;
  var_samp?: Maybe<Cache_Var_Samp_Fields>;
  variance?: Maybe<Cache_Variance_Fields>;
};
export type Cache_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cache_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Cache_Avg_Fields = {
__typename?: 'cache_avg_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Cache_Max_Fields = {
__typename?: 'cache_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Cache_Min_Fields = {
__typename?: 'cache_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "cache" */
export type Cache_Mutation_Response = {
__typename?: 'cache_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cache>;
};

/** aggregate stddev on columns */
export type Cache_Stddev_Fields = {
__typename?: 'cache_stddev_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Cache_Stddev_Pop_Fields = {
__typename?: 'cache_stddev_pop_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Cache_Stddev_Samp_Fields = {
__typename?: 'cache_stddev_samp_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Cache_Sum_Fields = {
__typename?: 'cache_sum_fields';

  expires_at?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Cache_Var_Pop_Fields = {
__typename?: 'cache_var_pop_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Cache_Var_Samp_Fields = {
__typename?: 'cache_var_samp_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Cache_Variance_Fields = {
__typename?: 'cache_variance_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** History of incoming sync events from Salesforce */
export type Change_In = {
__typename?: 'change_in';

  action?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  depends_on_sf_id?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** We don't want to do some additional processing in some cases when record came from bulk-import. When we get paid transaction via bulk import we don't want to trigger Enquiry Update in Salesforce (one example) */
  is_bulk_import: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  payload_timestamp?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id: Scalars['String'];
  should_be_deleted: Scalars['Boolean'];
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Change_Status_Enum>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "change_in" */
export type Change_In_Aggregate = {
__typename?: 'change_in_aggregate';

  aggregate?: Maybe<Change_In_Aggregate_Fields>;
  nodes: Array<Change_In>;
};

/** aggregate fields of "change_in" */
export type Change_In_Aggregate_Fields = {
__typename?: 'change_in_aggregate_fields';

  avg?: Maybe<Change_In_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Change_In_Max_Fields>;
  min?: Maybe<Change_In_Min_Fields>;
  stddev?: Maybe<Change_In_Stddev_Fields>;
  stddev_pop?: Maybe<Change_In_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Change_In_Stddev_Samp_Fields>;
  sum?: Maybe<Change_In_Sum_Fields>;
  var_pop?: Maybe<Change_In_Var_Pop_Fields>;
  var_samp?: Maybe<Change_In_Var_Samp_Fields>;
  variance?: Maybe<Change_In_Variance_Fields>;
};
export type Change_In_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Change_In_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Change_In_Avg_Fields = {
__typename?: 'change_in_avg_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Change_In_Max_Fields = {
__typename?: 'change_in_max_fields';

  action?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  depends_on_sf_id?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  payload_timestamp?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Change_In_Min_Fields = {
__typename?: 'change_in_min_fields';

  action?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  depends_on_sf_id?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  payload_timestamp?: Maybe<Scalars['bigint']>;
  record_type?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "change_in" */
export type Change_In_Mutation_Response = {
__typename?: 'change_in_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Change_In>;
};

/** aggregate stddev on columns */
export type Change_In_Stddev_Fields = {
__typename?: 'change_in_stddev_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Change_In_Stddev_Pop_Fields = {
__typename?: 'change_in_stddev_pop_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Change_In_Stddev_Samp_Fields = {
__typename?: 'change_in_stddev_samp_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Change_In_Sum_Fields = {
__typename?: 'change_in_sum_fields';

  payload_timestamp?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Change_In_Var_Pop_Fields = {
__typename?: 'change_in_var_pop_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Change_In_Var_Samp_Fields = {
__typename?: 'change_in_var_samp_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Change_In_Variance_Fields = {
__typename?: 'change_in_variance_fields';

  payload_timestamp?: Maybe<Scalars['Float']>;
};

/** Status values for change.status */
export type Change_Status = {
__typename?: 'change_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "change_status" */
export type Change_Status_Aggregate = {
__typename?: 'change_status_aggregate';

  aggregate?: Maybe<Change_Status_Aggregate_Fields>;
  nodes: Array<Change_Status>;
};

/** aggregate fields of "change_status" */
export type Change_Status_Aggregate_Fields = {
__typename?: 'change_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Change_Status_Max_Fields>;
  min?: Maybe<Change_Status_Min_Fields>;
};
export type Change_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Change_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Change_Status_Max_Fields = {
__typename?: 'change_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Change_Status_Min_Fields = {
__typename?: 'change_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "change_status" */
export type Change_Status_Mutation_Response = {
__typename?: 'change_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Change_Status>;
};

/** columns and relationships of "code" */
export type Code = {
__typename?: 'code';

  code: Scalars['String'];
  /** An object relationship */
  code_user?: Maybe<User>;
  created_at: Scalars['timestamptz'];
  created_by_user_id?: Maybe<Scalars['uuid']>;
  expires_at: Scalars['bigint'];
  id: Scalars['uuid'];
  is_verified: Scalars['Boolean'];
  key: Scalars['String'];
  request: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};
export type CodeRequestArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "code" */
export type Code_Aggregate = {
__typename?: 'code_aggregate';

  aggregate?: Maybe<Code_Aggregate_Fields>;
  nodes: Array<Code>;
};

/** aggregate fields of "code" */
export type Code_Aggregate_Fields = {
__typename?: 'code_aggregate_fields';

  avg?: Maybe<Code_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Code_Max_Fields>;
  min?: Maybe<Code_Min_Fields>;
  stddev?: Maybe<Code_Stddev_Fields>;
  stddev_pop?: Maybe<Code_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Code_Stddev_Samp_Fields>;
  sum?: Maybe<Code_Sum_Fields>;
  var_pop?: Maybe<Code_Var_Pop_Fields>;
  var_samp?: Maybe<Code_Var_Samp_Fields>;
  variance?: Maybe<Code_Variance_Fields>;
};
export type Code_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Code_Avg_Fields = {
__typename?: 'code_avg_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Code_Max_Fields = {
__typename?: 'code_max_fields';

  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  expires_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Code_Min_Fields = {
__typename?: 'code_min_fields';

  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  expires_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "code" */
export type Code_Mutation_Response = {
__typename?: 'code_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Code>;
};

/** aggregate stddev on columns */
export type Code_Stddev_Fields = {
__typename?: 'code_stddev_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Code_Stddev_Pop_Fields = {
__typename?: 'code_stddev_pop_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Code_Stddev_Samp_Fields = {
__typename?: 'code_stddev_samp_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Code_Sum_Fields = {
__typename?: 'code_sum_fields';

  expires_at?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Code_Var_Pop_Fields = {
__typename?: 'code_var_pop_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Code_Var_Samp_Fields = {
__typename?: 'code_var_samp_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Code_Variance_Fields = {
__typename?: 'code_variance_fields';

  expires_at?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "commission" */
export type Commission = {
__typename?: 'commission';

  /** An object relationship */
  agency_agreement?: Maybe<Agreement>;
  agency_agreement_id?: Maybe<Scalars['uuid']>;
  agent_amount?: Maybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_exchange_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  agent_incentive_amount?: Maybe<Scalars['numeric']>;
  agent_incentive_payable?: Maybe<Scalars['numeric']>;
  agent_incentive_rate?: Maybe<Scalars['numeric']>;
  agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  agent_payment_notes?: Maybe<Scalars['String']>;
  agent_rate?: Maybe<Scalars['numeric']>;
  agent_settlement_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['uuid']>;
  exchange_invoice_agent?: Maybe<Scalars['String']>;
  exchange_invoice_sub_agent?: Maybe<Scalars['String']>;
  external_commission_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  organisation_id?: Maybe<Scalars['uuid']>;
  property_name?: Maybe<Scalars['String']>;
  settlement_invoice_agent?: Maybe<Scalars['String']>;
  settlement_invoice_sub_agent?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  sub_agency_agreement?: Maybe<Agreement>;
  sub_agency_agreement_id?: Maybe<Scalars['uuid']>;
  sub_agent_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: Maybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  sub_agent_payment_notes?: Maybe<Scalars['String']>;
  sub_agent_rate?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "commission" */
export type Commission_Aggregate = {
__typename?: 'commission_aggregate';

  aggregate?: Maybe<Commission_Aggregate_Fields>;
  nodes: Array<Commission>;
};

/** aggregate fields of "commission" */
export type Commission_Aggregate_Fields = {
__typename?: 'commission_aggregate_fields';

  avg?: Maybe<Commission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Max_Fields>;
  min?: Maybe<Commission_Min_Fields>;
  stddev?: Maybe<Commission_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Sum_Fields>;
  var_pop?: Maybe<Commission_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Var_Samp_Fields>;
  variance?: Maybe<Commission_Variance_Fields>;
};
export type Commission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Commission_Avg_Fields = {
__typename?: 'commission_avg_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Commission_Max_Fields = {
__typename?: 'commission_max_fields';

  agency_agreement_id?: Maybe<Scalars['uuid']>;
  agent_amount?: Maybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_exchange_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  agent_incentive_amount?: Maybe<Scalars['numeric']>;
  agent_incentive_payable?: Maybe<Scalars['numeric']>;
  agent_incentive_rate?: Maybe<Scalars['numeric']>;
  agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  agent_payment_notes?: Maybe<Scalars['String']>;
  agent_rate?: Maybe<Scalars['numeric']>;
  agent_settlement_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  exchange_invoice_agent?: Maybe<Scalars['String']>;
  exchange_invoice_sub_agent?: Maybe<Scalars['String']>;
  external_commission_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  property_name?: Maybe<Scalars['String']>;
  settlement_invoice_agent?: Maybe<Scalars['String']>;
  settlement_invoice_sub_agent?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  sub_agency_agreement_id?: Maybe<Scalars['uuid']>;
  sub_agent_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: Maybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  sub_agent_payment_notes?: Maybe<Scalars['String']>;
  sub_agent_rate?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Commission_Min_Fields = {
__typename?: 'commission_min_fields';

  agency_agreement_id?: Maybe<Scalars['uuid']>;
  agent_amount?: Maybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_exchange_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  agent_incentive_amount?: Maybe<Scalars['numeric']>;
  agent_incentive_payable?: Maybe<Scalars['numeric']>;
  agent_incentive_rate?: Maybe<Scalars['numeric']>;
  agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  agent_payment_notes?: Maybe<Scalars['String']>;
  agent_rate?: Maybe<Scalars['numeric']>;
  agent_settlement_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  exchange_invoice_agent?: Maybe<Scalars['String']>;
  exchange_invoice_sub_agent?: Maybe<Scalars['String']>;
  external_commission_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  property_name?: Maybe<Scalars['String']>;
  settlement_invoice_agent?: Maybe<Scalars['String']>;
  settlement_invoice_sub_agent?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  sub_agency_agreement_id?: Maybe<Scalars['uuid']>;
  sub_agent_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: Maybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  sub_agent_payment_notes?: Maybe<Scalars['String']>;
  sub_agent_rate?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_invoice_paid_date?: Maybe<Scalars['timestamptz']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "commission" */
export type Commission_Mutation_Response = {
__typename?: 'commission_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission>;
};

/** aggregate stddev on columns */
export type Commission_Stddev_Fields = {
__typename?: 'commission_stddev_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Stddev_Pop_Fields = {
__typename?: 'commission_stddev_pop_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Stddev_Samp_Fields = {
__typename?: 'commission_stddev_samp_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Commission_Sum_Fields = {
__typename?: 'commission_sum_fields';

  agent_amount?: Maybe<Scalars['numeric']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['numeric']>;
  agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  agent_incentive_amount?: Maybe<Scalars['numeric']>;
  agent_incentive_payable?: Maybe<Scalars['numeric']>;
  agent_incentive_rate?: Maybe<Scalars['numeric']>;
  agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  agent_rate?: Maybe<Scalars['numeric']>;
  agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
  sub_agent_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_amount?: Maybe<Scalars['numeric']>;
  sub_agent_incentive_rate?: Maybe<Scalars['numeric']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['numeric']>;
  sub_agent_rate?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['numeric']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "commission_type" */
export type Commission_Type = {
__typename?: 'commission_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "commission_type" */
export type Commission_Type_Aggregate = {
__typename?: 'commission_type_aggregate';

  aggregate?: Maybe<Commission_Type_Aggregate_Fields>;
  nodes: Array<Commission_Type>;
};

/** aggregate fields of "commission_type" */
export type Commission_Type_Aggregate_Fields = {
__typename?: 'commission_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Commission_Type_Max_Fields>;
  min?: Maybe<Commission_Type_Min_Fields>;
};
export type Commission_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Commission_Type_Max_Fields = {
__typename?: 'commission_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Commission_Type_Min_Fields = {
__typename?: 'commission_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "commission_type" */
export type Commission_Type_Mutation_Response = {
__typename?: 'commission_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Type>;
};

/** aggregate var_pop on columns */
export type Commission_Var_Pop_Fields = {
__typename?: 'commission_var_pop_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Var_Samp_Fields = {
__typename?: 'commission_var_samp_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Variance_Fields = {
__typename?: 'commission_variance_fields';

  agent_amount?: Maybe<Scalars['Float']>;
  agent_commission_rate_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_commission_value_less_sub_agent?: Maybe<Scalars['Float']>;
  agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  agent_incentive_amount?: Maybe<Scalars['Float']>;
  agent_incentive_payable?: Maybe<Scalars['Float']>;
  agent_incentive_rate?: Maybe<Scalars['Float']>;
  agent_outstanding_balance?: Maybe<Scalars['Float']>;
  agent_rate?: Maybe<Scalars['Float']>;
  agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_exchange_payable_percentage?: Maybe<Scalars['Float']>;
  sub_agent_incentive_amount?: Maybe<Scalars['Float']>;
  sub_agent_incentive_rate?: Maybe<Scalars['Float']>;
  sub_agent_outstanding_balance?: Maybe<Scalars['Float']>;
  sub_agent_rate?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_amount?: Maybe<Scalars['Float']>;
  sub_agent_settlement_payable_percentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "contact" */
export type Contact = {
__typename?: 'contact';

  /** An array relationship */
  acl_contact_agent_member: Array<Acl_Contact_Agent_Member>;
  /** An aggregate relationship */
  acl_contact_agent_member_aggregate: Acl_Contact_Agent_Member_Aggregate;
  /** An array relationship */
  acl_contact_developer_member: Array<Acl_Contact_Developer_Member>;
  /** An aggregate relationship */
  acl_contact_developer_member_aggregate: Acl_Contact_Developer_Member_Aggregate;
  /** An array relationship */
  acl_contact_master_agent: Array<Acl_Contact_Master_Agent>;
  /** An aggregate relationship */
  acl_contact_master_agent_aggregate: Acl_Contact_Master_Agent_Aggregate;
  address_city_suburb?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  /** An object relationship */
  contact_type?: Maybe<Contact_Type>;
  country?: Maybe<Scalars['String']>;
  /** An object relationship */
  countryByCountry?: Maybe<Country>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  created_by_contact?: Maybe<Contact>;
  created_by_contact_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_team?: Maybe<Organisation>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user?: Maybe<User>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  deal_contacts: Array<Deal_Contact>;
  /** An aggregate relationship */
  deal_contacts_aggregate: Deal_Contact_Aggregate;
  /** An array relationship */
  dealsByAgencyContactId: Array<Deal>;
  /** An aggregate relationship */
  dealsByAgencyContactId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsByLeadId: Array<Deal>;
  /** An aggregate relationship */
  dealsByLeadId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsByPurchaserSolicitorContactId: Array<Deal>;
  /** An aggregate relationship */
  dealsByPurchaserSolicitorContactId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsBySubAgencyContactId: Array<Deal>;
  /** An aggregate relationship */
  dealsBySubAgencyContactId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsByVendorSolicitorContactId: Array<Deal>;
  /** An aggregate relationship */
  dealsByVendorSolicitorContactId_aggregate: Deal_Aggregate;
  email: Scalars['String'];
  fax_number?: Maybe<Scalars['String']>;
  firb_purchaser?: Maybe<Scalars['Boolean']>;
  first_name: Scalars['String'];
  /** A computed field, executes function "contact_address" */
  full_address?: Maybe<Scalars['String']>;
  /** A computed field, executes function "contact_full_name" */
  full_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  hubspot: Array<Hubspot_Contact>;
  /** An aggregate relationship */
  hubspot_aggregate: Hubspot_Contact_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  idProofs: Array<Document>;
  /** An aggregate relationship */
  idProofs_aggregate: Document_Aggregate;
  is_primary: Scalars['Boolean'];
  last_name: Scalars['String'];
  /** An array relationship */
  manyDealsByLeadId: Array<Deal_Contact>;
  /** An aggregate relationship */
  manyDealsByLeadId_aggregate: Deal_Contact_Aggregate;
  middle_name?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  /** An object relationship */
  nationalityByNationality?: Maybe<Nationality>;
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  organisation_id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  salesforce: Array<Sf_Contact>;
  /** An aggregate relationship */
  salesforce_aggregate: Sf_Contact_Aggregate;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  tax_file_number?: Maybe<Scalars['String']>;
  type?: Maybe<Contact_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};
export type ContactAcl_Contact_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type ContactAcl_Contact_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type ContactAcl_Contact_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type ContactAcl_Contact_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type ContactAcl_Contact_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type ContactAcl_Contact_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type ContactAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type ContactAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type ContactDeal_ContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type ContactDeal_Contacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type ContactDealsByAgencyContactIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByAgencyContactId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByLeadIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByLeadId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByPurchaserSolicitorContactIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByPurchaserSolicitorContactId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsBySubAgencyContactIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsBySubAgencyContactId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByVendorSolicitorContactIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactDealsByVendorSolicitorContactId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ContactHubspotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type ContactHubspot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type ContactIdProofsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type ContactIdProofs_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type ContactManyDealsByLeadIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type ContactManyDealsByLeadId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type ContactSalesforceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

export type ContactSalesforce_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};


/** aggregated selection of "contact" */
export type Contact_Aggregate = {
__typename?: 'contact_aggregate';

  aggregate?: Maybe<Contact_Aggregate_Fields>;
  nodes: Array<Contact>;
};

/** aggregate fields of "contact" */
export type Contact_Aggregate_Fields = {
__typename?: 'contact_aggregate_fields';

  avg?: Maybe<Contact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Max_Fields>;
  min?: Maybe<Contact_Min_Fields>;
  stddev?: Maybe<Contact_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Sum_Fields>;
  var_pop?: Maybe<Contact_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Var_Samp_Fields>;
  variance?: Maybe<Contact_Variance_Fields>;
};
export type Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Contact_Avg_Fields = {
__typename?: 'contact_avg_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Contact_Max_Fields = {
__typename?: 'contact_max_fields';

  address_city_suburb?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_contact_id?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  fax_number?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "contact_address" */
  full_address?: Maybe<Scalars['String']>;
  /** A computed field, executes function "contact_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  tax_file_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Contact_Min_Fields = {
__typename?: 'contact_min_fields';

  address_city_suburb?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_contact_id?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  fax_number?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "contact_address" */
  full_address?: Maybe<Scalars['String']>;
  /** A computed field, executes function "contact_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  tax_file_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "contact" */
export type Contact_Mutation_Response = {
__typename?: 'contact_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact>;
};

/** aggregate stddev on columns */
export type Contact_Stddev_Fields = {
__typename?: 'contact_stddev_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Stddev_Pop_Fields = {
__typename?: 'contact_stddev_pop_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Stddev_Samp_Fields = {
__typename?: 'contact_stddev_samp_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Contact_Sum_Fields = {
__typename?: 'contact_sum_fields';

  sf_down_sync_time?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "contact_type" */
export type Contact_Type = {
__typename?: 'contact_type';

  /** An array relationship */
  contacts: Array<Contact>;
  /** An aggregate relationship */
  contacts_aggregate: Contact_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Contact_TypeContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Contact_TypeContacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};


/** aggregated selection of "contact_type" */
export type Contact_Type_Aggregate = {
__typename?: 'contact_type_aggregate';

  aggregate?: Maybe<Contact_Type_Aggregate_Fields>;
  nodes: Array<Contact_Type>;
};

/** aggregate fields of "contact_type" */
export type Contact_Type_Aggregate_Fields = {
__typename?: 'contact_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Contact_Type_Max_Fields>;
  min?: Maybe<Contact_Type_Min_Fields>;
};
export type Contact_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Contact_Type_Max_Fields = {
__typename?: 'contact_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Contact_Type_Min_Fields = {
__typename?: 'contact_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contact_type" */
export type Contact_Type_Mutation_Response = {
__typename?: 'contact_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Type>;
};

/** aggregate var_pop on columns */
export type Contact_Var_Pop_Fields = {
__typename?: 'contact_var_pop_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Var_Samp_Fields = {
__typename?: 'contact_var_samp_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Variance_Fields = {
__typename?: 'contact_variance_fields';

  sf_down_sync_time?: Maybe<Scalars['Float']>;
};

/** Table for Hasura to be able to use user_created_count_and_rolling_avg function */
export type Count_With_Rolling_Avg = {
__typename?: 'count_with_rolling_avg';

  count: Scalars['Int'];
  date_start: Scalars['date'];
  rolling_avg: Scalars['numeric'];
};

/** aggregated selection of "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Aggregate = {
__typename?: 'count_with_rolling_avg_aggregate';

  aggregate?: Maybe<Count_With_Rolling_Avg_Aggregate_Fields>;
  nodes: Array<Count_With_Rolling_Avg>;
};

/** aggregate fields of "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Aggregate_Fields = {
__typename?: 'count_with_rolling_avg_aggregate_fields';

  avg?: Maybe<Count_With_Rolling_Avg_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_With_Rolling_Avg_Max_Fields>;
  min?: Maybe<Count_With_Rolling_Avg_Min_Fields>;
  stddev?: Maybe<Count_With_Rolling_Avg_Stddev_Fields>;
  stddev_pop?: Maybe<Count_With_Rolling_Avg_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_With_Rolling_Avg_Stddev_Samp_Fields>;
  sum?: Maybe<Count_With_Rolling_Avg_Sum_Fields>;
  var_pop?: Maybe<Count_With_Rolling_Avg_Var_Pop_Fields>;
  var_samp?: Maybe<Count_With_Rolling_Avg_Var_Samp_Fields>;
  variance?: Maybe<Count_With_Rolling_Avg_Variance_Fields>;
};
export type Count_With_Rolling_Avg_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Count_With_Rolling_Avg_Avg_Fields = {
__typename?: 'count_with_rolling_avg_avg_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Count_With_Rolling_Avg_Max_Fields = {
__typename?: 'count_with_rolling_avg_max_fields';

  count?: Maybe<Scalars['Int']>;
  date_start?: Maybe<Scalars['date']>;
  rolling_avg?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Count_With_Rolling_Avg_Min_Fields = {
__typename?: 'count_with_rolling_avg_min_fields';

  count?: Maybe<Scalars['Int']>;
  date_start?: Maybe<Scalars['date']>;
  rolling_avg?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "count_with_rolling_avg" */
export type Count_With_Rolling_Avg_Mutation_Response = {
__typename?: 'count_with_rolling_avg_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Count_With_Rolling_Avg>;
};

/** aggregate stddev on columns */
export type Count_With_Rolling_Avg_Stddev_Fields = {
__typename?: 'count_with_rolling_avg_stddev_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_With_Rolling_Avg_Stddev_Pop_Fields = {
__typename?: 'count_with_rolling_avg_stddev_pop_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_With_Rolling_Avg_Stddev_Samp_Fields = {
__typename?: 'count_with_rolling_avg_stddev_samp_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_With_Rolling_Avg_Sum_Fields = {
__typename?: 'count_with_rolling_avg_sum_fields';

  count?: Maybe<Scalars['Int']>;
  rolling_avg?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Count_With_Rolling_Avg_Var_Pop_Fields = {
__typename?: 'count_with_rolling_avg_var_pop_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_With_Rolling_Avg_Var_Samp_Fields = {
__typename?: 'count_with_rolling_avg_var_samp_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_With_Rolling_Avg_Variance_Fields = {
__typename?: 'count_with_rolling_avg_variance_fields';

  count?: Maybe<Scalars['Float']>;
  rolling_avg?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "country" */
export type Country = {
__typename?: 'country';

  /** An array relationship */
  contacts: Array<Contact>;
  /** An aggregate relationship */
  contacts_aggregate: Contact_Aggregate;
  value: Scalars['String'];
};
export type CountryContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type CountryContacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};


/** aggregated selection of "country" */
export type Country_Aggregate = {
__typename?: 'country_aggregate';

  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
__typename?: 'country_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
};
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Country_Max_Fields = {
__typename?: 'country_max_fields';

  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
__typename?: 'country_min_fields';

  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
__typename?: 'country_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** Salesforce connection we upsync to or downsync from */
export type Crm_Connection = {
__typename?: 'crm_connection';

  auth_token: Scalars['String'];
  consumer_key?: Maybe<Scalars['String']>;
  consumer_secret?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  default_admin_user?: Maybe<Scalars['uuid']>;
  env: Scalars['String'];
  heroku_connect_secret?: Maybe<Scalars['String']>;
  heroku_connect_url?: Maybe<Scalars['String']>;
  hook_token: Scalars['String'];
  name: Scalars['String'];
  owner_team_id?: Maybe<Scalars['uuid']>;
  refresh_token: Scalars['String'];
  sf_instance_id: Scalars['String'];
  sf_rest_api_uri: Scalars['String'];
  token_issued_at: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "crm_connection" */
export type Crm_Connection_Aggregate = {
__typename?: 'crm_connection_aggregate';

  aggregate?: Maybe<Crm_Connection_Aggregate_Fields>;
  nodes: Array<Crm_Connection>;
};

/** aggregate fields of "crm_connection" */
export type Crm_Connection_Aggregate_Fields = {
__typename?: 'crm_connection_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Crm_Connection_Max_Fields>;
  min?: Maybe<Crm_Connection_Min_Fields>;
};
export type Crm_Connection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Crm_Connection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Crm_Connection_Max_Fields = {
__typename?: 'crm_connection_max_fields';

  auth_token?: Maybe<Scalars['String']>;
  consumer_key?: Maybe<Scalars['String']>;
  consumer_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_admin_user?: Maybe<Scalars['uuid']>;
  env?: Maybe<Scalars['String']>;
  heroku_connect_secret?: Maybe<Scalars['String']>;
  heroku_connect_url?: Maybe<Scalars['String']>;
  hook_token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_team_id?: Maybe<Scalars['uuid']>;
  refresh_token?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  sf_rest_api_uri?: Maybe<Scalars['String']>;
  token_issued_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Crm_Connection_Min_Fields = {
__typename?: 'crm_connection_min_fields';

  auth_token?: Maybe<Scalars['String']>;
  consumer_key?: Maybe<Scalars['String']>;
  consumer_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_admin_user?: Maybe<Scalars['uuid']>;
  env?: Maybe<Scalars['String']>;
  heroku_connect_secret?: Maybe<Scalars['String']>;
  heroku_connect_url?: Maybe<Scalars['String']>;
  hook_token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_team_id?: Maybe<Scalars['uuid']>;
  refresh_token?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  sf_rest_api_uri?: Maybe<Scalars['String']>;
  token_issued_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "crm_connection" */
export type Crm_Connection_Mutation_Response = {
__typename?: 'crm_connection_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Crm_Connection>;
};

/** columns and relationships of "deal" */
export type Deal = {
__typename?: 'deal';

  account_manager_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** An array relationship */
  acl_deal_developer_member: Array<Acl_Deal_Developer_Member>;
  /** An aggregate relationship */
  acl_deal_developer_member_aggregate: Acl_Deal_Developer_Member_Aggregate;
  /** An array relationship */
  acl_deal_master_agent: Array<Acl_Deal_Master_Agent>;
  /** An aggregate relationship */
  acl_deal_master_agent_aggregate: Acl_Deal_Master_Agent_Aggregate;
  /** An array relationship */
  acl_deal_user_team: Array<Acl_Deal_User_Team>;
  /** An aggregate relationship */
  acl_deal_user_team_aggregate: Acl_Deal_User_Team_Aggregate;
  advised_contract_ready_to_progress?: Maybe<Scalars['date']>;
  agency_contact_id?: Maybe<Scalars['uuid']>;
  agency_id: Scalars['uuid'];
  /** An object relationship */
  agency_organisation: Organisation;
  /** An object relationship */
  agent_contact?: Maybe<Contact>;
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  awaiting_enquiry_sf_id: Scalars['Boolean'];
  awaiting_legal_sf_id: Scalars['Boolean'];
  balance_due_by?: Maybe<Scalars['timestamptz']>;
  balance_of_deposit?: Maybe<Scalars['Int']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bank_guarantee_expires?: Maybe<Scalars['date']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_pb_max?: Maybe<Scalars['Int']>;
  bedrooms_pb_min?: Maybe<Scalars['Int']>;
  bedrooms_requested?: Maybe<Scalars['String']>;
  bond_expires?: Maybe<Scalars['date']>;
  bpay_biller_code?: Maybe<Scalars['String']>;
  bpay_crn?: Maybe<Scalars['String']>;
  building_id?: Maybe<Scalars['uuid']>;
  buying_capacity?: Maybe<Scalars['Int']>;
  cars?: Maybe<Scalars['Int']>;
  changes_requested: Scalars['Boolean'];
  colour_scheme?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_for_buyer?: Maybe<Scalars['String']>;
  commission_split?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  commissions: Array<Commission>;
  /** An aggregate relationship */
  commissions_aggregate: Commission_Aggregate;
  confirmed_firb_status?: Maybe<Scalars['String']>;
  /** An object relationship */
  contact?: Maybe<Contact>;
  contact_id?: Maybe<Scalars['uuid']>;
  contact_status?: Maybe<Deal_Contact_Status_Enum>;
  /** An array relationship */
  contacts: Array<Deal_Contact>;
  /** An aggregate relationship */
  contacts_aggregate: Deal_Contact_Aggregate;
  contract_conditional?: Maybe<Scalars['date']>;
  contract_deposit_amount?: Maybe<Scalars['numeric']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  contract_exchanged?: Maybe<Scalars['timestamptz']>;
  contract_issued?: Maybe<Scalars['timestamptz']>;
  contract_progress_update?: Maybe<Deal_Progress_Update_Enum>;
  contract_returned_to_vendor?: Maybe<Scalars['date']>;
  contract_sent_to_purchaser?: Maybe<Scalars['date']>;
  contract_unconditional?: Maybe<Scalars['timestamptz']>;
  cooling_off_ends?: Maybe<Scalars['timestamptz']>;
  cooling_off_starts?: Maybe<Scalars['timestamptz']>;
  cooling_off_waived?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  deal_contact_status?: Maybe<Deal_Contact_Status>;
  /** An object relationship */
  deal_contact_type?: Maybe<Deal_Contact>;
  /** An object relationship */
  deal_deposit_status?: Maybe<Deal_Deposit_Status>;
  /** An object relationship */
  deal_enquiry_source?: Maybe<Deal_Enquiry_Source>;
  /** An object relationship */
  deal_fallen_reason?: Maybe<Deal_Fallen_Reason>;
  /** An object relationship */
  deal_payment_method?: Maybe<Deal_Payment_Method>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  deal_progress_update?: Maybe<Deal_Progress_Update>;
  /** An object relationship */
  deal_reason_to_purchase?: Maybe<Deal_Reason_Purchase>;
  /** An object relationship */
  deal_status?: Maybe<Deal_Status>;
  /** An object relationship */
  deal_transfer_status?: Maybe<Deal_Transfer_Status>;
  /** An object relationship */
  deal_type: Deal_Type;
  /** An array relationship */
  deal_witnesses: Array<Deal_Witness>;
  /** An aggregate relationship */
  deal_witnesses_aggregate: Deal_Witness_Aggregate;
  deposit_due?: Maybe<Scalars['Int']>;
  deposit_received?: Maybe<Scalars['date']>;
  deposit_status?: Maybe<Deal_Deposit_Status_Enum>;
  deposit_type?: Maybe<Deal_Deposit_Type_Enum>;
  enquiry_source?: Maybe<Deal_Enquiry_Source_Enum>;
  enquiry_source_other?: Maybe<Scalars['String']>;
  envelope_opened_by_buyer?: Maybe<Scalars['timestamptz']>;
  envelope_owner?: Maybe<Scalars['String']>;
  eoi_approved_by_vendor?: Maybe<Scalars['timestamptz']>;
  fallen_date?: Maybe<Scalars['date']>;
  fallen_explanation?: Maybe<Scalars['String']>;
  fallen_reason?: Maybe<Deal_Fallen_Reason_Enum>;
  finance_status?: Maybe<Finance_Status_Enum>;
  firb_approved_vendor?: Maybe<Scalars['String']>;
  firb_confirmation_received?: Maybe<Scalars['date']>;
  firb_purchaser?: Maybe<Scalars['Boolean']>;
  forecast_exchange?: Maybe<Scalars['date']>;
  form_of_settlement?: Maybe<Scalars['String']>;
  holding_deposit_amount?: Maybe<Scalars['numeric']>;
  holding_deposit_amount_received?: Maybe<Scalars['numeric']>;
  holding_deposit_confirmed_by_vendor?: Maybe<Scalars['date']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_number?: Maybe<Scalars['String']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_paid_handled?: Maybe<Scalars['Boolean']>;
  holding_deposit_percentage?: Maybe<Scalars['numeric']>;
  holding_deposit_received?: Maybe<Scalars['Boolean']>;
  holding_deposit_received_by_vsol?: Maybe<Scalars['date']>;
  how_did_you_hear_about_the_property?: Maybe<Scalars['String']>;
  /** An array relationship */
  hubspot: Array<Hubspot_Deal>;
  /** An aggregate relationship */
  hubspot_aggregate: Hubspot_Deal_Aggregate;
  id: Scalars['uuid'];
  id_received?: Maybe<Scalars['Boolean']>;
  identity_doc1_number?: Maybe<Scalars['String']>;
  identity_doc1_region?: Maybe<Scalars['String']>;
  identity_doc1_type?: Maybe<Scalars['String']>;
  incl_in_deposit?: Maybe<Scalars['Boolean']>;
  last_sales_advice_generated_at?: Maybe<Scalars['timestamptz']>;
  lead_id?: Maybe<Scalars['uuid']>;
  lead_strength?: Maybe<Scalars['String']>;
  /** An object relationship */
  listing?: Maybe<Listing>;
  listing_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  name_on_contract?: Maybe<Scalars['String']>;
  on_hold?: Maybe<Scalars['timestamptz']>;
  on_hold_approved_by_client?: Maybe<Scalars['timestamptz']>;
  on_hold_approved_by_vendor?: Maybe<Scalars['timestamptz']>;
  on_hold_release?: Maybe<Scalars['date']>;
  paid_by?: Maybe<Scalars['String']>;
  parking_spaces?: Maybe<Scalars['Int']>;
  payable_on?: Maybe<Scalars['date']>;
  payment_method?: Maybe<Deal_Payment_Method_Enum>;
  pre_settlement_booked_by?: Maybe<Scalars['String']>;
  /** An array relationship */
  preferences: Array<Preference>;
  /** An aggregate relationship */
  preferences_aggregate: Preference_Aggregate;
  /** An object relationship */
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['uuid']>;
  proof_verified: Scalars['Boolean'];
  /** An object relationship */
  property?: Maybe<Property>;
  /** An object relationship */
  property_group?: Maybe<Property_Group>;
  property_id?: Maybe<Scalars['uuid']>;
  purchase_proposal_sent?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  purchaser_solicitor_contact?: Maybe<Contact>;
  purchaser_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  purchaser_solicitor_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  purchaser_solicitor_organisation?: Maybe<Organisation>;
  reason_to_purchase?: Maybe<Scalars['String']>;
  refund_account_bsb?: Maybe<Scalars['String']>;
  refund_account_name?: Maybe<Scalars['String']>;
  refund_account_number?: Maybe<Scalars['String']>;
  refund_requested?: Maybe<Scalars['date']>;
  refund_swift_code?: Maybe<Scalars['String']>;
  request_reference?: Maybe<Scalars['String']>;
  reservation_finalised?: Maybe<Scalars['Boolean']>;
  reserved_on?: Maybe<Scalars['timestamptz']>;
  sa_received?: Maybe<Scalars['date']>;
  sa_sent_to_legal?: Maybe<Scalars['date']>;
  sale_price?: Maybe<Scalars['Int']>;
  settlement?: Maybe<Scalars['timestamptz']>;
  settlement_booked?: Maybe<Scalars['date']>;
  settlement_defect_status?: Maybe<Scalars['String']>;
  settlement_due_date?: Maybe<Scalars['date']>;
  settlement_extension_requested_date?: Maybe<Scalars['date']>;
  settlement_inspection?: Maybe<Scalars['timestamp']>;
  settlement_inspection_completed?: Maybe<Scalars['Boolean']>;
  settlement_keys_collected_date?: Maybe<Scalars['date']>;
  settlement_outlook?: Maybe<Scalars['String']>;
  settlement_story?: Maybe<Scalars['String']>;
  settlement_summary?: Maybe<Scalars['String']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  sf_enquiry_id?: Maybe<Scalars['String']>;
  sf_legal_id?: Maybe<Scalars['String']>;
  solicitor_notes?: Maybe<Scalars['String']>;
  source?: Maybe<Lead_Generation_Source_Enum>;
  /** An array relationship */
  spoken_language: Array<Deal_Spoken_Language>;
  /** An aggregate relationship */
  spoken_language_aggregate: Deal_Spoken_Language_Aggregate;
  status?: Maybe<Deal_Status_Enum>;
  /** An object relationship */
  sub_agency?: Maybe<Organisation>;
  sub_agency_contact_id?: Maybe<Scalars['uuid']>;
  sub_agency_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sub_agent_contact?: Maybe<Contact>;
  terms?: Maybe<Scalars['String']>;
  time_frame?: Maybe<Scalars['String']>;
  total_deposit_extension_date?: Maybe<Scalars['date']>;
  total_deposit_outstanding?: Maybe<Scalars['Int']>;
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  total_deposit_selection?: Maybe<Scalars['String']>;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An aggregate relationship */
  transactions_aggregate: Transaction_Aggregate;
  transfer_receipt_time?: Maybe<Scalars['date']>;
  transfer_status?: Maybe<Deal_Transfer_Status_Enum>;
  transfer_to_vsol_requested?: Maybe<Scalars['Boolean']>;
  transferred_to_vsol?: Maybe<Scalars['timestamptz']>;
  type: Deal_Type_Enum;
  updated_at: Scalars['timestamptz'];
  uploaded_id?: Maybe<Scalars['Boolean']>;
  uploaded_id_count?: Maybe<Scalars['Int']>;
  uploaded_receipt?: Maybe<Scalars['Boolean']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  vendor_solicitor_contact?: Maybe<Contact>;
  vendor_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vendor_solicitor_organisation?: Maybe<Organisation>;
  x66w_certificate_provided?: Maybe<Scalars['Boolean']>;
  x66w_received_by_vsol?: Maybe<Scalars['date']>;
};
export type DealAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type DealAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type DealAcl_Deal_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type DealAcl_Deal_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type DealAcl_Deal_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type DealAcl_Deal_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type DealAcl_Deal_User_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type DealAcl_Deal_User_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type DealAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type DealAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type DealCommissionsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type DealCommissions_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type DealContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type DealContacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type DealDeal_WitnessesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Witness_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type DealDeal_Witnesses_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Witness_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type DealHubspotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type DealHubspot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type DealPreferencesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type DealPreferences_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type DealSpoken_LanguageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type DealSpoken_Language_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type DealTransactionsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type DealTransactions_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};


/** aggregated selection of "deal" */
export type Deal_Aggregate = {
__typename?: 'deal_aggregate';

  aggregate?: Maybe<Deal_Aggregate_Fields>;
  nodes: Array<Deal>;
};

/** aggregate fields of "deal" */
export type Deal_Aggregate_Fields = {
__typename?: 'deal_aggregate_fields';

  avg?: Maybe<Deal_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Max_Fields>;
  min?: Maybe<Deal_Min_Fields>;
  stddev?: Maybe<Deal_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Sum_Fields>;
  var_pop?: Maybe<Deal_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Var_Samp_Fields>;
  variance?: Maybe<Deal_Variance_Fields>;
};
export type Deal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Deal_Avg_Fields = {
__typename?: 'deal_avg_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** Deal can have multiple contacts, contact can have multiple deals */
export type Deal_Contact = {
__typename?: 'deal_contact';

  buyer_type?: Maybe<Buyer_Type_Enum>;
  /** An object relationship */
  contact?: Maybe<Contact>;
  contact_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deal?: Maybe<Deal>;
  deal_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_existing: Scalars['Boolean'];
};

/** aggregated selection of "deal_contact" */
export type Deal_Contact_Aggregate = {
__typename?: 'deal_contact_aggregate';

  aggregate?: Maybe<Deal_Contact_Aggregate_Fields>;
  nodes: Array<Deal_Contact>;
};

/** aggregate fields of "deal_contact" */
export type Deal_Contact_Aggregate_Fields = {
__typename?: 'deal_contact_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Contact_Max_Fields>;
  min?: Maybe<Deal_Contact_Min_Fields>;
};
export type Deal_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Contact_Max_Fields = {
__typename?: 'deal_contact_max_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Deal_Contact_Min_Fields = {
__typename?: 'deal_contact_min_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "deal_contact" */
export type Deal_Contact_Mutation_Response = {
__typename?: 'deal_contact_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Contact>;
};

/** columns and relationships of "deal_contact_status" */
export type Deal_Contact_Status = {
__typename?: 'deal_contact_status';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Contact_StatusDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Contact_StatusDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_contact_status" */
export type Deal_Contact_Status_Aggregate = {
__typename?: 'deal_contact_status_aggregate';

  aggregate?: Maybe<Deal_Contact_Status_Aggregate_Fields>;
  nodes: Array<Deal_Contact_Status>;
};

/** aggregate fields of "deal_contact_status" */
export type Deal_Contact_Status_Aggregate_Fields = {
__typename?: 'deal_contact_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Contact_Status_Max_Fields>;
  min?: Maybe<Deal_Contact_Status_Min_Fields>;
};
export type Deal_Contact_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Contact_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Contact_Status_Max_Fields = {
__typename?: 'deal_contact_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Contact_Status_Min_Fields = {
__typename?: 'deal_contact_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_contact_status" */
export type Deal_Contact_Status_Mutation_Response = {
__typename?: 'deal_contact_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Contact_Status>;
};

/** columns and relationships of "deal_deposit_status" */
export type Deal_Deposit_Status = {
__typename?: 'deal_deposit_status';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Deposit_StatusDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Deposit_StatusDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_deposit_status" */
export type Deal_Deposit_Status_Aggregate = {
__typename?: 'deal_deposit_status_aggregate';

  aggregate?: Maybe<Deal_Deposit_Status_Aggregate_Fields>;
  nodes: Array<Deal_Deposit_Status>;
};

/** aggregate fields of "deal_deposit_status" */
export type Deal_Deposit_Status_Aggregate_Fields = {
__typename?: 'deal_deposit_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Deposit_Status_Max_Fields>;
  min?: Maybe<Deal_Deposit_Status_Min_Fields>;
};
export type Deal_Deposit_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Deposit_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Deposit_Status_Max_Fields = {
__typename?: 'deal_deposit_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Deposit_Status_Min_Fields = {
__typename?: 'deal_deposit_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_deposit_status" */
export type Deal_Deposit_Status_Mutation_Response = {
__typename?: 'deal_deposit_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Deposit_Status>;
};

/** columns and relationships of "deal_deposit_type" */
export type Deal_Deposit_Type = {
__typename?: 'deal_deposit_type';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Deposit_TypeDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Deposit_TypeDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_deposit_type" */
export type Deal_Deposit_Type_Aggregate = {
__typename?: 'deal_deposit_type_aggregate';

  aggregate?: Maybe<Deal_Deposit_Type_Aggregate_Fields>;
  nodes: Array<Deal_Deposit_Type>;
};

/** aggregate fields of "deal_deposit_type" */
export type Deal_Deposit_Type_Aggregate_Fields = {
__typename?: 'deal_deposit_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Deposit_Type_Max_Fields>;
  min?: Maybe<Deal_Deposit_Type_Min_Fields>;
};
export type Deal_Deposit_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Deposit_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Deposit_Type_Max_Fields = {
__typename?: 'deal_deposit_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Deposit_Type_Min_Fields = {
__typename?: 'deal_deposit_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_deposit_type" */
export type Deal_Deposit_Type_Mutation_Response = {
__typename?: 'deal_deposit_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Deposit_Type>;
};

/** columns and relationships of "deal_enquiry_source" */
export type Deal_Enquiry_Source = {
__typename?: 'deal_enquiry_source';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Enquiry_SourceDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Enquiry_SourceDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_enquiry_source" */
export type Deal_Enquiry_Source_Aggregate = {
__typename?: 'deal_enquiry_source_aggregate';

  aggregate?: Maybe<Deal_Enquiry_Source_Aggregate_Fields>;
  nodes: Array<Deal_Enquiry_Source>;
};

/** aggregate fields of "deal_enquiry_source" */
export type Deal_Enquiry_Source_Aggregate_Fields = {
__typename?: 'deal_enquiry_source_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Enquiry_Source_Max_Fields>;
  min?: Maybe<Deal_Enquiry_Source_Min_Fields>;
};
export type Deal_Enquiry_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Enquiry_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Enquiry_Source_Max_Fields = {
__typename?: 'deal_enquiry_source_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Enquiry_Source_Min_Fields = {
__typename?: 'deal_enquiry_source_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_enquiry_source" */
export type Deal_Enquiry_Source_Mutation_Response = {
__typename?: 'deal_enquiry_source_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Enquiry_Source>;
};

/** columns and relationships of "deal_fallen_reason" */
export type Deal_Fallen_Reason = {
__typename?: 'deal_fallen_reason';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Fallen_ReasonDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Fallen_ReasonDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_fallen_reason" */
export type Deal_Fallen_Reason_Aggregate = {
__typename?: 'deal_fallen_reason_aggregate';

  aggregate?: Maybe<Deal_Fallen_Reason_Aggregate_Fields>;
  nodes: Array<Deal_Fallen_Reason>;
};

/** aggregate fields of "deal_fallen_reason" */
export type Deal_Fallen_Reason_Aggregate_Fields = {
__typename?: 'deal_fallen_reason_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Fallen_Reason_Max_Fields>;
  min?: Maybe<Deal_Fallen_Reason_Min_Fields>;
};
export type Deal_Fallen_Reason_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Fallen_Reason_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Fallen_Reason_Max_Fields = {
__typename?: 'deal_fallen_reason_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Fallen_Reason_Min_Fields = {
__typename?: 'deal_fallen_reason_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_fallen_reason" */
export type Deal_Fallen_Reason_Mutation_Response = {
__typename?: 'deal_fallen_reason_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Fallen_Reason>;
};

/** aggregate max on columns */
export type Deal_Max_Fields = {
__typename?: 'deal_max_fields';

  account_manager_id?: Maybe<Scalars['uuid']>;
  advised_contract_ready_to_progress?: Maybe<Scalars['date']>;
  agency_contact_id?: Maybe<Scalars['uuid']>;
  agency_id?: Maybe<Scalars['uuid']>;
  balance_due_by?: Maybe<Scalars['timestamptz']>;
  balance_of_deposit?: Maybe<Scalars['Int']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bank_guarantee_expires?: Maybe<Scalars['date']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_pb_max?: Maybe<Scalars['Int']>;
  bedrooms_pb_min?: Maybe<Scalars['Int']>;
  bedrooms_requested?: Maybe<Scalars['String']>;
  bond_expires?: Maybe<Scalars['date']>;
  bpay_biller_code?: Maybe<Scalars['String']>;
  bpay_crn?: Maybe<Scalars['String']>;
  building_id?: Maybe<Scalars['uuid']>;
  buying_capacity?: Maybe<Scalars['Int']>;
  cars?: Maybe<Scalars['Int']>;
  colour_scheme?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_for_buyer?: Maybe<Scalars['String']>;
  confirmed_firb_status?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  contract_conditional?: Maybe<Scalars['date']>;
  contract_deposit_amount?: Maybe<Scalars['numeric']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  contract_exchanged?: Maybe<Scalars['timestamptz']>;
  contract_issued?: Maybe<Scalars['timestamptz']>;
  contract_returned_to_vendor?: Maybe<Scalars['date']>;
  contract_sent_to_purchaser?: Maybe<Scalars['date']>;
  contract_unconditional?: Maybe<Scalars['timestamptz']>;
  cooling_off_ends?: Maybe<Scalars['timestamptz']>;
  cooling_off_starts?: Maybe<Scalars['timestamptz']>;
  cooling_off_waived?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Int']>;
  deposit_received?: Maybe<Scalars['date']>;
  enquiry_source_other?: Maybe<Scalars['String']>;
  envelope_opened_by_buyer?: Maybe<Scalars['timestamptz']>;
  envelope_owner?: Maybe<Scalars['String']>;
  eoi_approved_by_vendor?: Maybe<Scalars['timestamptz']>;
  fallen_date?: Maybe<Scalars['date']>;
  fallen_explanation?: Maybe<Scalars['String']>;
  firb_approved_vendor?: Maybe<Scalars['String']>;
  firb_confirmation_received?: Maybe<Scalars['date']>;
  forecast_exchange?: Maybe<Scalars['date']>;
  form_of_settlement?: Maybe<Scalars['String']>;
  holding_deposit_amount?: Maybe<Scalars['numeric']>;
  holding_deposit_amount_received?: Maybe<Scalars['numeric']>;
  holding_deposit_confirmed_by_vendor?: Maybe<Scalars['date']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_number?: Maybe<Scalars['String']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['numeric']>;
  holding_deposit_received_by_vsol?: Maybe<Scalars['date']>;
  how_did_you_hear_about_the_property?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_doc1_number?: Maybe<Scalars['String']>;
  identity_doc1_region?: Maybe<Scalars['String']>;
  identity_doc1_type?: Maybe<Scalars['String']>;
  last_sales_advice_generated_at?: Maybe<Scalars['timestamptz']>;
  lead_id?: Maybe<Scalars['uuid']>;
  lead_strength?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_on_contract?: Maybe<Scalars['String']>;
  on_hold?: Maybe<Scalars['timestamptz']>;
  on_hold_approved_by_client?: Maybe<Scalars['timestamptz']>;
  on_hold_approved_by_vendor?: Maybe<Scalars['timestamptz']>;
  on_hold_release?: Maybe<Scalars['date']>;
  paid_by?: Maybe<Scalars['String']>;
  parking_spaces?: Maybe<Scalars['Int']>;
  payable_on?: Maybe<Scalars['date']>;
  pre_settlement_booked_by?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  purchaser_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  purchaser_solicitor_id?: Maybe<Scalars['uuid']>;
  reason_to_purchase?: Maybe<Scalars['String']>;
  refund_account_bsb?: Maybe<Scalars['String']>;
  refund_account_name?: Maybe<Scalars['String']>;
  refund_account_number?: Maybe<Scalars['String']>;
  refund_requested?: Maybe<Scalars['date']>;
  refund_swift_code?: Maybe<Scalars['String']>;
  request_reference?: Maybe<Scalars['String']>;
  reserved_on?: Maybe<Scalars['timestamptz']>;
  sa_received?: Maybe<Scalars['date']>;
  sa_sent_to_legal?: Maybe<Scalars['date']>;
  sale_price?: Maybe<Scalars['Int']>;
  settlement?: Maybe<Scalars['timestamptz']>;
  settlement_booked?: Maybe<Scalars['date']>;
  settlement_defect_status?: Maybe<Scalars['String']>;
  settlement_due_date?: Maybe<Scalars['date']>;
  settlement_extension_requested_date?: Maybe<Scalars['date']>;
  settlement_inspection?: Maybe<Scalars['timestamp']>;
  settlement_keys_collected_date?: Maybe<Scalars['date']>;
  settlement_outlook?: Maybe<Scalars['String']>;
  settlement_story?: Maybe<Scalars['String']>;
  settlement_summary?: Maybe<Scalars['String']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  sf_enquiry_id?: Maybe<Scalars['String']>;
  sf_legal_id?: Maybe<Scalars['String']>;
  solicitor_notes?: Maybe<Scalars['String']>;
  sub_agency_contact_id?: Maybe<Scalars['uuid']>;
  sub_agency_id?: Maybe<Scalars['uuid']>;
  terms?: Maybe<Scalars['String']>;
  time_frame?: Maybe<Scalars['String']>;
  total_deposit_extension_date?: Maybe<Scalars['date']>;
  total_deposit_outstanding?: Maybe<Scalars['Int']>;
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  total_deposit_selection?: Maybe<Scalars['String']>;
  transfer_receipt_time?: Maybe<Scalars['date']>;
  transferred_to_vsol?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_id_count?: Maybe<Scalars['Int']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
  vendor_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
  x66w_received_by_vsol?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Deal_Min_Fields = {
__typename?: 'deal_min_fields';

  account_manager_id?: Maybe<Scalars['uuid']>;
  advised_contract_ready_to_progress?: Maybe<Scalars['date']>;
  agency_contact_id?: Maybe<Scalars['uuid']>;
  agency_id?: Maybe<Scalars['uuid']>;
  balance_due_by?: Maybe<Scalars['timestamptz']>;
  balance_of_deposit?: Maybe<Scalars['Int']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bank_guarantee_expires?: Maybe<Scalars['date']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_pb_max?: Maybe<Scalars['Int']>;
  bedrooms_pb_min?: Maybe<Scalars['Int']>;
  bedrooms_requested?: Maybe<Scalars['String']>;
  bond_expires?: Maybe<Scalars['date']>;
  bpay_biller_code?: Maybe<Scalars['String']>;
  bpay_crn?: Maybe<Scalars['String']>;
  building_id?: Maybe<Scalars['uuid']>;
  buying_capacity?: Maybe<Scalars['Int']>;
  cars?: Maybe<Scalars['Int']>;
  colour_scheme?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  comment_for_buyer?: Maybe<Scalars['String']>;
  confirmed_firb_status?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  contract_conditional?: Maybe<Scalars['date']>;
  contract_deposit_amount?: Maybe<Scalars['numeric']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  contract_exchanged?: Maybe<Scalars['timestamptz']>;
  contract_issued?: Maybe<Scalars['timestamptz']>;
  contract_returned_to_vendor?: Maybe<Scalars['date']>;
  contract_sent_to_purchaser?: Maybe<Scalars['date']>;
  contract_unconditional?: Maybe<Scalars['timestamptz']>;
  cooling_off_ends?: Maybe<Scalars['timestamptz']>;
  cooling_off_starts?: Maybe<Scalars['timestamptz']>;
  cooling_off_waived?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Int']>;
  deposit_received?: Maybe<Scalars['date']>;
  enquiry_source_other?: Maybe<Scalars['String']>;
  envelope_opened_by_buyer?: Maybe<Scalars['timestamptz']>;
  envelope_owner?: Maybe<Scalars['String']>;
  eoi_approved_by_vendor?: Maybe<Scalars['timestamptz']>;
  fallen_date?: Maybe<Scalars['date']>;
  fallen_explanation?: Maybe<Scalars['String']>;
  firb_approved_vendor?: Maybe<Scalars['String']>;
  firb_confirmation_received?: Maybe<Scalars['date']>;
  forecast_exchange?: Maybe<Scalars['date']>;
  form_of_settlement?: Maybe<Scalars['String']>;
  holding_deposit_amount?: Maybe<Scalars['numeric']>;
  holding_deposit_amount_received?: Maybe<Scalars['numeric']>;
  holding_deposit_confirmed_by_vendor?: Maybe<Scalars['date']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_number?: Maybe<Scalars['String']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['numeric']>;
  holding_deposit_received_by_vsol?: Maybe<Scalars['date']>;
  how_did_you_hear_about_the_property?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identity_doc1_number?: Maybe<Scalars['String']>;
  identity_doc1_region?: Maybe<Scalars['String']>;
  identity_doc1_type?: Maybe<Scalars['String']>;
  last_sales_advice_generated_at?: Maybe<Scalars['timestamptz']>;
  lead_id?: Maybe<Scalars['uuid']>;
  lead_strength?: Maybe<Scalars['String']>;
  listing_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_on_contract?: Maybe<Scalars['String']>;
  on_hold?: Maybe<Scalars['timestamptz']>;
  on_hold_approved_by_client?: Maybe<Scalars['timestamptz']>;
  on_hold_approved_by_vendor?: Maybe<Scalars['timestamptz']>;
  on_hold_release?: Maybe<Scalars['date']>;
  paid_by?: Maybe<Scalars['String']>;
  parking_spaces?: Maybe<Scalars['Int']>;
  payable_on?: Maybe<Scalars['date']>;
  pre_settlement_booked_by?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  purchaser_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  purchaser_solicitor_id?: Maybe<Scalars['uuid']>;
  reason_to_purchase?: Maybe<Scalars['String']>;
  refund_account_bsb?: Maybe<Scalars['String']>;
  refund_account_name?: Maybe<Scalars['String']>;
  refund_account_number?: Maybe<Scalars['String']>;
  refund_requested?: Maybe<Scalars['date']>;
  refund_swift_code?: Maybe<Scalars['String']>;
  request_reference?: Maybe<Scalars['String']>;
  reserved_on?: Maybe<Scalars['timestamptz']>;
  sa_received?: Maybe<Scalars['date']>;
  sa_sent_to_legal?: Maybe<Scalars['date']>;
  sale_price?: Maybe<Scalars['Int']>;
  settlement?: Maybe<Scalars['timestamptz']>;
  settlement_booked?: Maybe<Scalars['date']>;
  settlement_defect_status?: Maybe<Scalars['String']>;
  settlement_due_date?: Maybe<Scalars['date']>;
  settlement_extension_requested_date?: Maybe<Scalars['date']>;
  settlement_inspection?: Maybe<Scalars['timestamp']>;
  settlement_keys_collected_date?: Maybe<Scalars['date']>;
  settlement_outlook?: Maybe<Scalars['String']>;
  settlement_story?: Maybe<Scalars['String']>;
  settlement_summary?: Maybe<Scalars['String']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  sf_enquiry_id?: Maybe<Scalars['String']>;
  sf_legal_id?: Maybe<Scalars['String']>;
  solicitor_notes?: Maybe<Scalars['String']>;
  sub_agency_contact_id?: Maybe<Scalars['uuid']>;
  sub_agency_id?: Maybe<Scalars['uuid']>;
  terms?: Maybe<Scalars['String']>;
  time_frame?: Maybe<Scalars['String']>;
  total_deposit_extension_date?: Maybe<Scalars['date']>;
  total_deposit_outstanding?: Maybe<Scalars['Int']>;
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  total_deposit_selection?: Maybe<Scalars['String']>;
  transfer_receipt_time?: Maybe<Scalars['date']>;
  transferred_to_vsol?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_id_count?: Maybe<Scalars['Int']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
  vendor_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
  x66w_received_by_vsol?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "deal" */
export type Deal_Mutation_Response = {
__typename?: 'deal_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal>;
};

/** columns and relationships of "deal_payment_method" */
export type Deal_Payment_Method = {
__typename?: 'deal_payment_method';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Payment_MethodDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Payment_MethodDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_payment_method" */
export type Deal_Payment_Method_Aggregate = {
__typename?: 'deal_payment_method_aggregate';

  aggregate?: Maybe<Deal_Payment_Method_Aggregate_Fields>;
  nodes: Array<Deal_Payment_Method>;
};

/** aggregate fields of "deal_payment_method" */
export type Deal_Payment_Method_Aggregate_Fields = {
__typename?: 'deal_payment_method_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Payment_Method_Max_Fields>;
  min?: Maybe<Deal_Payment_Method_Min_Fields>;
};
export type Deal_Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Payment_Method_Max_Fields = {
__typename?: 'deal_payment_method_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Payment_Method_Min_Fields = {
__typename?: 'deal_payment_method_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_payment_method" */
export type Deal_Payment_Method_Mutation_Response = {
__typename?: 'deal_payment_method_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Payment_Method>;
};

/** columns and relationships of "deal_progress_update" */
export type Deal_Progress_Update = {
__typename?: 'deal_progress_update';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Progress_UpdateDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Progress_UpdateDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_progress_update" */
export type Deal_Progress_Update_Aggregate = {
__typename?: 'deal_progress_update_aggregate';

  aggregate?: Maybe<Deal_Progress_Update_Aggregate_Fields>;
  nodes: Array<Deal_Progress_Update>;
};

/** aggregate fields of "deal_progress_update" */
export type Deal_Progress_Update_Aggregate_Fields = {
__typename?: 'deal_progress_update_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Progress_Update_Max_Fields>;
  min?: Maybe<Deal_Progress_Update_Min_Fields>;
};
export type Deal_Progress_Update_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Progress_Update_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Progress_Update_Max_Fields = {
__typename?: 'deal_progress_update_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Progress_Update_Min_Fields = {
__typename?: 'deal_progress_update_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_progress_update" */
export type Deal_Progress_Update_Mutation_Response = {
__typename?: 'deal_progress_update_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Progress_Update>;
};

/** columns and relationships of "deal_reason_purchase" */
export type Deal_Reason_Purchase = {
__typename?: 'deal_reason_purchase';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Reason_PurchaseDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Reason_PurchaseDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_reason_purchase" */
export type Deal_Reason_Purchase_Aggregate = {
__typename?: 'deal_reason_purchase_aggregate';

  aggregate?: Maybe<Deal_Reason_Purchase_Aggregate_Fields>;
  nodes: Array<Deal_Reason_Purchase>;
};

/** aggregate fields of "deal_reason_purchase" */
export type Deal_Reason_Purchase_Aggregate_Fields = {
__typename?: 'deal_reason_purchase_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Reason_Purchase_Max_Fields>;
  min?: Maybe<Deal_Reason_Purchase_Min_Fields>;
};
export type Deal_Reason_Purchase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Reason_Purchase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Reason_Purchase_Max_Fields = {
__typename?: 'deal_reason_purchase_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Reason_Purchase_Min_Fields = {
__typename?: 'deal_reason_purchase_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_reason_purchase" */
export type Deal_Reason_Purchase_Mutation_Response = {
__typename?: 'deal_reason_purchase_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Reason_Purchase>;
};

/** Salesforce actions connected to particular deal. These actions are queued to be executed asynchronously  */
export type Deal_Sf_Action = {
__typename?: 'deal_sf_action';

  action: Salesforce_Action_Enum;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  crm_connection: Crm_Connection;
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['uuid'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  payload: Scalars['jsonb'];
  sf_instance_id: Scalars['String'];
  status: Salesforce_Action_Status_Enum;
  updated_at: Scalars['timestamptz'];
};
export type Deal_Sf_ActionPayloadArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "deal_sf_action" */
export type Deal_Sf_Action_Aggregate = {
__typename?: 'deal_sf_action_aggregate';

  aggregate?: Maybe<Deal_Sf_Action_Aggregate_Fields>;
  nodes: Array<Deal_Sf_Action>;
};

/** aggregate fields of "deal_sf_action" */
export type Deal_Sf_Action_Aggregate_Fields = {
__typename?: 'deal_sf_action_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Sf_Action_Max_Fields>;
  min?: Maybe<Deal_Sf_Action_Min_Fields>;
};
export type Deal_Sf_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Sf_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Sf_Action_Max_Fields = {
__typename?: 'deal_sf_action_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deal_Sf_Action_Min_Fields = {
__typename?: 'deal_sf_action_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deal_sf_action" */
export type Deal_Sf_Action_Mutation_Response = {
__typename?: 'deal_sf_action_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Sf_Action>;
};

/** columns and relationships of "deal_spoken_language" */
export type Deal_Spoken_Language = {
__typename?: 'deal_spoken_language';

  created_at: Scalars['timestamptz'];
  deal_id: Scalars['uuid'];
  id: Scalars['uuid'];
  language: Spoken_Language_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "deal_spoken_language" */
export type Deal_Spoken_Language_Aggregate = {
__typename?: 'deal_spoken_language_aggregate';

  aggregate?: Maybe<Deal_Spoken_Language_Aggregate_Fields>;
  nodes: Array<Deal_Spoken_Language>;
};

/** aggregate fields of "deal_spoken_language" */
export type Deal_Spoken_Language_Aggregate_Fields = {
__typename?: 'deal_spoken_language_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Spoken_Language_Max_Fields>;
  min?: Maybe<Deal_Spoken_Language_Min_Fields>;
};
export type Deal_Spoken_Language_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Spoken_Language_Max_Fields = {
__typename?: 'deal_spoken_language_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deal_Spoken_Language_Min_Fields = {
__typename?: 'deal_spoken_language_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deal_spoken_language" */
export type Deal_Spoken_Language_Mutation_Response = {
__typename?: 'deal_spoken_language_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Spoken_Language>;
};

/** columns and relationships of "deal_status" */
export type Deal_Status = {
__typename?: 'deal_status';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_StatusDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_StatusDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_status" */
export type Deal_Status_Aggregate = {
__typename?: 'deal_status_aggregate';

  aggregate?: Maybe<Deal_Status_Aggregate_Fields>;
  nodes: Array<Deal_Status>;
};

/** aggregate fields of "deal_status" */
export type Deal_Status_Aggregate_Fields = {
__typename?: 'deal_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Status_Max_Fields>;
  min?: Maybe<Deal_Status_Min_Fields>;
};
export type Deal_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Status_Max_Fields = {
__typename?: 'deal_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Status_Min_Fields = {
__typename?: 'deal_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_status" */
export type Deal_Status_Mutation_Response = {
__typename?: 'deal_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Status>;
};

/** aggregate stddev on columns */
export type Deal_Stddev_Fields = {
__typename?: 'deal_stddev_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_pop on columns */
export type Deal_Stddev_Pop_Fields = {
__typename?: 'deal_stddev_pop_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_samp on columns */
export type Deal_Stddev_Samp_Fields = {
__typename?: 'deal_stddev_samp_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Deal_Sum_Fields = {
__typename?: 'deal_sum_fields';

  balance_of_deposit?: Maybe<Scalars['Int']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bedrooms_pb_max?: Maybe<Scalars['Int']>;
  bedrooms_pb_min?: Maybe<Scalars['Int']>;
  buying_capacity?: Maybe<Scalars['Int']>;
  cars?: Maybe<Scalars['Int']>;
  contract_deposit_amount?: Maybe<Scalars['numeric']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Int']>;
  holding_deposit_amount?: Maybe<Scalars['numeric']>;
  holding_deposit_amount_received?: Maybe<Scalars['numeric']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['numeric']>;
  parking_spaces?: Maybe<Scalars['Int']>;
  sale_price?: Maybe<Scalars['Int']>;
  sf_down_sync_time?: Maybe<Scalars['bigint']>;
  total_deposit_outstanding?: Maybe<Scalars['Int']>;
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  uploaded_id_count?: Maybe<Scalars['Int']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "deal_transfer_status" */
export type Deal_Transfer_Status = {
__typename?: 'deal_transfer_status';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_Transfer_StatusDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_Transfer_StatusDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_transfer_status" */
export type Deal_Transfer_Status_Aggregate = {
__typename?: 'deal_transfer_status_aggregate';

  aggregate?: Maybe<Deal_Transfer_Status_Aggregate_Fields>;
  nodes: Array<Deal_Transfer_Status>;
};

/** aggregate fields of "deal_transfer_status" */
export type Deal_Transfer_Status_Aggregate_Fields = {
__typename?: 'deal_transfer_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Transfer_Status_Max_Fields>;
  min?: Maybe<Deal_Transfer_Status_Min_Fields>;
};
export type Deal_Transfer_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Transfer_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Transfer_Status_Max_Fields = {
__typename?: 'deal_transfer_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Transfer_Status_Min_Fields = {
__typename?: 'deal_transfer_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_transfer_status" */
export type Deal_Transfer_Status_Mutation_Response = {
__typename?: 'deal_transfer_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Transfer_Status>;
};

/** columns and relationships of "deal_type" */
export type Deal_Type = {
__typename?: 'deal_type';

  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};
export type Deal_TypeDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Deal_TypeDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** aggregated selection of "deal_type" */
export type Deal_Type_Aggregate = {
__typename?: 'deal_type_aggregate';

  aggregate?: Maybe<Deal_Type_Aggregate_Fields>;
  nodes: Array<Deal_Type>;
};

/** aggregate fields of "deal_type" */
export type Deal_Type_Aggregate_Fields = {
__typename?: 'deal_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Type_Max_Fields>;
  min?: Maybe<Deal_Type_Min_Fields>;
};
export type Deal_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Type_Max_Fields = {
__typename?: 'deal_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Deal_Type_Min_Fields = {
__typename?: 'deal_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "deal_type" */
export type Deal_Type_Mutation_Response = {
__typename?: 'deal_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Type>;
};

/** Used for Valuation Booking section in the Sales Contracts of the project with Settlement enabled via feature or project settings */
export type Deal_Valuation_Booking = {
__typename?: 'deal_valuation_booking';

  bank_requesting_valuation: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by_team_id: Scalars['uuid'];
  created_by_user_id: Scalars['uuid'];
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['uuid'];
  hs_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  hubspot: Array<Hubspot_Valuation_Booking>;
  /** An aggregate relationship */
  hubspot_aggregate: Hubspot_Valuation_Booking_Aggregate;
  id: Scalars['uuid'];
  /** When set to true, will activate handling by HasuraEvent hubspot_deal_valuation_booking. This is to prevent down-sync to trigger up-sync. We explicitly trigger up-sync when we need to, based on Hasura event payload. Event handler will put this field back to false once it was handled. */
  sync_to_hubspot: Scalars['Boolean'];
  sync_to_salesforce: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  valuation_date: Scalars['timestamp'];
  valuation_result?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  valuer_contact: Contact;
  /** id of the contact record which belongs to organisation of type VALUATION_BANK only. */
  valuer_contact_id: Scalars['uuid'];
};
export type Deal_Valuation_BookingHubspotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

export type Deal_Valuation_BookingHubspot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};


/** aggregated selection of "deal_valuation_booking" */
export type Deal_Valuation_Booking_Aggregate = {
__typename?: 'deal_valuation_booking_aggregate';

  aggregate?: Maybe<Deal_Valuation_Booking_Aggregate_Fields>;
  nodes: Array<Deal_Valuation_Booking>;
};

/** aggregate fields of "deal_valuation_booking" */
export type Deal_Valuation_Booking_Aggregate_Fields = {
__typename?: 'deal_valuation_booking_aggregate_fields';

  avg?: Maybe<Deal_Valuation_Booking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Valuation_Booking_Max_Fields>;
  min?: Maybe<Deal_Valuation_Booking_Min_Fields>;
  stddev?: Maybe<Deal_Valuation_Booking_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Valuation_Booking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Valuation_Booking_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Valuation_Booking_Sum_Fields>;
  var_pop?: Maybe<Deal_Valuation_Booking_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Valuation_Booking_Var_Samp_Fields>;
  variance?: Maybe<Deal_Valuation_Booking_Variance_Fields>;
};
export type Deal_Valuation_Booking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Valuation_Booking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Deal_Valuation_Booking_Avg_Fields = {
__typename?: 'deal_valuation_booking_avg_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Deal_Valuation_Booking_Max_Fields = {
__typename?: 'deal_valuation_booking_max_fields';

  bank_requesting_valuation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  deal_id?: Maybe<Scalars['uuid']>;
  hs_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valuation_date?: Maybe<Scalars['timestamp']>;
  valuation_result?: Maybe<Scalars['numeric']>;
  /** id of the contact record which belongs to organisation of type VALUATION_BANK only. */
  valuer_contact_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Deal_Valuation_Booking_Min_Fields = {
__typename?: 'deal_valuation_booking_min_fields';

  bank_requesting_valuation?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  deal_id?: Maybe<Scalars['uuid']>;
  hs_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valuation_date?: Maybe<Scalars['timestamp']>;
  valuation_result?: Maybe<Scalars['numeric']>;
  /** id of the contact record which belongs to organisation of type VALUATION_BANK only. */
  valuer_contact_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "deal_valuation_booking" */
export type Deal_Valuation_Booking_Mutation_Response = {
__typename?: 'deal_valuation_booking_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Valuation_Booking>;
};

/** aggregate stddev on columns */
export type Deal_Valuation_Booking_Stddev_Fields = {
__typename?: 'deal_valuation_booking_stddev_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Deal_Valuation_Booking_Stddev_Pop_Fields = {
__typename?: 'deal_valuation_booking_stddev_pop_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Deal_Valuation_Booking_Stddev_Samp_Fields = {
__typename?: 'deal_valuation_booking_stddev_samp_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Deal_Valuation_Booking_Sum_Fields = {
__typename?: 'deal_valuation_booking_sum_fields';

  valuation_result?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Deal_Valuation_Booking_Var_Pop_Fields = {
__typename?: 'deal_valuation_booking_var_pop_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Deal_Valuation_Booking_Var_Samp_Fields = {
__typename?: 'deal_valuation_booking_var_samp_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Deal_Valuation_Booking_Variance_Fields = {
__typename?: 'deal_valuation_booking_variance_fields';

  valuation_result?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Deal_Var_Pop_Fields = {
__typename?: 'deal_var_pop_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** aggregate var_samp on columns */
export type Deal_Var_Samp_Fields = {
__typename?: 'deal_var_samp_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** aggregate variance on columns */
export type Deal_Variance_Fields = {
__typename?: 'deal_variance_fields';

  balance_of_deposit?: Maybe<Scalars['Float']>;
  /** Calculates balance of deposit deposit_due minus paid amount */
  balance_of_deposit_calc?: Maybe<Scalars['numeric']>;
  bathrooms?: Maybe<Scalars['Float']>;
  bedrooms?: Maybe<Scalars['Float']>;
  bedrooms_pb_max?: Maybe<Scalars['Float']>;
  bedrooms_pb_min?: Maybe<Scalars['Float']>;
  buying_capacity?: Maybe<Scalars['Float']>;
  cars?: Maybe<Scalars['Float']>;
  contract_deposit_amount?: Maybe<Scalars['Float']>;
  /** Calculated total contract amount  based on total_deposit_percentage of sales price */
  contract_deposit_amount_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit only (not includes holding deposit) */
  contract_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  /** Paid amount for contract deposit including paid amount of holding deposit if incl_in_deposit is set to TRUE */
  contract_deposit_paid_total_calc?: Maybe<Scalars['numeric']>;
  /** Calculated sale price of deal based on Variation amount */
  deal_price_inc_variations_calc?: Maybe<Scalars['numeric']>;
  deposit_due?: Maybe<Scalars['Float']>;
  holding_deposit_amount?: Maybe<Scalars['Float']>;
  holding_deposit_amount_received?: Maybe<Scalars['Float']>;
  /** Calculates  holding deposit due  holding_deposit_amount minus paid holding amount */
  holding_deposit_due_calc?: Maybe<Scalars['numeric']>;
  /** Calculated paid amount for holding deposit. Sums all holding deposit transactions which go to Platform for given deal */
  holding_deposit_paid_calc?: Maybe<Scalars['numeric']>;
  holding_deposit_percentage?: Maybe<Scalars['Float']>;
  parking_spaces?: Maybe<Scalars['Float']>;
  sale_price?: Maybe<Scalars['Float']>;
  sf_down_sync_time?: Maybe<Scalars['Float']>;
  total_deposit_outstanding?: Maybe<Scalars['Float']>;
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  uploaded_id_count?: Maybe<Scalars['Float']>;
  /** Calculated  total variation amount */
  variations_calc?: Maybe<Scalars['numeric']>;
};

/** Nominated witness to sign the contract */
export type Deal_Witness = {
__typename?: 'deal_witness';

  created_at: Scalars['timestamptz'];
  created_by_team_id: Scalars['uuid'];
  created_by_user_id: Scalars['uuid'];
  deal_id: Scalars['uuid'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "deal_witness" */
export type Deal_Witness_Aggregate = {
__typename?: 'deal_witness_aggregate';

  aggregate?: Maybe<Deal_Witness_Aggregate_Fields>;
  nodes: Array<Deal_Witness>;
};

/** aggregate fields of "deal_witness" */
export type Deal_Witness_Aggregate_Fields = {
__typename?: 'deal_witness_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Deal_Witness_Max_Fields>;
  min?: Maybe<Deal_Witness_Min_Fields>;
};
export type Deal_Witness_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Deal_Witness_Max_Fields = {
__typename?: 'deal_witness_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  deal_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Deal_Witness_Min_Fields = {
__typename?: 'deal_witness_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  deal_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deal_witness" */
export type Deal_Witness_Mutation_Response = {
__typename?: 'deal_witness_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Witness>;
};

/** Agents allocated by Developers to be able to have Project, Stage, Listing allocations */
export type Developer_Agent = {
__typename?: 'developer_agent';

  access_active: Scalars['Boolean'];
  /** An object relationship */
  agent: Organisation;
  agent_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by_salesforce: Scalars['Boolean'];
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  developer: Organisation;
  developer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  sf_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "developer_agent" */
export type Developer_Agent_Aggregate = {
__typename?: 'developer_agent_aggregate';

  aggregate?: Maybe<Developer_Agent_Aggregate_Fields>;
  nodes: Array<Developer_Agent>;
};

/** aggregate fields of "developer_agent" */
export type Developer_Agent_Aggregate_Fields = {
__typename?: 'developer_agent_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Developer_Agent_Max_Fields>;
  min?: Maybe<Developer_Agent_Min_Fields>;
};
export type Developer_Agent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Developer_Agent_Max_Fields = {
__typename?: 'developer_agent_max_fields';

  agent_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  developer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Developer_Agent_Min_Fields = {
__typename?: 'developer_agent_min_fields';

  agent_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  developer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "developer_agent" */
export type Developer_Agent_Mutation_Response = {
__typename?: 'developer_agent_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Developer_Agent>;
};

/** Stores each contact's ID proof details to be verified. */
export type Document = {
__typename?: 'document';

  /** An array relationship */
  acl_contact_agent_member: Array<Acl_Contact_Agent_Member>;
  /** An aggregate relationship */
  acl_contact_agent_member_aggregate: Acl_Contact_Agent_Member_Aggregate;
  /** An array relationship */
  acl_contact_developer_member: Array<Acl_Contact_Developer_Member>;
  /** An aggregate relationship */
  acl_contact_developer_member_aggregate: Acl_Contact_Developer_Member_Aggregate;
  /** An object relationship */
  attachment?: Maybe<Attachment>;
  attachment_id?: Maybe<Scalars['uuid']>;
  birth_date?: Maybe<Scalars['date']>;
  card_expiry?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  card_number?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  card_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  expiry_date?: Maybe<Scalars['date']>;
  /** This field is no longer in use. will be removed */
  family_name?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name1?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name2?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name3?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name4?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  gender?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  given_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Int']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  licence_attempt: Scalars['Int'];
  /** This field is no longer in use. will be removed */
  licence_number?: Maybe<Scalars['String']>;
  medicare_attempt: Scalars['Int'];
  /** This field is no longer in use. will be removed */
  middle_name?: Maybe<Scalars['String']>;
  passport_attempt: Scalars['Int'];
  response?: Maybe<Scalars['jsonb']>;
  /** This field is no longer in use. will be removed */
  state_of_issue?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  travel_document_number?: Maybe<Scalars['String']>;
  type: Document_Type_Enum;
  updated_at: Scalars['timestamptz'];
  verification_request_number?: Maybe<Scalars['String']>;
};
export type DocumentAcl_Contact_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type DocumentAcl_Contact_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type DocumentAcl_Contact_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type DocumentAcl_Contact_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type DocumentResponseArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "document" */
export type Document_Aggregate = {
__typename?: 'document_aggregate';

  aggregate?: Maybe<Document_Aggregate_Fields>;
  nodes: Array<Document>;
};

/** aggregate fields of "document" */
export type Document_Aggregate_Fields = {
__typename?: 'document_aggregate_fields';

  avg?: Maybe<Document_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Document_Max_Fields>;
  min?: Maybe<Document_Min_Fields>;
  stddev?: Maybe<Document_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Sum_Fields>;
  var_pop?: Maybe<Document_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Var_Samp_Fields>;
  variance?: Maybe<Document_Variance_Fields>;
};
export type Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Document_Avg_Fields = {
__typename?: 'document_avg_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** Used by Lambda function which runs every day and removes documents which are in this view. */
export type Document_Expired = {
__typename?: 'document_expired';

  attachment_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "document_expired" */
export type Document_Expired_Aggregate = {
__typename?: 'document_expired_aggregate';

  aggregate?: Maybe<Document_Expired_Aggregate_Fields>;
  nodes: Array<Document_Expired>;
};

/** aggregate fields of "document_expired" */
export type Document_Expired_Aggregate_Fields = {
__typename?: 'document_expired_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Document_Expired_Max_Fields>;
  min?: Maybe<Document_Expired_Min_Fields>;
};
export type Document_Expired_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Expired_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Document_Expired_Max_Fields = {
__typename?: 'document_expired_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Document_Expired_Min_Fields = {
__typename?: 'document_expired_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Document_Max_Fields = {
__typename?: 'document_max_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  birth_date?: Maybe<Scalars['date']>;
  card_expiry?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  card_number?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  card_type?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  expiry_date?: Maybe<Scalars['date']>;
  /** This field is no longer in use. will be removed */
  family_name?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name1?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name2?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name3?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name4?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  gender?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Int']>;
  licence_attempt?: Maybe<Scalars['Int']>;
  /** This field is no longer in use. will be removed */
  licence_number?: Maybe<Scalars['String']>;
  medicare_attempt?: Maybe<Scalars['Int']>;
  /** This field is no longer in use. will be removed */
  middle_name?: Maybe<Scalars['String']>;
  passport_attempt?: Maybe<Scalars['Int']>;
  /** This field is no longer in use. will be removed */
  state_of_issue?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  travel_document_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_request_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Document_Min_Fields = {
__typename?: 'document_min_fields';

  attachment_id?: Maybe<Scalars['uuid']>;
  birth_date?: Maybe<Scalars['date']>;
  card_expiry?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  card_number?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  card_type?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  expiry_date?: Maybe<Scalars['date']>;
  /** This field is no longer in use. will be removed */
  family_name?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name1?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name2?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name3?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  full_name4?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  gender?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Int']>;
  licence_attempt?: Maybe<Scalars['Int']>;
  /** This field is no longer in use. will be removed */
  licence_number?: Maybe<Scalars['String']>;
  medicare_attempt?: Maybe<Scalars['Int']>;
  /** This field is no longer in use. will be removed */
  middle_name?: Maybe<Scalars['String']>;
  passport_attempt?: Maybe<Scalars['Int']>;
  /** This field is no longer in use. will be removed */
  state_of_issue?: Maybe<Scalars['String']>;
  /** This field is no longer in use. will be removed */
  travel_document_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verification_request_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "document" */
export type Document_Mutation_Response = {
__typename?: 'document_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document>;
};

/** aggregate stddev on columns */
export type Document_Stddev_Fields = {
__typename?: 'document_stddev_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Document_Stddev_Pop_Fields = {
__typename?: 'document_stddev_pop_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Document_Stddev_Samp_Fields = {
__typename?: 'document_stddev_samp_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Document_Sum_Fields = {
__typename?: 'document_sum_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Int']>;
  licence_attempt?: Maybe<Scalars['Int']>;
  medicare_attempt?: Maybe<Scalars['Int']>;
  passport_attempt?: Maybe<Scalars['Int']>;
};

/** Stores document type values. */
export type Document_Type = {
__typename?: 'document_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "document_type" */
export type Document_Type_Aggregate = {
__typename?: 'document_type_aggregate';

  aggregate?: Maybe<Document_Type_Aggregate_Fields>;
  nodes: Array<Document_Type>;
};

/** aggregate fields of "document_type" */
export type Document_Type_Aggregate_Fields = {
__typename?: 'document_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Document_Type_Max_Fields>;
  min?: Maybe<Document_Type_Min_Fields>;
};
export type Document_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Document_Type_Max_Fields = {
__typename?: 'document_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Document_Type_Min_Fields = {
__typename?: 'document_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "document_type" */
export type Document_Type_Mutation_Response = {
__typename?: 'document_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Type>;
};

/** aggregate var_pop on columns */
export type Document_Var_Pop_Fields = {
__typename?: 'document_var_pop_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Document_Var_Samp_Fields = {
__typename?: 'document_var_samp_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Document_Variance_Fields = {
__typename?: 'document_variance_fields';

  /** This field is no longer in use. will be removed */
  individual_reference_number?: Maybe<Scalars['Float']>;
  licence_attempt?: Maybe<Scalars['Float']>;
  medicare_attempt?: Maybe<Scalars['Float']>;
  passport_attempt?: Maybe<Scalars['Float']>;
};

/** Plan Explorer embedable component records */
export type Embed = {
__typename?: 'embed';

  config: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by_team?: Maybe<Organisation>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  created_by_user: User;
  created_by_user_id: Scalars['uuid'];
  /** An object relationship */
  embed_user: User;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};
export type EmbedConfigArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "embed" */
export type Embed_Aggregate = {
__typename?: 'embed_aggregate';

  aggregate?: Maybe<Embed_Aggregate_Fields>;
  nodes: Array<Embed>;
};

/** aggregate fields of "embed" */
export type Embed_Aggregate_Fields = {
__typename?: 'embed_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Embed_Max_Fields>;
  min?: Maybe<Embed_Min_Fields>;
};
export type Embed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Embed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Embed_Max_Fields = {
__typename?: 'embed_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Embed_Min_Fields = {
__typename?: 'embed_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "embed" */
export type Embed_Mutation_Response = {
__typename?: 'embed_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Embed>;
};

/** Annature envelopes connected to the deal (digital contracts) */
export type Envelope = {
__typename?: 'envelope';

  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** An object relationship */
  agency_agreement?: Maybe<Agency_Agreement>;
  completed_redirect_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  deal?: Maybe<Deal>;
  declined_redirect_url?: Maybe<Scalars['String']>;
  envelope_id?: Maybe<Scalars['String']>;
  envelope_request?: Maybe<Scalars['jsonb']>;
  envelope_response?: Maybe<Scalars['jsonb']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  issuer_organisation?: Maybe<Organisation>;
  owner: Envelope_Owner_Enum;
  owner_id?: Maybe<Scalars['uuid']>;
  recipient_status?: Maybe<Scalars['jsonb']>;
  status: Envelope_Status_Enum;
  type: Envelope_Type_Enum;
  updated_at: Scalars['timestamptz'];
  void_reason?: Maybe<Scalars['String']>;
  voided_by_team_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  voided_by_user?: Maybe<User>;
  voided_by_user_id?: Maybe<Scalars['uuid']>;
};
export type EnvelopeAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type EnvelopeAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type EnvelopeEnvelope_RequestArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type EnvelopeEnvelope_ResponseArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type EnvelopeRecipient_StatusArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "envelope" */
export type Envelope_Aggregate = {
__typename?: 'envelope_aggregate';

  aggregate?: Maybe<Envelope_Aggregate_Fields>;
  nodes: Array<Envelope>;
};

/** aggregate fields of "envelope" */
export type Envelope_Aggregate_Fields = {
__typename?: 'envelope_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Envelope_Max_Fields>;
  min?: Maybe<Envelope_Min_Fields>;
};
export type Envelope_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Envelope_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Envelope_Max_Fields = {
__typename?: 'envelope_max_fields';

  completed_redirect_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  declined_redirect_url?: Maybe<Scalars['String']>;
  envelope_id?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  void_reason?: Maybe<Scalars['String']>;
  voided_by_team_id?: Maybe<Scalars['uuid']>;
  voided_by_user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Envelope_Min_Fields = {
__typename?: 'envelope_min_fields';

  completed_redirect_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  declined_redirect_url?: Maybe<Scalars['String']>;
  envelope_id?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  void_reason?: Maybe<Scalars['String']>;
  voided_by_team_id?: Maybe<Scalars['uuid']>;
  voided_by_user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "envelope" */
export type Envelope_Mutation_Response = {
__typename?: 'envelope_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Envelope>;
};

/** Which table can have a relationship with the envelope table? */
export type Envelope_Owner = {
__typename?: 'envelope_owner';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "envelope_owner" */
export type Envelope_Owner_Aggregate = {
__typename?: 'envelope_owner_aggregate';

  aggregate?: Maybe<Envelope_Owner_Aggregate_Fields>;
  nodes: Array<Envelope_Owner>;
};

/** aggregate fields of "envelope_owner" */
export type Envelope_Owner_Aggregate_Fields = {
__typename?: 'envelope_owner_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Envelope_Owner_Max_Fields>;
  min?: Maybe<Envelope_Owner_Min_Fields>;
};
export type Envelope_Owner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Envelope_Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Envelope_Owner_Max_Fields = {
__typename?: 'envelope_owner_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Envelope_Owner_Min_Fields = {
__typename?: 'envelope_owner_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "envelope_owner" */
export type Envelope_Owner_Mutation_Response = {
__typename?: 'envelope_owner_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Envelope_Owner>;
};

/** columns and relationships of "envelope_status" */
export type Envelope_Status = {
__typename?: 'envelope_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "envelope_status" */
export type Envelope_Status_Aggregate = {
__typename?: 'envelope_status_aggregate';

  aggregate?: Maybe<Envelope_Status_Aggregate_Fields>;
  nodes: Array<Envelope_Status>;
};

/** aggregate fields of "envelope_status" */
export type Envelope_Status_Aggregate_Fields = {
__typename?: 'envelope_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Envelope_Status_Max_Fields>;
  min?: Maybe<Envelope_Status_Min_Fields>;
};
export type Envelope_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Envelope_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Envelope_Status_Max_Fields = {
__typename?: 'envelope_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Envelope_Status_Min_Fields = {
__typename?: 'envelope_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "envelope_status" */
export type Envelope_Status_Mutation_Response = {
__typename?: 'envelope_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Envelope_Status>;
};

/** Every type of the envelope have it's own use case */
export type Envelope_Type = {
__typename?: 'envelope_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "envelope_type" */
export type Envelope_Type_Aggregate = {
__typename?: 'envelope_type_aggregate';

  aggregate?: Maybe<Envelope_Type_Aggregate_Fields>;
  nodes: Array<Envelope_Type>;
};

/** aggregate fields of "envelope_type" */
export type Envelope_Type_Aggregate_Fields = {
__typename?: 'envelope_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Envelope_Type_Max_Fields>;
  min?: Maybe<Envelope_Type_Min_Fields>;
};
export type Envelope_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Envelope_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Envelope_Type_Max_Fields = {
__typename?: 'envelope_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Envelope_Type_Min_Fields = {
__typename?: 'envelope_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "envelope_type" */
export type Envelope_Type_Mutation_Response = {
__typename?: 'envelope_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Envelope_Type>;
};

/** Experiments we can enable on ProjectRE. */
export type Experiment = {
__typename?: 'experiment';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "experiment" */
export type Experiment_Aggregate = {
__typename?: 'experiment_aggregate';

  aggregate?: Maybe<Experiment_Aggregate_Fields>;
  nodes: Array<Experiment>;
};

/** aggregate fields of "experiment" */
export type Experiment_Aggregate_Fields = {
__typename?: 'experiment_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Experiment_Max_Fields>;
  min?: Maybe<Experiment_Min_Fields>;
};
export type Experiment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Experiment_Max_Fields = {
__typename?: 'experiment_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Experiment_Min_Fields = {
__typename?: 'experiment_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "experiment" */
export type Experiment_Mutation_Response = {
__typename?: 'experiment_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Experiment>;
};

/** columns and relationships of "feature" */
export type Feature = {
__typename?: 'feature';

  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "feature" */
export type Feature_Aggregate = {
__typename?: 'feature_aggregate';

  aggregate?: Maybe<Feature_Aggregate_Fields>;
  nodes: Array<Feature>;
};

/** aggregate fields of "feature" */
export type Feature_Aggregate_Fields = {
__typename?: 'feature_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Feature_Max_Fields>;
  min?: Maybe<Feature_Min_Fields>;
};
export type Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** Table so hasura can use org_feature_check function */
export type Feature_Check = {
__typename?: 'feature_check';

  result: Scalars['Boolean'];
};

/** aggregated selection of "feature_check" */
export type Feature_Check_Aggregate = {
__typename?: 'feature_check_aggregate';

  aggregate?: Maybe<Feature_Check_Aggregate_Fields>;
  nodes: Array<Feature_Check>;
};

/** aggregate fields of "feature_check" */
export type Feature_Check_Aggregate_Fields = {
__typename?: 'feature_check_aggregate_fields';

  count: Scalars['Int'];
};
export type Feature_Check_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Check_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** response of any mutation on the table "feature_check" */
export type Feature_Check_Mutation_Response = {
__typename?: 'feature_check_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Check>;
};

/** aggregate max on columns */
export type Feature_Max_Fields = {
__typename?: 'feature_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feature_Min_Fields = {
__typename?: 'feature_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feature" */
export type Feature_Mutation_Response = {
__typename?: 'feature_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature>;
};

/** Feature pack contains features and can be assigned to an org */
export type Feature_Pack = {
__typename?: 'feature_pack';

  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An array relationship */
  features: Array<Feature_Pack_Feature>;
  /** An aggregate relationship */
  features_aggregate: Feature_Pack_Feature_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  org_feature_pack: Array<Org_Feature_Pack>;
  /** An aggregate relationship */
  org_feature_pack_aggregate: Org_Feature_Pack_Aggregate;
  updated_at: Scalars['timestamptz'];
};
export type Feature_PackFeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Feature_PackFeatures_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Feature_PackOrg_Feature_PackArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type Feature_PackOrg_Feature_Pack_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};


/** aggregated selection of "feature_pack" */
export type Feature_Pack_Aggregate = {
__typename?: 'feature_pack_aggregate';

  aggregate?: Maybe<Feature_Pack_Aggregate_Fields>;
  nodes: Array<Feature_Pack>;
};

/** aggregate fields of "feature_pack" */
export type Feature_Pack_Aggregate_Fields = {
__typename?: 'feature_pack_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Feature_Pack_Max_Fields>;
  min?: Maybe<Feature_Pack_Min_Fields>;
};
export type Feature_Pack_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Pack_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** Features included into a feature pack */
export type Feature_Pack_Feature = {
__typename?: 'feature_pack_feature';

  created_at: Scalars['timestamptz'];
  feature: Web_App_Feature_Enum;
  feature_pack_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  web_app_feature?: Maybe<Web_App_Feature>;
};

/** aggregated selection of "feature_pack_feature" */
export type Feature_Pack_Feature_Aggregate = {
__typename?: 'feature_pack_feature_aggregate';

  aggregate?: Maybe<Feature_Pack_Feature_Aggregate_Fields>;
  nodes: Array<Feature_Pack_Feature>;
};

/** aggregate fields of "feature_pack_feature" */
export type Feature_Pack_Feature_Aggregate_Fields = {
__typename?: 'feature_pack_feature_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Feature_Pack_Feature_Max_Fields>;
  min?: Maybe<Feature_Pack_Feature_Min_Fields>;
};
export type Feature_Pack_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Feature_Pack_Feature_Max_Fields = {
__typename?: 'feature_pack_feature_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature_pack_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Feature_Pack_Feature_Min_Fields = {
__typename?: 'feature_pack_feature_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature_pack_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "feature_pack_feature" */
export type Feature_Pack_Feature_Mutation_Response = {
__typename?: 'feature_pack_feature_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Pack_Feature>;
};

/** aggregate max on columns */
export type Feature_Pack_Max_Fields = {
__typename?: 'feature_pack_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Feature_Pack_Min_Fields = {
__typename?: 'feature_pack_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "feature_pack" */
export type Feature_Pack_Mutation_Response = {
__typename?: 'feature_pack_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Pack>;
};

/** filter page name */
export type Filter_Page = {
__typename?: 'filter_page';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "filter_page" */
export type Filter_Page_Aggregate = {
__typename?: 'filter_page_aggregate';

  aggregate?: Maybe<Filter_Page_Aggregate_Fields>;
  nodes: Array<Filter_Page>;
};

/** aggregate fields of "filter_page" */
export type Filter_Page_Aggregate_Fields = {
__typename?: 'filter_page_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Filter_Page_Max_Fields>;
  min?: Maybe<Filter_Page_Min_Fields>;
};
export type Filter_Page_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Filter_Page_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Filter_Page_Max_Fields = {
__typename?: 'filter_page_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Page_Min_Fields = {
__typename?: 'filter_page_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_page" */
export type Filter_Page_Mutation_Response = {
__typename?: 'filter_page_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Page>;
};

/** store filter state based on user and team and page */
export type Filter_State = {
__typename?: 'filter_state';

  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  page: Filter_Page_Enum;
  state: Scalars['jsonb'];
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};
export type Filter_StateStateArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "filter_state" */
export type Filter_State_Aggregate = {
__typename?: 'filter_state_aggregate';

  aggregate?: Maybe<Filter_State_Aggregate_Fields>;
  nodes: Array<Filter_State>;
};

/** aggregate fields of "filter_state" */
export type Filter_State_Aggregate_Fields = {
__typename?: 'filter_state_aggregate_fields';

  avg?: Maybe<Filter_State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filter_State_Max_Fields>;
  min?: Maybe<Filter_State_Min_Fields>;
  stddev?: Maybe<Filter_State_Stddev_Fields>;
  stddev_pop?: Maybe<Filter_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filter_State_Stddev_Samp_Fields>;
  sum?: Maybe<Filter_State_Sum_Fields>;
  var_pop?: Maybe<Filter_State_Var_Pop_Fields>;
  var_samp?: Maybe<Filter_State_Var_Samp_Fields>;
  variance?: Maybe<Filter_State_Variance_Fields>;
};
export type Filter_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Filter_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Filter_State_Avg_Fields = {
__typename?: 'filter_state_avg_fields';

  id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Filter_State_Max_Fields = {
__typename?: 'filter_state_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Filter_State_Min_Fields = {
__typename?: 'filter_state_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "filter_state" */
export type Filter_State_Mutation_Response = {
__typename?: 'filter_state_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_State>;
};

/** aggregate stddev on columns */
export type Filter_State_Stddev_Fields = {
__typename?: 'filter_state_stddev_fields';

  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Filter_State_Stddev_Pop_Fields = {
__typename?: 'filter_state_stddev_pop_fields';

  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Filter_State_Stddev_Samp_Fields = {
__typename?: 'filter_state_stddev_samp_fields';

  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Filter_State_Sum_Fields = {
__typename?: 'filter_state_sum_fields';

  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Filter_State_Var_Pop_Fields = {
__typename?: 'filter_state_var_pop_fields';

  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Filter_State_Var_Samp_Fields = {
__typename?: 'filter_state_var_samp_fields';

  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Filter_State_Variance_Fields = {
__typename?: 'filter_state_variance_fields';

  id?: Maybe<Scalars['Float']>;
};

/** Finance Status */
export type Finance_Status = {
__typename?: 'finance_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "finance_status" */
export type Finance_Status_Aggregate = {
__typename?: 'finance_status_aggregate';

  aggregate?: Maybe<Finance_Status_Aggregate_Fields>;
  nodes: Array<Finance_Status>;
};

/** aggregate fields of "finance_status" */
export type Finance_Status_Aggregate_Fields = {
__typename?: 'finance_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Finance_Status_Max_Fields>;
  min?: Maybe<Finance_Status_Min_Fields>;
};
export type Finance_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Finance_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Finance_Status_Max_Fields = {
__typename?: 'finance_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Finance_Status_Min_Fields = {
__typename?: 'finance_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "finance_status" */
export type Finance_Status_Mutation_Response = {
__typename?: 'finance_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Finance_Status>;
};

/** GraphQL query stats sent from the frontend  */
export type Graphql_Query_Log = {
__typename?: 'graphql_query_log';

  created_at: Scalars['timestamptz'];
  created_by_role?: Maybe<Scalars['String']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_team_role?: Maybe<Scalars['String']>;
  created_by_team_type?: Maybe<Scalars['String']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  duration: Scalars['Int'];
  finished_at: Scalars['bigint'];
  id: Scalars['Int'];
  op_content: Scalars['String'];
  op_name: Scalars['String'];
  op_vars: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  started_at: Scalars['bigint'];
};

/** aggregated selection of "graphql_query_log" */
export type Graphql_Query_Log_Aggregate = {
__typename?: 'graphql_query_log_aggregate';

  aggregate?: Maybe<Graphql_Query_Log_Aggregate_Fields>;
  nodes: Array<Graphql_Query_Log>;
};

/** aggregate fields of "graphql_query_log" */
export type Graphql_Query_Log_Aggregate_Fields = {
__typename?: 'graphql_query_log_aggregate_fields';

  avg?: Maybe<Graphql_Query_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graphql_Query_Log_Max_Fields>;
  min?: Maybe<Graphql_Query_Log_Min_Fields>;
  stddev?: Maybe<Graphql_Query_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Graphql_Query_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graphql_Query_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Graphql_Query_Log_Sum_Fields>;
  var_pop?: Maybe<Graphql_Query_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Graphql_Query_Log_Var_Samp_Fields>;
  variance?: Maybe<Graphql_Query_Log_Variance_Fields>;
};
export type Graphql_Query_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Graphql_Query_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Graphql_Query_Log_Avg_Fields = {
__typename?: 'graphql_query_log_avg_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Graphql_Query_Log_Max_Fields = {
__typename?: 'graphql_query_log_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_role?: Maybe<Scalars['String']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_team_role?: Maybe<Scalars['String']>;
  created_by_team_type?: Maybe<Scalars['String']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  finished_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  op_content?: Maybe<Scalars['String']>;
  op_name?: Maybe<Scalars['String']>;
  op_vars?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Graphql_Query_Log_Min_Fields = {
__typename?: 'graphql_query_log_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_role?: Maybe<Scalars['String']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_team_role?: Maybe<Scalars['String']>;
  created_by_team_type?: Maybe<Scalars['String']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  duration?: Maybe<Scalars['Int']>;
  finished_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  op_content?: Maybe<Scalars['String']>;
  op_name?: Maybe<Scalars['String']>;
  op_vars?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "graphql_query_log" */
export type Graphql_Query_Log_Mutation_Response = {
__typename?: 'graphql_query_log_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graphql_Query_Log>;
};

/** aggregate stddev on columns */
export type Graphql_Query_Log_Stddev_Fields = {
__typename?: 'graphql_query_log_stddev_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graphql_Query_Log_Stddev_Pop_Fields = {
__typename?: 'graphql_query_log_stddev_pop_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graphql_Query_Log_Stddev_Samp_Fields = {
__typename?: 'graphql_query_log_stddev_samp_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graphql_Query_Log_Sum_Fields = {
__typename?: 'graphql_query_log_sum_fields';

  duration?: Maybe<Scalars['Int']>;
  finished_at?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Graphql_Query_Log_Var_Pop_Fields = {
__typename?: 'graphql_query_log_var_pop_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graphql_Query_Log_Var_Samp_Fields = {
__typename?: 'graphql_query_log_var_samp_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graphql_Query_Log_Variance_Fields = {
__typename?: 'graphql_query_log_variance_fields';

  duration?: Maybe<Scalars['Float']>;
  finished_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  started_at?: Maybe<Scalars['Float']>;
};

/** Queue of actions to be performed on HubSpot */
export type Hubspot_Action = {
__typename?: 'hubspot_action';

  created_at: Scalars['timestamptz'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  payload: Scalars['jsonb'];
  status: Process_Status_Enum;
  type: Hubspot_Action_Type_Enum;
  updated_at: Scalars['timestamptz'];
};
export type Hubspot_ActionPayloadArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "hubspot_action" */
export type Hubspot_Action_Aggregate = {
__typename?: 'hubspot_action_aggregate';

  aggregate?: Maybe<Hubspot_Action_Aggregate_Fields>;
  nodes: Array<Hubspot_Action>;
};

/** aggregate fields of "hubspot_action" */
export type Hubspot_Action_Aggregate_Fields = {
__typename?: 'hubspot_action_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Action_Max_Fields>;
  min?: Maybe<Hubspot_Action_Min_Fields>;
};
export type Hubspot_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Action_Max_Fields = {
__typename?: 'hubspot_action_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hubspot_Action_Min_Fields = {
__typename?: 'hubspot_action_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspot_action" */
export type Hubspot_Action_Mutation_Response = {
__typename?: 'hubspot_action_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Action>;
};

/** Types of supported HubSpot up-sync actions */
export type Hubspot_Action_Type = {
__typename?: 'hubspot_action_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "hubspot_action_type" */
export type Hubspot_Action_Type_Aggregate = {
__typename?: 'hubspot_action_type_aggregate';

  aggregate?: Maybe<Hubspot_Action_Type_Aggregate_Fields>;
  nodes: Array<Hubspot_Action_Type>;
};

/** aggregate fields of "hubspot_action_type" */
export type Hubspot_Action_Type_Aggregate_Fields = {
__typename?: 'hubspot_action_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Action_Type_Max_Fields>;
  min?: Maybe<Hubspot_Action_Type_Min_Fields>;
};
export type Hubspot_Action_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Action_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Action_Type_Max_Fields = {
__typename?: 'hubspot_action_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hubspot_Action_Type_Min_Fields = {
__typename?: 'hubspot_action_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hubspot_action_type" */
export type Hubspot_Action_Type_Mutation_Response = {
__typename?: 'hubspot_action_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Action_Type>;
};

/** Relationship between ProjectRE contacts and HubSpot contact objects */
export type Hubspot_Contact = {
__typename?: 'hubspot_contact';

  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  hs_id: Scalars['String'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "hubspot_contact" */
export type Hubspot_Contact_Aggregate = {
__typename?: 'hubspot_contact_aggregate';

  aggregate?: Maybe<Hubspot_Contact_Aggregate_Fields>;
  nodes: Array<Hubspot_Contact>;
};

/** aggregate fields of "hubspot_contact" */
export type Hubspot_Contact_Aggregate_Fields = {
__typename?: 'hubspot_contact_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Contact_Max_Fields>;
  min?: Maybe<Hubspot_Contact_Min_Fields>;
};
export type Hubspot_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Contact_Max_Fields = {
__typename?: 'hubspot_contact_max_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hubspot_Contact_Min_Fields = {
__typename?: 'hubspot_contact_min_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspot_contact" */
export type Hubspot_Contact_Mutation_Response = {
__typename?: 'hubspot_contact_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Contact>;
};

/** Relationship between ProjectRE deals and HubSpot deals objects */
export type Hubspot_Deal = {
__typename?: 'hubspot_deal';

  created_at: Scalars['timestamptz'];
  deal_id: Scalars['uuid'];
  hs_id: Scalars['String'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "hubspot_deal" */
export type Hubspot_Deal_Aggregate = {
__typename?: 'hubspot_deal_aggregate';

  aggregate?: Maybe<Hubspot_Deal_Aggregate_Fields>;
  nodes: Array<Hubspot_Deal>;
};

/** aggregate fields of "hubspot_deal" */
export type Hubspot_Deal_Aggregate_Fields = {
__typename?: 'hubspot_deal_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Deal_Max_Fields>;
  min?: Maybe<Hubspot_Deal_Min_Fields>;
};
export type Hubspot_Deal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Deal_Max_Fields = {
__typename?: 'hubspot_deal_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hubspot_Deal_Min_Fields = {
__typename?: 'hubspot_deal_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspot_deal" */
export type Hubspot_Deal_Mutation_Response = {
__typename?: 'hubspot_deal_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Deal>;
};

/** Stores organisation's HubSpot IDs from multiple HubSpot instances */
export type Hubspot_Organisation = {
__typename?: 'hubspot_organisation';

  created_at: Scalars['timestamptz'];
  hs_id: Scalars['String'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  org_id: Scalars['uuid'];
  /** An object relationship */
  organisation: Organisation;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "hubspot_organisation" */
export type Hubspot_Organisation_Aggregate = {
__typename?: 'hubspot_organisation_aggregate';

  aggregate?: Maybe<Hubspot_Organisation_Aggregate_Fields>;
  nodes: Array<Hubspot_Organisation>;
};

/** aggregate fields of "hubspot_organisation" */
export type Hubspot_Organisation_Aggregate_Fields = {
__typename?: 'hubspot_organisation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Organisation_Max_Fields>;
  min?: Maybe<Hubspot_Organisation_Min_Fields>;
};
export type Hubspot_Organisation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Organisation_Max_Fields = {
__typename?: 'hubspot_organisation_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hubspot_Organisation_Min_Fields = {
__typename?: 'hubspot_organisation_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspot_organisation" */
export type Hubspot_Organisation_Mutation_Response = {
__typename?: 'hubspot_organisation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Organisation>;
};

/** Relationship between ProjectRE projects and HubSpot project objects */
export type Hubspot_Project = {
__typename?: 'hubspot_project';

  created_at: Scalars['timestamptz'];
  hs_id: Scalars['String'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "hubspot_project" */
export type Hubspot_Project_Aggregate = {
__typename?: 'hubspot_project_aggregate';

  aggregate?: Maybe<Hubspot_Project_Aggregate_Fields>;
  nodes: Array<Hubspot_Project>;
};

/** aggregate fields of "hubspot_project" */
export type Hubspot_Project_Aggregate_Fields = {
__typename?: 'hubspot_project_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Project_Max_Fields>;
  min?: Maybe<Hubspot_Project_Min_Fields>;
};
export type Hubspot_Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Project_Max_Fields = {
__typename?: 'hubspot_project_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hubspot_Project_Min_Fields = {
__typename?: 'hubspot_project_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspot_project" */
export type Hubspot_Project_Mutation_Response = {
__typename?: 'hubspot_project_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Project>;
};

/** Store hubspot schema sync errors */
export type Hubspot_Schema_Error_Log = {
__typename?: 'hubspot_schema_error_log';

  created_at: Scalars['timestamptz'];
  error: Scalars['jsonb'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};
export type Hubspot_Schema_Error_LogErrorArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_Aggregate = {
__typename?: 'hubspot_schema_error_log_aggregate';

  aggregate?: Maybe<Hubspot_Schema_Error_Log_Aggregate_Fields>;
  nodes: Array<Hubspot_Schema_Error_Log>;
};

/** aggregate fields of "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_Aggregate_Fields = {
__typename?: 'hubspot_schema_error_log_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Schema_Error_Log_Max_Fields>;
  min?: Maybe<Hubspot_Schema_Error_Log_Min_Fields>;
};
export type Hubspot_Schema_Error_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Schema_Error_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Schema_Error_Log_Max_Fields = {
__typename?: 'hubspot_schema_error_log_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Hubspot_Schema_Error_Log_Min_Fields = {
__typename?: 'hubspot_schema_error_log_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspot_schema_error_log" */
export type Hubspot_Schema_Error_Log_Mutation_Response = {
__typename?: 'hubspot_schema_error_log_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Schema_Error_Log>;
};

/** Relationship between HubSpot contact objects and ProjectRE user records */
export type Hubspot_User = {
__typename?: 'hubspot_user';

  created_at: Scalars['timestamptz'];
  hs_id: Scalars['String'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "hubspot_user" */
export type Hubspot_User_Aggregate = {
__typename?: 'hubspot_user_aggregate';

  aggregate?: Maybe<Hubspot_User_Aggregate_Fields>;
  nodes: Array<Hubspot_User>;
};

/** aggregate fields of "hubspot_user" */
export type Hubspot_User_Aggregate_Fields = {
__typename?: 'hubspot_user_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_User_Max_Fields>;
  min?: Maybe<Hubspot_User_Min_Fields>;
};
export type Hubspot_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_User_Max_Fields = {
__typename?: 'hubspot_user_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Hubspot_User_Min_Fields = {
__typename?: 'hubspot_user_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hubspot_user" */
export type Hubspot_User_Mutation_Response = {
__typename?: 'hubspot_user_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_User>;
};

/** Deal Valuation Bookings synced to HubSpot */
export type Hubspot_Valuation_Booking = {
__typename?: 'hubspot_valuation_booking';

  created_at: Scalars['timestamptz'];
  hs_id: Scalars['String'];
  hs_instance_id: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  valuation_booking_id: Scalars['uuid'];
};

/** aggregated selection of "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Aggregate = {
__typename?: 'hubspot_valuation_booking_aggregate';

  aggregate?: Maybe<Hubspot_Valuation_Booking_Aggregate_Fields>;
  nodes: Array<Hubspot_Valuation_Booking>;
};

/** aggregate fields of "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Aggregate_Fields = {
__typename?: 'hubspot_valuation_booking_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Hubspot_Valuation_Booking_Max_Fields>;
  min?: Maybe<Hubspot_Valuation_Booking_Min_Fields>;
};
export type Hubspot_Valuation_Booking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Hubspot_Valuation_Booking_Max_Fields = {
__typename?: 'hubspot_valuation_booking_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valuation_booking_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Hubspot_Valuation_Booking_Min_Fields = {
__typename?: 'hubspot_valuation_booking_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  hs_id?: Maybe<Scalars['String']>;
  hs_instance_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valuation_booking_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hubspot_valuation_booking" */
export type Hubspot_Valuation_Booking_Mutation_Response = {
__typename?: 'hubspot_valuation_booking_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hubspot_Valuation_Booking>;
};

/** columns and relationships of "invitation" */
export type Invitation = {
__typename?: 'invitation';

  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  email: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  invitation_status: Invitation_Status;
  /** An object relationship */
  invitation_type: Invitation_Type;
  /** An array relationship */
  invitedByEmail: Array<User>;
  /** An aggregate relationship */
  invitedByEmail_aggregate: User_Aggregate;
  invitee_firstname?: Maybe<Scalars['String']>;
  invitee_lastname?: Maybe<Scalars['String']>;
  /** This phone number is used to auto fill phone number in the phone verification step during sign-up via invitation link */
  invitee_phone_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  inviter: User;
  inviter_firstname?: Maybe<Scalars['String']>;
  inviter_lastname?: Maybe<Scalars['String']>;
  /** An object relationship */
  member_role: Member_Role;
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  organisation_id?: Maybe<Scalars['uuid']>;
  organisation_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  partner_portal?: Maybe<Partner_Portal>;
  /** Used to link USER invitations with Partner Portal submissions. So we can properly handle user/contact creation during USER sign up for invitations created via Partner Portal */
  partner_portal_id?: Maybe<Scalars['uuid']>;
  role: Member_Role_Enum;
  status: Invitation_Status_Enum;
  type: Invitation_Type_Enum;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};
export type InvitationAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type InvitationAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type InvitationInvitedByEmailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};

export type InvitationInvitedByEmail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};


/** aggregated selection of "invitation" */
export type Invitation_Aggregate = {
__typename?: 'invitation_aggregate';

  aggregate?: Maybe<Invitation_Aggregate_Fields>;
  nodes: Array<Invitation>;
};

/** aggregate fields of "invitation" */
export type Invitation_Aggregate_Fields = {
__typename?: 'invitation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Invitation_Max_Fields>;
  min?: Maybe<Invitation_Min_Fields>;
};
export type Invitation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invitation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Invitation_Max_Fields = {
__typename?: 'invitation_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitee_firstname?: Maybe<Scalars['String']>;
  invitee_lastname?: Maybe<Scalars['String']>;
  /** This phone number is used to auto fill phone number in the phone verification step during sign-up via invitation link */
  invitee_phone_number?: Maybe<Scalars['String']>;
  inviter_firstname?: Maybe<Scalars['String']>;
  inviter_lastname?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  organisation_name?: Maybe<Scalars['String']>;
  /** Used to link USER invitations with Partner Portal submissions. So we can properly handle user/contact creation during USER sign up for invitations created via Partner Portal */
  partner_portal_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Invitation_Min_Fields = {
__typename?: 'invitation_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitee_firstname?: Maybe<Scalars['String']>;
  invitee_lastname?: Maybe<Scalars['String']>;
  /** This phone number is used to auto fill phone number in the phone verification step during sign-up via invitation link */
  invitee_phone_number?: Maybe<Scalars['String']>;
  inviter_firstname?: Maybe<Scalars['String']>;
  inviter_lastname?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  organisation_name?: Maybe<Scalars['String']>;
  /** Used to link USER invitations with Partner Portal submissions. So we can properly handle user/contact creation during USER sign up for invitations created via Partner Portal */
  partner_portal_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "invitation" */
export type Invitation_Mutation_Response = {
__typename?: 'invitation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitation>;
};

/** columns and relationships of "invitation_status" */
export type Invitation_Status = {
__typename?: 'invitation_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "invitation_status" */
export type Invitation_Status_Aggregate = {
__typename?: 'invitation_status_aggregate';

  aggregate?: Maybe<Invitation_Status_Aggregate_Fields>;
  nodes: Array<Invitation_Status>;
};

/** aggregate fields of "invitation_status" */
export type Invitation_Status_Aggregate_Fields = {
__typename?: 'invitation_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Invitation_Status_Max_Fields>;
  min?: Maybe<Invitation_Status_Min_Fields>;
};
export type Invitation_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invitation_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Invitation_Status_Max_Fields = {
__typename?: 'invitation_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invitation_Status_Min_Fields = {
__typename?: 'invitation_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invitation_status" */
export type Invitation_Status_Mutation_Response = {
__typename?: 'invitation_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitation_Status>;
};

/** columns and relationships of "invitation_type" */
export type Invitation_Type = {
__typename?: 'invitation_type';

  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  invitations: Array<Invitation>;
  /** An aggregate relationship */
  invitations_aggregate: Invitation_Aggregate;
  value: Scalars['String'];
};
export type Invitation_TypeInvitationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Invitation_TypeInvitations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};


/** aggregated selection of "invitation_type" */
export type Invitation_Type_Aggregate = {
__typename?: 'invitation_type_aggregate';

  aggregate?: Maybe<Invitation_Type_Aggregate_Fields>;
  nodes: Array<Invitation_Type>;
};

/** aggregate fields of "invitation_type" */
export type Invitation_Type_Aggregate_Fields = {
__typename?: 'invitation_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Invitation_Type_Max_Fields>;
  min?: Maybe<Invitation_Type_Min_Fields>;
};
export type Invitation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invitation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Invitation_Type_Max_Fields = {
__typename?: 'invitation_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invitation_Type_Min_Fields = {
__typename?: 'invitation_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invitation_type" */
export type Invitation_Type_Mutation_Response = {
__typename?: 'invitation_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitation_Type>;
};

/** Track request & response for some lambdas */
export type Lambda_Log = {
__typename?: 'lambda_log';

  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  request: Scalars['jsonb'];
  response: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};
export type Lambda_LogRequestArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type Lambda_LogResponseArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "lambda_log" */
export type Lambda_Log_Aggregate = {
__typename?: 'lambda_log_aggregate';

  aggregate?: Maybe<Lambda_Log_Aggregate_Fields>;
  nodes: Array<Lambda_Log>;
};

/** aggregate fields of "lambda_log" */
export type Lambda_Log_Aggregate_Fields = {
__typename?: 'lambda_log_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Lambda_Log_Max_Fields>;
  min?: Maybe<Lambda_Log_Min_Fields>;
};
export type Lambda_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lambda_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Lambda_Log_Max_Fields = {
__typename?: 'lambda_log_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lambda_Log_Min_Fields = {
__typename?: 'lambda_log_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lambda_log" */
export type Lambda_Log_Mutation_Response = {
__typename?: 'lambda_log_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lambda_Log>;
};

/** columns and relationships of "lead_generation" */
export type Lead_Generation = {
__typename?: 'lead_generation';

  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  lead_generation_webhook_logs: Array<Lead_Generation_Webhook_Log>;
  /** An aggregate relationship */
  lead_generation_webhook_logs_aggregate: Lead_Generation_Webhook_Log_Aggregate;
  lead_owner_id?: Maybe<Scalars['uuid']>;
  organisation_id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  source: Lead_Generation_Source_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
};
export type Lead_GenerationLead_Generation_Webhook_LogsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Webhook_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

export type Lead_GenerationLead_Generation_Webhook_Logs_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Webhook_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};


/** aggregated selection of "lead_generation" */
export type Lead_Generation_Aggregate = {
__typename?: 'lead_generation_aggregate';

  aggregate?: Maybe<Lead_Generation_Aggregate_Fields>;
  nodes: Array<Lead_Generation>;
};

/** aggregate fields of "lead_generation" */
export type Lead_Generation_Aggregate_Fields = {
__typename?: 'lead_generation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Lead_Generation_Max_Fields>;
  min?: Maybe<Lead_Generation_Min_Fields>;
};
export type Lead_Generation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lead_Generation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Lead_Generation_Max_Fields = {
__typename?: 'lead_generation_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead_owner_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lead_Generation_Min_Fields = {
__typename?: 'lead_generation_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lead_owner_id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lead_generation" */
export type Lead_Generation_Mutation_Response = {
__typename?: 'lead_generation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lead_Generation>;
};

/** columns and relationships of "lead_generation_source" */
export type Lead_Generation_Source = {
__typename?: 'lead_generation_source';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "lead_generation_source" */
export type Lead_Generation_Source_Aggregate = {
__typename?: 'lead_generation_source_aggregate';

  aggregate?: Maybe<Lead_Generation_Source_Aggregate_Fields>;
  nodes: Array<Lead_Generation_Source>;
};

/** aggregate fields of "lead_generation_source" */
export type Lead_Generation_Source_Aggregate_Fields = {
__typename?: 'lead_generation_source_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Lead_Generation_Source_Max_Fields>;
  min?: Maybe<Lead_Generation_Source_Min_Fields>;
};
export type Lead_Generation_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lead_Generation_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Lead_Generation_Source_Max_Fields = {
__typename?: 'lead_generation_source_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lead_Generation_Source_Min_Fields = {
__typename?: 'lead_generation_source_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lead_generation_source" */
export type Lead_Generation_Source_Mutation_Response = {
__typename?: 'lead_generation_source_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lead_Generation_Source>;
};

/** store incoming webhook request log */
export type Lead_Generation_Webhook_Log = {
__typename?: 'lead_generation_webhook_log';

  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  lead_generation: Lead_Generation;
  lead_generation_id: Scalars['uuid'];
  request: Scalars['jsonb'];
  status: Process_Status_Enum;
  updated_at: Scalars['timestamptz'];
};
export type Lead_Generation_Webhook_LogRequestArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Aggregate = {
__typename?: 'lead_generation_webhook_log_aggregate';

  aggregate?: Maybe<Lead_Generation_Webhook_Log_Aggregate_Fields>;
  nodes: Array<Lead_Generation_Webhook_Log>;
};

/** aggregate fields of "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Aggregate_Fields = {
__typename?: 'lead_generation_webhook_log_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Lead_Generation_Webhook_Log_Max_Fields>;
  min?: Maybe<Lead_Generation_Webhook_Log_Min_Fields>;
};
export type Lead_Generation_Webhook_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Lead_Generation_Webhook_Log_Max_Fields = {
__typename?: 'lead_generation_webhook_log_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lead_generation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lead_Generation_Webhook_Log_Min_Fields = {
__typename?: 'lead_generation_webhook_log_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lead_generation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lead_generation_webhook_log" */
export type Lead_Generation_Webhook_Log_Mutation_Response = {
__typename?: 'lead_generation_webhook_log_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lead_Generation_Webhook_Log>;
};

/** columns and relationships of "listing" */
export type Listing = {
__typename?: 'listing';

  /** An array relationship */
  acl_allocation_edit: Array<Acl_Allocation_Edit>;
  /** An aggregate relationship */
  acl_allocation_edit_aggregate: Acl_Allocation_Edit_Aggregate;
  /** An array relationship */
  acl_listing_agent_member: Array<Acl_Listing_Agent_Member>;
  /** An aggregate relationship */
  acl_listing_agent_member_aggregate: Acl_Listing_Agent_Member_Aggregate;
  /** An array relationship */
  acl_listing_master_agent: Array<Acl_Listing_Master_Agent>;
  /** An aggregate relationship */
  acl_listing_master_agent_aggregate: Acl_Listing_Master_Agent_Aggregate;
  /** An array relationship */
  acl_listing_property: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_aggregate: Acl_Listing_Property_Aggregate;
  /** An array relationship */
  acl_listing_property_team: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_aggregate: Acl_Listing_Property_Team_Aggregate;
  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  block_date?: Maybe<Scalars['timestamptz']>;
  block_release_date?: Maybe<Scalars['timestamptz']>;
  blocked_by_user?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  blocked_by_user_rel?: Maybe<User>;
  blocked_for_user?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  blocked_for_user_rel?: Maybe<User>;
  car_space?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  id: Scalars['uuid'];
  is_blocked?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  listing_status?: Maybe<Listing_Status>;
  /** An array relationship */
  member_project_allocates: Array<Member_Project_Allocate>;
  /** An aggregate relationship */
  member_project_allocates_aggregate: Member_Project_Allocate_Aggregate;
  price: Scalars['Int'];
  /** An object relationship */
  price_per_sqmt?: Maybe<Listing_Price_Per_Sqmt>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  /** An object relationship */
  property: Property;
  property_id: Scalars['uuid'];
  release_number?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  status?: Maybe<Listing_Status_Enum>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count: Scalars['Int'];
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type ListingAcl_Allocation_EditArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type ListingAcl_Allocation_Edit_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type ListingAcl_Listing_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type ListingAcl_Listing_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type ListingAcl_Listing_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type ListingAcl_Listing_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type ListingAcl_Listing_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type ListingAcl_Listing_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type ListingAcl_Listing_Property_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type ListingAcl_Listing_Property_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type ListingAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type ListingAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type ListingAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type ListingAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type ListingDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ListingDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ListingMember_Project_AllocatesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type ListingMember_Project_Allocates_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};


/** aggregated selection of "listing" */
export type Listing_Aggregate = {
__typename?: 'listing_aggregate';

  aggregate?: Maybe<Listing_Aggregate_Fields>;
  nodes: Array<Listing>;
};

/** aggregate fields of "listing" */
export type Listing_Aggregate_Fields = {
__typename?: 'listing_aggregate_fields';

  avg?: Maybe<Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Listing_Max_Fields>;
  min?: Maybe<Listing_Min_Fields>;
  stddev?: Maybe<Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Listing_Sum_Fields>;
  var_pop?: Maybe<Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Listing_Var_Samp_Fields>;
  variance?: Maybe<Listing_Variance_Fields>;
};
export type Listing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Listing_Avg_Fields = {
__typename?: 'listing_avg_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Listing_Max_Fields = {
__typename?: 'listing_max_fields';

  block_date?: Maybe<Scalars['timestamptz']>;
  block_release_date?: Maybe<Scalars['timestamptz']>;
  blocked_by_user?: Maybe<Scalars['uuid']>;
  blocked_for_user?: Maybe<Scalars['uuid']>;
  car_space?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  release_number?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Int']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Listing_Min_Fields = {
__typename?: 'listing_min_fields';

  block_date?: Maybe<Scalars['timestamptz']>;
  block_release_date?: Maybe<Scalars['timestamptz']>;
  blocked_by_user?: Maybe<Scalars['uuid']>;
  blocked_for_user?: Maybe<Scalars['uuid']>;
  car_space?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  release_number?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Int']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "listing" */
export type Listing_Mutation_Response = {
__typename?: 'listing_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Listing>;
};

/** columns and relationships of "listing_price_per_sqmt" */
export type Listing_Price_Per_Sqmt = {
__typename?: 'listing_price_per_sqmt';

  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  listing?: Maybe<Listing>;
  price_per_sqmt?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "listing_price_per_sqmt" */
export type Listing_Price_Per_Sqmt_Aggregate = {
__typename?: 'listing_price_per_sqmt_aggregate';

  aggregate?: Maybe<Listing_Price_Per_Sqmt_Aggregate_Fields>;
  nodes: Array<Listing_Price_Per_Sqmt>;
};

/** aggregate fields of "listing_price_per_sqmt" */
export type Listing_Price_Per_Sqmt_Aggregate_Fields = {
__typename?: 'listing_price_per_sqmt_aggregate_fields';

  avg?: Maybe<Listing_Price_Per_Sqmt_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Listing_Price_Per_Sqmt_Max_Fields>;
  min?: Maybe<Listing_Price_Per_Sqmt_Min_Fields>;
  stddev?: Maybe<Listing_Price_Per_Sqmt_Stddev_Fields>;
  stddev_pop?: Maybe<Listing_Price_Per_Sqmt_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Listing_Price_Per_Sqmt_Stddev_Samp_Fields>;
  sum?: Maybe<Listing_Price_Per_Sqmt_Sum_Fields>;
  var_pop?: Maybe<Listing_Price_Per_Sqmt_Var_Pop_Fields>;
  var_samp?: Maybe<Listing_Price_Per_Sqmt_Var_Samp_Fields>;
  variance?: Maybe<Listing_Price_Per_Sqmt_Variance_Fields>;
};
export type Listing_Price_Per_Sqmt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Price_Per_Sqmt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Listing_Price_Per_Sqmt_Avg_Fields = {
__typename?: 'listing_price_per_sqmt_avg_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Listing_Price_Per_Sqmt_Max_Fields = {
__typename?: 'listing_price_per_sqmt_max_fields';

  id?: Maybe<Scalars['uuid']>;
  price_per_sqmt?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Listing_Price_Per_Sqmt_Min_Fields = {
__typename?: 'listing_price_per_sqmt_min_fields';

  id?: Maybe<Scalars['uuid']>;
  price_per_sqmt?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Listing_Price_Per_Sqmt_Stddev_Fields = {
__typename?: 'listing_price_per_sqmt_stddev_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Listing_Price_Per_Sqmt_Stddev_Pop_Fields = {
__typename?: 'listing_price_per_sqmt_stddev_pop_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Listing_Price_Per_Sqmt_Stddev_Samp_Fields = {
__typename?: 'listing_price_per_sqmt_stddev_samp_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Listing_Price_Per_Sqmt_Sum_Fields = {
__typename?: 'listing_price_per_sqmt_sum_fields';

  price_per_sqmt?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Listing_Price_Per_Sqmt_Var_Pop_Fields = {
__typename?: 'listing_price_per_sqmt_var_pop_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Listing_Price_Per_Sqmt_Var_Samp_Fields = {
__typename?: 'listing_price_per_sqmt_var_samp_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Listing_Price_Per_Sqmt_Variance_Fields = {
__typename?: 'listing_price_per_sqmt_variance_fields';

  price_per_sqmt?: Maybe<Scalars['Float']>;
};

/** storing publish listing errors */
export type Listing_Publish_Errors = {
__typename?: 'listing_publish_errors';

  agency_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  error_field: Scalars['String'];
  error_message?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  listing_id: Scalars['uuid'];
  portal_source: Listing_Publish_Portal_Source_Enum;
  updated_at: Scalars['timestamptz'];
};
export type Listing_Publish_ErrorsError_MessageArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "listing_publish_errors" */
export type Listing_Publish_Errors_Aggregate = {
__typename?: 'listing_publish_errors_aggregate';

  aggregate?: Maybe<Listing_Publish_Errors_Aggregate_Fields>;
  nodes: Array<Listing_Publish_Errors>;
};

/** aggregate fields of "listing_publish_errors" */
export type Listing_Publish_Errors_Aggregate_Fields = {
__typename?: 'listing_publish_errors_aggregate_fields';

  avg?: Maybe<Listing_Publish_Errors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Listing_Publish_Errors_Max_Fields>;
  min?: Maybe<Listing_Publish_Errors_Min_Fields>;
  stddev?: Maybe<Listing_Publish_Errors_Stddev_Fields>;
  stddev_pop?: Maybe<Listing_Publish_Errors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Listing_Publish_Errors_Stddev_Samp_Fields>;
  sum?: Maybe<Listing_Publish_Errors_Sum_Fields>;
  var_pop?: Maybe<Listing_Publish_Errors_Var_Pop_Fields>;
  var_samp?: Maybe<Listing_Publish_Errors_Var_Samp_Fields>;
  variance?: Maybe<Listing_Publish_Errors_Variance_Fields>;
};
export type Listing_Publish_Errors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Publish_Errors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Listing_Publish_Errors_Avg_Fields = {
__typename?: 'listing_publish_errors_avg_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Listing_Publish_Errors_Max_Fields = {
__typename?: 'listing_publish_errors_max_fields';

  agency_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  error_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Listing_Publish_Errors_Min_Fields = {
__typename?: 'listing_publish_errors_min_fields';

  agency_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  error_field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "listing_publish_errors" */
export type Listing_Publish_Errors_Mutation_Response = {
__typename?: 'listing_publish_errors_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Listing_Publish_Errors>;
};

/** aggregate stddev on columns */
export type Listing_Publish_Errors_Stddev_Fields = {
__typename?: 'listing_publish_errors_stddev_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Listing_Publish_Errors_Stddev_Pop_Fields = {
__typename?: 'listing_publish_errors_stddev_pop_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Listing_Publish_Errors_Stddev_Samp_Fields = {
__typename?: 'listing_publish_errors_stddev_samp_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Listing_Publish_Errors_Sum_Fields = {
__typename?: 'listing_publish_errors_sum_fields';

  agency_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Listing_Publish_Errors_Var_Pop_Fields = {
__typename?: 'listing_publish_errors_var_pop_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Listing_Publish_Errors_Var_Samp_Fields = {
__typename?: 'listing_publish_errors_var_samp_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Listing_Publish_Errors_Variance_Fields = {
__typename?: 'listing_publish_errors_variance_fields';

  agency_id?: Maybe<Scalars['Float']>;
};

/** store publish portal source like domain  */
export type Listing_Publish_Portal_Source = {
__typename?: 'listing_publish_portal_source';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_Aggregate = {
__typename?: 'listing_publish_portal_source_aggregate';

  aggregate?: Maybe<Listing_Publish_Portal_Source_Aggregate_Fields>;
  nodes: Array<Listing_Publish_Portal_Source>;
};

/** aggregate fields of "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_Aggregate_Fields = {
__typename?: 'listing_publish_portal_source_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Listing_Publish_Portal_Source_Max_Fields>;
  min?: Maybe<Listing_Publish_Portal_Source_Min_Fields>;
};
export type Listing_Publish_Portal_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Publish_Portal_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Listing_Publish_Portal_Source_Max_Fields = {
__typename?: 'listing_publish_portal_source_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Listing_Publish_Portal_Source_Min_Fields = {
__typename?: 'listing_publish_portal_source_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "listing_publish_portal_source" */
export type Listing_Publish_Portal_Source_Mutation_Response = {
__typename?: 'listing_publish_portal_source_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Listing_Publish_Portal_Source>;
};

/** store publish listing status */
export type Listing_Publish_Status = {
__typename?: 'listing_publish_status';

  created_at: Scalars['timestamptz'];
  domain_data?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  is_test: Scalars['Boolean'];
  /** An object relationship */
  listing?: Maybe<Listing>;
  listing_id: Scalars['uuid'];
  portal_source: Listing_Publish_Portal_Source_Enum;
  process_job_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  publish_status: Listing_Publish_Status_Type_Enum;
  request?: Maybe<Scalars['jsonb']>;
  response?: Maybe<Scalars['jsonb']>;
  test_xml?: Maybe<Scalars['String']>;
  test_xml_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  xml_file_name?: Maybe<Scalars['String']>;
};
export type Listing_Publish_StatusDomain_DataArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type Listing_Publish_StatusRequestArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type Listing_Publish_StatusResponseArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "listing_publish_status" */
export type Listing_Publish_Status_Aggregate = {
__typename?: 'listing_publish_status_aggregate';

  aggregate?: Maybe<Listing_Publish_Status_Aggregate_Fields>;
  nodes: Array<Listing_Publish_Status>;
};

/** aggregate fields of "listing_publish_status" */
export type Listing_Publish_Status_Aggregate_Fields = {
__typename?: 'listing_publish_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Listing_Publish_Status_Max_Fields>;
  min?: Maybe<Listing_Publish_Status_Min_Fields>;
};
export type Listing_Publish_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Publish_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Listing_Publish_Status_Max_Fields = {
__typename?: 'listing_publish_status_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  process_job_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  test_xml?: Maybe<Scalars['String']>;
  test_xml_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  xml_file_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Listing_Publish_Status_Min_Fields = {
__typename?: 'listing_publish_status_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  process_job_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  test_xml?: Maybe<Scalars['String']>;
  test_xml_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  xml_file_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "listing_publish_status" */
export type Listing_Publish_Status_Mutation_Response = {
__typename?: 'listing_publish_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Listing_Publish_Status>;
};

/** store publish status type  */
export type Listing_Publish_Status_Type = {
__typename?: 'listing_publish_status_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "listing_publish_status_type" */
export type Listing_Publish_Status_Type_Aggregate = {
__typename?: 'listing_publish_status_type_aggregate';

  aggregate?: Maybe<Listing_Publish_Status_Type_Aggregate_Fields>;
  nodes: Array<Listing_Publish_Status_Type>;
};

/** aggregate fields of "listing_publish_status_type" */
export type Listing_Publish_Status_Type_Aggregate_Fields = {
__typename?: 'listing_publish_status_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Listing_Publish_Status_Type_Max_Fields>;
  min?: Maybe<Listing_Publish_Status_Type_Min_Fields>;
};
export type Listing_Publish_Status_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Publish_Status_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Listing_Publish_Status_Type_Max_Fields = {
__typename?: 'listing_publish_status_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Listing_Publish_Status_Type_Min_Fields = {
__typename?: 'listing_publish_status_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "listing_publish_status_type" */
export type Listing_Publish_Status_Type_Mutation_Response = {
__typename?: 'listing_publish_status_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Listing_Publish_Status_Type>;
};

/** columns and relationships of "listing_status" */
export type Listing_Status = {
__typename?: 'listing_status';

  description: Scalars['String'];
  /** An array relationship */
  listings: Array<Listing>;
  /** An aggregate relationship */
  listings_aggregate: Listing_Aggregate;
  value: Scalars['String'];
};
export type Listing_StatusListingsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Listing_StatusListings_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};


/** aggregated selection of "listing_status" */
export type Listing_Status_Aggregate = {
__typename?: 'listing_status_aggregate';

  aggregate?: Maybe<Listing_Status_Aggregate_Fields>;
  nodes: Array<Listing_Status>;
};

/** aggregate fields of "listing_status" */
export type Listing_Status_Aggregate_Fields = {
__typename?: 'listing_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Listing_Status_Max_Fields>;
  min?: Maybe<Listing_Status_Min_Fields>;
};
export type Listing_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Listing_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Listing_Status_Max_Fields = {
__typename?: 'listing_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Listing_Status_Min_Fields = {
__typename?: 'listing_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "listing_status" */
export type Listing_Status_Mutation_Response = {
__typename?: 'listing_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Listing_Status>;
};

/** aggregate stddev on columns */
export type Listing_Stddev_Fields = {
__typename?: 'listing_stddev_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Listing_Stddev_Pop_Fields = {
__typename?: 'listing_stddev_pop_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Listing_Stddev_Samp_Fields = {
__typename?: 'listing_stddev_samp_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Listing_Sum_Fields = {
__typename?: 'listing_sum_fields';

  car_space?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Int']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Listing_Var_Pop_Fields = {
__typename?: 'listing_var_pop_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Listing_Var_Samp_Fields = {
__typename?: 'listing_var_samp_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Listing_Variance_Fields = {
__typename?: 'listing_variance_fields';

  car_space?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  /** calculate from range price based on property group setting */
  price_range_from_calc?: Maybe<Scalars['Int']>;
  /** Calculate Price To Range based on property group  */
  price_range_to_calc?: Maybe<Scalars['Int']>;
  /** This field is calculated during ACL refresh. See refresh_acl hasura action. or update_acl lambda which is triggered by acl_update_check lambda */
  unit_allocation_count?: Maybe<Scalars['Float']>;
  /** This field is the same as unit_allocation_count but only counts allocations to Master Agency if any */
  unit_allocation_count_master?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "member" */
export type Member = {
__typename?: 'member';

  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  features: Array<Member_Feature>;
  /** An aggregate relationship */
  features_aggregate: Member_Feature_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  member_role: Member_Role;
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['uuid'];
  /** An array relationship */
  project_allocates: Array<Member_Project_Allocate>;
  /** An aggregate relationship */
  project_allocates_aggregate: Member_Project_Allocate_Aggregate;
  role: Member_Role_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};
export type MemberFeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type MemberFeatures_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type MemberProject_AllocatesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type MemberProject_Allocates_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};


/** aggregated selection of "member" */
export type Member_Aggregate = {
__typename?: 'member_aggregate';

  aggregate?: Maybe<Member_Aggregate_Fields>;
  nodes: Array<Member>;
};

/** aggregate fields of "member" */
export type Member_Aggregate_Fields = {
__typename?: 'member_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Member_Max_Fields>;
  min?: Maybe<Member_Min_Fields>;
};
export type Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "member_feature" */
export type Member_Feature = {
__typename?: 'member_feature';

  created_at: Scalars['timestamptz'];
  feature: User_Feature_Enum;
  id: Scalars['uuid'];
  /** An object relationship */
  member?: Maybe<Member>;
  member_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_feature" */
export type Member_Feature_Aggregate = {
__typename?: 'member_feature_aggregate';

  aggregate?: Maybe<Member_Feature_Aggregate_Fields>;
  nodes: Array<Member_Feature>;
};

/** aggregate fields of "member_feature" */
export type Member_Feature_Aggregate_Fields = {
__typename?: 'member_feature_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Member_Feature_Max_Fields>;
  min?: Maybe<Member_Feature_Min_Fields>;
};
export type Member_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Member_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Member_Feature_Max_Fields = {
__typename?: 'member_feature_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  member_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Member_Feature_Min_Fields = {
__typename?: 'member_feature_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  member_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "member_feature" */
export type Member_Feature_Mutation_Response = {
__typename?: 'member_feature_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Member_Feature>;
};

/** aggregate max on columns */
export type Member_Max_Fields = {
__typename?: 'member_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Member_Min_Fields = {
__typename?: 'member_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "member" */
export type Member_Mutation_Response = {
__typename?: 'member_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Member>;
};

/** Defines which members can manage allocations for which projects. ALLOCATION member_feature must exist for given user, otherwise it will not work */
export type Member_Project_Allocate = {
__typename?: 'member_project_allocate';

  created_at: Scalars['timestamptz'];
  created_by_team_id: Scalars['uuid'];
  created_by_user_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_project_allocate" */
export type Member_Project_Allocate_Aggregate = {
__typename?: 'member_project_allocate_aggregate';

  aggregate?: Maybe<Member_Project_Allocate_Aggregate_Fields>;
  nodes: Array<Member_Project_Allocate>;
};

/** aggregate fields of "member_project_allocate" */
export type Member_Project_Allocate_Aggregate_Fields = {
__typename?: 'member_project_allocate_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Member_Project_Allocate_Max_Fields>;
  min?: Maybe<Member_Project_Allocate_Min_Fields>;
};
export type Member_Project_Allocate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Member_Project_Allocate_Max_Fields = {
__typename?: 'member_project_allocate_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  member_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Member_Project_Allocate_Min_Fields = {
__typename?: 'member_project_allocate_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  created_by_user_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  member_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "member_project_allocate" */
export type Member_Project_Allocate_Mutation_Response = {
__typename?: 'member_project_allocate_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Member_Project_Allocate>;
};

/** columns and relationships of "member_role" */
export type Member_Role = {
__typename?: 'member_role';

  description: Scalars['String'];
  /** An array relationship */
  invitations: Array<Invitation>;
  /** An aggregate relationship */
  invitations_aggregate: Invitation_Aggregate;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregate relationship */
  members_aggregate: Member_Aggregate;
  value: Scalars['String'];
};
export type Member_RoleInvitationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Member_RoleInvitations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Member_RoleMembersArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type Member_RoleMembers_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};


/** aggregated selection of "member_role" */
export type Member_Role_Aggregate = {
__typename?: 'member_role_aggregate';

  aggregate?: Maybe<Member_Role_Aggregate_Fields>;
  nodes: Array<Member_Role>;
};

/** aggregate fields of "member_role" */
export type Member_Role_Aggregate_Fields = {
__typename?: 'member_role_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Member_Role_Max_Fields>;
  min?: Maybe<Member_Role_Min_Fields>;
};
export type Member_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Member_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Member_Role_Max_Fields = {
__typename?: 'member_role_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Member_Role_Min_Fields = {
__typename?: 'member_role_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "member_role" */
export type Member_Role_Mutation_Response = {
__typename?: 'member_role_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Member_Role>;
};

/** mutation root */
export type Mutation_Root = {
__typename?: 'mutation_root';

  /** Creates and annature envelope for given agency agreement and sends it */
  agency_agreement_send: AnnatureCreateEnvelopeResp;
  /** Allocates all project top level stages to enabled agents (those which have active project allocation) */
  allocate_project_stages: AllocateProjectStagesResp;
  /** Enabled include_listings flag on all stage allocations for given project (affects only enabled stage allocations) */
  allocate_project_stages_open: AllocateProjectStagesOpenResp;
  /** Copy annature envelope with given id and create new one in Draft state with given name */
  annature_copy_envelope: AnnatureCopyEnvelopeResp;
  annature_create_account: AnnatureCreateAccountResp;
  annature_create_envelope?: Maybe<AnnatureCreateEnvelopeResp>;
  annature_delete_envelope: AnnatureDeleteEnvelopeResp;
  annature_resend_signing_email?: Maybe<AnnatureResendSigningEmailResp>;
  annature_void_envelope: AnnatureVoidEnvelopeResp;
  buyer_qr_code_sign_in: BuyerQrCodeSigInResp;
  buyer_qr_code_sign_up: BuyerQrCodeSignUpResp;
  cancel_deal?: Maybe<CancelDealResponse>;
  check_due_deals?: Maybe<NotificationResponse>;
  check_over_due_deals?: Maybe<NotificationResponse>;
  check_publish_listing_status?: Maybe<HasuraJsonResponse>;
  /** Attempt to recover Cognito user which exists in database but not in Cognito */
  cognito_recover_user: CognitoRecoverUserResp;
  /** Removed expired documents which belong to contacts if expiry_date is ste. If not, removes 1 year old documents. */
  contact_delete_expired_documents: JsonResponse;
  convert_eoi_to_reservation?: Maybe<EoiDealResponse>;
  create_client_registration_deal: ClientRegistrationDealResponse;
  create_code?: Maybe<CreateCodeResp>;
  create_contact?: Maybe<ContactResponse>;
  create_deal?: Maybe<DealResponse>;
  create_embed?: Maybe<CreateEmbedResponse>;
  create_embed_enquiry: HasuraJsonResponse;
  create_enquiry?: Maybe<EnquiryResponse>;
  create_organisation?: Maybe<OrganisationResponse>;
  create_sandbox_domain_agency?: Maybe<CreateSandboxDomainAgencyResponse>;
  cypress_drop?: Maybe<CypressDropResponse>;
  cypress_sign_up?: Maybe<CypressSignUpResponse>;
  deal_status_update?: Maybe<DealStatusUpdateResp>;
  /** delete data from the table: "acl_update" */
  delete_acl_update?: Maybe<Acl_Update_Mutation_Response>;
  /** delete single row from the table: "acl_update" */
  delete_acl_update_by_pk?: Maybe<Acl_Update>;
  /** delete data from the table: "acl_update_status" */
  delete_acl_update_status?: Maybe<Acl_Update_Status_Mutation_Response>;
  /** delete single row from the table: "acl_update_status" */
  delete_acl_update_status_by_pk?: Maybe<Acl_Update_Status>;
  /** delete data from the table: "agency_agreement" */
  delete_agency_agreement?: Maybe<Agency_Agreement_Mutation_Response>;
  /** delete single row from the table: "agency_agreement" */
  delete_agency_agreement_by_pk?: Maybe<Agency_Agreement>;
  /** delete data from the table: "agreement" */
  delete_agreement?: Maybe<Agreement_Mutation_Response>;
  /** delete single row from the table: "agreement" */
  delete_agreement_by_pk?: Maybe<Agreement>;
  /** delete data from the table: "allocation" */
  delete_allocation?: Maybe<Allocation_Mutation_Response>;
  /** delete single row from the table: "allocation" */
  delete_allocation_by_pk?: Maybe<Allocation>;
  /** delete data from the table: "allocation_log" */
  delete_allocation_log?: Maybe<Allocation_Log_Mutation_Response>;
  /** delete single row from the table: "allocation_log" */
  delete_allocation_log_by_pk?: Maybe<Allocation_Log>;
  /** delete data from the table: "allocation_type" */
  delete_allocation_type?: Maybe<Allocation_Type_Mutation_Response>;
  /** delete single row from the table: "allocation_type" */
  delete_allocation_type_by_pk?: Maybe<Allocation_Type>;
  /** delete data from the table: "apartment_plan_building" */
  delete_apartment_plan_building?: Maybe<Apartment_Plan_Building_Mutation_Response>;
  /** delete single row from the table: "apartment_plan_building" */
  delete_apartment_plan_building_by_pk?: Maybe<Apartment_Plan_Building>;
  /** delete data from the table: "apartment_plan_property" */
  delete_apartment_plan_property?: Maybe<Apartment_Plan_Property_Mutation_Response>;
  /** delete single row from the table: "apartment_plan_property" */
  delete_apartment_plan_property_by_pk?: Maybe<Apartment_Plan_Property>;
  /** delete data from the table: "area_plot" */
  delete_area_plot?: Maybe<Area_Plot_Mutation_Response>;
  /** delete single row from the table: "area_plot" */
  delete_area_plot_by_pk?: Maybe<Area_Plot>;
  /** delete data from the table: "area_plot_type" */
  delete_area_plot_type?: Maybe<Area_Plot_Type_Mutation_Response>;
  /** delete single row from the table: "area_plot_type" */
  delete_area_plot_type_by_pk?: Maybe<Area_Plot_Type>;
  /** delete data from the table: "attachment" */
  delete_attachment?: Maybe<Attachment_Mutation_Response>;
  /** delete single row from the table: "attachment" */
  delete_attachment_by_pk?: Maybe<Attachment>;
  /** delete data from the table: "attachment_config" */
  delete_attachment_config?: Maybe<Attachment_Config_Mutation_Response>;
  /** delete single row from the table: "attachment_config" */
  delete_attachment_config_by_pk?: Maybe<Attachment_Config>;
  /** delete data from the table: "attachment_edit_project_order" */
  delete_attachment_edit_project_order?: Maybe<Attachment_Edit_Project_Order_Mutation_Response>;
  /** delete single row from the table: "attachment_edit_project_order" */
  delete_attachment_edit_project_order_by_pk?: Maybe<Attachment_Edit_Project_Order>;
  /** delete data from the table: "attachment_owner" */
  delete_attachment_owner?: Maybe<Attachment_Owner_Mutation_Response>;
  /** delete single row from the table: "attachment_owner" */
  delete_attachment_owner_by_pk?: Maybe<Attachment_Owner>;
  /** delete data from the table: "attachment_product_type" */
  delete_attachment_product_type?: Maybe<Attachment_Product_Type_Mutation_Response>;
  /** delete single row from the table: "attachment_product_type" */
  delete_attachment_product_type_by_pk?: Maybe<Attachment_Product_Type>;
  /** delete data from the table: "attachment_stage_order" */
  delete_attachment_stage_order?: Maybe<Attachment_Stage_Order_Mutation_Response>;
  /** delete single row from the table: "attachment_stage_order" */
  delete_attachment_stage_order_by_pk?: Maybe<Attachment_Stage_Order>;
  /** delete data from the table: "attachment_thumbnail" */
  delete_attachment_thumbnail?: Maybe<Attachment_Thumbnail_Mutation_Response>;
  /** delete single row from the table: "attachment_thumbnail" */
  delete_attachment_thumbnail_by_pk?: Maybe<Attachment_Thumbnail>;
  /** delete data from the table: "attachment_thumbnail_type" */
  delete_attachment_thumbnail_type?: Maybe<Attachment_Thumbnail_Type_Mutation_Response>;
  /** delete single row from the table: "attachment_thumbnail_type" */
  delete_attachment_thumbnail_type_by_pk?: Maybe<Attachment_Thumbnail_Type>;
  /** delete data from the table: "attachment_type" */
  delete_attachment_type?: Maybe<Attachment_Type_Mutation_Response>;
  /** delete single row from the table: "attachment_type" */
  delete_attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** delete data from the table: "buyer_portal" */
  delete_buyer_portal?: Maybe<Buyer_Portal_Mutation_Response>;
  /** delete single row from the table: "buyer_portal" */
  delete_buyer_portal_by_pk?: Maybe<Buyer_Portal>;
  /** delete data from the table: "buyer_project" */
  delete_buyer_project?: Maybe<Buyer_Project_Mutation_Response>;
  /** delete single row from the table: "buyer_project" */
  delete_buyer_project_by_pk?: Maybe<Buyer_Project>;
  /** delete data from the table: "buyer_type" */
  delete_buyer_type?: Maybe<Buyer_Type_Mutation_Response>;
  /** delete single row from the table: "buyer_type" */
  delete_buyer_type_by_pk?: Maybe<Buyer_Type>;
  /** delete data from the table: "cache" */
  delete_cache?: Maybe<Cache_Mutation_Response>;
  /** delete single row from the table: "cache" */
  delete_cache_by_pk?: Maybe<Cache>;
  /** delete data from the table: "change_in" */
  delete_change_in?: Maybe<Change_In_Mutation_Response>;
  /** delete single row from the table: "change_in" */
  delete_change_in_by_pk?: Maybe<Change_In>;
  /** delete data from the table: "change_status" */
  delete_change_status?: Maybe<Change_Status_Mutation_Response>;
  /** delete single row from the table: "change_status" */
  delete_change_status_by_pk?: Maybe<Change_Status>;
  /** delete data from the table: "code" */
  delete_code?: Maybe<Code_Mutation_Response>;
  /** delete single row from the table: "code" */
  delete_code_by_pk?: Maybe<Code>;
  /** delete data from the table: "commission" */
  delete_commission?: Maybe<Commission_Mutation_Response>;
  /** delete single row from the table: "commission" */
  delete_commission_by_pk?: Maybe<Commission>;
  /** delete data from the table: "commission_type" */
  delete_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** delete single row from the table: "commission_type" */
  delete_commission_type_by_pk?: Maybe<Commission_Type>;
  /** delete data from the table: "contact" */
  delete_contact?: Maybe<Contact_Mutation_Response>;
  /** delete single row from the table: "contact" */
  delete_contact_by_pk?: Maybe<Contact>;
  /** delete data from the table: "contact_type" */
  delete_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** delete single row from the table: "contact_type" */
  delete_contact_type_by_pk?: Maybe<Contact_Type>;
  /** delete data from the table: "count_with_rolling_avg" */
  delete_count_with_rolling_avg?: Maybe<Count_With_Rolling_Avg_Mutation_Response>;
  /** delete single row from the table: "count_with_rolling_avg" */
  delete_count_with_rolling_avg_by_pk?: Maybe<Count_With_Rolling_Avg>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "crm_connection" */
  delete_crm_connection?: Maybe<Crm_Connection_Mutation_Response>;
  /** delete single row from the table: "crm_connection" */
  delete_crm_connection_by_pk?: Maybe<Crm_Connection>;
  /** delete data from the table: "deal" */
  delete_deal?: Maybe<Deal_Mutation_Response>;
  /** delete single row from the table: "deal" */
  delete_deal_by_pk?: Maybe<Deal>;
  /** delete data from the table: "deal_contact" */
  delete_deal_contact?: Maybe<Deal_Contact_Mutation_Response>;
  /** delete single row from the table: "deal_contact" */
  delete_deal_contact_by_pk?: Maybe<Deal_Contact>;
  /** delete data from the table: "deal_contact_status" */
  delete_deal_contact_status?: Maybe<Deal_Contact_Status_Mutation_Response>;
  /** delete single row from the table: "deal_contact_status" */
  delete_deal_contact_status_by_pk?: Maybe<Deal_Contact_Status>;
  /** delete data from the table: "deal_deposit_status" */
  delete_deal_deposit_status?: Maybe<Deal_Deposit_Status_Mutation_Response>;
  /** delete single row from the table: "deal_deposit_status" */
  delete_deal_deposit_status_by_pk?: Maybe<Deal_Deposit_Status>;
  /** delete data from the table: "deal_deposit_type" */
  delete_deal_deposit_type?: Maybe<Deal_Deposit_Type_Mutation_Response>;
  /** delete single row from the table: "deal_deposit_type" */
  delete_deal_deposit_type_by_pk?: Maybe<Deal_Deposit_Type>;
  /** delete data from the table: "deal_enquiry_source" */
  delete_deal_enquiry_source?: Maybe<Deal_Enquiry_Source_Mutation_Response>;
  /** delete single row from the table: "deal_enquiry_source" */
  delete_deal_enquiry_source_by_pk?: Maybe<Deal_Enquiry_Source>;
  /** delete data from the table: "deal_fallen_reason" */
  delete_deal_fallen_reason?: Maybe<Deal_Fallen_Reason_Mutation_Response>;
  /** delete single row from the table: "deal_fallen_reason" */
  delete_deal_fallen_reason_by_pk?: Maybe<Deal_Fallen_Reason>;
  /** delete data from the table: "deal_payment_method" */
  delete_deal_payment_method?: Maybe<Deal_Payment_Method_Mutation_Response>;
  /** delete single row from the table: "deal_payment_method" */
  delete_deal_payment_method_by_pk?: Maybe<Deal_Payment_Method>;
  /** delete data from the table: "deal_progress_update" */
  delete_deal_progress_update?: Maybe<Deal_Progress_Update_Mutation_Response>;
  /** delete single row from the table: "deal_progress_update" */
  delete_deal_progress_update_by_pk?: Maybe<Deal_Progress_Update>;
  /** delete data from the table: "deal_reason_purchase" */
  delete_deal_reason_purchase?: Maybe<Deal_Reason_Purchase_Mutation_Response>;
  /** delete single row from the table: "deal_reason_purchase" */
  delete_deal_reason_purchase_by_pk?: Maybe<Deal_Reason_Purchase>;
  /** delete data from the table: "deal_sf_action" */
  delete_deal_sf_action?: Maybe<Deal_Sf_Action_Mutation_Response>;
  /** delete single row from the table: "deal_sf_action" */
  delete_deal_sf_action_by_pk?: Maybe<Deal_Sf_Action>;
  /** delete data from the table: "deal_spoken_language" */
  delete_deal_spoken_language?: Maybe<Deal_Spoken_Language_Mutation_Response>;
  /** delete single row from the table: "deal_spoken_language" */
  delete_deal_spoken_language_by_pk?: Maybe<Deal_Spoken_Language>;
  /** delete data from the table: "deal_status" */
  delete_deal_status?: Maybe<Deal_Status_Mutation_Response>;
  /** delete single row from the table: "deal_status" */
  delete_deal_status_by_pk?: Maybe<Deal_Status>;
  /** delete data from the table: "deal_transfer_status" */
  delete_deal_transfer_status?: Maybe<Deal_Transfer_Status_Mutation_Response>;
  /** delete single row from the table: "deal_transfer_status" */
  delete_deal_transfer_status_by_pk?: Maybe<Deal_Transfer_Status>;
  /** delete data from the table: "deal_type" */
  delete_deal_type?: Maybe<Deal_Type_Mutation_Response>;
  /** delete single row from the table: "deal_type" */
  delete_deal_type_by_pk?: Maybe<Deal_Type>;
  /** delete data from the table: "deal_valuation_booking" */
  delete_deal_valuation_booking?: Maybe<Deal_Valuation_Booking_Mutation_Response>;
  /** delete single row from the table: "deal_valuation_booking" */
  delete_deal_valuation_booking_by_pk?: Maybe<Deal_Valuation_Booking>;
  /** delete data from the table: "deal_witness" */
  delete_deal_witness?: Maybe<Deal_Witness_Mutation_Response>;
  /** delete single row from the table: "deal_witness" */
  delete_deal_witness_by_pk?: Maybe<Deal_Witness>;
  /** delete data from the table: "developer_agent" */
  delete_developer_agent?: Maybe<Developer_Agent_Mutation_Response>;
  /** delete single row from the table: "developer_agent" */
  delete_developer_agent_by_pk?: Maybe<Developer_Agent>;
  /** delete data from the table: "document" */
  delete_document?: Maybe<Document_Mutation_Response>;
  /** delete single row from the table: "document" */
  delete_document_by_pk?: Maybe<Document>;
  /** delete data from the table: "document_type" */
  delete_document_type?: Maybe<Document_Type_Mutation_Response>;
  /** delete single row from the table: "document_type" */
  delete_document_type_by_pk?: Maybe<Document_Type>;
  /** delete data from the table: "embed" */
  delete_embed?: Maybe<Embed_Mutation_Response>;
  /** delete single row from the table: "embed" */
  delete_embed_by_pk?: Maybe<Embed>;
  /** delete data from the table: "envelope" */
  delete_envelope?: Maybe<Envelope_Mutation_Response>;
  /** delete single row from the table: "envelope" */
  delete_envelope_by_pk?: Maybe<Envelope>;
  /** delete data from the table: "envelope_owner" */
  delete_envelope_owner?: Maybe<Envelope_Owner_Mutation_Response>;
  /** delete single row from the table: "envelope_owner" */
  delete_envelope_owner_by_pk?: Maybe<Envelope_Owner>;
  /** delete data from the table: "envelope_status" */
  delete_envelope_status?: Maybe<Envelope_Status_Mutation_Response>;
  /** delete single row from the table: "envelope_status" */
  delete_envelope_status_by_pk?: Maybe<Envelope_Status>;
  /** delete data from the table: "envelope_type" */
  delete_envelope_type?: Maybe<Envelope_Type_Mutation_Response>;
  /** delete single row from the table: "envelope_type" */
  delete_envelope_type_by_pk?: Maybe<Envelope_Type>;
  /** delete data from the table: "experiment" */
  delete_experiment?: Maybe<Experiment_Mutation_Response>;
  /** delete single row from the table: "experiment" */
  delete_experiment_by_pk?: Maybe<Experiment>;
  /** delete data from the table: "feature" */
  delete_feature?: Maybe<Feature_Mutation_Response>;
  /** delete single row from the table: "feature" */
  delete_feature_by_pk?: Maybe<Feature>;
  /** delete data from the table: "feature_check" */
  delete_feature_check?: Maybe<Feature_Check_Mutation_Response>;
  /** delete single row from the table: "feature_check" */
  delete_feature_check_by_pk?: Maybe<Feature_Check>;
  /** delete data from the table: "feature_pack" */
  delete_feature_pack?: Maybe<Feature_Pack_Mutation_Response>;
  /** delete single row from the table: "feature_pack" */
  delete_feature_pack_by_pk?: Maybe<Feature_Pack>;
  /** delete data from the table: "feature_pack_feature" */
  delete_feature_pack_feature?: Maybe<Feature_Pack_Feature_Mutation_Response>;
  /** delete single row from the table: "feature_pack_feature" */
  delete_feature_pack_feature_by_pk?: Maybe<Feature_Pack_Feature>;
  /** delete data from the table: "filter_page" */
  delete_filter_page?: Maybe<Filter_Page_Mutation_Response>;
  /** delete single row from the table: "filter_page" */
  delete_filter_page_by_pk?: Maybe<Filter_Page>;
  /** delete data from the table: "filter_state" */
  delete_filter_state?: Maybe<Filter_State_Mutation_Response>;
  /** delete single row from the table: "filter_state" */
  delete_filter_state_by_pk?: Maybe<Filter_State>;
  /** delete data from the table: "finance_status" */
  delete_finance_status?: Maybe<Finance_Status_Mutation_Response>;
  /** delete single row from the table: "finance_status" */
  delete_finance_status_by_pk?: Maybe<Finance_Status>;
  /** delete data from the table: "graphql_query_log" */
  delete_graphql_query_log?: Maybe<Graphql_Query_Log_Mutation_Response>;
  /** delete single row from the table: "graphql_query_log" */
  delete_graphql_query_log_by_pk?: Maybe<Graphql_Query_Log>;
  /** delete data from the table: "hubspot_action" */
  delete_hubspot_action?: Maybe<Hubspot_Action_Mutation_Response>;
  /** delete single row from the table: "hubspot_action" */
  delete_hubspot_action_by_pk?: Maybe<Hubspot_Action>;
  /** delete data from the table: "hubspot_action_type" */
  delete_hubspot_action_type?: Maybe<Hubspot_Action_Type_Mutation_Response>;
  /** delete single row from the table: "hubspot_action_type" */
  delete_hubspot_action_type_by_pk?: Maybe<Hubspot_Action_Type>;
  /** delete data from the table: "hubspot_contact" */
  delete_hubspot_contact?: Maybe<Hubspot_Contact_Mutation_Response>;
  /** delete single row from the table: "hubspot_contact" */
  delete_hubspot_contact_by_pk?: Maybe<Hubspot_Contact>;
  /** delete data from the table: "hubspot_deal" */
  delete_hubspot_deal?: Maybe<Hubspot_Deal_Mutation_Response>;
  /** delete single row from the table: "hubspot_deal" */
  delete_hubspot_deal_by_pk?: Maybe<Hubspot_Deal>;
  /** delete data from the table: "hubspot_organisation" */
  delete_hubspot_organisation?: Maybe<Hubspot_Organisation_Mutation_Response>;
  /** delete single row from the table: "hubspot_organisation" */
  delete_hubspot_organisation_by_pk?: Maybe<Hubspot_Organisation>;
  /** delete data from the table: "hubspot_project" */
  delete_hubspot_project?: Maybe<Hubspot_Project_Mutation_Response>;
  /** delete single row from the table: "hubspot_project" */
  delete_hubspot_project_by_pk?: Maybe<Hubspot_Project>;
  /** delete data from the table: "hubspot_schema_error_log" */
  delete_hubspot_schema_error_log?: Maybe<Hubspot_Schema_Error_Log_Mutation_Response>;
  /** delete single row from the table: "hubspot_schema_error_log" */
  delete_hubspot_schema_error_log_by_pk?: Maybe<Hubspot_Schema_Error_Log>;
  /** delete data from the table: "hubspot_user" */
  delete_hubspot_user?: Maybe<Hubspot_User_Mutation_Response>;
  /** delete single row from the table: "hubspot_user" */
  delete_hubspot_user_by_pk?: Maybe<Hubspot_User>;
  /** delete data from the table: "hubspot_valuation_booking" */
  delete_hubspot_valuation_booking?: Maybe<Hubspot_Valuation_Booking_Mutation_Response>;
  /** delete single row from the table: "hubspot_valuation_booking" */
  delete_hubspot_valuation_booking_by_pk?: Maybe<Hubspot_Valuation_Booking>;
  /** delete data from the table: "invitation" */
  delete_invitation?: Maybe<Invitation_Mutation_Response>;
  /** delete single row from the table: "invitation" */
  delete_invitation_by_pk?: Maybe<Invitation>;
  /** delete data from the table: "invitation_status" */
  delete_invitation_status?: Maybe<Invitation_Status_Mutation_Response>;
  /** delete single row from the table: "invitation_status" */
  delete_invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** delete data from the table: "invitation_type" */
  delete_invitation_type?: Maybe<Invitation_Type_Mutation_Response>;
  /** delete single row from the table: "invitation_type" */
  delete_invitation_type_by_pk?: Maybe<Invitation_Type>;
  /** delete data from the table: "lambda_log" */
  delete_lambda_log?: Maybe<Lambda_Log_Mutation_Response>;
  /** delete single row from the table: "lambda_log" */
  delete_lambda_log_by_pk?: Maybe<Lambda_Log>;
  /** delete data from the table: "lead_generation" */
  delete_lead_generation?: Maybe<Lead_Generation_Mutation_Response>;
  /** delete single row from the table: "lead_generation" */
  delete_lead_generation_by_pk?: Maybe<Lead_Generation>;
  /** delete data from the table: "lead_generation_source" */
  delete_lead_generation_source?: Maybe<Lead_Generation_Source_Mutation_Response>;
  /** delete single row from the table: "lead_generation_source" */
  delete_lead_generation_source_by_pk?: Maybe<Lead_Generation_Source>;
  /** delete data from the table: "lead_generation_webhook_log" */
  delete_lead_generation_webhook_log?: Maybe<Lead_Generation_Webhook_Log_Mutation_Response>;
  /** delete single row from the table: "lead_generation_webhook_log" */
  delete_lead_generation_webhook_log_by_pk?: Maybe<Lead_Generation_Webhook_Log>;
  /** delete data from the table: "listing" */
  delete_listing?: Maybe<Listing_Mutation_Response>;
  /** delete single row from the table: "listing" */
  delete_listing_by_pk?: Maybe<Listing>;
  /** delete data from the table: "listing_publish_errors" */
  delete_listing_publish_errors?: Maybe<Listing_Publish_Errors_Mutation_Response>;
  /** delete single row from the table: "listing_publish_errors" */
  delete_listing_publish_errors_by_pk?: Maybe<Listing_Publish_Errors>;
  /** delete data from the table: "listing_publish_portal_source" */
  delete_listing_publish_portal_source?: Maybe<Listing_Publish_Portal_Source_Mutation_Response>;
  /** delete single row from the table: "listing_publish_portal_source" */
  delete_listing_publish_portal_source_by_pk?: Maybe<Listing_Publish_Portal_Source>;
  /** delete data from the table: "listing_publish_status" */
  delete_listing_publish_status?: Maybe<Listing_Publish_Status_Mutation_Response>;
  /** delete single row from the table: "listing_publish_status" */
  delete_listing_publish_status_by_pk?: Maybe<Listing_Publish_Status>;
  /** delete data from the table: "listing_publish_status_type" */
  delete_listing_publish_status_type?: Maybe<Listing_Publish_Status_Type_Mutation_Response>;
  /** delete single row from the table: "listing_publish_status_type" */
  delete_listing_publish_status_type_by_pk?: Maybe<Listing_Publish_Status_Type>;
  /** delete data from the table: "listing_status" */
  delete_listing_status?: Maybe<Listing_Status_Mutation_Response>;
  /** delete single row from the table: "listing_status" */
  delete_listing_status_by_pk?: Maybe<Listing_Status>;
  /** delete data from the table: "member" */
  delete_member?: Maybe<Member_Mutation_Response>;
  /** delete single row from the table: "member" */
  delete_member_by_pk?: Maybe<Member>;
  /** delete data from the table: "member_feature" */
  delete_member_feature?: Maybe<Member_Feature_Mutation_Response>;
  /** delete single row from the table: "member_feature" */
  delete_member_feature_by_pk?: Maybe<Member_Feature>;
  /** delete data from the table: "member_project_allocate" */
  delete_member_project_allocate?: Maybe<Member_Project_Allocate_Mutation_Response>;
  /** delete single row from the table: "member_project_allocate" */
  delete_member_project_allocate_by_pk?: Maybe<Member_Project_Allocate>;
  /** delete data from the table: "member_role" */
  delete_member_role?: Maybe<Member_Role_Mutation_Response>;
  /** delete single row from the table: "member_role" */
  delete_member_role_by_pk?: Maybe<Member_Role>;
  /** delete data from the table: "nationality" */
  delete_nationality?: Maybe<Nationality_Mutation_Response>;
  /** delete single row from the table: "nationality" */
  delete_nationality_by_pk?: Maybe<Nationality>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "notification_channel" */
  delete_notification_channel?: Maybe<Notification_Channel_Mutation_Response>;
  /** delete single row from the table: "notification_channel" */
  delete_notification_channel_by_pk?: Maybe<Notification_Channel>;
  /** delete data from the table: "notification_owner" */
  delete_notification_owner?: Maybe<Notification_Owner_Mutation_Response>;
  /** delete single row from the table: "notification_owner" */
  delete_notification_owner_by_pk?: Maybe<Notification_Owner>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<Notification_Type>;
  /** delete data from the table: "org_feature_pack" */
  delete_org_feature_pack?: Maybe<Org_Feature_Pack_Mutation_Response>;
  /** delete single row from the table: "org_feature_pack" */
  delete_org_feature_pack_by_pk?: Maybe<Org_Feature_Pack>;
  /** delete data from the table: "organisation" */
  delete_organisation?: Maybe<Organisation_Mutation_Response>;
  /** delete single row from the table: "organisation" */
  delete_organisation_by_pk?: Maybe<Organisation>;
  /** delete data from the table: "organisation_feature" */
  delete_organisation_feature?: Maybe<Organisation_Feature_Mutation_Response>;
  /** delete single row from the table: "organisation_feature" */
  delete_organisation_feature_by_pk?: Maybe<Organisation_Feature>;
  /** delete data from the table: "organisation_type" */
  delete_organisation_type?: Maybe<Organisation_Type_Mutation_Response>;
  /** delete single row from the table: "organisation_type" */
  delete_organisation_type_by_pk?: Maybe<Organisation_Type>;
  /** delete data from the table: "partner_portal" */
  delete_partner_portal?: Maybe<Partner_Portal_Mutation_Response>;
  /** delete single row from the table: "partner_portal" */
  delete_partner_portal_by_pk?: Maybe<Partner_Portal>;
  /** delete data from the table: "payment_method" */
  delete_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** delete single row from the table: "payment_method" */
  delete_payment_method_by_pk?: Maybe<Payment_Method>;
  /** delete data from the table: "payment_method_type" */
  delete_payment_method_type?: Maybe<Payment_Method_Type_Mutation_Response>;
  /** delete single row from the table: "payment_method_type" */
  delete_payment_method_type_by_pk?: Maybe<Payment_Method_Type>;
  /** delete data from the table: "preference" */
  delete_preference?: Maybe<Preference_Mutation_Response>;
  /** delete single row from the table: "preference" */
  delete_preference_by_pk?: Maybe<Preference>;
  /** delete data from the table: "process" */
  delete_process?: Maybe<Process_Mutation_Response>;
  /** delete single row from the table: "process" */
  delete_process_by_pk?: Maybe<Process>;
  /** delete data from the table: "process_status" */
  delete_process_status?: Maybe<Process_Status_Mutation_Response>;
  /** delete single row from the table: "process_status" */
  delete_process_status_by_pk?: Maybe<Process_Status>;
  /** delete data from the table: "project" */
  delete_project?: Maybe<Project_Mutation_Response>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<Project>;
  /** delete data from the table: "project_detail" */
  delete_project_detail?: Maybe<Project_Detail_Mutation_Response>;
  /** delete single row from the table: "project_detail" */
  delete_project_detail_by_pk?: Maybe<Project_Detail>;
  /** delete data from the table: "project_features" */
  delete_project_features?: Maybe<Project_Features_Mutation_Response>;
  /** delete single row from the table: "project_features" */
  delete_project_features_by_pk?: Maybe<Project_Features>;
  /** delete data from the table: "project_payment_method" */
  delete_project_payment_method?: Maybe<Project_Payment_Method_Mutation_Response>;
  /** delete single row from the table: "project_payment_method" */
  delete_project_payment_method_by_pk?: Maybe<Project_Payment_Method>;
  /** delete data from the table: "project_qr_code_for_buyer" */
  delete_project_qr_code_for_buyer?: Maybe<Project_Qr_Code_For_Buyer_Mutation_Response>;
  /** delete single row from the table: "project_qr_code_for_buyer" */
  delete_project_qr_code_for_buyer_by_pk?: Maybe<Project_Qr_Code_For_Buyer>;
  /** delete data from the table: "project_reservation_setting" */
  delete_project_reservation_setting?: Maybe<Project_Reservation_Setting_Mutation_Response>;
  /** delete single row from the table: "project_reservation_setting" */
  delete_project_reservation_setting_by_pk?: Maybe<Project_Reservation_Setting>;
  /** delete data from the table: "project_sources" */
  delete_project_sources?: Maybe<Project_Sources_Mutation_Response>;
  /** delete single row from the table: "project_sources" */
  delete_project_sources_by_pk?: Maybe<Project_Sources>;
  /** delete data from the table: "project_status" */
  delete_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** delete single row from the table: "project_status" */
  delete_project_status_by_pk?: Maybe<Project_Status>;
  /** delete data from the table: "project_type" */
  delete_project_type?: Maybe<Project_Type_Mutation_Response>;
  /** delete single row from the table: "project_type" */
  delete_project_type_by_pk?: Maybe<Project_Type>;
  /** delete data from the table: "property" */
  delete_property?: Maybe<Property_Mutation_Response>;
  /** delete single row from the table: "property" */
  delete_property_by_pk?: Maybe<Property>;
  /** delete data from the table: "property_completion_status" */
  delete_property_completion_status?: Maybe<Property_Completion_Status_Mutation_Response>;
  /** delete single row from the table: "property_completion_status" */
  delete_property_completion_status_by_pk?: Maybe<Property_Completion_Status>;
  /** delete data from the table: "property_group" */
  delete_property_group?: Maybe<Property_Group_Mutation_Response>;
  /** delete single row from the table: "property_group" */
  delete_property_group_by_pk?: Maybe<Property_Group>;
  /** delete data from the table: "property_group_completion_status" */
  delete_property_group_completion_status?: Maybe<Property_Group_Completion_Status_Mutation_Response>;
  /** delete single row from the table: "property_group_completion_status" */
  delete_property_group_completion_status_by_pk?: Maybe<Property_Group_Completion_Status>;
  /** delete data from the table: "property_group_type" */
  delete_property_group_type?: Maybe<Property_Group_Type_Mutation_Response>;
  /** delete single row from the table: "property_group_type" */
  delete_property_group_type_by_pk?: Maybe<Property_Group_Type>;
  /** Deletes given properties and listings from Salesforce */
  delete_property_sf: DeletePropertyResp;
  /** delete data from the table: "property_type" */
  delete_property_type?: Maybe<Property_Type_Mutation_Response>;
  /** delete single row from the table: "property_type" */
  delete_property_type_by_pk?: Maybe<Property_Type>;
  /** delete data from the table: "reservation" */
  delete_reservation?: Maybe<Reservation_Mutation_Response>;
  /** delete single row from the table: "reservation" */
  delete_reservation_by_pk?: Maybe<Reservation>;
  /** delete data from the table: "salesforce_action" */
  delete_salesforce_action?: Maybe<Salesforce_Action_Mutation_Response>;
  /** delete single row from the table: "salesforce_action" */
  delete_salesforce_action_by_pk?: Maybe<Salesforce_Action>;
  /** delete data from the table: "salesforce_action_status" */
  delete_salesforce_action_status?: Maybe<Salesforce_Action_Status_Mutation_Response>;
  /** delete single row from the table: "salesforce_action_status" */
  delete_salesforce_action_status_by_pk?: Maybe<Salesforce_Action_Status>;
  /** delete data from the table: "salesforce_bulk_import" */
  delete_salesforce_bulk_import?: Maybe<Salesforce_Bulk_Import_Mutation_Response>;
  /** delete single row from the table: "salesforce_bulk_import" */
  delete_salesforce_bulk_import_by_pk?: Maybe<Salesforce_Bulk_Import>;
  /** delete data from the table: "salesforce_connection" */
  delete_salesforce_connection?: Maybe<Salesforce_Connection_Mutation_Response>;
  /** delete single row from the table: "salesforce_connection" */
  delete_salesforce_connection_by_pk?: Maybe<Salesforce_Connection>;
  /** delete data from the table: "salesforce_sync_status" */
  delete_salesforce_sync_status?: Maybe<Salesforce_Sync_Status_Mutation_Response>;
  /** delete single row from the table: "salesforce_sync_status" */
  delete_salesforce_sync_status_by_pk?: Maybe<Salesforce_Sync_Status>;
  /** delete data from the table: "settings" */
  delete_settings?: Maybe<Settings_Mutation_Response>;
  /** delete single row from the table: "settings" */
  delete_settings_by_pk?: Maybe<Settings>;
  /** delete data from the table: "sf_action" */
  delete_sf_action?: Maybe<Sf_Action_Mutation_Response>;
  /** delete single row from the table: "sf_action" */
  delete_sf_action_by_pk?: Maybe<Sf_Action>;
  /** delete data from the table: "sf_contact" */
  delete_sf_contact?: Maybe<Sf_Contact_Mutation_Response>;
  /** delete single row from the table: "sf_contact" */
  delete_sf_contact_by_pk?: Maybe<Sf_Contact>;
  /** delete data from the table: "sf_organisation" */
  delete_sf_organisation?: Maybe<Sf_Organisation_Mutation_Response>;
  /** delete single row from the table: "sf_organisation" */
  delete_sf_organisation_by_pk?: Maybe<Sf_Organisation>;
  /** delete data from the table: "spoken_language" */
  delete_spoken_language?: Maybe<Spoken_Language_Mutation_Response>;
  /** delete single row from the table: "spoken_language" */
  delete_spoken_language_by_pk?: Maybe<Spoken_Language>;
  /** delete data from the table: "system" */
  delete_system?: Maybe<System_Mutation_Response>;
  /** delete single row from the table: "system" */
  delete_system_by_pk?: Maybe<System>;
  /** delete data from the table: "transaction" */
  delete_transaction?: Maybe<Transaction_Mutation_Response>;
  /** delete single row from the table: "transaction" */
  delete_transaction_by_pk?: Maybe<Transaction>;
  /** delete data from the table: "transaction_status" */
  delete_transaction_status?: Maybe<Transaction_Status_Mutation_Response>;
  /** delete single row from the table: "transaction_status" */
  delete_transaction_status_by_pk?: Maybe<Transaction_Status>;
  /** delete data from the table: "transaction_type" */
  delete_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** delete single row from the table: "transaction_type" */
  delete_transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_experiment" */
  delete_user_experiment?: Maybe<User_Experiment_Mutation_Response>;
  /** delete single row from the table: "user_experiment" */
  delete_user_experiment_by_pk?: Maybe<User_Experiment>;
  /** delete data from the table: "user_feature" */
  delete_user_feature?: Maybe<User_Feature_Mutation_Response>;
  /** delete single row from the table: "user_feature" */
  delete_user_feature_by_pk?: Maybe<User_Feature>;
  /** delete data from the table: "user_push_token" */
  delete_user_push_token?: Maybe<User_Push_Token_Mutation_Response>;
  /** delete single row from the table: "user_push_token" */
  delete_user_push_token_by_pk?: Maybe<User_Push_Token>;
  /** delete data from the table: "variation" */
  delete_variation?: Maybe<Variation_Mutation_Response>;
  /** delete single row from the table: "variation" */
  delete_variation_by_pk?: Maybe<Variation>;
  /** delete data from the table: "variation_category" */
  delete_variation_category?: Maybe<Variation_Category_Mutation_Response>;
  /** delete single row from the table: "variation_category" */
  delete_variation_category_by_pk?: Maybe<Variation_Category>;
  /** delete data from the table: "variation_type" */
  delete_variation_type?: Maybe<Variation_Type_Mutation_Response>;
  /** delete single row from the table: "variation_type" */
  delete_variation_type_by_pk?: Maybe<Variation_Type>;
  /** delete data from the table: "variation_type_category" */
  delete_variation_type_category?: Maybe<Variation_Type_Category_Mutation_Response>;
  /** delete single row from the table: "variation_type_category" */
  delete_variation_type_category_by_pk?: Maybe<Variation_Type_Category>;
  /** delete data from the table: "web_app_feature" */
  delete_web_app_feature?: Maybe<Web_App_Feature_Mutation_Response>;
  /** delete single row from the table: "web_app_feature" */
  delete_web_app_feature_by_pk?: Maybe<Web_App_Feature>;
  /** delete data from the table: "websocket_log" */
  delete_websocket_log?: Maybe<Websocket_Log_Mutation_Response>;
  /** delete single row from the table: "websocket_log" */
  delete_websocket_log_by_pk?: Maybe<Websocket_Log>;
  drop_embed?: Maybe<DropEmbedResponse>;
  edit_deal_name_on_contract?: Maybe<HasuraActionResp>;
  /** Up-Sync listing table changes to Salesforce, only changes in the paylod will be synced */
  edit_listing: EditListingResp;
  /** Edit status of single listing with up-sync to Salesforce if necessary */
  edit_listing_price: HasuraActionResp;
  /** Edit status of one or multiple listings with up-sync to Salesforce if necessary */
  edit_listing_status: HasuraActionResp;
  /** Up-Sync property table changes to Salesforce, only changes in the paylod will be synced */
  edit_property: EditPropertyResp;
  generate_id_report_attachment?: Maybe<HasuraJsonResponse>;
  generate_thumbnails?: Maybe<Array<HasuraGeneratedThumbnail>>;
  get_flat_file_token: FlatFileTokenResponse;
  handle_invitation?: Maybe<AcceptInviteResponse>;
  hold_listing?: Maybe<HoldListingActionResponse>;
  holding_deposit_paid: HoldingDepositPaidResp;
  hubspot_contacts_sync?: Maybe<HubspotSyncResponse>;
  hubspot_deal_sync?: Maybe<HubspotSyncResponse>;
  hubspot_deals_sync?: Maybe<HubspotSyncResponse>;
  hubspot_partner_contacts_sync?: Maybe<HubspotSyncResponse>;
  hubspot_partner_team_sync?: Maybe<HubspotSyncResponse>;
  hubspot_partner_users_sync?: Maybe<HubspotSyncResponse>;
  hubspot_projects_sync?: Maybe<HubspotSyncResponse>;
  /** hubspot_reset_schema */
  hubspot_reset_schema?: Maybe<HasuraJsonResponse>;
  /** Creates associations in the HubSpot */
  hubspot_sync_associations: JsonResponse;
  hubspot_sync_schema?: Maybe<HasuraJsonResponse>;
  hubspot_team_sync?: Maybe<HubspotSyncResponse>;
  hubspot_users_sync?: Maybe<HubspotSyncResponse>;
  /** insert data into the table: "acl_update" */
  insert_acl_update?: Maybe<Acl_Update_Mutation_Response>;
  /** insert a single row into the table: "acl_update" */
  insert_acl_update_one?: Maybe<Acl_Update>;
  /** insert data into the table: "acl_update_status" */
  insert_acl_update_status?: Maybe<Acl_Update_Status_Mutation_Response>;
  /** insert a single row into the table: "acl_update_status" */
  insert_acl_update_status_one?: Maybe<Acl_Update_Status>;
  /** insert data into the table: "agency_agreement" */
  insert_agency_agreement?: Maybe<Agency_Agreement_Mutation_Response>;
  /** insert a single row into the table: "agency_agreement" */
  insert_agency_agreement_one?: Maybe<Agency_Agreement>;
  /** insert data into the table: "agreement" */
  insert_agreement?: Maybe<Agreement_Mutation_Response>;
  /** insert a single row into the table: "agreement" */
  insert_agreement_one?: Maybe<Agreement>;
  /** insert data into the table: "allocation" */
  insert_allocation?: Maybe<Allocation_Mutation_Response>;
  /** insert data into the table: "allocation_log" */
  insert_allocation_log?: Maybe<Allocation_Log_Mutation_Response>;
  /** insert a single row into the table: "allocation_log" */
  insert_allocation_log_one?: Maybe<Allocation_Log>;
  /** insert a single row into the table: "allocation" */
  insert_allocation_one?: Maybe<Allocation>;
  /** insert data into the table: "allocation_type" */
  insert_allocation_type?: Maybe<Allocation_Type_Mutation_Response>;
  /** insert a single row into the table: "allocation_type" */
  insert_allocation_type_one?: Maybe<Allocation_Type>;
  /** insert data into the table: "apartment_plan_building" */
  insert_apartment_plan_building?: Maybe<Apartment_Plan_Building_Mutation_Response>;
  /** insert a single row into the table: "apartment_plan_building" */
  insert_apartment_plan_building_one?: Maybe<Apartment_Plan_Building>;
  /** insert data into the table: "apartment_plan_property" */
  insert_apartment_plan_property?: Maybe<Apartment_Plan_Property_Mutation_Response>;
  /** insert a single row into the table: "apartment_plan_property" */
  insert_apartment_plan_property_one?: Maybe<Apartment_Plan_Property>;
  /** insert data into the table: "area_plot" */
  insert_area_plot?: Maybe<Area_Plot_Mutation_Response>;
  /** insert a single row into the table: "area_plot" */
  insert_area_plot_one?: Maybe<Area_Plot>;
  /** insert data into the table: "area_plot_type" */
  insert_area_plot_type?: Maybe<Area_Plot_Type_Mutation_Response>;
  /** insert a single row into the table: "area_plot_type" */
  insert_area_plot_type_one?: Maybe<Area_Plot_Type>;
  /** insert data into the table: "attachment" */
  insert_attachment?: Maybe<Attachment_Mutation_Response>;
  /** insert data into the table: "attachment_config" */
  insert_attachment_config?: Maybe<Attachment_Config_Mutation_Response>;
  /** insert a single row into the table: "attachment_config" */
  insert_attachment_config_one?: Maybe<Attachment_Config>;
  /** insert data into the table: "attachment_edit_project_order" */
  insert_attachment_edit_project_order?: Maybe<Attachment_Edit_Project_Order_Mutation_Response>;
  /** insert a single row into the table: "attachment_edit_project_order" */
  insert_attachment_edit_project_order_one?: Maybe<Attachment_Edit_Project_Order>;
  /** insert a single row into the table: "attachment" */
  insert_attachment_one?: Maybe<Attachment>;
  /** insert data into the table: "attachment_owner" */
  insert_attachment_owner?: Maybe<Attachment_Owner_Mutation_Response>;
  /** insert a single row into the table: "attachment_owner" */
  insert_attachment_owner_one?: Maybe<Attachment_Owner>;
  /** insert data into the table: "attachment_product_type" */
  insert_attachment_product_type?: Maybe<Attachment_Product_Type_Mutation_Response>;
  /** insert a single row into the table: "attachment_product_type" */
  insert_attachment_product_type_one?: Maybe<Attachment_Product_Type>;
  /** insert data into the table: "attachment_stage_order" */
  insert_attachment_stage_order?: Maybe<Attachment_Stage_Order_Mutation_Response>;
  /** insert a single row into the table: "attachment_stage_order" */
  insert_attachment_stage_order_one?: Maybe<Attachment_Stage_Order>;
  /** insert data into the table: "attachment_thumbnail" */
  insert_attachment_thumbnail?: Maybe<Attachment_Thumbnail_Mutation_Response>;
  /** insert a single row into the table: "attachment_thumbnail" */
  insert_attachment_thumbnail_one?: Maybe<Attachment_Thumbnail>;
  /** insert data into the table: "attachment_thumbnail_type" */
  insert_attachment_thumbnail_type?: Maybe<Attachment_Thumbnail_Type_Mutation_Response>;
  /** insert a single row into the table: "attachment_thumbnail_type" */
  insert_attachment_thumbnail_type_one?: Maybe<Attachment_Thumbnail_Type>;
  /** insert data into the table: "attachment_type" */
  insert_attachment_type?: Maybe<Attachment_Type_Mutation_Response>;
  /** insert a single row into the table: "attachment_type" */
  insert_attachment_type_one?: Maybe<Attachment_Type>;
  /** insert data into the table: "buyer_portal" */
  insert_buyer_portal?: Maybe<Buyer_Portal_Mutation_Response>;
  /** insert a single row into the table: "buyer_portal" */
  insert_buyer_portal_one?: Maybe<Buyer_Portal>;
  /** insert data into the table: "buyer_project" */
  insert_buyer_project?: Maybe<Buyer_Project_Mutation_Response>;
  /** insert a single row into the table: "buyer_project" */
  insert_buyer_project_one?: Maybe<Buyer_Project>;
  /** insert data into the table: "buyer_type" */
  insert_buyer_type?: Maybe<Buyer_Type_Mutation_Response>;
  /** insert a single row into the table: "buyer_type" */
  insert_buyer_type_one?: Maybe<Buyer_Type>;
  /** insert data into the table: "cache" */
  insert_cache?: Maybe<Cache_Mutation_Response>;
  /** insert a single row into the table: "cache" */
  insert_cache_one?: Maybe<Cache>;
  /** insert data into the table: "change_in" */
  insert_change_in?: Maybe<Change_In_Mutation_Response>;
  /** insert a single row into the table: "change_in" */
  insert_change_in_one?: Maybe<Change_In>;
  /** insert data into the table: "change_status" */
  insert_change_status?: Maybe<Change_Status_Mutation_Response>;
  /** insert a single row into the table: "change_status" */
  insert_change_status_one?: Maybe<Change_Status>;
  /** insert data into the table: "code" */
  insert_code?: Maybe<Code_Mutation_Response>;
  /** insert a single row into the table: "code" */
  insert_code_one?: Maybe<Code>;
  /** insert data into the table: "commission" */
  insert_commission?: Maybe<Commission_Mutation_Response>;
  /** insert a single row into the table: "commission" */
  insert_commission_one?: Maybe<Commission>;
  /** insert data into the table: "commission_type" */
  insert_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** insert a single row into the table: "commission_type" */
  insert_commission_type_one?: Maybe<Commission_Type>;
  /** insert data into the table: "contact" */
  insert_contact?: Maybe<Contact_Mutation_Response>;
  /** insert a single row into the table: "contact" */
  insert_contact_one?: Maybe<Contact>;
  /** insert data into the table: "contact_type" */
  insert_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** insert a single row into the table: "contact_type" */
  insert_contact_type_one?: Maybe<Contact_Type>;
  /** insert data into the table: "count_with_rolling_avg" */
  insert_count_with_rolling_avg?: Maybe<Count_With_Rolling_Avg_Mutation_Response>;
  /** insert a single row into the table: "count_with_rolling_avg" */
  insert_count_with_rolling_avg_one?: Maybe<Count_With_Rolling_Avg>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "crm_connection" */
  insert_crm_connection?: Maybe<Crm_Connection_Mutation_Response>;
  /** insert a single row into the table: "crm_connection" */
  insert_crm_connection_one?: Maybe<Crm_Connection>;
  /** insert data into the table: "deal" */
  insert_deal?: Maybe<Deal_Mutation_Response>;
  /** insert data into the table: "deal_contact" */
  insert_deal_contact?: Maybe<Deal_Contact_Mutation_Response>;
  /** insert a single row into the table: "deal_contact" */
  insert_deal_contact_one?: Maybe<Deal_Contact>;
  /** insert data into the table: "deal_contact_status" */
  insert_deal_contact_status?: Maybe<Deal_Contact_Status_Mutation_Response>;
  /** insert a single row into the table: "deal_contact_status" */
  insert_deal_contact_status_one?: Maybe<Deal_Contact_Status>;
  /** insert data into the table: "deal_deposit_status" */
  insert_deal_deposit_status?: Maybe<Deal_Deposit_Status_Mutation_Response>;
  /** insert a single row into the table: "deal_deposit_status" */
  insert_deal_deposit_status_one?: Maybe<Deal_Deposit_Status>;
  /** insert data into the table: "deal_deposit_type" */
  insert_deal_deposit_type?: Maybe<Deal_Deposit_Type_Mutation_Response>;
  /** insert a single row into the table: "deal_deposit_type" */
  insert_deal_deposit_type_one?: Maybe<Deal_Deposit_Type>;
  /** insert data into the table: "deal_enquiry_source" */
  insert_deal_enquiry_source?: Maybe<Deal_Enquiry_Source_Mutation_Response>;
  /** insert a single row into the table: "deal_enquiry_source" */
  insert_deal_enquiry_source_one?: Maybe<Deal_Enquiry_Source>;
  /** insert data into the table: "deal_fallen_reason" */
  insert_deal_fallen_reason?: Maybe<Deal_Fallen_Reason_Mutation_Response>;
  /** insert a single row into the table: "deal_fallen_reason" */
  insert_deal_fallen_reason_one?: Maybe<Deal_Fallen_Reason>;
  /** insert a single row into the table: "deal" */
  insert_deal_one?: Maybe<Deal>;
  /** insert data into the table: "deal_payment_method" */
  insert_deal_payment_method?: Maybe<Deal_Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "deal_payment_method" */
  insert_deal_payment_method_one?: Maybe<Deal_Payment_Method>;
  /** insert data into the table: "deal_progress_update" */
  insert_deal_progress_update?: Maybe<Deal_Progress_Update_Mutation_Response>;
  /** insert a single row into the table: "deal_progress_update" */
  insert_deal_progress_update_one?: Maybe<Deal_Progress_Update>;
  /** insert data into the table: "deal_reason_purchase" */
  insert_deal_reason_purchase?: Maybe<Deal_Reason_Purchase_Mutation_Response>;
  /** insert a single row into the table: "deal_reason_purchase" */
  insert_deal_reason_purchase_one?: Maybe<Deal_Reason_Purchase>;
  /** insert data into the table: "deal_sf_action" */
  insert_deal_sf_action?: Maybe<Deal_Sf_Action_Mutation_Response>;
  /** insert a single row into the table: "deal_sf_action" */
  insert_deal_sf_action_one?: Maybe<Deal_Sf_Action>;
  /** insert data into the table: "deal_spoken_language" */
  insert_deal_spoken_language?: Maybe<Deal_Spoken_Language_Mutation_Response>;
  /** insert a single row into the table: "deal_spoken_language" */
  insert_deal_spoken_language_one?: Maybe<Deal_Spoken_Language>;
  /** insert data into the table: "deal_status" */
  insert_deal_status?: Maybe<Deal_Status_Mutation_Response>;
  /** insert a single row into the table: "deal_status" */
  insert_deal_status_one?: Maybe<Deal_Status>;
  /** insert data into the table: "deal_transfer_status" */
  insert_deal_transfer_status?: Maybe<Deal_Transfer_Status_Mutation_Response>;
  /** insert a single row into the table: "deal_transfer_status" */
  insert_deal_transfer_status_one?: Maybe<Deal_Transfer_Status>;
  /** insert data into the table: "deal_type" */
  insert_deal_type?: Maybe<Deal_Type_Mutation_Response>;
  /** insert a single row into the table: "deal_type" */
  insert_deal_type_one?: Maybe<Deal_Type>;
  /** insert data into the table: "deal_valuation_booking" */
  insert_deal_valuation_booking?: Maybe<Deal_Valuation_Booking_Mutation_Response>;
  /** insert a single row into the table: "deal_valuation_booking" */
  insert_deal_valuation_booking_one?: Maybe<Deal_Valuation_Booking>;
  /** insert data into the table: "deal_witness" */
  insert_deal_witness?: Maybe<Deal_Witness_Mutation_Response>;
  /** insert a single row into the table: "deal_witness" */
  insert_deal_witness_one?: Maybe<Deal_Witness>;
  /** insert data into the table: "developer_agent" */
  insert_developer_agent?: Maybe<Developer_Agent_Mutation_Response>;
  /** insert a single row into the table: "developer_agent" */
  insert_developer_agent_one?: Maybe<Developer_Agent>;
  /** insert data into the table: "document" */
  insert_document?: Maybe<Document_Mutation_Response>;
  /** insert a single row into the table: "document" */
  insert_document_one?: Maybe<Document>;
  /** insert data into the table: "document_type" */
  insert_document_type?: Maybe<Document_Type_Mutation_Response>;
  /** insert a single row into the table: "document_type" */
  insert_document_type_one?: Maybe<Document_Type>;
  /** insert data into the table: "embed" */
  insert_embed?: Maybe<Embed_Mutation_Response>;
  /** insert a single row into the table: "embed" */
  insert_embed_one?: Maybe<Embed>;
  /** insert data into the table: "envelope" */
  insert_envelope?: Maybe<Envelope_Mutation_Response>;
  /** insert a single row into the table: "envelope" */
  insert_envelope_one?: Maybe<Envelope>;
  /** insert data into the table: "envelope_owner" */
  insert_envelope_owner?: Maybe<Envelope_Owner_Mutation_Response>;
  /** insert a single row into the table: "envelope_owner" */
  insert_envelope_owner_one?: Maybe<Envelope_Owner>;
  /** insert data into the table: "envelope_status" */
  insert_envelope_status?: Maybe<Envelope_Status_Mutation_Response>;
  /** insert a single row into the table: "envelope_status" */
  insert_envelope_status_one?: Maybe<Envelope_Status>;
  /** insert data into the table: "envelope_type" */
  insert_envelope_type?: Maybe<Envelope_Type_Mutation_Response>;
  /** insert a single row into the table: "envelope_type" */
  insert_envelope_type_one?: Maybe<Envelope_Type>;
  /** insert data into the table: "experiment" */
  insert_experiment?: Maybe<Experiment_Mutation_Response>;
  /** insert a single row into the table: "experiment" */
  insert_experiment_one?: Maybe<Experiment>;
  /** insert data into the table: "feature" */
  insert_feature?: Maybe<Feature_Mutation_Response>;
  /** insert data into the table: "feature_check" */
  insert_feature_check?: Maybe<Feature_Check_Mutation_Response>;
  /** insert a single row into the table: "feature_check" */
  insert_feature_check_one?: Maybe<Feature_Check>;
  /** insert a single row into the table: "feature" */
  insert_feature_one?: Maybe<Feature>;
  /** insert data into the table: "feature_pack" */
  insert_feature_pack?: Maybe<Feature_Pack_Mutation_Response>;
  /** insert data into the table: "feature_pack_feature" */
  insert_feature_pack_feature?: Maybe<Feature_Pack_Feature_Mutation_Response>;
  /** insert a single row into the table: "feature_pack_feature" */
  insert_feature_pack_feature_one?: Maybe<Feature_Pack_Feature>;
  /** insert a single row into the table: "feature_pack" */
  insert_feature_pack_one?: Maybe<Feature_Pack>;
  /** insert data into the table: "filter_page" */
  insert_filter_page?: Maybe<Filter_Page_Mutation_Response>;
  /** insert a single row into the table: "filter_page" */
  insert_filter_page_one?: Maybe<Filter_Page>;
  /** insert data into the table: "filter_state" */
  insert_filter_state?: Maybe<Filter_State_Mutation_Response>;
  /** insert a single row into the table: "filter_state" */
  insert_filter_state_one?: Maybe<Filter_State>;
  /** insert data into the table: "finance_status" */
  insert_finance_status?: Maybe<Finance_Status_Mutation_Response>;
  /** insert a single row into the table: "finance_status" */
  insert_finance_status_one?: Maybe<Finance_Status>;
  /** insert data into the table: "graphql_query_log" */
  insert_graphql_query_log?: Maybe<Graphql_Query_Log_Mutation_Response>;
  /** insert a single row into the table: "graphql_query_log" */
  insert_graphql_query_log_one?: Maybe<Graphql_Query_Log>;
  /** insert data into the table: "hubspot_action" */
  insert_hubspot_action?: Maybe<Hubspot_Action_Mutation_Response>;
  /** insert a single row into the table: "hubspot_action" */
  insert_hubspot_action_one?: Maybe<Hubspot_Action>;
  /** insert data into the table: "hubspot_action_type" */
  insert_hubspot_action_type?: Maybe<Hubspot_Action_Type_Mutation_Response>;
  /** insert a single row into the table: "hubspot_action_type" */
  insert_hubspot_action_type_one?: Maybe<Hubspot_Action_Type>;
  /** insert data into the table: "hubspot_contact" */
  insert_hubspot_contact?: Maybe<Hubspot_Contact_Mutation_Response>;
  /** insert a single row into the table: "hubspot_contact" */
  insert_hubspot_contact_one?: Maybe<Hubspot_Contact>;
  /** insert data into the table: "hubspot_deal" */
  insert_hubspot_deal?: Maybe<Hubspot_Deal_Mutation_Response>;
  /** insert a single row into the table: "hubspot_deal" */
  insert_hubspot_deal_one?: Maybe<Hubspot_Deal>;
  /** insert data into the table: "hubspot_organisation" */
  insert_hubspot_organisation?: Maybe<Hubspot_Organisation_Mutation_Response>;
  /** insert a single row into the table: "hubspot_organisation" */
  insert_hubspot_organisation_one?: Maybe<Hubspot_Organisation>;
  /** insert data into the table: "hubspot_project" */
  insert_hubspot_project?: Maybe<Hubspot_Project_Mutation_Response>;
  /** insert a single row into the table: "hubspot_project" */
  insert_hubspot_project_one?: Maybe<Hubspot_Project>;
  /** insert data into the table: "hubspot_schema_error_log" */
  insert_hubspot_schema_error_log?: Maybe<Hubspot_Schema_Error_Log_Mutation_Response>;
  /** insert a single row into the table: "hubspot_schema_error_log" */
  insert_hubspot_schema_error_log_one?: Maybe<Hubspot_Schema_Error_Log>;
  /** insert data into the table: "hubspot_user" */
  insert_hubspot_user?: Maybe<Hubspot_User_Mutation_Response>;
  /** insert a single row into the table: "hubspot_user" */
  insert_hubspot_user_one?: Maybe<Hubspot_User>;
  /** insert data into the table: "hubspot_valuation_booking" */
  insert_hubspot_valuation_booking?: Maybe<Hubspot_Valuation_Booking_Mutation_Response>;
  /** insert a single row into the table: "hubspot_valuation_booking" */
  insert_hubspot_valuation_booking_one?: Maybe<Hubspot_Valuation_Booking>;
  /** insert data into the table: "invitation" */
  insert_invitation?: Maybe<Invitation_Mutation_Response>;
  /** insert a single row into the table: "invitation" */
  insert_invitation_one?: Maybe<Invitation>;
  /** insert data into the table: "invitation_status" */
  insert_invitation_status?: Maybe<Invitation_Status_Mutation_Response>;
  /** insert a single row into the table: "invitation_status" */
  insert_invitation_status_one?: Maybe<Invitation_Status>;
  /** insert data into the table: "invitation_type" */
  insert_invitation_type?: Maybe<Invitation_Type_Mutation_Response>;
  /** insert a single row into the table: "invitation_type" */
  insert_invitation_type_one?: Maybe<Invitation_Type>;
  /** insert data into the table: "lambda_log" */
  insert_lambda_log?: Maybe<Lambda_Log_Mutation_Response>;
  /** insert a single row into the table: "lambda_log" */
  insert_lambda_log_one?: Maybe<Lambda_Log>;
  /** insert data into the table: "lead_generation" */
  insert_lead_generation?: Maybe<Lead_Generation_Mutation_Response>;
  /** insert a single row into the table: "lead_generation" */
  insert_lead_generation_one?: Maybe<Lead_Generation>;
  /** insert data into the table: "lead_generation_source" */
  insert_lead_generation_source?: Maybe<Lead_Generation_Source_Mutation_Response>;
  /** insert a single row into the table: "lead_generation_source" */
  insert_lead_generation_source_one?: Maybe<Lead_Generation_Source>;
  /** insert data into the table: "lead_generation_webhook_log" */
  insert_lead_generation_webhook_log?: Maybe<Lead_Generation_Webhook_Log_Mutation_Response>;
  /** insert a single row into the table: "lead_generation_webhook_log" */
  insert_lead_generation_webhook_log_one?: Maybe<Lead_Generation_Webhook_Log>;
  /** insert data into the table: "listing" */
  insert_listing?: Maybe<Listing_Mutation_Response>;
  /** insert a single row into the table: "listing" */
  insert_listing_one?: Maybe<Listing>;
  /** insert data into the table: "listing_publish_errors" */
  insert_listing_publish_errors?: Maybe<Listing_Publish_Errors_Mutation_Response>;
  /** insert a single row into the table: "listing_publish_errors" */
  insert_listing_publish_errors_one?: Maybe<Listing_Publish_Errors>;
  /** insert data into the table: "listing_publish_portal_source" */
  insert_listing_publish_portal_source?: Maybe<Listing_Publish_Portal_Source_Mutation_Response>;
  /** insert a single row into the table: "listing_publish_portal_source" */
  insert_listing_publish_portal_source_one?: Maybe<Listing_Publish_Portal_Source>;
  /** insert data into the table: "listing_publish_status" */
  insert_listing_publish_status?: Maybe<Listing_Publish_Status_Mutation_Response>;
  /** insert a single row into the table: "listing_publish_status" */
  insert_listing_publish_status_one?: Maybe<Listing_Publish_Status>;
  /** insert data into the table: "listing_publish_status_type" */
  insert_listing_publish_status_type?: Maybe<Listing_Publish_Status_Type_Mutation_Response>;
  /** insert a single row into the table: "listing_publish_status_type" */
  insert_listing_publish_status_type_one?: Maybe<Listing_Publish_Status_Type>;
  /** insert data into the table: "listing_status" */
  insert_listing_status?: Maybe<Listing_Status_Mutation_Response>;
  /** insert a single row into the table: "listing_status" */
  insert_listing_status_one?: Maybe<Listing_Status>;
  /** insert data into the table: "member" */
  insert_member?: Maybe<Member_Mutation_Response>;
  /** insert data into the table: "member_feature" */
  insert_member_feature?: Maybe<Member_Feature_Mutation_Response>;
  /** insert a single row into the table: "member_feature" */
  insert_member_feature_one?: Maybe<Member_Feature>;
  /** insert a single row into the table: "member" */
  insert_member_one?: Maybe<Member>;
  /** insert data into the table: "member_project_allocate" */
  insert_member_project_allocate?: Maybe<Member_Project_Allocate_Mutation_Response>;
  /** insert a single row into the table: "member_project_allocate" */
  insert_member_project_allocate_one?: Maybe<Member_Project_Allocate>;
  /** insert data into the table: "member_role" */
  insert_member_role?: Maybe<Member_Role_Mutation_Response>;
  /** insert a single row into the table: "member_role" */
  insert_member_role_one?: Maybe<Member_Role>;
  /** insert data into the table: "nationality" */
  insert_nationality?: Maybe<Nationality_Mutation_Response>;
  /** insert a single row into the table: "nationality" */
  insert_nationality_one?: Maybe<Nationality>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>;
  /** insert data into the table: "notification_channel" */
  insert_notification_channel?: Maybe<Notification_Channel_Mutation_Response>;
  /** insert a single row into the table: "notification_channel" */
  insert_notification_channel_one?: Maybe<Notification_Channel>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "notification_owner" */
  insert_notification_owner?: Maybe<Notification_Owner_Mutation_Response>;
  /** insert a single row into the table: "notification_owner" */
  insert_notification_owner_one?: Maybe<Notification_Owner>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<Notification_Type>;
  /** insert data into the table: "org_feature_pack" */
  insert_org_feature_pack?: Maybe<Org_Feature_Pack_Mutation_Response>;
  /** insert a single row into the table: "org_feature_pack" */
  insert_org_feature_pack_one?: Maybe<Org_Feature_Pack>;
  /** insert data into the table: "organisation" */
  insert_organisation?: Maybe<Organisation_Mutation_Response>;
  /** insert data into the table: "organisation_feature" */
  insert_organisation_feature?: Maybe<Organisation_Feature_Mutation_Response>;
  /** insert a single row into the table: "organisation_feature" */
  insert_organisation_feature_one?: Maybe<Organisation_Feature>;
  /** insert a single row into the table: "organisation" */
  insert_organisation_one?: Maybe<Organisation>;
  /** insert data into the table: "organisation_type" */
  insert_organisation_type?: Maybe<Organisation_Type_Mutation_Response>;
  /** insert a single row into the table: "organisation_type" */
  insert_organisation_type_one?: Maybe<Organisation_Type>;
  /** insert data into the table: "partner_portal" */
  insert_partner_portal?: Maybe<Partner_Portal_Mutation_Response>;
  /** insert a single row into the table: "partner_portal" */
  insert_partner_portal_one?: Maybe<Partner_Portal>;
  /** insert data into the table: "payment_method" */
  insert_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "payment_method" */
  insert_payment_method_one?: Maybe<Payment_Method>;
  /** insert data into the table: "payment_method_type" */
  insert_payment_method_type?: Maybe<Payment_Method_Type_Mutation_Response>;
  /** insert a single row into the table: "payment_method_type" */
  insert_payment_method_type_one?: Maybe<Payment_Method_Type>;
  /** insert data into the table: "preference" */
  insert_preference?: Maybe<Preference_Mutation_Response>;
  /** insert a single row into the table: "preference" */
  insert_preference_one?: Maybe<Preference>;
  /** insert data into the table: "process" */
  insert_process?: Maybe<Process_Mutation_Response>;
  /** insert a single row into the table: "process" */
  insert_process_one?: Maybe<Process>;
  /** insert data into the table: "process_status" */
  insert_process_status?: Maybe<Process_Status_Mutation_Response>;
  /** insert a single row into the table: "process_status" */
  insert_process_status_one?: Maybe<Process_Status>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<Project_Mutation_Response>;
  /** insert data into the table: "project_detail" */
  insert_project_detail?: Maybe<Project_Detail_Mutation_Response>;
  /** insert a single row into the table: "project_detail" */
  insert_project_detail_one?: Maybe<Project_Detail>;
  /** insert data into the table: "project_features" */
  insert_project_features?: Maybe<Project_Features_Mutation_Response>;
  /** insert a single row into the table: "project_features" */
  insert_project_features_one?: Maybe<Project_Features>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<Project>;
  /** insert data into the table: "project_payment_method" */
  insert_project_payment_method?: Maybe<Project_Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "project_payment_method" */
  insert_project_payment_method_one?: Maybe<Project_Payment_Method>;
  /** insert data into the table: "project_qr_code_for_buyer" */
  insert_project_qr_code_for_buyer?: Maybe<Project_Qr_Code_For_Buyer_Mutation_Response>;
  /** insert a single row into the table: "project_qr_code_for_buyer" */
  insert_project_qr_code_for_buyer_one?: Maybe<Project_Qr_Code_For_Buyer>;
  /** insert data into the table: "project_reservation_setting" */
  insert_project_reservation_setting?: Maybe<Project_Reservation_Setting_Mutation_Response>;
  /** insert a single row into the table: "project_reservation_setting" */
  insert_project_reservation_setting_one?: Maybe<Project_Reservation_Setting>;
  /** insert data into the table: "project_sources" */
  insert_project_sources?: Maybe<Project_Sources_Mutation_Response>;
  /** insert a single row into the table: "project_sources" */
  insert_project_sources_one?: Maybe<Project_Sources>;
  /** insert data into the table: "project_status" */
  insert_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** insert a single row into the table: "project_status" */
  insert_project_status_one?: Maybe<Project_Status>;
  /** insert data into the table: "project_type" */
  insert_project_type?: Maybe<Project_Type_Mutation_Response>;
  /** insert a single row into the table: "project_type" */
  insert_project_type_one?: Maybe<Project_Type>;
  /** insert data into the table: "property" */
  insert_property?: Maybe<Property_Mutation_Response>;
  /** insert data into the table: "property_completion_status" */
  insert_property_completion_status?: Maybe<Property_Completion_Status_Mutation_Response>;
  /** insert a single row into the table: "property_completion_status" */
  insert_property_completion_status_one?: Maybe<Property_Completion_Status>;
  /** insert data into the table: "property_group" */
  insert_property_group?: Maybe<Property_Group_Mutation_Response>;
  /** insert data into the table: "property_group_completion_status" */
  insert_property_group_completion_status?: Maybe<Property_Group_Completion_Status_Mutation_Response>;
  /** insert a single row into the table: "property_group_completion_status" */
  insert_property_group_completion_status_one?: Maybe<Property_Group_Completion_Status>;
  /** insert a single row into the table: "property_group" */
  insert_property_group_one?: Maybe<Property_Group>;
  /** insert data into the table: "property_group_type" */
  insert_property_group_type?: Maybe<Property_Group_Type_Mutation_Response>;
  /** insert a single row into the table: "property_group_type" */
  insert_property_group_type_one?: Maybe<Property_Group_Type>;
  /** insert a single row into the table: "property" */
  insert_property_one?: Maybe<Property>;
  /** insert data into the table: "property_type" */
  insert_property_type?: Maybe<Property_Type_Mutation_Response>;
  /** insert a single row into the table: "property_type" */
  insert_property_type_one?: Maybe<Property_Type>;
  /** insert data into the table: "reservation" */
  insert_reservation?: Maybe<Reservation_Mutation_Response>;
  /** insert a single row into the table: "reservation" */
  insert_reservation_one?: Maybe<Reservation>;
  /** insert data into the table: "salesforce_action" */
  insert_salesforce_action?: Maybe<Salesforce_Action_Mutation_Response>;
  /** insert a single row into the table: "salesforce_action" */
  insert_salesforce_action_one?: Maybe<Salesforce_Action>;
  /** insert data into the table: "salesforce_action_status" */
  insert_salesforce_action_status?: Maybe<Salesforce_Action_Status_Mutation_Response>;
  /** insert a single row into the table: "salesforce_action_status" */
  insert_salesforce_action_status_one?: Maybe<Salesforce_Action_Status>;
  /** insert data into the table: "salesforce_bulk_import" */
  insert_salesforce_bulk_import?: Maybe<Salesforce_Bulk_Import_Mutation_Response>;
  /** insert a single row into the table: "salesforce_bulk_import" */
  insert_salesforce_bulk_import_one?: Maybe<Salesforce_Bulk_Import>;
  /** insert data into the table: "salesforce_connection" */
  insert_salesforce_connection?: Maybe<Salesforce_Connection_Mutation_Response>;
  /** insert a single row into the table: "salesforce_connection" */
  insert_salesforce_connection_one?: Maybe<Salesforce_Connection>;
  /** insert data into the table: "salesforce_sync_status" */
  insert_salesforce_sync_status?: Maybe<Salesforce_Sync_Status_Mutation_Response>;
  /** insert a single row into the table: "salesforce_sync_status" */
  insert_salesforce_sync_status_one?: Maybe<Salesforce_Sync_Status>;
  /** insert data into the table: "settings" */
  insert_settings?: Maybe<Settings_Mutation_Response>;
  /** insert a single row into the table: "settings" */
  insert_settings_one?: Maybe<Settings>;
  /** insert data into the table: "sf_action" */
  insert_sf_action?: Maybe<Sf_Action_Mutation_Response>;
  /** insert a single row into the table: "sf_action" */
  insert_sf_action_one?: Maybe<Sf_Action>;
  /** insert data into the table: "sf_contact" */
  insert_sf_contact?: Maybe<Sf_Contact_Mutation_Response>;
  /** insert a single row into the table: "sf_contact" */
  insert_sf_contact_one?: Maybe<Sf_Contact>;
  /** insert data into the table: "sf_organisation" */
  insert_sf_organisation?: Maybe<Sf_Organisation_Mutation_Response>;
  /** insert a single row into the table: "sf_organisation" */
  insert_sf_organisation_one?: Maybe<Sf_Organisation>;
  /** insert data into the table: "spoken_language" */
  insert_spoken_language?: Maybe<Spoken_Language_Mutation_Response>;
  /** insert a single row into the table: "spoken_language" */
  insert_spoken_language_one?: Maybe<Spoken_Language>;
  /** insert data into the table: "system" */
  insert_system?: Maybe<System_Mutation_Response>;
  /** insert a single row into the table: "system" */
  insert_system_one?: Maybe<System>;
  /** insert data into the table: "transaction" */
  insert_transaction?: Maybe<Transaction_Mutation_Response>;
  /** insert a single row into the table: "transaction" */
  insert_transaction_one?: Maybe<Transaction>;
  /** insert data into the table: "transaction_status" */
  insert_transaction_status?: Maybe<Transaction_Status_Mutation_Response>;
  /** insert a single row into the table: "transaction_status" */
  insert_transaction_status_one?: Maybe<Transaction_Status>;
  /** insert data into the table: "transaction_type" */
  insert_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** insert a single row into the table: "transaction_type" */
  insert_transaction_type_one?: Maybe<Transaction_Type>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_experiment" */
  insert_user_experiment?: Maybe<User_Experiment_Mutation_Response>;
  /** insert a single row into the table: "user_experiment" */
  insert_user_experiment_one?: Maybe<User_Experiment>;
  /** insert data into the table: "user_feature" */
  insert_user_feature?: Maybe<User_Feature_Mutation_Response>;
  /** insert a single row into the table: "user_feature" */
  insert_user_feature_one?: Maybe<User_Feature>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_push_token" */
  insert_user_push_token?: Maybe<User_Push_Token_Mutation_Response>;
  /** insert a single row into the table: "user_push_token" */
  insert_user_push_token_one?: Maybe<User_Push_Token>;
  /** insert data into the table: "variation" */
  insert_variation?: Maybe<Variation_Mutation_Response>;
  /** insert data into the table: "variation_category" */
  insert_variation_category?: Maybe<Variation_Category_Mutation_Response>;
  /** insert a single row into the table: "variation_category" */
  insert_variation_category_one?: Maybe<Variation_Category>;
  /** insert a single row into the table: "variation" */
  insert_variation_one?: Maybe<Variation>;
  /** insert data into the table: "variation_type" */
  insert_variation_type?: Maybe<Variation_Type_Mutation_Response>;
  /** insert data into the table: "variation_type_category" */
  insert_variation_type_category?: Maybe<Variation_Type_Category_Mutation_Response>;
  /** insert a single row into the table: "variation_type_category" */
  insert_variation_type_category_one?: Maybe<Variation_Type_Category>;
  /** insert a single row into the table: "variation_type" */
  insert_variation_type_one?: Maybe<Variation_Type>;
  /** insert data into the table: "web_app_feature" */
  insert_web_app_feature?: Maybe<Web_App_Feature_Mutation_Response>;
  /** insert a single row into the table: "web_app_feature" */
  insert_web_app_feature_one?: Maybe<Web_App_Feature>;
  /** insert data into the table: "websocket_log" */
  insert_websocket_log?: Maybe<Websocket_Log_Mutation_Response>;
  /** insert a single row into the table: "websocket_log" */
  insert_websocket_log_one?: Maybe<Websocket_Log>;
  /** Schedules actions which will move given properties to a new property group/project in Salesforce */
  move_property: ActionResp;
  /** Creates new agency org, makes it a partner of developer org, and sends invitations to new users. partnerPortalId is the id in the partner_portal table which is linked with invitation table via invitation_id column. */
  partner_portal_invite: ActionResp;
  process_lead_generation_log?: Maybe<JsonResponse>;
  publish_listing?: Maybe<PublishListingResponse>;
  publish_project_listing: PublishListingResponse;
  refresh_acl?: Maybe<RefreshAclResponse>;
  /** Refreshes acl_property_allocation and acl_property_allocation_count */
  refresh_acl_property_allocation: JsonResponse;
  request_refund: HasuraJsonResponse;
  reserve_deal?: Maybe<ReserveDealResponse>;
  run_test: RunTestResp;
  /** Creates pba__Property__c and pba__Listing__c on Salesforce if necessary */
  salesforce_create_property_listing: HasuraActionResp;
  /** Attempts to remove offer and enquiry from Salesforce by given deal ID */
  salesforce_delete_deal: SalesforceDeleteDealResp;
  /** Write new comment to Salesforce pba__Enquiry__c.Comment__c */
  salesforce_set_deal_comment: ActionResp;
  salesforce_sync_deal_deposit_percentage?: Maybe<HasuraActionResp>;
  salesforce_sync_payable_on?: Maybe<HasuraActionResp>;
  /** Reads contact details in ProjectRE and pushes updates to Salesforce. Expects either contactId or contactSfId */
  salesforce_update_contact: JsonResponse;
  /** Updates Pre-Settlement Inspection Booking fields in pba__offer__c for deal with given id */
  salesforce_update_deal_pre_settlement_inspection_booking: JsonResponse;
  /** Updates Settlement Outlook related fields in the Salesforce pba__offer__c for deal with given id */
  salesforce_update_deal_settlement_outlook: JsonResponse;
  /** Updates Settlement Status related fields in the Salesforce pba__offer__c for deal with given id */
  salesforce_update_deal_settlement_status: JsonResponse;
  /** Up-Sync details of given valuation booking to the Salesforce pba__offer__c. see Valuation Booking section in Sales Contract */
  salesforce_update_deal_valuation_booking: JsonResponse;
  /** Up-Sync details of the organisation with given orgId to all connected Salesforce instances */
  salesforce_update_org: ActionResp;
  seed_create?: Maybe<SeedCreateResponse>;
  seed_drop?: Maybe<SeedDropResponse>;
  send_client_reservation?: Maybe<ClientReservationResponse>;
  send_code?: Maybe<SendCodeResp>;
  send_notification?: Maybe<NotificationResponse>;
  /** send the payment receipt to buyer */
  send_payment_receipt_to_buyer?: Maybe<SendPaymentReceiptToBuyerResp>;
  /** Sends push notification to all registered devices if any of user with given id */
  send_push: JsonResponse;
  send_reservation_invitation_to_buyer?: Maybe<SendReservationInvitationToBuyerResp>;
  share_embed_enquiry: HasuraJsonResponse;
  share_listing_enquiry: HasuraJsonResponse;
  /** Schedules Bulk Import from Salesforce results can be seen in salesforce_bulk_import table */
  start_heroku_connect_bulk_import?: Maybe<HerokuConnectBulkImportResp>;
  stripe_check_transaction?: Maybe<CheckTransactionResponse>;
  stripe_pay?: Maybe<PayResponse>;
  stripe_pay_with_amount?: Maybe<PayResponse>;
  /** Call this action when you want to swith current user to a new team */
  switch_team: SwitchTeamResp;
  twilio_send_message: TwilioSendMessageResp;
  /** update data of the table: "acl_update" */
  update_acl_update?: Maybe<Acl_Update_Mutation_Response>;
  /** update single row of the table: "acl_update" */
  update_acl_update_by_pk?: Maybe<Acl_Update>;
  /** update multiples rows of table: "acl_update" */
  update_acl_update_many?: Maybe<Array<Acl_Update_Mutation_Response>>;
  /** update data of the table: "acl_update_status" */
  update_acl_update_status?: Maybe<Acl_Update_Status_Mutation_Response>;
  /** update single row of the table: "acl_update_status" */
  update_acl_update_status_by_pk?: Maybe<Acl_Update_Status>;
  /** update multiples rows of table: "acl_update_status" */
  update_acl_update_status_many?: Maybe<Array<Acl_Update_Status_Mutation_Response>>;
  /** update data of the table: "agency_agreement" */
  update_agency_agreement?: Maybe<Agency_Agreement_Mutation_Response>;
  /** update single row of the table: "agency_agreement" */
  update_agency_agreement_by_pk?: Maybe<Agency_Agreement>;
  /** update multiples rows of table: "agency_agreement" */
  update_agency_agreement_many?: Maybe<Array<Agency_Agreement_Mutation_Response>>;
  /** Overwrites agency details with new one taken from Agency Agreement with given id. Empty agency agreement fiels are ignored. */
  update_agency_from_agency_agreement: HasuraActionResp;
  /** update data of the table: "agreement" */
  update_agreement?: Maybe<Agreement_Mutation_Response>;
  /** update single row of the table: "agreement" */
  update_agreement_by_pk?: Maybe<Agreement>;
  /** update multiples rows of table: "agreement" */
  update_agreement_many?: Maybe<Array<Agreement_Mutation_Response>>;
  /** update data of the table: "allocation" */
  update_allocation?: Maybe<Allocation_Mutation_Response>;
  /** update single row of the table: "allocation" */
  update_allocation_by_pk?: Maybe<Allocation>;
  /** update data of the table: "allocation_log" */
  update_allocation_log?: Maybe<Allocation_Log_Mutation_Response>;
  /** update single row of the table: "allocation_log" */
  update_allocation_log_by_pk?: Maybe<Allocation_Log>;
  /** update multiples rows of table: "allocation_log" */
  update_allocation_log_many?: Maybe<Array<Allocation_Log_Mutation_Response>>;
  /** update multiples rows of table: "allocation" */
  update_allocation_many?: Maybe<Array<Allocation_Mutation_Response>>;
  /** update data of the table: "allocation_type" */
  update_allocation_type?: Maybe<Allocation_Type_Mutation_Response>;
  /** update single row of the table: "allocation_type" */
  update_allocation_type_by_pk?: Maybe<Allocation_Type>;
  /** update multiples rows of table: "allocation_type" */
  update_allocation_type_many?: Maybe<Array<Allocation_Type_Mutation_Response>>;
  /** update data of the table: "apartment_plan_building" */
  update_apartment_plan_building?: Maybe<Apartment_Plan_Building_Mutation_Response>;
  /** update single row of the table: "apartment_plan_building" */
  update_apartment_plan_building_by_pk?: Maybe<Apartment_Plan_Building>;
  /** update multiples rows of table: "apartment_plan_building" */
  update_apartment_plan_building_many?: Maybe<Array<Apartment_Plan_Building_Mutation_Response>>;
  /** update data of the table: "apartment_plan_property" */
  update_apartment_plan_property?: Maybe<Apartment_Plan_Property_Mutation_Response>;
  /** update single row of the table: "apartment_plan_property" */
  update_apartment_plan_property_by_pk?: Maybe<Apartment_Plan_Property>;
  /** update multiples rows of table: "apartment_plan_property" */
  update_apartment_plan_property_many?: Maybe<Array<Apartment_Plan_Property_Mutation_Response>>;
  /** update data of the table: "area_plot" */
  update_area_plot?: Maybe<Area_Plot_Mutation_Response>;
  /** update single row of the table: "area_plot" */
  update_area_plot_by_pk?: Maybe<Area_Plot>;
  /** update multiples rows of table: "area_plot" */
  update_area_plot_many?: Maybe<Array<Area_Plot_Mutation_Response>>;
  /** update data of the table: "area_plot_type" */
  update_area_plot_type?: Maybe<Area_Plot_Type_Mutation_Response>;
  /** update single row of the table: "area_plot_type" */
  update_area_plot_type_by_pk?: Maybe<Area_Plot_Type>;
  /** update multiples rows of table: "area_plot_type" */
  update_area_plot_type_many?: Maybe<Array<Area_Plot_Type_Mutation_Response>>;
  /** update data of the table: "attachment" */
  update_attachment?: Maybe<Attachment_Mutation_Response>;
  /** update single row of the table: "attachment" */
  update_attachment_by_pk?: Maybe<Attachment>;
  /** update data of the table: "attachment_config" */
  update_attachment_config?: Maybe<Attachment_Config_Mutation_Response>;
  /** update single row of the table: "attachment_config" */
  update_attachment_config_by_pk?: Maybe<Attachment_Config>;
  /** update multiples rows of table: "attachment_config" */
  update_attachment_config_many?: Maybe<Array<Attachment_Config_Mutation_Response>>;
  /** update data of the table: "attachment_edit_project_order" */
  update_attachment_edit_project_order?: Maybe<Attachment_Edit_Project_Order_Mutation_Response>;
  /** update single row of the table: "attachment_edit_project_order" */
  update_attachment_edit_project_order_by_pk?: Maybe<Attachment_Edit_Project_Order>;
  /** update multiples rows of table: "attachment_edit_project_order" */
  update_attachment_edit_project_order_many?: Maybe<Array<Attachment_Edit_Project_Order_Mutation_Response>>;
  /** update multiples rows of table: "attachment" */
  update_attachment_many?: Maybe<Array<Attachment_Mutation_Response>>;
  /** update data of the table: "attachment_owner" */
  update_attachment_owner?: Maybe<Attachment_Owner_Mutation_Response>;
  /** update single row of the table: "attachment_owner" */
  update_attachment_owner_by_pk?: Maybe<Attachment_Owner>;
  /** update multiples rows of table: "attachment_owner" */
  update_attachment_owner_many?: Maybe<Array<Attachment_Owner_Mutation_Response>>;
  /** update data of the table: "attachment_product_type" */
  update_attachment_product_type?: Maybe<Attachment_Product_Type_Mutation_Response>;
  /** update single row of the table: "attachment_product_type" */
  update_attachment_product_type_by_pk?: Maybe<Attachment_Product_Type>;
  /** update multiples rows of table: "attachment_product_type" */
  update_attachment_product_type_many?: Maybe<Array<Attachment_Product_Type_Mutation_Response>>;
  /** update data of the table: "attachment_stage_order" */
  update_attachment_stage_order?: Maybe<Attachment_Stage_Order_Mutation_Response>;
  /** update single row of the table: "attachment_stage_order" */
  update_attachment_stage_order_by_pk?: Maybe<Attachment_Stage_Order>;
  /** update multiples rows of table: "attachment_stage_order" */
  update_attachment_stage_order_many?: Maybe<Array<Attachment_Stage_Order_Mutation_Response>>;
  /** update data of the table: "attachment_thumbnail" */
  update_attachment_thumbnail?: Maybe<Attachment_Thumbnail_Mutation_Response>;
  /** update single row of the table: "attachment_thumbnail" */
  update_attachment_thumbnail_by_pk?: Maybe<Attachment_Thumbnail>;
  /** update multiples rows of table: "attachment_thumbnail" */
  update_attachment_thumbnail_many?: Maybe<Array<Attachment_Thumbnail_Mutation_Response>>;
  /** update data of the table: "attachment_thumbnail_type" */
  update_attachment_thumbnail_type?: Maybe<Attachment_Thumbnail_Type_Mutation_Response>;
  /** update single row of the table: "attachment_thumbnail_type" */
  update_attachment_thumbnail_type_by_pk?: Maybe<Attachment_Thumbnail_Type>;
  /** update multiples rows of table: "attachment_thumbnail_type" */
  update_attachment_thumbnail_type_many?: Maybe<Array<Attachment_Thumbnail_Type_Mutation_Response>>;
  /** update data of the table: "attachment_type" */
  update_attachment_type?: Maybe<Attachment_Type_Mutation_Response>;
  /** update single row of the table: "attachment_type" */
  update_attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** update multiples rows of table: "attachment_type" */
  update_attachment_type_many?: Maybe<Array<Attachment_Type_Mutation_Response>>;
  /** update data of the table: "buyer_portal" */
  update_buyer_portal?: Maybe<Buyer_Portal_Mutation_Response>;
  /** update single row of the table: "buyer_portal" */
  update_buyer_portal_by_pk?: Maybe<Buyer_Portal>;
  /** update multiples rows of table: "buyer_portal" */
  update_buyer_portal_many?: Maybe<Array<Buyer_Portal_Mutation_Response>>;
  /** update data of the table: "buyer_project" */
  update_buyer_project?: Maybe<Buyer_Project_Mutation_Response>;
  /** update single row of the table: "buyer_project" */
  update_buyer_project_by_pk?: Maybe<Buyer_Project>;
  /** update multiples rows of table: "buyer_project" */
  update_buyer_project_many?: Maybe<Array<Buyer_Project_Mutation_Response>>;
  /** update data of the table: "buyer_type" */
  update_buyer_type?: Maybe<Buyer_Type_Mutation_Response>;
  /** update single row of the table: "buyer_type" */
  update_buyer_type_by_pk?: Maybe<Buyer_Type>;
  /** update multiples rows of table: "buyer_type" */
  update_buyer_type_many?: Maybe<Array<Buyer_Type_Mutation_Response>>;
  /** update data of the table: "cache" */
  update_cache?: Maybe<Cache_Mutation_Response>;
  /** update single row of the table: "cache" */
  update_cache_by_pk?: Maybe<Cache>;
  /** update multiples rows of table: "cache" */
  update_cache_many?: Maybe<Array<Cache_Mutation_Response>>;
  /** update data of the table: "change_in" */
  update_change_in?: Maybe<Change_In_Mutation_Response>;
  /** update single row of the table: "change_in" */
  update_change_in_by_pk?: Maybe<Change_In>;
  /** update multiples rows of table: "change_in" */
  update_change_in_many?: Maybe<Array<Change_In_Mutation_Response>>;
  /** update data of the table: "change_status" */
  update_change_status?: Maybe<Change_Status_Mutation_Response>;
  /** update single row of the table: "change_status" */
  update_change_status_by_pk?: Maybe<Change_Status>;
  /** update multiples rows of table: "change_status" */
  update_change_status_many?: Maybe<Array<Change_Status_Mutation_Response>>;
  /** update data of the table: "code" */
  update_code?: Maybe<Code_Mutation_Response>;
  /** update single row of the table: "code" */
  update_code_by_pk?: Maybe<Code>;
  /** update multiples rows of table: "code" */
  update_code_many?: Maybe<Array<Code_Mutation_Response>>;
  /** update data of the table: "commission" */
  update_commission?: Maybe<Commission_Mutation_Response>;
  /** update single row of the table: "commission" */
  update_commission_by_pk?: Maybe<Commission>;
  /** update multiples rows of table: "commission" */
  update_commission_many?: Maybe<Array<Commission_Mutation_Response>>;
  /** update data of the table: "commission_type" */
  update_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** update single row of the table: "commission_type" */
  update_commission_type_by_pk?: Maybe<Commission_Type>;
  /** update multiples rows of table: "commission_type" */
  update_commission_type_many?: Maybe<Array<Commission_Type_Mutation_Response>>;
  /** update data of the table: "contact" */
  update_contact?: Maybe<Contact_Mutation_Response>;
  /** update single row of the table: "contact" */
  update_contact_by_pk?: Maybe<Contact>;
  /** update multiples rows of table: "contact" */
  update_contact_many?: Maybe<Array<Contact_Mutation_Response>>;
  /** update data of the table: "contact_type" */
  update_contact_type?: Maybe<Contact_Type_Mutation_Response>;
  /** update single row of the table: "contact_type" */
  update_contact_type_by_pk?: Maybe<Contact_Type>;
  /** update multiples rows of table: "contact_type" */
  update_contact_type_many?: Maybe<Array<Contact_Type_Mutation_Response>>;
  /** update data of the table: "count_with_rolling_avg" */
  update_count_with_rolling_avg?: Maybe<Count_With_Rolling_Avg_Mutation_Response>;
  /** update single row of the table: "count_with_rolling_avg" */
  update_count_with_rolling_avg_by_pk?: Maybe<Count_With_Rolling_Avg>;
  /** update multiples rows of table: "count_with_rolling_avg" */
  update_count_with_rolling_avg_many?: Maybe<Array<Count_With_Rolling_Avg_Mutation_Response>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Country_Mutation_Response>>;
  /** update data of the table: "crm_connection" */
  update_crm_connection?: Maybe<Crm_Connection_Mutation_Response>;
  /** update single row of the table: "crm_connection" */
  update_crm_connection_by_pk?: Maybe<Crm_Connection>;
  /** update multiples rows of table: "crm_connection" */
  update_crm_connection_many?: Maybe<Array<Crm_Connection_Mutation_Response>>;
  /** update data of the table: "deal" */
  update_deal?: Maybe<Deal_Mutation_Response>;
  /** update single row of the table: "deal" */
  update_deal_by_pk?: Maybe<Deal>;
  /** update data of the table: "deal_contact" */
  update_deal_contact?: Maybe<Deal_Contact_Mutation_Response>;
  /** update single row of the table: "deal_contact" */
  update_deal_contact_by_pk?: Maybe<Deal_Contact>;
  /** update multiples rows of table: "deal_contact" */
  update_deal_contact_many?: Maybe<Array<Deal_Contact_Mutation_Response>>;
  /** update data of the table: "deal_contact_status" */
  update_deal_contact_status?: Maybe<Deal_Contact_Status_Mutation_Response>;
  /** update single row of the table: "deal_contact_status" */
  update_deal_contact_status_by_pk?: Maybe<Deal_Contact_Status>;
  /** update multiples rows of table: "deal_contact_status" */
  update_deal_contact_status_many?: Maybe<Array<Deal_Contact_Status_Mutation_Response>>;
  update_deal_contract?: Maybe<DealContractResponse>;
  /** update data of the table: "deal_deposit_status" */
  update_deal_deposit_status?: Maybe<Deal_Deposit_Status_Mutation_Response>;
  /** update single row of the table: "deal_deposit_status" */
  update_deal_deposit_status_by_pk?: Maybe<Deal_Deposit_Status>;
  /** update multiples rows of table: "deal_deposit_status" */
  update_deal_deposit_status_many?: Maybe<Array<Deal_Deposit_Status_Mutation_Response>>;
  /** update data of the table: "deal_deposit_type" */
  update_deal_deposit_type?: Maybe<Deal_Deposit_Type_Mutation_Response>;
  /** update single row of the table: "deal_deposit_type" */
  update_deal_deposit_type_by_pk?: Maybe<Deal_Deposit_Type>;
  /** update multiples rows of table: "deal_deposit_type" */
  update_deal_deposit_type_many?: Maybe<Array<Deal_Deposit_Type_Mutation_Response>>;
  /** update data of the table: "deal_enquiry_source" */
  update_deal_enquiry_source?: Maybe<Deal_Enquiry_Source_Mutation_Response>;
  /** update single row of the table: "deal_enquiry_source" */
  update_deal_enquiry_source_by_pk?: Maybe<Deal_Enquiry_Source>;
  /** update multiples rows of table: "deal_enquiry_source" */
  update_deal_enquiry_source_many?: Maybe<Array<Deal_Enquiry_Source_Mutation_Response>>;
  /** update data of the table: "deal_fallen_reason" */
  update_deal_fallen_reason?: Maybe<Deal_Fallen_Reason_Mutation_Response>;
  /** update single row of the table: "deal_fallen_reason" */
  update_deal_fallen_reason_by_pk?: Maybe<Deal_Fallen_Reason>;
  /** update multiples rows of table: "deal_fallen_reason" */
  update_deal_fallen_reason_many?: Maybe<Array<Deal_Fallen_Reason_Mutation_Response>>;
  /** update multiples rows of table: "deal" */
  update_deal_many?: Maybe<Array<Deal_Mutation_Response>>;
  /** update data of the table: "deal_payment_method" */
  update_deal_payment_method?: Maybe<Deal_Payment_Method_Mutation_Response>;
  /** update single row of the table: "deal_payment_method" */
  update_deal_payment_method_by_pk?: Maybe<Deal_Payment_Method>;
  /** update multiples rows of table: "deal_payment_method" */
  update_deal_payment_method_many?: Maybe<Array<Deal_Payment_Method_Mutation_Response>>;
  /** update data of the table: "deal_progress_update" */
  update_deal_progress_update?: Maybe<Deal_Progress_Update_Mutation_Response>;
  /** update single row of the table: "deal_progress_update" */
  update_deal_progress_update_by_pk?: Maybe<Deal_Progress_Update>;
  /** update multiples rows of table: "deal_progress_update" */
  update_deal_progress_update_many?: Maybe<Array<Deal_Progress_Update_Mutation_Response>>;
  /** update data of the table: "deal_reason_purchase" */
  update_deal_reason_purchase?: Maybe<Deal_Reason_Purchase_Mutation_Response>;
  /** update single row of the table: "deal_reason_purchase" */
  update_deal_reason_purchase_by_pk?: Maybe<Deal_Reason_Purchase>;
  /** update multiples rows of table: "deal_reason_purchase" */
  update_deal_reason_purchase_many?: Maybe<Array<Deal_Reason_Purchase_Mutation_Response>>;
  /** update data of the table: "deal_sf_action" */
  update_deal_sf_action?: Maybe<Deal_Sf_Action_Mutation_Response>;
  /** update single row of the table: "deal_sf_action" */
  update_deal_sf_action_by_pk?: Maybe<Deal_Sf_Action>;
  /** update multiples rows of table: "deal_sf_action" */
  update_deal_sf_action_many?: Maybe<Array<Deal_Sf_Action_Mutation_Response>>;
  update_deal_solicitor?: Maybe<DealSolicitorResponse>;
  update_deal_solicitor_and_contract?: Maybe<HasuraJsonResponse>;
  /** update data of the table: "deal_spoken_language" */
  update_deal_spoken_language?: Maybe<Deal_Spoken_Language_Mutation_Response>;
  /** update single row of the table: "deal_spoken_language" */
  update_deal_spoken_language_by_pk?: Maybe<Deal_Spoken_Language>;
  /** update multiples rows of table: "deal_spoken_language" */
  update_deal_spoken_language_many?: Maybe<Array<Deal_Spoken_Language_Mutation_Response>>;
  /** update data of the table: "deal_status" */
  update_deal_status?: Maybe<Deal_Status_Mutation_Response>;
  /** update single row of the table: "deal_status" */
  update_deal_status_by_pk?: Maybe<Deal_Status>;
  /** update multiples rows of table: "deal_status" */
  update_deal_status_many?: Maybe<Array<Deal_Status_Mutation_Response>>;
  /** update data of the table: "deal_transfer_status" */
  update_deal_transfer_status?: Maybe<Deal_Transfer_Status_Mutation_Response>;
  /** update single row of the table: "deal_transfer_status" */
  update_deal_transfer_status_by_pk?: Maybe<Deal_Transfer_Status>;
  /** update multiples rows of table: "deal_transfer_status" */
  update_deal_transfer_status_many?: Maybe<Array<Deal_Transfer_Status_Mutation_Response>>;
  /** update data of the table: "deal_type" */
  update_deal_type?: Maybe<Deal_Type_Mutation_Response>;
  /** update single row of the table: "deal_type" */
  update_deal_type_by_pk?: Maybe<Deal_Type>;
  /** update multiples rows of table: "deal_type" */
  update_deal_type_many?: Maybe<Array<Deal_Type_Mutation_Response>>;
  /** update data of the table: "deal_valuation_booking" */
  update_deal_valuation_booking?: Maybe<Deal_Valuation_Booking_Mutation_Response>;
  /** update single row of the table: "deal_valuation_booking" */
  update_deal_valuation_booking_by_pk?: Maybe<Deal_Valuation_Booking>;
  /** update multiples rows of table: "deal_valuation_booking" */
  update_deal_valuation_booking_many?: Maybe<Array<Deal_Valuation_Booking_Mutation_Response>>;
  /** update data of the table: "deal_witness" */
  update_deal_witness?: Maybe<Deal_Witness_Mutation_Response>;
  /** update single row of the table: "deal_witness" */
  update_deal_witness_by_pk?: Maybe<Deal_Witness>;
  /** update multiples rows of table: "deal_witness" */
  update_deal_witness_many?: Maybe<Array<Deal_Witness_Mutation_Response>>;
  /** update data of the table: "developer_agent" */
  update_developer_agent?: Maybe<Developer_Agent_Mutation_Response>;
  /** update single row of the table: "developer_agent" */
  update_developer_agent_by_pk?: Maybe<Developer_Agent>;
  /** update multiples rows of table: "developer_agent" */
  update_developer_agent_many?: Maybe<Array<Developer_Agent_Mutation_Response>>;
  /** update data of the table: "document" */
  update_document?: Maybe<Document_Mutation_Response>;
  /** update single row of the table: "document" */
  update_document_by_pk?: Maybe<Document>;
  /** update multiples rows of table: "document" */
  update_document_many?: Maybe<Array<Document_Mutation_Response>>;
  /** update data of the table: "document_type" */
  update_document_type?: Maybe<Document_Type_Mutation_Response>;
  /** update single row of the table: "document_type" */
  update_document_type_by_pk?: Maybe<Document_Type>;
  /** update multiples rows of table: "document_type" */
  update_document_type_many?: Maybe<Array<Document_Type_Mutation_Response>>;
  /** update data of the table: "embed" */
  update_embed?: Maybe<Embed_Mutation_Response>;
  /** update single row of the table: "embed" */
  update_embed_by_pk?: Maybe<Embed>;
  /** update multiples rows of table: "embed" */
  update_embed_many?: Maybe<Array<Embed_Mutation_Response>>;
  update_enquiry?: Maybe<EnquiryResponse>;
  /** update data of the table: "envelope" */
  update_envelope?: Maybe<Envelope_Mutation_Response>;
  /** update single row of the table: "envelope" */
  update_envelope_by_pk?: Maybe<Envelope>;
  /** update multiples rows of table: "envelope" */
  update_envelope_many?: Maybe<Array<Envelope_Mutation_Response>>;
  /** update data of the table: "envelope_owner" */
  update_envelope_owner?: Maybe<Envelope_Owner_Mutation_Response>;
  /** update single row of the table: "envelope_owner" */
  update_envelope_owner_by_pk?: Maybe<Envelope_Owner>;
  /** update multiples rows of table: "envelope_owner" */
  update_envelope_owner_many?: Maybe<Array<Envelope_Owner_Mutation_Response>>;
  /** update data of the table: "envelope_status" */
  update_envelope_status?: Maybe<Envelope_Status_Mutation_Response>;
  /** update single row of the table: "envelope_status" */
  update_envelope_status_by_pk?: Maybe<Envelope_Status>;
  /** update multiples rows of table: "envelope_status" */
  update_envelope_status_many?: Maybe<Array<Envelope_Status_Mutation_Response>>;
  /** update data of the table: "envelope_type" */
  update_envelope_type?: Maybe<Envelope_Type_Mutation_Response>;
  /** update single row of the table: "envelope_type" */
  update_envelope_type_by_pk?: Maybe<Envelope_Type>;
  /** update multiples rows of table: "envelope_type" */
  update_envelope_type_many?: Maybe<Array<Envelope_Type_Mutation_Response>>;
  /** update data of the table: "experiment" */
  update_experiment?: Maybe<Experiment_Mutation_Response>;
  /** update single row of the table: "experiment" */
  update_experiment_by_pk?: Maybe<Experiment>;
  /** update multiples rows of table: "experiment" */
  update_experiment_many?: Maybe<Array<Experiment_Mutation_Response>>;
  /** update data of the table: "feature" */
  update_feature?: Maybe<Feature_Mutation_Response>;
  /** update single row of the table: "feature" */
  update_feature_by_pk?: Maybe<Feature>;
  /** update data of the table: "feature_check" */
  update_feature_check?: Maybe<Feature_Check_Mutation_Response>;
  /** update single row of the table: "feature_check" */
  update_feature_check_by_pk?: Maybe<Feature_Check>;
  /** update multiples rows of table: "feature_check" */
  update_feature_check_many?: Maybe<Array<Feature_Check_Mutation_Response>>;
  /** update multiples rows of table: "feature" */
  update_feature_many?: Maybe<Array<Feature_Mutation_Response>>;
  /** update data of the table: "feature_pack" */
  update_feature_pack?: Maybe<Feature_Pack_Mutation_Response>;
  /** update single row of the table: "feature_pack" */
  update_feature_pack_by_pk?: Maybe<Feature_Pack>;
  /** update data of the table: "feature_pack_feature" */
  update_feature_pack_feature?: Maybe<Feature_Pack_Feature_Mutation_Response>;
  /** update single row of the table: "feature_pack_feature" */
  update_feature_pack_feature_by_pk?: Maybe<Feature_Pack_Feature>;
  /** update multiples rows of table: "feature_pack_feature" */
  update_feature_pack_feature_many?: Maybe<Array<Feature_Pack_Feature_Mutation_Response>>;
  /** update multiples rows of table: "feature_pack" */
  update_feature_pack_many?: Maybe<Array<Feature_Pack_Mutation_Response>>;
  /** update data of the table: "filter_page" */
  update_filter_page?: Maybe<Filter_Page_Mutation_Response>;
  /** update single row of the table: "filter_page" */
  update_filter_page_by_pk?: Maybe<Filter_Page>;
  /** update multiples rows of table: "filter_page" */
  update_filter_page_many?: Maybe<Array<Filter_Page_Mutation_Response>>;
  /** update data of the table: "filter_state" */
  update_filter_state?: Maybe<Filter_State_Mutation_Response>;
  /** update single row of the table: "filter_state" */
  update_filter_state_by_pk?: Maybe<Filter_State>;
  /** update multiples rows of table: "filter_state" */
  update_filter_state_many?: Maybe<Array<Filter_State_Mutation_Response>>;
  /** update data of the table: "finance_status" */
  update_finance_status?: Maybe<Finance_Status_Mutation_Response>;
  /** update single row of the table: "finance_status" */
  update_finance_status_by_pk?: Maybe<Finance_Status>;
  /** update multiples rows of table: "finance_status" */
  update_finance_status_many?: Maybe<Array<Finance_Status_Mutation_Response>>;
  /** update data of the table: "graphql_query_log" */
  update_graphql_query_log?: Maybe<Graphql_Query_Log_Mutation_Response>;
  /** update single row of the table: "graphql_query_log" */
  update_graphql_query_log_by_pk?: Maybe<Graphql_Query_Log>;
  /** update multiples rows of table: "graphql_query_log" */
  update_graphql_query_log_many?: Maybe<Array<Graphql_Query_Log_Mutation_Response>>;
  /** update data of the table: "hubspot_action" */
  update_hubspot_action?: Maybe<Hubspot_Action_Mutation_Response>;
  /** update single row of the table: "hubspot_action" */
  update_hubspot_action_by_pk?: Maybe<Hubspot_Action>;
  /** update multiples rows of table: "hubspot_action" */
  update_hubspot_action_many?: Maybe<Array<Hubspot_Action_Mutation_Response>>;
  /** update data of the table: "hubspot_action_type" */
  update_hubspot_action_type?: Maybe<Hubspot_Action_Type_Mutation_Response>;
  /** update single row of the table: "hubspot_action_type" */
  update_hubspot_action_type_by_pk?: Maybe<Hubspot_Action_Type>;
  /** update multiples rows of table: "hubspot_action_type" */
  update_hubspot_action_type_many?: Maybe<Array<Hubspot_Action_Type_Mutation_Response>>;
  /** update data of the table: "hubspot_contact" */
  update_hubspot_contact?: Maybe<Hubspot_Contact_Mutation_Response>;
  /** update single row of the table: "hubspot_contact" */
  update_hubspot_contact_by_pk?: Maybe<Hubspot_Contact>;
  /** update multiples rows of table: "hubspot_contact" */
  update_hubspot_contact_many?: Maybe<Array<Hubspot_Contact_Mutation_Response>>;
  /** update data of the table: "hubspot_deal" */
  update_hubspot_deal?: Maybe<Hubspot_Deal_Mutation_Response>;
  /** update single row of the table: "hubspot_deal" */
  update_hubspot_deal_by_pk?: Maybe<Hubspot_Deal>;
  /** update multiples rows of table: "hubspot_deal" */
  update_hubspot_deal_many?: Maybe<Array<Hubspot_Deal_Mutation_Response>>;
  /** update data of the table: "hubspot_organisation" */
  update_hubspot_organisation?: Maybe<Hubspot_Organisation_Mutation_Response>;
  /** update single row of the table: "hubspot_organisation" */
  update_hubspot_organisation_by_pk?: Maybe<Hubspot_Organisation>;
  /** update multiples rows of table: "hubspot_organisation" */
  update_hubspot_organisation_many?: Maybe<Array<Hubspot_Organisation_Mutation_Response>>;
  /** update data of the table: "hubspot_project" */
  update_hubspot_project?: Maybe<Hubspot_Project_Mutation_Response>;
  /** update single row of the table: "hubspot_project" */
  update_hubspot_project_by_pk?: Maybe<Hubspot_Project>;
  /** update multiples rows of table: "hubspot_project" */
  update_hubspot_project_many?: Maybe<Array<Hubspot_Project_Mutation_Response>>;
  /** update data of the table: "hubspot_schema_error_log" */
  update_hubspot_schema_error_log?: Maybe<Hubspot_Schema_Error_Log_Mutation_Response>;
  /** update single row of the table: "hubspot_schema_error_log" */
  update_hubspot_schema_error_log_by_pk?: Maybe<Hubspot_Schema_Error_Log>;
  /** update multiples rows of table: "hubspot_schema_error_log" */
  update_hubspot_schema_error_log_many?: Maybe<Array<Hubspot_Schema_Error_Log_Mutation_Response>>;
  /** update data of the table: "hubspot_user" */
  update_hubspot_user?: Maybe<Hubspot_User_Mutation_Response>;
  /** update single row of the table: "hubspot_user" */
  update_hubspot_user_by_pk?: Maybe<Hubspot_User>;
  /** update multiples rows of table: "hubspot_user" */
  update_hubspot_user_many?: Maybe<Array<Hubspot_User_Mutation_Response>>;
  /** update data of the table: "hubspot_valuation_booking" */
  update_hubspot_valuation_booking?: Maybe<Hubspot_Valuation_Booking_Mutation_Response>;
  /** update single row of the table: "hubspot_valuation_booking" */
  update_hubspot_valuation_booking_by_pk?: Maybe<Hubspot_Valuation_Booking>;
  /** update multiples rows of table: "hubspot_valuation_booking" */
  update_hubspot_valuation_booking_many?: Maybe<Array<Hubspot_Valuation_Booking_Mutation_Response>>;
  /** update data of the table: "invitation" */
  update_invitation?: Maybe<Invitation_Mutation_Response>;
  /** update single row of the table: "invitation" */
  update_invitation_by_pk?: Maybe<Invitation>;
  /** update multiples rows of table: "invitation" */
  update_invitation_many?: Maybe<Array<Invitation_Mutation_Response>>;
  /** update data of the table: "invitation_status" */
  update_invitation_status?: Maybe<Invitation_Status_Mutation_Response>;
  /** update single row of the table: "invitation_status" */
  update_invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** update multiples rows of table: "invitation_status" */
  update_invitation_status_many?: Maybe<Array<Invitation_Status_Mutation_Response>>;
  /** update data of the table: "invitation_type" */
  update_invitation_type?: Maybe<Invitation_Type_Mutation_Response>;
  /** update single row of the table: "invitation_type" */
  update_invitation_type_by_pk?: Maybe<Invitation_Type>;
  /** update multiples rows of table: "invitation_type" */
  update_invitation_type_many?: Maybe<Array<Invitation_Type_Mutation_Response>>;
  /** update data of the table: "lambda_log" */
  update_lambda_log?: Maybe<Lambda_Log_Mutation_Response>;
  /** update single row of the table: "lambda_log" */
  update_lambda_log_by_pk?: Maybe<Lambda_Log>;
  /** update multiples rows of table: "lambda_log" */
  update_lambda_log_many?: Maybe<Array<Lambda_Log_Mutation_Response>>;
  /** update data of the table: "lead_generation" */
  update_lead_generation?: Maybe<Lead_Generation_Mutation_Response>;
  /** update single row of the table: "lead_generation" */
  update_lead_generation_by_pk?: Maybe<Lead_Generation>;
  /** update multiples rows of table: "lead_generation" */
  update_lead_generation_many?: Maybe<Array<Lead_Generation_Mutation_Response>>;
  /** update data of the table: "lead_generation_source" */
  update_lead_generation_source?: Maybe<Lead_Generation_Source_Mutation_Response>;
  /** update single row of the table: "lead_generation_source" */
  update_lead_generation_source_by_pk?: Maybe<Lead_Generation_Source>;
  /** update multiples rows of table: "lead_generation_source" */
  update_lead_generation_source_many?: Maybe<Array<Lead_Generation_Source_Mutation_Response>>;
  /** update data of the table: "lead_generation_webhook_log" */
  update_lead_generation_webhook_log?: Maybe<Lead_Generation_Webhook_Log_Mutation_Response>;
  /** update single row of the table: "lead_generation_webhook_log" */
  update_lead_generation_webhook_log_by_pk?: Maybe<Lead_Generation_Webhook_Log>;
  /** update multiples rows of table: "lead_generation_webhook_log" */
  update_lead_generation_webhook_log_many?: Maybe<Array<Lead_Generation_Webhook_Log_Mutation_Response>>;
  /** update data of the table: "listing" */
  update_listing?: Maybe<Listing_Mutation_Response>;
  /** update single row of the table: "listing" */
  update_listing_by_pk?: Maybe<Listing>;
  /** update multiples rows of table: "listing" */
  update_listing_many?: Maybe<Array<Listing_Mutation_Response>>;
  /** update data of the table: "listing_publish_errors" */
  update_listing_publish_errors?: Maybe<Listing_Publish_Errors_Mutation_Response>;
  /** update single row of the table: "listing_publish_errors" */
  update_listing_publish_errors_by_pk?: Maybe<Listing_Publish_Errors>;
  /** update multiples rows of table: "listing_publish_errors" */
  update_listing_publish_errors_many?: Maybe<Array<Listing_Publish_Errors_Mutation_Response>>;
  /** update data of the table: "listing_publish_portal_source" */
  update_listing_publish_portal_source?: Maybe<Listing_Publish_Portal_Source_Mutation_Response>;
  /** update single row of the table: "listing_publish_portal_source" */
  update_listing_publish_portal_source_by_pk?: Maybe<Listing_Publish_Portal_Source>;
  /** update multiples rows of table: "listing_publish_portal_source" */
  update_listing_publish_portal_source_many?: Maybe<Array<Listing_Publish_Portal_Source_Mutation_Response>>;
  /** update data of the table: "listing_publish_status" */
  update_listing_publish_status?: Maybe<Listing_Publish_Status_Mutation_Response>;
  /** update single row of the table: "listing_publish_status" */
  update_listing_publish_status_by_pk?: Maybe<Listing_Publish_Status>;
  /** update multiples rows of table: "listing_publish_status" */
  update_listing_publish_status_many?: Maybe<Array<Listing_Publish_Status_Mutation_Response>>;
  /** update data of the table: "listing_publish_status_type" */
  update_listing_publish_status_type?: Maybe<Listing_Publish_Status_Type_Mutation_Response>;
  /** update single row of the table: "listing_publish_status_type" */
  update_listing_publish_status_type_by_pk?: Maybe<Listing_Publish_Status_Type>;
  /** update multiples rows of table: "listing_publish_status_type" */
  update_listing_publish_status_type_many?: Maybe<Array<Listing_Publish_Status_Type_Mutation_Response>>;
  /** update data of the table: "listing_status" */
  update_listing_status?: Maybe<Listing_Status_Mutation_Response>;
  /** update single row of the table: "listing_status" */
  update_listing_status_by_pk?: Maybe<Listing_Status>;
  /** update multiples rows of table: "listing_status" */
  update_listing_status_many?: Maybe<Array<Listing_Status_Mutation_Response>>;
  /** update data of the table: "member" */
  update_member?: Maybe<Member_Mutation_Response>;
  /** update single row of the table: "member" */
  update_member_by_pk?: Maybe<Member>;
  /** update data of the table: "member_feature" */
  update_member_feature?: Maybe<Member_Feature_Mutation_Response>;
  /** update single row of the table: "member_feature" */
  update_member_feature_by_pk?: Maybe<Member_Feature>;
  /** update multiples rows of table: "member_feature" */
  update_member_feature_many?: Maybe<Array<Member_Feature_Mutation_Response>>;
  /** update multiples rows of table: "member" */
  update_member_many?: Maybe<Array<Member_Mutation_Response>>;
  /** update data of the table: "member_project_allocate" */
  update_member_project_allocate?: Maybe<Member_Project_Allocate_Mutation_Response>;
  /** update single row of the table: "member_project_allocate" */
  update_member_project_allocate_by_pk?: Maybe<Member_Project_Allocate>;
  /** update multiples rows of table: "member_project_allocate" */
  update_member_project_allocate_many?: Maybe<Array<Member_Project_Allocate_Mutation_Response>>;
  /** update data of the table: "member_role" */
  update_member_role?: Maybe<Member_Role_Mutation_Response>;
  /** update single row of the table: "member_role" */
  update_member_role_by_pk?: Maybe<Member_Role>;
  /** update multiples rows of table: "member_role" */
  update_member_role_many?: Maybe<Array<Member_Role_Mutation_Response>>;
  /** update data of the table: "nationality" */
  update_nationality?: Maybe<Nationality_Mutation_Response>;
  /** update single row of the table: "nationality" */
  update_nationality_by_pk?: Maybe<Nationality>;
  /** update multiples rows of table: "nationality" */
  update_nationality_many?: Maybe<Array<Nationality_Mutation_Response>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update data of the table: "notification_channel" */
  update_notification_channel?: Maybe<Notification_Channel_Mutation_Response>;
  /** update single row of the table: "notification_channel" */
  update_notification_channel_by_pk?: Maybe<Notification_Channel>;
  /** update multiples rows of table: "notification_channel" */
  update_notification_channel_many?: Maybe<Array<Notification_Channel_Mutation_Response>>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Notification_Mutation_Response>>;
  /** update data of the table: "notification_owner" */
  update_notification_owner?: Maybe<Notification_Owner_Mutation_Response>;
  /** update single row of the table: "notification_owner" */
  update_notification_owner_by_pk?: Maybe<Notification_Owner>;
  /** update multiples rows of table: "notification_owner" */
  update_notification_owner_many?: Maybe<Array<Notification_Owner_Mutation_Response>>;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<Notification_Type>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<Array<Notification_Type_Mutation_Response>>;
  /** update data of the table: "org_feature_pack" */
  update_org_feature_pack?: Maybe<Org_Feature_Pack_Mutation_Response>;
  /** update single row of the table: "org_feature_pack" */
  update_org_feature_pack_by_pk?: Maybe<Org_Feature_Pack>;
  /** update multiples rows of table: "org_feature_pack" */
  update_org_feature_pack_many?: Maybe<Array<Org_Feature_Pack_Mutation_Response>>;
  /** update data of the table: "organisation" */
  update_organisation?: Maybe<Organisation_Mutation_Response>;
  /** update single row of the table: "organisation" */
  update_organisation_by_pk?: Maybe<Organisation>;
  /** update data of the table: "organisation_feature" */
  update_organisation_feature?: Maybe<Organisation_Feature_Mutation_Response>;
  /** update single row of the table: "organisation_feature" */
  update_organisation_feature_by_pk?: Maybe<Organisation_Feature>;
  /** update multiples rows of table: "organisation_feature" */
  update_organisation_feature_many?: Maybe<Array<Organisation_Feature_Mutation_Response>>;
  /** update multiples rows of table: "organisation" */
  update_organisation_many?: Maybe<Array<Organisation_Mutation_Response>>;
  /** update data of the table: "organisation_type" */
  update_organisation_type?: Maybe<Organisation_Type_Mutation_Response>;
  /** update single row of the table: "organisation_type" */
  update_organisation_type_by_pk?: Maybe<Organisation_Type>;
  /** update multiples rows of table: "organisation_type" */
  update_organisation_type_many?: Maybe<Array<Organisation_Type_Mutation_Response>>;
  /** update data of the table: "partner_portal" */
  update_partner_portal?: Maybe<Partner_Portal_Mutation_Response>;
  /** update single row of the table: "partner_portal" */
  update_partner_portal_by_pk?: Maybe<Partner_Portal>;
  /** update multiples rows of table: "partner_portal" */
  update_partner_portal_many?: Maybe<Array<Partner_Portal_Mutation_Response>>;
  /** update data of the table: "payment_method" */
  update_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** update single row of the table: "payment_method" */
  update_payment_method_by_pk?: Maybe<Payment_Method>;
  /** update multiples rows of table: "payment_method" */
  update_payment_method_many?: Maybe<Array<Payment_Method_Mutation_Response>>;
  /** update data of the table: "payment_method_type" */
  update_payment_method_type?: Maybe<Payment_Method_Type_Mutation_Response>;
  /** update single row of the table: "payment_method_type" */
  update_payment_method_type_by_pk?: Maybe<Payment_Method_Type>;
  /** update multiples rows of table: "payment_method_type" */
  update_payment_method_type_many?: Maybe<Array<Payment_Method_Type_Mutation_Response>>;
  /** update data of the table: "preference" */
  update_preference?: Maybe<Preference_Mutation_Response>;
  /** update single row of the table: "preference" */
  update_preference_by_pk?: Maybe<Preference>;
  /** update multiples rows of table: "preference" */
  update_preference_many?: Maybe<Array<Preference_Mutation_Response>>;
  /** update data of the table: "process" */
  update_process?: Maybe<Process_Mutation_Response>;
  /** update single row of the table: "process" */
  update_process_by_pk?: Maybe<Process>;
  /** update multiples rows of table: "process" */
  update_process_many?: Maybe<Array<Process_Mutation_Response>>;
  /** update data of the table: "process_status" */
  update_process_status?: Maybe<Process_Status_Mutation_Response>;
  /** update single row of the table: "process_status" */
  update_process_status_by_pk?: Maybe<Process_Status>;
  /** update multiples rows of table: "process_status" */
  update_process_status_many?: Maybe<Array<Process_Status_Mutation_Response>>;
  /** update data of the table: "project" */
  update_project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<Project>;
  /** update data of the table: "project_detail" */
  update_project_detail?: Maybe<Project_Detail_Mutation_Response>;
  /** update single row of the table: "project_detail" */
  update_project_detail_by_pk?: Maybe<Project_Detail>;
  /** update multiples rows of table: "project_detail" */
  update_project_detail_many?: Maybe<Array<Project_Detail_Mutation_Response>>;
  /** update data of the table: "project_features" */
  update_project_features?: Maybe<Project_Features_Mutation_Response>;
  /** update single row of the table: "project_features" */
  update_project_features_by_pk?: Maybe<Project_Features>;
  /** update multiples rows of table: "project_features" */
  update_project_features_many?: Maybe<Array<Project_Features_Mutation_Response>>;
  /** update multiples rows of table: "project" */
  update_project_many?: Maybe<Array<Project_Mutation_Response>>;
  /** update data of the table: "project_payment_method" */
  update_project_payment_method?: Maybe<Project_Payment_Method_Mutation_Response>;
  /** update single row of the table: "project_payment_method" */
  update_project_payment_method_by_pk?: Maybe<Project_Payment_Method>;
  /** update multiples rows of table: "project_payment_method" */
  update_project_payment_method_many?: Maybe<Array<Project_Payment_Method_Mutation_Response>>;
  /** update data of the table: "project_qr_code_for_buyer" */
  update_project_qr_code_for_buyer?: Maybe<Project_Qr_Code_For_Buyer_Mutation_Response>;
  /** update single row of the table: "project_qr_code_for_buyer" */
  update_project_qr_code_for_buyer_by_pk?: Maybe<Project_Qr_Code_For_Buyer>;
  /** update multiples rows of table: "project_qr_code_for_buyer" */
  update_project_qr_code_for_buyer_many?: Maybe<Array<Project_Qr_Code_For_Buyer_Mutation_Response>>;
  /** update data of the table: "project_reservation_setting" */
  update_project_reservation_setting?: Maybe<Project_Reservation_Setting_Mutation_Response>;
  /** update single row of the table: "project_reservation_setting" */
  update_project_reservation_setting_by_pk?: Maybe<Project_Reservation_Setting>;
  /** update multiples rows of table: "project_reservation_setting" */
  update_project_reservation_setting_many?: Maybe<Array<Project_Reservation_Setting_Mutation_Response>>;
  /** update data of the table: "project_sources" */
  update_project_sources?: Maybe<Project_Sources_Mutation_Response>;
  /** update single row of the table: "project_sources" */
  update_project_sources_by_pk?: Maybe<Project_Sources>;
  /** update multiples rows of table: "project_sources" */
  update_project_sources_many?: Maybe<Array<Project_Sources_Mutation_Response>>;
  /** update data of the table: "project_status" */
  update_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** update single row of the table: "project_status" */
  update_project_status_by_pk?: Maybe<Project_Status>;
  /** update multiples rows of table: "project_status" */
  update_project_status_many?: Maybe<Array<Project_Status_Mutation_Response>>;
  /** update data of the table: "project_type" */
  update_project_type?: Maybe<Project_Type_Mutation_Response>;
  /** update single row of the table: "project_type" */
  update_project_type_by_pk?: Maybe<Project_Type>;
  /** update multiples rows of table: "project_type" */
  update_project_type_many?: Maybe<Array<Project_Type_Mutation_Response>>;
  /** update data of the table: "property" */
  update_property?: Maybe<Property_Mutation_Response>;
  /** update single row of the table: "property" */
  update_property_by_pk?: Maybe<Property>;
  /** update data of the table: "property_completion_status" */
  update_property_completion_status?: Maybe<Property_Completion_Status_Mutation_Response>;
  /** update single row of the table: "property_completion_status" */
  update_property_completion_status_by_pk?: Maybe<Property_Completion_Status>;
  /** update multiples rows of table: "property_completion_status" */
  update_property_completion_status_many?: Maybe<Array<Property_Completion_Status_Mutation_Response>>;
  /** update data of the table: "property_group" */
  update_property_group?: Maybe<Property_Group_Mutation_Response>;
  /** update single row of the table: "property_group" */
  update_property_group_by_pk?: Maybe<Property_Group>;
  /** update data of the table: "property_group_completion_status" */
  update_property_group_completion_status?: Maybe<Property_Group_Completion_Status_Mutation_Response>;
  /** update single row of the table: "property_group_completion_status" */
  update_property_group_completion_status_by_pk?: Maybe<Property_Group_Completion_Status>;
  /** update multiples rows of table: "property_group_completion_status" */
  update_property_group_completion_status_many?: Maybe<Array<Property_Group_Completion_Status_Mutation_Response>>;
  /** update multiples rows of table: "property_group" */
  update_property_group_many?: Maybe<Array<Property_Group_Mutation_Response>>;
  /** update data of the table: "property_group_type" */
  update_property_group_type?: Maybe<Property_Group_Type_Mutation_Response>;
  /** update single row of the table: "property_group_type" */
  update_property_group_type_by_pk?: Maybe<Property_Group_Type>;
  /** update multiples rows of table: "property_group_type" */
  update_property_group_type_many?: Maybe<Array<Property_Group_Type_Mutation_Response>>;
  /** update multiples rows of table: "property" */
  update_property_many?: Maybe<Array<Property_Mutation_Response>>;
  /** update data of the table: "property_type" */
  update_property_type?: Maybe<Property_Type_Mutation_Response>;
  /** update single row of the table: "property_type" */
  update_property_type_by_pk?: Maybe<Property_Type>;
  /** update multiples rows of table: "property_type" */
  update_property_type_many?: Maybe<Array<Property_Type_Mutation_Response>>;
  /** update data of the table: "reservation" */
  update_reservation?: Maybe<Reservation_Mutation_Response>;
  /** update single row of the table: "reservation" */
  update_reservation_by_pk?: Maybe<Reservation>;
  /** update multiples rows of table: "reservation" */
  update_reservation_many?: Maybe<Array<Reservation_Mutation_Response>>;
  /** update data of the table: "salesforce_action" */
  update_salesforce_action?: Maybe<Salesforce_Action_Mutation_Response>;
  /** update single row of the table: "salesforce_action" */
  update_salesforce_action_by_pk?: Maybe<Salesforce_Action>;
  /** update multiples rows of table: "salesforce_action" */
  update_salesforce_action_many?: Maybe<Array<Salesforce_Action_Mutation_Response>>;
  /** update data of the table: "salesforce_action_status" */
  update_salesforce_action_status?: Maybe<Salesforce_Action_Status_Mutation_Response>;
  /** update single row of the table: "salesforce_action_status" */
  update_salesforce_action_status_by_pk?: Maybe<Salesforce_Action_Status>;
  /** update multiples rows of table: "salesforce_action_status" */
  update_salesforce_action_status_many?: Maybe<Array<Salesforce_Action_Status_Mutation_Response>>;
  /** update data of the table: "salesforce_bulk_import" */
  update_salesforce_bulk_import?: Maybe<Salesforce_Bulk_Import_Mutation_Response>;
  /** update single row of the table: "salesforce_bulk_import" */
  update_salesforce_bulk_import_by_pk?: Maybe<Salesforce_Bulk_Import>;
  /** update multiples rows of table: "salesforce_bulk_import" */
  update_salesforce_bulk_import_many?: Maybe<Array<Salesforce_Bulk_Import_Mutation_Response>>;
  /** update data of the table: "salesforce_connection" */
  update_salesforce_connection?: Maybe<Salesforce_Connection_Mutation_Response>;
  /** update single row of the table: "salesforce_connection" */
  update_salesforce_connection_by_pk?: Maybe<Salesforce_Connection>;
  /** update multiples rows of table: "salesforce_connection" */
  update_salesforce_connection_many?: Maybe<Array<Salesforce_Connection_Mutation_Response>>;
  /** update data of the table: "salesforce_sync_status" */
  update_salesforce_sync_status?: Maybe<Salesforce_Sync_Status_Mutation_Response>;
  /** update single row of the table: "salesforce_sync_status" */
  update_salesforce_sync_status_by_pk?: Maybe<Salesforce_Sync_Status>;
  /** update multiples rows of table: "salesforce_sync_status" */
  update_salesforce_sync_status_many?: Maybe<Array<Salesforce_Sync_Status_Mutation_Response>>;
  /** update data of the table: "settings" */
  update_settings?: Maybe<Settings_Mutation_Response>;
  /** update single row of the table: "settings" */
  update_settings_by_pk?: Maybe<Settings>;
  /** update multiples rows of table: "settings" */
  update_settings_many?: Maybe<Array<Settings_Mutation_Response>>;
  /** update data of the table: "sf_action" */
  update_sf_action?: Maybe<Sf_Action_Mutation_Response>;
  /** update single row of the table: "sf_action" */
  update_sf_action_by_pk?: Maybe<Sf_Action>;
  /** update multiples rows of table: "sf_action" */
  update_sf_action_many?: Maybe<Array<Sf_Action_Mutation_Response>>;
  /** update data of the table: "sf_contact" */
  update_sf_contact?: Maybe<Sf_Contact_Mutation_Response>;
  /** update single row of the table: "sf_contact" */
  update_sf_contact_by_pk?: Maybe<Sf_Contact>;
  /** update multiples rows of table: "sf_contact" */
  update_sf_contact_many?: Maybe<Array<Sf_Contact_Mutation_Response>>;
  /** update data of the table: "sf_organisation" */
  update_sf_organisation?: Maybe<Sf_Organisation_Mutation_Response>;
  /** update single row of the table: "sf_organisation" */
  update_sf_organisation_by_pk?: Maybe<Sf_Organisation>;
  /** update multiples rows of table: "sf_organisation" */
  update_sf_organisation_many?: Maybe<Array<Sf_Organisation_Mutation_Response>>;
  /** update data of the table: "spoken_language" */
  update_spoken_language?: Maybe<Spoken_Language_Mutation_Response>;
  /** update single row of the table: "spoken_language" */
  update_spoken_language_by_pk?: Maybe<Spoken_Language>;
  /** update multiples rows of table: "spoken_language" */
  update_spoken_language_many?: Maybe<Array<Spoken_Language_Mutation_Response>>;
  /** update data of the table: "system" */
  update_system?: Maybe<System_Mutation_Response>;
  /** update single row of the table: "system" */
  update_system_by_pk?: Maybe<System>;
  /** update multiples rows of table: "system" */
  update_system_many?: Maybe<Array<System_Mutation_Response>>;
  /** update data of the table: "transaction" */
  update_transaction?: Maybe<Transaction_Mutation_Response>;
  /** update single row of the table: "transaction" */
  update_transaction_by_pk?: Maybe<Transaction>;
  /** update multiples rows of table: "transaction" */
  update_transaction_many?: Maybe<Array<Transaction_Mutation_Response>>;
  /** update data of the table: "transaction_status" */
  update_transaction_status?: Maybe<Transaction_Status_Mutation_Response>;
  /** update single row of the table: "transaction_status" */
  update_transaction_status_by_pk?: Maybe<Transaction_Status>;
  /** update multiples rows of table: "transaction_status" */
  update_transaction_status_many?: Maybe<Array<Transaction_Status_Mutation_Response>>;
  /** update data of the table: "transaction_type" */
  update_transaction_type?: Maybe<Transaction_Type_Mutation_Response>;
  /** update single row of the table: "transaction_type" */
  update_transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** update multiples rows of table: "transaction_type" */
  update_transaction_type_many?: Maybe<Array<Transaction_Type_Mutation_Response>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_experiment" */
  update_user_experiment?: Maybe<User_Experiment_Mutation_Response>;
  /** update single row of the table: "user_experiment" */
  update_user_experiment_by_pk?: Maybe<User_Experiment>;
  /** update multiples rows of table: "user_experiment" */
  update_user_experiment_many?: Maybe<Array<User_Experiment_Mutation_Response>>;
  /** update data of the table: "user_feature" */
  update_user_feature?: Maybe<User_Feature_Mutation_Response>;
  /** update single row of the table: "user_feature" */
  update_user_feature_by_pk?: Maybe<User_Feature>;
  /** update multiples rows of table: "user_feature" */
  update_user_feature_many?: Maybe<Array<User_Feature_Mutation_Response>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<User_Mutation_Response>>;
  /** update data of the table: "user_push_token" */
  update_user_push_token?: Maybe<User_Push_Token_Mutation_Response>;
  /** update single row of the table: "user_push_token" */
  update_user_push_token_by_pk?: Maybe<User_Push_Token>;
  /** update multiples rows of table: "user_push_token" */
  update_user_push_token_many?: Maybe<Array<User_Push_Token_Mutation_Response>>;
  /** update data of the table: "variation" */
  update_variation?: Maybe<Variation_Mutation_Response>;
  /** update single row of the table: "variation" */
  update_variation_by_pk?: Maybe<Variation>;
  /** update data of the table: "variation_category" */
  update_variation_category?: Maybe<Variation_Category_Mutation_Response>;
  /** update single row of the table: "variation_category" */
  update_variation_category_by_pk?: Maybe<Variation_Category>;
  /** update multiples rows of table: "variation_category" */
  update_variation_category_many?: Maybe<Array<Variation_Category_Mutation_Response>>;
  /** update multiples rows of table: "variation" */
  update_variation_many?: Maybe<Array<Variation_Mutation_Response>>;
  /** update data of the table: "variation_type" */
  update_variation_type?: Maybe<Variation_Type_Mutation_Response>;
  /** update single row of the table: "variation_type" */
  update_variation_type_by_pk?: Maybe<Variation_Type>;
  /** update data of the table: "variation_type_category" */
  update_variation_type_category?: Maybe<Variation_Type_Category_Mutation_Response>;
  /** update single row of the table: "variation_type_category" */
  update_variation_type_category_by_pk?: Maybe<Variation_Type_Category>;
  /** update multiples rows of table: "variation_type_category" */
  update_variation_type_category_many?: Maybe<Array<Variation_Type_Category_Mutation_Response>>;
  /** update multiples rows of table: "variation_type" */
  update_variation_type_many?: Maybe<Array<Variation_Type_Mutation_Response>>;
  /** update data of the table: "web_app_feature" */
  update_web_app_feature?: Maybe<Web_App_Feature_Mutation_Response>;
  /** update single row of the table: "web_app_feature" */
  update_web_app_feature_by_pk?: Maybe<Web_App_Feature>;
  /** update multiples rows of table: "web_app_feature" */
  update_web_app_feature_many?: Maybe<Array<Web_App_Feature_Mutation_Response>>;
  /** update data of the table: "websocket_log" */
  update_websocket_log?: Maybe<Websocket_Log_Mutation_Response>;
  /** update single row of the table: "websocket_log" */
  update_websocket_log_by_pk?: Maybe<Websocket_Log>;
  /** update multiples rows of table: "websocket_log" */
  update_websocket_log_many?: Maybe<Array<Websocket_Log_Mutation_Response>>;
  validate_driver_license?: Maybe<ValidateDriverLicenseOutput>;
  /** Validates manually uploaded attachment of ID_PROOF type, expiry date is required YYYY-MM-DD */
  validate_id_manual: JsonResponse;
  validate_medicare?: Maybe<ValidateMedicareOutput>;
  validate_passport?: Maybe<ValidatePassportOutput>;
};
export type Mutation_RootAgency_Agreement_SendArgs = {
  input: AgencyAgreementSendInput;
};

export type Mutation_RootAllocate_Project_StagesArgs = {
  projectId: Scalars['uuid'];
};

export type Mutation_RootAllocate_Project_Stages_OpenArgs = {
  projectId: Scalars['uuid'];
};

export type Mutation_RootAnnature_Copy_EnvelopeArgs = {
  dstEnvelopeName: Scalars['String'];
  srcEnvelopeId: Scalars['String'];
};

export type Mutation_RootAnnature_Create_AccountArgs = {
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Mutation_RootAnnature_Create_EnvelopeArgs = {
  input: AnnatureCreateEnvelopeReq;
};

export type Mutation_RootAnnature_Delete_EnvelopeArgs = {
  id: Scalars['String'];
};

export type Mutation_RootAnnature_Resend_Signing_EmailArgs = {
  recipientId: Scalars['String'];
};

export type Mutation_RootAnnature_Void_EnvelopeArgs = {
  envelope_id: Scalars['String'];
  void_reason: Scalars['String'];
};

export type Mutation_RootBuyer_Qr_Code_Sign_InArgs = {
  buyer: BuyerQrCodeSignInInput;
};

export type Mutation_RootBuyer_Qr_Code_Sign_UpArgs = {
  buyer: BuyerQrCodeSignUpInput;
};

export type Mutation_RootCancel_DealArgs = {
  req?: InputMaybe<CancelDealRequest>;
};

export type Mutation_RootCheck_Publish_Listing_StatusArgs = {
  arg: CheckPublishStatusRequest;
};

export type Mutation_RootCognito_Recover_UserArgs = {
  userId: Scalars['String'];
};

export type Mutation_RootConvert_Eoi_To_ReservationArgs = {
  deal: EoiDealRequest;
};

export type Mutation_RootCreate_Client_Registration_DealArgs = {
  deal: ClientRegistrationDealRequest;
};

export type Mutation_RootCreate_CodeArgs = {
  key: Scalars['String'];
};

export type Mutation_RootCreate_ContactArgs = {
  contact: ContactRequest;
};

export type Mutation_RootCreate_DealArgs = {
  deal: DealRequest;
};

export type Mutation_RootCreate_EmbedArgs = {
  name: Scalars['String'];
  payload: Scalars['jsonb'];
  projectId: Scalars['uuid'];
};

export type Mutation_RootCreate_Embed_EnquiryArgs = {
  input: CreateEmbedEnquiryRequest;
};

export type Mutation_RootCreate_EnquiryArgs = {
  enquiry: EnquiryRequest;
};

export type Mutation_RootCreate_OrganisationArgs = {
  organisation: OrganisationRequest;
};

export type Mutation_RootCypress_Sign_UpArgs = {
  user: CypressSignUpRequest;
};

export type Mutation_RootDeal_Status_UpdateArgs = {
  arg: DealStatusUpdateInput;
};

export type Mutation_RootDelete_Acl_UpdateArgs = {
  /** filter the rows which have to be deleted */
  where: Acl_Update_Bool_Exp;
};

export type Mutation_RootDelete_Acl_Update_By_PkArgs = {
  acl: Scalars['String'];
};

export type Mutation_RootDelete_Acl_Update_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Acl_Update_Status_Bool_Exp;
};

export type Mutation_RootDelete_Acl_Update_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Agency_AgreementArgs = {
  /** filter the rows which have to be deleted */
  where: Agency_Agreement_Bool_Exp;
};

export type Mutation_RootDelete_Agency_Agreement_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_AgreementArgs = {
  /** filter the rows which have to be deleted */
  where: Agreement_Bool_Exp;
};

export type Mutation_RootDelete_Agreement_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_AllocationArgs = {
  /** filter the rows which have to be deleted */
  where: Allocation_Bool_Exp;
};

export type Mutation_RootDelete_Allocation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Allocation_LogArgs = {
  /** filter the rows which have to be deleted */
  where: Allocation_Log_Bool_Exp;
};

export type Mutation_RootDelete_Allocation_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Allocation_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Allocation_Type_Bool_Exp;
};

export type Mutation_RootDelete_Allocation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Apartment_Plan_BuildingArgs = {
  /** filter the rows which have to be deleted */
  where: Apartment_Plan_Building_Bool_Exp;
};

export type Mutation_RootDelete_Apartment_Plan_Building_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Apartment_Plan_PropertyArgs = {
  /** filter the rows which have to be deleted */
  where: Apartment_Plan_Property_Bool_Exp;
};

export type Mutation_RootDelete_Apartment_Plan_Property_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Area_PlotArgs = {
  /** filter the rows which have to be deleted */
  where: Area_Plot_Bool_Exp;
};

export type Mutation_RootDelete_Area_Plot_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Area_Plot_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Area_Plot_Type_Bool_Exp;
};

export type Mutation_RootDelete_Area_Plot_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_AttachmentArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Attachment_ConfigArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Config_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Attachment_Edit_Project_OrderArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Edit_Project_Order_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Edit_Project_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Attachment_OwnerArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Owner_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Attachment_Product_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Product_Type_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Product_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Attachment_Stage_OrderArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Stage_Order_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Stage_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Attachment_ThumbnailArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Thumbnail_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Thumbnail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Attachment_Thumbnail_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Thumbnail_Type_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Thumbnail_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Attachment_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Attachment_Type_Bool_Exp;
};

export type Mutation_RootDelete_Attachment_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Buyer_PortalArgs = {
  /** filter the rows which have to be deleted */
  where: Buyer_Portal_Bool_Exp;
};

export type Mutation_RootDelete_Buyer_Portal_By_PkArgs = {
  deal_id: Scalars['uuid'];
};

export type Mutation_RootDelete_Buyer_ProjectArgs = {
  /** filter the rows which have to be deleted */
  where: Buyer_Project_Bool_Exp;
};

export type Mutation_RootDelete_Buyer_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Buyer_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Buyer_Type_Bool_Exp;
};

export type Mutation_RootDelete_Buyer_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_CacheArgs = {
  /** filter the rows which have to be deleted */
  where: Cache_Bool_Exp;
};

export type Mutation_RootDelete_Cache_By_PkArgs = {
  key: Scalars['String'];
};

export type Mutation_RootDelete_Change_InArgs = {
  /** filter the rows which have to be deleted */
  where: Change_In_Bool_Exp;
};

export type Mutation_RootDelete_Change_In_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Change_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Change_Status_Bool_Exp;
};

export type Mutation_RootDelete_Change_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_CodeArgs = {
  /** filter the rows which have to be deleted */
  where: Code_Bool_Exp;
};

export type Mutation_RootDelete_Code_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_CommissionArgs = {
  /** filter the rows which have to be deleted */
  where: Commission_Bool_Exp;
};

export type Mutation_RootDelete_Commission_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Commission_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Commission_Type_Bool_Exp;
};

export type Mutation_RootDelete_Commission_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_ContactArgs = {
  /** filter the rows which have to be deleted */
  where: Contact_Bool_Exp;
};

export type Mutation_RootDelete_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Contact_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Contact_Type_Bool_Exp;
};

export type Mutation_RootDelete_Contact_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Count_With_Rolling_AvgArgs = {
  /** filter the rows which have to be deleted */
  where: Count_With_Rolling_Avg_Bool_Exp;
};

export type Mutation_RootDelete_Count_With_Rolling_Avg_By_PkArgs = {
  date_start: Scalars['date'];
};

export type Mutation_RootDelete_CountryArgs = {
  /** filter the rows which have to be deleted */
  where: Country_Bool_Exp;
};

export type Mutation_RootDelete_Country_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Crm_ConnectionArgs = {
  /** filter the rows which have to be deleted */
  where: Crm_Connection_Bool_Exp;
};

export type Mutation_RootDelete_Crm_Connection_By_PkArgs = {
  sf_instance_id: Scalars['String'];
};

export type Mutation_RootDelete_DealArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Bool_Exp;
};

export type Mutation_RootDelete_Deal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Deal_ContactArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Contact_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Deal_Contact_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Contact_Status_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Contact_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Deposit_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Deposit_Status_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Deposit_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Deposit_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Deposit_Type_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Deposit_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Enquiry_SourceArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Enquiry_Source_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Enquiry_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Fallen_ReasonArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Fallen_Reason_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Fallen_Reason_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Payment_MethodArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Payment_Method_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Payment_Method_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Progress_UpdateArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Progress_Update_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Progress_Update_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Reason_PurchaseArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Reason_Purchase_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Reason_Purchase_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Sf_ActionArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Sf_Action_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Sf_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Deal_Spoken_LanguageArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Spoken_Language_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Spoken_Language_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Deal_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Status_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Transfer_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Transfer_Status_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Transfer_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Type_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Deal_Valuation_BookingArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Valuation_Booking_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Valuation_Booking_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Deal_WitnessArgs = {
  /** filter the rows which have to be deleted */
  where: Deal_Witness_Bool_Exp;
};

export type Mutation_RootDelete_Deal_Witness_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Developer_AgentArgs = {
  /** filter the rows which have to be deleted */
  where: Developer_Agent_Bool_Exp;
};

export type Mutation_RootDelete_Developer_Agent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_DocumentArgs = {
  /** filter the rows which have to be deleted */
  where: Document_Bool_Exp;
};

export type Mutation_RootDelete_Document_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Document_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Document_Type_Bool_Exp;
};

export type Mutation_RootDelete_Document_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_EmbedArgs = {
  /** filter the rows which have to be deleted */
  where: Embed_Bool_Exp;
};

export type Mutation_RootDelete_Embed_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_EnvelopeArgs = {
  /** filter the rows which have to be deleted */
  where: Envelope_Bool_Exp;
};

export type Mutation_RootDelete_Envelope_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Envelope_OwnerArgs = {
  /** filter the rows which have to be deleted */
  where: Envelope_Owner_Bool_Exp;
};

export type Mutation_RootDelete_Envelope_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Envelope_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Envelope_Status_Bool_Exp;
};

export type Mutation_RootDelete_Envelope_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Envelope_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Envelope_Type_Bool_Exp;
};

export type Mutation_RootDelete_Envelope_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_ExperimentArgs = {
  /** filter the rows which have to be deleted */
  where: Experiment_Bool_Exp;
};

export type Mutation_RootDelete_Experiment_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_FeatureArgs = {
  /** filter the rows which have to be deleted */
  where: Feature_Bool_Exp;
};

export type Mutation_RootDelete_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Feature_CheckArgs = {
  /** filter the rows which have to be deleted */
  where: Feature_Check_Bool_Exp;
};

export type Mutation_RootDelete_Feature_Check_By_PkArgs = {
  result: Scalars['Boolean'];
};

export type Mutation_RootDelete_Feature_PackArgs = {
  /** filter the rows which have to be deleted */
  where: Feature_Pack_Bool_Exp;
};

export type Mutation_RootDelete_Feature_Pack_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Feature_Pack_FeatureArgs = {
  /** filter the rows which have to be deleted */
  where: Feature_Pack_Feature_Bool_Exp;
};

export type Mutation_RootDelete_Feature_Pack_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Filter_PageArgs = {
  /** filter the rows which have to be deleted */
  where: Filter_Page_Bool_Exp;
};

export type Mutation_RootDelete_Filter_Page_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Filter_StateArgs = {
  /** filter the rows which have to be deleted */
  where: Filter_State_Bool_Exp;
};

export type Mutation_RootDelete_Filter_State_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Finance_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Finance_Status_Bool_Exp;
};

export type Mutation_RootDelete_Finance_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Graphql_Query_LogArgs = {
  /** filter the rows which have to be deleted */
  where: Graphql_Query_Log_Bool_Exp;
};

export type Mutation_RootDelete_Graphql_Query_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Mutation_RootDelete_Hubspot_ActionArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Action_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_Action_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Action_Type_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Action_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Hubspot_ContactArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Contact_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_DealArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Deal_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Deal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_OrganisationArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Organisation_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_ProjectArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Project_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_Schema_Error_LogArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Schema_Error_Log_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Schema_Error_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_UserArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_User_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Hubspot_Valuation_BookingArgs = {
  /** filter the rows which have to be deleted */
  where: Hubspot_Valuation_Booking_Bool_Exp;
};

export type Mutation_RootDelete_Hubspot_Valuation_Booking_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_InvitationArgs = {
  /** filter the rows which have to be deleted */
  where: Invitation_Bool_Exp;
};

export type Mutation_RootDelete_Invitation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Invitation_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Invitation_Status_Bool_Exp;
};

export type Mutation_RootDelete_Invitation_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Invitation_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Invitation_Type_Bool_Exp;
};

export type Mutation_RootDelete_Invitation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Lambda_LogArgs = {
  /** filter the rows which have to be deleted */
  where: Lambda_Log_Bool_Exp;
};

export type Mutation_RootDelete_Lambda_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Lead_GenerationArgs = {
  /** filter the rows which have to be deleted */
  where: Lead_Generation_Bool_Exp;
};

export type Mutation_RootDelete_Lead_Generation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Lead_Generation_SourceArgs = {
  /** filter the rows which have to be deleted */
  where: Lead_Generation_Source_Bool_Exp;
};

export type Mutation_RootDelete_Lead_Generation_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Lead_Generation_Webhook_LogArgs = {
  /** filter the rows which have to be deleted */
  where: Lead_Generation_Webhook_Log_Bool_Exp;
};

export type Mutation_RootDelete_Lead_Generation_Webhook_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_ListingArgs = {
  /** filter the rows which have to be deleted */
  where: Listing_Bool_Exp;
};

export type Mutation_RootDelete_Listing_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Listing_Publish_ErrorsArgs = {
  /** filter the rows which have to be deleted */
  where: Listing_Publish_Errors_Bool_Exp;
};

export type Mutation_RootDelete_Listing_Publish_Errors_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Listing_Publish_Portal_SourceArgs = {
  /** filter the rows which have to be deleted */
  where: Listing_Publish_Portal_Source_Bool_Exp;
};

export type Mutation_RootDelete_Listing_Publish_Portal_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Listing_Publish_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Listing_Publish_Status_Bool_Exp;
};

export type Mutation_RootDelete_Listing_Publish_Status_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Listing_Publish_Status_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Listing_Publish_Status_Type_Bool_Exp;
};

export type Mutation_RootDelete_Listing_Publish_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Listing_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Listing_Status_Bool_Exp;
};

export type Mutation_RootDelete_Listing_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_MemberArgs = {
  /** filter the rows which have to be deleted */
  where: Member_Bool_Exp;
};

export type Mutation_RootDelete_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Member_FeatureArgs = {
  /** filter the rows which have to be deleted */
  where: Member_Feature_Bool_Exp;
};

export type Mutation_RootDelete_Member_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Member_Project_AllocateArgs = {
  /** filter the rows which have to be deleted */
  where: Member_Project_Allocate_Bool_Exp;
};

export type Mutation_RootDelete_Member_Project_Allocate_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Member_RoleArgs = {
  /** filter the rows which have to be deleted */
  where: Member_Role_Bool_Exp;
};

export type Mutation_RootDelete_Member_Role_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_NationalityArgs = {
  /** filter the rows which have to be deleted */
  where: Nationality_Bool_Exp;
};

export type Mutation_RootDelete_Nationality_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_NotificationArgs = {
  /** filter the rows which have to be deleted */
  where: Notification_Bool_Exp;
};

export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Notification_ChannelArgs = {
  /** filter the rows which have to be deleted */
  where: Notification_Channel_Bool_Exp;
};

export type Mutation_RootDelete_Notification_Channel_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Notification_OwnerArgs = {
  /** filter the rows which have to be deleted */
  where: Notification_Owner_Bool_Exp;
};

export type Mutation_RootDelete_Notification_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Notification_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Notification_Type_Bool_Exp;
};

export type Mutation_RootDelete_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Org_Feature_PackArgs = {
  /** filter the rows which have to be deleted */
  where: Org_Feature_Pack_Bool_Exp;
};

export type Mutation_RootDelete_Org_Feature_Pack_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_OrganisationArgs = {
  /** filter the rows which have to be deleted */
  where: Organisation_Bool_Exp;
};

export type Mutation_RootDelete_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Organisation_FeatureArgs = {
  /** filter the rows which have to be deleted */
  where: Organisation_Feature_Bool_Exp;
};

export type Mutation_RootDelete_Organisation_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Organisation_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Organisation_Type_Bool_Exp;
};

export type Mutation_RootDelete_Organisation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Partner_PortalArgs = {
  /** filter the rows which have to be deleted */
  where: Partner_Portal_Bool_Exp;
};

export type Mutation_RootDelete_Partner_Portal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Payment_MethodArgs = {
  /** filter the rows which have to be deleted */
  where: Payment_Method_Bool_Exp;
};

export type Mutation_RootDelete_Payment_Method_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Payment_Method_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Payment_Method_Type_Bool_Exp;
};

export type Mutation_RootDelete_Payment_Method_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_PreferenceArgs = {
  /** filter the rows which have to be deleted */
  where: Preference_Bool_Exp;
};

export type Mutation_RootDelete_Preference_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_ProcessArgs = {
  /** filter the rows which have to be deleted */
  where: Process_Bool_Exp;
};

export type Mutation_RootDelete_Process_By_PkArgs = {
  id: Scalars['String'];
};

export type Mutation_RootDelete_Process_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Process_Status_Bool_Exp;
};

export type Mutation_RootDelete_Process_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_ProjectArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Bool_Exp;
};

export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_DetailArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Detail_Bool_Exp;
};

export type Mutation_RootDelete_Project_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_FeaturesArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Features_Bool_Exp;
};

export type Mutation_RootDelete_Project_Features_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_Payment_MethodArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Payment_Method_Bool_Exp;
};

export type Mutation_RootDelete_Project_Payment_Method_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_Qr_Code_For_BuyerArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Qr_Code_For_Buyer_Bool_Exp;
};

export type Mutation_RootDelete_Project_Qr_Code_For_Buyer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_Reservation_SettingArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Reservation_Setting_Bool_Exp;
};

export type Mutation_RootDelete_Project_Reservation_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_SourcesArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Sources_Bool_Exp;
};

export type Mutation_RootDelete_Project_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Project_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Status_Bool_Exp;
};

export type Mutation_RootDelete_Project_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Project_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Project_Type_Bool_Exp;
};

export type Mutation_RootDelete_Project_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_PropertyArgs = {
  /** filter the rows which have to be deleted */
  where: Property_Bool_Exp;
};

export type Mutation_RootDelete_Property_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Property_Completion_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Property_Completion_Status_Bool_Exp;
};

export type Mutation_RootDelete_Property_Completion_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Property_GroupArgs = {
  /** filter the rows which have to be deleted */
  where: Property_Group_Bool_Exp;
};

export type Mutation_RootDelete_Property_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Property_Group_Completion_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Property_Group_Completion_Status_Bool_Exp;
};

export type Mutation_RootDelete_Property_Group_Completion_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Property_Group_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Property_Group_Type_Bool_Exp;
};

export type Mutation_RootDelete_Property_Group_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Property_SfArgs = {
  propertyIds: Array<Scalars['uuid']>;
};

export type Mutation_RootDelete_Property_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Property_Type_Bool_Exp;
};

export type Mutation_RootDelete_Property_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_ReservationArgs = {
  /** filter the rows which have to be deleted */
  where: Reservation_Bool_Exp;
};

export type Mutation_RootDelete_Reservation_By_PkArgs = {
  deal_id: Scalars['uuid'];
};

export type Mutation_RootDelete_Salesforce_ActionArgs = {
  /** filter the rows which have to be deleted */
  where: Salesforce_Action_Bool_Exp;
};

export type Mutation_RootDelete_Salesforce_Action_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Salesforce_Action_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Salesforce_Action_Status_Bool_Exp;
};

export type Mutation_RootDelete_Salesforce_Action_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Salesforce_Bulk_ImportArgs = {
  /** filter the rows which have to be deleted */
  where: Salesforce_Bulk_Import_Bool_Exp;
};

export type Mutation_RootDelete_Salesforce_Bulk_Import_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Salesforce_ConnectionArgs = {
  /** filter the rows which have to be deleted */
  where: Salesforce_Connection_Bool_Exp;
};

export type Mutation_RootDelete_Salesforce_Connection_By_PkArgs = {
  access_token: Scalars['String'];
};

export type Mutation_RootDelete_Salesforce_Sync_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Salesforce_Sync_Status_Bool_Exp;
};

export type Mutation_RootDelete_Salesforce_Sync_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_SettingsArgs = {
  /** filter the rows which have to be deleted */
  where: Settings_Bool_Exp;
};

export type Mutation_RootDelete_Settings_By_PkArgs = {
  key: Scalars['String'];
};

export type Mutation_RootDelete_Sf_ActionArgs = {
  /** filter the rows which have to be deleted */
  where: Sf_Action_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Sf_ContactArgs = {
  /** filter the rows which have to be deleted */
  where: Sf_Contact_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Sf_OrganisationArgs = {
  /** filter the rows which have to be deleted */
  where: Sf_Organisation_Bool_Exp;
};

export type Mutation_RootDelete_Sf_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Spoken_LanguageArgs = {
  /** filter the rows which have to be deleted */
  where: Spoken_Language_Bool_Exp;
};

export type Mutation_RootDelete_Spoken_Language_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_SystemArgs = {
  /** filter the rows which have to be deleted */
  where: System_Bool_Exp;
};

export type Mutation_RootDelete_System_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_TransactionArgs = {
  /** filter the rows which have to be deleted */
  where: Transaction_Bool_Exp;
};

export type Mutation_RootDelete_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Transaction_StatusArgs = {
  /** filter the rows which have to be deleted */
  where: Transaction_Status_Bool_Exp;
};

export type Mutation_RootDelete_Transaction_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Transaction_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Transaction_Type_Bool_Exp;
};

export type Mutation_RootDelete_Transaction_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_UserArgs = {
  /** filter the rows which have to be deleted */
  where: User_Bool_Exp;
};

export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_User_ExperimentArgs = {
  /** filter the rows which have to be deleted */
  where: User_Experiment_Bool_Exp;
};

export type Mutation_RootDelete_User_Experiment_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Mutation_RootDelete_User_FeatureArgs = {
  /** filter the rows which have to be deleted */
  where: User_Feature_Bool_Exp;
};

export type Mutation_RootDelete_User_Feature_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_User_Push_TokenArgs = {
  /** filter the rows which have to be deleted */
  where: User_Push_Token_Bool_Exp;
};

export type Mutation_RootDelete_User_Push_Token_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_VariationArgs = {
  /** filter the rows which have to be deleted */
  where: Variation_Bool_Exp;
};

export type Mutation_RootDelete_Variation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Variation_CategoryArgs = {
  /** filter the rows which have to be deleted */
  where: Variation_Category_Bool_Exp;
};

export type Mutation_RootDelete_Variation_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Variation_TypeArgs = {
  /** filter the rows which have to be deleted */
  where: Variation_Type_Bool_Exp;
};

export type Mutation_RootDelete_Variation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Variation_Type_CategoryArgs = {
  /** filter the rows which have to be deleted */
  where: Variation_Type_Category_Bool_Exp;
};

export type Mutation_RootDelete_Variation_Type_Category_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootDelete_Web_App_FeatureArgs = {
  /** filter the rows which have to be deleted */
  where: Web_App_Feature_Bool_Exp;
};

export type Mutation_RootDelete_Web_App_Feature_By_PkArgs = {
  value: Scalars['String'];
};

export type Mutation_RootDelete_Websocket_LogArgs = {
  /** filter the rows which have to be deleted */
  where: Websocket_Log_Bool_Exp;
};

export type Mutation_RootDelete_Websocket_Log_By_PkArgs = {
  request_id: Scalars['uuid'];
};

export type Mutation_RootDrop_EmbedArgs = {
  id: Scalars['uuid'];
};

export type Mutation_RootEdit_Deal_Name_On_ContractArgs = {
  dealId: Scalars['uuid'];
  nameOnContract: Scalars['String'];
};

export type Mutation_RootEdit_ListingArgs = {
  input: EditListingInput;
  listingIds: Array<Scalars['uuid']>;
};

export type Mutation_RootEdit_Listing_PriceArgs = {
  listingId: Scalars['uuid'];
  price: Scalars['Int'];
};

export type Mutation_RootEdit_Listing_StatusArgs = {
  listingIds: Array<Scalars['uuid']>;
  status: Scalars['String'];
};

export type Mutation_RootEdit_PropertyArgs = {
  input: EditPropertyInput;
  propertyIds: Array<Scalars['uuid']>;
};

export type Mutation_RootGenerate_Id_Report_AttachmentArgs = {
  documaentId: Scalars['String'];
};

export type Mutation_RootGenerate_ThumbnailsArgs = {
  attachment_id: Scalars['uuid'];
};

export type Mutation_RootGet_Flat_File_TokenArgs = {
  arg: FlatFileTokenRequest;
};

export type Mutation_RootHandle_InvitationArgs = {
  invitationId: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  organisation?: InputMaybe<OrganisationPayload>;
};

export type Mutation_RootHold_ListingArgs = {
  request?: InputMaybe<HoldListingActionRequest>;
};

export type Mutation_RootHolding_Deposit_PaidArgs = {
  dealId: Scalars['String'];
};

export type Mutation_RootHubspot_Contacts_SyncArgs = {
  req: HubspotContactsRequest;
};

export type Mutation_RootHubspot_Deal_SyncArgs = {
  req: HubspotDealRequest;
};

export type Mutation_RootHubspot_Deals_SyncArgs = {
  req: HubspotDealsRequest;
};

export type Mutation_RootHubspot_Partner_Contacts_SyncArgs = {
  req: HubspotPartnerContactsRequest;
};

export type Mutation_RootHubspot_Partner_Team_SyncArgs = {
  req: HubspotPartnerTeamSyncRequest;
};

export type Mutation_RootHubspot_Partner_Users_SyncArgs = {
  req: HubspotPartnerUsersRequest;
};

export type Mutation_RootHubspot_Projects_SyncArgs = {
  req: HubspotProjectSyncRequest;
};

export type Mutation_RootHubspot_Sync_AssociationsArgs = {
  hsInstanceId: Scalars['String'];
};

export type Mutation_RootHubspot_Sync_SchemaArgs = {
  req: HubspotSyncShemaRequest;
};

export type Mutation_RootHubspot_Team_SyncArgs = {
  req: HubspotTeamSyncRequest;
};

export type Mutation_RootHubspot_Users_SyncArgs = {
  req: HubspotUsersRequest;
};

export type Mutation_RootInsert_Acl_UpdateArgs = {
  /** the rows to be inserted */
  objects: Array<Acl_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Acl_Update_On_Conflict>;
};

export type Mutation_RootInsert_Acl_Update_OneArgs = {
  /** the row to be inserted */
  object: Acl_Update_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Acl_Update_On_Conflict>;
};

export type Mutation_RootInsert_Acl_Update_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Acl_Update_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Acl_Update_Status_On_Conflict>;
};

export type Mutation_RootInsert_Acl_Update_Status_OneArgs = {
  /** the row to be inserted */
  object: Acl_Update_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Acl_Update_Status_On_Conflict>;
};

export type Mutation_RootInsert_Agency_AgreementArgs = {
  /** the rows to be inserted */
  objects: Array<Agency_Agreement_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Agency_Agreement_On_Conflict>;
};

export type Mutation_RootInsert_Agency_Agreement_OneArgs = {
  /** the row to be inserted */
  object: Agency_Agreement_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Agency_Agreement_On_Conflict>;
};

export type Mutation_RootInsert_AgreementArgs = {
  /** the rows to be inserted */
  objects: Array<Agreement_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Agreement_On_Conflict>;
};

export type Mutation_RootInsert_Agreement_OneArgs = {
  /** the row to be inserted */
  object: Agreement_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Agreement_On_Conflict>;
};

export type Mutation_RootInsert_AllocationArgs = {
  /** the rows to be inserted */
  objects: Array<Allocation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Allocation_On_Conflict>;
};

export type Mutation_RootInsert_Allocation_LogArgs = {
  /** the rows to be inserted */
  objects: Array<Allocation_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Allocation_Log_On_Conflict>;
};

export type Mutation_RootInsert_Allocation_Log_OneArgs = {
  /** the row to be inserted */
  object: Allocation_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allocation_Log_On_Conflict>;
};

export type Mutation_RootInsert_Allocation_OneArgs = {
  /** the row to be inserted */
  object: Allocation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allocation_On_Conflict>;
};

export type Mutation_RootInsert_Allocation_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Allocation_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Allocation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Allocation_Type_OneArgs = {
  /** the row to be inserted */
  object: Allocation_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allocation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Apartment_Plan_BuildingArgs = {
  /** the rows to be inserted */
  objects: Array<Apartment_Plan_Building_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Plan_Building_On_Conflict>;
};

export type Mutation_RootInsert_Apartment_Plan_Building_OneArgs = {
  /** the row to be inserted */
  object: Apartment_Plan_Building_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Plan_Building_On_Conflict>;
};

export type Mutation_RootInsert_Apartment_Plan_PropertyArgs = {
  /** the rows to be inserted */
  objects: Array<Apartment_Plan_Property_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Plan_Property_On_Conflict>;
};

export type Mutation_RootInsert_Apartment_Plan_Property_OneArgs = {
  /** the row to be inserted */
  object: Apartment_Plan_Property_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Plan_Property_On_Conflict>;
};

export type Mutation_RootInsert_Area_PlotArgs = {
  /** the rows to be inserted */
  objects: Array<Area_Plot_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Area_Plot_On_Conflict>;
};

export type Mutation_RootInsert_Area_Plot_OneArgs = {
  /** the row to be inserted */
  object: Area_Plot_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Area_Plot_On_Conflict>;
};

export type Mutation_RootInsert_Area_Plot_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Area_Plot_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Area_Plot_Type_On_Conflict>;
};

export type Mutation_RootInsert_Area_Plot_Type_OneArgs = {
  /** the row to be inserted */
  object: Area_Plot_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Area_Plot_Type_On_Conflict>;
};

export type Mutation_RootInsert_AttachmentArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_ConfigArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Config_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Config_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Config_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Edit_Project_OrderArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Edit_Project_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Edit_Project_Order_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Edit_Project_Order_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Edit_Project_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Edit_Project_Order_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_OwnerArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Owner_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Owner_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Owner_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Owner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Owner_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Product_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Product_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Product_Type_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Product_Type_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Product_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Product_Type_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Stage_OrderArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Stage_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Stage_Order_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Stage_Order_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Stage_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Stage_Order_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_ThumbnailArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Thumbnail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Thumbnail_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Thumbnail_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Thumbnail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Thumbnail_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Thumbnail_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Thumbnail_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Thumbnail_Type_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Thumbnail_Type_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Thumbnail_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Thumbnail_Type_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Attachment_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Type_On_Conflict>;
};

export type Mutation_RootInsert_Attachment_Type_OneArgs = {
  /** the row to be inserted */
  object: Attachment_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Type_On_Conflict>;
};

export type Mutation_RootInsert_Buyer_PortalArgs = {
  /** the rows to be inserted */
  objects: Array<Buyer_Portal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_Portal_On_Conflict>;
};

export type Mutation_RootInsert_Buyer_Portal_OneArgs = {
  /** the row to be inserted */
  object: Buyer_Portal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_Portal_On_Conflict>;
};

export type Mutation_RootInsert_Buyer_ProjectArgs = {
  /** the rows to be inserted */
  objects: Array<Buyer_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_Project_On_Conflict>;
};

export type Mutation_RootInsert_Buyer_Project_OneArgs = {
  /** the row to be inserted */
  object: Buyer_Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_Project_On_Conflict>;
};

export type Mutation_RootInsert_Buyer_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Buyer_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_Type_On_Conflict>;
};

export type Mutation_RootInsert_Buyer_Type_OneArgs = {
  /** the row to be inserted */
  object: Buyer_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_Type_On_Conflict>;
};

export type Mutation_RootInsert_CacheArgs = {
  /** the rows to be inserted */
  objects: Array<Cache_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cache_On_Conflict>;
};

export type Mutation_RootInsert_Cache_OneArgs = {
  /** the row to be inserted */
  object: Cache_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Cache_On_Conflict>;
};

export type Mutation_RootInsert_Change_InArgs = {
  /** the rows to be inserted */
  objects: Array<Change_In_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_In_On_Conflict>;
};

export type Mutation_RootInsert_Change_In_OneArgs = {
  /** the row to be inserted */
  object: Change_In_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_In_On_Conflict>;
};

export type Mutation_RootInsert_Change_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Change_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_Status_On_Conflict>;
};

export type Mutation_RootInsert_Change_Status_OneArgs = {
  /** the row to be inserted */
  object: Change_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_Status_On_Conflict>;
};

export type Mutation_RootInsert_CodeArgs = {
  /** the rows to be inserted */
  objects: Array<Code_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Code_On_Conflict>;
};

export type Mutation_RootInsert_Code_OneArgs = {
  /** the row to be inserted */
  object: Code_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Code_On_Conflict>;
};

export type Mutation_RootInsert_CommissionArgs = {
  /** the rows to be inserted */
  objects: Array<Commission_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Commission_On_Conflict>;
};

export type Mutation_RootInsert_Commission_OneArgs = {
  /** the row to be inserted */
  object: Commission_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Commission_On_Conflict>;
};

export type Mutation_RootInsert_Commission_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Commission_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};

export type Mutation_RootInsert_Commission_Type_OneArgs = {
  /** the row to be inserted */
  object: Commission_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};

export type Mutation_RootInsert_ContactArgs = {
  /** the rows to be inserted */
  objects: Array<Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

export type Mutation_RootInsert_Contact_OneArgs = {
  /** the row to be inserted */
  object: Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_On_Conflict>;
};

export type Mutation_RootInsert_Contact_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Contact_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};

export type Mutation_RootInsert_Contact_Type_OneArgs = {
  /** the row to be inserted */
  object: Contact_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Type_On_Conflict>;
};

export type Mutation_RootInsert_Count_With_Rolling_AvgArgs = {
  /** the rows to be inserted */
  objects: Array<Count_With_Rolling_Avg_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Count_With_Rolling_Avg_On_Conflict>;
};

export type Mutation_RootInsert_Count_With_Rolling_Avg_OneArgs = {
  /** the row to be inserted */
  object: Count_With_Rolling_Avg_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Count_With_Rolling_Avg_On_Conflict>;
};

export type Mutation_RootInsert_CountryArgs = {
  /** the rows to be inserted */
  objects: Array<Country_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

export type Mutation_RootInsert_Country_OneArgs = {
  /** the row to be inserted */
  object: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

export type Mutation_RootInsert_Crm_ConnectionArgs = {
  /** the rows to be inserted */
  objects: Array<Crm_Connection_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Crm_Connection_On_Conflict>;
};

export type Mutation_RootInsert_Crm_Connection_OneArgs = {
  /** the row to be inserted */
  object: Crm_Connection_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Crm_Connection_On_Conflict>;
};

export type Mutation_RootInsert_DealArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

export type Mutation_RootInsert_Deal_ContactArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Contact_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Contact_OneArgs = {
  /** the row to be inserted */
  object: Deal_Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Contact_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Contact_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Contact_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Contact_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Contact_Status_OneArgs = {
  /** the row to be inserted */
  object: Deal_Contact_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Contact_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Deposit_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Deposit_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Deposit_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Deposit_Status_OneArgs = {
  /** the row to be inserted */
  object: Deal_Deposit_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Deposit_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Deposit_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Deposit_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Deposit_Type_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Deposit_Type_OneArgs = {
  /** the row to be inserted */
  object: Deal_Deposit_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Deposit_Type_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Enquiry_SourceArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Enquiry_Source_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Enquiry_Source_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Enquiry_Source_OneArgs = {
  /** the row to be inserted */
  object: Deal_Enquiry_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Enquiry_Source_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Fallen_ReasonArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Fallen_Reason_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Fallen_Reason_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Fallen_Reason_OneArgs = {
  /** the row to be inserted */
  object: Deal_Fallen_Reason_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Fallen_Reason_On_Conflict>;
};

export type Mutation_RootInsert_Deal_OneArgs = {
  /** the row to be inserted */
  object: Deal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Payment_MethodArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Payment_Method_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Payment_Method_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Payment_Method_OneArgs = {
  /** the row to be inserted */
  object: Deal_Payment_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Payment_Method_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Progress_UpdateArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Progress_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Progress_Update_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Progress_Update_OneArgs = {
  /** the row to be inserted */
  object: Deal_Progress_Update_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Progress_Update_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Reason_PurchaseArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Reason_Purchase_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Reason_Purchase_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Reason_Purchase_OneArgs = {
  /** the row to be inserted */
  object: Deal_Reason_Purchase_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Reason_Purchase_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Sf_ActionArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Sf_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Sf_Action_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Sf_Action_OneArgs = {
  /** the row to be inserted */
  object: Deal_Sf_Action_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Sf_Action_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Spoken_LanguageArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Spoken_Language_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Spoken_Language_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Spoken_Language_OneArgs = {
  /** the row to be inserted */
  object: Deal_Spoken_Language_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Spoken_Language_On_Conflict>;
};

export type Mutation_RootInsert_Deal_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Status_OneArgs = {
  /** the row to be inserted */
  object: Deal_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Transfer_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Transfer_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Transfer_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Transfer_Status_OneArgs = {
  /** the row to be inserted */
  object: Deal_Transfer_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Transfer_Status_On_Conflict>;
};

export type Mutation_RootInsert_Deal_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Type_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Type_OneArgs = {
  /** the row to be inserted */
  object: Deal_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Type_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Valuation_BookingArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Valuation_Booking_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Valuation_Booking_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Valuation_Booking_OneArgs = {
  /** the row to be inserted */
  object: Deal_Valuation_Booking_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Valuation_Booking_On_Conflict>;
};

export type Mutation_RootInsert_Deal_WitnessArgs = {
  /** the rows to be inserted */
  objects: Array<Deal_Witness_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Witness_On_Conflict>;
};

export type Mutation_RootInsert_Deal_Witness_OneArgs = {
  /** the row to be inserted */
  object: Deal_Witness_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_Witness_On_Conflict>;
};

export type Mutation_RootInsert_Developer_AgentArgs = {
  /** the rows to be inserted */
  objects: Array<Developer_Agent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Developer_Agent_On_Conflict>;
};

export type Mutation_RootInsert_Developer_Agent_OneArgs = {
  /** the row to be inserted */
  object: Developer_Agent_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Developer_Agent_On_Conflict>;
};

export type Mutation_RootInsert_DocumentArgs = {
  /** the rows to be inserted */
  objects: Array<Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

export type Mutation_RootInsert_Document_OneArgs = {
  /** the row to be inserted */
  object: Document_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

export type Mutation_RootInsert_Document_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Document_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Type_On_Conflict>;
};

export type Mutation_RootInsert_Document_Type_OneArgs = {
  /** the row to be inserted */
  object: Document_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Type_On_Conflict>;
};

export type Mutation_RootInsert_EmbedArgs = {
  /** the rows to be inserted */
  objects: Array<Embed_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Embed_On_Conflict>;
};

export type Mutation_RootInsert_Embed_OneArgs = {
  /** the row to be inserted */
  object: Embed_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Embed_On_Conflict>;
};

export type Mutation_RootInsert_EnvelopeArgs = {
  /** the rows to be inserted */
  objects: Array<Envelope_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_OneArgs = {
  /** the row to be inserted */
  object: Envelope_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_OwnerArgs = {
  /** the rows to be inserted */
  objects: Array<Envelope_Owner_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_Owner_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_Owner_OneArgs = {
  /** the row to be inserted */
  object: Envelope_Owner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_Owner_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Envelope_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_Status_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_Status_OneArgs = {
  /** the row to be inserted */
  object: Envelope_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_Status_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Envelope_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_Type_On_Conflict>;
};

export type Mutation_RootInsert_Envelope_Type_OneArgs = {
  /** the row to be inserted */
  object: Envelope_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Envelope_Type_On_Conflict>;
};

export type Mutation_RootInsert_ExperimentArgs = {
  /** the rows to be inserted */
  objects: Array<Experiment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

export type Mutation_RootInsert_Experiment_OneArgs = {
  /** the row to be inserted */
  object: Experiment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

export type Mutation_RootInsert_FeatureArgs = {
  /** the rows to be inserted */
  objects: Array<Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

export type Mutation_RootInsert_Feature_CheckArgs = {
  /** the rows to be inserted */
  objects: Array<Feature_Check_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Check_On_Conflict>;
};

export type Mutation_RootInsert_Feature_Check_OneArgs = {
  /** the row to be inserted */
  object: Feature_Check_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Check_On_Conflict>;
};

export type Mutation_RootInsert_Feature_OneArgs = {
  /** the row to be inserted */
  object: Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

export type Mutation_RootInsert_Feature_PackArgs = {
  /** the rows to be inserted */
  objects: Array<Feature_Pack_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Pack_On_Conflict>;
};

export type Mutation_RootInsert_Feature_Pack_FeatureArgs = {
  /** the rows to be inserted */
  objects: Array<Feature_Pack_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Pack_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Feature_Pack_Feature_OneArgs = {
  /** the row to be inserted */
  object: Feature_Pack_Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Pack_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Feature_Pack_OneArgs = {
  /** the row to be inserted */
  object: Feature_Pack_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Pack_On_Conflict>;
};

export type Mutation_RootInsert_Filter_PageArgs = {
  /** the rows to be inserted */
  objects: Array<Filter_Page_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Filter_Page_On_Conflict>;
};

export type Mutation_RootInsert_Filter_Page_OneArgs = {
  /** the row to be inserted */
  object: Filter_Page_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Filter_Page_On_Conflict>;
};

export type Mutation_RootInsert_Filter_StateArgs = {
  /** the rows to be inserted */
  objects: Array<Filter_State_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Filter_State_On_Conflict>;
};

export type Mutation_RootInsert_Filter_State_OneArgs = {
  /** the row to be inserted */
  object: Filter_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Filter_State_On_Conflict>;
};

export type Mutation_RootInsert_Finance_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Finance_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Finance_Status_On_Conflict>;
};

export type Mutation_RootInsert_Finance_Status_OneArgs = {
  /** the row to be inserted */
  object: Finance_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Finance_Status_On_Conflict>;
};

export type Mutation_RootInsert_Graphql_Query_LogArgs = {
  /** the rows to be inserted */
  objects: Array<Graphql_Query_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Graphql_Query_Log_On_Conflict>;
};

export type Mutation_RootInsert_Graphql_Query_Log_OneArgs = {
  /** the row to be inserted */
  object: Graphql_Query_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Graphql_Query_Log_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_ActionArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Action_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Action_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Action_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Action_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Action_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Action_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Action_Type_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Action_Type_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Action_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Action_Type_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_ContactArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Contact_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Contact_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Contact_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_DealArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Deal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Deal_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Deal_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Deal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Deal_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_OrganisationArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Organisation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Organisation_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Organisation_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Organisation_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_ProjectArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Project_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Project_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Project_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Schema_Error_LogArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Schema_Error_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Schema_Error_Log_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Schema_Error_Log_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Schema_Error_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Schema_Error_Log_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_UserArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_User_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_User_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_User_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Valuation_BookingArgs = {
  /** the rows to be inserted */
  objects: Array<Hubspot_Valuation_Booking_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Valuation_Booking_On_Conflict>;
};

export type Mutation_RootInsert_Hubspot_Valuation_Booking_OneArgs = {
  /** the row to be inserted */
  object: Hubspot_Valuation_Booking_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hubspot_Valuation_Booking_On_Conflict>;
};

export type Mutation_RootInsert_InvitationArgs = {
  /** the rows to be inserted */
  objects: Array<Invitation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitation_On_Conflict>;
};

export type Mutation_RootInsert_Invitation_OneArgs = {
  /** the row to be inserted */
  object: Invitation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitation_On_Conflict>;
};

export type Mutation_RootInsert_Invitation_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Invitation_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitation_Status_On_Conflict>;
};

export type Mutation_RootInsert_Invitation_Status_OneArgs = {
  /** the row to be inserted */
  object: Invitation_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitation_Status_On_Conflict>;
};

export type Mutation_RootInsert_Invitation_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Invitation_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Invitation_Type_OneArgs = {
  /** the row to be inserted */
  object: Invitation_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Lambda_LogArgs = {
  /** the rows to be inserted */
  objects: Array<Lambda_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lambda_Log_On_Conflict>;
};

export type Mutation_RootInsert_Lambda_Log_OneArgs = {
  /** the row to be inserted */
  object: Lambda_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lambda_Log_On_Conflict>;
};

export type Mutation_RootInsert_Lead_GenerationArgs = {
  /** the rows to be inserted */
  objects: Array<Lead_Generation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lead_Generation_On_Conflict>;
};

export type Mutation_RootInsert_Lead_Generation_OneArgs = {
  /** the row to be inserted */
  object: Lead_Generation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lead_Generation_On_Conflict>;
};

export type Mutation_RootInsert_Lead_Generation_SourceArgs = {
  /** the rows to be inserted */
  objects: Array<Lead_Generation_Source_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lead_Generation_Source_On_Conflict>;
};

export type Mutation_RootInsert_Lead_Generation_Source_OneArgs = {
  /** the row to be inserted */
  object: Lead_Generation_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lead_Generation_Source_On_Conflict>;
};

export type Mutation_RootInsert_Lead_Generation_Webhook_LogArgs = {
  /** the rows to be inserted */
  objects: Array<Lead_Generation_Webhook_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lead_Generation_Webhook_Log_On_Conflict>;
};

export type Mutation_RootInsert_Lead_Generation_Webhook_Log_OneArgs = {
  /** the row to be inserted */
  object: Lead_Generation_Webhook_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lead_Generation_Webhook_Log_On_Conflict>;
};

export type Mutation_RootInsert_ListingArgs = {
  /** the rows to be inserted */
  objects: Array<Listing_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_On_Conflict>;
};

export type Mutation_RootInsert_Listing_OneArgs = {
  /** the row to be inserted */
  object: Listing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_ErrorsArgs = {
  /** the rows to be inserted */
  objects: Array<Listing_Publish_Errors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Errors_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_Errors_OneArgs = {
  /** the row to be inserted */
  object: Listing_Publish_Errors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Errors_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_Portal_SourceArgs = {
  /** the rows to be inserted */
  objects: Array<Listing_Publish_Portal_Source_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Portal_Source_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_Portal_Source_OneArgs = {
  /** the row to be inserted */
  object: Listing_Publish_Portal_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Portal_Source_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Listing_Publish_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Status_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_Status_OneArgs = {
  /** the row to be inserted */
  object: Listing_Publish_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Status_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_Status_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Listing_Publish_Status_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Status_Type_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Publish_Status_Type_OneArgs = {
  /** the row to be inserted */
  object: Listing_Publish_Status_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Publish_Status_Type_On_Conflict>;
};

export type Mutation_RootInsert_Listing_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Listing_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Status_On_Conflict>;
};

export type Mutation_RootInsert_Listing_Status_OneArgs = {
  /** the row to be inserted */
  object: Listing_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Listing_Status_On_Conflict>;
};

export type Mutation_RootInsert_MemberArgs = {
  /** the rows to be inserted */
  objects: Array<Member_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_On_Conflict>;
};

export type Mutation_RootInsert_Member_FeatureArgs = {
  /** the rows to be inserted */
  objects: Array<Member_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Member_Feature_OneArgs = {
  /** the row to be inserted */
  object: Member_Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Member_OneArgs = {
  /** the row to be inserted */
  object: Member_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_On_Conflict>;
};

export type Mutation_RootInsert_Member_Project_AllocateArgs = {
  /** the rows to be inserted */
  objects: Array<Member_Project_Allocate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_Project_Allocate_On_Conflict>;
};

export type Mutation_RootInsert_Member_Project_Allocate_OneArgs = {
  /** the row to be inserted */
  object: Member_Project_Allocate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_Project_Allocate_On_Conflict>;
};

export type Mutation_RootInsert_Member_RoleArgs = {
  /** the rows to be inserted */
  objects: Array<Member_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_Role_On_Conflict>;
};

export type Mutation_RootInsert_Member_Role_OneArgs = {
  /** the row to be inserted */
  object: Member_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Member_Role_On_Conflict>;
};

export type Mutation_RootInsert_NationalityArgs = {
  /** the rows to be inserted */
  objects: Array<Nationality_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nationality_On_Conflict>;
};

export type Mutation_RootInsert_Nationality_OneArgs = {
  /** the row to be inserted */
  object: Nationality_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Nationality_On_Conflict>;
};

export type Mutation_RootInsert_NotificationArgs = {
  /** the rows to be inserted */
  objects: Array<Notification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};

export type Mutation_RootInsert_Notification_ChannelArgs = {
  /** the rows to be inserted */
  objects: Array<Notification_Channel_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Channel_On_Conflict>;
};

export type Mutation_RootInsert_Notification_Channel_OneArgs = {
  /** the row to be inserted */
  object: Notification_Channel_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Channel_On_Conflict>;
};

export type Mutation_RootInsert_Notification_OneArgs = {
  /** the row to be inserted */
  object: Notification_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};

export type Mutation_RootInsert_Notification_OwnerArgs = {
  /** the rows to be inserted */
  objects: Array<Notification_Owner_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Owner_On_Conflict>;
};

export type Mutation_RootInsert_Notification_Owner_OneArgs = {
  /** the row to be inserted */
  object: Notification_Owner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Owner_On_Conflict>;
};

export type Mutation_RootInsert_Notification_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Notification_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};

export type Mutation_RootInsert_Notification_Type_OneArgs = {
  /** the row to be inserted */
  object: Notification_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};

export type Mutation_RootInsert_Org_Feature_PackArgs = {
  /** the rows to be inserted */
  objects: Array<Org_Feature_Pack_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Org_Feature_Pack_On_Conflict>;
};

export type Mutation_RootInsert_Org_Feature_Pack_OneArgs = {
  /** the row to be inserted */
  object: Org_Feature_Pack_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Org_Feature_Pack_On_Conflict>;
};

export type Mutation_RootInsert_OrganisationArgs = {
  /** the rows to be inserted */
  objects: Array<Organisation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};

export type Mutation_RootInsert_Organisation_FeatureArgs = {
  /** the rows to be inserted */
  objects: Array<Organisation_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Organisation_Feature_OneArgs = {
  /** the row to be inserted */
  object: Organisation_Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Organisation_OneArgs = {
  /** the row to be inserted */
  object: Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_On_Conflict>;
};

export type Mutation_RootInsert_Organisation_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Organisation_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Organisation_Type_OneArgs = {
  /** the row to be inserted */
  object: Organisation_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organisation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Partner_PortalArgs = {
  /** the rows to be inserted */
  objects: Array<Partner_Portal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Portal_On_Conflict>;
};

export type Mutation_RootInsert_Partner_Portal_OneArgs = {
  /** the row to be inserted */
  object: Partner_Portal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Portal_On_Conflict>;
};

export type Mutation_RootInsert_Payment_MethodArgs = {
  /** the rows to be inserted */
  objects: Array<Payment_Method_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

export type Mutation_RootInsert_Payment_Method_OneArgs = {
  /** the row to be inserted */
  object: Payment_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

export type Mutation_RootInsert_Payment_Method_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Payment_Method_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_Type_On_Conflict>;
};

export type Mutation_RootInsert_Payment_Method_Type_OneArgs = {
  /** the row to be inserted */
  object: Payment_Method_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_Type_On_Conflict>;
};

export type Mutation_RootInsert_PreferenceArgs = {
  /** the rows to be inserted */
  objects: Array<Preference_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Preference_On_Conflict>;
};

export type Mutation_RootInsert_Preference_OneArgs = {
  /** the row to be inserted */
  object: Preference_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Preference_On_Conflict>;
};

export type Mutation_RootInsert_ProcessArgs = {
  /** the rows to be inserted */
  objects: Array<Process_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Process_On_Conflict>;
};

export type Mutation_RootInsert_Process_OneArgs = {
  /** the row to be inserted */
  object: Process_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Process_On_Conflict>;
};

export type Mutation_RootInsert_Process_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Process_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Process_Status_On_Conflict>;
};

export type Mutation_RootInsert_Process_Status_OneArgs = {
  /** the row to be inserted */
  object: Process_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Process_Status_On_Conflict>;
};

export type Mutation_RootInsert_ProjectArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

export type Mutation_RootInsert_Project_DetailArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Detail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Detail_On_Conflict>;
};

export type Mutation_RootInsert_Project_Detail_OneArgs = {
  /** the row to be inserted */
  object: Project_Detail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Detail_On_Conflict>;
};

export type Mutation_RootInsert_Project_FeaturesArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Features_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Features_On_Conflict>;
};

export type Mutation_RootInsert_Project_Features_OneArgs = {
  /** the row to be inserted */
  object: Project_Features_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Features_On_Conflict>;
};

export type Mutation_RootInsert_Project_OneArgs = {
  /** the row to be inserted */
  object: Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

export type Mutation_RootInsert_Project_Payment_MethodArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Payment_Method_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Payment_Method_On_Conflict>;
};

export type Mutation_RootInsert_Project_Payment_Method_OneArgs = {
  /** the row to be inserted */
  object: Project_Payment_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Payment_Method_On_Conflict>;
};

export type Mutation_RootInsert_Project_Qr_Code_For_BuyerArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Qr_Code_For_Buyer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Qr_Code_For_Buyer_On_Conflict>;
};

export type Mutation_RootInsert_Project_Qr_Code_For_Buyer_OneArgs = {
  /** the row to be inserted */
  object: Project_Qr_Code_For_Buyer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Qr_Code_For_Buyer_On_Conflict>;
};

export type Mutation_RootInsert_Project_Reservation_SettingArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Reservation_Setting_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Reservation_Setting_On_Conflict>;
};

export type Mutation_RootInsert_Project_Reservation_Setting_OneArgs = {
  /** the row to be inserted */
  object: Project_Reservation_Setting_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Reservation_Setting_On_Conflict>;
};

export type Mutation_RootInsert_Project_SourcesArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Sources_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Sources_On_Conflict>;
};

export type Mutation_RootInsert_Project_Sources_OneArgs = {
  /** the row to be inserted */
  object: Project_Sources_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Sources_On_Conflict>;
};

export type Mutation_RootInsert_Project_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};

export type Mutation_RootInsert_Project_Status_OneArgs = {
  /** the row to be inserted */
  object: Project_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};

export type Mutation_RootInsert_Project_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Project_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Type_On_Conflict>;
};

export type Mutation_RootInsert_Project_Type_OneArgs = {
  /** the row to be inserted */
  object: Project_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Type_On_Conflict>;
};

export type Mutation_RootInsert_PropertyArgs = {
  /** the rows to be inserted */
  objects: Array<Property_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

export type Mutation_RootInsert_Property_Completion_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Property_Completion_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Completion_Status_On_Conflict>;
};

export type Mutation_RootInsert_Property_Completion_Status_OneArgs = {
  /** the row to be inserted */
  object: Property_Completion_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Completion_Status_On_Conflict>;
};

export type Mutation_RootInsert_Property_GroupArgs = {
  /** the rows to be inserted */
  objects: Array<Property_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Group_On_Conflict>;
};

export type Mutation_RootInsert_Property_Group_Completion_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Property_Group_Completion_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Group_Completion_Status_On_Conflict>;
};

export type Mutation_RootInsert_Property_Group_Completion_Status_OneArgs = {
  /** the row to be inserted */
  object: Property_Group_Completion_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Group_Completion_Status_On_Conflict>;
};

export type Mutation_RootInsert_Property_Group_OneArgs = {
  /** the row to be inserted */
  object: Property_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Group_On_Conflict>;
};

export type Mutation_RootInsert_Property_Group_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Property_Group_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Group_Type_On_Conflict>;
};

export type Mutation_RootInsert_Property_Group_Type_OneArgs = {
  /** the row to be inserted */
  object: Property_Group_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Group_Type_On_Conflict>;
};

export type Mutation_RootInsert_Property_OneArgs = {
  /** the row to be inserted */
  object: Property_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_On_Conflict>;
};

export type Mutation_RootInsert_Property_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Property_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Type_On_Conflict>;
};

export type Mutation_RootInsert_Property_Type_OneArgs = {
  /** the row to be inserted */
  object: Property_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Property_Type_On_Conflict>;
};

export type Mutation_RootInsert_ReservationArgs = {
  /** the rows to be inserted */
  objects: Array<Reservation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};

export type Mutation_RootInsert_Reservation_OneArgs = {
  /** the row to be inserted */
  object: Reservation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_ActionArgs = {
  /** the rows to be inserted */
  objects: Array<Salesforce_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Action_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Action_OneArgs = {
  /** the row to be inserted */
  object: Salesforce_Action_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Action_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Action_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Salesforce_Action_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Action_Status_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Action_Status_OneArgs = {
  /** the row to be inserted */
  object: Salesforce_Action_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Action_Status_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Bulk_ImportArgs = {
  /** the rows to be inserted */
  objects: Array<Salesforce_Bulk_Import_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Bulk_Import_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Bulk_Import_OneArgs = {
  /** the row to be inserted */
  object: Salesforce_Bulk_Import_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Bulk_Import_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_ConnectionArgs = {
  /** the rows to be inserted */
  objects: Array<Salesforce_Connection_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Connection_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Connection_OneArgs = {
  /** the row to be inserted */
  object: Salesforce_Connection_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Connection_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Sync_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Salesforce_Sync_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Sync_Status_On_Conflict>;
};

export type Mutation_RootInsert_Salesforce_Sync_Status_OneArgs = {
  /** the row to be inserted */
  object: Salesforce_Sync_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Salesforce_Sync_Status_On_Conflict>;
};

export type Mutation_RootInsert_SettingsArgs = {
  /** the rows to be inserted */
  objects: Array<Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Settings_On_Conflict>;
};

export type Mutation_RootInsert_Settings_OneArgs = {
  /** the row to be inserted */
  object: Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Settings_On_Conflict>;
};

export type Mutation_RootInsert_Sf_ActionArgs = {
  /** the rows to be inserted */
  objects: Array<Sf_Action_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sf_Action_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Action_OneArgs = {
  /** the row to be inserted */
  object: Sf_Action_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sf_Action_On_Conflict>;
};

export type Mutation_RootInsert_Sf_ContactArgs = {
  /** the rows to be inserted */
  objects: Array<Sf_Contact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sf_Contact_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Contact_OneArgs = {
  /** the row to be inserted */
  object: Sf_Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sf_Contact_On_Conflict>;
};

export type Mutation_RootInsert_Sf_OrganisationArgs = {
  /** the rows to be inserted */
  objects: Array<Sf_Organisation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sf_Organisation_On_Conflict>;
};

export type Mutation_RootInsert_Sf_Organisation_OneArgs = {
  /** the row to be inserted */
  object: Sf_Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sf_Organisation_On_Conflict>;
};

export type Mutation_RootInsert_Spoken_LanguageArgs = {
  /** the rows to be inserted */
  objects: Array<Spoken_Language_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Spoken_Language_On_Conflict>;
};

export type Mutation_RootInsert_Spoken_Language_OneArgs = {
  /** the row to be inserted */
  object: Spoken_Language_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Spoken_Language_On_Conflict>;
};

export type Mutation_RootInsert_SystemArgs = {
  /** the rows to be inserted */
  objects: Array<System_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<System_On_Conflict>;
};

export type Mutation_RootInsert_System_OneArgs = {
  /** the row to be inserted */
  object: System_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<System_On_Conflict>;
};

export type Mutation_RootInsert_TransactionArgs = {
  /** the rows to be inserted */
  objects: Array<Transaction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

export type Mutation_RootInsert_Transaction_OneArgs = {
  /** the row to be inserted */
  object: Transaction_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

export type Mutation_RootInsert_Transaction_StatusArgs = {
  /** the rows to be inserted */
  objects: Array<Transaction_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_Status_On_Conflict>;
};

export type Mutation_RootInsert_Transaction_Status_OneArgs = {
  /** the row to be inserted */
  object: Transaction_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_Status_On_Conflict>;
};

export type Mutation_RootInsert_Transaction_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Transaction_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_Type_On_Conflict>;
};

export type Mutation_RootInsert_Transaction_Type_OneArgs = {
  /** the row to be inserted */
  object: Transaction_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_Type_On_Conflict>;
};

export type Mutation_RootInsert_UserArgs = {
  /** the rows to be inserted */
  objects: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

export type Mutation_RootInsert_User_ExperimentArgs = {
  /** the rows to be inserted */
  objects: Array<User_Experiment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Experiment_On_Conflict>;
};

export type Mutation_RootInsert_User_Experiment_OneArgs = {
  /** the row to be inserted */
  object: User_Experiment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Experiment_On_Conflict>;
};

export type Mutation_RootInsert_User_FeatureArgs = {
  /** the rows to be inserted */
  objects: Array<User_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Feature_On_Conflict>;
};

export type Mutation_RootInsert_User_Feature_OneArgs = {
  /** the row to be inserted */
  object: User_Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Feature_On_Conflict>;
};

export type Mutation_RootInsert_User_OneArgs = {
  /** the row to be inserted */
  object: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

export type Mutation_RootInsert_User_Push_TokenArgs = {
  /** the rows to be inserted */
  objects: Array<User_Push_Token_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Push_Token_On_Conflict>;
};

export type Mutation_RootInsert_User_Push_Token_OneArgs = {
  /** the row to be inserted */
  object: User_Push_Token_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Push_Token_On_Conflict>;
};

export type Mutation_RootInsert_VariationArgs = {
  /** the rows to be inserted */
  objects: Array<Variation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_On_Conflict>;
};

export type Mutation_RootInsert_Variation_CategoryArgs = {
  /** the rows to be inserted */
  objects: Array<Variation_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_Category_On_Conflict>;
};

export type Mutation_RootInsert_Variation_Category_OneArgs = {
  /** the row to be inserted */
  object: Variation_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_Category_On_Conflict>;
};

export type Mutation_RootInsert_Variation_OneArgs = {
  /** the row to be inserted */
  object: Variation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_On_Conflict>;
};

export type Mutation_RootInsert_Variation_TypeArgs = {
  /** the rows to be inserted */
  objects: Array<Variation_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Variation_Type_CategoryArgs = {
  /** the rows to be inserted */
  objects: Array<Variation_Type_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_Type_Category_On_Conflict>;
};

export type Mutation_RootInsert_Variation_Type_Category_OneArgs = {
  /** the row to be inserted */
  object: Variation_Type_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_Type_Category_On_Conflict>;
};

export type Mutation_RootInsert_Variation_Type_OneArgs = {
  /** the row to be inserted */
  object: Variation_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Variation_Type_On_Conflict>;
};

export type Mutation_RootInsert_Web_App_FeatureArgs = {
  /** the rows to be inserted */
  objects: Array<Web_App_Feature_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Web_App_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Web_App_Feature_OneArgs = {
  /** the row to be inserted */
  object: Web_App_Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Web_App_Feature_On_Conflict>;
};

export type Mutation_RootInsert_Websocket_LogArgs = {
  /** the rows to be inserted */
  objects: Array<Websocket_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Websocket_Log_On_Conflict>;
};

export type Mutation_RootInsert_Websocket_Log_OneArgs = {
  /** the row to be inserted */
  object: Websocket_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Websocket_Log_On_Conflict>;
};

export type Mutation_RootMove_PropertyArgs = {
  propertyGroupId: Scalars['uuid'];
  propertyIds?: InputMaybe<Array<Scalars['uuid']>>;
};

export type Mutation_RootPartner_Portal_InviteArgs = {
  partnerPortalId: Scalars['String'];
};

export type Mutation_RootProcess_Lead_Generation_LogArgs = {
  leadLogId: Scalars['String'];
};

export type Mutation_RootPublish_ListingArgs = {
  arg: PublishListingRequest;
};

export type Mutation_RootPublish_Project_ListingArgs = {
  portal: Scalars['String'];
  projectId: Scalars['String'];
};

export type Mutation_RootRequest_RefundArgs = {
  dealId: Scalars['String'];
  requestAccountName?: InputMaybe<Scalars['String']>;
  requestAccountNumber?: InputMaybe<Scalars['String']>;
  requestAmount?: InputMaybe<Scalars['Int']>;
};

export type Mutation_RootReserve_DealArgs = {
  comment?: InputMaybe<Scalars['String']>;
  dealId: Scalars['String'];
};

export type Mutation_RootRun_TestArgs = {
  id: Scalars['String'];
};

export type Mutation_RootSalesforce_Create_Property_ListingArgs = {
  listingId: Scalars['uuid'];
};

export type Mutation_RootSalesforce_Delete_DealArgs = {
  dealId: Scalars['uuid'];
};

export type Mutation_RootSalesforce_Set_Deal_CommentArgs = {
  comment: Scalars['String'];
  dealId: Scalars['uuid'];
};

export type Mutation_RootSalesforce_Sync_Deal_Deposit_PercentageArgs = {
  dealId: Scalars['String'];
};

export type Mutation_RootSalesforce_Sync_Payable_OnArgs = {
  dealId: Scalars['String'];
};

export type Mutation_RootSalesforce_Update_ContactArgs = {
  args: SalesforceUpdateContactInput;
};

export type Mutation_RootSalesforce_Update_Deal_Pre_Settlement_Inspection_BookingArgs = {
  dealId: Scalars['uuid'];
};

export type Mutation_RootSalesforce_Update_Deal_Settlement_OutlookArgs = {
  dealId: Scalars['String'];
};

export type Mutation_RootSalesforce_Update_Deal_Settlement_StatusArgs = {
  dealId: Scalars['String'];
};

export type Mutation_RootSalesforce_Update_Deal_Valuation_BookingArgs = {
  dealValuationBookingId: Scalars['uuid'];
};

export type Mutation_RootSalesforce_Update_OrgArgs = {
  orgId: Scalars['uuid'];
};

export type Mutation_RootSend_Client_ReservationArgs = {
  channelType: Scalars['String'];
  currentStep?: InputMaybe<Scalars['String']>;
  dealId: Scalars['String'];
  orgId: Scalars['String'];
};

export type Mutation_RootSend_CodeArgs = {
  id: Scalars['String'];
};

export type Mutation_RootSend_NotificationArgs = {
  id: Scalars['String'];
};

export type Mutation_RootSend_Payment_Receipt_To_BuyerArgs = {
  input: SendPaymentReceiptToBuyerInput;
};

export type Mutation_RootSend_PushArgs = {
  object: SendPushInput;
};

export type Mutation_RootSend_Reservation_Invitation_To_BuyerArgs = {
  input: SendReservationInvitationToBuyerRequest;
};

export type Mutation_RootShare_Embed_EnquiryArgs = {
  enquiry: ShareEmbedEnquiry;
};

export type Mutation_RootShare_Listing_EnquiryArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  listingId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  sendVia: Scalars['String'];
};

export type Mutation_RootStart_Heroku_Connect_Bulk_ImportArgs = {
  input: HerokuConnectBulkImportReq;
};

export type Mutation_RootStripe_Check_TransactionArgs = {
  originId: Scalars['String'];
};

export type Mutation_RootStripe_PayArgs = {
  dealId: Scalars['uuid'];
  paidByContactId: Scalars['uuid'];
  paidToContactId: Scalars['uuid'];
};

export type Mutation_RootStripe_Pay_With_AmountArgs = {
  amountToBePaid?: InputMaybe<Scalars['numeric']>;
  dealId: Scalars['uuid'];
  paidByContactId: Scalars['uuid'];
  paidToContactId: Scalars['uuid'];
  transactionType: Scalars['String'];
};

export type Mutation_RootSwitch_TeamArgs = {
  teamId: Scalars['uuid'];
};

export type Mutation_RootTwilio_Send_MessageArgs = {
  input: TwilioSendMessageInput;
};

export type Mutation_RootUpdate_Acl_UpdateArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Acl_Update_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Acl_Update_Set_Input>;
  /** filter the rows which have to be updated */
  where: Acl_Update_Bool_Exp;
};

export type Mutation_RootUpdate_Acl_Update_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Acl_Update_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Acl_Update_Set_Input>;
  pk_columns: Acl_Update_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Acl_Update_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Acl_Update_Updates>;
};

export type Mutation_RootUpdate_Acl_Update_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Acl_Update_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Acl_Update_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Acl_Update_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Acl_Update_Status_Set_Input>;
  pk_columns: Acl_Update_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Acl_Update_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Acl_Update_Status_Updates>;
};

export type Mutation_RootUpdate_Agency_AgreementArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Agency_Agreement_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Agency_Agreement_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Agency_Agreement_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Agency_Agreement_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agency_Agreement_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Agency_Agreement_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agency_Agreement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Agency_Agreement_Bool_Exp;
};

export type Mutation_RootUpdate_Agency_Agreement_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Agency_Agreement_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Agency_Agreement_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Agency_Agreement_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Agency_Agreement_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agency_Agreement_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Agency_Agreement_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agency_Agreement_Set_Input>;
  pk_columns: Agency_Agreement_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Agency_Agreement_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Agency_Agreement_Updates>;
};

export type Mutation_RootUpdate_Agency_From_Agency_AgreementArgs = {
  agencyAgreementId: Scalars['uuid'];
};

export type Mutation_RootUpdate_AgreementArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agreement_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agreement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Agreement_Bool_Exp;
};

export type Mutation_RootUpdate_Agreement_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Agreement_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Agreement_Set_Input>;
  pk_columns: Agreement_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Agreement_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Agreement_Updates>;
};

export type Mutation_RootUpdate_AllocationArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allocation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allocation_Bool_Exp;
};

export type Mutation_RootUpdate_Allocation_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allocation_Set_Input>;
  pk_columns: Allocation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Allocation_LogArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allocation_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allocation_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Allocation_Log_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allocation_Log_Set_Input>;
  pk_columns: Allocation_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Allocation_Log_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Allocation_Log_Updates>;
};

export type Mutation_RootUpdate_Allocation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Allocation_Updates>;
};

export type Mutation_RootUpdate_Allocation_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allocation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allocation_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Allocation_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allocation_Type_Set_Input>;
  pk_columns: Allocation_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Allocation_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Allocation_Type_Updates>;
};

export type Mutation_RootUpdate_Apartment_Plan_BuildingArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apartment_Plan_Building_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apartment_Plan_Building_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apartment_Plan_Building_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apartment_Plan_Building_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apartment_Plan_Building_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Plan_Building_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apartment_Plan_Building_Bool_Exp;
};

export type Mutation_RootUpdate_Apartment_Plan_Building_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apartment_Plan_Building_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apartment_Plan_Building_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apartment_Plan_Building_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apartment_Plan_Building_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apartment_Plan_Building_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Plan_Building_Set_Input>;
  pk_columns: Apartment_Plan_Building_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Apartment_Plan_Building_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Apartment_Plan_Building_Updates>;
};

export type Mutation_RootUpdate_Apartment_Plan_PropertyArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apartment_Plan_Property_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apartment_Plan_Property_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apartment_Plan_Property_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apartment_Plan_Property_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apartment_Plan_Property_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Plan_Property_Set_Input>;
  /** filter the rows which have to be updated */
  where: Apartment_Plan_Property_Bool_Exp;
};

export type Mutation_RootUpdate_Apartment_Plan_Property_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apartment_Plan_Property_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apartment_Plan_Property_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apartment_Plan_Property_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apartment_Plan_Property_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apartment_Plan_Property_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Plan_Property_Set_Input>;
  pk_columns: Apartment_Plan_Property_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Apartment_Plan_Property_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Apartment_Plan_Property_Updates>;
};

export type Mutation_RootUpdate_Area_PlotArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Area_Plot_Set_Input>;
  /** filter the rows which have to be updated */
  where: Area_Plot_Bool_Exp;
};

export type Mutation_RootUpdate_Area_Plot_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Area_Plot_Set_Input>;
  pk_columns: Area_Plot_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Area_Plot_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Area_Plot_Updates>;
};

export type Mutation_RootUpdate_Area_Plot_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Area_Plot_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Area_Plot_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Area_Plot_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Area_Plot_Type_Set_Input>;
  pk_columns: Area_Plot_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Area_Plot_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Area_Plot_Type_Updates>;
};

export type Mutation_RootUpdate_AttachmentArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Set_Input>;
  pk_columns: Attachment_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_ConfigArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Config_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Config_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Config_Set_Input>;
  pk_columns: Attachment_Config_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Config_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Config_Updates>;
};

export type Mutation_RootUpdate_Attachment_Edit_Project_OrderArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Edit_Project_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Edit_Project_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Edit_Project_Order_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Edit_Project_Order_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Edit_Project_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Edit_Project_Order_Set_Input>;
  pk_columns: Attachment_Edit_Project_Order_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Edit_Project_Order_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Edit_Project_Order_Updates>;
};

export type Mutation_RootUpdate_Attachment_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Updates>;
};

export type Mutation_RootUpdate_Attachment_OwnerArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Owner_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Owner_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Owner_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Owner_Set_Input>;
  pk_columns: Attachment_Owner_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Owner_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Owner_Updates>;
};

export type Mutation_RootUpdate_Attachment_Product_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Product_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Product_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Product_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Product_Type_Set_Input>;
  pk_columns: Attachment_Product_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Product_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Product_Type_Updates>;
};

export type Mutation_RootUpdate_Attachment_Stage_OrderArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Stage_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Stage_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Stage_Order_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Stage_Order_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Stage_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Stage_Order_Set_Input>;
  pk_columns: Attachment_Stage_Order_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Stage_Order_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Stage_Order_Updates>;
};

export type Mutation_RootUpdate_Attachment_ThumbnailArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Thumbnail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Thumbnail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Thumbnail_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Thumbnail_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Thumbnail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Thumbnail_Set_Input>;
  pk_columns: Attachment_Thumbnail_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Thumbnail_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Thumbnail_Updates>;
};

export type Mutation_RootUpdate_Attachment_Thumbnail_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Thumbnail_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Thumbnail_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Thumbnail_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Thumbnail_Type_Set_Input>;
  pk_columns: Attachment_Thumbnail_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Thumbnail_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Thumbnail_Type_Updates>;
};

export type Mutation_RootUpdate_Attachment_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Attachment_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Type_Set_Input>;
  pk_columns: Attachment_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Attachment_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Attachment_Type_Updates>;
};

export type Mutation_RootUpdate_Buyer_PortalArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Buyer_Portal_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Buyer_Portal_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Buyer_Portal_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Buyer_Portal_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Buyer_Portal_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Portal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buyer_Portal_Bool_Exp;
};

export type Mutation_RootUpdate_Buyer_Portal_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Buyer_Portal_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Buyer_Portal_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Buyer_Portal_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Buyer_Portal_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Buyer_Portal_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Portal_Set_Input>;
  pk_columns: Buyer_Portal_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Buyer_Portal_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Buyer_Portal_Updates>;
};

export type Mutation_RootUpdate_Buyer_ProjectArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buyer_Project_Bool_Exp;
};

export type Mutation_RootUpdate_Buyer_Project_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Project_Set_Input>;
  pk_columns: Buyer_Project_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Buyer_Project_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Buyer_Project_Updates>;
};

export type Mutation_RootUpdate_Buyer_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buyer_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Buyer_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Type_Set_Input>;
  pk_columns: Buyer_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Buyer_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Buyer_Type_Updates>;
};

export type Mutation_RootUpdate_CacheArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cache_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cache_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cache_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cache_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cache_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cache_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cache_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cache_Bool_Exp;
};

export type Mutation_RootUpdate_Cache_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cache_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cache_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cache_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cache_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cache_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cache_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cache_Set_Input>;
  pk_columns: Cache_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Cache_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Cache_Updates>;
};

export type Mutation_RootUpdate_Change_InArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Change_In_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Change_In_Set_Input>;
  /** filter the rows which have to be updated */
  where: Change_In_Bool_Exp;
};

export type Mutation_RootUpdate_Change_In_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Change_In_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Change_In_Set_Input>;
  pk_columns: Change_In_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Change_In_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Change_In_Updates>;
};

export type Mutation_RootUpdate_Change_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Change_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Change_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Change_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Change_Status_Set_Input>;
  pk_columns: Change_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Change_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Change_Status_Updates>;
};

export type Mutation_RootUpdate_CodeArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Code_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Code_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Code_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Code_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Code_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Code_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Code_Bool_Exp;
};

export type Mutation_RootUpdate_Code_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Code_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Code_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Code_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Code_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Code_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Code_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Code_Set_Input>;
  pk_columns: Code_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Code_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Code_Updates>;
};

export type Mutation_RootUpdate_CommissionArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Bool_Exp;
};

export type Mutation_RootUpdate_Commission_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Commission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Set_Input>;
  pk_columns: Commission_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Commission_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Commission_Updates>;
};

export type Mutation_RootUpdate_Commission_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Commission_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Commission_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Commission_Type_Set_Input>;
  pk_columns: Commission_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Commission_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Commission_Type_Updates>;
};

export type Mutation_RootUpdate_ContactArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Bool_Exp;
};

export type Mutation_RootUpdate_Contact_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Set_Input>;
  pk_columns: Contact_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Contact_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Contact_Updates>;
};

export type Mutation_RootUpdate_Contact_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Contact_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Type_Set_Input>;
  pk_columns: Contact_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Contact_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Contact_Type_Updates>;
};

export type Mutation_RootUpdate_Count_With_Rolling_AvgArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Count_With_Rolling_Avg_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Count_With_Rolling_Avg_Set_Input>;
  /** filter the rows which have to be updated */
  where: Count_With_Rolling_Avg_Bool_Exp;
};

export type Mutation_RootUpdate_Count_With_Rolling_Avg_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Count_With_Rolling_Avg_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Count_With_Rolling_Avg_Set_Input>;
  pk_columns: Count_With_Rolling_Avg_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Count_With_Rolling_Avg_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Count_With_Rolling_Avg_Updates>;
};

export type Mutation_RootUpdate_CountryArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

export type Mutation_RootUpdate_Country_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Country_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Country_Updates>;
};

export type Mutation_RootUpdate_Crm_ConnectionArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Crm_Connection_Set_Input>;
  /** filter the rows which have to be updated */
  where: Crm_Connection_Bool_Exp;
};

export type Mutation_RootUpdate_Crm_Connection_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Crm_Connection_Set_Input>;
  pk_columns: Crm_Connection_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Crm_Connection_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Crm_Connection_Updates>;
};

export type Mutation_RootUpdate_DealArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deal_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deal_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Set_Input>;
  pk_columns: Deal_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_ContactArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Contact_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Contact_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Contact_Set_Input>;
  pk_columns: Deal_Contact_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Contact_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Contact_Updates>;
};

export type Mutation_RootUpdate_Deal_Contact_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Contact_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Contact_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Contact_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Contact_Status_Set_Input>;
  pk_columns: Deal_Contact_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Contact_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Contact_Status_Updates>;
};

export type Mutation_RootUpdate_Deal_ContractArgs = {
  deal: DealContractRequest;
};

export type Mutation_RootUpdate_Deal_Deposit_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Deposit_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Deposit_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Deposit_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Deposit_Status_Set_Input>;
  pk_columns: Deal_Deposit_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Deposit_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Deposit_Status_Updates>;
};

export type Mutation_RootUpdate_Deal_Deposit_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Deposit_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Deposit_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Deposit_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Deposit_Type_Set_Input>;
  pk_columns: Deal_Deposit_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Deposit_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Deposit_Type_Updates>;
};

export type Mutation_RootUpdate_Deal_Enquiry_SourceArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Enquiry_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Enquiry_Source_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Enquiry_Source_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Enquiry_Source_Set_Input>;
  pk_columns: Deal_Enquiry_Source_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Enquiry_Source_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Enquiry_Source_Updates>;
};

export type Mutation_RootUpdate_Deal_Fallen_ReasonArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Fallen_Reason_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Fallen_Reason_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Fallen_Reason_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Fallen_Reason_Set_Input>;
  pk_columns: Deal_Fallen_Reason_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Fallen_Reason_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Fallen_Reason_Updates>;
};

export type Mutation_RootUpdate_Deal_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Updates>;
};

export type Mutation_RootUpdate_Deal_Payment_MethodArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Payment_Method_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Payment_Method_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Payment_Method_Set_Input>;
  pk_columns: Deal_Payment_Method_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Payment_Method_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Payment_Method_Updates>;
};

export type Mutation_RootUpdate_Deal_Progress_UpdateArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Progress_Update_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Progress_Update_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Progress_Update_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Progress_Update_Set_Input>;
  pk_columns: Deal_Progress_Update_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Progress_Update_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Progress_Update_Updates>;
};

export type Mutation_RootUpdate_Deal_Reason_PurchaseArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Reason_Purchase_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Reason_Purchase_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Reason_Purchase_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Reason_Purchase_Set_Input>;
  pk_columns: Deal_Reason_Purchase_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Reason_Purchase_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Reason_Purchase_Updates>;
};

export type Mutation_RootUpdate_Deal_Sf_ActionArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Deal_Sf_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Deal_Sf_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Deal_Sf_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Deal_Sf_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Deal_Sf_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Sf_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Sf_Action_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Sf_Action_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Deal_Sf_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Deal_Sf_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Deal_Sf_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Deal_Sf_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Deal_Sf_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Sf_Action_Set_Input>;
  pk_columns: Deal_Sf_Action_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Sf_Action_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Sf_Action_Updates>;
};

export type Mutation_RootUpdate_Deal_SolicitorArgs = {
  deal: DealSolicitorRequest;
};

export type Mutation_RootUpdate_Deal_Solicitor_And_ContractArgs = {
  deal: DealSolicitorAndContractRequest;
};

export type Mutation_RootUpdate_Deal_Spoken_LanguageArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Spoken_Language_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Spoken_Language_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Spoken_Language_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Spoken_Language_Set_Input>;
  pk_columns: Deal_Spoken_Language_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Spoken_Language_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Spoken_Language_Updates>;
};

export type Mutation_RootUpdate_Deal_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Status_Set_Input>;
  pk_columns: Deal_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Status_Updates>;
};

export type Mutation_RootUpdate_Deal_Transfer_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Transfer_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Transfer_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Transfer_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Transfer_Status_Set_Input>;
  pk_columns: Deal_Transfer_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Transfer_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Transfer_Status_Updates>;
};

export type Mutation_RootUpdate_Deal_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Type_Set_Input>;
  pk_columns: Deal_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Type_Updates>;
};

export type Mutation_RootUpdate_Deal_Valuation_BookingArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deal_Valuation_Booking_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Valuation_Booking_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Valuation_Booking_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Valuation_Booking_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deal_Valuation_Booking_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Valuation_Booking_Set_Input>;
  pk_columns: Deal_Valuation_Booking_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Valuation_Booking_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Valuation_Booking_Updates>;
};

export type Mutation_RootUpdate_Deal_WitnessArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Witness_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Witness_Bool_Exp;
};

export type Mutation_RootUpdate_Deal_Witness_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Witness_Set_Input>;
  pk_columns: Deal_Witness_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Deal_Witness_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Deal_Witness_Updates>;
};

export type Mutation_RootUpdate_Developer_AgentArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Developer_Agent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Developer_Agent_Bool_Exp;
};

export type Mutation_RootUpdate_Developer_Agent_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Developer_Agent_Set_Input>;
  pk_columns: Developer_Agent_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Developer_Agent_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Developer_Agent_Updates>;
};

export type Mutation_RootUpdate_DocumentArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Document_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Document_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Document_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Document_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Document_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Document_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Bool_Exp;
};

export type Mutation_RootUpdate_Document_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Document_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Document_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Document_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Document_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Document_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Document_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Set_Input>;
  pk_columns: Document_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Document_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Document_Updates>;
};

export type Mutation_RootUpdate_Document_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Document_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Type_Set_Input>;
  pk_columns: Document_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Document_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Document_Type_Updates>;
};

export type Mutation_RootUpdate_EmbedArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Embed_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Embed_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Embed_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Embed_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Embed_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Embed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Embed_Bool_Exp;
};

export type Mutation_RootUpdate_Embed_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Embed_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Embed_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Embed_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Embed_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Embed_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Embed_Set_Input>;
  pk_columns: Embed_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Embed_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Embed_Updates>;
};

export type Mutation_RootUpdate_EnquiryArgs = {
  enquiry: EnquiryUpdateRequest;
};

export type Mutation_RootUpdate_EnvelopeArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Envelope_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Envelope_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Envelope_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Envelope_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Envelope_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Set_Input>;
  /** filter the rows which have to be updated */
  where: Envelope_Bool_Exp;
};

export type Mutation_RootUpdate_Envelope_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Envelope_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Envelope_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Envelope_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Envelope_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Envelope_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Set_Input>;
  pk_columns: Envelope_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Envelope_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Envelope_Updates>;
};

export type Mutation_RootUpdate_Envelope_OwnerArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Owner_Set_Input>;
  /** filter the rows which have to be updated */
  where: Envelope_Owner_Bool_Exp;
};

export type Mutation_RootUpdate_Envelope_Owner_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Owner_Set_Input>;
  pk_columns: Envelope_Owner_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Envelope_Owner_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Envelope_Owner_Updates>;
};

export type Mutation_RootUpdate_Envelope_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Envelope_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Envelope_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Status_Set_Input>;
  pk_columns: Envelope_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Envelope_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Envelope_Status_Updates>;
};

export type Mutation_RootUpdate_Envelope_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Envelope_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Envelope_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Envelope_Type_Set_Input>;
  pk_columns: Envelope_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Envelope_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Envelope_Type_Updates>;
};

export type Mutation_RootUpdate_ExperimentArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Experiment_Bool_Exp;
};

export type Mutation_RootUpdate_Experiment_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiment_Set_Input>;
  pk_columns: Experiment_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Experiment_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Experiment_Updates>;
};

export type Mutation_RootUpdate_FeatureArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Bool_Exp;
};

export type Mutation_RootUpdate_Feature_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Set_Input>;
  pk_columns: Feature_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Feature_CheckArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Check_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Check_Bool_Exp;
};

export type Mutation_RootUpdate_Feature_Check_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Check_Set_Input>;
  pk_columns: Feature_Check_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Feature_Check_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Feature_Check_Updates>;
};

export type Mutation_RootUpdate_Feature_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Feature_Updates>;
};

export type Mutation_RootUpdate_Feature_PackArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Pack_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Pack_Bool_Exp;
};

export type Mutation_RootUpdate_Feature_Pack_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Pack_Set_Input>;
  pk_columns: Feature_Pack_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Feature_Pack_FeatureArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Pack_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Pack_Feature_Bool_Exp;
};

export type Mutation_RootUpdate_Feature_Pack_Feature_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Pack_Feature_Set_Input>;
  pk_columns: Feature_Pack_Feature_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Feature_Pack_Feature_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Feature_Pack_Feature_Updates>;
};

export type Mutation_RootUpdate_Feature_Pack_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Feature_Pack_Updates>;
};

export type Mutation_RootUpdate_Filter_PageArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filter_Page_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filter_Page_Bool_Exp;
};

export type Mutation_RootUpdate_Filter_Page_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filter_Page_Set_Input>;
  pk_columns: Filter_Page_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Filter_Page_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Filter_Page_Updates>;
};

export type Mutation_RootUpdate_Filter_StateArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Filter_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Filter_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Filter_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Filter_State_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Filter_State_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Filter_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filter_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filter_State_Bool_Exp;
};

export type Mutation_RootUpdate_Filter_State_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Filter_State_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Filter_State_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Filter_State_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Filter_State_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Filter_State_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Filter_State_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filter_State_Set_Input>;
  pk_columns: Filter_State_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Filter_State_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Filter_State_Updates>;
};

export type Mutation_RootUpdate_Finance_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Finance_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finance_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Finance_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Finance_Status_Set_Input>;
  pk_columns: Finance_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Finance_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Finance_Status_Updates>;
};

export type Mutation_RootUpdate_Graphql_Query_LogArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Graphql_Query_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Graphql_Query_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Graphql_Query_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Graphql_Query_Log_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Graphql_Query_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Graphql_Query_Log_Set_Input>;
  pk_columns: Graphql_Query_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Graphql_Query_Log_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Graphql_Query_Log_Updates>;
};

export type Mutation_RootUpdate_Hubspot_ActionArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hubspot_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hubspot_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hubspot_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hubspot_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hubspot_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Action_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Action_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hubspot_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hubspot_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hubspot_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hubspot_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hubspot_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Action_Set_Input>;
  pk_columns: Hubspot_Action_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Action_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Action_Updates>;
};

export type Mutation_RootUpdate_Hubspot_Action_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Action_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Action_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Action_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Action_Type_Set_Input>;
  pk_columns: Hubspot_Action_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Action_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Action_Type_Updates>;
};

export type Mutation_RootUpdate_Hubspot_ContactArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Contact_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Contact_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Contact_Set_Input>;
  pk_columns: Hubspot_Contact_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Contact_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Contact_Updates>;
};

export type Mutation_RootUpdate_Hubspot_DealArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Deal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Deal_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Deal_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Deal_Set_Input>;
  pk_columns: Hubspot_Deal_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Deal_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Deal_Updates>;
};

export type Mutation_RootUpdate_Hubspot_OrganisationArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Organisation_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Organisation_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Organisation_Set_Input>;
  pk_columns: Hubspot_Organisation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Organisation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Organisation_Updates>;
};

export type Mutation_RootUpdate_Hubspot_ProjectArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Project_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Project_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Project_Set_Input>;
  pk_columns: Hubspot_Project_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Project_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Project_Updates>;
};

export type Mutation_RootUpdate_Hubspot_Schema_Error_LogArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hubspot_Schema_Error_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hubspot_Schema_Error_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hubspot_Schema_Error_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hubspot_Schema_Error_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hubspot_Schema_Error_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Schema_Error_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Schema_Error_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Schema_Error_Log_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hubspot_Schema_Error_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hubspot_Schema_Error_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hubspot_Schema_Error_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hubspot_Schema_Error_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hubspot_Schema_Error_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Schema_Error_Log_Set_Input>;
  pk_columns: Hubspot_Schema_Error_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Schema_Error_Log_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Schema_Error_Log_Updates>;
};

export type Mutation_RootUpdate_Hubspot_UserArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_User_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_User_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_User_Set_Input>;
  pk_columns: Hubspot_User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_User_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_User_Updates>;
};

export type Mutation_RootUpdate_Hubspot_Valuation_BookingArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Valuation_Booking_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hubspot_Valuation_Booking_Bool_Exp;
};

export type Mutation_RootUpdate_Hubspot_Valuation_Booking_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hubspot_Valuation_Booking_Set_Input>;
  pk_columns: Hubspot_Valuation_Booking_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Hubspot_Valuation_Booking_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Hubspot_Valuation_Booking_Updates>;
};

export type Mutation_RootUpdate_InvitationArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invitation_Bool_Exp;
};

export type Mutation_RootUpdate_Invitation_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitation_Set_Input>;
  pk_columns: Invitation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Invitation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Invitation_Updates>;
};

export type Mutation_RootUpdate_Invitation_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invitation_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Invitation_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitation_Status_Set_Input>;
  pk_columns: Invitation_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Invitation_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Invitation_Status_Updates>;
};

export type Mutation_RootUpdate_Invitation_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invitation_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Invitation_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitation_Type_Set_Input>;
  pk_columns: Invitation_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Invitation_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Invitation_Type_Updates>;
};

export type Mutation_RootUpdate_Lambda_LogArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lambda_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lambda_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lambda_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lambda_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lambda_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lambda_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lambda_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Lambda_Log_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lambda_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lambda_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lambda_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lambda_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lambda_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lambda_Log_Set_Input>;
  pk_columns: Lambda_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Lambda_Log_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Lambda_Log_Updates>;
};

export type Mutation_RootUpdate_Lead_GenerationArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lead_Generation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lead_Generation_Bool_Exp;
};

export type Mutation_RootUpdate_Lead_Generation_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lead_Generation_Set_Input>;
  pk_columns: Lead_Generation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Lead_Generation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Lead_Generation_Updates>;
};

export type Mutation_RootUpdate_Lead_Generation_SourceArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lead_Generation_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lead_Generation_Source_Bool_Exp;
};

export type Mutation_RootUpdate_Lead_Generation_Source_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lead_Generation_Source_Set_Input>;
  pk_columns: Lead_Generation_Source_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Lead_Generation_Source_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Lead_Generation_Source_Updates>;
};

export type Mutation_RootUpdate_Lead_Generation_Webhook_LogArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lead_Generation_Webhook_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lead_Generation_Webhook_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lead_Generation_Webhook_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lead_Generation_Webhook_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lead_Generation_Webhook_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lead_Generation_Webhook_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lead_Generation_Webhook_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Lead_Generation_Webhook_Log_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lead_Generation_Webhook_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lead_Generation_Webhook_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lead_Generation_Webhook_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lead_Generation_Webhook_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lead_Generation_Webhook_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lead_Generation_Webhook_Log_Set_Input>;
  pk_columns: Lead_Generation_Webhook_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Lead_Generation_Webhook_Log_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Lead_Generation_Webhook_Log_Updates>;
};

export type Mutation_RootUpdate_ListingArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Listing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listing_Bool_Exp;
};

export type Mutation_RootUpdate_Listing_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Listing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Set_Input>;
  pk_columns: Listing_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Listing_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Listing_Updates>;
};

export type Mutation_RootUpdate_Listing_Publish_ErrorsArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Listing_Publish_Errors_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Listing_Publish_Errors_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Listing_Publish_Errors_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Listing_Publish_Errors_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Listing_Publish_Errors_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Listing_Publish_Errors_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Errors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listing_Publish_Errors_Bool_Exp;
};

export type Mutation_RootUpdate_Listing_Publish_Errors_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Listing_Publish_Errors_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Listing_Publish_Errors_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Listing_Publish_Errors_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Listing_Publish_Errors_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Listing_Publish_Errors_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Listing_Publish_Errors_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Errors_Set_Input>;
  pk_columns: Listing_Publish_Errors_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Listing_Publish_Errors_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Listing_Publish_Errors_Updates>;
};

export type Mutation_RootUpdate_Listing_Publish_Portal_SourceArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Portal_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listing_Publish_Portal_Source_Bool_Exp;
};

export type Mutation_RootUpdate_Listing_Publish_Portal_Source_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Portal_Source_Set_Input>;
  pk_columns: Listing_Publish_Portal_Source_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Listing_Publish_Portal_Source_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Listing_Publish_Portal_Source_Updates>;
};

export type Mutation_RootUpdate_Listing_Publish_StatusArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Listing_Publish_Status_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Listing_Publish_Status_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Listing_Publish_Status_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Listing_Publish_Status_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Listing_Publish_Status_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listing_Publish_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Listing_Publish_Status_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Listing_Publish_Status_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Listing_Publish_Status_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Listing_Publish_Status_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Listing_Publish_Status_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Listing_Publish_Status_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Status_Set_Input>;
  pk_columns: Listing_Publish_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Listing_Publish_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Listing_Publish_Status_Updates>;
};

export type Mutation_RootUpdate_Listing_Publish_Status_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Status_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listing_Publish_Status_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Listing_Publish_Status_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Publish_Status_Type_Set_Input>;
  pk_columns: Listing_Publish_Status_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Listing_Publish_Status_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Listing_Publish_Status_Type_Updates>;
};

export type Mutation_RootUpdate_Listing_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Listing_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Listing_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Listing_Status_Set_Input>;
  pk_columns: Listing_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Listing_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Listing_Status_Updates>;
};

export type Mutation_RootUpdate_MemberArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Set_Input>;
  /** filter the rows which have to be updated */
  where: Member_Bool_Exp;
};

export type Mutation_RootUpdate_Member_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Set_Input>;
  pk_columns: Member_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Member_FeatureArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Member_Feature_Bool_Exp;
};

export type Mutation_RootUpdate_Member_Feature_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Feature_Set_Input>;
  pk_columns: Member_Feature_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Member_Feature_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Member_Feature_Updates>;
};

export type Mutation_RootUpdate_Member_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Member_Updates>;
};

export type Mutation_RootUpdate_Member_Project_AllocateArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Project_Allocate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Member_Project_Allocate_Bool_Exp;
};

export type Mutation_RootUpdate_Member_Project_Allocate_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Project_Allocate_Set_Input>;
  pk_columns: Member_Project_Allocate_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Member_Project_Allocate_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Member_Project_Allocate_Updates>;
};

export type Mutation_RootUpdate_Member_RoleArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Member_Role_Bool_Exp;
};

export type Mutation_RootUpdate_Member_Role_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Member_Role_Set_Input>;
  pk_columns: Member_Role_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Member_Role_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Member_Role_Updates>;
};

export type Mutation_RootUpdate_NationalityArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nationality_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nationality_Bool_Exp;
};

export type Mutation_RootUpdate_Nationality_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nationality_Set_Input>;
  pk_columns: Nationality_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Nationality_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Nationality_Updates>;
};

export type Mutation_RootUpdate_NotificationArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Bool_Exp;
};

export type Mutation_RootUpdate_Notification_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Set_Input>;
  pk_columns: Notification_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Notification_ChannelArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Channel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Channel_Bool_Exp;
};

export type Mutation_RootUpdate_Notification_Channel_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Channel_Set_Input>;
  pk_columns: Notification_Channel_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Notification_Channel_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Notification_Channel_Updates>;
};

export type Mutation_RootUpdate_Notification_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Notification_Updates>;
};

export type Mutation_RootUpdate_Notification_OwnerArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Owner_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Owner_Bool_Exp;
};

export type Mutation_RootUpdate_Notification_Owner_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Owner_Set_Input>;
  pk_columns: Notification_Owner_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Notification_Owner_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Notification_Owner_Updates>;
};

export type Mutation_RootUpdate_Notification_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Notification_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Type_Set_Input>;
  pk_columns: Notification_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Notification_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Notification_Type_Updates>;
};

export type Mutation_RootUpdate_Org_Feature_PackArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Org_Feature_Pack_Set_Input>;
  /** filter the rows which have to be updated */
  where: Org_Feature_Pack_Bool_Exp;
};

export type Mutation_RootUpdate_Org_Feature_Pack_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Org_Feature_Pack_Set_Input>;
  pk_columns: Org_Feature_Pack_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Org_Feature_Pack_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Org_Feature_Pack_Updates>;
};

export type Mutation_RootUpdate_OrganisationArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Organisation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Organisation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Organisation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Organisation_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Organisation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Bool_Exp;
};

export type Mutation_RootUpdate_Organisation_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Organisation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Organisation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Organisation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Organisation_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Organisation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Set_Input>;
  pk_columns: Organisation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Organisation_FeatureArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Feature_Bool_Exp;
};

export type Mutation_RootUpdate_Organisation_Feature_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Feature_Set_Input>;
  pk_columns: Organisation_Feature_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Organisation_Feature_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Organisation_Feature_Updates>;
};

export type Mutation_RootUpdate_Organisation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Organisation_Updates>;
};

export type Mutation_RootUpdate_Organisation_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Organisation_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organisation_Type_Set_Input>;
  pk_columns: Organisation_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Organisation_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Organisation_Type_Updates>;
};

export type Mutation_RootUpdate_Partner_PortalArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Portal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Portal_Bool_Exp;
};

export type Mutation_RootUpdate_Partner_Portal_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Portal_Set_Input>;
  pk_columns: Partner_Portal_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Partner_Portal_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Partner_Portal_Updates>;
};

export type Mutation_RootUpdate_Payment_MethodArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Method_Bool_Exp;
};

export type Mutation_RootUpdate_Payment_Method_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Set_Input>;
  pk_columns: Payment_Method_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Payment_Method_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Payment_Method_Updates>;
};

export type Mutation_RootUpdate_Payment_Method_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Method_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Payment_Method_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Type_Set_Input>;
  pk_columns: Payment_Method_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Payment_Method_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Payment_Method_Type_Updates>;
};

export type Mutation_RootUpdate_PreferenceArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Preference_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Preference_Set_Input>;
  /** filter the rows which have to be updated */
  where: Preference_Bool_Exp;
};

export type Mutation_RootUpdate_Preference_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Preference_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Preference_Set_Input>;
  pk_columns: Preference_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Preference_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Preference_Updates>;
};

export type Mutation_RootUpdate_ProcessArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Process_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Process_Set_Input>;
  /** filter the rows which have to be updated */
  where: Process_Bool_Exp;
};

export type Mutation_RootUpdate_Process_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Process_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Process_Set_Input>;
  pk_columns: Process_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Process_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Process_Updates>;
};

export type Mutation_RootUpdate_Process_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Process_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Process_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Process_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Process_Status_Set_Input>;
  pk_columns: Process_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Process_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Process_Status_Updates>;
};

export type Mutation_RootUpdate_ProjectArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Bool_Exp;
};

export type Mutation_RootUpdate_Project_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_DetailArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Detail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Detail_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Detail_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Detail_Set_Input>;
  pk_columns: Project_Detail_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Detail_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Detail_Updates>;
};

export type Mutation_RootUpdate_Project_FeaturesArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Features_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Features_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Features_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Features_Set_Input>;
  pk_columns: Project_Features_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Features_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Features_Updates>;
};

export type Mutation_RootUpdate_Project_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Updates>;
};

export type Mutation_RootUpdate_Project_Payment_MethodArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Project_Payment_Method_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Project_Payment_Method_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Project_Payment_Method_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Project_Payment_Method_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Project_Payment_Method_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Payment_Method_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Payment_Method_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Project_Payment_Method_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Project_Payment_Method_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Project_Payment_Method_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Project_Payment_Method_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Project_Payment_Method_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Payment_Method_Set_Input>;
  pk_columns: Project_Payment_Method_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Payment_Method_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Payment_Method_Updates>;
};

export type Mutation_RootUpdate_Project_Qr_Code_For_BuyerArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Qr_Code_For_Buyer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Qr_Code_For_Buyer_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Qr_Code_For_Buyer_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Qr_Code_For_Buyer_Set_Input>;
  pk_columns: Project_Qr_Code_For_Buyer_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Qr_Code_For_Buyer_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Qr_Code_For_Buyer_Updates>;
};

export type Mutation_RootUpdate_Project_Reservation_SettingArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Reservation_Setting_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Reservation_Setting_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Reservation_Setting_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Reservation_Setting_Set_Input>;
  pk_columns: Project_Reservation_Setting_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Reservation_Setting_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Reservation_Setting_Updates>;
};

export type Mutation_RootUpdate_Project_SourcesArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Sources_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Sources_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Sources_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Sources_Set_Input>;
  pk_columns: Project_Sources_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Sources_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Sources_Updates>;
};

export type Mutation_RootUpdate_Project_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Status_Set_Input>;
  pk_columns: Project_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Status_Updates>;
};

export type Mutation_RootUpdate_Project_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Project_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Type_Set_Input>;
  pk_columns: Project_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Project_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Project_Type_Updates>;
};

export type Mutation_RootUpdate_PropertyArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Bool_Exp;
};

export type Mutation_RootUpdate_Property_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Set_Input>;
  pk_columns: Property_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Property_Completion_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Completion_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Completion_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Property_Completion_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Completion_Status_Set_Input>;
  pk_columns: Property_Completion_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Property_Completion_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Property_Completion_Status_Updates>;
};

export type Mutation_RootUpdate_Property_GroupArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Property_Group_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Property_Group_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Property_Group_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Property_Group_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Group_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Property_Group_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Group_Bool_Exp;
};

export type Mutation_RootUpdate_Property_Group_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Property_Group_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Property_Group_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Property_Group_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Property_Group_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Property_Group_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Property_Group_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Group_Set_Input>;
  pk_columns: Property_Group_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Property_Group_Completion_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Group_Completion_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Group_Completion_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Property_Group_Completion_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Group_Completion_Status_Set_Input>;
  pk_columns: Property_Group_Completion_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Property_Group_Completion_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Property_Group_Completion_Status_Updates>;
};

export type Mutation_RootUpdate_Property_Group_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Property_Group_Updates>;
};

export type Mutation_RootUpdate_Property_Group_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Group_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Group_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Property_Group_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Group_Type_Set_Input>;
  pk_columns: Property_Group_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Property_Group_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Property_Group_Type_Updates>;
};

export type Mutation_RootUpdate_Property_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Property_Updates>;
};

export type Mutation_RootUpdate_Property_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Property_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Property_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Property_Type_Set_Input>;
  pk_columns: Property_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Property_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Property_Type_Updates>;
};

export type Mutation_RootUpdate_ReservationArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reservation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reservation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reservation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reservation_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reservation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Bool_Exp;
};

export type Mutation_RootUpdate_Reservation_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reservation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reservation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reservation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reservation_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reservation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservation_Set_Input>;
  pk_columns: Reservation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Reservation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Reservation_Updates>;
};

export type Mutation_RootUpdate_Salesforce_ActionArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Action_Bool_Exp;
};

export type Mutation_RootUpdate_Salesforce_Action_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Action_Set_Input>;
  pk_columns: Salesforce_Action_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Salesforce_Action_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Salesforce_Action_Updates>;
};

export type Mutation_RootUpdate_Salesforce_Action_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Action_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Action_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Salesforce_Action_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Action_Status_Set_Input>;
  pk_columns: Salesforce_Action_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Salesforce_Action_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Salesforce_Action_Status_Updates>;
};

export type Mutation_RootUpdate_Salesforce_Bulk_ImportArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Salesforce_Bulk_Import_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Salesforce_Bulk_Import_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Salesforce_Bulk_Import_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Salesforce_Bulk_Import_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Salesforce_Bulk_Import_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Salesforce_Bulk_Import_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Bulk_Import_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Bulk_Import_Bool_Exp;
};

export type Mutation_RootUpdate_Salesforce_Bulk_Import_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Salesforce_Bulk_Import_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Salesforce_Bulk_Import_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Salesforce_Bulk_Import_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Salesforce_Bulk_Import_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Salesforce_Bulk_Import_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Salesforce_Bulk_Import_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Bulk_Import_Set_Input>;
  pk_columns: Salesforce_Bulk_Import_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Salesforce_Bulk_Import_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Salesforce_Bulk_Import_Updates>;
};

export type Mutation_RootUpdate_Salesforce_ConnectionArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Connection_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Connection_Bool_Exp;
};

export type Mutation_RootUpdate_Salesforce_Connection_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Connection_Set_Input>;
  pk_columns: Salesforce_Connection_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Salesforce_Connection_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Salesforce_Connection_Updates>;
};

export type Mutation_RootUpdate_Salesforce_Sync_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Sync_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Salesforce_Sync_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Salesforce_Sync_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salesforce_Sync_Status_Set_Input>;
  pk_columns: Salesforce_Sync_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Salesforce_Sync_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Salesforce_Sync_Status_Updates>;
};

export type Mutation_RootUpdate_SettingsArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Settings_Bool_Exp;
};

export type Mutation_RootUpdate_Settings_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Settings_Set_Input>;
  pk_columns: Settings_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Settings_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Settings_Updates>;
};

export type Mutation_RootUpdate_Sf_ActionArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sf_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sf_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sf_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sf_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sf_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sf_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sf_Action_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Action_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sf_Action_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sf_Action_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sf_Action_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sf_Action_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sf_Action_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sf_Action_Set_Input>;
  pk_columns: Sf_Action_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Action_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Sf_Action_Updates>;
};

export type Mutation_RootUpdate_Sf_ContactArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sf_Contact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sf_Contact_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Contact_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sf_Contact_Set_Input>;
  pk_columns: Sf_Contact_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Contact_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Sf_Contact_Updates>;
};

export type Mutation_RootUpdate_Sf_OrganisationArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sf_Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sf_Organisation_Bool_Exp;
};

export type Mutation_RootUpdate_Sf_Organisation_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sf_Organisation_Set_Input>;
  pk_columns: Sf_Organisation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Sf_Organisation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Sf_Organisation_Updates>;
};

export type Mutation_RootUpdate_Spoken_LanguageArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spoken_Language_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spoken_Language_Bool_Exp;
};

export type Mutation_RootUpdate_Spoken_Language_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spoken_Language_Set_Input>;
  pk_columns: Spoken_Language_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Spoken_Language_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Spoken_Language_Updates>;
};

export type Mutation_RootUpdate_SystemArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<System_Set_Input>;
  /** filter the rows which have to be updated */
  where: System_Bool_Exp;
};

export type Mutation_RootUpdate_System_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<System_Set_Input>;
  pk_columns: System_Pk_Columns_Input;
};

export type Mutation_RootUpdate_System_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<System_Updates>;
};

export type Mutation_RootUpdate_TransactionArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transaction_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transaction_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transaction_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transaction_Bool_Exp;
};

export type Mutation_RootUpdate_Transaction_By_PkArgs = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transaction_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transaction_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transaction_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transaction_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transaction_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transaction_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Set_Input>;
  pk_columns: Transaction_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Transaction_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Transaction_Updates>;
};

export type Mutation_RootUpdate_Transaction_StatusArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transaction_Status_Bool_Exp;
};

export type Mutation_RootUpdate_Transaction_Status_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Status_Set_Input>;
  pk_columns: Transaction_Status_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Transaction_Status_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Transaction_Status_Updates>;
};

export type Mutation_RootUpdate_Transaction_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transaction_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Transaction_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Type_Set_Input>;
  pk_columns: Transaction_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Transaction_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Transaction_Type_Updates>;
};

export type Mutation_RootUpdate_UserArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

export type Mutation_RootUpdate_User_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_ExperimentArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Experiment_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Experiment_Bool_Exp;
};

export type Mutation_RootUpdate_User_Experiment_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Experiment_Set_Input>;
  pk_columns: User_Experiment_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Experiment_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<User_Experiment_Updates>;
};

export type Mutation_RootUpdate_User_FeatureArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Feature_Bool_Exp;
};

export type Mutation_RootUpdate_User_Feature_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Feature_Set_Input>;
  pk_columns: User_Feature_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Feature_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<User_Feature_Updates>;
};

export type Mutation_RootUpdate_User_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<User_Updates>;
};

export type Mutation_RootUpdate_User_Push_TokenArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Push_Token_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Push_Token_Bool_Exp;
};

export type Mutation_RootUpdate_User_Push_Token_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Push_Token_Set_Input>;
  pk_columns: User_Push_Token_Pk_Columns_Input;
};

export type Mutation_RootUpdate_User_Push_Token_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<User_Push_Token_Updates>;
};

export type Mutation_RootUpdate_VariationArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Variation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Variation_Bool_Exp;
};

export type Mutation_RootUpdate_Variation_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Variation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Set_Input>;
  pk_columns: Variation_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Variation_CategoryArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Variation_Category_Bool_Exp;
};

export type Mutation_RootUpdate_Variation_Category_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Category_Set_Input>;
  pk_columns: Variation_Category_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Variation_Category_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Variation_Category_Updates>;
};

export type Mutation_RootUpdate_Variation_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Variation_Updates>;
};

export type Mutation_RootUpdate_Variation_TypeArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Variation_Type_Bool_Exp;
};

export type Mutation_RootUpdate_Variation_Type_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Type_Set_Input>;
  pk_columns: Variation_Type_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Variation_Type_CategoryArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Type_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Variation_Type_Category_Bool_Exp;
};

export type Mutation_RootUpdate_Variation_Type_Category_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Variation_Type_Category_Set_Input>;
  pk_columns: Variation_Type_Category_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Variation_Type_Category_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Variation_Type_Category_Updates>;
};

export type Mutation_RootUpdate_Variation_Type_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Variation_Type_Updates>;
};

export type Mutation_RootUpdate_Web_App_FeatureArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Web_App_Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Web_App_Feature_Bool_Exp;
};

export type Mutation_RootUpdate_Web_App_Feature_By_PkArgs = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Web_App_Feature_Set_Input>;
  pk_columns: Web_App_Feature_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Web_App_Feature_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Web_App_Feature_Updates>;
};

export type Mutation_RootUpdate_Websocket_LogArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Websocket_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Websocket_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Websocket_Log_Bool_Exp;
};

export type Mutation_RootUpdate_Websocket_Log_By_PkArgs = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Websocket_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Websocket_Log_Set_Input>;
  pk_columns: Websocket_Log_Pk_Columns_Input;
};

export type Mutation_RootUpdate_Websocket_Log_ManyArgs = {
  /** updates to execute, in order */
  updates: Array<Websocket_Log_Updates>;
};

export type Mutation_RootValidate_Driver_LicenseArgs = {
  licence: ValidateDriverLicenseInput;
};

export type Mutation_RootValidate_Id_ManualArgs = {
  attachmentId: Scalars['uuid'];
  expiryDate: Scalars['String'];
};

export type Mutation_RootValidate_MedicareArgs = {
  medicare: ValidateMedicareInput;
};

export type Mutation_RootValidate_PassportArgs = {
  passport: ValidatePassportInput;
};


/** columns and relationships of "nationality" */
export type Nationality = {
__typename?: 'nationality';

  /** An array relationship */
  contacts: Array<Contact>;
  /** An aggregate relationship */
  contacts_aggregate: Contact_Aggregate;
  value: Scalars['String'];
};
export type NationalityContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type NationalityContacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};


/** aggregated selection of "nationality" */
export type Nationality_Aggregate = {
__typename?: 'nationality_aggregate';

  aggregate?: Maybe<Nationality_Aggregate_Fields>;
  nodes: Array<Nationality>;
};

/** aggregate fields of "nationality" */
export type Nationality_Aggregate_Fields = {
__typename?: 'nationality_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Nationality_Max_Fields>;
  min?: Maybe<Nationality_Min_Fields>;
};
export type Nationality_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nationality_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Nationality_Max_Fields = {
__typename?: 'nationality_max_fields';

  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nationality_Min_Fields = {
__typename?: 'nationality_min_fields';

  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nationality" */
export type Nationality_Mutation_Response = {
__typename?: 'nationality_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nationality>;
};

/** store notifications */
export type Notification = {
__typename?: 'notification';

  channel?: Maybe<Notification_Channel_Enum>;
  created_at: Scalars['timestamptz'];
  dst_id: Scalars['uuid'];
  /** An object relationship */
  dst_organisation: Organisation;
  dst_team_id: Scalars['uuid'];
  dst_type: Notification_Owner_Enum;
  /** An object relationship */
  dst_user?: Maybe<User>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  member?: Maybe<Member>;
  payload: Scalars['jsonb'];
  read_at?: Maybe<Scalars['timestamptz']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  src_id: Scalars['uuid'];
  src_type: Notification_Owner_Enum;
  status: Process_Status_Enum;
  type: Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
};
export type NotificationPayloadArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "notification" */
export type Notification_Aggregate = {
__typename?: 'notification_aggregate';

  aggregate?: Maybe<Notification_Aggregate_Fields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
__typename?: 'notification_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Notification_Max_Fields>;
  min?: Maybe<Notification_Min_Fields>;
};
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "notification_channel" */
export type Notification_Channel = {
__typename?: 'notification_channel';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "notification_channel" */
export type Notification_Channel_Aggregate = {
__typename?: 'notification_channel_aggregate';

  aggregate?: Maybe<Notification_Channel_Aggregate_Fields>;
  nodes: Array<Notification_Channel>;
};

/** aggregate fields of "notification_channel" */
export type Notification_Channel_Aggregate_Fields = {
__typename?: 'notification_channel_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Notification_Channel_Max_Fields>;
  min?: Maybe<Notification_Channel_Min_Fields>;
};
export type Notification_Channel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Channel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Notification_Channel_Max_Fields = {
__typename?: 'notification_channel_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Channel_Min_Fields = {
__typename?: 'notification_channel_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_channel" */
export type Notification_Channel_Mutation_Response = {
__typename?: 'notification_channel_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Channel>;
};

/** aggregate max on columns */
export type Notification_Max_Fields = {
__typename?: 'notification_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  dst_id?: Maybe<Scalars['uuid']>;
  dst_team_id?: Maybe<Scalars['uuid']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  src_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Notification_Min_Fields = {
__typename?: 'notification_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  dst_id?: Maybe<Scalars['uuid']>;
  dst_team_id?: Maybe<Scalars['uuid']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  scheduled_at?: Maybe<Scalars['timestamptz']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  src_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
__typename?: 'notification_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** columns and relationships of "notification_owner" */
export type Notification_Owner = {
__typename?: 'notification_owner';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "notification_owner" */
export type Notification_Owner_Aggregate = {
__typename?: 'notification_owner_aggregate';

  aggregate?: Maybe<Notification_Owner_Aggregate_Fields>;
  nodes: Array<Notification_Owner>;
};

/** aggregate fields of "notification_owner" */
export type Notification_Owner_Aggregate_Fields = {
__typename?: 'notification_owner_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Notification_Owner_Max_Fields>;
  min?: Maybe<Notification_Owner_Min_Fields>;
};
export type Notification_Owner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Notification_Owner_Max_Fields = {
__typename?: 'notification_owner_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Owner_Min_Fields = {
__typename?: 'notification_owner_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_owner" */
export type Notification_Owner_Mutation_Response = {
__typename?: 'notification_owner_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Owner>;
};

/** store notification type */
export type Notification_Type = {
__typename?: 'notification_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "notification_type" */
export type Notification_Type_Aggregate = {
__typename?: 'notification_type_aggregate';

  aggregate?: Maybe<Notification_Type_Aggregate_Fields>;
  nodes: Array<Notification_Type>;
};

/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_Fields = {
__typename?: 'notification_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Notification_Type_Max_Fields>;
  min?: Maybe<Notification_Type_Min_Fields>;
};
export type Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Notification_Type_Max_Fields = {
__typename?: 'notification_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Type_Min_Fields = {
__typename?: 'notification_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_type" */
export type Notification_Type_Mutation_Response = {
__typename?: 'notification_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Type>;
};

/** columns and relationships of "org_feature_enabled" */
export type Org_Feature_Enabled = {
__typename?: 'org_feature_enabled';

  feature?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "org_feature_enabled" */
export type Org_Feature_Enabled_Aggregate = {
__typename?: 'org_feature_enabled_aggregate';

  aggregate?: Maybe<Org_Feature_Enabled_Aggregate_Fields>;
  nodes: Array<Org_Feature_Enabled>;
};

/** aggregate fields of "org_feature_enabled" */
export type Org_Feature_Enabled_Aggregate_Fields = {
__typename?: 'org_feature_enabled_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Org_Feature_Enabled_Max_Fields>;
  min?: Maybe<Org_Feature_Enabled_Min_Fields>;
};
export type Org_Feature_Enabled_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Org_Feature_Enabled_Max_Fields = {
__typename?: 'org_feature_enabled_max_fields';

  feature?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Org_Feature_Enabled_Min_Fields = {
__typename?: 'org_feature_enabled_min_fields';

  feature?: Maybe<Scalars['String']>;
  org_id?: Maybe<Scalars['uuid']>;
};

/** Feature pack available to given org */
export type Org_Feature_Pack = {
__typename?: 'org_feature_pack';

  created_at: Scalars['timestamptz'];
  /** An object relationship */
  feature_pack?: Maybe<Feature_Pack>;
  feature_pack_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_enabled: Scalars['Boolean'];
  org_id: Scalars['uuid'];
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "org_feature_pack" */
export type Org_Feature_Pack_Aggregate = {
__typename?: 'org_feature_pack_aggregate';

  aggregate?: Maybe<Org_Feature_Pack_Aggregate_Fields>;
  nodes: Array<Org_Feature_Pack>;
};

/** aggregate fields of "org_feature_pack" */
export type Org_Feature_Pack_Aggregate_Fields = {
__typename?: 'org_feature_pack_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Org_Feature_Pack_Max_Fields>;
  min?: Maybe<Org_Feature_Pack_Min_Fields>;
};
export type Org_Feature_Pack_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Org_Feature_Pack_Max_Fields = {
__typename?: 'org_feature_pack_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature_pack_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Org_Feature_Pack_Min_Fields = {
__typename?: 'org_feature_pack_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature_pack_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "org_feature_pack" */
export type Org_Feature_Pack_Mutation_Response = {
__typename?: 'org_feature_pack_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Org_Feature_Pack>;
};

/** columns and relationships of "organisation" */
export type Organisation = {
__typename?: 'organisation';

  abn?: Maybe<Scalars['String']>;
  /** An array relationship */
  acl_features: Array<Acl_Org_Feature_Enabled>;
  /** An aggregate relationship */
  acl_features_aggregate: Acl_Org_Feature_Enabled_Aggregate;
  /** An array relationship */
  acl_org_agent_member: Array<Acl_Org_Agent_Member>;
  /** An aggregate relationship */
  acl_org_agent_member_aggregate: Acl_Org_Agent_Member_Aggregate;
  /** An array relationship */
  acl_org_developer_member: Array<Acl_Org_Developer_Member>;
  /** An aggregate relationship */
  acl_org_developer_member_aggregate: Acl_Org_Developer_Member_Aggregate;
  /** An array relationship */
  acl_org_master_agent: Array<Acl_Org_Master_Agent>;
  /** An aggregate relationship */
  acl_org_master_agent_aggregate: Acl_Org_Master_Agent_Aggregate;
  acn?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address_city_suburb?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  /** An array relationship */
  agency_agreements: Array<Agency_Agreement>;
  /** An aggregate relationship */
  agency_agreements_aggregate: Agency_Agreement_Aggregate;
  /** An array relationship */
  agreements: Array<Agreement>;
  /** An aggregate relationship */
  agreements_aggregate: Agreement_Aggregate;
  /** An array relationship */
  allocation_counts: Array<Organisation_Allocation_Counts_View>;
  /** An aggregate relationship */
  allocation_counts_aggregate: Organisation_Allocation_Counts_View_Aggregate;
  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  /** An array relationship */
  contacts: Array<Contact>;
  /** An aggregate relationship */
  contacts_aggregate: Contact_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  created_contacts: Array<Contact>;
  /** An aggregate relationship */
  created_contacts_aggregate: Contact_Aggregate;
  /** An array relationship */
  dealsByAgencyId: Array<Deal>;
  /** An aggregate relationship */
  dealsByAgencyId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsByPurchaserSolicitorId: Array<Deal>;
  /** An aggregate relationship */
  dealsByPurchaserSolicitorId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsBySubAgencyId: Array<Deal>;
  /** An aggregate relationship */
  dealsBySubAgencyId_aggregate: Deal_Aggregate;
  /** An array relationship */
  dealsByVendorSolicitorId: Array<Deal>;
  /** An aggregate relationship */
  dealsByVendorSolicitorId_aggregate: Deal_Aggregate;
  /** An array relationship */
  delegated_projects: Array<Project>;
  /** An aggregate relationship */
  delegated_projects_aggregate: Project_Aggregate;
  /** An array relationship */
  developer_agents_by_agent: Array<Developer_Agent>;
  /** An aggregate relationship */
  developer_agents_by_agent_aggregate: Developer_Agent_Aggregate;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  enabled_features: Array<Org_Feature_Enabled>;
  /** An aggregate relationship */
  enabled_features_aggregate: Org_Feature_Enabled_Aggregate;
  favicon_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  feature_packs: Array<Org_Feature_Pack>;
  /** An aggregate relationship */
  feature_packs_aggregate: Org_Feature_Pack_Aggregate;
  /** An array relationship */
  features: Array<Organisation_Feature>;
  /** An aggregate relationship */
  features_aggregate: Organisation_Feature_Aggregate;
  /** Computes full address based on other address_* fields */
  full_address?: Maybe<Scalars['String']>;
  /** An array relationship */
  hubspot: Array<Hubspot_Organisation>;
  /** An aggregate relationship */
  hubspot_aggregate: Hubspot_Organisation_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  invitations: Array<Invitation>;
  /** An aggregate relationship */
  invitations_aggregate: Invitation_Aggregate;
  is_setup: Scalars['Boolean'];
  is_user_team: Scalars['Boolean'];
  licence_number?: Maybe<Scalars['String']>;
  licence_state_of_issue?: Maybe<Scalars['String']>;
  /** An object relationship */
  licensee_contact?: Maybe<Contact>;
  licensee_contact_id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  main_contact?: Maybe<Contact>;
  main_contact_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregate relationship */
  members_aggregate: Member_Aggregate;
  name: Scalars['String'];
  nsw_api_verify?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  organisation_developer_agents: Array<Developer_Agent>;
  /** An aggregate relationship */
  organisation_developer_agents_aggregate: Developer_Agent_Aggregate;
  /** An object relationship */
  organisation_favicon?: Maybe<Attachment>;
  /** An object relationship */
  organisation_logo?: Maybe<Attachment>;
  /** An object relationship */
  organisation_type: Organisation_Type;
  phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  primary_contact_sf?: Maybe<Sf_Contact>;
  primary_contact_sf_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  projects: Array<Project>;
  /** An array relationship */
  projectsByVendorSolicitorId: Array<Project>;
  /** An aggregate relationship */
  projectsByVendorSolicitorId_aggregate: Project_Aggregate;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  /** An array relationship */
  propertyGroupsByVendorSolicitor: Array<Property_Group>;
  /** An aggregate relationship */
  propertyGroupsByVendorSolicitor_aggregate: Property_Group_Aggregate;
  /** An array relationship */
  property_allocations: Array<Property_Allocation>;
  /** An aggregate relationship */
  property_allocations_aggregate: Property_Allocation_Aggregate;
  /** An array relationship */
  property_groups: Array<Property_Group>;
  /** An aggregate relationship */
  property_groups_aggregate: Property_Group_Aggregate;
  registered_for_gst?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  salesforce: Array<Sf_Organisation>;
  /** An aggregate relationship */
  salesforce_aggregate: Sf_Organisation_Aggregate;
  type: Organisation_Type_Enum;
  updated_at: Scalars['timestamptz'];
  website?: Maybe<Scalars['String']>;
};
export type OrganisationAcl_FeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type OrganisationAcl_Features_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type OrganisationAcl_Org_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type OrganisationAcl_Org_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type OrganisationAcl_Org_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type OrganisationAcl_Org_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type OrganisationAcl_Org_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type OrganisationAcl_Org_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type OrganisationAgency_AgreementsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agency_Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type OrganisationAgency_Agreements_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agency_Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type OrganisationAgreementsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type OrganisationAgreements_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type OrganisationAllocation_CountsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Allocation_Counts_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type OrganisationAllocation_Counts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Allocation_Counts_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type OrganisationAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type OrganisationAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type OrganisationContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type OrganisationContacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type OrganisationCreated_ContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type OrganisationCreated_Contacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type OrganisationDealsByAgencyIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsByAgencyId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsByPurchaserSolicitorIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsByPurchaserSolicitorId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsBySubAgencyIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsBySubAgencyId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsByVendorSolicitorIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDealsByVendorSolicitorId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type OrganisationDelegated_ProjectsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type OrganisationDelegated_Projects_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type OrganisationDeveloper_Agents_By_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type OrganisationDeveloper_Agents_By_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type OrganisationEnabled_FeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type OrganisationEnabled_Features_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type OrganisationFeature_PacksArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type OrganisationFeature_Packs_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type OrganisationFeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type OrganisationFeatures_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type OrganisationHubspotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type OrganisationHubspot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type OrganisationInvitationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type OrganisationInvitations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type OrganisationMembersArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type OrganisationMembers_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type OrganisationNsw_Api_VerifyArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type OrganisationOrganisation_Developer_AgentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type OrganisationOrganisation_Developer_Agents_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type OrganisationProjectsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type OrganisationProjectsByVendorSolicitorIdArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type OrganisationProjectsByVendorSolicitorId_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type OrganisationProjects_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type OrganisationPropertyGroupsByVendorSolicitorArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type OrganisationPropertyGroupsByVendorSolicitor_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type OrganisationProperty_AllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type OrganisationProperty_Allocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type OrganisationProperty_GroupsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type OrganisationProperty_Groups_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type OrganisationSalesforceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

export type OrganisationSalesforce_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};


/** aggregated selection of "organisation" */
export type Organisation_Aggregate = {
__typename?: 'organisation_aggregate';

  aggregate?: Maybe<Organisation_Aggregate_Fields>;
  nodes: Array<Organisation>;
};

/** aggregate fields of "organisation" */
export type Organisation_Aggregate_Fields = {
__typename?: 'organisation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Organisation_Max_Fields>;
  min?: Maybe<Organisation_Min_Fields>;
};
export type Organisation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View = {
__typename?: 'organisation_allocation_counts_view';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  shared_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Aggregate = {
__typename?: 'organisation_allocation_counts_view_aggregate';

  aggregate?: Maybe<Organisation_Allocation_Counts_View_Aggregate_Fields>;
  nodes: Array<Organisation_Allocation_Counts_View>;
};

/** aggregate fields of "organisation_allocation_counts_view" */
export type Organisation_Allocation_Counts_View_Aggregate_Fields = {
__typename?: 'organisation_allocation_counts_view_aggregate_fields';

  avg?: Maybe<Organisation_Allocation_Counts_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organisation_Allocation_Counts_View_Max_Fields>;
  min?: Maybe<Organisation_Allocation_Counts_View_Min_Fields>;
  stddev?: Maybe<Organisation_Allocation_Counts_View_Stddev_Fields>;
  stddev_pop?: Maybe<Organisation_Allocation_Counts_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organisation_Allocation_Counts_View_Stddev_Samp_Fields>;
  sum?: Maybe<Organisation_Allocation_Counts_View_Sum_Fields>;
  var_pop?: Maybe<Organisation_Allocation_Counts_View_Var_Pop_Fields>;
  var_samp?: Maybe<Organisation_Allocation_Counts_View_Var_Samp_Fields>;
  variance?: Maybe<Organisation_Allocation_Counts_View_Variance_Fields>;
};
export type Organisation_Allocation_Counts_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Organisation_Allocation_Counts_View_Avg_Fields = {
__typename?: 'organisation_allocation_counts_view_avg_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Organisation_Allocation_Counts_View_Max_Fields = {
__typename?: 'organisation_allocation_counts_view_max_fields';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  shared_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Organisation_Allocation_Counts_View_Min_Fields = {
__typename?: 'organisation_allocation_counts_view_min_fields';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  shared_count?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Organisation_Allocation_Counts_View_Stddev_Fields = {
__typename?: 'organisation_allocation_counts_view_stddev_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organisation_Allocation_Counts_View_Stddev_Pop_Fields = {
__typename?: 'organisation_allocation_counts_view_stddev_pop_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organisation_Allocation_Counts_View_Stddev_Samp_Fields = {
__typename?: 'organisation_allocation_counts_view_stddev_samp_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Organisation_Allocation_Counts_View_Sum_Fields = {
__typename?: 'organisation_allocation_counts_view_sum_fields';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  shared_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Organisation_Allocation_Counts_View_Var_Pop_Fields = {
__typename?: 'organisation_allocation_counts_view_var_pop_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organisation_Allocation_Counts_View_Var_Samp_Fields = {
__typename?: 'organisation_allocation_counts_view_var_samp_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organisation_Allocation_Counts_View_Variance_Fields = {
__typename?: 'organisation_allocation_counts_view_variance_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** Overrides features defined via org_feature_pack table */
export type Organisation_Feature = {
__typename?: 'organisation_feature';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature: Web_App_Feature_Enum;
  /** An object relationship */
  feature_obj: Web_App_Feature;
  id: Scalars['uuid'];
  is_enabled: Scalars['Boolean'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "organisation_feature" */
export type Organisation_Feature_Aggregate = {
__typename?: 'organisation_feature_aggregate';

  aggregate?: Maybe<Organisation_Feature_Aggregate_Fields>;
  nodes: Array<Organisation_Feature>;
};

/** aggregate fields of "organisation_feature" */
export type Organisation_Feature_Aggregate_Fields = {
__typename?: 'organisation_feature_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Organisation_Feature_Max_Fields>;
  min?: Maybe<Organisation_Feature_Min_Fields>;
};
export type Organisation_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Organisation_Feature_Max_Fields = {
__typename?: 'organisation_feature_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Organisation_Feature_Min_Fields = {
__typename?: 'organisation_feature_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "organisation_feature" */
export type Organisation_Feature_Mutation_Response = {
__typename?: 'organisation_feature_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Feature>;
};

/** aggregate max on columns */
export type Organisation_Max_Fields = {
__typename?: 'organisation_max_fields';

  abn?: Maybe<Scalars['String']>;
  acn?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address_city_suburb?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  favicon_id?: Maybe<Scalars['uuid']>;
  /** Computes full address based on other address_* fields */
  full_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  licence_number?: Maybe<Scalars['String']>;
  licence_state_of_issue?: Maybe<Scalars['String']>;
  licensee_contact_id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  main_contact_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primary_contact_sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organisation_Min_Fields = {
__typename?: 'organisation_min_fields';

  abn?: Maybe<Scalars['String']>;
  acn?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address_city_suburb?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  created_by_team_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  favicon_id?: Maybe<Scalars['uuid']>;
  /** Computes full address based on other address_* fields */
  full_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  licence_number?: Maybe<Scalars['String']>;
  licence_state_of_issue?: Maybe<Scalars['String']>;
  licensee_contact_id?: Maybe<Scalars['uuid']>;
  logo_id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  main_contact_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primary_contact_sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organisation" */
export type Organisation_Mutation_Response = {
__typename?: 'organisation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation>;
};

/** columns and relationships of "organisation_type" */
export type Organisation_Type = {
__typename?: 'organisation_type';

  description: Scalars['String'];
  /** An array relationship */
  organisations: Array<Organisation>;
  /** An aggregate relationship */
  organisations_aggregate: Organisation_Aggregate;
  value: Scalars['String'];
};
export type Organisation_TypeOrganisationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type Organisation_TypeOrganisations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};


/** aggregated selection of "organisation_type" */
export type Organisation_Type_Aggregate = {
__typename?: 'organisation_type_aggregate';

  aggregate?: Maybe<Organisation_Type_Aggregate_Fields>;
  nodes: Array<Organisation_Type>;
};

/** aggregate fields of "organisation_type" */
export type Organisation_Type_Aggregate_Fields = {
__typename?: 'organisation_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Organisation_Type_Max_Fields>;
  min?: Maybe<Organisation_Type_Min_Fields>;
};
export type Organisation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organisation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Organisation_Type_Max_Fields = {
__typename?: 'organisation_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organisation_Type_Min_Fields = {
__typename?: 'organisation_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organisation_type" */
export type Organisation_Type_Mutation_Response = {
__typename?: 'organisation_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation_Type>;
};

/** Data captured online via /partner-portal/{invitationId} */
export type Partner_Portal = {
__typename?: 'partner_portal';

  abn: Scalars['String'];
  /** Filled in via Partner Portal form, if company was found by given ABN, and company has no users. If ABN is new, and new agency is needed this should always be null */
  agency_org_id?: Maybe<Scalars['uuid']>;
  company_address_city_suburb: Scalars['String'];
  company_address_country: Scalars['String'];
  company_address_line_1: Scalars['String'];
  company_address_postcode: Scalars['String'];
  company_address_state: Scalars['String'];
  company_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  invitation: Invitation;
  invitation_id: Scalars['uuid'];
  licence_holder_address_city_suburb?: Maybe<Scalars['String']>;
  licence_holder_address_country?: Maybe<Scalars['String']>;
  licence_holder_address_line_1?: Maybe<Scalars['String']>;
  licence_holder_address_postcode?: Maybe<Scalars['String']>;
  licence_holder_address_state?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. */
  licence_holder_contact_id?: Maybe<Scalars['uuid']>;
  licence_holder_email: Scalars['String'];
  licence_holder_first_name: Scalars['String'];
  licence_holder_last_name: Scalars['String'];
  licence_holder_phone: Scalars['String'];
  licence_number: Scalars['String'];
  main_contact_email?: Maybe<Scalars['String']>;
  main_contact_first_name?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. */
  main_contact_id?: Maybe<Scalars['uuid']>;
  main_contact_last_name?: Maybe<Scalars['String']>;
  main_contact_phone?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. Filled in only if agency_org_id was null before calling partner_portal_invite */
  new_agency_org_id?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "partner_portal" */
export type Partner_Portal_Aggregate = {
__typename?: 'partner_portal_aggregate';

  aggregate?: Maybe<Partner_Portal_Aggregate_Fields>;
  nodes: Array<Partner_Portal>;
};

/** aggregate fields of "partner_portal" */
export type Partner_Portal_Aggregate_Fields = {
__typename?: 'partner_portal_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Partner_Portal_Max_Fields>;
  min?: Maybe<Partner_Portal_Min_Fields>;
};
export type Partner_Portal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Portal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Partner_Portal_Max_Fields = {
__typename?: 'partner_portal_max_fields';

  abn?: Maybe<Scalars['String']>;
  /** Filled in via Partner Portal form, if company was found by given ABN, and company has no users. If ABN is new, and new agency is needed this should always be null */
  agency_org_id?: Maybe<Scalars['uuid']>;
  company_address_city_suburb?: Maybe<Scalars['String']>;
  company_address_country?: Maybe<Scalars['String']>;
  company_address_line_1?: Maybe<Scalars['String']>;
  company_address_postcode?: Maybe<Scalars['String']>;
  company_address_state?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitation_id?: Maybe<Scalars['uuid']>;
  licence_holder_address_city_suburb?: Maybe<Scalars['String']>;
  licence_holder_address_country?: Maybe<Scalars['String']>;
  licence_holder_address_line_1?: Maybe<Scalars['String']>;
  licence_holder_address_postcode?: Maybe<Scalars['String']>;
  licence_holder_address_state?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. */
  licence_holder_contact_id?: Maybe<Scalars['uuid']>;
  licence_holder_email?: Maybe<Scalars['String']>;
  licence_holder_first_name?: Maybe<Scalars['String']>;
  licence_holder_last_name?: Maybe<Scalars['String']>;
  licence_holder_phone?: Maybe<Scalars['String']>;
  licence_number?: Maybe<Scalars['String']>;
  main_contact_email?: Maybe<Scalars['String']>;
  main_contact_first_name?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. */
  main_contact_id?: Maybe<Scalars['uuid']>;
  main_contact_last_name?: Maybe<Scalars['String']>;
  main_contact_phone?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. Filled in only if agency_org_id was null before calling partner_portal_invite */
  new_agency_org_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Partner_Portal_Min_Fields = {
__typename?: 'partner_portal_min_fields';

  abn?: Maybe<Scalars['String']>;
  /** Filled in via Partner Portal form, if company was found by given ABN, and company has no users. If ABN is new, and new agency is needed this should always be null */
  agency_org_id?: Maybe<Scalars['uuid']>;
  company_address_city_suburb?: Maybe<Scalars['String']>;
  company_address_country?: Maybe<Scalars['String']>;
  company_address_line_1?: Maybe<Scalars['String']>;
  company_address_postcode?: Maybe<Scalars['String']>;
  company_address_state?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invitation_id?: Maybe<Scalars['uuid']>;
  licence_holder_address_city_suburb?: Maybe<Scalars['String']>;
  licence_holder_address_country?: Maybe<Scalars['String']>;
  licence_holder_address_line_1?: Maybe<Scalars['String']>;
  licence_holder_address_postcode?: Maybe<Scalars['String']>;
  licence_holder_address_state?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. */
  licence_holder_contact_id?: Maybe<Scalars['uuid']>;
  licence_holder_email?: Maybe<Scalars['String']>;
  licence_holder_first_name?: Maybe<Scalars['String']>;
  licence_holder_last_name?: Maybe<Scalars['String']>;
  licence_holder_phone?: Maybe<Scalars['String']>;
  licence_number?: Maybe<Scalars['String']>;
  main_contact_email?: Maybe<Scalars['String']>;
  main_contact_first_name?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. */
  main_contact_id?: Maybe<Scalars['uuid']>;
  main_contact_last_name?: Maybe<Scalars['String']>;
  main_contact_phone?: Maybe<Scalars['String']>;
  /** Filled in by partner_portal_invite Hasura Action upon processing given form data. Filled in only if agency_org_id was null before calling partner_portal_invite */
  new_agency_org_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "partner_portal" */
export type Partner_Portal_Mutation_Response = {
__typename?: 'partner_portal_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Portal>;
};

/** columns and relationships of "payment_method" */
export type Payment_Method = {
__typename?: 'payment_method';

  biller_code: Scalars['String'];
  id: Scalars['uuid'];
  payment_description?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<Project>;
  type: Payment_Method_Type_Enum;
};

/** aggregated selection of "payment_method" */
export type Payment_Method_Aggregate = {
__typename?: 'payment_method_aggregate';

  aggregate?: Maybe<Payment_Method_Aggregate_Fields>;
  nodes: Array<Payment_Method>;
};

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_Fields = {
__typename?: 'payment_method_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Payment_Method_Max_Fields>;
  min?: Maybe<Payment_Method_Min_Fields>;
};
export type Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Payment_Method_Max_Fields = {
__typename?: 'payment_method_max_fields';

  biller_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_description?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Method_Min_Fields = {
__typename?: 'payment_method_min_fields';

  biller_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_method" */
export type Payment_Method_Mutation_Response = {
__typename?: 'payment_method_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method>;
};

/** columns and relationships of "payment_method_type" */
export type Payment_Method_Type = {
__typename?: 'payment_method_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "payment_method_type" */
export type Payment_Method_Type_Aggregate = {
__typename?: 'payment_method_type_aggregate';

  aggregate?: Maybe<Payment_Method_Type_Aggregate_Fields>;
  nodes: Array<Payment_Method_Type>;
};

/** aggregate fields of "payment_method_type" */
export type Payment_Method_Type_Aggregate_Fields = {
__typename?: 'payment_method_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Payment_Method_Type_Max_Fields>;
  min?: Maybe<Payment_Method_Type_Min_Fields>;
};
export type Payment_Method_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Method_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Payment_Method_Type_Max_Fields = {
__typename?: 'payment_method_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Method_Type_Min_Fields = {
__typename?: 'payment_method_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_method_type" */
export type Payment_Method_Type_Mutation_Response = {
__typename?: 'payment_method_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method_Type>;
};

/** columns and relationships of "preference" */
export type Preference = {
__typename?: 'preference';

  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** An array relationship */
  acl_deal_developer_member: Array<Acl_Deal_Developer_Member>;
  /** An aggregate relationship */
  acl_deal_developer_member_aggregate: Acl_Deal_Developer_Member_Aggregate;
  /** An array relationship */
  acl_deal_master_agent: Array<Acl_Deal_Master_Agent>;
  /** An aggregate relationship */
  acl_deal_master_agent_aggregate: Acl_Deal_Master_Agent_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['uuid'];
  id: Scalars['uuid'];
  priority?: Maybe<Scalars['Int']>;
  /** An object relationship */
  property: Property;
  /** An object relationship */
  property_group: Property_Group;
  property_group_id: Scalars['uuid'];
  property_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};
export type PreferenceAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type PreferenceAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type PreferenceAcl_Deal_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type PreferenceAcl_Deal_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type PreferenceAcl_Deal_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type PreferenceAcl_Deal_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};


/** aggregated selection of "preference" */
export type Preference_Aggregate = {
__typename?: 'preference_aggregate';

  aggregate?: Maybe<Preference_Aggregate_Fields>;
  nodes: Array<Preference>;
};

/** aggregate fields of "preference" */
export type Preference_Aggregate_Fields = {
__typename?: 'preference_aggregate_fields';

  avg?: Maybe<Preference_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Preference_Max_Fields>;
  min?: Maybe<Preference_Min_Fields>;
  stddev?: Maybe<Preference_Stddev_Fields>;
  stddev_pop?: Maybe<Preference_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Preference_Stddev_Samp_Fields>;
  sum?: Maybe<Preference_Sum_Fields>;
  var_pop?: Maybe<Preference_Var_Pop_Fields>;
  var_samp?: Maybe<Preference_Var_Samp_Fields>;
  variance?: Maybe<Preference_Variance_Fields>;
};
export type Preference_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Preference_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Preference_Avg_Fields = {
__typename?: 'preference_avg_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Preference_Max_Fields = {
__typename?: 'preference_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Preference_Min_Fields = {
__typename?: 'preference_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  property_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "preference" */
export type Preference_Mutation_Response = {
__typename?: 'preference_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Preference>;
};

/** aggregate stddev on columns */
export type Preference_Stddev_Fields = {
__typename?: 'preference_stddev_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Preference_Stddev_Pop_Fields = {
__typename?: 'preference_stddev_pop_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Preference_Stddev_Samp_Fields = {
__typename?: 'preference_stddev_samp_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Preference_Sum_Fields = {
__typename?: 'preference_sum_fields';

  priority?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Preference_Var_Pop_Fields = {
__typename?: 'preference_var_pop_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Preference_Var_Samp_Fields = {
__typename?: 'preference_var_samp_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Preference_Variance_Fields = {
__typename?: 'preference_variance_fields';

  priority?: Maybe<Scalars['Float']>;
};

/** Table that contains information about running processes */
export type Process = {
__typename?: 'process';

  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['String'];
  is_running: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  updated_at_unix: Scalars['bigint'];
};

/** aggregated selection of "process" */
export type Process_Aggregate = {
__typename?: 'process_aggregate';

  aggregate?: Maybe<Process_Aggregate_Fields>;
  nodes: Array<Process>;
};

/** aggregate fields of "process" */
export type Process_Aggregate_Fields = {
__typename?: 'process_aggregate_fields';

  avg?: Maybe<Process_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Process_Max_Fields>;
  min?: Maybe<Process_Min_Fields>;
  stddev?: Maybe<Process_Stddev_Fields>;
  stddev_pop?: Maybe<Process_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Process_Stddev_Samp_Fields>;
  sum?: Maybe<Process_Sum_Fields>;
  var_pop?: Maybe<Process_Var_Pop_Fields>;
  var_samp?: Maybe<Process_Var_Samp_Fields>;
  variance?: Maybe<Process_Variance_Fields>;
};
export type Process_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Process_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Process_Avg_Fields = {
__typename?: 'process_avg_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Process_Max_Fields = {
__typename?: 'process_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_at_unix?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Process_Min_Fields = {
__typename?: 'process_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  updated_at_unix?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "process" */
export type Process_Mutation_Response = {
__typename?: 'process_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Process>;
};

/** columns and relationships of "process_status" */
export type Process_Status = {
__typename?: 'process_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "process_status" */
export type Process_Status_Aggregate = {
__typename?: 'process_status_aggregate';

  aggregate?: Maybe<Process_Status_Aggregate_Fields>;
  nodes: Array<Process_Status>;
};

/** aggregate fields of "process_status" */
export type Process_Status_Aggregate_Fields = {
__typename?: 'process_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Process_Status_Max_Fields>;
  min?: Maybe<Process_Status_Min_Fields>;
};
export type Process_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Process_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Process_Status_Max_Fields = {
__typename?: 'process_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Process_Status_Min_Fields = {
__typename?: 'process_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "process_status" */
export type Process_Status_Mutation_Response = {
__typename?: 'process_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Process_Status>;
};

/** aggregate stddev on columns */
export type Process_Stddev_Fields = {
__typename?: 'process_stddev_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Process_Stddev_Pop_Fields = {
__typename?: 'process_stddev_pop_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Process_Stddev_Samp_Fields = {
__typename?: 'process_stddev_samp_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Process_Sum_Fields = {
__typename?: 'process_sum_fields';

  updated_at_unix?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Process_Var_Pop_Fields = {
__typename?: 'process_var_pop_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Process_Var_Samp_Fields = {
__typename?: 'process_var_samp_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Process_Variance_Fields = {
__typename?: 'process_variance_fields';

  updated_at_unix?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "project" */
export type Project = {
__typename?: 'project';

  /** An array relationship */
  acl_allocation_edit: Array<Acl_Allocation_Edit>;
  /** An aggregate relationship */
  acl_allocation_edit_aggregate: Acl_Allocation_Edit_Aggregate;
  /** An array relationship */
  acl_listing_property: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_aggregate: Acl_Listing_Property_Aggregate;
  /** An array relationship */
  acl_listing_property_team: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_aggregate: Acl_Listing_Property_Team_Aggregate;
  active_project?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  /** An array relationship */
  area_plots: Array<Area_Plot>;
  /** An aggregate relationship */
  area_plots_aggregate: Area_Plot_Aggregate;
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  /** An object relationship */
  background_attachment?: Maybe<Attachment>;
  background_attachment_id?: Maybe<Scalars['uuid']>;
  brand_bg_color?: Maybe<Scalars['String']>;
  brand_fg_color?: Maybe<Scalars['String']>;
  /** An array relationship */
  buyer_projects: Array<Buyer_Project>;
  /** An aggregate relationship */
  buyer_projects_aggregate: Buyer_Project_Aggregate;
  can_publish: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  crm_connection?: Maybe<Crm_Connection>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  /** An object relationship */
  default_head_agency?: Maybe<Organisation>;
  default_head_agency_id?: Maybe<Scalars['uuid']>;
  delegated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  delegated_by_team?: Maybe<Organisation>;
  description?: Maybe<Scalars['String']>;
  domain_agency_id?: Maybe<Scalars['Int']>;
  domain_project_id?: Maybe<Scalars['Int']>;
  domain_verification_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  embeds: Array<Embed>;
  /** An aggregate relationship */
  embeds_aggregate: Embed_Aggregate;
  eoi_enabled: Scalars['Boolean'];
  fake?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  features: Array<Project_Features>;
  /** An aggregate relationship */
  features_aggregate: Project_Features_Aggregate;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['numeric']>;
  holding_enabled?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  hubspot: Array<Hubspot_Project>;
  /** An aggregate relationship */
  hubspot_aggregate: Hubspot_Project_Aggregate;
  id: Scalars['uuid'];
  /** if true, Users will pay a variable amount (% of listing purchase price) as the holding deposit to reserve a listing. */
  is_use_variable_holding_deposit: Scalars['Boolean'];
  /** An array relationship */
  listings: Array<Listing>;
  /** An aggregate relationship */
  listings_aggregate: Listing_Aggregate;
  /** An object relationship */
  logo_attachment?: Maybe<Attachment>;
  logo_attachment_id?: Maybe<Scalars['uuid']>;
  /** Project's coordinates on the map following google maps convention */
  map_lat_lng?: Maybe<Scalars['point']>;
  /** An object relationship */
  master_plan_attachment?: Maybe<Attachment>;
  master_plan_attachment_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  member_project_allocates: Array<Member_Project_Allocate>;
  /** An aggregate relationship */
  member_project_allocates_aggregate: Member_Project_Allocate_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  organisation: Organisation;
  /** An object relationship */
  organisationByVendorSolicitorId?: Maybe<Organisation>;
  organisation_id: Scalars['uuid'];
  payment_info?: Maybe<Scalars['String']>;
  /** An object relationship */
  payment_method?: Maybe<Payment_Method>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  payment_methods: Array<Project_Payment_Method>;
  /** An aggregate relationship */
  payment_methods_aggregate: Project_Payment_Method_Aggregate;
  postcode?: Maybe<Scalars['String']>;
  /** An object relationship */
  project_detail?: Maybe<Project_Detail>;
  /** An object relationship */
  project_director_contact?: Maybe<Contact>;
  project_director_contact_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project_type: Project_Type;
  /** An object relationship */
  project_units?: Maybe<Project_Units>;
  /** An array relationship */
  properties: Array<Property>;
  /** An aggregate relationship */
  properties_aggregate: Property_Aggregate;
  /** An array relationship */
  property_groups: Array<Property_Group>;
  /** An aggregate relationship */
  property_groups_aggregate: Property_Group_Aggregate;
  /** An object relationship */
  reservation_setting?: Maybe<Project_Reservation_Setting>;
  sales_advice_vendor?: Maybe<Scalars['String']>;
  setting_holding_hours: Scalars['Int'];
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status: Project_Status_Enum;
  sub_description?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  type: Project_Type_Enum;
  updated_at: Scalars['timestamptz'];
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage: Scalars['numeric'];
  /** An object relationship */
  vendor_solicitor_contact?: Maybe<Contact>;
  vendor_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
};
export type ProjectAcl_Allocation_EditArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type ProjectAcl_Allocation_Edit_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type ProjectAcl_Listing_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type ProjectAcl_Listing_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type ProjectAcl_Listing_Property_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type ProjectAcl_Listing_Property_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type ProjectAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type ProjectAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type ProjectArea_PlotsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type ProjectArea_Plots_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type ProjectAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type ProjectAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type ProjectBuyer_ProjectsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type ProjectBuyer_Projects_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type ProjectDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ProjectDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type ProjectEmbedsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Embed_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Embed_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type ProjectEmbeds_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Embed_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Embed_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type ProjectFeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Features_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Features_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type ProjectFeatures_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Features_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Features_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type ProjectHubspotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type ProjectHubspot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type ProjectListingsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type ProjectListings_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type ProjectMember_Project_AllocatesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type ProjectMember_Project_Allocates_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type ProjectPayment_MethodsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type ProjectPayment_Methods_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type ProjectPropertiesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type ProjectProperties_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type ProjectProperty_GroupsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type ProjectProperty_Groups_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};


/** aggregated selection of "project" */
export type Project_Aggregate = {
__typename?: 'project_aggregate';

  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
__typename?: 'project_aggregate_fields';

  avg?: Maybe<Project_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
  stddev?: Maybe<Project_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Sum_Fields>;
  var_pop?: Maybe<Project_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Var_Samp_Fields>;
  variance?: Maybe<Project_Variance_Fields>;
};
export type Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "project_apartment_level" */
export type Project_Apartment_Level = {
__typename?: 'project_apartment_level';

  apartment_count?: Maybe<Scalars['bigint']>;
  attachment_id?: Maybe<Scalars['uuid']>;
  building_id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  level_plan_url?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "project_apartment_level" */
export type Project_Apartment_Level_Aggregate = {
__typename?: 'project_apartment_level_aggregate';

  aggregate?: Maybe<Project_Apartment_Level_Aggregate_Fields>;
  nodes: Array<Project_Apartment_Level>;
};

/** aggregate fields of "project_apartment_level" */
export type Project_Apartment_Level_Aggregate_Fields = {
__typename?: 'project_apartment_level_aggregate_fields';

  avg?: Maybe<Project_Apartment_Level_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Apartment_Level_Max_Fields>;
  min?: Maybe<Project_Apartment_Level_Min_Fields>;
  stddev?: Maybe<Project_Apartment_Level_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Apartment_Level_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Apartment_Level_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Apartment_Level_Sum_Fields>;
  var_pop?: Maybe<Project_Apartment_Level_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Apartment_Level_Var_Samp_Fields>;
  variance?: Maybe<Project_Apartment_Level_Variance_Fields>;
};
export type Project_Apartment_Level_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Project_Apartment_Level_Avg_Fields = {
__typename?: 'project_apartment_level_avg_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Project_Apartment_Level_Max_Fields = {
__typename?: 'project_apartment_level_max_fields';

  apartment_count?: Maybe<Scalars['bigint']>;
  attachment_id?: Maybe<Scalars['uuid']>;
  building_id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  level_plan_url?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Apartment_Level_Min_Fields = {
__typename?: 'project_apartment_level_min_fields';

  apartment_count?: Maybe<Scalars['bigint']>;
  attachment_id?: Maybe<Scalars['uuid']>;
  building_id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  level_plan_url?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Apartment_Level_Stddev_Fields = {
__typename?: 'project_apartment_level_stddev_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Apartment_Level_Stddev_Pop_Fields = {
__typename?: 'project_apartment_level_stddev_pop_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Apartment_Level_Stddev_Samp_Fields = {
__typename?: 'project_apartment_level_stddev_samp_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Project_Apartment_Level_Sum_Fields = {
__typename?: 'project_apartment_level_sum_fields';

  apartment_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Project_Apartment_Level_Var_Pop_Fields = {
__typename?: 'project_apartment_level_var_pop_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Apartment_Level_Var_Samp_Fields = {
__typename?: 'project_apartment_level_var_samp_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Apartment_Level_Variance_Fields = {
__typename?: 'project_apartment_level_variance_fields';

  apartment_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "project_apartment_stat" */
export type Project_Apartment_Stat = {
__typename?: 'project_apartment_stat';

  building_id?: Maybe<Scalars['uuid']>;
  building_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  total_lots?: Maybe<Scalars['bigint']>;
  total_price?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "project_apartment_stat" */
export type Project_Apartment_Stat_Aggregate = {
__typename?: 'project_apartment_stat_aggregate';

  aggregate?: Maybe<Project_Apartment_Stat_Aggregate_Fields>;
  nodes: Array<Project_Apartment_Stat>;
};

/** aggregate fields of "project_apartment_stat" */
export type Project_Apartment_Stat_Aggregate_Fields = {
__typename?: 'project_apartment_stat_aggregate_fields';

  avg?: Maybe<Project_Apartment_Stat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Apartment_Stat_Max_Fields>;
  min?: Maybe<Project_Apartment_Stat_Min_Fields>;
  stddev?: Maybe<Project_Apartment_Stat_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Apartment_Stat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Apartment_Stat_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Apartment_Stat_Sum_Fields>;
  var_pop?: Maybe<Project_Apartment_Stat_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Apartment_Stat_Var_Samp_Fields>;
  variance?: Maybe<Project_Apartment_Stat_Variance_Fields>;
};
export type Project_Apartment_Stat_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Apartment_Stat_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Project_Apartment_Stat_Avg_Fields = {
__typename?: 'project_apartment_stat_avg_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Project_Apartment_Stat_Max_Fields = {
__typename?: 'project_apartment_stat_max_fields';

  building_id?: Maybe<Scalars['uuid']>;
  building_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  total_lots?: Maybe<Scalars['bigint']>;
  total_price?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Project_Apartment_Stat_Min_Fields = {
__typename?: 'project_apartment_stat_min_fields';

  building_id?: Maybe<Scalars['uuid']>;
  building_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  total_lots?: Maybe<Scalars['bigint']>;
  total_price?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Project_Apartment_Stat_Stddev_Fields = {
__typename?: 'project_apartment_stat_stddev_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Apartment_Stat_Stddev_Pop_Fields = {
__typename?: 'project_apartment_stat_stddev_pop_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Apartment_Stat_Stddev_Samp_Fields = {
__typename?: 'project_apartment_stat_stddev_samp_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Project_Apartment_Stat_Sum_Fields = {
__typename?: 'project_apartment_stat_sum_fields';

  total_lots?: Maybe<Scalars['bigint']>;
  total_price?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Project_Apartment_Stat_Var_Pop_Fields = {
__typename?: 'project_apartment_stat_var_pop_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Apartment_Stat_Var_Samp_Fields = {
__typename?: 'project_apartment_stat_var_samp_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Apartment_Stat_Variance_Fields = {
__typename?: 'project_apartment_stat_variance_fields';

  total_lots?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Project_Avg_Fields = {
__typename?: 'project_avg_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "project_client_count" */
export type Project_Client_Count = {
__typename?: 'project_client_count';

  client_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_client_count" */
export type Project_Client_Count_Aggregate = {
__typename?: 'project_client_count_aggregate';

  aggregate?: Maybe<Project_Client_Count_Aggregate_Fields>;
  nodes: Array<Project_Client_Count>;
};

/** aggregate fields of "project_client_count" */
export type Project_Client_Count_Aggregate_Fields = {
__typename?: 'project_client_count_aggregate_fields';

  avg?: Maybe<Project_Client_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Client_Count_Max_Fields>;
  min?: Maybe<Project_Client_Count_Min_Fields>;
  stddev?: Maybe<Project_Client_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Client_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Client_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Client_Count_Sum_Fields>;
  var_pop?: Maybe<Project_Client_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Client_Count_Var_Samp_Fields>;
  variance?: Maybe<Project_Client_Count_Variance_Fields>;
};
export type Project_Client_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Project_Client_Count_Avg_Fields = {
__typename?: 'project_client_count_avg_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Project_Client_Count_Max_Fields = {
__typename?: 'project_client_count_max_fields';

  client_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Client_Count_Min_Fields = {
__typename?: 'project_client_count_min_fields';

  client_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Client_Count_Stddev_Fields = {
__typename?: 'project_client_count_stddev_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Client_Count_Stddev_Pop_Fields = {
__typename?: 'project_client_count_stddev_pop_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Client_Count_Stddev_Samp_Fields = {
__typename?: 'project_client_count_stddev_samp_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Project_Client_Count_Sum_Fields = {
__typename?: 'project_client_count_sum_fields';

  client_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Project_Client_Count_Var_Pop_Fields = {
__typename?: 'project_client_count_var_pop_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Client_Count_Var_Samp_Fields = {
__typename?: 'project_client_count_var_samp_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Client_Count_Variance_Fields = {
__typename?: 'project_client_count_variance_fields';

  client_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "project_detail" */
export type Project_Detail = {
__typename?: 'project_detail';

  created_at: Scalars['timestamptz'];
  development_address?: Maybe<Scalars['String']>;
  development_address_postal_code?: Maybe<Scalars['String']>;
  development_address_state?: Maybe<Scalars['String']>;
  development_address_suburb?: Maybe<Scalars['String']>;
  development_country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  suite_address?: Maybe<Scalars['String']>;
  suite_address_postal_code?: Maybe<Scalars['String']>;
  suite_address_state?: Maybe<Scalars['String']>;
  suite_address_suburb?: Maybe<Scalars['String']>;
  suite_country?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  website_address?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_detail" */
export type Project_Detail_Aggregate = {
__typename?: 'project_detail_aggregate';

  aggregate?: Maybe<Project_Detail_Aggregate_Fields>;
  nodes: Array<Project_Detail>;
};

/** aggregate fields of "project_detail" */
export type Project_Detail_Aggregate_Fields = {
__typename?: 'project_detail_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Detail_Max_Fields>;
  min?: Maybe<Project_Detail_Min_Fields>;
};
export type Project_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Detail_Max_Fields = {
__typename?: 'project_detail_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  development_address?: Maybe<Scalars['String']>;
  development_address_postal_code?: Maybe<Scalars['String']>;
  development_address_state?: Maybe<Scalars['String']>;
  development_address_suburb?: Maybe<Scalars['String']>;
  development_country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  suite_address?: Maybe<Scalars['String']>;
  suite_address_postal_code?: Maybe<Scalars['String']>;
  suite_address_state?: Maybe<Scalars['String']>;
  suite_address_suburb?: Maybe<Scalars['String']>;
  suite_country?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website_address?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Detail_Min_Fields = {
__typename?: 'project_detail_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  development_address?: Maybe<Scalars['String']>;
  development_address_postal_code?: Maybe<Scalars['String']>;
  development_address_state?: Maybe<Scalars['String']>;
  development_address_suburb?: Maybe<Scalars['String']>;
  development_country?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  suite_address?: Maybe<Scalars['String']>;
  suite_address_postal_code?: Maybe<Scalars['String']>;
  suite_address_state?: Maybe<Scalars['String']>;
  suite_address_suburb?: Maybe<Scalars['String']>;
  suite_country?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website_address?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_detail" */
export type Project_Detail_Mutation_Response = {
__typename?: 'project_detail_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Detail>;
};

/** project features */
export type Project_Features = {
__typename?: 'project_features';

  created_at: Scalars['timestamptz'];
  feature: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "project_features" */
export type Project_Features_Aggregate = {
__typename?: 'project_features_aggregate';

  aggregate?: Maybe<Project_Features_Aggregate_Fields>;
  nodes: Array<Project_Features>;
};

/** aggregate fields of "project_features" */
export type Project_Features_Aggregate_Fields = {
__typename?: 'project_features_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Features_Max_Fields>;
  min?: Maybe<Project_Features_Min_Fields>;
};
export type Project_Features_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Features_Max_Fields = {
__typename?: 'project_features_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Features_Min_Fields = {
__typename?: 'project_features_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  feature?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_features" */
export type Project_Features_Mutation_Response = {
__typename?: 'project_features_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Features>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
__typename?: 'project_max_fields';

  background_attachment_id?: Maybe<Scalars['uuid']>;
  brand_bg_color?: Maybe<Scalars['String']>;
  brand_fg_color?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  default_head_agency_id?: Maybe<Scalars['uuid']>;
  delegated_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  domain_agency_id?: Maybe<Scalars['Int']>;
  domain_project_id?: Maybe<Scalars['Int']>;
  domain_verification_code?: Maybe<Scalars['String']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  logo_attachment_id?: Maybe<Scalars['uuid']>;
  master_plan_attachment_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  payment_info?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  project_director_contact_id?: Maybe<Scalars['uuid']>;
  sales_advice_vendor?: Maybe<Scalars['String']>;
  setting_holding_hours?: Maybe<Scalars['Int']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  sub_description?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['numeric']>;
  vendor_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
__typename?: 'project_min_fields';

  background_attachment_id?: Maybe<Scalars['uuid']>;
  brand_bg_color?: Maybe<Scalars['String']>;
  brand_fg_color?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  default_head_agency_id?: Maybe<Scalars['uuid']>;
  delegated_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  domain_agency_id?: Maybe<Scalars['Int']>;
  domain_project_id?: Maybe<Scalars['Int']>;
  domain_verification_code?: Maybe<Scalars['String']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  logo_attachment_id?: Maybe<Scalars['uuid']>;
  master_plan_attachment_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  payment_info?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['uuid']>;
  postcode?: Maybe<Scalars['String']>;
  project_director_contact_id?: Maybe<Scalars['uuid']>;
  sales_advice_vendor?: Maybe<Scalars['String']>;
  setting_holding_hours?: Maybe<Scalars['Int']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  sub_description?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['numeric']>;
  vendor_solicitor_contact_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
__typename?: 'project_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** project payment method settings */
export type Project_Payment_Method = {
__typename?: 'project_payment_method';

  config?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  is_enabled: Scalars['Boolean'];
  method: Payment_Method_Type_Enum;
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
};
export type Project_Payment_MethodConfigArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "project_payment_method" */
export type Project_Payment_Method_Aggregate = {
__typename?: 'project_payment_method_aggregate';

  aggregate?: Maybe<Project_Payment_Method_Aggregate_Fields>;
  nodes: Array<Project_Payment_Method>;
};

/** aggregate fields of "project_payment_method" */
export type Project_Payment_Method_Aggregate_Fields = {
__typename?: 'project_payment_method_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Payment_Method_Max_Fields>;
  min?: Maybe<Project_Payment_Method_Min_Fields>;
};
export type Project_Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Payment_Method_Max_Fields = {
__typename?: 'project_payment_method_max_fields';

  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Payment_Method_Min_Fields = {
__typename?: 'project_payment_method_min_fields';

  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_payment_method" */
export type Project_Payment_Method_Mutation_Response = {
__typename?: 'project_payment_method_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Payment_Method>;
};

/** QR Codes created by Agents to invite buyers to sign up */
export type Project_Qr_Code_For_Buyer = {
__typename?: 'project_qr_code_for_buyer';

  /** An object relationship */
  agent: User;
  /** An object relationship */
  agent_team: Organisation;
  agent_team_id: Scalars['uuid'];
  agent_user_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_Aggregate = {
__typename?: 'project_qr_code_for_buyer_aggregate';

  aggregate?: Maybe<Project_Qr_Code_For_Buyer_Aggregate_Fields>;
  nodes: Array<Project_Qr_Code_For_Buyer>;
};

/** aggregate fields of "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_Aggregate_Fields = {
__typename?: 'project_qr_code_for_buyer_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Qr_Code_For_Buyer_Max_Fields>;
  min?: Maybe<Project_Qr_Code_For_Buyer_Min_Fields>;
};
export type Project_Qr_Code_For_Buyer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Qr_Code_For_Buyer_Max_Fields = {
__typename?: 'project_qr_code_for_buyer_max_fields';

  agent_team_id?: Maybe<Scalars['uuid']>;
  agent_user_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Qr_Code_For_Buyer_Min_Fields = {
__typename?: 'project_qr_code_for_buyer_min_fields';

  agent_team_id?: Maybe<Scalars['uuid']>;
  agent_user_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_qr_code_for_buyer" */
export type Project_Qr_Code_For_Buyer_Mutation_Response = {
__typename?: 'project_qr_code_for_buyer_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Qr_Code_For_Buyer>;
};

/** Store project Reservation setting */
export type Project_Reservation_Setting = {
__typename?: 'project_reservation_setting';

  agent_holding_deposit_content: Scalars['String'];
  agent_review_summary_content: Scalars['String'];
  buyer_holding_deposit_content: Scalars['String'];
  buyer_review_summary_content: Scalars['String'];
  buyer_sign_contract_content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "project_reservation_setting" */
export type Project_Reservation_Setting_Aggregate = {
__typename?: 'project_reservation_setting_aggregate';

  aggregate?: Maybe<Project_Reservation_Setting_Aggregate_Fields>;
  nodes: Array<Project_Reservation_Setting>;
};

/** aggregate fields of "project_reservation_setting" */
export type Project_Reservation_Setting_Aggregate_Fields = {
__typename?: 'project_reservation_setting_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Reservation_Setting_Max_Fields>;
  min?: Maybe<Project_Reservation_Setting_Min_Fields>;
};
export type Project_Reservation_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Reservation_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Reservation_Setting_Max_Fields = {
__typename?: 'project_reservation_setting_max_fields';

  agent_holding_deposit_content?: Maybe<Scalars['String']>;
  agent_review_summary_content?: Maybe<Scalars['String']>;
  buyer_holding_deposit_content?: Maybe<Scalars['String']>;
  buyer_review_summary_content?: Maybe<Scalars['String']>;
  buyer_sign_contract_content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Reservation_Setting_Min_Fields = {
__typename?: 'project_reservation_setting_min_fields';

  agent_holding_deposit_content?: Maybe<Scalars['String']>;
  agent_review_summary_content?: Maybe<Scalars['String']>;
  buyer_holding_deposit_content?: Maybe<Scalars['String']>;
  buyer_review_summary_content?: Maybe<Scalars['String']>;
  buyer_sign_contract_content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_reservation_setting" */
export type Project_Reservation_Setting_Mutation_Response = {
__typename?: 'project_reservation_setting_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Reservation_Setting>;
};

/** columns and relationships of "project_sources" */
export type Project_Sources = {
__typename?: 'project_sources';

  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "project_sources" */
export type Project_Sources_Aggregate = {
__typename?: 'project_sources_aggregate';

  aggregate?: Maybe<Project_Sources_Aggregate_Fields>;
  nodes: Array<Project_Sources>;
};

/** aggregate fields of "project_sources" */
export type Project_Sources_Aggregate_Fields = {
__typename?: 'project_sources_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Sources_Max_Fields>;
  min?: Maybe<Project_Sources_Min_Fields>;
};
export type Project_Sources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Sources_Max_Fields = {
__typename?: 'project_sources_max_fields';

  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Sources_Min_Fields = {
__typename?: 'project_sources_min_fields';

  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_sources" */
export type Project_Sources_Mutation_Response = {
__typename?: 'project_sources_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Sources>;
};

/** Available project statuses */
export type Project_Status = {
__typename?: 'project_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "project_status" */
export type Project_Status_Aggregate = {
__typename?: 'project_status_aggregate';

  aggregate?: Maybe<Project_Status_Aggregate_Fields>;
  nodes: Array<Project_Status>;
};

/** aggregate fields of "project_status" */
export type Project_Status_Aggregate_Fields = {
__typename?: 'project_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Status_Max_Fields>;
  min?: Maybe<Project_Status_Min_Fields>;
};
export type Project_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Status_Max_Fields = {
__typename?: 'project_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Status_Min_Fields = {
__typename?: 'project_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_status" */
export type Project_Status_Mutation_Response = {
__typename?: 'project_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Status>;
};

/** aggregate stddev on columns */
export type Project_Stddev_Fields = {
__typename?: 'project_stddev_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Stddev_Pop_Fields = {
__typename?: 'project_stddev_pop_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Stddev_Samp_Fields = {
__typename?: 'project_stddev_samp_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Project_Sum_Fields = {
__typename?: 'project_sum_fields';

  domain_agency_id?: Maybe<Scalars['Int']>;
  domain_project_id?: Maybe<Scalars['Int']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['numeric']>;
  setting_holding_hours?: Maybe<Scalars['Int']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['numeric']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "project_type" */
export type Project_Type = {
__typename?: 'project_type';

  description: Scalars['String'];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  value: Scalars['String'];
};
export type Project_TypeProjectsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Project_TypeProjects_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};


/** aggregated selection of "project_type" */
export type Project_Type_Aggregate = {
__typename?: 'project_type_aggregate';

  aggregate?: Maybe<Project_Type_Aggregate_Fields>;
  nodes: Array<Project_Type>;
};

/** aggregate fields of "project_type" */
export type Project_Type_Aggregate_Fields = {
__typename?: 'project_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Project_Type_Max_Fields>;
  min?: Maybe<Project_Type_Min_Fields>;
};
export type Project_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Project_Type_Max_Fields = {
__typename?: 'project_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Type_Min_Fields = {
__typename?: 'project_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_type" */
export type Project_Type_Mutation_Response = {
__typename?: 'project_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Type>;
};

/** columns and relationships of "project_units" */
export type Project_Units = {
__typename?: 'project_units';

  bg_thumbnail_url?: Maybe<Scalars['String']>;
  bg_url?: Maybe<Scalars['String']>;
  brand_bg_color?: Maybe<Scalars['String']>;
  brand_fg_color?: Maybe<Scalars['String']>;
  delegated_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dev_org_logo_url?: Maybe<Scalars['String']>;
  dev_org_name?: Maybe<Scalars['String']>;
  listing_available_count?: Maybe<Scalars['bigint']>;
  listing_count?: Maybe<Scalars['bigint']>;
  logo_thumbnail_url?: Maybe<Scalars['String']>;
  logo_thumbnail_url_x2?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  managed_dev_logo_url?: Maybe<Scalars['String']>;
  managed_dev_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_units" */
export type Project_Units_Aggregate = {
__typename?: 'project_units_aggregate';

  aggregate?: Maybe<Project_Units_Aggregate_Fields>;
  nodes: Array<Project_Units>;
};

/** aggregate fields of "project_units" */
export type Project_Units_Aggregate_Fields = {
__typename?: 'project_units_aggregate_fields';

  avg?: Maybe<Project_Units_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Units_Max_Fields>;
  min?: Maybe<Project_Units_Min_Fields>;
  stddev?: Maybe<Project_Units_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Units_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Units_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Units_Sum_Fields>;
  var_pop?: Maybe<Project_Units_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Units_Var_Samp_Fields>;
  variance?: Maybe<Project_Units_Variance_Fields>;
};
export type Project_Units_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Units_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Project_Units_Avg_Fields = {
__typename?: 'project_units_avg_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Project_Units_Max_Fields = {
__typename?: 'project_units_max_fields';

  bg_thumbnail_url?: Maybe<Scalars['String']>;
  bg_url?: Maybe<Scalars['String']>;
  brand_bg_color?: Maybe<Scalars['String']>;
  brand_fg_color?: Maybe<Scalars['String']>;
  delegated_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dev_org_logo_url?: Maybe<Scalars['String']>;
  dev_org_name?: Maybe<Scalars['String']>;
  listing_available_count?: Maybe<Scalars['bigint']>;
  listing_count?: Maybe<Scalars['bigint']>;
  logo_thumbnail_url?: Maybe<Scalars['String']>;
  logo_thumbnail_url_x2?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  managed_dev_logo_url?: Maybe<Scalars['String']>;
  managed_dev_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Units_Min_Fields = {
__typename?: 'project_units_min_fields';

  bg_thumbnail_url?: Maybe<Scalars['String']>;
  bg_url?: Maybe<Scalars['String']>;
  brand_bg_color?: Maybe<Scalars['String']>;
  brand_fg_color?: Maybe<Scalars['String']>;
  delegated_by?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  dev_org_logo_url?: Maybe<Scalars['String']>;
  dev_org_name?: Maybe<Scalars['String']>;
  listing_available_count?: Maybe<Scalars['bigint']>;
  listing_count?: Maybe<Scalars['bigint']>;
  logo_thumbnail_url?: Maybe<Scalars['String']>;
  logo_thumbnail_url_x2?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  managed_dev_logo_url?: Maybe<Scalars['String']>;
  managed_dev_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Units_Stddev_Fields = {
__typename?: 'project_units_stddev_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Units_Stddev_Pop_Fields = {
__typename?: 'project_units_stddev_pop_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Units_Stddev_Samp_Fields = {
__typename?: 'project_units_stddev_samp_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Project_Units_Sum_Fields = {
__typename?: 'project_units_sum_fields';

  listing_available_count?: Maybe<Scalars['bigint']>;
  listing_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Project_Units_Var_Pop_Fields = {
__typename?: 'project_units_var_pop_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Units_Var_Samp_Fields = {
__typename?: 'project_units_var_samp_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Units_Variance_Fields = {
__typename?: 'project_units_variance_fields';

  listing_available_count?: Maybe<Scalars['Float']>;
  listing_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Project_Var_Pop_Fields = {
__typename?: 'project_var_pop_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Var_Samp_Fields = {
__typename?: 'project_var_samp_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Variance_Fields = {
__typename?: 'project_variance_fields';

  domain_agency_id?: Maybe<Scalars['Float']>;
  domain_project_id?: Maybe<Scalars['Float']>;
  /** Fixed holding deposit */
  holding_deposit?: Maybe<Scalars['Float']>;
  setting_holding_hours?: Maybe<Scalars['Float']>;
  /** contract deposit percentage */
  total_deposit_percentage?: Maybe<Scalars['Float']>;
  /** use for calculate the variable amount of holding deposit */
  variable_holding_deposit_percentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "property" */
export type Property = {
__typename?: 'property';

  /** An array relationship */
  acl_listing_agent_member: Array<Acl_Listing_Agent_Member>;
  /** An aggregate relationship */
  acl_listing_agent_member_aggregate: Acl_Listing_Agent_Member_Aggregate;
  /** An array relationship */
  acl_listing_master_agent: Array<Acl_Listing_Master_Agent>;
  /** An aggregate relationship */
  acl_listing_master_agent_aggregate: Acl_Listing_Master_Agent_Aggregate;
  /** An array relationship */
  acl_listing_property: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_aggregate: Acl_Listing_Property_Aggregate;
  /** An array relationship */
  acl_listing_property_team: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_aggregate: Acl_Listing_Property_Team_Aggregate;
  /** An array relationship */
  acl_property_allocation: Array<Acl_Property_Allocation>;
  /** An aggregate relationship */
  acl_property_allocation_aggregate: Acl_Property_Allocation_Aggregate;
  /** An object relationship */
  acl_property_allocation_count?: Maybe<Acl_Property_Allocation_Count>;
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  apartment_plan_object?: Maybe<Apartment_Plan_Property>;
  /** An array relationship */
  area_plots: Array<Area_Plot>;
  /** An aggregate relationship */
  area_plots_aggregate: Area_Plot_Aggregate;
  aspect?: Maybe<Scalars['String']>;
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  bathroom?: Maybe<Scalars['numeric']>;
  bedroom?: Maybe<Scalars['numeric']>;
  completion_status?: Maybe<Property_Completion_Status_Enum>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  enable_unit_preferences?: Maybe<Scalars['Boolean']>;
  external_area?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  features: Array<Feature>;
  /** An aggregate relationship */
  features_aggregate: Feature_Aggregate;
  /** An object relationship */
  floor_plan?: Maybe<Attachment>;
  floor_plan_id?: Maybe<Scalars['uuid']>;
  garage?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  internal_area?: Maybe<Scalars['numeric']>;
  level?: Maybe<Scalars['String']>;
  level_number?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  linen_plan?: Maybe<Attachment>;
  linen_plan_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  listing?: Maybe<Listing>;
  /** An array relationship */
  listings: Array<Listing>;
  /** An aggregate relationship */
  listings_aggregate: Listing_Aggregate;
  lot?: Maybe<Scalars['Int']>;
  lot_area?: Maybe<Scalars['numeric']>;
  lot_depth?: Maybe<Scalars['numeric']>;
  lot_suffix?: Maybe<Scalars['String']>;
  lot_width?: Maybe<Scalars['numeric']>;
  name: Scalars['String'];
  /** An array relationship */
  preferences: Array<Preference>;
  /** An aggregate relationship */
  preferences_aggregate: Preference_Aggregate;
  product_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: Project;
  project_id: Scalars['uuid'];
  /** An array relationship */
  property_allocation: Array<Property_Allocation>;
  /** An aggregate relationship */
  property_allocation_aggregate: Property_Allocation_Aggregate;
  /** An object relationship */
  property_allocation_count?: Maybe<Property_Allocation_Count>;
  /** An object relationship */
  property_completion_status?: Maybe<Property_Completion_Status>;
  /** An object relationship */
  property_group: Property_Group;
  property_group_id: Scalars['uuid'];
  /** An object relationship */
  property_type: Property_Type;
  sf_id?: Maybe<Scalars['String']>;
  storage: Scalars['Boolean'];
  storeys?: Maybe<Scalars['Int']>;
  street_name?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
  type: Property_Type_Enum;
  updated_at: Scalars['timestamptz'];
};
export type PropertyAcl_Listing_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type PropertyAcl_Listing_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type PropertyAcl_Listing_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type PropertyAcl_Listing_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type PropertyAcl_Listing_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type PropertyAcl_Listing_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type PropertyAcl_Listing_Property_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type PropertyAcl_Listing_Property_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type PropertyAcl_Property_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type PropertyAcl_Property_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type PropertyArea_PlotsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type PropertyArea_Plots_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type PropertyAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type PropertyAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type PropertyDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type PropertyDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type PropertyFeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type PropertyFeatures_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type PropertyListingsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type PropertyListings_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type PropertyPreferencesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type PropertyPreferences_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type PropertyProperty_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type PropertyProperty_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};


/** aggregated selection of "property" */
export type Property_Aggregate = {
__typename?: 'property_aggregate';

  aggregate?: Maybe<Property_Aggregate_Fields>;
  nodes: Array<Property>;
};

/** aggregate fields of "property" */
export type Property_Aggregate_Fields = {
__typename?: 'property_aggregate_fields';

  avg?: Maybe<Property_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Max_Fields>;
  min?: Maybe<Property_Min_Fields>;
  stddev?: Maybe<Property_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Sum_Fields>;
  var_pop?: Maybe<Property_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Var_Samp_Fields>;
  variance?: Maybe<Property_Variance_Fields>;
};
export type Property_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "property_allocation" */
export type Property_Allocation = {
__typename?: 'property_allocation';

  allocated_at?: Maybe<Scalars['timestamptz']>;
  allocated_to_org_id?: Maybe<Scalars['uuid']>;
  allocated_to_org_name?: Maybe<Scalars['String']>;
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  property_group?: Maybe<Property_Group>;
  property_group_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** An object relationship */
  unit_allocation_count?: Maybe<Unit_Allocation_Count>;
};

/** aggregated selection of "property_allocation" */
export type Property_Allocation_Aggregate = {
__typename?: 'property_allocation_aggregate';

  aggregate?: Maybe<Property_Allocation_Aggregate_Fields>;
  nodes: Array<Property_Allocation>;
};

/** aggregate fields of "property_allocation" */
export type Property_Allocation_Aggregate_Fields = {
__typename?: 'property_allocation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Property_Allocation_Max_Fields>;
  min?: Maybe<Property_Allocation_Min_Fields>;
};
export type Property_Allocation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "property_allocation_count" */
export type Property_Allocation_Count = {
__typename?: 'property_allocation_count';

  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "property_allocation_count" */
export type Property_Allocation_Count_Aggregate = {
__typename?: 'property_allocation_count_aggregate';

  aggregate?: Maybe<Property_Allocation_Count_Aggregate_Fields>;
  nodes: Array<Property_Allocation_Count>;
};

/** aggregate fields of "property_allocation_count" */
export type Property_Allocation_Count_Aggregate_Fields = {
__typename?: 'property_allocation_count_aggregate_fields';

  avg?: Maybe<Property_Allocation_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Allocation_Count_Max_Fields>;
  min?: Maybe<Property_Allocation_Count_Min_Fields>;
  stddev?: Maybe<Property_Allocation_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Allocation_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Allocation_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Allocation_Count_Sum_Fields>;
  var_pop?: Maybe<Property_Allocation_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Allocation_Count_Var_Samp_Fields>;
  variance?: Maybe<Property_Allocation_Count_Variance_Fields>;
};
export type Property_Allocation_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Allocation_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Property_Allocation_Count_Avg_Fields = {
__typename?: 'property_allocation_count_avg_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Property_Allocation_Count_Max_Fields = {
__typename?: 'property_allocation_count_max_fields';

  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Property_Allocation_Count_Min_Fields = {
__typename?: 'property_allocation_count_min_fields';

  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Property_Allocation_Count_Stddev_Fields = {
__typename?: 'property_allocation_count_stddev_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Property_Allocation_Count_Stddev_Pop_Fields = {
__typename?: 'property_allocation_count_stddev_pop_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Property_Allocation_Count_Stddev_Samp_Fields = {
__typename?: 'property_allocation_count_stddev_samp_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Property_Allocation_Count_Sum_Fields = {
__typename?: 'property_allocation_count_sum_fields';

  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Property_Allocation_Count_Var_Pop_Fields = {
__typename?: 'property_allocation_count_var_pop_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Property_Allocation_Count_Var_Samp_Fields = {
__typename?: 'property_allocation_count_var_samp_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Property_Allocation_Count_Variance_Fields = {
__typename?: 'property_allocation_count_variance_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Property_Allocation_Max_Fields = {
__typename?: 'property_allocation_max_fields';

  allocated_at?: Maybe<Scalars['timestamptz']>;
  allocated_to_org_id?: Maybe<Scalars['uuid']>;
  allocated_to_org_name?: Maybe<Scalars['String']>;
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Allocation_Min_Fields = {
__typename?: 'property_allocation_min_fields';

  allocated_at?: Maybe<Scalars['timestamptz']>;
  allocated_to_org_id?: Maybe<Scalars['uuid']>;
  allocated_to_org_name?: Maybe<Scalars['String']>;
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate avg on columns */
export type Property_Avg_Fields = {
__typename?: 'property_avg_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "property_completion_status" */
export type Property_Completion_Status = {
__typename?: 'property_completion_status';

  description: Scalars['String'];
  /** An array relationship */
  properties: Array<Property>;
  /** An aggregate relationship */
  properties_aggregate: Property_Aggregate;
  value: Scalars['String'];
};
export type Property_Completion_StatusPropertiesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Property_Completion_StatusProperties_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};


/** aggregated selection of "property_completion_status" */
export type Property_Completion_Status_Aggregate = {
__typename?: 'property_completion_status_aggregate';

  aggregate?: Maybe<Property_Completion_Status_Aggregate_Fields>;
  nodes: Array<Property_Completion_Status>;
};

/** aggregate fields of "property_completion_status" */
export type Property_Completion_Status_Aggregate_Fields = {
__typename?: 'property_completion_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Property_Completion_Status_Max_Fields>;
  min?: Maybe<Property_Completion_Status_Min_Fields>;
};
export type Property_Completion_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Completion_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Property_Completion_Status_Max_Fields = {
__typename?: 'property_completion_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Completion_Status_Min_Fields = {
__typename?: 'property_completion_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "property_completion_status" */
export type Property_Completion_Status_Mutation_Response = {
__typename?: 'property_completion_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Completion_Status>;
};

/** columns and relationships of "property_detail" */
export type Property_Detail = {
__typename?: 'property_detail';

  access_type?: Maybe<Scalars['String']>;
  allocation_agency_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  listings: Array<Listing>;
  /** An aggregate relationship */
  listings_aggregate: Listing_Aggregate;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  property_group?: Maybe<Property_Group>;
  property_group_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  property_type?: Maybe<Property_Type>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};
export type Property_DetailListingsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Property_DetailListings_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};


/** aggregated selection of "property_detail" */
export type Property_Detail_Aggregate = {
__typename?: 'property_detail_aggregate';

  aggregate?: Maybe<Property_Detail_Aggregate_Fields>;
  nodes: Array<Property_Detail>;
};

/** aggregate fields of "property_detail" */
export type Property_Detail_Aggregate_Fields = {
__typename?: 'property_detail_aggregate_fields';

  avg?: Maybe<Property_Detail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Detail_Max_Fields>;
  min?: Maybe<Property_Detail_Min_Fields>;
  stddev?: Maybe<Property_Detail_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Detail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Detail_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Detail_Sum_Fields>;
  var_pop?: Maybe<Property_Detail_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Detail_Var_Samp_Fields>;
  variance?: Maybe<Property_Detail_Variance_Fields>;
};
export type Property_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Property_Detail_Avg_Fields = {
__typename?: 'property_detail_avg_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Property_Detail_Max_Fields = {
__typename?: 'property_detail_max_fields';

  access_type?: Maybe<Scalars['String']>;
  allocation_agency_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Detail_Min_Fields = {
__typename?: 'property_detail_min_fields';

  access_type?: Maybe<Scalars['String']>;
  allocation_agency_count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Property_Detail_Stddev_Fields = {
__typename?: 'property_detail_stddev_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Property_Detail_Stddev_Pop_Fields = {
__typename?: 'property_detail_stddev_pop_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Property_Detail_Stddev_Samp_Fields = {
__typename?: 'property_detail_stddev_samp_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Property_Detail_Sum_Fields = {
__typename?: 'property_detail_sum_fields';

  allocation_agency_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Property_Detail_Var_Pop_Fields = {
__typename?: 'property_detail_var_pop_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Property_Detail_Var_Samp_Fields = {
__typename?: 'property_detail_var_samp_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Property_Detail_Variance_Fields = {
__typename?: 'property_detail_variance_fields';

  allocation_agency_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "property_group" */
export type Property_Group = {
__typename?: 'property_group';

  /** An array relationship */
  acl_allocation_edit: Array<Acl_Allocation_Edit>;
  /** An aggregate relationship */
  acl_allocation_edit_aggregate: Acl_Allocation_Edit_Aggregate;
  /** An array relationship */
  acl_listing_property: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_aggregate: Acl_Listing_Property_Aggregate;
  /** An array relationship */
  acl_listing_property_parent: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_parent_aggregate: Acl_Listing_Property_Aggregate;
  /** An array relationship */
  acl_listing_property_team: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_aggregate: Acl_Listing_Property_Team_Aggregate;
  /** An array relationship */
  acl_listing_property_team_parent: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_parent_aggregate: Acl_Listing_Property_Team_Aggregate;
  /** An array relationship */
  acl_stage_agent_member: Array<Acl_Stage_Agent_Member>;
  /** An aggregate relationship */
  acl_stage_agent_member_aggregate: Acl_Stage_Agent_Member_Aggregate;
  address?: Maybe<Scalars['String']>;
  agency_agreement_settings: Scalars['jsonb'];
  /** An array relationship */
  allocations: Array<Allocation>;
  /** An aggregate relationship */
  allocations_aggregate: Allocation_Aggregate;
  /** An object relationship */
  apartment_plan_building?: Maybe<Apartment_Plan_Building>;
  /** An object relationship */
  apartment_stat?: Maybe<Project_Apartment_Stat>;
  /** An array relationship */
  area_plots: Array<Area_Plot>;
  /** An aggregate relationship */
  area_plots_aggregate: Area_Plot_Aggregate;
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  can_publish: Scalars['Boolean'];
  completion?: Maybe<Scalars['date']>;
  completion_status?: Maybe<Property_Group_Completion_Status_Enum>;
  construction_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  digital_contract_settings: Scalars['jsonb'];
  enable_reservation: Scalars['Boolean'];
  enable_settlement_status: Scalars['Boolean'];
  enabled_registration?: Maybe<Scalars['Boolean']>;
  eoi_enabled: Scalars['Boolean'];
  holding_deposit?: Maybe<Scalars['numeric']>;
  holding_enabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  /** An array relationship */
  member_project_allocates: Array<Member_Project_Allocate>;
  /** An aggregate relationship */
  member_project_allocates_aggregate: Member_Project_Allocate_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  parent_allocations: Array<Allocation>;
  /** An aggregate relationship */
  parent_allocations_aggregate: Allocation_Aggregate;
  parent_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  plan_attachment?: Maybe<Attachment>;
  plan_attachment_id?: Maybe<Scalars['uuid']>;
  preference_enabled: Scalars['Boolean'];
  /** An array relationship */
  preferences: Array<Preference>;
  /** An aggregate relationship */
  preferences_aggregate: Preference_Aggregate;
  price_from_range: Scalars['numeric'];
  price_to_range: Scalars['numeric'];
  product_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: Project;
  /** An array relationship */
  project_apartment_levels: Array<Project_Apartment_Level>;
  /** An aggregate relationship */
  project_apartment_levels_aggregate: Project_Apartment_Level_Aggregate;
  project_id: Scalars['uuid'];
  /** An array relationship */
  properties: Array<Property>;
  /** An aggregate relationship */
  properties_aggregate: Property_Aggregate;
  /** An array relationship */
  property_group_children: Array<Property_Group>;
  /** An aggregate relationship */
  property_group_children_aggregate: Property_Group_Aggregate;
  /** An object relationship */
  property_group_completion_status?: Maybe<Property_Group_Completion_Status>;
  /** An object relationship */
  property_group_parent?: Maybe<Property_Group>;
  /** An object relationship */
  property_group_type: Property_Group_Type;
  sf_id?: Maybe<Scalars['String']>;
  show_price_range: Scalars['Boolean'];
  show_pricing: Scalars['Boolean'];
  street_name?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  type: Property_Group_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  vendor?: Maybe<Organisation>;
  vendor_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  vendor_solicitor?: Maybe<Organisation>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
};
export type Property_GroupAcl_Allocation_EditArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Property_GroupAcl_Allocation_Edit_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Property_GroupAcl_Listing_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_ParentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_Parent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_Team_ParentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Property_GroupAcl_Listing_Property_Team_Parent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Property_GroupAcl_Stage_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Property_GroupAcl_Stage_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Property_GroupAgency_Agreement_SettingsArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type Property_GroupAllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Property_GroupAllocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Property_GroupArea_PlotsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Property_GroupArea_Plots_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Property_GroupAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Property_GroupAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Property_GroupDealsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Property_GroupDeals_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Property_GroupDigital_Contract_SettingsArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};

export type Property_GroupMember_Project_AllocatesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Property_GroupMember_Project_Allocates_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Property_GroupParent_AllocationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Property_GroupParent_Allocations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Property_GroupPreferencesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Property_GroupPreferences_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Property_GroupProject_Apartment_LevelsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Level_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Property_GroupProject_Apartment_Levels_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Level_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Property_GroupPropertiesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Property_GroupProperties_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Property_GroupProperty_Group_ChildrenArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Property_GroupProperty_Group_Children_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};


/** aggregated selection of "property_group" */
export type Property_Group_Aggregate = {
__typename?: 'property_group_aggregate';

  aggregate?: Maybe<Property_Group_Aggregate_Fields>;
  nodes: Array<Property_Group>;
};

/** aggregate fields of "property_group" */
export type Property_Group_Aggregate_Fields = {
__typename?: 'property_group_aggregate_fields';

  avg?: Maybe<Property_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Group_Max_Fields>;
  min?: Maybe<Property_Group_Min_Fields>;
  stddev?: Maybe<Property_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Group_Sum_Fields>;
  var_pop?: Maybe<Property_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Group_Var_Samp_Fields>;
  variance?: Maybe<Property_Group_Variance_Fields>;
};
export type Property_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "property_group_allocation_detail_view" */
export type Property_Group_Allocation_Detail_View = {
__typename?: 'property_group_allocation_detail_view';

  organisation_id?: Maybe<Scalars['uuid']>;
  project_access_active?: Maybe<Scalars['Boolean']>;
  project_allocation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  stage_access_active?: Maybe<Scalars['Boolean']>;
  stage_allocation_id?: Maybe<Scalars['uuid']>;
  stage_include_listings?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "property_group_allocation_detail_view" */
export type Property_Group_Allocation_Detail_View_Aggregate = {
__typename?: 'property_group_allocation_detail_view_aggregate';

  aggregate?: Maybe<Property_Group_Allocation_Detail_View_Aggregate_Fields>;
  nodes: Array<Property_Group_Allocation_Detail_View>;
};

/** aggregate fields of "property_group_allocation_detail_view" */
export type Property_Group_Allocation_Detail_View_Aggregate_Fields = {
__typename?: 'property_group_allocation_detail_view_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Property_Group_Allocation_Detail_View_Max_Fields>;
  min?: Maybe<Property_Group_Allocation_Detail_View_Min_Fields>;
};
export type Property_Group_Allocation_Detail_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Property_Group_Allocation_Detail_View_Max_Fields = {
__typename?: 'property_group_allocation_detail_view_max_fields';

  organisation_id?: Maybe<Scalars['uuid']>;
  project_allocation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  stage_allocation_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Property_Group_Allocation_Detail_View_Min_Fields = {
__typename?: 'property_group_allocation_detail_view_min_fields';

  organisation_id?: Maybe<Scalars['uuid']>;
  project_allocation_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  stage_allocation_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "property_group_allocation_view" */
export type Property_Group_Allocation_View = {
__typename?: 'property_group_allocation_view';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  shared_count?: Maybe<Scalars['bigint']>;
  stage_id?: Maybe<Scalars['uuid']>;
  stage_name?: Maybe<Scalars['String']>;
  stage_parent_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "property_group_allocation_view" */
export type Property_Group_Allocation_View_Aggregate = {
__typename?: 'property_group_allocation_view_aggregate';

  aggregate?: Maybe<Property_Group_Allocation_View_Aggregate_Fields>;
  nodes: Array<Property_Group_Allocation_View>;
};

/** aggregate fields of "property_group_allocation_view" */
export type Property_Group_Allocation_View_Aggregate_Fields = {
__typename?: 'property_group_allocation_view_aggregate_fields';

  avg?: Maybe<Property_Group_Allocation_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Property_Group_Allocation_View_Max_Fields>;
  min?: Maybe<Property_Group_Allocation_View_Min_Fields>;
  stddev?: Maybe<Property_Group_Allocation_View_Stddev_Fields>;
  stddev_pop?: Maybe<Property_Group_Allocation_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Property_Group_Allocation_View_Stddev_Samp_Fields>;
  sum?: Maybe<Property_Group_Allocation_View_Sum_Fields>;
  var_pop?: Maybe<Property_Group_Allocation_View_Var_Pop_Fields>;
  var_samp?: Maybe<Property_Group_Allocation_View_Var_Samp_Fields>;
  variance?: Maybe<Property_Group_Allocation_View_Variance_Fields>;
};
export type Property_Group_Allocation_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Group_Allocation_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Property_Group_Allocation_View_Avg_Fields = {
__typename?: 'property_group_allocation_view_avg_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Property_Group_Allocation_View_Max_Fields = {
__typename?: 'property_group_allocation_view_max_fields';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  shared_count?: Maybe<Scalars['bigint']>;
  stage_id?: Maybe<Scalars['uuid']>;
  stage_name?: Maybe<Scalars['String']>;
  stage_parent_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Property_Group_Allocation_View_Min_Fields = {
__typename?: 'property_group_allocation_view_min_fields';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  shared_count?: Maybe<Scalars['bigint']>;
  stage_id?: Maybe<Scalars['uuid']>;
  stage_name?: Maybe<Scalars['String']>;
  stage_parent_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Property_Group_Allocation_View_Stddev_Fields = {
__typename?: 'property_group_allocation_view_stddev_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Property_Group_Allocation_View_Stddev_Pop_Fields = {
__typename?: 'property_group_allocation_view_stddev_pop_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Property_Group_Allocation_View_Stddev_Samp_Fields = {
__typename?: 'property_group_allocation_view_stddev_samp_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Property_Group_Allocation_View_Sum_Fields = {
__typename?: 'property_group_allocation_view_sum_fields';

  exclusive_count?: Maybe<Scalars['bigint']>;
  open_count?: Maybe<Scalars['bigint']>;
  shared_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Property_Group_Allocation_View_Var_Pop_Fields = {
__typename?: 'property_group_allocation_view_var_pop_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Property_Group_Allocation_View_Var_Samp_Fields = {
__typename?: 'property_group_allocation_view_var_samp_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Property_Group_Allocation_View_Variance_Fields = {
__typename?: 'property_group_allocation_view_variance_fields';

  exclusive_count?: Maybe<Scalars['Float']>;
  open_count?: Maybe<Scalars['Float']>;
  shared_count?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Property_Group_Avg_Fields = {
__typename?: 'property_group_avg_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "property_group_completion_status" */
export type Property_Group_Completion_Status = {
__typename?: 'property_group_completion_status';

  description: Scalars['String'];
  /** An array relationship */
  property_groups: Array<Property_Group>;
  /** An aggregate relationship */
  property_groups_aggregate: Property_Group_Aggregate;
  value: Scalars['String'];
};
export type Property_Group_Completion_StatusProperty_GroupsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Property_Group_Completion_StatusProperty_Groups_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};


/** aggregated selection of "property_group_completion_status" */
export type Property_Group_Completion_Status_Aggregate = {
__typename?: 'property_group_completion_status_aggregate';

  aggregate?: Maybe<Property_Group_Completion_Status_Aggregate_Fields>;
  nodes: Array<Property_Group_Completion_Status>;
};

/** aggregate fields of "property_group_completion_status" */
export type Property_Group_Completion_Status_Aggregate_Fields = {
__typename?: 'property_group_completion_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Property_Group_Completion_Status_Max_Fields>;
  min?: Maybe<Property_Group_Completion_Status_Min_Fields>;
};
export type Property_Group_Completion_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Group_Completion_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Property_Group_Completion_Status_Max_Fields = {
__typename?: 'property_group_completion_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Group_Completion_Status_Min_Fields = {
__typename?: 'property_group_completion_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "property_group_completion_status" */
export type Property_Group_Completion_Status_Mutation_Response = {
__typename?: 'property_group_completion_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Group_Completion_Status>;
};

/** aggregate max on columns */
export type Property_Group_Max_Fields = {
__typename?: 'property_group_max_fields';

  address?: Maybe<Scalars['String']>;
  completion?: Maybe<Scalars['date']>;
  construction_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  holding_deposit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  plan_attachment_id?: Maybe<Scalars['uuid']>;
  price_from_range?: Maybe<Scalars['numeric']>;
  price_to_range?: Maybe<Scalars['numeric']>;
  product_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  street_name?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Property_Group_Min_Fields = {
__typename?: 'property_group_min_fields';

  address?: Maybe<Scalars['String']>;
  completion?: Maybe<Scalars['date']>;
  construction_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  holding_deposit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  plan_attachment_id?: Maybe<Scalars['uuid']>;
  price_from_range?: Maybe<Scalars['numeric']>;
  price_to_range?: Maybe<Scalars['numeric']>;
  product_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  street_name?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['uuid']>;
  vendor_solicitor_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "property_group" */
export type Property_Group_Mutation_Response = {
__typename?: 'property_group_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Group>;
};

/** aggregate stddev on columns */
export type Property_Group_Stddev_Fields = {
__typename?: 'property_group_stddev_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Property_Group_Stddev_Pop_Fields = {
__typename?: 'property_group_stddev_pop_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Property_Group_Stddev_Samp_Fields = {
__typename?: 'property_group_stddev_samp_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Property_Group_Sum_Fields = {
__typename?: 'property_group_sum_fields';

  holding_deposit?: Maybe<Scalars['numeric']>;
  price_from_range?: Maybe<Scalars['numeric']>;
  price_to_range?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "property_group_type" */
export type Property_Group_Type = {
__typename?: 'property_group_type';

  description: Scalars['String'];
  /** An array relationship */
  property_groups: Array<Property_Group>;
  /** An aggregate relationship */
  property_groups_aggregate: Property_Group_Aggregate;
  value: Scalars['String'];
};
export type Property_Group_TypeProperty_GroupsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Property_Group_TypeProperty_Groups_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};


/** aggregated selection of "property_group_type" */
export type Property_Group_Type_Aggregate = {
__typename?: 'property_group_type_aggregate';

  aggregate?: Maybe<Property_Group_Type_Aggregate_Fields>;
  nodes: Array<Property_Group_Type>;
};

/** aggregate fields of "property_group_type" */
export type Property_Group_Type_Aggregate_Fields = {
__typename?: 'property_group_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Property_Group_Type_Max_Fields>;
  min?: Maybe<Property_Group_Type_Min_Fields>;
};
export type Property_Group_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Group_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Property_Group_Type_Max_Fields = {
__typename?: 'property_group_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Group_Type_Min_Fields = {
__typename?: 'property_group_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "property_group_type" */
export type Property_Group_Type_Mutation_Response = {
__typename?: 'property_group_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Group_Type>;
};

/** aggregate var_pop on columns */
export type Property_Group_Var_Pop_Fields = {
__typename?: 'property_group_var_pop_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Property_Group_Var_Samp_Fields = {
__typename?: 'property_group_var_samp_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Property_Group_Variance_Fields = {
__typename?: 'property_group_variance_fields';

  holding_deposit?: Maybe<Scalars['Float']>;
  price_from_range?: Maybe<Scalars['Float']>;
  price_to_range?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Property_Max_Fields = {
__typename?: 'property_max_fields';

  address?: Maybe<Scalars['String']>;
  aspect?: Maybe<Scalars['String']>;
  bathroom?: Maybe<Scalars['numeric']>;
  bedroom?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_area?: Maybe<Scalars['numeric']>;
  floor_plan_id?: Maybe<Scalars['uuid']>;
  garage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  internal_area?: Maybe<Scalars['numeric']>;
  level?: Maybe<Scalars['String']>;
  level_number?: Maybe<Scalars['numeric']>;
  linen_plan_id?: Maybe<Scalars['uuid']>;
  lot?: Maybe<Scalars['Int']>;
  lot_area?: Maybe<Scalars['numeric']>;
  lot_depth?: Maybe<Scalars['numeric']>;
  lot_suffix?: Maybe<Scalars['String']>;
  lot_width?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  product_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  storeys?: Maybe<Scalars['Int']>;
  street_name?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Property_Min_Fields = {
__typename?: 'property_min_fields';

  address?: Maybe<Scalars['String']>;
  aspect?: Maybe<Scalars['String']>;
  bathroom?: Maybe<Scalars['numeric']>;
  bedroom?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_area?: Maybe<Scalars['numeric']>;
  floor_plan_id?: Maybe<Scalars['uuid']>;
  garage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  internal_area?: Maybe<Scalars['numeric']>;
  level?: Maybe<Scalars['String']>;
  level_number?: Maybe<Scalars['numeric']>;
  linen_plan_id?: Maybe<Scalars['uuid']>;
  lot?: Maybe<Scalars['Int']>;
  lot_area?: Maybe<Scalars['numeric']>;
  lot_depth?: Maybe<Scalars['numeric']>;
  lot_suffix?: Maybe<Scalars['String']>;
  lot_width?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  product_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  property_group_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  storeys?: Maybe<Scalars['Int']>;
  street_name?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "property" */
export type Property_Mutation_Response = {
__typename?: 'property_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property>;
};

/** aggregate stddev on columns */
export type Property_Stddev_Fields = {
__typename?: 'property_stddev_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_pop on columns */
export type Property_Stddev_Pop_Fields = {
__typename?: 'property_stddev_pop_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev_samp on columns */
export type Property_Stddev_Samp_Fields = {
__typename?: 'property_stddev_samp_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Property_Sum_Fields = {
__typename?: 'property_sum_fields';

  bathroom?: Maybe<Scalars['numeric']>;
  bedroom?: Maybe<Scalars['numeric']>;
  external_area?: Maybe<Scalars['numeric']>;
  garage?: Maybe<Scalars['numeric']>;
  internal_area?: Maybe<Scalars['numeric']>;
  level_number?: Maybe<Scalars['numeric']>;
  lot?: Maybe<Scalars['Int']>;
  lot_area?: Maybe<Scalars['numeric']>;
  lot_depth?: Maybe<Scalars['numeric']>;
  lot_width?: Maybe<Scalars['numeric']>;
  storeys?: Maybe<Scalars['Int']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "property_type" */
export type Property_Type = {
__typename?: 'property_type';

  description: Scalars['String'];
  /** An array relationship */
  properties: Array<Property>;
  /** An aggregate relationship */
  properties_aggregate: Property_Aggregate;
  value: Scalars['String'];
};
export type Property_TypePropertiesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Property_TypeProperties_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};


/** aggregated selection of "property_type" */
export type Property_Type_Aggregate = {
__typename?: 'property_type_aggregate';

  aggregate?: Maybe<Property_Type_Aggregate_Fields>;
  nodes: Array<Property_Type>;
};

/** aggregate fields of "property_type" */
export type Property_Type_Aggregate_Fields = {
__typename?: 'property_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Property_Type_Max_Fields>;
  min?: Maybe<Property_Type_Min_Fields>;
};
export type Property_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Property_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Property_Type_Max_Fields = {
__typename?: 'property_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Property_Type_Min_Fields = {
__typename?: 'property_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "property_type" */
export type Property_Type_Mutation_Response = {
__typename?: 'property_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Property_Type>;
};

/** aggregate var_pop on columns */
export type Property_Var_Pop_Fields = {
__typename?: 'property_var_pop_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** aggregate var_samp on columns */
export type Property_Var_Samp_Fields = {
__typename?: 'property_var_samp_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

/** aggregate variance on columns */
export type Property_Variance_Fields = {
__typename?: 'property_variance_fields';

  bathroom?: Maybe<Scalars['Float']>;
  bedroom?: Maybe<Scalars['Float']>;
  external_area?: Maybe<Scalars['Float']>;
  garage?: Maybe<Scalars['Float']>;
  internal_area?: Maybe<Scalars['Float']>;
  level_number?: Maybe<Scalars['Float']>;
  lot?: Maybe<Scalars['Float']>;
  lot_area?: Maybe<Scalars['Float']>;
  lot_depth?: Maybe<Scalars['Float']>;
  lot_width?: Maybe<Scalars['Float']>;
  storeys?: Maybe<Scalars['Float']>;
  /** Sum of Internal & external area */
  total_area?: Maybe<Scalars['numeric']>;
};

export type Query_Root = {
__typename?: 'query_root';

  /** ABN or ACN lookup via 3rd party API. invitationId is required when called anonymously via Partner Portal */
  abn_acn_lookup: AbnAcnLookupResp;
  /** An array relationship */
  acl_allocation: Array<Acl_Allocation>;
  /** An aggregate relationship */
  acl_allocation_aggregate: Acl_Allocation_Aggregate;
  /** An array relationship */
  acl_allocation_edit: Array<Acl_Allocation_Edit>;
  /** An aggregate relationship */
  acl_allocation_edit_aggregate: Acl_Allocation_Edit_Aggregate;
  /** An array relationship */
  acl_contact_agent_member: Array<Acl_Contact_Agent_Member>;
  /** An aggregate relationship */
  acl_contact_agent_member_aggregate: Acl_Contact_Agent_Member_Aggregate;
  /** An array relationship */
  acl_contact_developer_member: Array<Acl_Contact_Developer_Member>;
  /** An aggregate relationship */
  acl_contact_developer_member_aggregate: Acl_Contact_Developer_Member_Aggregate;
  /** An array relationship */
  acl_contact_master_agent: Array<Acl_Contact_Master_Agent>;
  /** An aggregate relationship */
  acl_contact_master_agent_aggregate: Acl_Contact_Master_Agent_Aggregate;
  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** An array relationship */
  acl_deal_developer_member: Array<Acl_Deal_Developer_Member>;
  /** An aggregate relationship */
  acl_deal_developer_member_aggregate: Acl_Deal_Developer_Member_Aggregate;
  /** An array relationship */
  acl_deal_master_agent: Array<Acl_Deal_Master_Agent>;
  /** An aggregate relationship */
  acl_deal_master_agent_aggregate: Acl_Deal_Master_Agent_Aggregate;
  /** An array relationship */
  acl_deal_user_team: Array<Acl_Deal_User_Team>;
  /** An aggregate relationship */
  acl_deal_user_team_aggregate: Acl_Deal_User_Team_Aggregate;
  /** fetch data from the table: "acl_deal_user_team_desc" */
  acl_deal_user_team_desc: Array<Acl_Deal_User_Team_Desc>;
  /** fetch aggregated fields from the table: "acl_deal_user_team_desc" */
  acl_deal_user_team_desc_aggregate: Acl_Deal_User_Team_Desc_Aggregate;
  /** An array relationship */
  acl_listing_agent_member: Array<Acl_Listing_Agent_Member>;
  /** An aggregate relationship */
  acl_listing_agent_member_aggregate: Acl_Listing_Agent_Member_Aggregate;
  /** fetch data from the table: "acl_listing_developer_member" */
  acl_listing_developer_member: Array<Acl_Listing_Developer_Member>;
  /** fetch aggregated fields from the table: "acl_listing_developer_member" */
  acl_listing_developer_member_aggregate: Acl_Listing_Developer_Member_Aggregate;
  /** An array relationship */
  acl_listing_master_agent: Array<Acl_Listing_Master_Agent>;
  /** An aggregate relationship */
  acl_listing_master_agent_aggregate: Acl_Listing_Master_Agent_Aggregate;
  /** An array relationship */
  acl_listing_property: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_aggregate: Acl_Listing_Property_Aggregate;
  /** fetch data from the table: "acl_listing_property_desc" */
  acl_listing_property_desc: Array<Acl_Listing_Property_Desc>;
  /** fetch aggregated fields from the table: "acl_listing_property_desc" */
  acl_listing_property_desc_aggregate: Acl_Listing_Property_Desc_Aggregate;
  /** fetch data from the table: "acl_listing_property_member" */
  acl_listing_property_member: Array<Acl_Listing_Property_Member>;
  /** fetch aggregated fields from the table: "acl_listing_property_member" */
  acl_listing_property_member_aggregate: Acl_Listing_Property_Member_Aggregate;
  /** An array relationship */
  acl_listing_property_team: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_aggregate: Acl_Listing_Property_Team_Aggregate;
  /** fetch data from the table: "acl_listing_property_team_desc" */
  acl_listing_property_team_desc: Array<Acl_Listing_Property_Team_Desc>;
  /** fetch aggregated fields from the table: "acl_listing_property_team_desc" */
  acl_listing_property_team_desc_aggregate: Acl_Listing_Property_Team_Desc_Aggregate;
  /** fetch data from the table: "acl_listing_via_listing_agent_member" */
  acl_listing_via_listing_agent_member: Array<Acl_Listing_Via_Listing_Agent_Member>;
  /** fetch aggregated fields from the table: "acl_listing_via_listing_agent_member" */
  acl_listing_via_listing_agent_member_aggregate: Acl_Listing_Via_Listing_Agent_Member_Aggregate;
  /** fetch data from the table: "acl_listing_via_stage_agent_member" */
  acl_listing_via_stage_agent_member: Array<Acl_Listing_Via_Stage_Agent_Member>;
  /** fetch aggregated fields from the table: "acl_listing_via_stage_agent_member" */
  acl_listing_via_stage_agent_member_aggregate: Acl_Listing_Via_Stage_Agent_Member_Aggregate;
  /** An array relationship */
  acl_org_agent_member: Array<Acl_Org_Agent_Member>;
  /** An aggregate relationship */
  acl_org_agent_member_aggregate: Acl_Org_Agent_Member_Aggregate;
  /** An array relationship */
  acl_org_developer_member: Array<Acl_Org_Developer_Member>;
  /** An aggregate relationship */
  acl_org_developer_member_aggregate: Acl_Org_Developer_Member_Aggregate;
  /** fetch data from the table: "acl_org_feature_enabled" */
  acl_org_feature_enabled: Array<Acl_Org_Feature_Enabled>;
  /** fetch aggregated fields from the table: "acl_org_feature_enabled" */
  acl_org_feature_enabled_aggregate: Acl_Org_Feature_Enabled_Aggregate;
  /** An array relationship */
  acl_org_master_agent: Array<Acl_Org_Master_Agent>;
  /** An aggregate relationship */
  acl_org_master_agent_aggregate: Acl_Org_Master_Agent_Aggregate;
  /** An array relationship */
  acl_property_allocation: Array<Acl_Property_Allocation>;
  /** An aggregate relationship */
  acl_property_allocation_aggregate: Acl_Property_Allocation_Aggregate;
  /** fetch data from the table: "acl_property_allocation_count" */
  acl_property_allocation_count: Array<Acl_Property_Allocation_Count>;
  /** fetch aggregated fields from the table: "acl_property_allocation_count" */
  acl_property_allocation_count_aggregate: Acl_Property_Allocation_Count_Aggregate;
  /** An array relationship */
  acl_stage_agent_member: Array<Acl_Stage_Agent_Member>;
  /** An aggregate relationship */
  acl_stage_agent_member_aggregate: Acl_Stage_Agent_Member_Aggregate;
  /** fetch data from the table: "acl_update" */
  acl_update: Array<Acl_Update>;
  /** fetch aggregated fields from the table: "acl_update" */
  acl_update_aggregate: Acl_Update_Aggregate;
  /** fetch data from the table: "acl_update" using primary key columns */
  acl_update_by_pk?: Maybe<Acl_Update>;
  /** fetch data from the table: "acl_update_status" */
  acl_update_status: Array<Acl_Update_Status>;
  /** fetch aggregated fields from the table: "acl_update_status" */
  acl_update_status_aggregate: Acl_Update_Status_Aggregate;
  /** fetch data from the table: "acl_update_status" using primary key columns */
  acl_update_status_by_pk?: Maybe<Acl_Update_Status>;
  address_auto_complete?: Maybe<AddressAutoCompleteGroupOutput>;
  /** fetch data from the table: "agency_agreement" */
  agency_agreement: Array<Agency_Agreement>;
  /** fetch aggregated fields from the table: "agency_agreement" */
  agency_agreement_aggregate: Agency_Agreement_Aggregate;
  /** fetch data from the table: "agency_agreement" using primary key columns */
  agency_agreement_by_pk?: Maybe<Agency_Agreement>;
  /** fetch data from the table: "agreement" */
  agreement: Array<Agreement>;
  /** fetch aggregated fields from the table: "agreement" */
  agreement_aggregate: Agreement_Aggregate;
  /** fetch data from the table: "agreement" using primary key columns */
  agreement_by_pk?: Maybe<Agreement>;
  /** fetch data from the table: "allocation" */
  allocation: Array<Allocation>;
  /** fetch aggregated fields from the table: "allocation" */
  allocation_aggregate: Allocation_Aggregate;
  /** fetch data from the table: "allocation" using primary key columns */
  allocation_by_pk?: Maybe<Allocation>;
  /** fetch data from the table: "allocation_log" */
  allocation_log: Array<Allocation_Log>;
  /** fetch aggregated fields from the table: "allocation_log" */
  allocation_log_aggregate: Allocation_Log_Aggregate;
  /** fetch data from the table: "allocation_log" using primary key columns */
  allocation_log_by_pk?: Maybe<Allocation_Log>;
  /** fetch data from the table: "allocation_type" */
  allocation_type: Array<Allocation_Type>;
  /** fetch aggregated fields from the table: "allocation_type" */
  allocation_type_aggregate: Allocation_Type_Aggregate;
  /** fetch data from the table: "allocation_type" using primary key columns */
  allocation_type_by_pk?: Maybe<Allocation_Type>;
  annature_get_envelope: AnnatureGetEnvelopeResp;
  /** Annature groups to be able to select in Digital Contract settings to style Annature Emails */
  annature_groups: AnnatureGroupsResp;
  annature_search_envelope: AnnatureSearchEnvelopeResp;
  annature_validate_envelope: AnnatureValidateEnvelopeResp;
  /** fetch data from the table: "apartment_plan_building" */
  apartment_plan_building: Array<Apartment_Plan_Building>;
  /** fetch aggregated fields from the table: "apartment_plan_building" */
  apartment_plan_building_aggregate: Apartment_Plan_Building_Aggregate;
  /** fetch data from the table: "apartment_plan_building" using primary key columns */
  apartment_plan_building_by_pk?: Maybe<Apartment_Plan_Building>;
  /** fetch data from the table: "apartment_plan_property" */
  apartment_plan_property: Array<Apartment_Plan_Property>;
  /** fetch aggregated fields from the table: "apartment_plan_property" */
  apartment_plan_property_aggregate: Apartment_Plan_Property_Aggregate;
  /** fetch data from the table: "apartment_plan_property" using primary key columns */
  apartment_plan_property_by_pk?: Maybe<Apartment_Plan_Property>;
  /** fetch data from the table: "area_plot" */
  area_plot: Array<Area_Plot>;
  /** fetch aggregated fields from the table: "area_plot" */
  area_plot_aggregate: Area_Plot_Aggregate;
  /** fetch data from the table: "area_plot" using primary key columns */
  area_plot_by_pk?: Maybe<Area_Plot>;
  /** fetch data from the table: "area_plot_type" */
  area_plot_type: Array<Area_Plot_Type>;
  /** fetch aggregated fields from the table: "area_plot_type" */
  area_plot_type_aggregate: Area_Plot_Type_Aggregate;
  /** fetch data from the table: "area_plot_type" using primary key columns */
  area_plot_type_by_pk?: Maybe<Area_Plot_Type>;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** An array relationship */
  attachment_config: Array<Attachment_Config>;
  /** An aggregate relationship */
  attachment_config_aggregate: Attachment_Config_Aggregate;
  /** fetch data from the table: "attachment_config" using primary key columns */
  attachment_config_by_pk?: Maybe<Attachment_Config>;
  /** fetch data from the table: "attachment_edit_project_order" */
  attachment_edit_project_order: Array<Attachment_Edit_Project_Order>;
  /** fetch aggregated fields from the table: "attachment_edit_project_order" */
  attachment_edit_project_order_aggregate: Attachment_Edit_Project_Order_Aggregate;
  /** fetch data from the table: "attachment_edit_project_order" using primary key columns */
  attachment_edit_project_order_by_pk?: Maybe<Attachment_Edit_Project_Order>;
  /** fetch data from the table: "attachment_owner" */
  attachment_owner: Array<Attachment_Owner>;
  /** fetch aggregated fields from the table: "attachment_owner" */
  attachment_owner_aggregate: Attachment_Owner_Aggregate;
  /** fetch data from the table: "attachment_owner" using primary key columns */
  attachment_owner_by_pk?: Maybe<Attachment_Owner>;
  /** fetch data from the table: "attachment_product_type" */
  attachment_product_type: Array<Attachment_Product_Type>;
  /** fetch aggregated fields from the table: "attachment_product_type" */
  attachment_product_type_aggregate: Attachment_Product_Type_Aggregate;
  /** fetch data from the table: "attachment_product_type" using primary key columns */
  attachment_product_type_by_pk?: Maybe<Attachment_Product_Type>;
  /** fetch data from the table: "attachment_stage_order" */
  attachment_stage_order: Array<Attachment_Stage_Order>;
  /** fetch aggregated fields from the table: "attachment_stage_order" */
  attachment_stage_order_aggregate: Attachment_Stage_Order_Aggregate;
  /** fetch data from the table: "attachment_stage_order" using primary key columns */
  attachment_stage_order_by_pk?: Maybe<Attachment_Stage_Order>;
  /** fetch data from the table: "attachment_thumbnail" */
  attachment_thumbnail: Array<Attachment_Thumbnail>;
  /** fetch aggregated fields from the table: "attachment_thumbnail" */
  attachment_thumbnail_aggregate: Attachment_Thumbnail_Aggregate;
  /** fetch data from the table: "attachment_thumbnail" using primary key columns */
  attachment_thumbnail_by_pk?: Maybe<Attachment_Thumbnail>;
  /** fetch data from the table: "attachment_thumbnail_count" */
  attachment_thumbnail_count: Array<Attachment_Thumbnail_Count>;
  /** fetch aggregated fields from the table: "attachment_thumbnail_count" */
  attachment_thumbnail_count_aggregate: Attachment_Thumbnail_Count_Aggregate;
  /** fetch data from the table: "attachment_thumbnail_type" */
  attachment_thumbnail_type: Array<Attachment_Thumbnail_Type>;
  /** fetch aggregated fields from the table: "attachment_thumbnail_type" */
  attachment_thumbnail_type_aggregate: Attachment_Thumbnail_Type_Aggregate;
  /** fetch data from the table: "attachment_thumbnail_type" using primary key columns */
  attachment_thumbnail_type_by_pk?: Maybe<Attachment_Thumbnail_Type>;
  /** fetch data from the table: "attachment_type" */
  attachment_type: Array<Attachment_Type>;
  /** fetch aggregated fields from the table: "attachment_type" */
  attachment_type_aggregate: Attachment_Type_Aggregate;
  /** fetch data from the table: "attachment_type" using primary key columns */
  attachment_type_by_pk?: Maybe<Attachment_Type>;
  buyer_deal_contacts: Array<BuyerDealContactResp>;
  /** fetch data from the table: "buyer_portal" */
  buyer_portal: Array<Buyer_Portal>;
  /** fetch aggregated fields from the table: "buyer_portal" */
  buyer_portal_aggregate: Buyer_Portal_Aggregate;
  /** fetch data from the table: "buyer_portal" using primary key columns */
  buyer_portal_by_pk?: Maybe<Buyer_Portal>;
  /** fetch data from the table: "buyer_project" */
  buyer_project: Array<Buyer_Project>;
  /** fetch aggregated fields from the table: "buyer_project" */
  buyer_project_aggregate: Buyer_Project_Aggregate;
  /** fetch data from the table: "buyer_project" using primary key columns */
  buyer_project_by_pk?: Maybe<Buyer_Project>;
  buyer_property_detail: BuyerPropertyDetailResp;
  /** Return the list of property behalf of agent for buyer */
  buyer_property_list: Array<BuyerPropertyListResp>;
  /** fetch data from the table: "buyer_type" */
  buyer_type: Array<Buyer_Type>;
  /** fetch aggregated fields from the table: "buyer_type" */
  buyer_type_aggregate: Buyer_Type_Aggregate;
  /** fetch data from the table: "buyer_type" using primary key columns */
  buyer_type_by_pk?: Maybe<Buyer_Type>;
  /** fetch data from the table: "cache" */
  cache: Array<Cache>;
  /** fetch aggregated fields from the table: "cache" */
  cache_aggregate: Cache_Aggregate;
  /** fetch data from the table: "cache" using primary key columns */
  cache_by_pk?: Maybe<Cache>;
  /** fetch data from the table: "change_in" */
  change_in: Array<Change_In>;
  /** fetch aggregated fields from the table: "change_in" */
  change_in_aggregate: Change_In_Aggregate;
  /** fetch data from the table: "change_in" using primary key columns */
  change_in_by_pk?: Maybe<Change_In>;
  /** fetch data from the table: "change_status" */
  change_status: Array<Change_Status>;
  /** fetch aggregated fields from the table: "change_status" */
  change_status_aggregate: Change_Status_Aggregate;
  /** fetch data from the table: "change_status" using primary key columns */
  change_status_by_pk?: Maybe<Change_Status>;
  cloud_endpoint?: Maybe<CloudEndpoint>;
  /** fetch data from the table: "code" */
  code: Array<Code>;
  /** fetch aggregated fields from the table: "code" */
  code_aggregate: Code_Aggregate;
  /** fetch data from the table: "code" using primary key columns */
  code_by_pk?: Maybe<Code>;
  /** fetch data from the table: "commission" */
  commission: Array<Commission>;
  /** fetch aggregated fields from the table: "commission" */
  commission_aggregate: Commission_Aggregate;
  /** fetch data from the table: "commission" using primary key columns */
  commission_by_pk?: Maybe<Commission>;
  /** fetch data from the table: "commission_type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission_type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission_type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** fetch data from the table: "contact_type" */
  contact_type: Array<Contact_Type>;
  /** fetch aggregated fields from the table: "contact_type" */
  contact_type_aggregate: Contact_Type_Aggregate;
  /** fetch data from the table: "contact_type" using primary key columns */
  contact_type_by_pk?: Maybe<Contact_Type>;
  /** fetch data from the table: "count_with_rolling_avg" */
  count_with_rolling_avg: Array<Count_With_Rolling_Avg>;
  /** fetch aggregated fields from the table: "count_with_rolling_avg" */
  count_with_rolling_avg_aggregate: Count_With_Rolling_Avg_Aggregate;
  /** fetch data from the table: "count_with_rolling_avg" using primary key columns */
  count_with_rolling_avg_by_pk?: Maybe<Count_With_Rolling_Avg>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "crm_connection" */
  crm_connection: Array<Crm_Connection>;
  /** fetch aggregated fields from the table: "crm_connection" */
  crm_connection_aggregate: Crm_Connection_Aggregate;
  /** fetch data from the table: "crm_connection" using primary key columns */
  crm_connection_by_pk?: Maybe<Crm_Connection>;
  cypress_login?: Maybe<CypressLoginResponse>;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "deal_contact" */
  deal_contact: Array<Deal_Contact>;
  /** fetch aggregated fields from the table: "deal_contact" */
  deal_contact_aggregate: Deal_Contact_Aggregate;
  /** fetch data from the table: "deal_contact" using primary key columns */
  deal_contact_by_pk?: Maybe<Deal_Contact>;
  /** fetch data from the table: "deal_contact_status" */
  deal_contact_status: Array<Deal_Contact_Status>;
  /** fetch aggregated fields from the table: "deal_contact_status" */
  deal_contact_status_aggregate: Deal_Contact_Status_Aggregate;
  /** fetch data from the table: "deal_contact_status" using primary key columns */
  deal_contact_status_by_pk?: Maybe<Deal_Contact_Status>;
  deal_contacts: Array<DealContactResp>;
  /** fetch data from the table: "deal_deposit_status" */
  deal_deposit_status: Array<Deal_Deposit_Status>;
  /** fetch aggregated fields from the table: "deal_deposit_status" */
  deal_deposit_status_aggregate: Deal_Deposit_Status_Aggregate;
  /** fetch data from the table: "deal_deposit_status" using primary key columns */
  deal_deposit_status_by_pk?: Maybe<Deal_Deposit_Status>;
  /** fetch data from the table: "deal_deposit_type" */
  deal_deposit_type: Array<Deal_Deposit_Type>;
  /** fetch aggregated fields from the table: "deal_deposit_type" */
  deal_deposit_type_aggregate: Deal_Deposit_Type_Aggregate;
  /** fetch data from the table: "deal_deposit_type" using primary key columns */
  deal_deposit_type_by_pk?: Maybe<Deal_Deposit_Type>;
  /** fetch data from the table: "deal_enquiry_source" */
  deal_enquiry_source: Array<Deal_Enquiry_Source>;
  /** fetch aggregated fields from the table: "deal_enquiry_source" */
  deal_enquiry_source_aggregate: Deal_Enquiry_Source_Aggregate;
  /** fetch data from the table: "deal_enquiry_source" using primary key columns */
  deal_enquiry_source_by_pk?: Maybe<Deal_Enquiry_Source>;
  /** fetch data from the table: "deal_fallen_reason" */
  deal_fallen_reason: Array<Deal_Fallen_Reason>;
  /** fetch aggregated fields from the table: "deal_fallen_reason" */
  deal_fallen_reason_aggregate: Deal_Fallen_Reason_Aggregate;
  /** fetch data from the table: "deal_fallen_reason" using primary key columns */
  deal_fallen_reason_by_pk?: Maybe<Deal_Fallen_Reason>;
  /** fetch data from the table: "deal_payment_method" */
  deal_payment_method: Array<Deal_Payment_Method>;
  /** fetch aggregated fields from the table: "deal_payment_method" */
  deal_payment_method_aggregate: Deal_Payment_Method_Aggregate;
  /** fetch data from the table: "deal_payment_method" using primary key columns */
  deal_payment_method_by_pk?: Maybe<Deal_Payment_Method>;
  /** fetch data from the table: "deal_progress_update" */
  deal_progress_update: Array<Deal_Progress_Update>;
  /** fetch aggregated fields from the table: "deal_progress_update" */
  deal_progress_update_aggregate: Deal_Progress_Update_Aggregate;
  /** fetch data from the table: "deal_progress_update" using primary key columns */
  deal_progress_update_by_pk?: Maybe<Deal_Progress_Update>;
  /** fetch data from the table: "deal_reason_purchase" */
  deal_reason_purchase: Array<Deal_Reason_Purchase>;
  /** fetch aggregated fields from the table: "deal_reason_purchase" */
  deal_reason_purchase_aggregate: Deal_Reason_Purchase_Aggregate;
  /** fetch data from the table: "deal_reason_purchase" using primary key columns */
  deal_reason_purchase_by_pk?: Maybe<Deal_Reason_Purchase>;
  /** fetch data from the table: "deal_sf_action" */
  deal_sf_action: Array<Deal_Sf_Action>;
  /** fetch aggregated fields from the table: "deal_sf_action" */
  deal_sf_action_aggregate: Deal_Sf_Action_Aggregate;
  /** fetch data from the table: "deal_sf_action" using primary key columns */
  deal_sf_action_by_pk?: Maybe<Deal_Sf_Action>;
  /** fetch data from the table: "deal_spoken_language" */
  deal_spoken_language: Array<Deal_Spoken_Language>;
  /** fetch aggregated fields from the table: "deal_spoken_language" */
  deal_spoken_language_aggregate: Deal_Spoken_Language_Aggregate;
  /** fetch data from the table: "deal_spoken_language" using primary key columns */
  deal_spoken_language_by_pk?: Maybe<Deal_Spoken_Language>;
  /** fetch data from the table: "deal_status" */
  deal_status: Array<Deal_Status>;
  /** fetch aggregated fields from the table: "deal_status" */
  deal_status_aggregate: Deal_Status_Aggregate;
  /** fetch data from the table: "deal_status" using primary key columns */
  deal_status_by_pk?: Maybe<Deal_Status>;
  /** fetch data from the table: "deal_transfer_status" */
  deal_transfer_status: Array<Deal_Transfer_Status>;
  /** fetch aggregated fields from the table: "deal_transfer_status" */
  deal_transfer_status_aggregate: Deal_Transfer_Status_Aggregate;
  /** fetch data from the table: "deal_transfer_status" using primary key columns */
  deal_transfer_status_by_pk?: Maybe<Deal_Transfer_Status>;
  /** fetch data from the table: "deal_type" */
  deal_type: Array<Deal_Type>;
  /** fetch aggregated fields from the table: "deal_type" */
  deal_type_aggregate: Deal_Type_Aggregate;
  /** fetch data from the table: "deal_type" using primary key columns */
  deal_type_by_pk?: Maybe<Deal_Type>;
  /** fetch data from the table: "deal_valuation_booking" */
  deal_valuation_booking: Array<Deal_Valuation_Booking>;
  /** fetch aggregated fields from the table: "deal_valuation_booking" */
  deal_valuation_booking_aggregate: Deal_Valuation_Booking_Aggregate;
  /** fetch data from the table: "deal_valuation_booking" using primary key columns */
  deal_valuation_booking_by_pk?: Maybe<Deal_Valuation_Booking>;
  /** fetch data from the table: "deal_witness" */
  deal_witness: Array<Deal_Witness>;
  /** fetch aggregated fields from the table: "deal_witness" */
  deal_witness_aggregate: Deal_Witness_Aggregate;
  /** fetch data from the table: "deal_witness" using primary key columns */
  deal_witness_by_pk?: Maybe<Deal_Witness>;
  /** fetch data from the table: "developer_agent" */
  developer_agent: Array<Developer_Agent>;
  /** fetch aggregated fields from the table: "developer_agent" */
  developer_agent_aggregate: Developer_Agent_Aggregate;
  /** fetch data from the table: "developer_agent" using primary key columns */
  developer_agent_by_pk?: Maybe<Developer_Agent>;
  digital_contract_settings: DigitalContractSettingsResp;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "document_expired" */
  document_expired: Array<Document_Expired>;
  /** fetch aggregated fields from the table: "document_expired" */
  document_expired_aggregate: Document_Expired_Aggregate;
  /** fetch data from the table: "document_type" */
  document_type: Array<Document_Type>;
  /** fetch aggregated fields from the table: "document_type" */
  document_type_aggregate: Document_Type_Aggregate;
  /** fetch data from the table: "document_type" using primary key columns */
  document_type_by_pk?: Maybe<Document_Type>;
  /** fetch data from the table: "embed" */
  embed: Array<Embed>;
  /** fetch aggregated fields from the table: "embed" */
  embed_aggregate: Embed_Aggregate;
  /** fetch data from the table: "embed" using primary key columns */
  embed_by_pk?: Maybe<Embed>;
  /** fetch data from the table: "envelope" */
  envelope: Array<Envelope>;
  /** fetch aggregated fields from the table: "envelope" */
  envelope_aggregate: Envelope_Aggregate;
  /** fetch data from the table: "envelope" using primary key columns */
  envelope_by_pk?: Maybe<Envelope>;
  /** fetch data from the table: "envelope_owner" */
  envelope_owner: Array<Envelope_Owner>;
  /** fetch aggregated fields from the table: "envelope_owner" */
  envelope_owner_aggregate: Envelope_Owner_Aggregate;
  /** fetch data from the table: "envelope_owner" using primary key columns */
  envelope_owner_by_pk?: Maybe<Envelope_Owner>;
  /** fetch data from the table: "envelope_status" */
  envelope_status: Array<Envelope_Status>;
  /** fetch aggregated fields from the table: "envelope_status" */
  envelope_status_aggregate: Envelope_Status_Aggregate;
  /** fetch data from the table: "envelope_status" using primary key columns */
  envelope_status_by_pk?: Maybe<Envelope_Status>;
  /** fetch data from the table: "envelope_type" */
  envelope_type: Array<Envelope_Type>;
  /** fetch aggregated fields from the table: "envelope_type" */
  envelope_type_aggregate: Envelope_Type_Aggregate;
  /** fetch data from the table: "envelope_type" using primary key columns */
  envelope_type_by_pk?: Maybe<Envelope_Type>;
  /** fetch data from the table: "experiment" */
  experiment: Array<Experiment>;
  /** fetch aggregated fields from the table: "experiment" */
  experiment_aggregate: Experiment_Aggregate;
  /** fetch data from the table: "experiment" using primary key columns */
  experiment_by_pk?: Maybe<Experiment>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk?: Maybe<Feature>;
  /** fetch data from the table: "feature_check" */
  feature_check: Array<Feature_Check>;
  /** fetch aggregated fields from the table: "feature_check" */
  feature_check_aggregate: Feature_Check_Aggregate;
  /** fetch data from the table: "feature_check" using primary key columns */
  feature_check_by_pk?: Maybe<Feature_Check>;
  /** fetch data from the table: "feature_pack" */
  feature_pack: Array<Feature_Pack>;
  /** fetch aggregated fields from the table: "feature_pack" */
  feature_pack_aggregate: Feature_Pack_Aggregate;
  /** fetch data from the table: "feature_pack" using primary key columns */
  feature_pack_by_pk?: Maybe<Feature_Pack>;
  /** fetch data from the table: "feature_pack_feature" */
  feature_pack_feature: Array<Feature_Pack_Feature>;
  /** fetch aggregated fields from the table: "feature_pack_feature" */
  feature_pack_feature_aggregate: Feature_Pack_Feature_Aggregate;
  /** fetch data from the table: "feature_pack_feature" using primary key columns */
  feature_pack_feature_by_pk?: Maybe<Feature_Pack_Feature>;
  /** fetch data from the table: "filter_page" */
  filter_page: Array<Filter_Page>;
  /** fetch aggregated fields from the table: "filter_page" */
  filter_page_aggregate: Filter_Page_Aggregate;
  /** fetch data from the table: "filter_page" using primary key columns */
  filter_page_by_pk?: Maybe<Filter_Page>;
  /** fetch data from the table: "filter_state" */
  filter_state: Array<Filter_State>;
  /** fetch aggregated fields from the table: "filter_state" */
  filter_state_aggregate: Filter_State_Aggregate;
  /** fetch data from the table: "filter_state" using primary key columns */
  filter_state_by_pk?: Maybe<Filter_State>;
  /** fetch data from the table: "finance_status" */
  finance_status: Array<Finance_Status>;
  /** fetch aggregated fields from the table: "finance_status" */
  finance_status_aggregate: Finance_Status_Aggregate;
  /** fetch data from the table: "finance_status" using primary key columns */
  finance_status_by_pk?: Maybe<Finance_Status>;
  get_address_detail?: Maybe<GetAddressDetailOutput>;
  /** Get agencies for given agent Email */
  get_agencies_by_email: GetAgencyByEmailResp;
  get_attachment_upload_request: GetAttachmentUploadRequestResp;
  get_embed_code?: Maybe<GetEmbedCodeResponse>;
  get_invitation?: Maybe<GetInvitationResponse>;
  get_project_by_deal_Id: GetProjectByDealIdResponse;
  get_project_qr_code?: Maybe<GetProjectQrCodeResp>;
  get_tests: Array<GetTestsResp>;
  /** fetch data from the table: "graphql_query_log" */
  graphql_query_log: Array<Graphql_Query_Log>;
  /** fetch aggregated fields from the table: "graphql_query_log" */
  graphql_query_log_aggregate: Graphql_Query_Log_Aggregate;
  /** fetch data from the table: "graphql_query_log" using primary key columns */
  graphql_query_log_by_pk?: Maybe<Graphql_Query_Log>;
  /** fetch data from the table: "hubspot_action" */
  hubspot_action: Array<Hubspot_Action>;
  /** fetch aggregated fields from the table: "hubspot_action" */
  hubspot_action_aggregate: Hubspot_Action_Aggregate;
  /** fetch data from the table: "hubspot_action" using primary key columns */
  hubspot_action_by_pk?: Maybe<Hubspot_Action>;
  /** fetch data from the table: "hubspot_action_type" */
  hubspot_action_type: Array<Hubspot_Action_Type>;
  /** fetch aggregated fields from the table: "hubspot_action_type" */
  hubspot_action_type_aggregate: Hubspot_Action_Type_Aggregate;
  /** fetch data from the table: "hubspot_action_type" using primary key columns */
  hubspot_action_type_by_pk?: Maybe<Hubspot_Action_Type>;
  /** fetch data from the table: "hubspot_contact" */
  hubspot_contact: Array<Hubspot_Contact>;
  /** fetch aggregated fields from the table: "hubspot_contact" */
  hubspot_contact_aggregate: Hubspot_Contact_Aggregate;
  /** fetch data from the table: "hubspot_contact" using primary key columns */
  hubspot_contact_by_pk?: Maybe<Hubspot_Contact>;
  /** fetch data from the table: "hubspot_deal" */
  hubspot_deal: Array<Hubspot_Deal>;
  /** fetch aggregated fields from the table: "hubspot_deal" */
  hubspot_deal_aggregate: Hubspot_Deal_Aggregate;
  /** fetch data from the table: "hubspot_deal" using primary key columns */
  hubspot_deal_by_pk?: Maybe<Hubspot_Deal>;
  /** fetch data from the table: "hubspot_organisation" */
  hubspot_organisation: Array<Hubspot_Organisation>;
  /** fetch aggregated fields from the table: "hubspot_organisation" */
  hubspot_organisation_aggregate: Hubspot_Organisation_Aggregate;
  /** fetch data from the table: "hubspot_organisation" using primary key columns */
  hubspot_organisation_by_pk?: Maybe<Hubspot_Organisation>;
  /** fetch data from the table: "hubspot_project" */
  hubspot_project: Array<Hubspot_Project>;
  /** fetch aggregated fields from the table: "hubspot_project" */
  hubspot_project_aggregate: Hubspot_Project_Aggregate;
  /** fetch data from the table: "hubspot_project" using primary key columns */
  hubspot_project_by_pk?: Maybe<Hubspot_Project>;
  /** fetch data from the table: "hubspot_schema_error_log" */
  hubspot_schema_error_log: Array<Hubspot_Schema_Error_Log>;
  /** fetch aggregated fields from the table: "hubspot_schema_error_log" */
  hubspot_schema_error_log_aggregate: Hubspot_Schema_Error_Log_Aggregate;
  /** fetch data from the table: "hubspot_schema_error_log" using primary key columns */
  hubspot_schema_error_log_by_pk?: Maybe<Hubspot_Schema_Error_Log>;
  /** fetch data from the table: "hubspot_user" */
  hubspot_user: Array<Hubspot_User>;
  /** fetch aggregated fields from the table: "hubspot_user" */
  hubspot_user_aggregate: Hubspot_User_Aggregate;
  /** fetch data from the table: "hubspot_user" using primary key columns */
  hubspot_user_by_pk?: Maybe<Hubspot_User>;
  /** fetch data from the table: "hubspot_valuation_booking" */
  hubspot_valuation_booking: Array<Hubspot_Valuation_Booking>;
  /** fetch aggregated fields from the table: "hubspot_valuation_booking" */
  hubspot_valuation_booking_aggregate: Hubspot_Valuation_Booking_Aggregate;
  /** fetch data from the table: "hubspot_valuation_booking" using primary key columns */
  hubspot_valuation_booking_by_pk?: Maybe<Hubspot_Valuation_Booking>;
  impersonate_user?: Maybe<ImpersonateTokenResponse>;
  /** fetch data from the table: "invitation" */
  invitation: Array<Invitation>;
  /** fetch aggregated fields from the table: "invitation" */
  invitation_aggregate: Invitation_Aggregate;
  /** fetch data from the table: "invitation" using primary key columns */
  invitation_by_pk?: Maybe<Invitation>;
  /** fetch data from the table: "invitation_status" */
  invitation_status: Array<Invitation_Status>;
  /** fetch aggregated fields from the table: "invitation_status" */
  invitation_status_aggregate: Invitation_Status_Aggregate;
  /** fetch data from the table: "invitation_status" using primary key columns */
  invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** fetch data from the table: "invitation_type" */
  invitation_type: Array<Invitation_Type>;
  /** fetch aggregated fields from the table: "invitation_type" */
  invitation_type_aggregate: Invitation_Type_Aggregate;
  /** fetch data from the table: "invitation_type" using primary key columns */
  invitation_type_by_pk?: Maybe<Invitation_Type>;
  /** Given Phone number or Email, detects if this belongs to existing user or not. */
  is_existing_user: IsExistingUserResp;
  /** fetch data from the table: "lambda_log" */
  lambda_log: Array<Lambda_Log>;
  /** fetch aggregated fields from the table: "lambda_log" */
  lambda_log_aggregate: Lambda_Log_Aggregate;
  /** fetch data from the table: "lambda_log" using primary key columns */
  lambda_log_by_pk?: Maybe<Lambda_Log>;
  /** fetch data from the table: "lead_generation" */
  lead_generation: Array<Lead_Generation>;
  /** fetch aggregated fields from the table: "lead_generation" */
  lead_generation_aggregate: Lead_Generation_Aggregate;
  /** fetch data from the table: "lead_generation" using primary key columns */
  lead_generation_by_pk?: Maybe<Lead_Generation>;
  /** fetch data from the table: "lead_generation_source" */
  lead_generation_source: Array<Lead_Generation_Source>;
  /** fetch aggregated fields from the table: "lead_generation_source" */
  lead_generation_source_aggregate: Lead_Generation_Source_Aggregate;
  /** fetch data from the table: "lead_generation_source" using primary key columns */
  lead_generation_source_by_pk?: Maybe<Lead_Generation_Source>;
  /** fetch data from the table: "lead_generation_webhook_log" */
  lead_generation_webhook_log: Array<Lead_Generation_Webhook_Log>;
  /** fetch aggregated fields from the table: "lead_generation_webhook_log" */
  lead_generation_webhook_log_aggregate: Lead_Generation_Webhook_Log_Aggregate;
  /** fetch data from the table: "lead_generation_webhook_log" using primary key columns */
  lead_generation_webhook_log_by_pk?: Maybe<Lead_Generation_Webhook_Log>;
  link_login: LinkLoginResponse;
  /** fetch data from the table: "listing" */
  listing: Array<Listing>;
  /** fetch aggregated fields from the table: "listing" */
  listing_aggregate: Listing_Aggregate;
  /** fetch data from the table: "listing" using primary key columns */
  listing_by_pk?: Maybe<Listing>;
  /** fetch data from the table: "listing_price_per_sqmt" */
  listing_price_per_sqmt: Array<Listing_Price_Per_Sqmt>;
  /** fetch aggregated fields from the table: "listing_price_per_sqmt" */
  listing_price_per_sqmt_aggregate: Listing_Price_Per_Sqmt_Aggregate;
  /** fetch data from the table: "listing_publish_errors" */
  listing_publish_errors: Array<Listing_Publish_Errors>;
  /** fetch aggregated fields from the table: "listing_publish_errors" */
  listing_publish_errors_aggregate: Listing_Publish_Errors_Aggregate;
  /** fetch data from the table: "listing_publish_errors" using primary key columns */
  listing_publish_errors_by_pk?: Maybe<Listing_Publish_Errors>;
  /** fetch data from the table: "listing_publish_portal_source" */
  listing_publish_portal_source: Array<Listing_Publish_Portal_Source>;
  /** fetch aggregated fields from the table: "listing_publish_portal_source" */
  listing_publish_portal_source_aggregate: Listing_Publish_Portal_Source_Aggregate;
  /** fetch data from the table: "listing_publish_portal_source" using primary key columns */
  listing_publish_portal_source_by_pk?: Maybe<Listing_Publish_Portal_Source>;
  /** fetch data from the table: "listing_publish_status" */
  listing_publish_status: Array<Listing_Publish_Status>;
  /** fetch aggregated fields from the table: "listing_publish_status" */
  listing_publish_status_aggregate: Listing_Publish_Status_Aggregate;
  /** fetch data from the table: "listing_publish_status" using primary key columns */
  listing_publish_status_by_pk?: Maybe<Listing_Publish_Status>;
  /** fetch data from the table: "listing_publish_status_type" */
  listing_publish_status_type: Array<Listing_Publish_Status_Type>;
  /** fetch aggregated fields from the table: "listing_publish_status_type" */
  listing_publish_status_type_aggregate: Listing_Publish_Status_Type_Aggregate;
  /** fetch data from the table: "listing_publish_status_type" using primary key columns */
  listing_publish_status_type_by_pk?: Maybe<Listing_Publish_Status_Type>;
  /** fetch data from the table: "listing_status" */
  listing_status: Array<Listing_Status>;
  /** fetch aggregated fields from the table: "listing_status" */
  listing_status_aggregate: Listing_Status_Aggregate;
  /** fetch data from the table: "listing_status" using primary key columns */
  listing_status_by_pk?: Maybe<Listing_Status>;
  /** fetch data from the table: "member" */
  member: Array<Member>;
  /** fetch aggregated fields from the table: "member" */
  member_aggregate: Member_Aggregate;
  /** fetch data from the table: "member" using primary key columns */
  member_by_pk?: Maybe<Member>;
  /** fetch data from the table: "member_feature" */
  member_feature: Array<Member_Feature>;
  /** fetch aggregated fields from the table: "member_feature" */
  member_feature_aggregate: Member_Feature_Aggregate;
  /** fetch data from the table: "member_feature" using primary key columns */
  member_feature_by_pk?: Maybe<Member_Feature>;
  /** fetch data from the table: "member_project_allocate" */
  member_project_allocate: Array<Member_Project_Allocate>;
  /** fetch aggregated fields from the table: "member_project_allocate" */
  member_project_allocate_aggregate: Member_Project_Allocate_Aggregate;
  /** fetch data from the table: "member_project_allocate" using primary key columns */
  member_project_allocate_by_pk?: Maybe<Member_Project_Allocate>;
  /** fetch data from the table: "member_role" */
  member_role: Array<Member_Role>;
  /** fetch aggregated fields from the table: "member_role" */
  member_role_aggregate: Member_Role_Aggregate;
  /** fetch data from the table: "member_role" using primary key columns */
  member_role_by_pk?: Maybe<Member_Role>;
  mobile_code_verification?: Maybe<CodeVerificationResponse>;
  /** fetch data from the table: "nationality" */
  nationality: Array<Nationality>;
  /** fetch aggregated fields from the table: "nationality" */
  nationality_aggregate: Nationality_Aggregate;
  /** fetch data from the table: "nationality" using primary key columns */
  nationality_by_pk?: Maybe<Nationality>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_channel" */
  notification_channel: Array<Notification_Channel>;
  /** fetch aggregated fields from the table: "notification_channel" */
  notification_channel_aggregate: Notification_Channel_Aggregate;
  /** fetch data from the table: "notification_channel" using primary key columns */
  notification_channel_by_pk?: Maybe<Notification_Channel>;
  /** fetch data from the table: "notification_owner" */
  notification_owner: Array<Notification_Owner>;
  /** fetch aggregated fields from the table: "notification_owner" */
  notification_owner_aggregate: Notification_Owner_Aggregate;
  /** fetch data from the table: "notification_owner" using primary key columns */
  notification_owner_by_pk?: Maybe<Notification_Owner>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** Search licence or company names by licence number company name or any other partial info you have */
  nsw_api_browse: NswApiBrowseResp;
  /** Get licence details from API.NSW given you have licenceId */
  nsw_api_details: NswApiDetailsResp;
  /** Authenticate with API.NSW and get 11 hour access token to be used in Authorization header sent to API.NSW */
  nsw_api_get_token: NswApiGetTokenResp;
  /** Verify licence number via API.NSW. invitationId must be specified when calling as an anonymous user */
  nsw_api_verify: NswApiVerifyResp;
  /** execute function "org_feature_check" which returns "feature_check" */
  org_feature_check: Array<Feature_Check>;
  /** execute function "org_feature_check" and query aggregates on result of table type "feature_check" */
  org_feature_check_aggregate: Feature_Check_Aggregate;
  /** fetch data from the table: "org_feature_enabled" */
  org_feature_enabled: Array<Org_Feature_Enabled>;
  /** fetch aggregated fields from the table: "org_feature_enabled" */
  org_feature_enabled_aggregate: Org_Feature_Enabled_Aggregate;
  /** An array relationship */
  org_feature_pack: Array<Org_Feature_Pack>;
  /** An aggregate relationship */
  org_feature_pack_aggregate: Org_Feature_Pack_Aggregate;
  /** fetch data from the table: "org_feature_pack" using primary key columns */
  org_feature_pack_by_pk?: Maybe<Org_Feature_Pack>;
  org_search: Array<OrgSearchResp>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation_allocation_counts_view" */
  organisation_allocation_counts_view: Array<Organisation_Allocation_Counts_View>;
  /** fetch aggregated fields from the table: "organisation_allocation_counts_view" */
  organisation_allocation_counts_view_aggregate: Organisation_Allocation_Counts_View_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table: "organisation_feature" */
  organisation_feature: Array<Organisation_Feature>;
  /** fetch aggregated fields from the table: "organisation_feature" */
  organisation_feature_aggregate: Organisation_Feature_Aggregate;
  /** fetch data from the table: "organisation_feature" using primary key columns */
  organisation_feature_by_pk?: Maybe<Organisation_Feature>;
  /** fetch data from the table: "organisation_type" */
  organisation_type: Array<Organisation_Type>;
  /** fetch aggregated fields from the table: "organisation_type" */
  organisation_type_aggregate: Organisation_Type_Aggregate;
  /** fetch data from the table: "organisation_type" using primary key columns */
  organisation_type_by_pk?: Maybe<Organisation_Type>;
  /** fetch data from the table: "partner_portal" */
  partner_portal: Array<Partner_Portal>;
  /** Check if given ABN exists and returns org_id and info whether org has any users or not. invitationId is required to authenticate for given action. As it's available for anonymous user. */
  partner_portal_abn_check: PartnerPortalAbnCheckResp;
  /** fetch aggregated fields from the table: "partner_portal" */
  partner_portal_aggregate: Partner_Portal_Aggregate;
  /** fetch data from the table: "partner_portal" using primary key columns */
  partner_portal_by_pk?: Maybe<Partner_Portal>;
  /** Checks if given login is already taken or not. login can be an Email or a Phone number. Can only be used with the valid invitationId which belongs to PARTNER_PORTAL invitation */
  partner_portal_is_login_taken: PartnerPortalIsLoginTakenResp;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table: "payment_method_type" */
  payment_method_type: Array<Payment_Method_Type>;
  /** fetch aggregated fields from the table: "payment_method_type" */
  payment_method_type_aggregate: Payment_Method_Type_Aggregate;
  /** fetch data from the table: "payment_method_type" using primary key columns */
  payment_method_type_by_pk?: Maybe<Payment_Method_Type>;
  /** fetch data from the table: "preference" */
  preference: Array<Preference>;
  /** fetch aggregated fields from the table: "preference" */
  preference_aggregate: Preference_Aggregate;
  /** fetch data from the table: "preference" using primary key columns */
  preference_by_pk?: Maybe<Preference>;
  /** fetch data from the table: "process" */
  process: Array<Process>;
  /** fetch aggregated fields from the table: "process" */
  process_aggregate: Process_Aggregate;
  /** fetch data from the table: "process" using primary key columns */
  process_by_pk?: Maybe<Process>;
  /** fetch data from the table: "process_status" */
  process_status: Array<Process_Status>;
  /** fetch aggregated fields from the table: "process_status" */
  process_status_aggregate: Process_Status_Aggregate;
  /** fetch data from the table: "process_status" using primary key columns */
  process_status_by_pk?: Maybe<Process_Status>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project_apartment_level" */
  project_apartment_level: Array<Project_Apartment_Level>;
  /** fetch aggregated fields from the table: "project_apartment_level" */
  project_apartment_level_aggregate: Project_Apartment_Level_Aggregate;
  /** fetch data from the table: "project_apartment_stat" */
  project_apartment_stat: Array<Project_Apartment_Stat>;
  /** fetch aggregated fields from the table: "project_apartment_stat" */
  project_apartment_stat_aggregate: Project_Apartment_Stat_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_client_count" */
  project_client_count: Array<Project_Client_Count>;
  /** fetch aggregated fields from the table: "project_client_count" */
  project_client_count_aggregate: Project_Client_Count_Aggregate;
  /** execute function "project_client_count_for_user" which returns "project_client_count" */
  project_client_count_for_user: Array<Project_Client_Count>;
  /** execute function "project_client_count_for_user" and query aggregates on result of table type "project_client_count" */
  project_client_count_for_user_aggregate: Project_Client_Count_Aggregate;
  /** fetch data from the table: "project_detail" */
  project_detail: Array<Project_Detail>;
  /** fetch aggregated fields from the table: "project_detail" */
  project_detail_aggregate: Project_Detail_Aggregate;
  /** fetch data from the table: "project_detail" using primary key columns */
  project_detail_by_pk?: Maybe<Project_Detail>;
  project_details: ProjectResponse;
  /** fetch data from the table: "project_features" */
  project_features: Array<Project_Features>;
  /** fetch aggregated fields from the table: "project_features" */
  project_features_aggregate: Project_Features_Aggregate;
  /** fetch data from the table: "project_features" using primary key columns */
  project_features_by_pk?: Maybe<Project_Features>;
  /** fetch data from the table: "project_payment_method" */
  project_payment_method: Array<Project_Payment_Method>;
  /** fetch aggregated fields from the table: "project_payment_method" */
  project_payment_method_aggregate: Project_Payment_Method_Aggregate;
  /** fetch data from the table: "project_payment_method" using primary key columns */
  project_payment_method_by_pk?: Maybe<Project_Payment_Method>;
  /** fetch data from the table: "project_qr_code_for_buyer" */
  project_qr_code_for_buyer: Array<Project_Qr_Code_For_Buyer>;
  /** fetch aggregated fields from the table: "project_qr_code_for_buyer" */
  project_qr_code_for_buyer_aggregate: Project_Qr_Code_For_Buyer_Aggregate;
  /** fetch data from the table: "project_qr_code_for_buyer" using primary key columns */
  project_qr_code_for_buyer_by_pk?: Maybe<Project_Qr_Code_For_Buyer>;
  /** fetch data from the table: "project_reservation_setting" */
  project_reservation_setting: Array<Project_Reservation_Setting>;
  /** fetch aggregated fields from the table: "project_reservation_setting" */
  project_reservation_setting_aggregate: Project_Reservation_Setting_Aggregate;
  /** fetch data from the table: "project_reservation_setting" using primary key columns */
  project_reservation_setting_by_pk?: Maybe<Project_Reservation_Setting>;
  /** fetch data from the table: "project_sources" */
  project_sources: Array<Project_Sources>;
  /** fetch aggregated fields from the table: "project_sources" */
  project_sources_aggregate: Project_Sources_Aggregate;
  /** fetch data from the table: "project_sources" using primary key columns */
  project_sources_by_pk?: Maybe<Project_Sources>;
  /** fetch data from the table: "project_status" */
  project_status: Array<Project_Status>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: Project_Status_Aggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<Project_Status>;
  /** fetch data from the table: "project_type" */
  project_type: Array<Project_Type>;
  /** fetch aggregated fields from the table: "project_type" */
  project_type_aggregate: Project_Type_Aggregate;
  /** fetch data from the table: "project_type" using primary key columns */
  project_type_by_pk?: Maybe<Project_Type>;
  /** fetch data from the table: "project_units" */
  project_units: Array<Project_Units>;
  /** fetch aggregated fields from the table: "project_units" */
  project_units_aggregate: Project_Units_Aggregate;
  /** execute function "project_units_for_user" which returns "project_units" */
  project_units_for_user: Array<Project_Units>;
  /** execute function "project_units_for_user" and query aggregates on result of table type "project_units" */
  project_units_for_user_aggregate: Project_Units_Aggregate;
  /** fetch data from the table: "property" */
  property: Array<Property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: Property_Aggregate;
  /** An array relationship */
  property_allocation: Array<Property_Allocation>;
  /** An aggregate relationship */
  property_allocation_aggregate: Property_Allocation_Aggregate;
  /** fetch data from the table: "property_allocation_count" */
  property_allocation_count: Array<Property_Allocation_Count>;
  /** fetch aggregated fields from the table: "property_allocation_count" */
  property_allocation_count_aggregate: Property_Allocation_Count_Aggregate;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<Property>;
  /** fetch data from the table: "property_completion_status" */
  property_completion_status: Array<Property_Completion_Status>;
  /** fetch aggregated fields from the table: "property_completion_status" */
  property_completion_status_aggregate: Property_Completion_Status_Aggregate;
  /** fetch data from the table: "property_completion_status" using primary key columns */
  property_completion_status_by_pk?: Maybe<Property_Completion_Status>;
  /** fetch data from the table: "property_detail" */
  property_detail: Array<Property_Detail>;
  /** fetch aggregated fields from the table: "property_detail" */
  property_detail_aggregate: Property_Detail_Aggregate;
  /** fetch data from the table: "property_group" */
  property_group: Array<Property_Group>;
  /** fetch aggregated fields from the table: "property_group" */
  property_group_aggregate: Property_Group_Aggregate;
  /** fetch data from the table: "property_group_allocation_detail_view" */
  property_group_allocation_detail_view: Array<Property_Group_Allocation_Detail_View>;
  /** fetch aggregated fields from the table: "property_group_allocation_detail_view" */
  property_group_allocation_detail_view_aggregate: Property_Group_Allocation_Detail_View_Aggregate;
  /** fetch data from the table: "property_group_allocation_view" */
  property_group_allocation_view: Array<Property_Group_Allocation_View>;
  /** fetch aggregated fields from the table: "property_group_allocation_view" */
  property_group_allocation_view_aggregate: Property_Group_Allocation_View_Aggregate;
  /** fetch data from the table: "property_group" using primary key columns */
  property_group_by_pk?: Maybe<Property_Group>;
  /** fetch data from the table: "property_group_completion_status" */
  property_group_completion_status: Array<Property_Group_Completion_Status>;
  /** fetch aggregated fields from the table: "property_group_completion_status" */
  property_group_completion_status_aggregate: Property_Group_Completion_Status_Aggregate;
  /** fetch data from the table: "property_group_completion_status" using primary key columns */
  property_group_completion_status_by_pk?: Maybe<Property_Group_Completion_Status>;
  /** fetch data from the table: "property_group_type" */
  property_group_type: Array<Property_Group_Type>;
  /** fetch aggregated fields from the table: "property_group_type" */
  property_group_type_aggregate: Property_Group_Type_Aggregate;
  /** fetch data from the table: "property_group_type" using primary key columns */
  property_group_type_by_pk?: Maybe<Property_Group_Type>;
  /** fetch data from the table: "property_type" */
  property_type: Array<Property_Type>;
  /** fetch aggregated fields from the table: "property_type" */
  property_type_aggregate: Property_Type_Aggregate;
  /** fetch data from the table: "property_type" using primary key columns */
  property_type_by_pk?: Maybe<Property_Type>;
  resend_email_verification_link?: Maybe<MailResponse>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  reset_password?: Maybe<MailSentResponse>;
  /** fetch data from the table: "salesforce_action" */
  salesforce_action: Array<Salesforce_Action>;
  /** fetch aggregated fields from the table: "salesforce_action" */
  salesforce_action_aggregate: Salesforce_Action_Aggregate;
  /** fetch data from the table: "salesforce_action" using primary key columns */
  salesforce_action_by_pk?: Maybe<Salesforce_Action>;
  /** fetch data from the table: "salesforce_action_status" */
  salesforce_action_status: Array<Salesforce_Action_Status>;
  /** fetch aggregated fields from the table: "salesforce_action_status" */
  salesforce_action_status_aggregate: Salesforce_Action_Status_Aggregate;
  /** fetch data from the table: "salesforce_action_status" using primary key columns */
  salesforce_action_status_by_pk?: Maybe<Salesforce_Action_Status>;
  /** fetch data from the table: "salesforce_bulk_import" */
  salesforce_bulk_import: Array<Salesforce_Bulk_Import>;
  /** fetch aggregated fields from the table: "salesforce_bulk_import" */
  salesforce_bulk_import_aggregate: Salesforce_Bulk_Import_Aggregate;
  /** fetch data from the table: "salesforce_bulk_import" using primary key columns */
  salesforce_bulk_import_by_pk?: Maybe<Salesforce_Bulk_Import>;
  /** fetch data from the table: "salesforce_connection" */
  salesforce_connection: Array<Salesforce_Connection>;
  /** fetch aggregated fields from the table: "salesforce_connection" */
  salesforce_connection_aggregate: Salesforce_Connection_Aggregate;
  /** fetch data from the table: "salesforce_connection" using primary key columns */
  salesforce_connection_by_pk?: Maybe<Salesforce_Connection>;
  salesforce_sync_stat?: Maybe<SalesforceSyncStatResponse>;
  /** fetch data from the table: "salesforce_sync_status" */
  salesforce_sync_status: Array<Salesforce_Sync_Status>;
  /** fetch aggregated fields from the table: "salesforce_sync_status" */
  salesforce_sync_status_aggregate: Salesforce_Sync_Status_Aggregate;
  /** fetch data from the table: "salesforce_sync_status" using primary key columns */
  salesforce_sync_status_by_pk?: Maybe<Salesforce_Sync_Status>;
  send_email_verification_link?: Maybe<MailResponse>;
  send_reset_password_link?: Maybe<MailSentResponse>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** fetch data from the table: "sf_action" */
  sf_action: Array<Sf_Action>;
  /** fetch aggregated fields from the table: "sf_action" */
  sf_action_aggregate: Sf_Action_Aggregate;
  /** fetch data from the table: "sf_action" using primary key columns */
  sf_action_by_pk?: Maybe<Sf_Action>;
  /** fetch data from the table: "sf_contact" */
  sf_contact: Array<Sf_Contact>;
  /** fetch aggregated fields from the table: "sf_contact" */
  sf_contact_aggregate: Sf_Contact_Aggregate;
  /** fetch data from the table: "sf_contact" using primary key columns */
  sf_contact_by_pk?: Maybe<Sf_Contact>;
  /** fetch data from the table: "sf_organisation" */
  sf_organisation: Array<Sf_Organisation>;
  /** fetch aggregated fields from the table: "sf_organisation" */
  sf_organisation_aggregate: Sf_Organisation_Aggregate;
  /** fetch data from the table: "sf_organisation" using primary key columns */
  sf_organisation_by_pk?: Maybe<Sf_Organisation>;
  solicitor_orgs: Array<Solicitor_Orgs>;
  /** fetch data from the table: "spoken_language" */
  spoken_language: Array<Spoken_Language>;
  /** fetch aggregated fields from the table: "spoken_language" */
  spoken_language_aggregate: Spoken_Language_Aggregate;
  /** fetch data from the table: "spoken_language" using primary key columns */
  spoken_language_by_pk?: Maybe<Spoken_Language>;
  /** fetch data from the table: "system" */
  system: Array<System>;
  /** fetch aggregated fields from the table: "system" */
  system_aggregate: System_Aggregate;
  /** fetch data from the table: "system" using primary key columns */
  system_by_pk?: Maybe<System>;
  token_verification_login: TokenVerificationLoginResp;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table: "transaction_status" */
  transaction_status: Array<Transaction_Status>;
  /** fetch aggregated fields from the table: "transaction_status" */
  transaction_status_aggregate: Transaction_Status_Aggregate;
  /** fetch data from the table: "transaction_status" using primary key columns */
  transaction_status_by_pk?: Maybe<Transaction_Status>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<Transaction_Type>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: Transaction_Type_Aggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<Transaction_Type>;
  twilio_messages?: Maybe<Array<TwilioMessagesResp>>;
  /** fetch data from the table: "unit_allocation_count" */
  unit_allocation_count: Array<Unit_Allocation_Count>;
  /** fetch aggregated fields from the table: "unit_allocation_count" */
  unit_allocation_count_aggregate: Unit_Allocation_Count_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** Returns app config for current user/team, frequently used values */
  user_app_config: UserAppConfigResp;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** execute function "user_created_count_and_rolling_avg" which returns "count_with_rolling_avg" */
  user_created_count_and_rolling_avg: Array<Count_With_Rolling_Avg>;
  /** execute function "user_created_count_and_rolling_avg" and query aggregates on result of table type "count_with_rolling_avg" */
  user_created_count_and_rolling_avg_aggregate: Count_With_Rolling_Avg_Aggregate;
  /** fetch data from the table: "user_experiment" */
  user_experiment: Array<User_Experiment>;
  /** fetch aggregated fields from the table: "user_experiment" */
  user_experiment_aggregate: User_Experiment_Aggregate;
  /** fetch data from the table: "user_experiment" using primary key columns */
  user_experiment_by_pk?: Maybe<User_Experiment>;
  /** fetch data from the table: "user_feature" */
  user_feature: Array<User_Feature>;
  /** fetch aggregated fields from the table: "user_feature" */
  user_feature_aggregate: User_Feature_Aggregate;
  /** fetch data from the table: "user_feature" using primary key columns */
  user_feature_by_pk?: Maybe<User_Feature>;
  /** fetch data from the table: "user_push_token" */
  user_push_token: Array<User_Push_Token>;
  /** fetch aggregated fields from the table: "user_push_token" */
  user_push_token_aggregate: User_Push_Token_Aggregate;
  /** fetch data from the table: "user_push_token" using primary key columns */
  user_push_token_by_pk?: Maybe<User_Push_Token>;
  /** fetch data from the table: "variation" */
  variation: Array<Variation>;
  /** fetch aggregated fields from the table: "variation" */
  variation_aggregate: Variation_Aggregate;
  /** fetch data from the table: "variation" using primary key columns */
  variation_by_pk?: Maybe<Variation>;
  /** fetch data from the table: "variation_category" */
  variation_category: Array<Variation_Category>;
  /** fetch aggregated fields from the table: "variation_category" */
  variation_category_aggregate: Variation_Category_Aggregate;
  /** fetch data from the table: "variation_category" using primary key columns */
  variation_category_by_pk?: Maybe<Variation_Category>;
  /** fetch data from the table: "variation_type" */
  variation_type: Array<Variation_Type>;
  /** fetch aggregated fields from the table: "variation_type" */
  variation_type_aggregate: Variation_Type_Aggregate;
  /** fetch data from the table: "variation_type" using primary key columns */
  variation_type_by_pk?: Maybe<Variation_Type>;
  /** fetch data from the table: "variation_type_category" */
  variation_type_category: Array<Variation_Type_Category>;
  /** fetch aggregated fields from the table: "variation_type_category" */
  variation_type_category_aggregate: Variation_Type_Category_Aggregate;
  /** fetch data from the table: "variation_type_category" using primary key columns */
  variation_type_category_by_pk?: Maybe<Variation_Type_Category>;
  verify_code: VerifyCodeResp;
  verify_mobile_number?: Maybe<VerifyMobileResponse>;
  /** fetch data from the table: "web_app_feature" */
  web_app_feature: Array<Web_App_Feature>;
  /** fetch aggregated fields from the table: "web_app_feature" */
  web_app_feature_aggregate: Web_App_Feature_Aggregate;
  /** fetch data from the table: "web_app_feature" using primary key columns */
  web_app_feature_by_pk?: Maybe<Web_App_Feature>;
  /** fetch data from the table: "websocket_log" */
  websocket_log: Array<Websocket_Log>;
  /** fetch aggregated fields from the table: "websocket_log" */
  websocket_log_aggregate: Websocket_Log_Aggregate;
  /** fetch data from the table: "websocket_log" using primary key columns */
  websocket_log_by_pk?: Maybe<Websocket_Log>;
};
export type Query_RootAbn_Acn_LookupArgs = {
  abn_or_acn: Scalars['String'];
  invitationId?: InputMaybe<Scalars['String']>;
};

export type Query_RootAcl_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};

export type Query_RootAcl_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};

export type Query_RootAcl_Allocation_EditArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Query_RootAcl_Allocation_Edit_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Query_RootAcl_Contact_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Contact_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Contact_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Contact_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Contact_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Contact_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Deal_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Deal_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Deal_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Deal_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Deal_User_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Query_RootAcl_Deal_User_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Query_RootAcl_Deal_User_Team_DescArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Desc_Bool_Exp>;
};

export type Query_RootAcl_Deal_User_Team_Desc_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Desc_Bool_Exp>;
};

export type Query_RootAcl_Listing_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Listing_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Listing_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_DescArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Desc_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_Desc_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Desc_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_Team_DescArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Desc_Bool_Exp>;
};

export type Query_RootAcl_Listing_Property_Team_Desc_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Desc_Bool_Exp>;
};

export type Query_RootAcl_Listing_Via_Listing_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Via_Listing_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Via_Stage_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Listing_Via_Stage_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Org_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Org_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Org_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Org_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type Query_RootAcl_Org_Feature_EnabledArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type Query_RootAcl_Org_Feature_Enabled_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type Query_RootAcl_Org_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Org_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type Query_RootAcl_Property_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type Query_RootAcl_Property_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type Query_RootAcl_Property_Allocation_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
};

export type Query_RootAcl_Property_Allocation_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
};

export type Query_RootAcl_Stage_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_Stage_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Query_RootAcl_UpdateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Bool_Exp>;
};

export type Query_RootAcl_Update_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Bool_Exp>;
};

export type Query_RootAcl_Update_By_PkArgs = {
  acl: Scalars['String'];
};

export type Query_RootAcl_Update_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Status_Bool_Exp>;
};

export type Query_RootAcl_Update_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Status_Bool_Exp>;
};

export type Query_RootAcl_Update_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAddress_Auto_CompleteArgs = {
  address: AddressAutoCompleteInput;
};

export type Query_RootAgency_AgreementArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agency_Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type Query_RootAgency_Agreement_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agency_Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type Query_RootAgency_Agreement_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAgreementArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type Query_RootAgreement_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type Query_RootAgreement_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Query_RootAllocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Query_RootAllocation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAllocation_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Log_Bool_Exp>;
};

export type Query_RootAllocation_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Log_Bool_Exp>;
};

export type Query_RootAllocation_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAllocation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Type_Bool_Exp>;
};

export type Query_RootAllocation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Type_Bool_Exp>;
};

export type Query_RootAllocation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAnnature_Get_EnvelopeArgs = {
  id: Scalars['String'];
};

export type Query_RootAnnature_Search_EnvelopeArgs = {
  startsWith: Scalars['String'];
};

export type Query_RootAnnature_Validate_EnvelopeArgs = {
  envelope_id: Scalars['String'];
  property_group_id: Scalars['uuid'];
};

export type Query_RootApartment_Plan_BuildingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Building_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Building_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
};

export type Query_RootApartment_Plan_Building_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Building_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Building_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
};

export type Query_RootApartment_Plan_Building_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootApartment_Plan_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
};

export type Query_RootApartment_Plan_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
};

export type Query_RootApartment_Plan_Property_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootArea_PlotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Query_RootArea_Plot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Query_RootArea_Plot_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootArea_Plot_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Type_Bool_Exp>;
};

export type Query_RootArea_Plot_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Type_Bool_Exp>;
};

export type Query_RootArea_Plot_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAttachmentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Query_RootAttachment_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Query_RootAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAttachment_ConfigArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Config_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type Query_RootAttachment_Config_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Config_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type Query_RootAttachment_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAttachment_Edit_Project_OrderArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Edit_Project_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Edit_Project_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
};

export type Query_RootAttachment_Edit_Project_Order_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Edit_Project_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Edit_Project_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
};

export type Query_RootAttachment_Edit_Project_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAttachment_OwnerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Owner_Bool_Exp>;
};

export type Query_RootAttachment_Owner_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Owner_Bool_Exp>;
};

export type Query_RootAttachment_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAttachment_Product_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Product_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type Query_RootAttachment_Product_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Product_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type Query_RootAttachment_Product_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAttachment_Stage_OrderArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Stage_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Stage_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
};

export type Query_RootAttachment_Stage_Order_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Stage_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Stage_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
};

export type Query_RootAttachment_Stage_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAttachment_ThumbnailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

export type Query_RootAttachment_Thumbnail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

export type Query_RootAttachment_Thumbnail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAttachment_Thumbnail_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Count_Bool_Exp>;
};

export type Query_RootAttachment_Thumbnail_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Count_Bool_Exp>;
};

export type Query_RootAttachment_Thumbnail_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
};

export type Query_RootAttachment_Thumbnail_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
};

export type Query_RootAttachment_Thumbnail_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAttachment_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Type_Bool_Exp>;
};

export type Query_RootAttachment_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Type_Bool_Exp>;
};

export type Query_RootAttachment_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootBuyer_Deal_ContactsArgs = {
  dealId: Scalars['String'];
};

export type Query_RootBuyer_PortalArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Portal_Bool_Exp>;
};

export type Query_RootBuyer_Portal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Portal_Bool_Exp>;
};

export type Query_RootBuyer_Portal_By_PkArgs = {
  deal_id: Scalars['uuid'];
};

export type Query_RootBuyer_ProjectArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type Query_RootBuyer_Project_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type Query_RootBuyer_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootBuyer_Property_DetailArgs = {
  input: BuyerPropertyDetailInput;
};

export type Query_RootBuyer_Property_ListArgs = {
  input: BuyerPropertyListInput;
};

export type Query_RootBuyer_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Type_Bool_Exp>;
};

export type Query_RootBuyer_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Type_Bool_Exp>;
};

export type Query_RootBuyer_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCacheArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Cache_Bool_Exp>;
};

export type Query_RootCache_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Cache_Bool_Exp>;
};

export type Query_RootCache_By_PkArgs = {
  key: Scalars['String'];
};

export type Query_RootChange_InArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_In_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_In_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_In_Bool_Exp>;
};

export type Query_RootChange_In_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_In_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_In_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_In_Bool_Exp>;
};

export type Query_RootChange_In_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChange_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_Status_Bool_Exp>;
};

export type Query_RootChange_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_Status_Bool_Exp>;
};

export type Query_RootChange_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCodeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Code_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Code_Bool_Exp>;
};

export type Query_RootCode_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Code_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Code_Bool_Exp>;
};

export type Query_RootCode_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCommissionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type Query_RootCommission_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type Query_RootCommission_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCommission_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

export type Query_RootCommission_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

export type Query_RootCommission_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Query_RootContact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Query_RootContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootContact_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

export type Query_RootContact_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

export type Query_RootContact_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCount_With_Rolling_AvgArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Query_RootCount_With_Rolling_Avg_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Query_RootCount_With_Rolling_Avg_By_PkArgs = {
  date_start: Scalars['date'];
};

export type Query_RootCountryArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Country_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Query_RootCountry_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Country_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Query_RootCountry_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCrm_ConnectionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Crm_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Crm_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Crm_Connection_Bool_Exp>;
};

export type Query_RootCrm_Connection_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Crm_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Crm_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Crm_Connection_Bool_Exp>;
};

export type Query_RootCrm_Connection_By_PkArgs = {
  sf_instance_id: Scalars['String'];
};

export type Query_RootCypress_LoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Query_RootDealArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Query_RootDeal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Query_RootDeal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeal_ContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type Query_RootDeal_Contact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type Query_RootDeal_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeal_Contact_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
};

export type Query_RootDeal_Contact_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
};

export type Query_RootDeal_Contact_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_ContactsArgs = {
  dealId: Scalars['String'];
};

export type Query_RootDeal_Deposit_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
};

export type Query_RootDeal_Deposit_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
};

export type Query_RootDeal_Deposit_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Deposit_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
};

export type Query_RootDeal_Deposit_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
};

export type Query_RootDeal_Deposit_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Enquiry_SourceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Enquiry_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Enquiry_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
};

export type Query_RootDeal_Enquiry_Source_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Enquiry_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Enquiry_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
};

export type Query_RootDeal_Enquiry_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Fallen_ReasonArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Fallen_Reason_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Fallen_Reason_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
};

export type Query_RootDeal_Fallen_Reason_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Fallen_Reason_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Fallen_Reason_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
};

export type Query_RootDeal_Fallen_Reason_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Payment_MethodArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
};

export type Query_RootDeal_Payment_Method_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
};

export type Query_RootDeal_Payment_Method_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Progress_UpdateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Progress_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Progress_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
};

export type Query_RootDeal_Progress_Update_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Progress_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Progress_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
};

export type Query_RootDeal_Progress_Update_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Reason_PurchaseArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Reason_Purchase_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Reason_Purchase_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
};

export type Query_RootDeal_Reason_Purchase_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Reason_Purchase_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Reason_Purchase_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
};

export type Query_RootDeal_Reason_Purchase_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Sf_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
};

export type Query_RootDeal_Sf_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
};

export type Query_RootDeal_Sf_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeal_Spoken_LanguageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type Query_RootDeal_Spoken_Language_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type Query_RootDeal_Spoken_Language_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeal_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Status_Bool_Exp>;
};

export type Query_RootDeal_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Status_Bool_Exp>;
};

export type Query_RootDeal_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Transfer_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Transfer_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Transfer_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
};

export type Query_RootDeal_Transfer_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Transfer_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Transfer_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
};

export type Query_RootDeal_Transfer_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Type_Bool_Exp>;
};

export type Query_RootDeal_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Type_Bool_Exp>;
};

export type Query_RootDeal_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDeal_Valuation_BookingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
};

export type Query_RootDeal_Valuation_Booking_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
};

export type Query_RootDeal_Valuation_Booking_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeal_WitnessArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Witness_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type Query_RootDeal_Witness_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Witness_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type Query_RootDeal_Witness_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeveloper_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type Query_RootDeveloper_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type Query_RootDeveloper_Agent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDigital_Contract_SettingsArgs = {
  property_group_id: Scalars['uuid'];
};

export type Query_RootDocumentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type Query_RootDocument_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type Query_RootDocument_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDocument_ExpiredArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Expired_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Expired_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Expired_Bool_Exp>;
};

export type Query_RootDocument_Expired_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Expired_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Expired_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Expired_Bool_Exp>;
};

export type Query_RootDocument_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

export type Query_RootDocument_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

export type Query_RootDocument_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEmbedArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Embed_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Embed_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type Query_RootEmbed_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Embed_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Embed_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type Query_RootEmbed_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEnvelopeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Query_RootEnvelope_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Query_RootEnvelope_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEnvelope_OwnerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Owner_Bool_Exp>;
};

export type Query_RootEnvelope_Owner_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Owner_Bool_Exp>;
};

export type Query_RootEnvelope_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnvelope_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Status_Bool_Exp>;
};

export type Query_RootEnvelope_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Status_Bool_Exp>;
};

export type Query_RootEnvelope_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnvelope_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Type_Bool_Exp>;
};

export type Query_RootEnvelope_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Type_Bool_Exp>;
};

export type Query_RootEnvelope_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootExperimentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Query_RootExperiment_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Query_RootExperiment_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootFeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Query_RootFeature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Query_RootFeature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFeature_CheckArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Query_RootFeature_Check_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Query_RootFeature_Check_By_PkArgs = {
  result: Scalars['Boolean'];
};

export type Query_RootFeature_PackArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Bool_Exp>;
};

export type Query_RootFeature_Pack_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Bool_Exp>;
};

export type Query_RootFeature_Pack_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFeature_Pack_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Query_RootFeature_Pack_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Query_RootFeature_Pack_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFilter_PageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_Page_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_Page_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_Page_Bool_Exp>;
};

export type Query_RootFilter_Page_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_Page_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_Page_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_Page_Bool_Exp>;
};

export type Query_RootFilter_Page_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootFilter_StateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_State_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_State_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_State_Bool_Exp>;
};

export type Query_RootFilter_State_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_State_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_State_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_State_Bool_Exp>;
};

export type Query_RootFilter_State_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootFinance_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Finance_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Finance_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Finance_Status_Bool_Exp>;
};

export type Query_RootFinance_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Finance_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Finance_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Finance_Status_Bool_Exp>;
};

export type Query_RootFinance_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootGet_Address_DetailArgs = {
  address: GetAddressDetailInput;
};

export type Query_RootGet_Agencies_By_EmailArgs = {
  agentEmail: Scalars['String'];
};

export type Query_RootGet_Attachment_Upload_RequestArgs = {
  contentType: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Query_RootGet_Embed_CodeArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGet_InvitationArgs = {
  invitationId: Scalars['uuid'];
};

export type Query_RootGet_Project_By_Deal_IdArgs = {
  dealId: Scalars['String'];
};

export type Query_RootGet_Project_Qr_CodeArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGraphql_Query_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Graphql_Query_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Graphql_Query_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
};

export type Query_RootGraphql_Query_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Graphql_Query_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Graphql_Query_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
};

export type Query_RootGraphql_Query_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootHubspot_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Bool_Exp>;
};

export type Query_RootHubspot_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Bool_Exp>;
};

export type Query_RootHubspot_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_Action_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
};

export type Query_RootHubspot_Action_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
};

export type Query_RootHubspot_Action_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootHubspot_ContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type Query_RootHubspot_Contact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type Query_RootHubspot_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_DealArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type Query_RootHubspot_Deal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type Query_RootHubspot_Deal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_OrganisationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type Query_RootHubspot_Organisation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type Query_RootHubspot_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_ProjectArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type Query_RootHubspot_Project_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type Query_RootHubspot_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_Schema_Error_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Schema_Error_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Schema_Error_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
};

export type Query_RootHubspot_Schema_Error_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Schema_Error_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Schema_Error_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
};

export type Query_RootHubspot_Schema_Error_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_UserArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type Query_RootHubspot_User_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type Query_RootHubspot_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspot_Valuation_BookingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

export type Query_RootHubspot_Valuation_Booking_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

export type Query_RootHubspot_Valuation_Booking_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootImpersonate_UserArgs = {
  userId: Scalars['String'];
};

export type Query_RootInvitationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Query_RootInvitation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Query_RootInvitation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootInvitation_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Status_Bool_Exp>;
};

export type Query_RootInvitation_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Status_Bool_Exp>;
};

export type Query_RootInvitation_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootInvitation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Type_Bool_Exp>;
};

export type Query_RootInvitation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Type_Bool_Exp>;
};

export type Query_RootInvitation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootIs_Existing_UserArgs = {
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type Query_RootLambda_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lambda_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lambda_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lambda_Log_Bool_Exp>;
};

export type Query_RootLambda_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lambda_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lambda_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lambda_Log_Bool_Exp>;
};

export type Query_RootLambda_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLead_GenerationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Bool_Exp>;
};

export type Query_RootLead_Generation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Bool_Exp>;
};

export type Query_RootLead_Generation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLead_Generation_SourceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
};

export type Query_RootLead_Generation_Source_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
};

export type Query_RootLead_Generation_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootLead_Generation_Webhook_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Webhook_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

export type Query_RootLead_Generation_Webhook_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Webhook_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

export type Query_RootLead_Generation_Webhook_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLink_LoginArgs = {
  loginSecret: Scalars['String'];
};

export type Query_RootListingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Query_RootListing_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Query_RootListing_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootListing_Price_Per_SqmtArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Price_Per_Sqmt_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Price_Per_Sqmt_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
};

export type Query_RootListing_Price_Per_Sqmt_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Price_Per_Sqmt_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Price_Per_Sqmt_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
};

export type Query_RootListing_Publish_ErrorsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Errors_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Errors_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
};

export type Query_RootListing_Publish_Errors_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Errors_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Errors_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
};

export type Query_RootListing_Publish_Errors_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootListing_Publish_Portal_SourceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Portal_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Portal_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
};

export type Query_RootListing_Publish_Portal_Source_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Portal_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Portal_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
};

export type Query_RootListing_Publish_Portal_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootListing_Publish_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
};

export type Query_RootListing_Publish_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
};

export type Query_RootListing_Publish_Status_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootListing_Publish_Status_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
};

export type Query_RootListing_Publish_Status_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
};

export type Query_RootListing_Publish_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootListing_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Status_Bool_Exp>;
};

export type Query_RootListing_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Status_Bool_Exp>;
};

export type Query_RootListing_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootMemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type Query_RootMember_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type Query_RootMember_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMember_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type Query_RootMember_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type Query_RootMember_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMember_Project_AllocateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Query_RootMember_Project_Allocate_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Query_RootMember_Project_Allocate_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMember_RoleArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Role_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Role_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Role_Bool_Exp>;
};

export type Query_RootMember_Role_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Role_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Role_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Role_Bool_Exp>;
};

export type Query_RootMember_Role_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootMobile_Code_VerificationArgs = {
  code: Scalars['String'];
  mobileNumber: Scalars['String'];
};

export type Query_RootNationalityArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Nationality_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Nationality_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Nationality_Bool_Exp>;
};

export type Query_RootNationality_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Nationality_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Nationality_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Nationality_Bool_Exp>;
};

export type Query_RootNationality_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootNotificationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Query_RootNotification_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootNotification_ChannelArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Channel_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Channel_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Channel_Bool_Exp>;
};

export type Query_RootNotification_Channel_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Channel_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Channel_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Channel_Bool_Exp>;
};

export type Query_RootNotification_Channel_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootNotification_OwnerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Owner_Bool_Exp>;
};

export type Query_RootNotification_Owner_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Owner_Bool_Exp>;
};

export type Query_RootNotification_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootNotification_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Query_RootNotification_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Query_RootNotification_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootNsw_Api_BrowseArgs = {
  search: Scalars['String'];
};

export type Query_RootNsw_Api_DetailsArgs = {
  licenceId: Scalars['String'];
};

export type Query_RootNsw_Api_VerifyArgs = {
  invitationId?: InputMaybe<Scalars['String']>;
  licenceNumber: Scalars['String'];
};

export type Query_RootOrg_Feature_CheckArgs = {
  /** input parameters for function "org_feature_check" */
  args: Org_Feature_Check_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Query_RootOrg_Feature_Check_AggregateArgs = {
  /** input parameters for function "org_feature_check_aggregate" */
  args: Org_Feature_Check_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Query_RootOrg_Feature_EnabledArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type Query_RootOrg_Feature_Enabled_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type Query_RootOrg_Feature_PackArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type Query_RootOrg_Feature_Pack_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type Query_RootOrg_Feature_Pack_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrg_SearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
};

export type Query_RootOrganisationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type Query_RootOrganisation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type Query_RootOrganisation_Allocation_Counts_ViewArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Allocation_Counts_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type Query_RootOrganisation_Allocation_Counts_View_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Allocation_Counts_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type Query_RootOrganisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganisation_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type Query_RootOrganisation_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type Query_RootOrganisation_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganisation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Type_Bool_Exp>;
};

export type Query_RootOrganisation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Type_Bool_Exp>;
};

export type Query_RootOrganisation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootPartner_PortalArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Partner_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Partner_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Partner_Portal_Bool_Exp>;
};

export type Query_RootPartner_Portal_Abn_CheckArgs = {
  abn: Scalars['String'];
  invitationId: Scalars['String'];
};

export type Query_RootPartner_Portal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Partner_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Partner_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Partner_Portal_Bool_Exp>;
};

export type Query_RootPartner_Portal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPartner_Portal_Is_Login_TakenArgs = {
  invitationId: Scalars['String'];
  login: Scalars['String'];
};

export type Query_RootPayment_MethodArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Query_RootPayment_Method_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Query_RootPayment_Method_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPayment_Method_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Type_Bool_Exp>;
};

export type Query_RootPayment_Method_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Type_Bool_Exp>;
};

export type Query_RootPayment_Method_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootPreferenceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Query_RootPreference_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Query_RootPreference_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProcessArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Bool_Exp>;
};

export type Query_RootProcess_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Bool_Exp>;
};

export type Query_RootProcess_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootProcess_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Status_Bool_Exp>;
};

export type Query_RootProcess_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Status_Bool_Exp>;
};

export type Query_RootProcess_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProjectArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Query_RootProject_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Query_RootProject_Apartment_LevelArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Level_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Query_RootProject_Apartment_Level_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Level_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Query_RootProject_Apartment_StatArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Stat_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Stat_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
};

export type Query_RootProject_Apartment_Stat_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Stat_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Stat_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
};

export type Query_RootProject_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Client_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Query_RootProject_Client_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Query_RootProject_Client_Count_For_UserArgs = {
  /** input parameters for function "project_client_count_for_user" */
  args: Project_Client_Count_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Query_RootProject_Client_Count_For_User_AggregateArgs = {
  /** input parameters for function "project_client_count_for_user_aggregate" */
  args: Project_Client_Count_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Query_RootProject_DetailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Detail_Bool_Exp>;
};

export type Query_RootProject_Detail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Detail_Bool_Exp>;
};

export type Query_RootProject_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_DetailsArgs = {
  projectId: Scalars['String'];
};

export type Query_RootProject_FeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Features_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Features_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type Query_RootProject_Features_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Features_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Features_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type Query_RootProject_Features_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Payment_MethodArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type Query_RootProject_Payment_Method_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type Query_RootProject_Payment_Method_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Qr_Code_For_BuyerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
};

export type Query_RootProject_Qr_Code_For_Buyer_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
};

export type Query_RootProject_Qr_Code_For_Buyer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Reservation_SettingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Reservation_Setting_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Reservation_Setting_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
};

export type Query_RootProject_Reservation_Setting_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Reservation_Setting_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Reservation_Setting_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
};

export type Query_RootProject_Reservation_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_SourcesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Sources_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Sources_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Sources_Bool_Exp>;
};

export type Query_RootProject_Sources_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Sources_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Sources_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Sources_Bool_Exp>;
};

export type Query_RootProject_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Query_RootProject_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Query_RootProject_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProject_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Type_Bool_Exp>;
};

export type Query_RootProject_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Type_Bool_Exp>;
};

export type Query_RootProject_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProject_UnitsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Query_RootProject_Units_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Query_RootProject_Units_For_UserArgs = {
  /** input parameters for function "project_units_for_user" */
  args: Project_Units_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Query_RootProject_Units_For_User_AggregateArgs = {
  /** input parameters for function "project_units_for_user_aggregate" */
  args: Project_Units_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Query_RootPropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Query_RootProperty_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Query_RootProperty_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type Query_RootProperty_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type Query_RootProperty_Allocation_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
};

export type Query_RootProperty_Allocation_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
};

export type Query_RootProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProperty_Completion_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Completion_Status_Bool_Exp>;
};

export type Query_RootProperty_Completion_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Completion_Status_Bool_Exp>;
};

export type Query_RootProperty_Completion_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProperty_DetailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Detail_Bool_Exp>;
};

export type Query_RootProperty_Detail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Detail_Bool_Exp>;
};

export type Query_RootProperty_GroupArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Query_RootProperty_Group_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Query_RootProperty_Group_Allocation_Detail_ViewArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_Detail_View_Bool_Exp>;
};

export type Query_RootProperty_Group_Allocation_Detail_View_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_Detail_View_Bool_Exp>;
};

export type Query_RootProperty_Group_Allocation_ViewArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_View_Bool_Exp>;
};

export type Query_RootProperty_Group_Allocation_View_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_View_Bool_Exp>;
};

export type Query_RootProperty_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProperty_Group_Completion_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
};

export type Query_RootProperty_Group_Completion_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
};

export type Query_RootProperty_Group_Completion_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProperty_Group_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Type_Bool_Exp>;
};

export type Query_RootProperty_Group_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Type_Bool_Exp>;
};

export type Query_RootProperty_Group_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProperty_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

export type Query_RootProperty_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

export type Query_RootProperty_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootReservationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Reservation_Bool_Exp>;
};

export type Query_RootReservation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Reservation_Bool_Exp>;
};

export type Query_RootReservation_By_PkArgs = {
  deal_id: Scalars['uuid'];
};

export type Query_RootReset_PasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type Query_RootSalesforce_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Bool_Exp>;
};

export type Query_RootSalesforce_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Bool_Exp>;
};

export type Query_RootSalesforce_Action_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSalesforce_Action_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
};

export type Query_RootSalesforce_Action_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
};

export type Query_RootSalesforce_Action_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSalesforce_Bulk_ImportArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Bulk_Import_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Bulk_Import_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
};

export type Query_RootSalesforce_Bulk_Import_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Bulk_Import_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Bulk_Import_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
};

export type Query_RootSalesforce_Bulk_Import_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSalesforce_ConnectionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Connection_Bool_Exp>;
};

export type Query_RootSalesforce_Connection_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Connection_Bool_Exp>;
};

export type Query_RootSalesforce_Connection_By_PkArgs = {
  access_token: Scalars['String'];
};

export type Query_RootSalesforce_Sync_StatArgs = {
  sfInstanceId: Scalars['String'];
};

export type Query_RootSalesforce_Sync_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Sync_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Sync_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
};

export type Query_RootSalesforce_Sync_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Sync_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Sync_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
};

export type Query_RootSalesforce_Sync_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSend_Email_Verification_LinkArgs = {
  email: Scalars['String'];
};

export type Query_RootSend_Reset_Password_LinkArgs = {
  email: Scalars['String'];
};

export type Query_RootSettingsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Settings_Bool_Exp>;
};

export type Query_RootSettings_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Settings_Bool_Exp>;
};

export type Query_RootSettings_By_PkArgs = {
  key: Scalars['String'];
};

export type Query_RootSf_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Action_Bool_Exp>;
};

export type Query_RootSf_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Action_Bool_Exp>;
};

export type Query_RootSf_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSf_ContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

export type Query_RootSf_Contact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

export type Query_RootSf_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSf_OrganisationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

export type Query_RootSf_Organisation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

export type Query_RootSf_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSolicitor_OrgsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Solicitor_Orgs_Enum_Name>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Solicitor_Orgs_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Solicitor_Orgs_Bool_Exp_Bool_Exp>;
};

export type Query_RootSpoken_LanguageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Spoken_Language_Bool_Exp>;
};

export type Query_RootSpoken_Language_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Spoken_Language_Bool_Exp>;
};

export type Query_RootSpoken_Language_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSystemArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<System_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<System_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<System_Bool_Exp>;
};

export type Query_RootSystem_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<System_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<System_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<System_Bool_Exp>;
};

export type Query_RootSystem_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootToken_Verification_LoginArgs = {
  verificationToken: Scalars['String'];
};

export type Query_RootTransactionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type Query_RootTransaction_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type Query_RootTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTransaction_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Status_Bool_Exp>;
};

export type Query_RootTransaction_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Status_Bool_Exp>;
};

export type Query_RootTransaction_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTransaction_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Type_Bool_Exp>;
};

export type Query_RootTransaction_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Type_Bool_Exp>;
};

export type Query_RootTransaction_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTwilio_MessagesArgs = {
  to?: InputMaybe<Scalars['String']>;
};

export type Query_RootUnit_Allocation_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Unit_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Unit_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
};

export type Query_RootUnit_Allocation_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Unit_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Unit_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
};

export type Query_RootUserArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Created_Count_And_Rolling_AvgArgs = {
  /** input parameters for function "user_created_count_and_rolling_avg" */
  args: User_Created_Count_And_Rolling_Avg_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Query_RootUser_Created_Count_And_Rolling_Avg_AggregateArgs = {
  /** input parameters for function "user_created_count_and_rolling_avg_aggregate" */
  args: User_Created_Count_And_Rolling_Avg_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Query_RootUser_ExperimentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type Query_RootUser_Experiment_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type Query_RootUser_Experiment_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootUser_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Feature_Bool_Exp>;
};

export type Query_RootUser_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Feature_Bool_Exp>;
};

export type Query_RootUser_Feature_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootUser_Push_TokenArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Push_Token_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

export type Query_RootUser_Push_Token_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Push_Token_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

export type Query_RootUser_Push_Token_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVariationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Bool_Exp>;
};

export type Query_RootVariation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Bool_Exp>;
};

export type Query_RootVariation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVariation_CategoryArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Category_Bool_Exp>;
};

export type Query_RootVariation_Category_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Category_Bool_Exp>;
};

export type Query_RootVariation_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootVariation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Bool_Exp>;
};

export type Query_RootVariation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Bool_Exp>;
};

export type Query_RootVariation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootVariation_Type_CategoryArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Category_Bool_Exp>;
};

export type Query_RootVariation_Type_Category_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Category_Bool_Exp>;
};

export type Query_RootVariation_Type_Category_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVerify_CodeArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};

export type Query_RootVerify_Mobile_NumberArgs = {
  channel: Scalars['String'];
  mobileNumber: Scalars['String'];
  type: Scalars['String'];
};

export type Query_RootWeb_App_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Web_App_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Web_App_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

export type Query_RootWeb_App_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Web_App_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Web_App_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

export type Query_RootWeb_App_Feature_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootWebsocket_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Websocket_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Websocket_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Websocket_Log_Bool_Exp>;
};

export type Query_RootWebsocket_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Websocket_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Websocket_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Websocket_Log_Bool_Exp>;
};

export type Query_RootWebsocket_Log_By_PkArgs = {
  request_id: Scalars['uuid'];
};


/** State of side-nav component items in page based reservation flow */
export type Reservation = {
__typename?: 'reservation';

  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['uuid'];
  /** An object relationship */
  listing: Listing;
  listing_id: Scalars['uuid'];
  step_config?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};
export type ReservationStep_ConfigArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "reservation" */
export type Reservation_Aggregate = {
__typename?: 'reservation_aggregate';

  aggregate?: Maybe<Reservation_Aggregate_Fields>;
  nodes: Array<Reservation>;
};

/** aggregate fields of "reservation" */
export type Reservation_Aggregate_Fields = {
__typename?: 'reservation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Reservation_Max_Fields>;
  min?: Maybe<Reservation_Min_Fields>;
};
export type Reservation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Reservation_Max_Fields = {
__typename?: 'reservation_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Reservation_Min_Fields = {
__typename?: 'reservation_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  listing_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "reservation" */
export type Reservation_Mutation_Response = {
__typename?: 'reservation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation>;
};

/** A list of supported actions which can be performed in Salesforce */
export type Salesforce_Action = {
__typename?: 'salesforce_action';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "salesforce_action" */
export type Salesforce_Action_Aggregate = {
__typename?: 'salesforce_action_aggregate';

  aggregate?: Maybe<Salesforce_Action_Aggregate_Fields>;
  nodes: Array<Salesforce_Action>;
};

/** aggregate fields of "salesforce_action" */
export type Salesforce_Action_Aggregate_Fields = {
__typename?: 'salesforce_action_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Salesforce_Action_Max_Fields>;
  min?: Maybe<Salesforce_Action_Min_Fields>;
};
export type Salesforce_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Salesforce_Action_Max_Fields = {
__typename?: 'salesforce_action_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salesforce_Action_Min_Fields = {
__typename?: 'salesforce_action_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_action" */
export type Salesforce_Action_Mutation_Response = {
__typename?: 'salesforce_action_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Action>;
};

/** List of supported statuses, salesforce actions can have */
export type Salesforce_Action_Status = {
__typename?: 'salesforce_action_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "salesforce_action_status" */
export type Salesforce_Action_Status_Aggregate = {
__typename?: 'salesforce_action_status_aggregate';

  aggregate?: Maybe<Salesforce_Action_Status_Aggregate_Fields>;
  nodes: Array<Salesforce_Action_Status>;
};

/** aggregate fields of "salesforce_action_status" */
export type Salesforce_Action_Status_Aggregate_Fields = {
__typename?: 'salesforce_action_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Salesforce_Action_Status_Max_Fields>;
  min?: Maybe<Salesforce_Action_Status_Min_Fields>;
};
export type Salesforce_Action_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Action_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Salesforce_Action_Status_Max_Fields = {
__typename?: 'salesforce_action_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salesforce_Action_Status_Min_Fields = {
__typename?: 'salesforce_action_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_action_status" */
export type Salesforce_Action_Status_Mutation_Response = {
__typename?: 'salesforce_action_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Action_Status>;
};

/** Table for tracking status of Salesforce bulk imports */
export type Salesforce_Bulk_Import = {
__typename?: 'salesforce_bulk_import';

  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  page_size: Scalars['Int'];
  payload: Scalars['jsonb'];
  sf_instance_id: Scalars['String'];
  sf_object_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};
export type Salesforce_Bulk_ImportPayloadArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Aggregate = {
__typename?: 'salesforce_bulk_import_aggregate';

  aggregate?: Maybe<Salesforce_Bulk_Import_Aggregate_Fields>;
  nodes: Array<Salesforce_Bulk_Import>;
};

/** aggregate fields of "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Aggregate_Fields = {
__typename?: 'salesforce_bulk_import_aggregate_fields';

  avg?: Maybe<Salesforce_Bulk_Import_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Salesforce_Bulk_Import_Max_Fields>;
  min?: Maybe<Salesforce_Bulk_Import_Min_Fields>;
  stddev?: Maybe<Salesforce_Bulk_Import_Stddev_Fields>;
  stddev_pop?: Maybe<Salesforce_Bulk_Import_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Salesforce_Bulk_Import_Stddev_Samp_Fields>;
  sum?: Maybe<Salesforce_Bulk_Import_Sum_Fields>;
  var_pop?: Maybe<Salesforce_Bulk_Import_Var_Pop_Fields>;
  var_samp?: Maybe<Salesforce_Bulk_Import_Var_Samp_Fields>;
  variance?: Maybe<Salesforce_Bulk_Import_Variance_Fields>;
};
export type Salesforce_Bulk_Import_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Bulk_Import_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Salesforce_Bulk_Import_Avg_Fields = {
__typename?: 'salesforce_bulk_import_avg_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Salesforce_Bulk_Import_Max_Fields = {
__typename?: 'salesforce_bulk_import_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  page_size?: Maybe<Scalars['Int']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  sf_object_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Salesforce_Bulk_Import_Min_Fields = {
__typename?: 'salesforce_bulk_import_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  page_size?: Maybe<Scalars['Int']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  sf_object_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "salesforce_bulk_import" */
export type Salesforce_Bulk_Import_Mutation_Response = {
__typename?: 'salesforce_bulk_import_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Bulk_Import>;
};

/** aggregate stddev on columns */
export type Salesforce_Bulk_Import_Stddev_Fields = {
__typename?: 'salesforce_bulk_import_stddev_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Salesforce_Bulk_Import_Stddev_Pop_Fields = {
__typename?: 'salesforce_bulk_import_stddev_pop_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Salesforce_Bulk_Import_Stddev_Samp_Fields = {
__typename?: 'salesforce_bulk_import_stddev_samp_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Salesforce_Bulk_Import_Sum_Fields = {
__typename?: 'salesforce_bulk_import_sum_fields';

  page_size?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Salesforce_Bulk_Import_Var_Pop_Fields = {
__typename?: 'salesforce_bulk_import_var_pop_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Salesforce_Bulk_Import_Var_Samp_Fields = {
__typename?: 'salesforce_bulk_import_var_samp_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Salesforce_Bulk_Import_Variance_Fields = {
__typename?: 'salesforce_bulk_import_variance_fields';

  page_size?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "salesforce_connection" */
export type Salesforce_Connection = {
__typename?: 'salesforce_connection';

  access_token: Scalars['String'];
  instance_url?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
};

/** aggregated selection of "salesforce_connection" */
export type Salesforce_Connection_Aggregate = {
__typename?: 'salesforce_connection_aggregate';

  aggregate?: Maybe<Salesforce_Connection_Aggregate_Fields>;
  nodes: Array<Salesforce_Connection>;
};

/** aggregate fields of "salesforce_connection" */
export type Salesforce_Connection_Aggregate_Fields = {
__typename?: 'salesforce_connection_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Salesforce_Connection_Max_Fields>;
  min?: Maybe<Salesforce_Connection_Min_Fields>;
};
export type Salesforce_Connection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Connection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Salesforce_Connection_Max_Fields = {
__typename?: 'salesforce_connection_max_fields';

  access_token?: Maybe<Scalars['String']>;
  instance_url?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salesforce_Connection_Min_Fields = {
__typename?: 'salesforce_connection_min_fields';

  access_token?: Maybe<Scalars['String']>;
  instance_url?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_connection" */
export type Salesforce_Connection_Mutation_Response = {
__typename?: 'salesforce_connection_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Connection>;
};

/** columns and relationships of "salesforce_sync_status" */
export type Salesforce_Sync_Status = {
__typename?: 'salesforce_sync_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "salesforce_sync_status" */
export type Salesforce_Sync_Status_Aggregate = {
__typename?: 'salesforce_sync_status_aggregate';

  aggregate?: Maybe<Salesforce_Sync_Status_Aggregate_Fields>;
  nodes: Array<Salesforce_Sync_Status>;
};

/** aggregate fields of "salesforce_sync_status" */
export type Salesforce_Sync_Status_Aggregate_Fields = {
__typename?: 'salesforce_sync_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Salesforce_Sync_Status_Max_Fields>;
  min?: Maybe<Salesforce_Sync_Status_Min_Fields>;
};
export type Salesforce_Sync_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salesforce_Sync_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Salesforce_Sync_Status_Max_Fields = {
__typename?: 'salesforce_sync_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Salesforce_Sync_Status_Min_Fields = {
__typename?: 'salesforce_sync_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_sync_status" */
export type Salesforce_Sync_Status_Mutation_Response = {
__typename?: 'salesforce_sync_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salesforce_Sync_Status>;
};

/** stores settings in key value pair */
export type Settings = {
__typename?: 'settings';

  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['jsonb'];
};
export type SettingsValueArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "settings" */
export type Settings_Aggregate = {
__typename?: 'settings_aggregate';

  aggregate?: Maybe<Settings_Aggregate_Fields>;
  nodes: Array<Settings>;
};

/** aggregate fields of "settings" */
export type Settings_Aggregate_Fields = {
__typename?: 'settings_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Settings_Max_Fields>;
  min?: Maybe<Settings_Min_Fields>;
};
export type Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Settings_Max_Fields = {
__typename?: 'settings_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Settings_Min_Fields = {
__typename?: 'settings_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "settings" */
export type Settings_Mutation_Response = {
__typename?: 'settings_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Settings>;
};

/** Actions to be performed on Salesforce */
export type Sf_Action = {
__typename?: 'sf_action';

  action: Salesforce_Action_Enum;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  payload: Scalars['jsonb'];
  sf_instance_id: Scalars['String'];
  status: Salesforce_Action_Status_Enum;
  updated_at: Scalars['timestamptz'];
};
export type Sf_ActionPayloadArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "sf_action" */
export type Sf_Action_Aggregate = {
__typename?: 'sf_action_aggregate';

  aggregate?: Maybe<Sf_Action_Aggregate_Fields>;
  nodes: Array<Sf_Action>;
};

/** aggregate fields of "sf_action" */
export type Sf_Action_Aggregate_Fields = {
__typename?: 'sf_action_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Sf_Action_Max_Fields>;
  min?: Maybe<Sf_Action_Min_Fields>;
};
export type Sf_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sf_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Sf_Action_Max_Fields = {
__typename?: 'sf_action_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sf_Action_Min_Fields = {
__typename?: 'sf_action_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sf_action" */
export type Sf_Action_Mutation_Response = {
__typename?: 'sf_action_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sf_Action>;
};

/** Stores contact's Salesforce IDs from multiple Salesforce instances */
export type Sf_Contact = {
__typename?: 'sf_contact';

  /** An object relationship */
  contact: Contact;
  contact_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  crm_connection: Crm_Connection;
  id: Scalars['uuid'];
  sf_id: Scalars['String'];
  sf_instance_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sf_contact" */
export type Sf_Contact_Aggregate = {
__typename?: 'sf_contact_aggregate';

  aggregate?: Maybe<Sf_Contact_Aggregate_Fields>;
  nodes: Array<Sf_Contact>;
};

/** aggregate fields of "sf_contact" */
export type Sf_Contact_Aggregate_Fields = {
__typename?: 'sf_contact_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Sf_Contact_Max_Fields>;
  min?: Maybe<Sf_Contact_Min_Fields>;
};
export type Sf_Contact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Sf_Contact_Max_Fields = {
__typename?: 'sf_contact_max_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sf_Contact_Min_Fields = {
__typename?: 'sf_contact_min_fields';

  contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sf_contact" */
export type Sf_Contact_Mutation_Response = {
__typename?: 'sf_contact_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sf_Contact>;
};

/** Stores organisation's Salesforce IDs from multiple Salesforce instances */
export type Sf_Organisation = {
__typename?: 'sf_organisation';

  created_at: Scalars['timestamptz'];
  /** An object relationship */
  crm_connection: Crm_Connection;
  id: Scalars['uuid'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['uuid'];
  sf_id: Scalars['String'];
  sf_instance_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sf_organisation" */
export type Sf_Organisation_Aggregate = {
__typename?: 'sf_organisation_aggregate';

  aggregate?: Maybe<Sf_Organisation_Aggregate_Fields>;
  nodes: Array<Sf_Organisation>;
};

/** aggregate fields of "sf_organisation" */
export type Sf_Organisation_Aggregate_Fields = {
__typename?: 'sf_organisation_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Sf_Organisation_Max_Fields>;
  min?: Maybe<Sf_Organisation_Min_Fields>;
};
export type Sf_Organisation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Sf_Organisation_Max_Fields = {
__typename?: 'sf_organisation_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sf_Organisation_Min_Fields = {
__typename?: 'sf_organisation_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  organisation_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sf_organisation" */
export type Sf_Organisation_Mutation_Response = {
__typename?: 'sf_organisation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sf_Organisation>;
};

export type Solicitor_Orgs = {
__typename?: 'solicitor_orgs';

  active_deal_count: Scalars['bigint'];
  contact_count: Scalars['bigint'];
  crm_name?: Maybe<Scalars['String']>;
  deal_count: Scalars['bigint'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  project_count: Scalars['bigint'];
  sf_id?: Maybe<Scalars['String']>;
  sf_instance_id?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** spoken_language */
export type Spoken_Language = {
__typename?: 'spoken_language';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "spoken_language" */
export type Spoken_Language_Aggregate = {
__typename?: 'spoken_language_aggregate';

  aggregate?: Maybe<Spoken_Language_Aggregate_Fields>;
  nodes: Array<Spoken_Language>;
};

/** aggregate fields of "spoken_language" */
export type Spoken_Language_Aggregate_Fields = {
__typename?: 'spoken_language_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Spoken_Language_Max_Fields>;
  min?: Maybe<Spoken_Language_Min_Fields>;
};
export type Spoken_Language_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spoken_Language_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Spoken_Language_Max_Fields = {
__typename?: 'spoken_language_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spoken_Language_Min_Fields = {
__typename?: 'spoken_language_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spoken_language" */
export type Spoken_Language_Mutation_Response = {
__typename?: 'spoken_language_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spoken_Language>;
};

export type Subscription_Root = {
__typename?: 'subscription_root';

  /** An array relationship */
  acl_allocation: Array<Acl_Allocation>;
  /** An aggregate relationship */
  acl_allocation_aggregate: Acl_Allocation_Aggregate;
  /** An array relationship */
  acl_allocation_edit: Array<Acl_Allocation_Edit>;
  /** An aggregate relationship */
  acl_allocation_edit_aggregate: Acl_Allocation_Edit_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_allocation_edit" */
  acl_allocation_edit_stream: Array<Acl_Allocation_Edit>;
  /** fetch data from the table in a streaming manner: "acl_allocation" */
  acl_allocation_stream: Array<Acl_Allocation>;
  /** An array relationship */
  acl_contact_agent_member: Array<Acl_Contact_Agent_Member>;
  /** An aggregate relationship */
  acl_contact_agent_member_aggregate: Acl_Contact_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_contact_agent_member" */
  acl_contact_agent_member_stream: Array<Acl_Contact_Agent_Member>;
  /** An array relationship */
  acl_contact_developer_member: Array<Acl_Contact_Developer_Member>;
  /** An aggregate relationship */
  acl_contact_developer_member_aggregate: Acl_Contact_Developer_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_contact_developer_member" */
  acl_contact_developer_member_stream: Array<Acl_Contact_Developer_Member>;
  /** An array relationship */
  acl_contact_master_agent: Array<Acl_Contact_Master_Agent>;
  /** An aggregate relationship */
  acl_contact_master_agent_aggregate: Acl_Contact_Master_Agent_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_contact_master_agent" */
  acl_contact_master_agent_stream: Array<Acl_Contact_Master_Agent>;
  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_deal_agent_member" */
  acl_deal_agent_member_stream: Array<Acl_Deal_Agent_Member>;
  /** An array relationship */
  acl_deal_developer_member: Array<Acl_Deal_Developer_Member>;
  /** An aggregate relationship */
  acl_deal_developer_member_aggregate: Acl_Deal_Developer_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_deal_developer_member" */
  acl_deal_developer_member_stream: Array<Acl_Deal_Developer_Member>;
  /** An array relationship */
  acl_deal_master_agent: Array<Acl_Deal_Master_Agent>;
  /** An aggregate relationship */
  acl_deal_master_agent_aggregate: Acl_Deal_Master_Agent_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_deal_master_agent" */
  acl_deal_master_agent_stream: Array<Acl_Deal_Master_Agent>;
  /** An array relationship */
  acl_deal_user_team: Array<Acl_Deal_User_Team>;
  /** An aggregate relationship */
  acl_deal_user_team_aggregate: Acl_Deal_User_Team_Aggregate;
  /** fetch data from the table: "acl_deal_user_team_desc" */
  acl_deal_user_team_desc: Array<Acl_Deal_User_Team_Desc>;
  /** fetch aggregated fields from the table: "acl_deal_user_team_desc" */
  acl_deal_user_team_desc_aggregate: Acl_Deal_User_Team_Desc_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_deal_user_team_desc" */
  acl_deal_user_team_desc_stream: Array<Acl_Deal_User_Team_Desc>;
  /** fetch data from the table in a streaming manner: "acl_deal_user_team" */
  acl_deal_user_team_stream: Array<Acl_Deal_User_Team>;
  /** An array relationship */
  acl_listing_agent_member: Array<Acl_Listing_Agent_Member>;
  /** An aggregate relationship */
  acl_listing_agent_member_aggregate: Acl_Listing_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_agent_member" */
  acl_listing_agent_member_stream: Array<Acl_Listing_Agent_Member>;
  /** fetch data from the table: "acl_listing_developer_member" */
  acl_listing_developer_member: Array<Acl_Listing_Developer_Member>;
  /** fetch aggregated fields from the table: "acl_listing_developer_member" */
  acl_listing_developer_member_aggregate: Acl_Listing_Developer_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_developer_member" */
  acl_listing_developer_member_stream: Array<Acl_Listing_Developer_Member>;
  /** An array relationship */
  acl_listing_master_agent: Array<Acl_Listing_Master_Agent>;
  /** An aggregate relationship */
  acl_listing_master_agent_aggregate: Acl_Listing_Master_Agent_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_master_agent" */
  acl_listing_master_agent_stream: Array<Acl_Listing_Master_Agent>;
  /** An array relationship */
  acl_listing_property: Array<Acl_Listing_Property>;
  /** An aggregate relationship */
  acl_listing_property_aggregate: Acl_Listing_Property_Aggregate;
  /** fetch data from the table: "acl_listing_property_desc" */
  acl_listing_property_desc: Array<Acl_Listing_Property_Desc>;
  /** fetch aggregated fields from the table: "acl_listing_property_desc" */
  acl_listing_property_desc_aggregate: Acl_Listing_Property_Desc_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_property_desc" */
  acl_listing_property_desc_stream: Array<Acl_Listing_Property_Desc>;
  /** fetch data from the table: "acl_listing_property_member" */
  acl_listing_property_member: Array<Acl_Listing_Property_Member>;
  /** fetch aggregated fields from the table: "acl_listing_property_member" */
  acl_listing_property_member_aggregate: Acl_Listing_Property_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_property_member" */
  acl_listing_property_member_stream: Array<Acl_Listing_Property_Member>;
  /** fetch data from the table in a streaming manner: "acl_listing_property" */
  acl_listing_property_stream: Array<Acl_Listing_Property>;
  /** An array relationship */
  acl_listing_property_team: Array<Acl_Listing_Property_Team>;
  /** An aggregate relationship */
  acl_listing_property_team_aggregate: Acl_Listing_Property_Team_Aggregate;
  /** fetch data from the table: "acl_listing_property_team_desc" */
  acl_listing_property_team_desc: Array<Acl_Listing_Property_Team_Desc>;
  /** fetch aggregated fields from the table: "acl_listing_property_team_desc" */
  acl_listing_property_team_desc_aggregate: Acl_Listing_Property_Team_Desc_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_property_team_desc" */
  acl_listing_property_team_desc_stream: Array<Acl_Listing_Property_Team_Desc>;
  /** fetch data from the table in a streaming manner: "acl_listing_property_team" */
  acl_listing_property_team_stream: Array<Acl_Listing_Property_Team>;
  /** fetch data from the table: "acl_listing_via_listing_agent_member" */
  acl_listing_via_listing_agent_member: Array<Acl_Listing_Via_Listing_Agent_Member>;
  /** fetch aggregated fields from the table: "acl_listing_via_listing_agent_member" */
  acl_listing_via_listing_agent_member_aggregate: Acl_Listing_Via_Listing_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_via_listing_agent_member" */
  acl_listing_via_listing_agent_member_stream: Array<Acl_Listing_Via_Listing_Agent_Member>;
  /** fetch data from the table: "acl_listing_via_stage_agent_member" */
  acl_listing_via_stage_agent_member: Array<Acl_Listing_Via_Stage_Agent_Member>;
  /** fetch aggregated fields from the table: "acl_listing_via_stage_agent_member" */
  acl_listing_via_stage_agent_member_aggregate: Acl_Listing_Via_Stage_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_listing_via_stage_agent_member" */
  acl_listing_via_stage_agent_member_stream: Array<Acl_Listing_Via_Stage_Agent_Member>;
  /** An array relationship */
  acl_org_agent_member: Array<Acl_Org_Agent_Member>;
  /** An aggregate relationship */
  acl_org_agent_member_aggregate: Acl_Org_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_org_agent_member" */
  acl_org_agent_member_stream: Array<Acl_Org_Agent_Member>;
  /** An array relationship */
  acl_org_developer_member: Array<Acl_Org_Developer_Member>;
  /** An aggregate relationship */
  acl_org_developer_member_aggregate: Acl_Org_Developer_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_org_developer_member" */
  acl_org_developer_member_stream: Array<Acl_Org_Developer_Member>;
  /** fetch data from the table: "acl_org_feature_enabled" */
  acl_org_feature_enabled: Array<Acl_Org_Feature_Enabled>;
  /** fetch aggregated fields from the table: "acl_org_feature_enabled" */
  acl_org_feature_enabled_aggregate: Acl_Org_Feature_Enabled_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_org_feature_enabled" */
  acl_org_feature_enabled_stream: Array<Acl_Org_Feature_Enabled>;
  /** An array relationship */
  acl_org_master_agent: Array<Acl_Org_Master_Agent>;
  /** An aggregate relationship */
  acl_org_master_agent_aggregate: Acl_Org_Master_Agent_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_org_master_agent" */
  acl_org_master_agent_stream: Array<Acl_Org_Master_Agent>;
  /** An array relationship */
  acl_property_allocation: Array<Acl_Property_Allocation>;
  /** An aggregate relationship */
  acl_property_allocation_aggregate: Acl_Property_Allocation_Aggregate;
  /** fetch data from the table: "acl_property_allocation_count" */
  acl_property_allocation_count: Array<Acl_Property_Allocation_Count>;
  /** fetch aggregated fields from the table: "acl_property_allocation_count" */
  acl_property_allocation_count_aggregate: Acl_Property_Allocation_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_property_allocation_count" */
  acl_property_allocation_count_stream: Array<Acl_Property_Allocation_Count>;
  /** fetch data from the table in a streaming manner: "acl_property_allocation" */
  acl_property_allocation_stream: Array<Acl_Property_Allocation>;
  /** An array relationship */
  acl_stage_agent_member: Array<Acl_Stage_Agent_Member>;
  /** An aggregate relationship */
  acl_stage_agent_member_aggregate: Acl_Stage_Agent_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "acl_stage_agent_member" */
  acl_stage_agent_member_stream: Array<Acl_Stage_Agent_Member>;
  /** fetch data from the table: "acl_update" */
  acl_update: Array<Acl_Update>;
  /** fetch aggregated fields from the table: "acl_update" */
  acl_update_aggregate: Acl_Update_Aggregate;
  /** fetch data from the table: "acl_update" using primary key columns */
  acl_update_by_pk?: Maybe<Acl_Update>;
  /** fetch data from the table: "acl_update_status" */
  acl_update_status: Array<Acl_Update_Status>;
  /** fetch aggregated fields from the table: "acl_update_status" */
  acl_update_status_aggregate: Acl_Update_Status_Aggregate;
  /** fetch data from the table: "acl_update_status" using primary key columns */
  acl_update_status_by_pk?: Maybe<Acl_Update_Status>;
  /** fetch data from the table in a streaming manner: "acl_update_status" */
  acl_update_status_stream: Array<Acl_Update_Status>;
  /** fetch data from the table in a streaming manner: "acl_update" */
  acl_update_stream: Array<Acl_Update>;
  /** fetch data from the table: "agency_agreement" */
  agency_agreement: Array<Agency_Agreement>;
  /** fetch aggregated fields from the table: "agency_agreement" */
  agency_agreement_aggregate: Agency_Agreement_Aggregate;
  /** fetch data from the table: "agency_agreement" using primary key columns */
  agency_agreement_by_pk?: Maybe<Agency_Agreement>;
  /** fetch data from the table in a streaming manner: "agency_agreement" */
  agency_agreement_stream: Array<Agency_Agreement>;
  /** fetch data from the table: "agreement" */
  agreement: Array<Agreement>;
  /** fetch aggregated fields from the table: "agreement" */
  agreement_aggregate: Agreement_Aggregate;
  /** fetch data from the table: "agreement" using primary key columns */
  agreement_by_pk?: Maybe<Agreement>;
  /** fetch data from the table in a streaming manner: "agreement" */
  agreement_stream: Array<Agreement>;
  /** fetch data from the table: "allocation" */
  allocation: Array<Allocation>;
  /** fetch aggregated fields from the table: "allocation" */
  allocation_aggregate: Allocation_Aggregate;
  /** fetch data from the table: "allocation" using primary key columns */
  allocation_by_pk?: Maybe<Allocation>;
  /** fetch data from the table: "allocation_log" */
  allocation_log: Array<Allocation_Log>;
  /** fetch aggregated fields from the table: "allocation_log" */
  allocation_log_aggregate: Allocation_Log_Aggregate;
  /** fetch data from the table: "allocation_log" using primary key columns */
  allocation_log_by_pk?: Maybe<Allocation_Log>;
  /** fetch data from the table in a streaming manner: "allocation_log" */
  allocation_log_stream: Array<Allocation_Log>;
  /** fetch data from the table in a streaming manner: "allocation" */
  allocation_stream: Array<Allocation>;
  /** fetch data from the table: "allocation_type" */
  allocation_type: Array<Allocation_Type>;
  /** fetch aggregated fields from the table: "allocation_type" */
  allocation_type_aggregate: Allocation_Type_Aggregate;
  /** fetch data from the table: "allocation_type" using primary key columns */
  allocation_type_by_pk?: Maybe<Allocation_Type>;
  /** fetch data from the table in a streaming manner: "allocation_type" */
  allocation_type_stream: Array<Allocation_Type>;
  /** fetch data from the table: "apartment_plan_building" */
  apartment_plan_building: Array<Apartment_Plan_Building>;
  /** fetch aggregated fields from the table: "apartment_plan_building" */
  apartment_plan_building_aggregate: Apartment_Plan_Building_Aggregate;
  /** fetch data from the table: "apartment_plan_building" using primary key columns */
  apartment_plan_building_by_pk?: Maybe<Apartment_Plan_Building>;
  /** fetch data from the table in a streaming manner: "apartment_plan_building" */
  apartment_plan_building_stream: Array<Apartment_Plan_Building>;
  /** fetch data from the table: "apartment_plan_property" */
  apartment_plan_property: Array<Apartment_Plan_Property>;
  /** fetch aggregated fields from the table: "apartment_plan_property" */
  apartment_plan_property_aggregate: Apartment_Plan_Property_Aggregate;
  /** fetch data from the table: "apartment_plan_property" using primary key columns */
  apartment_plan_property_by_pk?: Maybe<Apartment_Plan_Property>;
  /** fetch data from the table in a streaming manner: "apartment_plan_property" */
  apartment_plan_property_stream: Array<Apartment_Plan_Property>;
  /** fetch data from the table: "area_plot" */
  area_plot: Array<Area_Plot>;
  /** fetch aggregated fields from the table: "area_plot" */
  area_plot_aggregate: Area_Plot_Aggregate;
  /** fetch data from the table: "area_plot" using primary key columns */
  area_plot_by_pk?: Maybe<Area_Plot>;
  /** fetch data from the table in a streaming manner: "area_plot" */
  area_plot_stream: Array<Area_Plot>;
  /** fetch data from the table: "area_plot_type" */
  area_plot_type: Array<Area_Plot_Type>;
  /** fetch aggregated fields from the table: "area_plot_type" */
  area_plot_type_aggregate: Area_Plot_Type_Aggregate;
  /** fetch data from the table: "area_plot_type" using primary key columns */
  area_plot_type_by_pk?: Maybe<Area_Plot_Type>;
  /** fetch data from the table in a streaming manner: "area_plot_type" */
  area_plot_type_stream: Array<Area_Plot_Type>;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** An array relationship */
  attachment_config: Array<Attachment_Config>;
  /** An aggregate relationship */
  attachment_config_aggregate: Attachment_Config_Aggregate;
  /** fetch data from the table: "attachment_config" using primary key columns */
  attachment_config_by_pk?: Maybe<Attachment_Config>;
  /** fetch data from the table in a streaming manner: "attachment_config" */
  attachment_config_stream: Array<Attachment_Config>;
  /** fetch data from the table: "attachment_edit_project_order" */
  attachment_edit_project_order: Array<Attachment_Edit_Project_Order>;
  /** fetch aggregated fields from the table: "attachment_edit_project_order" */
  attachment_edit_project_order_aggregate: Attachment_Edit_Project_Order_Aggregate;
  /** fetch data from the table: "attachment_edit_project_order" using primary key columns */
  attachment_edit_project_order_by_pk?: Maybe<Attachment_Edit_Project_Order>;
  /** fetch data from the table in a streaming manner: "attachment_edit_project_order" */
  attachment_edit_project_order_stream: Array<Attachment_Edit_Project_Order>;
  /** fetch data from the table: "attachment_owner" */
  attachment_owner: Array<Attachment_Owner>;
  /** fetch aggregated fields from the table: "attachment_owner" */
  attachment_owner_aggregate: Attachment_Owner_Aggregate;
  /** fetch data from the table: "attachment_owner" using primary key columns */
  attachment_owner_by_pk?: Maybe<Attachment_Owner>;
  /** fetch data from the table in a streaming manner: "attachment_owner" */
  attachment_owner_stream: Array<Attachment_Owner>;
  /** fetch data from the table: "attachment_product_type" */
  attachment_product_type: Array<Attachment_Product_Type>;
  /** fetch aggregated fields from the table: "attachment_product_type" */
  attachment_product_type_aggregate: Attachment_Product_Type_Aggregate;
  /** fetch data from the table: "attachment_product_type" using primary key columns */
  attachment_product_type_by_pk?: Maybe<Attachment_Product_Type>;
  /** fetch data from the table in a streaming manner: "attachment_product_type" */
  attachment_product_type_stream: Array<Attachment_Product_Type>;
  /** fetch data from the table: "attachment_stage_order" */
  attachment_stage_order: Array<Attachment_Stage_Order>;
  /** fetch aggregated fields from the table: "attachment_stage_order" */
  attachment_stage_order_aggregate: Attachment_Stage_Order_Aggregate;
  /** fetch data from the table: "attachment_stage_order" using primary key columns */
  attachment_stage_order_by_pk?: Maybe<Attachment_Stage_Order>;
  /** fetch data from the table in a streaming manner: "attachment_stage_order" */
  attachment_stage_order_stream: Array<Attachment_Stage_Order>;
  /** fetch data from the table in a streaming manner: "attachment" */
  attachment_stream: Array<Attachment>;
  /** fetch data from the table: "attachment_thumbnail" */
  attachment_thumbnail: Array<Attachment_Thumbnail>;
  /** fetch aggregated fields from the table: "attachment_thumbnail" */
  attachment_thumbnail_aggregate: Attachment_Thumbnail_Aggregate;
  /** fetch data from the table: "attachment_thumbnail" using primary key columns */
  attachment_thumbnail_by_pk?: Maybe<Attachment_Thumbnail>;
  /** fetch data from the table: "attachment_thumbnail_count" */
  attachment_thumbnail_count: Array<Attachment_Thumbnail_Count>;
  /** fetch aggregated fields from the table: "attachment_thumbnail_count" */
  attachment_thumbnail_count_aggregate: Attachment_Thumbnail_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "attachment_thumbnail_count" */
  attachment_thumbnail_count_stream: Array<Attachment_Thumbnail_Count>;
  /** fetch data from the table in a streaming manner: "attachment_thumbnail" */
  attachment_thumbnail_stream: Array<Attachment_Thumbnail>;
  /** fetch data from the table: "attachment_thumbnail_type" */
  attachment_thumbnail_type: Array<Attachment_Thumbnail_Type>;
  /** fetch aggregated fields from the table: "attachment_thumbnail_type" */
  attachment_thumbnail_type_aggregate: Attachment_Thumbnail_Type_Aggregate;
  /** fetch data from the table: "attachment_thumbnail_type" using primary key columns */
  attachment_thumbnail_type_by_pk?: Maybe<Attachment_Thumbnail_Type>;
  /** fetch data from the table in a streaming manner: "attachment_thumbnail_type" */
  attachment_thumbnail_type_stream: Array<Attachment_Thumbnail_Type>;
  /** fetch data from the table: "attachment_type" */
  attachment_type: Array<Attachment_Type>;
  /** fetch aggregated fields from the table: "attachment_type" */
  attachment_type_aggregate: Attachment_Type_Aggregate;
  /** fetch data from the table: "attachment_type" using primary key columns */
  attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** fetch data from the table in a streaming manner: "attachment_type" */
  attachment_type_stream: Array<Attachment_Type>;
  /** fetch data from the table: "buyer_portal" */
  buyer_portal: Array<Buyer_Portal>;
  /** fetch aggregated fields from the table: "buyer_portal" */
  buyer_portal_aggregate: Buyer_Portal_Aggregate;
  /** fetch data from the table: "buyer_portal" using primary key columns */
  buyer_portal_by_pk?: Maybe<Buyer_Portal>;
  /** fetch data from the table in a streaming manner: "buyer_portal" */
  buyer_portal_stream: Array<Buyer_Portal>;
  /** fetch data from the table: "buyer_project" */
  buyer_project: Array<Buyer_Project>;
  /** fetch aggregated fields from the table: "buyer_project" */
  buyer_project_aggregate: Buyer_Project_Aggregate;
  /** fetch data from the table: "buyer_project" using primary key columns */
  buyer_project_by_pk?: Maybe<Buyer_Project>;
  /** fetch data from the table in a streaming manner: "buyer_project" */
  buyer_project_stream: Array<Buyer_Project>;
  /** fetch data from the table: "buyer_type" */
  buyer_type: Array<Buyer_Type>;
  /** fetch aggregated fields from the table: "buyer_type" */
  buyer_type_aggregate: Buyer_Type_Aggregate;
  /** fetch data from the table: "buyer_type" using primary key columns */
  buyer_type_by_pk?: Maybe<Buyer_Type>;
  /** fetch data from the table in a streaming manner: "buyer_type" */
  buyer_type_stream: Array<Buyer_Type>;
  /** fetch data from the table: "cache" */
  cache: Array<Cache>;
  /** fetch aggregated fields from the table: "cache" */
  cache_aggregate: Cache_Aggregate;
  /** fetch data from the table: "cache" using primary key columns */
  cache_by_pk?: Maybe<Cache>;
  /** fetch data from the table in a streaming manner: "cache" */
  cache_stream: Array<Cache>;
  /** fetch data from the table: "change_in" */
  change_in: Array<Change_In>;
  /** fetch aggregated fields from the table: "change_in" */
  change_in_aggregate: Change_In_Aggregate;
  /** fetch data from the table: "change_in" using primary key columns */
  change_in_by_pk?: Maybe<Change_In>;
  /** fetch data from the table in a streaming manner: "change_in" */
  change_in_stream: Array<Change_In>;
  /** fetch data from the table: "change_status" */
  change_status: Array<Change_Status>;
  /** fetch aggregated fields from the table: "change_status" */
  change_status_aggregate: Change_Status_Aggregate;
  /** fetch data from the table: "change_status" using primary key columns */
  change_status_by_pk?: Maybe<Change_Status>;
  /** fetch data from the table in a streaming manner: "change_status" */
  change_status_stream: Array<Change_Status>;
  /** fetch data from the table: "code" */
  code: Array<Code>;
  /** fetch aggregated fields from the table: "code" */
  code_aggregate: Code_Aggregate;
  /** fetch data from the table: "code" using primary key columns */
  code_by_pk?: Maybe<Code>;
  /** fetch data from the table in a streaming manner: "code" */
  code_stream: Array<Code>;
  /** fetch data from the table: "commission" */
  commission: Array<Commission>;
  /** fetch aggregated fields from the table: "commission" */
  commission_aggregate: Commission_Aggregate;
  /** fetch data from the table: "commission" using primary key columns */
  commission_by_pk?: Maybe<Commission>;
  /** fetch data from the table in a streaming manner: "commission" */
  commission_stream: Array<Commission>;
  /** fetch data from the table: "commission_type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission_type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission_type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table in a streaming manner: "commission_type" */
  commission_type_stream: Array<Commission_Type>;
  /** fetch data from the table: "contact" */
  contact: Array<Contact>;
  /** fetch aggregated fields from the table: "contact" */
  contact_aggregate: Contact_Aggregate;
  /** fetch data from the table: "contact" using primary key columns */
  contact_by_pk?: Maybe<Contact>;
  /** fetch data from the table in a streaming manner: "contact" */
  contact_stream: Array<Contact>;
  /** fetch data from the table: "contact_type" */
  contact_type: Array<Contact_Type>;
  /** fetch aggregated fields from the table: "contact_type" */
  contact_type_aggregate: Contact_Type_Aggregate;
  /** fetch data from the table: "contact_type" using primary key columns */
  contact_type_by_pk?: Maybe<Contact_Type>;
  /** fetch data from the table in a streaming manner: "contact_type" */
  contact_type_stream: Array<Contact_Type>;
  /** fetch data from the table: "count_with_rolling_avg" */
  count_with_rolling_avg: Array<Count_With_Rolling_Avg>;
  /** fetch aggregated fields from the table: "count_with_rolling_avg" */
  count_with_rolling_avg_aggregate: Count_With_Rolling_Avg_Aggregate;
  /** fetch data from the table: "count_with_rolling_avg" using primary key columns */
  count_with_rolling_avg_by_pk?: Maybe<Count_With_Rolling_Avg>;
  /** fetch data from the table in a streaming manner: "count_with_rolling_avg" */
  count_with_rolling_avg_stream: Array<Count_With_Rolling_Avg>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "crm_connection" */
  crm_connection: Array<Crm_Connection>;
  /** fetch aggregated fields from the table: "crm_connection" */
  crm_connection_aggregate: Crm_Connection_Aggregate;
  /** fetch data from the table: "crm_connection" using primary key columns */
  crm_connection_by_pk?: Maybe<Crm_Connection>;
  /** fetch data from the table in a streaming manner: "crm_connection" */
  crm_connection_stream: Array<Crm_Connection>;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "deal_contact" */
  deal_contact: Array<Deal_Contact>;
  /** fetch aggregated fields from the table: "deal_contact" */
  deal_contact_aggregate: Deal_Contact_Aggregate;
  /** fetch data from the table: "deal_contact" using primary key columns */
  deal_contact_by_pk?: Maybe<Deal_Contact>;
  /** fetch data from the table: "deal_contact_status" */
  deal_contact_status: Array<Deal_Contact_Status>;
  /** fetch aggregated fields from the table: "deal_contact_status" */
  deal_contact_status_aggregate: Deal_Contact_Status_Aggregate;
  /** fetch data from the table: "deal_contact_status" using primary key columns */
  deal_contact_status_by_pk?: Maybe<Deal_Contact_Status>;
  /** fetch data from the table in a streaming manner: "deal_contact_status" */
  deal_contact_status_stream: Array<Deal_Contact_Status>;
  /** fetch data from the table in a streaming manner: "deal_contact" */
  deal_contact_stream: Array<Deal_Contact>;
  /** fetch data from the table: "deal_deposit_status" */
  deal_deposit_status: Array<Deal_Deposit_Status>;
  /** fetch aggregated fields from the table: "deal_deposit_status" */
  deal_deposit_status_aggregate: Deal_Deposit_Status_Aggregate;
  /** fetch data from the table: "deal_deposit_status" using primary key columns */
  deal_deposit_status_by_pk?: Maybe<Deal_Deposit_Status>;
  /** fetch data from the table in a streaming manner: "deal_deposit_status" */
  deal_deposit_status_stream: Array<Deal_Deposit_Status>;
  /** fetch data from the table: "deal_deposit_type" */
  deal_deposit_type: Array<Deal_Deposit_Type>;
  /** fetch aggregated fields from the table: "deal_deposit_type" */
  deal_deposit_type_aggregate: Deal_Deposit_Type_Aggregate;
  /** fetch data from the table: "deal_deposit_type" using primary key columns */
  deal_deposit_type_by_pk?: Maybe<Deal_Deposit_Type>;
  /** fetch data from the table in a streaming manner: "deal_deposit_type" */
  deal_deposit_type_stream: Array<Deal_Deposit_Type>;
  /** fetch data from the table: "deal_enquiry_source" */
  deal_enquiry_source: Array<Deal_Enquiry_Source>;
  /** fetch aggregated fields from the table: "deal_enquiry_source" */
  deal_enquiry_source_aggregate: Deal_Enquiry_Source_Aggregate;
  /** fetch data from the table: "deal_enquiry_source" using primary key columns */
  deal_enquiry_source_by_pk?: Maybe<Deal_Enquiry_Source>;
  /** fetch data from the table in a streaming manner: "deal_enquiry_source" */
  deal_enquiry_source_stream: Array<Deal_Enquiry_Source>;
  /** fetch data from the table: "deal_fallen_reason" */
  deal_fallen_reason: Array<Deal_Fallen_Reason>;
  /** fetch aggregated fields from the table: "deal_fallen_reason" */
  deal_fallen_reason_aggregate: Deal_Fallen_Reason_Aggregate;
  /** fetch data from the table: "deal_fallen_reason" using primary key columns */
  deal_fallen_reason_by_pk?: Maybe<Deal_Fallen_Reason>;
  /** fetch data from the table in a streaming manner: "deal_fallen_reason" */
  deal_fallen_reason_stream: Array<Deal_Fallen_Reason>;
  /** fetch data from the table: "deal_payment_method" */
  deal_payment_method: Array<Deal_Payment_Method>;
  /** fetch aggregated fields from the table: "deal_payment_method" */
  deal_payment_method_aggregate: Deal_Payment_Method_Aggregate;
  /** fetch data from the table: "deal_payment_method" using primary key columns */
  deal_payment_method_by_pk?: Maybe<Deal_Payment_Method>;
  /** fetch data from the table in a streaming manner: "deal_payment_method" */
  deal_payment_method_stream: Array<Deal_Payment_Method>;
  /** fetch data from the table: "deal_progress_update" */
  deal_progress_update: Array<Deal_Progress_Update>;
  /** fetch aggregated fields from the table: "deal_progress_update" */
  deal_progress_update_aggregate: Deal_Progress_Update_Aggregate;
  /** fetch data from the table: "deal_progress_update" using primary key columns */
  deal_progress_update_by_pk?: Maybe<Deal_Progress_Update>;
  /** fetch data from the table in a streaming manner: "deal_progress_update" */
  deal_progress_update_stream: Array<Deal_Progress_Update>;
  /** fetch data from the table: "deal_reason_purchase" */
  deal_reason_purchase: Array<Deal_Reason_Purchase>;
  /** fetch aggregated fields from the table: "deal_reason_purchase" */
  deal_reason_purchase_aggregate: Deal_Reason_Purchase_Aggregate;
  /** fetch data from the table: "deal_reason_purchase" using primary key columns */
  deal_reason_purchase_by_pk?: Maybe<Deal_Reason_Purchase>;
  /** fetch data from the table in a streaming manner: "deal_reason_purchase" */
  deal_reason_purchase_stream: Array<Deal_Reason_Purchase>;
  /** fetch data from the table: "deal_sf_action" */
  deal_sf_action: Array<Deal_Sf_Action>;
  /** fetch aggregated fields from the table: "deal_sf_action" */
  deal_sf_action_aggregate: Deal_Sf_Action_Aggregate;
  /** fetch data from the table: "deal_sf_action" using primary key columns */
  deal_sf_action_by_pk?: Maybe<Deal_Sf_Action>;
  /** fetch data from the table in a streaming manner: "deal_sf_action" */
  deal_sf_action_stream: Array<Deal_Sf_Action>;
  /** fetch data from the table: "deal_spoken_language" */
  deal_spoken_language: Array<Deal_Spoken_Language>;
  /** fetch aggregated fields from the table: "deal_spoken_language" */
  deal_spoken_language_aggregate: Deal_Spoken_Language_Aggregate;
  /** fetch data from the table: "deal_spoken_language" using primary key columns */
  deal_spoken_language_by_pk?: Maybe<Deal_Spoken_Language>;
  /** fetch data from the table in a streaming manner: "deal_spoken_language" */
  deal_spoken_language_stream: Array<Deal_Spoken_Language>;
  /** fetch data from the table: "deal_status" */
  deal_status: Array<Deal_Status>;
  /** fetch aggregated fields from the table: "deal_status" */
  deal_status_aggregate: Deal_Status_Aggregate;
  /** fetch data from the table: "deal_status" using primary key columns */
  deal_status_by_pk?: Maybe<Deal_Status>;
  /** fetch data from the table in a streaming manner: "deal_status" */
  deal_status_stream: Array<Deal_Status>;
  /** fetch data from the table in a streaming manner: "deal" */
  deal_stream: Array<Deal>;
  /** fetch data from the table: "deal_transfer_status" */
  deal_transfer_status: Array<Deal_Transfer_Status>;
  /** fetch aggregated fields from the table: "deal_transfer_status" */
  deal_transfer_status_aggregate: Deal_Transfer_Status_Aggregate;
  /** fetch data from the table: "deal_transfer_status" using primary key columns */
  deal_transfer_status_by_pk?: Maybe<Deal_Transfer_Status>;
  /** fetch data from the table in a streaming manner: "deal_transfer_status" */
  deal_transfer_status_stream: Array<Deal_Transfer_Status>;
  /** fetch data from the table: "deal_type" */
  deal_type: Array<Deal_Type>;
  /** fetch aggregated fields from the table: "deal_type" */
  deal_type_aggregate: Deal_Type_Aggregate;
  /** fetch data from the table: "deal_type" using primary key columns */
  deal_type_by_pk?: Maybe<Deal_Type>;
  /** fetch data from the table in a streaming manner: "deal_type" */
  deal_type_stream: Array<Deal_Type>;
  /** fetch data from the table: "deal_valuation_booking" */
  deal_valuation_booking: Array<Deal_Valuation_Booking>;
  /** fetch aggregated fields from the table: "deal_valuation_booking" */
  deal_valuation_booking_aggregate: Deal_Valuation_Booking_Aggregate;
  /** fetch data from the table: "deal_valuation_booking" using primary key columns */
  deal_valuation_booking_by_pk?: Maybe<Deal_Valuation_Booking>;
  /** fetch data from the table in a streaming manner: "deal_valuation_booking" */
  deal_valuation_booking_stream: Array<Deal_Valuation_Booking>;
  /** fetch data from the table: "deal_witness" */
  deal_witness: Array<Deal_Witness>;
  /** fetch aggregated fields from the table: "deal_witness" */
  deal_witness_aggregate: Deal_Witness_Aggregate;
  /** fetch data from the table: "deal_witness" using primary key columns */
  deal_witness_by_pk?: Maybe<Deal_Witness>;
  /** fetch data from the table in a streaming manner: "deal_witness" */
  deal_witness_stream: Array<Deal_Witness>;
  /** fetch data from the table: "developer_agent" */
  developer_agent: Array<Developer_Agent>;
  /** fetch aggregated fields from the table: "developer_agent" */
  developer_agent_aggregate: Developer_Agent_Aggregate;
  /** fetch data from the table: "developer_agent" using primary key columns */
  developer_agent_by_pk?: Maybe<Developer_Agent>;
  /** fetch data from the table in a streaming manner: "developer_agent" */
  developer_agent_stream: Array<Developer_Agent>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "document_expired" */
  document_expired: Array<Document_Expired>;
  /** fetch aggregated fields from the table: "document_expired" */
  document_expired_aggregate: Document_Expired_Aggregate;
  /** fetch data from the table in a streaming manner: "document_expired" */
  document_expired_stream: Array<Document_Expired>;
  /** fetch data from the table in a streaming manner: "document" */
  document_stream: Array<Document>;
  /** fetch data from the table: "document_type" */
  document_type: Array<Document_Type>;
  /** fetch aggregated fields from the table: "document_type" */
  document_type_aggregate: Document_Type_Aggregate;
  /** fetch data from the table: "document_type" using primary key columns */
  document_type_by_pk?: Maybe<Document_Type>;
  /** fetch data from the table in a streaming manner: "document_type" */
  document_type_stream: Array<Document_Type>;
  /** fetch data from the table: "embed" */
  embed: Array<Embed>;
  /** fetch aggregated fields from the table: "embed" */
  embed_aggregate: Embed_Aggregate;
  /** fetch data from the table: "embed" using primary key columns */
  embed_by_pk?: Maybe<Embed>;
  /** fetch data from the table in a streaming manner: "embed" */
  embed_stream: Array<Embed>;
  /** fetch data from the table: "envelope" */
  envelope: Array<Envelope>;
  /** fetch aggregated fields from the table: "envelope" */
  envelope_aggregate: Envelope_Aggregate;
  /** fetch data from the table: "envelope" using primary key columns */
  envelope_by_pk?: Maybe<Envelope>;
  /** fetch data from the table: "envelope_owner" */
  envelope_owner: Array<Envelope_Owner>;
  /** fetch aggregated fields from the table: "envelope_owner" */
  envelope_owner_aggregate: Envelope_Owner_Aggregate;
  /** fetch data from the table: "envelope_owner" using primary key columns */
  envelope_owner_by_pk?: Maybe<Envelope_Owner>;
  /** fetch data from the table in a streaming manner: "envelope_owner" */
  envelope_owner_stream: Array<Envelope_Owner>;
  /** fetch data from the table: "envelope_status" */
  envelope_status: Array<Envelope_Status>;
  /** fetch aggregated fields from the table: "envelope_status" */
  envelope_status_aggregate: Envelope_Status_Aggregate;
  /** fetch data from the table: "envelope_status" using primary key columns */
  envelope_status_by_pk?: Maybe<Envelope_Status>;
  /** fetch data from the table in a streaming manner: "envelope_status" */
  envelope_status_stream: Array<Envelope_Status>;
  /** fetch data from the table in a streaming manner: "envelope" */
  envelope_stream: Array<Envelope>;
  /** fetch data from the table: "envelope_type" */
  envelope_type: Array<Envelope_Type>;
  /** fetch aggregated fields from the table: "envelope_type" */
  envelope_type_aggregate: Envelope_Type_Aggregate;
  /** fetch data from the table: "envelope_type" using primary key columns */
  envelope_type_by_pk?: Maybe<Envelope_Type>;
  /** fetch data from the table in a streaming manner: "envelope_type" */
  envelope_type_stream: Array<Envelope_Type>;
  /** fetch data from the table: "experiment" */
  experiment: Array<Experiment>;
  /** fetch aggregated fields from the table: "experiment" */
  experiment_aggregate: Experiment_Aggregate;
  /** fetch data from the table: "experiment" using primary key columns */
  experiment_by_pk?: Maybe<Experiment>;
  /** fetch data from the table in a streaming manner: "experiment" */
  experiment_stream: Array<Experiment>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk?: Maybe<Feature>;
  /** fetch data from the table: "feature_check" */
  feature_check: Array<Feature_Check>;
  /** fetch aggregated fields from the table: "feature_check" */
  feature_check_aggregate: Feature_Check_Aggregate;
  /** fetch data from the table: "feature_check" using primary key columns */
  feature_check_by_pk?: Maybe<Feature_Check>;
  /** fetch data from the table in a streaming manner: "feature_check" */
  feature_check_stream: Array<Feature_Check>;
  /** fetch data from the table: "feature_pack" */
  feature_pack: Array<Feature_Pack>;
  /** fetch aggregated fields from the table: "feature_pack" */
  feature_pack_aggregate: Feature_Pack_Aggregate;
  /** fetch data from the table: "feature_pack" using primary key columns */
  feature_pack_by_pk?: Maybe<Feature_Pack>;
  /** fetch data from the table: "feature_pack_feature" */
  feature_pack_feature: Array<Feature_Pack_Feature>;
  /** fetch aggregated fields from the table: "feature_pack_feature" */
  feature_pack_feature_aggregate: Feature_Pack_Feature_Aggregate;
  /** fetch data from the table: "feature_pack_feature" using primary key columns */
  feature_pack_feature_by_pk?: Maybe<Feature_Pack_Feature>;
  /** fetch data from the table in a streaming manner: "feature_pack_feature" */
  feature_pack_feature_stream: Array<Feature_Pack_Feature>;
  /** fetch data from the table in a streaming manner: "feature_pack" */
  feature_pack_stream: Array<Feature_Pack>;
  /** fetch data from the table in a streaming manner: "feature" */
  feature_stream: Array<Feature>;
  /** fetch data from the table: "filter_page" */
  filter_page: Array<Filter_Page>;
  /** fetch aggregated fields from the table: "filter_page" */
  filter_page_aggregate: Filter_Page_Aggregate;
  /** fetch data from the table: "filter_page" using primary key columns */
  filter_page_by_pk?: Maybe<Filter_Page>;
  /** fetch data from the table in a streaming manner: "filter_page" */
  filter_page_stream: Array<Filter_Page>;
  /** fetch data from the table: "filter_state" */
  filter_state: Array<Filter_State>;
  /** fetch aggregated fields from the table: "filter_state" */
  filter_state_aggregate: Filter_State_Aggregate;
  /** fetch data from the table: "filter_state" using primary key columns */
  filter_state_by_pk?: Maybe<Filter_State>;
  /** fetch data from the table in a streaming manner: "filter_state" */
  filter_state_stream: Array<Filter_State>;
  /** fetch data from the table: "finance_status" */
  finance_status: Array<Finance_Status>;
  /** fetch aggregated fields from the table: "finance_status" */
  finance_status_aggregate: Finance_Status_Aggregate;
  /** fetch data from the table: "finance_status" using primary key columns */
  finance_status_by_pk?: Maybe<Finance_Status>;
  /** fetch data from the table in a streaming manner: "finance_status" */
  finance_status_stream: Array<Finance_Status>;
  /** fetch data from the table: "graphql_query_log" */
  graphql_query_log: Array<Graphql_Query_Log>;
  /** fetch aggregated fields from the table: "graphql_query_log" */
  graphql_query_log_aggregate: Graphql_Query_Log_Aggregate;
  /** fetch data from the table: "graphql_query_log" using primary key columns */
  graphql_query_log_by_pk?: Maybe<Graphql_Query_Log>;
  /** fetch data from the table in a streaming manner: "graphql_query_log" */
  graphql_query_log_stream: Array<Graphql_Query_Log>;
  /** fetch data from the table: "hubspot_action" */
  hubspot_action: Array<Hubspot_Action>;
  /** fetch aggregated fields from the table: "hubspot_action" */
  hubspot_action_aggregate: Hubspot_Action_Aggregate;
  /** fetch data from the table: "hubspot_action" using primary key columns */
  hubspot_action_by_pk?: Maybe<Hubspot_Action>;
  /** fetch data from the table in a streaming manner: "hubspot_action" */
  hubspot_action_stream: Array<Hubspot_Action>;
  /** fetch data from the table: "hubspot_action_type" */
  hubspot_action_type: Array<Hubspot_Action_Type>;
  /** fetch aggregated fields from the table: "hubspot_action_type" */
  hubspot_action_type_aggregate: Hubspot_Action_Type_Aggregate;
  /** fetch data from the table: "hubspot_action_type" using primary key columns */
  hubspot_action_type_by_pk?: Maybe<Hubspot_Action_Type>;
  /** fetch data from the table in a streaming manner: "hubspot_action_type" */
  hubspot_action_type_stream: Array<Hubspot_Action_Type>;
  /** fetch data from the table: "hubspot_contact" */
  hubspot_contact: Array<Hubspot_Contact>;
  /** fetch aggregated fields from the table: "hubspot_contact" */
  hubspot_contact_aggregate: Hubspot_Contact_Aggregate;
  /** fetch data from the table: "hubspot_contact" using primary key columns */
  hubspot_contact_by_pk?: Maybe<Hubspot_Contact>;
  /** fetch data from the table in a streaming manner: "hubspot_contact" */
  hubspot_contact_stream: Array<Hubspot_Contact>;
  /** fetch data from the table: "hubspot_deal" */
  hubspot_deal: Array<Hubspot_Deal>;
  /** fetch aggregated fields from the table: "hubspot_deal" */
  hubspot_deal_aggregate: Hubspot_Deal_Aggregate;
  /** fetch data from the table: "hubspot_deal" using primary key columns */
  hubspot_deal_by_pk?: Maybe<Hubspot_Deal>;
  /** fetch data from the table in a streaming manner: "hubspot_deal" */
  hubspot_deal_stream: Array<Hubspot_Deal>;
  /** fetch data from the table: "hubspot_organisation" */
  hubspot_organisation: Array<Hubspot_Organisation>;
  /** fetch aggregated fields from the table: "hubspot_organisation" */
  hubspot_organisation_aggregate: Hubspot_Organisation_Aggregate;
  /** fetch data from the table: "hubspot_organisation" using primary key columns */
  hubspot_organisation_by_pk?: Maybe<Hubspot_Organisation>;
  /** fetch data from the table in a streaming manner: "hubspot_organisation" */
  hubspot_organisation_stream: Array<Hubspot_Organisation>;
  /** fetch data from the table: "hubspot_project" */
  hubspot_project: Array<Hubspot_Project>;
  /** fetch aggregated fields from the table: "hubspot_project" */
  hubspot_project_aggregate: Hubspot_Project_Aggregate;
  /** fetch data from the table: "hubspot_project" using primary key columns */
  hubspot_project_by_pk?: Maybe<Hubspot_Project>;
  /** fetch data from the table in a streaming manner: "hubspot_project" */
  hubspot_project_stream: Array<Hubspot_Project>;
  /** fetch data from the table: "hubspot_schema_error_log" */
  hubspot_schema_error_log: Array<Hubspot_Schema_Error_Log>;
  /** fetch aggregated fields from the table: "hubspot_schema_error_log" */
  hubspot_schema_error_log_aggregate: Hubspot_Schema_Error_Log_Aggregate;
  /** fetch data from the table: "hubspot_schema_error_log" using primary key columns */
  hubspot_schema_error_log_by_pk?: Maybe<Hubspot_Schema_Error_Log>;
  /** fetch data from the table in a streaming manner: "hubspot_schema_error_log" */
  hubspot_schema_error_log_stream: Array<Hubspot_Schema_Error_Log>;
  /** fetch data from the table: "hubspot_user" */
  hubspot_user: Array<Hubspot_User>;
  /** fetch aggregated fields from the table: "hubspot_user" */
  hubspot_user_aggregate: Hubspot_User_Aggregate;
  /** fetch data from the table: "hubspot_user" using primary key columns */
  hubspot_user_by_pk?: Maybe<Hubspot_User>;
  /** fetch data from the table in a streaming manner: "hubspot_user" */
  hubspot_user_stream: Array<Hubspot_User>;
  /** fetch data from the table: "hubspot_valuation_booking" */
  hubspot_valuation_booking: Array<Hubspot_Valuation_Booking>;
  /** fetch aggregated fields from the table: "hubspot_valuation_booking" */
  hubspot_valuation_booking_aggregate: Hubspot_Valuation_Booking_Aggregate;
  /** fetch data from the table: "hubspot_valuation_booking" using primary key columns */
  hubspot_valuation_booking_by_pk?: Maybe<Hubspot_Valuation_Booking>;
  /** fetch data from the table in a streaming manner: "hubspot_valuation_booking" */
  hubspot_valuation_booking_stream: Array<Hubspot_Valuation_Booking>;
  /** fetch data from the table: "invitation" */
  invitation: Array<Invitation>;
  /** fetch aggregated fields from the table: "invitation" */
  invitation_aggregate: Invitation_Aggregate;
  /** fetch data from the table: "invitation" using primary key columns */
  invitation_by_pk?: Maybe<Invitation>;
  /** fetch data from the table: "invitation_status" */
  invitation_status: Array<Invitation_Status>;
  /** fetch aggregated fields from the table: "invitation_status" */
  invitation_status_aggregate: Invitation_Status_Aggregate;
  /** fetch data from the table: "invitation_status" using primary key columns */
  invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** fetch data from the table in a streaming manner: "invitation_status" */
  invitation_status_stream: Array<Invitation_Status>;
  /** fetch data from the table in a streaming manner: "invitation" */
  invitation_stream: Array<Invitation>;
  /** fetch data from the table: "invitation_type" */
  invitation_type: Array<Invitation_Type>;
  /** fetch aggregated fields from the table: "invitation_type" */
  invitation_type_aggregate: Invitation_Type_Aggregate;
  /** fetch data from the table: "invitation_type" using primary key columns */
  invitation_type_by_pk?: Maybe<Invitation_Type>;
  /** fetch data from the table in a streaming manner: "invitation_type" */
  invitation_type_stream: Array<Invitation_Type>;
  /** fetch data from the table: "lambda_log" */
  lambda_log: Array<Lambda_Log>;
  /** fetch aggregated fields from the table: "lambda_log" */
  lambda_log_aggregate: Lambda_Log_Aggregate;
  /** fetch data from the table: "lambda_log" using primary key columns */
  lambda_log_by_pk?: Maybe<Lambda_Log>;
  /** fetch data from the table in a streaming manner: "lambda_log" */
  lambda_log_stream: Array<Lambda_Log>;
  /** fetch data from the table: "lead_generation" */
  lead_generation: Array<Lead_Generation>;
  /** fetch aggregated fields from the table: "lead_generation" */
  lead_generation_aggregate: Lead_Generation_Aggregate;
  /** fetch data from the table: "lead_generation" using primary key columns */
  lead_generation_by_pk?: Maybe<Lead_Generation>;
  /** fetch data from the table: "lead_generation_source" */
  lead_generation_source: Array<Lead_Generation_Source>;
  /** fetch aggregated fields from the table: "lead_generation_source" */
  lead_generation_source_aggregate: Lead_Generation_Source_Aggregate;
  /** fetch data from the table: "lead_generation_source" using primary key columns */
  lead_generation_source_by_pk?: Maybe<Lead_Generation_Source>;
  /** fetch data from the table in a streaming manner: "lead_generation_source" */
  lead_generation_source_stream: Array<Lead_Generation_Source>;
  /** fetch data from the table in a streaming manner: "lead_generation" */
  lead_generation_stream: Array<Lead_Generation>;
  /** fetch data from the table: "lead_generation_webhook_log" */
  lead_generation_webhook_log: Array<Lead_Generation_Webhook_Log>;
  /** fetch aggregated fields from the table: "lead_generation_webhook_log" */
  lead_generation_webhook_log_aggregate: Lead_Generation_Webhook_Log_Aggregate;
  /** fetch data from the table: "lead_generation_webhook_log" using primary key columns */
  lead_generation_webhook_log_by_pk?: Maybe<Lead_Generation_Webhook_Log>;
  /** fetch data from the table in a streaming manner: "lead_generation_webhook_log" */
  lead_generation_webhook_log_stream: Array<Lead_Generation_Webhook_Log>;
  /** fetch data from the table: "listing" */
  listing: Array<Listing>;
  /** fetch aggregated fields from the table: "listing" */
  listing_aggregate: Listing_Aggregate;
  /** fetch data from the table: "listing" using primary key columns */
  listing_by_pk?: Maybe<Listing>;
  /** fetch data from the table: "listing_price_per_sqmt" */
  listing_price_per_sqmt: Array<Listing_Price_Per_Sqmt>;
  /** fetch aggregated fields from the table: "listing_price_per_sqmt" */
  listing_price_per_sqmt_aggregate: Listing_Price_Per_Sqmt_Aggregate;
  /** fetch data from the table in a streaming manner: "listing_price_per_sqmt" */
  listing_price_per_sqmt_stream: Array<Listing_Price_Per_Sqmt>;
  /** fetch data from the table: "listing_publish_errors" */
  listing_publish_errors: Array<Listing_Publish_Errors>;
  /** fetch aggregated fields from the table: "listing_publish_errors" */
  listing_publish_errors_aggregate: Listing_Publish_Errors_Aggregate;
  /** fetch data from the table: "listing_publish_errors" using primary key columns */
  listing_publish_errors_by_pk?: Maybe<Listing_Publish_Errors>;
  /** fetch data from the table in a streaming manner: "listing_publish_errors" */
  listing_publish_errors_stream: Array<Listing_Publish_Errors>;
  /** fetch data from the table: "listing_publish_portal_source" */
  listing_publish_portal_source: Array<Listing_Publish_Portal_Source>;
  /** fetch aggregated fields from the table: "listing_publish_portal_source" */
  listing_publish_portal_source_aggregate: Listing_Publish_Portal_Source_Aggregate;
  /** fetch data from the table: "listing_publish_portal_source" using primary key columns */
  listing_publish_portal_source_by_pk?: Maybe<Listing_Publish_Portal_Source>;
  /** fetch data from the table in a streaming manner: "listing_publish_portal_source" */
  listing_publish_portal_source_stream: Array<Listing_Publish_Portal_Source>;
  /** fetch data from the table: "listing_publish_status" */
  listing_publish_status: Array<Listing_Publish_Status>;
  /** fetch aggregated fields from the table: "listing_publish_status" */
  listing_publish_status_aggregate: Listing_Publish_Status_Aggregate;
  /** fetch data from the table: "listing_publish_status" using primary key columns */
  listing_publish_status_by_pk?: Maybe<Listing_Publish_Status>;
  /** fetch data from the table in a streaming manner: "listing_publish_status" */
  listing_publish_status_stream: Array<Listing_Publish_Status>;
  /** fetch data from the table: "listing_publish_status_type" */
  listing_publish_status_type: Array<Listing_Publish_Status_Type>;
  /** fetch aggregated fields from the table: "listing_publish_status_type" */
  listing_publish_status_type_aggregate: Listing_Publish_Status_Type_Aggregate;
  /** fetch data from the table: "listing_publish_status_type" using primary key columns */
  listing_publish_status_type_by_pk?: Maybe<Listing_Publish_Status_Type>;
  /** fetch data from the table in a streaming manner: "listing_publish_status_type" */
  listing_publish_status_type_stream: Array<Listing_Publish_Status_Type>;
  /** fetch data from the table: "listing_status" */
  listing_status: Array<Listing_Status>;
  /** fetch aggregated fields from the table: "listing_status" */
  listing_status_aggregate: Listing_Status_Aggregate;
  /** fetch data from the table: "listing_status" using primary key columns */
  listing_status_by_pk?: Maybe<Listing_Status>;
  /** fetch data from the table in a streaming manner: "listing_status" */
  listing_status_stream: Array<Listing_Status>;
  /** fetch data from the table in a streaming manner: "listing" */
  listing_stream: Array<Listing>;
  /** fetch data from the table: "member" */
  member: Array<Member>;
  /** fetch aggregated fields from the table: "member" */
  member_aggregate: Member_Aggregate;
  /** fetch data from the table: "member" using primary key columns */
  member_by_pk?: Maybe<Member>;
  /** fetch data from the table: "member_feature" */
  member_feature: Array<Member_Feature>;
  /** fetch aggregated fields from the table: "member_feature" */
  member_feature_aggregate: Member_Feature_Aggregate;
  /** fetch data from the table: "member_feature" using primary key columns */
  member_feature_by_pk?: Maybe<Member_Feature>;
  /** fetch data from the table in a streaming manner: "member_feature" */
  member_feature_stream: Array<Member_Feature>;
  /** fetch data from the table: "member_project_allocate" */
  member_project_allocate: Array<Member_Project_Allocate>;
  /** fetch aggregated fields from the table: "member_project_allocate" */
  member_project_allocate_aggregate: Member_Project_Allocate_Aggregate;
  /** fetch data from the table: "member_project_allocate" using primary key columns */
  member_project_allocate_by_pk?: Maybe<Member_Project_Allocate>;
  /** fetch data from the table in a streaming manner: "member_project_allocate" */
  member_project_allocate_stream: Array<Member_Project_Allocate>;
  /** fetch data from the table: "member_role" */
  member_role: Array<Member_Role>;
  /** fetch aggregated fields from the table: "member_role" */
  member_role_aggregate: Member_Role_Aggregate;
  /** fetch data from the table: "member_role" using primary key columns */
  member_role_by_pk?: Maybe<Member_Role>;
  /** fetch data from the table in a streaming manner: "member_role" */
  member_role_stream: Array<Member_Role>;
  /** fetch data from the table in a streaming manner: "member" */
  member_stream: Array<Member>;
  /** fetch data from the table: "nationality" */
  nationality: Array<Nationality>;
  /** fetch aggregated fields from the table: "nationality" */
  nationality_aggregate: Nationality_Aggregate;
  /** fetch data from the table: "nationality" using primary key columns */
  nationality_by_pk?: Maybe<Nationality>;
  /** fetch data from the table in a streaming manner: "nationality" */
  nationality_stream: Array<Nationality>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_channel" */
  notification_channel: Array<Notification_Channel>;
  /** fetch aggregated fields from the table: "notification_channel" */
  notification_channel_aggregate: Notification_Channel_Aggregate;
  /** fetch data from the table: "notification_channel" using primary key columns */
  notification_channel_by_pk?: Maybe<Notification_Channel>;
  /** fetch data from the table in a streaming manner: "notification_channel" */
  notification_channel_stream: Array<Notification_Channel>;
  /** fetch data from the table: "notification_owner" */
  notification_owner: Array<Notification_Owner>;
  /** fetch aggregated fields from the table: "notification_owner" */
  notification_owner_aggregate: Notification_Owner_Aggregate;
  /** fetch data from the table: "notification_owner" using primary key columns */
  notification_owner_by_pk?: Maybe<Notification_Owner>;
  /** fetch data from the table in a streaming manner: "notification_owner" */
  notification_owner_stream: Array<Notification_Owner>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<Notification_Type>;
  /** execute function "org_feature_check" which returns "feature_check" */
  org_feature_check: Array<Feature_Check>;
  /** execute function "org_feature_check" and query aggregates on result of table type "feature_check" */
  org_feature_check_aggregate: Feature_Check_Aggregate;
  /** fetch data from the table: "org_feature_enabled" */
  org_feature_enabled: Array<Org_Feature_Enabled>;
  /** fetch aggregated fields from the table: "org_feature_enabled" */
  org_feature_enabled_aggregate: Org_Feature_Enabled_Aggregate;
  /** fetch data from the table in a streaming manner: "org_feature_enabled" */
  org_feature_enabled_stream: Array<Org_Feature_Enabled>;
  /** An array relationship */
  org_feature_pack: Array<Org_Feature_Pack>;
  /** An aggregate relationship */
  org_feature_pack_aggregate: Org_Feature_Pack_Aggregate;
  /** fetch data from the table: "org_feature_pack" using primary key columns */
  org_feature_pack_by_pk?: Maybe<Org_Feature_Pack>;
  /** fetch data from the table in a streaming manner: "org_feature_pack" */
  org_feature_pack_stream: Array<Org_Feature_Pack>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation_allocation_counts_view" */
  organisation_allocation_counts_view: Array<Organisation_Allocation_Counts_View>;
  /** fetch aggregated fields from the table: "organisation_allocation_counts_view" */
  organisation_allocation_counts_view_aggregate: Organisation_Allocation_Counts_View_Aggregate;
  /** fetch data from the table in a streaming manner: "organisation_allocation_counts_view" */
  organisation_allocation_counts_view_stream: Array<Organisation_Allocation_Counts_View>;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table: "organisation_feature" */
  organisation_feature: Array<Organisation_Feature>;
  /** fetch aggregated fields from the table: "organisation_feature" */
  organisation_feature_aggregate: Organisation_Feature_Aggregate;
  /** fetch data from the table: "organisation_feature" using primary key columns */
  organisation_feature_by_pk?: Maybe<Organisation_Feature>;
  /** fetch data from the table in a streaming manner: "organisation_feature" */
  organisation_feature_stream: Array<Organisation_Feature>;
  /** fetch data from the table in a streaming manner: "organisation" */
  organisation_stream: Array<Organisation>;
  /** fetch data from the table: "organisation_type" */
  organisation_type: Array<Organisation_Type>;
  /** fetch aggregated fields from the table: "organisation_type" */
  organisation_type_aggregate: Organisation_Type_Aggregate;
  /** fetch data from the table: "organisation_type" using primary key columns */
  organisation_type_by_pk?: Maybe<Organisation_Type>;
  /** fetch data from the table in a streaming manner: "organisation_type" */
  organisation_type_stream: Array<Organisation_Type>;
  /** fetch data from the table: "partner_portal" */
  partner_portal: Array<Partner_Portal>;
  /** fetch aggregated fields from the table: "partner_portal" */
  partner_portal_aggregate: Partner_Portal_Aggregate;
  /** fetch data from the table: "partner_portal" using primary key columns */
  partner_portal_by_pk?: Maybe<Partner_Portal>;
  /** fetch data from the table in a streaming manner: "partner_portal" */
  partner_portal_stream: Array<Partner_Portal>;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table in a streaming manner: "payment_method" */
  payment_method_stream: Array<Payment_Method>;
  /** fetch data from the table: "payment_method_type" */
  payment_method_type: Array<Payment_Method_Type>;
  /** fetch aggregated fields from the table: "payment_method_type" */
  payment_method_type_aggregate: Payment_Method_Type_Aggregate;
  /** fetch data from the table: "payment_method_type" using primary key columns */
  payment_method_type_by_pk?: Maybe<Payment_Method_Type>;
  /** fetch data from the table in a streaming manner: "payment_method_type" */
  payment_method_type_stream: Array<Payment_Method_Type>;
  /** fetch data from the table: "preference" */
  preference: Array<Preference>;
  /** fetch aggregated fields from the table: "preference" */
  preference_aggregate: Preference_Aggregate;
  /** fetch data from the table: "preference" using primary key columns */
  preference_by_pk?: Maybe<Preference>;
  /** fetch data from the table in a streaming manner: "preference" */
  preference_stream: Array<Preference>;
  /** fetch data from the table: "process" */
  process: Array<Process>;
  /** fetch aggregated fields from the table: "process" */
  process_aggregate: Process_Aggregate;
  /** fetch data from the table: "process" using primary key columns */
  process_by_pk?: Maybe<Process>;
  /** fetch data from the table: "process_status" */
  process_status: Array<Process_Status>;
  /** fetch aggregated fields from the table: "process_status" */
  process_status_aggregate: Process_Status_Aggregate;
  /** fetch data from the table: "process_status" using primary key columns */
  process_status_by_pk?: Maybe<Process_Status>;
  /** fetch data from the table in a streaming manner: "process_status" */
  process_status_stream: Array<Process_Status>;
  /** fetch data from the table in a streaming manner: "process" */
  process_stream: Array<Process>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project_apartment_level" */
  project_apartment_level: Array<Project_Apartment_Level>;
  /** fetch aggregated fields from the table: "project_apartment_level" */
  project_apartment_level_aggregate: Project_Apartment_Level_Aggregate;
  /** fetch data from the table in a streaming manner: "project_apartment_level" */
  project_apartment_level_stream: Array<Project_Apartment_Level>;
  /** fetch data from the table: "project_apartment_stat" */
  project_apartment_stat: Array<Project_Apartment_Stat>;
  /** fetch aggregated fields from the table: "project_apartment_stat" */
  project_apartment_stat_aggregate: Project_Apartment_Stat_Aggregate;
  /** fetch data from the table in a streaming manner: "project_apartment_stat" */
  project_apartment_stat_stream: Array<Project_Apartment_Stat>;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_client_count" */
  project_client_count: Array<Project_Client_Count>;
  /** fetch aggregated fields from the table: "project_client_count" */
  project_client_count_aggregate: Project_Client_Count_Aggregate;
  /** execute function "project_client_count_for_user" which returns "project_client_count" */
  project_client_count_for_user: Array<Project_Client_Count>;
  /** execute function "project_client_count_for_user" and query aggregates on result of table type "project_client_count" */
  project_client_count_for_user_aggregate: Project_Client_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "project_client_count" */
  project_client_count_stream: Array<Project_Client_Count>;
  /** fetch data from the table: "project_detail" */
  project_detail: Array<Project_Detail>;
  /** fetch aggregated fields from the table: "project_detail" */
  project_detail_aggregate: Project_Detail_Aggregate;
  /** fetch data from the table: "project_detail" using primary key columns */
  project_detail_by_pk?: Maybe<Project_Detail>;
  /** fetch data from the table in a streaming manner: "project_detail" */
  project_detail_stream: Array<Project_Detail>;
  /** fetch data from the table: "project_features" */
  project_features: Array<Project_Features>;
  /** fetch aggregated fields from the table: "project_features" */
  project_features_aggregate: Project_Features_Aggregate;
  /** fetch data from the table: "project_features" using primary key columns */
  project_features_by_pk?: Maybe<Project_Features>;
  /** fetch data from the table in a streaming manner: "project_features" */
  project_features_stream: Array<Project_Features>;
  /** fetch data from the table: "project_payment_method" */
  project_payment_method: Array<Project_Payment_Method>;
  /** fetch aggregated fields from the table: "project_payment_method" */
  project_payment_method_aggregate: Project_Payment_Method_Aggregate;
  /** fetch data from the table: "project_payment_method" using primary key columns */
  project_payment_method_by_pk?: Maybe<Project_Payment_Method>;
  /** fetch data from the table in a streaming manner: "project_payment_method" */
  project_payment_method_stream: Array<Project_Payment_Method>;
  /** fetch data from the table: "project_qr_code_for_buyer" */
  project_qr_code_for_buyer: Array<Project_Qr_Code_For_Buyer>;
  /** fetch aggregated fields from the table: "project_qr_code_for_buyer" */
  project_qr_code_for_buyer_aggregate: Project_Qr_Code_For_Buyer_Aggregate;
  /** fetch data from the table: "project_qr_code_for_buyer" using primary key columns */
  project_qr_code_for_buyer_by_pk?: Maybe<Project_Qr_Code_For_Buyer>;
  /** fetch data from the table in a streaming manner: "project_qr_code_for_buyer" */
  project_qr_code_for_buyer_stream: Array<Project_Qr_Code_For_Buyer>;
  /** fetch data from the table: "project_reservation_setting" */
  project_reservation_setting: Array<Project_Reservation_Setting>;
  /** fetch aggregated fields from the table: "project_reservation_setting" */
  project_reservation_setting_aggregate: Project_Reservation_Setting_Aggregate;
  /** fetch data from the table: "project_reservation_setting" using primary key columns */
  project_reservation_setting_by_pk?: Maybe<Project_Reservation_Setting>;
  /** fetch data from the table in a streaming manner: "project_reservation_setting" */
  project_reservation_setting_stream: Array<Project_Reservation_Setting>;
  /** fetch data from the table: "project_sources" */
  project_sources: Array<Project_Sources>;
  /** fetch aggregated fields from the table: "project_sources" */
  project_sources_aggregate: Project_Sources_Aggregate;
  /** fetch data from the table: "project_sources" using primary key columns */
  project_sources_by_pk?: Maybe<Project_Sources>;
  /** fetch data from the table in a streaming manner: "project_sources" */
  project_sources_stream: Array<Project_Sources>;
  /** fetch data from the table: "project_status" */
  project_status: Array<Project_Status>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: Project_Status_Aggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<Project_Status>;
  /** fetch data from the table in a streaming manner: "project_status" */
  project_status_stream: Array<Project_Status>;
  /** fetch data from the table in a streaming manner: "project" */
  project_stream: Array<Project>;
  /** fetch data from the table: "project_type" */
  project_type: Array<Project_Type>;
  /** fetch aggregated fields from the table: "project_type" */
  project_type_aggregate: Project_Type_Aggregate;
  /** fetch data from the table: "project_type" using primary key columns */
  project_type_by_pk?: Maybe<Project_Type>;
  /** fetch data from the table in a streaming manner: "project_type" */
  project_type_stream: Array<Project_Type>;
  /** fetch data from the table: "project_units" */
  project_units: Array<Project_Units>;
  /** fetch aggregated fields from the table: "project_units" */
  project_units_aggregate: Project_Units_Aggregate;
  /** execute function "project_units_for_user" which returns "project_units" */
  project_units_for_user: Array<Project_Units>;
  /** execute function "project_units_for_user" and query aggregates on result of table type "project_units" */
  project_units_for_user_aggregate: Project_Units_Aggregate;
  /** fetch data from the table in a streaming manner: "project_units" */
  project_units_stream: Array<Project_Units>;
  /** fetch data from the table: "property" */
  property: Array<Property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: Property_Aggregate;
  /** An array relationship */
  property_allocation: Array<Property_Allocation>;
  /** An aggregate relationship */
  property_allocation_aggregate: Property_Allocation_Aggregate;
  /** fetch data from the table: "property_allocation_count" */
  property_allocation_count: Array<Property_Allocation_Count>;
  /** fetch aggregated fields from the table: "property_allocation_count" */
  property_allocation_count_aggregate: Property_Allocation_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "property_allocation_count" */
  property_allocation_count_stream: Array<Property_Allocation_Count>;
  /** fetch data from the table in a streaming manner: "property_allocation" */
  property_allocation_stream: Array<Property_Allocation>;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<Property>;
  /** fetch data from the table: "property_completion_status" */
  property_completion_status: Array<Property_Completion_Status>;
  /** fetch aggregated fields from the table: "property_completion_status" */
  property_completion_status_aggregate: Property_Completion_Status_Aggregate;
  /** fetch data from the table: "property_completion_status" using primary key columns */
  property_completion_status_by_pk?: Maybe<Property_Completion_Status>;
  /** fetch data from the table in a streaming manner: "property_completion_status" */
  property_completion_status_stream: Array<Property_Completion_Status>;
  /** fetch data from the table: "property_detail" */
  property_detail: Array<Property_Detail>;
  /** fetch aggregated fields from the table: "property_detail" */
  property_detail_aggregate: Property_Detail_Aggregate;
  /** fetch data from the table in a streaming manner: "property_detail" */
  property_detail_stream: Array<Property_Detail>;
  /** fetch data from the table: "property_group" */
  property_group: Array<Property_Group>;
  /** fetch aggregated fields from the table: "property_group" */
  property_group_aggregate: Property_Group_Aggregate;
  /** fetch data from the table: "property_group_allocation_detail_view" */
  property_group_allocation_detail_view: Array<Property_Group_Allocation_Detail_View>;
  /** fetch aggregated fields from the table: "property_group_allocation_detail_view" */
  property_group_allocation_detail_view_aggregate: Property_Group_Allocation_Detail_View_Aggregate;
  /** fetch data from the table in a streaming manner: "property_group_allocation_detail_view" */
  property_group_allocation_detail_view_stream: Array<Property_Group_Allocation_Detail_View>;
  /** fetch data from the table: "property_group_allocation_view" */
  property_group_allocation_view: Array<Property_Group_Allocation_View>;
  /** fetch aggregated fields from the table: "property_group_allocation_view" */
  property_group_allocation_view_aggregate: Property_Group_Allocation_View_Aggregate;
  /** fetch data from the table in a streaming manner: "property_group_allocation_view" */
  property_group_allocation_view_stream: Array<Property_Group_Allocation_View>;
  /** fetch data from the table: "property_group" using primary key columns */
  property_group_by_pk?: Maybe<Property_Group>;
  /** fetch data from the table: "property_group_completion_status" */
  property_group_completion_status: Array<Property_Group_Completion_Status>;
  /** fetch aggregated fields from the table: "property_group_completion_status" */
  property_group_completion_status_aggregate: Property_Group_Completion_Status_Aggregate;
  /** fetch data from the table: "property_group_completion_status" using primary key columns */
  property_group_completion_status_by_pk?: Maybe<Property_Group_Completion_Status>;
  /** fetch data from the table in a streaming manner: "property_group_completion_status" */
  property_group_completion_status_stream: Array<Property_Group_Completion_Status>;
  /** fetch data from the table in a streaming manner: "property_group" */
  property_group_stream: Array<Property_Group>;
  /** fetch data from the table: "property_group_type" */
  property_group_type: Array<Property_Group_Type>;
  /** fetch aggregated fields from the table: "property_group_type" */
  property_group_type_aggregate: Property_Group_Type_Aggregate;
  /** fetch data from the table: "property_group_type" using primary key columns */
  property_group_type_by_pk?: Maybe<Property_Group_Type>;
  /** fetch data from the table in a streaming manner: "property_group_type" */
  property_group_type_stream: Array<Property_Group_Type>;
  /** fetch data from the table in a streaming manner: "property" */
  property_stream: Array<Property>;
  /** fetch data from the table: "property_type" */
  property_type: Array<Property_Type>;
  /** fetch aggregated fields from the table: "property_type" */
  property_type_aggregate: Property_Type_Aggregate;
  /** fetch data from the table: "property_type" using primary key columns */
  property_type_by_pk?: Maybe<Property_Type>;
  /** fetch data from the table in a streaming manner: "property_type" */
  property_type_stream: Array<Property_Type>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** fetch data from the table in a streaming manner: "reservation" */
  reservation_stream: Array<Reservation>;
  /** fetch data from the table: "salesforce_action" */
  salesforce_action: Array<Salesforce_Action>;
  /** fetch aggregated fields from the table: "salesforce_action" */
  salesforce_action_aggregate: Salesforce_Action_Aggregate;
  /** fetch data from the table: "salesforce_action" using primary key columns */
  salesforce_action_by_pk?: Maybe<Salesforce_Action>;
  /** fetch data from the table: "salesforce_action_status" */
  salesforce_action_status: Array<Salesforce_Action_Status>;
  /** fetch aggregated fields from the table: "salesforce_action_status" */
  salesforce_action_status_aggregate: Salesforce_Action_Status_Aggregate;
  /** fetch data from the table: "salesforce_action_status" using primary key columns */
  salesforce_action_status_by_pk?: Maybe<Salesforce_Action_Status>;
  /** fetch data from the table in a streaming manner: "salesforce_action_status" */
  salesforce_action_status_stream: Array<Salesforce_Action_Status>;
  /** fetch data from the table in a streaming manner: "salesforce_action" */
  salesforce_action_stream: Array<Salesforce_Action>;
  /** fetch data from the table: "salesforce_bulk_import" */
  salesforce_bulk_import: Array<Salesforce_Bulk_Import>;
  /** fetch aggregated fields from the table: "salesforce_bulk_import" */
  salesforce_bulk_import_aggregate: Salesforce_Bulk_Import_Aggregate;
  /** fetch data from the table: "salesforce_bulk_import" using primary key columns */
  salesforce_bulk_import_by_pk?: Maybe<Salesforce_Bulk_Import>;
  /** fetch data from the table in a streaming manner: "salesforce_bulk_import" */
  salesforce_bulk_import_stream: Array<Salesforce_Bulk_Import>;
  /** fetch data from the table: "salesforce_connection" */
  salesforce_connection: Array<Salesforce_Connection>;
  /** fetch aggregated fields from the table: "salesforce_connection" */
  salesforce_connection_aggregate: Salesforce_Connection_Aggregate;
  /** fetch data from the table: "salesforce_connection" using primary key columns */
  salesforce_connection_by_pk?: Maybe<Salesforce_Connection>;
  /** fetch data from the table in a streaming manner: "salesforce_connection" */
  salesforce_connection_stream: Array<Salesforce_Connection>;
  /** fetch data from the table: "salesforce_sync_status" */
  salesforce_sync_status: Array<Salesforce_Sync_Status>;
  /** fetch aggregated fields from the table: "salesforce_sync_status" */
  salesforce_sync_status_aggregate: Salesforce_Sync_Status_Aggregate;
  /** fetch data from the table: "salesforce_sync_status" using primary key columns */
  salesforce_sync_status_by_pk?: Maybe<Salesforce_Sync_Status>;
  /** fetch data from the table in a streaming manner: "salesforce_sync_status" */
  salesforce_sync_status_stream: Array<Salesforce_Sync_Status>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** fetch data from the table in a streaming manner: "settings" */
  settings_stream: Array<Settings>;
  /** fetch data from the table: "sf_action" */
  sf_action: Array<Sf_Action>;
  /** fetch aggregated fields from the table: "sf_action" */
  sf_action_aggregate: Sf_Action_Aggregate;
  /** fetch data from the table: "sf_action" using primary key columns */
  sf_action_by_pk?: Maybe<Sf_Action>;
  /** fetch data from the table in a streaming manner: "sf_action" */
  sf_action_stream: Array<Sf_Action>;
  /** fetch data from the table: "sf_contact" */
  sf_contact: Array<Sf_Contact>;
  /** fetch aggregated fields from the table: "sf_contact" */
  sf_contact_aggregate: Sf_Contact_Aggregate;
  /** fetch data from the table: "sf_contact" using primary key columns */
  sf_contact_by_pk?: Maybe<Sf_Contact>;
  /** fetch data from the table in a streaming manner: "sf_contact" */
  sf_contact_stream: Array<Sf_Contact>;
  /** fetch data from the table: "sf_organisation" */
  sf_organisation: Array<Sf_Organisation>;
  /** fetch aggregated fields from the table: "sf_organisation" */
  sf_organisation_aggregate: Sf_Organisation_Aggregate;
  /** fetch data from the table: "sf_organisation" using primary key columns */
  sf_organisation_by_pk?: Maybe<Sf_Organisation>;
  /** fetch data from the table in a streaming manner: "sf_organisation" */
  sf_organisation_stream: Array<Sf_Organisation>;
  solicitor_orgs: Array<Solicitor_Orgs>;
  /** fetch data from the table: "spoken_language" */
  spoken_language: Array<Spoken_Language>;
  /** fetch aggregated fields from the table: "spoken_language" */
  spoken_language_aggregate: Spoken_Language_Aggregate;
  /** fetch data from the table: "spoken_language" using primary key columns */
  spoken_language_by_pk?: Maybe<Spoken_Language>;
  /** fetch data from the table in a streaming manner: "spoken_language" */
  spoken_language_stream: Array<Spoken_Language>;
  /** fetch data from the table: "system" */
  system: Array<System>;
  /** fetch aggregated fields from the table: "system" */
  system_aggregate: System_Aggregate;
  /** fetch data from the table: "system" using primary key columns */
  system_by_pk?: Maybe<System>;
  /** fetch data from the table in a streaming manner: "system" */
  system_stream: Array<System>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table: "transaction_status" */
  transaction_status: Array<Transaction_Status>;
  /** fetch aggregated fields from the table: "transaction_status" */
  transaction_status_aggregate: Transaction_Status_Aggregate;
  /** fetch data from the table: "transaction_status" using primary key columns */
  transaction_status_by_pk?: Maybe<Transaction_Status>;
  /** fetch data from the table in a streaming manner: "transaction_status" */
  transaction_status_stream: Array<Transaction_Status>;
  /** fetch data from the table in a streaming manner: "transaction" */
  transaction_stream: Array<Transaction>;
  /** fetch data from the table: "transaction_type" */
  transaction_type: Array<Transaction_Type>;
  /** fetch aggregated fields from the table: "transaction_type" */
  transaction_type_aggregate: Transaction_Type_Aggregate;
  /** fetch data from the table: "transaction_type" using primary key columns */
  transaction_type_by_pk?: Maybe<Transaction_Type>;
  /** fetch data from the table in a streaming manner: "transaction_type" */
  transaction_type_stream: Array<Transaction_Type>;
  /** fetch data from the table: "unit_allocation_count" */
  unit_allocation_count: Array<Unit_Allocation_Count>;
  /** fetch aggregated fields from the table: "unit_allocation_count" */
  unit_allocation_count_aggregate: Unit_Allocation_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "unit_allocation_count" */
  unit_allocation_count_stream: Array<Unit_Allocation_Count>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** execute function "user_created_count_and_rolling_avg" which returns "count_with_rolling_avg" */
  user_created_count_and_rolling_avg: Array<Count_With_Rolling_Avg>;
  /** execute function "user_created_count_and_rolling_avg" and query aggregates on result of table type "count_with_rolling_avg" */
  user_created_count_and_rolling_avg_aggregate: Count_With_Rolling_Avg_Aggregate;
  /** fetch data from the table: "user_experiment" */
  user_experiment: Array<User_Experiment>;
  /** fetch aggregated fields from the table: "user_experiment" */
  user_experiment_aggregate: User_Experiment_Aggregate;
  /** fetch data from the table: "user_experiment" using primary key columns */
  user_experiment_by_pk?: Maybe<User_Experiment>;
  /** fetch data from the table in a streaming manner: "user_experiment" */
  user_experiment_stream: Array<User_Experiment>;
  /** fetch data from the table: "user_feature" */
  user_feature: Array<User_Feature>;
  /** fetch aggregated fields from the table: "user_feature" */
  user_feature_aggregate: User_Feature_Aggregate;
  /** fetch data from the table: "user_feature" using primary key columns */
  user_feature_by_pk?: Maybe<User_Feature>;
  /** fetch data from the table in a streaming manner: "user_feature" */
  user_feature_stream: Array<User_Feature>;
  /** fetch data from the table: "user_push_token" */
  user_push_token: Array<User_Push_Token>;
  /** fetch aggregated fields from the table: "user_push_token" */
  user_push_token_aggregate: User_Push_Token_Aggregate;
  /** fetch data from the table: "user_push_token" using primary key columns */
  user_push_token_by_pk?: Maybe<User_Push_Token>;
  /** fetch data from the table in a streaming manner: "user_push_token" */
  user_push_token_stream: Array<User_Push_Token>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "variation" */
  variation: Array<Variation>;
  /** fetch aggregated fields from the table: "variation" */
  variation_aggregate: Variation_Aggregate;
  /** fetch data from the table: "variation" using primary key columns */
  variation_by_pk?: Maybe<Variation>;
  /** fetch data from the table: "variation_category" */
  variation_category: Array<Variation_Category>;
  /** fetch aggregated fields from the table: "variation_category" */
  variation_category_aggregate: Variation_Category_Aggregate;
  /** fetch data from the table: "variation_category" using primary key columns */
  variation_category_by_pk?: Maybe<Variation_Category>;
  /** fetch data from the table in a streaming manner: "variation_category" */
  variation_category_stream: Array<Variation_Category>;
  /** fetch data from the table in a streaming manner: "variation" */
  variation_stream: Array<Variation>;
  /** fetch data from the table: "variation_type" */
  variation_type: Array<Variation_Type>;
  /** fetch aggregated fields from the table: "variation_type" */
  variation_type_aggregate: Variation_Type_Aggregate;
  /** fetch data from the table: "variation_type" using primary key columns */
  variation_type_by_pk?: Maybe<Variation_Type>;
  /** fetch data from the table: "variation_type_category" */
  variation_type_category: Array<Variation_Type_Category>;
  /** fetch aggregated fields from the table: "variation_type_category" */
  variation_type_category_aggregate: Variation_Type_Category_Aggregate;
  /** fetch data from the table: "variation_type_category" using primary key columns */
  variation_type_category_by_pk?: Maybe<Variation_Type_Category>;
  /** fetch data from the table in a streaming manner: "variation_type_category" */
  variation_type_category_stream: Array<Variation_Type_Category>;
  /** fetch data from the table in a streaming manner: "variation_type" */
  variation_type_stream: Array<Variation_Type>;
  /** fetch data from the table: "web_app_feature" */
  web_app_feature: Array<Web_App_Feature>;
  /** fetch aggregated fields from the table: "web_app_feature" */
  web_app_feature_aggregate: Web_App_Feature_Aggregate;
  /** fetch data from the table: "web_app_feature" using primary key columns */
  web_app_feature_by_pk?: Maybe<Web_App_Feature>;
  /** fetch data from the table in a streaming manner: "web_app_feature" */
  web_app_feature_stream: Array<Web_App_Feature>;
  /** fetch data from the table: "websocket_log" */
  websocket_log: Array<Websocket_Log>;
  /** fetch aggregated fields from the table: "websocket_log" */
  websocket_log_aggregate: Websocket_Log_Aggregate;
  /** fetch data from the table: "websocket_log" using primary key columns */
  websocket_log_by_pk?: Maybe<Websocket_Log>;
  /** fetch data from the table in a streaming manner: "websocket_log" */
  websocket_log_stream: Array<Websocket_Log>;
};
export type Subscription_RootAcl_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};

export type Subscription_RootAcl_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};

export type Subscription_RootAcl_Allocation_EditArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Subscription_RootAcl_Allocation_Edit_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Allocation_Edit_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Allocation_Edit_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Subscription_RootAcl_Allocation_Edit_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Allocation_Edit_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Edit_Bool_Exp>;
};

export type Subscription_RootAcl_Allocation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Allocation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Allocation_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Contact_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Developer_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Contact_Developer_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Contact_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Contact_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Contact_Master_Agent_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Contact_Master_Agent_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Contact_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Deal_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Developer_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Deal_Developer_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_Master_Agent_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Deal_Master_Agent_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_User_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_User_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_User_Team_DescArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_User_Team_Desc_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_User_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_User_Team_Desc_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Deal_User_Team_Desc_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Deal_User_Team_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Deal_User_Team_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_User_Team_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Developer_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Developer_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Master_Agent_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Master_Agent_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_DescArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Desc_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Desc_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Property_Desc_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Property_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Property_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_TeamArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Team_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Team_DescArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Team_Desc_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Property_Team_Desc_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Team_Desc_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Property_Team_Desc_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Desc_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Property_Team_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Property_Team_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Property_Team_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Via_Listing_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Via_Listing_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Listing_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Via_Listing_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Via_Listing_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Listing_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Via_Stage_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Via_Stage_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Listing_Via_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Listing_Via_Stage_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Listing_Via_Stage_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Listing_Via_Stage_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Org_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Developer_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Org_Developer_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Developer_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Feature_EnabledArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Feature_Enabled_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Feature_Enabled_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Org_Feature_Enabled_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Feature_Enabled_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Master_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Master_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Org_Master_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Org_Master_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Org_Master_Agent_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Org_Master_Agent_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Org_Master_Agent_Bool_Exp>;
};

export type Subscription_RootAcl_Property_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type Subscription_RootAcl_Property_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type Subscription_RootAcl_Property_Allocation_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootAcl_Property_Allocation_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootAcl_Property_Allocation_Count_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Property_Allocation_Count_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootAcl_Property_Allocation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Property_Allocation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Property_Allocation_Bool_Exp>;
};

export type Subscription_RootAcl_Stage_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Stage_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Stage_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Stage_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_Stage_Agent_Member_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Stage_Agent_Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Stage_Agent_Member_Bool_Exp>;
};

export type Subscription_RootAcl_UpdateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Bool_Exp>;
};

export type Subscription_RootAcl_Update_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Bool_Exp>;
};

export type Subscription_RootAcl_Update_By_PkArgs = {
  acl: Scalars['String'];
};

export type Subscription_RootAcl_Update_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Status_Bool_Exp>;
};

export type Subscription_RootAcl_Update_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Update_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Update_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Status_Bool_Exp>;
};

export type Subscription_RootAcl_Update_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAcl_Update_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Update_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Status_Bool_Exp>;
};

export type Subscription_RootAcl_Update_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Acl_Update_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Update_Bool_Exp>;
};

export type Subscription_RootAgency_AgreementArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agency_Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type Subscription_RootAgency_Agreement_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agency_Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agency_Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type Subscription_RootAgency_Agreement_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAgency_Agreement_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Agency_Agreement_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Agency_Agreement_Bool_Exp>;
};

export type Subscription_RootAgreementArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type Subscription_RootAgreement_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Agreement_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Agreement_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type Subscription_RootAgreement_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAgreement_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Agreement_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Agreement_Bool_Exp>;
};

export type Subscription_RootAllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Subscription_RootAllocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Subscription_RootAllocation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAllocation_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Log_Bool_Exp>;
};

export type Subscription_RootAllocation_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Log_Bool_Exp>;
};

export type Subscription_RootAllocation_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAllocation_Log_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Allocation_Log_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Log_Bool_Exp>;
};

export type Subscription_RootAllocation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Allocation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Bool_Exp>;
};

export type Subscription_RootAllocation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Type_Bool_Exp>;
};

export type Subscription_RootAllocation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Allocation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Allocation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Type_Bool_Exp>;
};

export type Subscription_RootAllocation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAllocation_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Allocation_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Allocation_Type_Bool_Exp>;
};

export type Subscription_RootApartment_Plan_BuildingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Building_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Building_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
};

export type Subscription_RootApartment_Plan_Building_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Building_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Building_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
};

export type Subscription_RootApartment_Plan_Building_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootApartment_Plan_Building_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Apartment_Plan_Building_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Building_Bool_Exp>;
};

export type Subscription_RootApartment_Plan_PropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
};

export type Subscription_RootApartment_Plan_Property_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Apartment_Plan_Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Apartment_Plan_Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
};

export type Subscription_RootApartment_Plan_Property_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootApartment_Plan_Property_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Apartment_Plan_Property_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Apartment_Plan_Property_Bool_Exp>;
};

export type Subscription_RootArea_PlotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Subscription_RootArea_Plot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Subscription_RootArea_Plot_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootArea_Plot_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Area_Plot_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Bool_Exp>;
};

export type Subscription_RootArea_Plot_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Type_Bool_Exp>;
};

export type Subscription_RootArea_Plot_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Area_Plot_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Area_Plot_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Type_Bool_Exp>;
};

export type Subscription_RootArea_Plot_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootArea_Plot_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Area_Plot_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Area_Plot_Type_Bool_Exp>;
};

export type Subscription_RootAttachmentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Subscription_RootAttachment_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Subscription_RootAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAttachment_ConfigArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Config_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type Subscription_RootAttachment_Config_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Config_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Config_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type Subscription_RootAttachment_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAttachment_Config_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Config_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Config_Bool_Exp>;
};

export type Subscription_RootAttachment_Edit_Project_OrderArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Edit_Project_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Edit_Project_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
};

export type Subscription_RootAttachment_Edit_Project_Order_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Edit_Project_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Edit_Project_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
};

export type Subscription_RootAttachment_Edit_Project_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAttachment_Edit_Project_Order_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Edit_Project_Order_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Edit_Project_Order_Bool_Exp>;
};

export type Subscription_RootAttachment_OwnerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Owner_Bool_Exp>;
};

export type Subscription_RootAttachment_Owner_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Owner_Bool_Exp>;
};

export type Subscription_RootAttachment_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAttachment_Owner_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Owner_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Owner_Bool_Exp>;
};

export type Subscription_RootAttachment_Product_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Product_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Product_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Product_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Product_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Product_Type_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAttachment_Product_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Product_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Product_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Stage_OrderArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Stage_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Stage_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
};

export type Subscription_RootAttachment_Stage_Order_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Stage_Order_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Stage_Order_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
};

export type Subscription_RootAttachment_Stage_Order_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAttachment_Stage_Order_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Stage_Order_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Stage_Order_Bool_Exp>;
};

export type Subscription_RootAttachment_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type Subscription_RootAttachment_ThumbnailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAttachment_Thumbnail_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Count_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Count_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_Count_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Thumbnail_Count_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Count_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Thumbnail_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Thumbnail_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Thumbnail_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Thumbnail_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAttachment_Thumbnail_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Thumbnail_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Thumbnail_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAttachment_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Attachment_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Type_Bool_Exp>;
};

export type Subscription_RootBuyer_PortalArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Portal_Bool_Exp>;
};

export type Subscription_RootBuyer_Portal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Portal_Bool_Exp>;
};

export type Subscription_RootBuyer_Portal_By_PkArgs = {
  deal_id: Scalars['uuid'];
};

export type Subscription_RootBuyer_Portal_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Buyer_Portal_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Portal_Bool_Exp>;
};

export type Subscription_RootBuyer_ProjectArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type Subscription_RootBuyer_Project_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type Subscription_RootBuyer_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootBuyer_Project_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Buyer_Project_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type Subscription_RootBuyer_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Type_Bool_Exp>;
};

export type Subscription_RootBuyer_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Type_Bool_Exp>;
};

export type Subscription_RootBuyer_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootBuyer_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Buyer_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Type_Bool_Exp>;
};

export type Subscription_RootCacheArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Cache_Bool_Exp>;
};

export type Subscription_RootCache_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Cache_Bool_Exp>;
};

export type Subscription_RootCache_By_PkArgs = {
  key: Scalars['String'];
};

export type Subscription_RootCache_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Cache_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Cache_Bool_Exp>;
};

export type Subscription_RootChange_InArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_In_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_In_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_In_Bool_Exp>;
};

export type Subscription_RootChange_In_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_In_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_In_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_In_Bool_Exp>;
};

export type Subscription_RootChange_In_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChange_In_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Change_In_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Change_In_Bool_Exp>;
};

export type Subscription_RootChange_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_Status_Bool_Exp>;
};

export type Subscription_RootChange_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Change_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Change_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Change_Status_Bool_Exp>;
};

export type Subscription_RootChange_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootChange_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Change_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Change_Status_Bool_Exp>;
};

export type Subscription_RootCodeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Code_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Code_Bool_Exp>;
};

export type Subscription_RootCode_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Code_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Code_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Code_Bool_Exp>;
};

export type Subscription_RootCode_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCode_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Code_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Code_Bool_Exp>;
};

export type Subscription_RootCommissionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type Subscription_RootCommission_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type Subscription_RootCommission_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCommission_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Commission_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Bool_Exp>;
};

export type Subscription_RootCommission_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

export type Subscription_RootCommission_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

export type Subscription_RootCommission_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCommission_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Commission_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

export type Subscription_RootContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Subscription_RootContact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Subscription_RootContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootContact_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Contact_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type Subscription_RootContact_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

export type Subscription_RootContact_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

export type Subscription_RootContact_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootContact_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Contact_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Type_Bool_Exp>;
};

export type Subscription_RootCount_With_Rolling_AvgArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Subscription_RootCount_With_Rolling_Avg_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Subscription_RootCount_With_Rolling_Avg_By_PkArgs = {
  date_start: Scalars['date'];
};

export type Subscription_RootCount_With_Rolling_Avg_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Count_With_Rolling_Avg_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Subscription_RootCountryArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Country_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Subscription_RootCountry_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Country_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Subscription_RootCountry_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCountry_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Country_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Subscription_RootCrm_ConnectionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Crm_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Crm_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Crm_Connection_Bool_Exp>;
};

export type Subscription_RootCrm_Connection_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Crm_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Crm_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Crm_Connection_Bool_Exp>;
};

export type Subscription_RootCrm_Connection_By_PkArgs = {
  sf_instance_id: Scalars['String'];
};

export type Subscription_RootCrm_Connection_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Crm_Connection_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Crm_Connection_Bool_Exp>;
};

export type Subscription_RootDealArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Subscription_RootDeal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Subscription_RootDeal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeal_ContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type Subscription_RootDeal_Contact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type Subscription_RootDeal_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeal_Contact_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Contact_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Contact_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Contact_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Contact_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Contact_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Contact_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Contact_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Contact_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Contact_Bool_Exp>;
};

export type Subscription_RootDeal_Deposit_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Deposit_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Deposit_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Deposit_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Deposit_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Deposit_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
};

export type Subscription_RootDeal_Deposit_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Deposit_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Deposit_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
};

export type Subscription_RootDeal_Deposit_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Deposit_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Deposit_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Deposit_Type_Bool_Exp>;
};

export type Subscription_RootDeal_Enquiry_SourceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Enquiry_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Enquiry_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
};

export type Subscription_RootDeal_Enquiry_Source_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Enquiry_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Enquiry_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
};

export type Subscription_RootDeal_Enquiry_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Enquiry_Source_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Enquiry_Source_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Enquiry_Source_Bool_Exp>;
};

export type Subscription_RootDeal_Fallen_ReasonArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Fallen_Reason_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Fallen_Reason_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
};

export type Subscription_RootDeal_Fallen_Reason_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Fallen_Reason_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Fallen_Reason_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
};

export type Subscription_RootDeal_Fallen_Reason_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Fallen_Reason_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Fallen_Reason_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Fallen_Reason_Bool_Exp>;
};

export type Subscription_RootDeal_Payment_MethodArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
};

export type Subscription_RootDeal_Payment_Method_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
};

export type Subscription_RootDeal_Payment_Method_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Payment_Method_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Payment_Method_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Payment_Method_Bool_Exp>;
};

export type Subscription_RootDeal_Progress_UpdateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Progress_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Progress_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
};

export type Subscription_RootDeal_Progress_Update_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Progress_Update_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Progress_Update_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
};

export type Subscription_RootDeal_Progress_Update_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Progress_Update_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Progress_Update_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Progress_Update_Bool_Exp>;
};

export type Subscription_RootDeal_Reason_PurchaseArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Reason_Purchase_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Reason_Purchase_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
};

export type Subscription_RootDeal_Reason_Purchase_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Reason_Purchase_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Reason_Purchase_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
};

export type Subscription_RootDeal_Reason_Purchase_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Reason_Purchase_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Reason_Purchase_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Reason_Purchase_Bool_Exp>;
};

export type Subscription_RootDeal_Sf_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
};

export type Subscription_RootDeal_Sf_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
};

export type Subscription_RootDeal_Sf_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeal_Sf_Action_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Sf_Action_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Sf_Action_Bool_Exp>;
};

export type Subscription_RootDeal_Spoken_LanguageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type Subscription_RootDeal_Spoken_Language_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type Subscription_RootDeal_Spoken_Language_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeal_Spoken_Language_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Spoken_Language_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Spoken_Language_Bool_Exp>;
};

export type Subscription_RootDeal_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Status_Bool_Exp>;
};

export type Subscription_RootDeal_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Bool_Exp>;
};

export type Subscription_RootDeal_Transfer_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Transfer_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Transfer_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Transfer_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Transfer_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Transfer_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
};

export type Subscription_RootDeal_Transfer_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Transfer_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Transfer_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Transfer_Status_Bool_Exp>;
};

export type Subscription_RootDeal_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Type_Bool_Exp>;
};

export type Subscription_RootDeal_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Type_Bool_Exp>;
};

export type Subscription_RootDeal_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDeal_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Type_Bool_Exp>;
};

export type Subscription_RootDeal_Valuation_BookingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
};

export type Subscription_RootDeal_Valuation_Booking_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
};

export type Subscription_RootDeal_Valuation_Booking_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeal_Valuation_Booking_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Valuation_Booking_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Valuation_Booking_Bool_Exp>;
};

export type Subscription_RootDeal_WitnessArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Witness_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type Subscription_RootDeal_Witness_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Deal_Witness_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Deal_Witness_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type Subscription_RootDeal_Witness_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeal_Witness_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Deal_Witness_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Deal_Witness_Bool_Exp>;
};

export type Subscription_RootDeveloper_AgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type Subscription_RootDeveloper_Agent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Developer_Agent_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Developer_Agent_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type Subscription_RootDeveloper_Agent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDeveloper_Agent_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Developer_Agent_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Developer_Agent_Bool_Exp>;
};

export type Subscription_RootDocumentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type Subscription_RootDocument_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type Subscription_RootDocument_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDocument_ExpiredArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Expired_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Expired_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Expired_Bool_Exp>;
};

export type Subscription_RootDocument_Expired_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Expired_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Expired_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Expired_Bool_Exp>;
};

export type Subscription_RootDocument_Expired_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Document_Expired_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Expired_Bool_Exp>;
};

export type Subscription_RootDocument_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Document_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Bool_Exp>;
};

export type Subscription_RootDocument_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

export type Subscription_RootDocument_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Document_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Document_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

export type Subscription_RootDocument_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDocument_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Document_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Document_Type_Bool_Exp>;
};

export type Subscription_RootEmbedArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Embed_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Embed_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type Subscription_RootEmbed_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Embed_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Embed_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type Subscription_RootEmbed_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEmbed_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Embed_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Embed_Bool_Exp>;
};

export type Subscription_RootEnvelopeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Subscription_RootEnvelope_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Subscription_RootEnvelope_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEnvelope_OwnerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Owner_Bool_Exp>;
};

export type Subscription_RootEnvelope_Owner_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Owner_Bool_Exp>;
};

export type Subscription_RootEnvelope_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnvelope_Owner_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Envelope_Owner_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Owner_Bool_Exp>;
};

export type Subscription_RootEnvelope_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Status_Bool_Exp>;
};

export type Subscription_RootEnvelope_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Status_Bool_Exp>;
};

export type Subscription_RootEnvelope_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnvelope_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Envelope_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Status_Bool_Exp>;
};

export type Subscription_RootEnvelope_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Envelope_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Bool_Exp>;
};

export type Subscription_RootEnvelope_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Type_Bool_Exp>;
};

export type Subscription_RootEnvelope_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Envelope_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Envelope_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Type_Bool_Exp>;
};

export type Subscription_RootEnvelope_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnvelope_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Envelope_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Envelope_Type_Bool_Exp>;
};

export type Subscription_RootExperimentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootExperiment_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootExperiment_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootExperiment_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Experiment_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootFeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFeature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFeature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFeature_CheckArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Subscription_RootFeature_Check_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Subscription_RootFeature_Check_By_PkArgs = {
  result: Scalars['Boolean'];
};

export type Subscription_RootFeature_Check_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Feature_Check_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Subscription_RootFeature_PackArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Bool_Exp>;
};

export type Subscription_RootFeature_Pack_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Bool_Exp>;
};

export type Subscription_RootFeature_Pack_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFeature_Pack_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Subscription_RootFeature_Pack_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Pack_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Pack_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Subscription_RootFeature_Pack_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFeature_Pack_Feature_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Feature_Pack_Feature_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Feature_Bool_Exp>;
};

export type Subscription_RootFeature_Pack_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Feature_Pack_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Pack_Bool_Exp>;
};

export type Subscription_RootFeature_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Feature_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFilter_PageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_Page_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_Page_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_Page_Bool_Exp>;
};

export type Subscription_RootFilter_Page_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_Page_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_Page_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_Page_Bool_Exp>;
};

export type Subscription_RootFilter_Page_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootFilter_Page_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Filter_Page_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_Page_Bool_Exp>;
};

export type Subscription_RootFilter_StateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_State_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_State_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_State_Bool_Exp>;
};

export type Subscription_RootFilter_State_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Filter_State_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Filter_State_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_State_Bool_Exp>;
};

export type Subscription_RootFilter_State_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootFilter_State_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Filter_State_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Filter_State_Bool_Exp>;
};

export type Subscription_RootFinance_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Finance_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Finance_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Finance_Status_Bool_Exp>;
};

export type Subscription_RootFinance_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Finance_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Finance_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Finance_Status_Bool_Exp>;
};

export type Subscription_RootFinance_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootFinance_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Finance_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Finance_Status_Bool_Exp>;
};

export type Subscription_RootGraphql_Query_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Graphql_Query_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Graphql_Query_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
};

export type Subscription_RootGraphql_Query_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Graphql_Query_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Graphql_Query_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
};

export type Subscription_RootGraphql_Query_Log_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGraphql_Query_Log_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Graphql_Query_Log_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Graphql_Query_Log_Bool_Exp>;
};

export type Subscription_RootHubspot_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Bool_Exp>;
};

export type Subscription_RootHubspot_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Bool_Exp>;
};

export type Subscription_RootHubspot_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Action_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Action_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Bool_Exp>;
};

export type Subscription_RootHubspot_Action_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
};

export type Subscription_RootHubspot_Action_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Action_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Action_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
};

export type Subscription_RootHubspot_Action_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootHubspot_Action_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Action_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Action_Type_Bool_Exp>;
};

export type Subscription_RootHubspot_ContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type Subscription_RootHubspot_Contact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type Subscription_RootHubspot_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Contact_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Contact_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Contact_Bool_Exp>;
};

export type Subscription_RootHubspot_DealArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type Subscription_RootHubspot_Deal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Deal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Deal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type Subscription_RootHubspot_Deal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Deal_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Deal_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Deal_Bool_Exp>;
};

export type Subscription_RootHubspot_OrganisationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type Subscription_RootHubspot_Organisation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type Subscription_RootHubspot_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Organisation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Organisation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Organisation_Bool_Exp>;
};

export type Subscription_RootHubspot_ProjectArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type Subscription_RootHubspot_Project_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type Subscription_RootHubspot_Project_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Project_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Project_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Project_Bool_Exp>;
};

export type Subscription_RootHubspot_Schema_Error_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Schema_Error_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Schema_Error_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
};

export type Subscription_RootHubspot_Schema_Error_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Schema_Error_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Schema_Error_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
};

export type Subscription_RootHubspot_Schema_Error_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Schema_Error_Log_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Schema_Error_Log_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Schema_Error_Log_Bool_Exp>;
};

export type Subscription_RootHubspot_UserArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type Subscription_RootHubspot_User_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type Subscription_RootHubspot_User_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_User_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_User_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type Subscription_RootHubspot_Valuation_BookingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

export type Subscription_RootHubspot_Valuation_Booking_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_Valuation_Booking_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_Valuation_Booking_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

export type Subscription_RootHubspot_Valuation_Booking_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspot_Valuation_Booking_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Hubspot_Valuation_Booking_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_Valuation_Booking_Bool_Exp>;
};

export type Subscription_RootInvitationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Subscription_RootInvitation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Subscription_RootInvitation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootInvitation_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Status_Bool_Exp>;
};

export type Subscription_RootInvitation_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Status_Bool_Exp>;
};

export type Subscription_RootInvitation_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootInvitation_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Invitation_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Status_Bool_Exp>;
};

export type Subscription_RootInvitation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Invitation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type Subscription_RootInvitation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Type_Bool_Exp>;
};

export type Subscription_RootInvitation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Type_Bool_Exp>;
};

export type Subscription_RootInvitation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootInvitation_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Invitation_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Type_Bool_Exp>;
};

export type Subscription_RootLambda_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lambda_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lambda_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lambda_Log_Bool_Exp>;
};

export type Subscription_RootLambda_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lambda_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lambda_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lambda_Log_Bool_Exp>;
};

export type Subscription_RootLambda_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLambda_Log_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Lambda_Log_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Lambda_Log_Bool_Exp>;
};

export type Subscription_RootLead_GenerationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Bool_Exp>;
};

export type Subscription_RootLead_Generation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Bool_Exp>;
};

export type Subscription_RootLead_Generation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLead_Generation_SourceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
};

export type Subscription_RootLead_Generation_Source_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
};

export type Subscription_RootLead_Generation_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootLead_Generation_Source_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Lead_Generation_Source_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Source_Bool_Exp>;
};

export type Subscription_RootLead_Generation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Lead_Generation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Bool_Exp>;
};

export type Subscription_RootLead_Generation_Webhook_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Webhook_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

export type Subscription_RootLead_Generation_Webhook_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Lead_Generation_Webhook_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Lead_Generation_Webhook_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

export type Subscription_RootLead_Generation_Webhook_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLead_Generation_Webhook_Log_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Lead_Generation_Webhook_Log_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Lead_Generation_Webhook_Log_Bool_Exp>;
};

export type Subscription_RootListingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Subscription_RootListing_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Subscription_RootListing_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootListing_Price_Per_SqmtArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Price_Per_Sqmt_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Price_Per_Sqmt_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
};

export type Subscription_RootListing_Price_Per_Sqmt_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Price_Per_Sqmt_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Price_Per_Sqmt_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
};

export type Subscription_RootListing_Price_Per_Sqmt_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Price_Per_Sqmt_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Price_Per_Sqmt_Bool_Exp>;
};

export type Subscription_RootListing_Publish_ErrorsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Errors_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Errors_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Errors_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Errors_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Errors_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Errors_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootListing_Publish_Errors_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Publish_Errors_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Errors_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Portal_SourceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Portal_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Portal_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Portal_Source_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Portal_Source_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Portal_Source_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Portal_Source_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootListing_Publish_Portal_Source_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Publish_Portal_Source_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Portal_Source_Bool_Exp>;
};

export type Subscription_RootListing_Publish_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Status_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootListing_Publish_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Publish_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Status_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Status_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Publish_Status_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Publish_Status_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
};

export type Subscription_RootListing_Publish_Status_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootListing_Publish_Status_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Publish_Status_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Publish_Status_Type_Bool_Exp>;
};

export type Subscription_RootListing_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Status_Bool_Exp>;
};

export type Subscription_RootListing_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Listing_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Listing_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Status_Bool_Exp>;
};

export type Subscription_RootListing_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootListing_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Status_Bool_Exp>;
};

export type Subscription_RootListing_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Listing_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Listing_Bool_Exp>;
};

export type Subscription_RootMemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type Subscription_RootMember_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type Subscription_RootMember_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMember_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type Subscription_RootMember_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type Subscription_RootMember_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMember_Feature_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Member_Feature_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Feature_Bool_Exp>;
};

export type Subscription_RootMember_Project_AllocateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Subscription_RootMember_Project_Allocate_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Project_Allocate_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Project_Allocate_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Subscription_RootMember_Project_Allocate_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMember_Project_Allocate_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Member_Project_Allocate_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Project_Allocate_Bool_Exp>;
};

export type Subscription_RootMember_RoleArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Role_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Role_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Role_Bool_Exp>;
};

export type Subscription_RootMember_Role_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Role_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Role_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Role_Bool_Exp>;
};

export type Subscription_RootMember_Role_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootMember_Role_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Member_Role_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Role_Bool_Exp>;
};

export type Subscription_RootMember_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Member_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type Subscription_RootNationalityArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Nationality_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Nationality_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Nationality_Bool_Exp>;
};

export type Subscription_RootNationality_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Nationality_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Nationality_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Nationality_Bool_Exp>;
};

export type Subscription_RootNationality_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootNationality_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Nationality_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Nationality_Bool_Exp>;
};

export type Subscription_RootNotificationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Subscription_RootNotification_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNotification_ChannelArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Channel_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Channel_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Channel_Bool_Exp>;
};

export type Subscription_RootNotification_Channel_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Channel_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Channel_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Channel_Bool_Exp>;
};

export type Subscription_RootNotification_Channel_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootNotification_Channel_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Notification_Channel_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Channel_Bool_Exp>;
};

export type Subscription_RootNotification_OwnerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Owner_Bool_Exp>;
};

export type Subscription_RootNotification_Owner_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Owner_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Owner_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Owner_Bool_Exp>;
};

export type Subscription_RootNotification_Owner_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootNotification_Owner_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Notification_Owner_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Owner_Bool_Exp>;
};

export type Subscription_RootNotification_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Notification_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Subscription_RootNotification_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Subscription_RootNotification_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Subscription_RootNotification_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootNotification_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Notification_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_CheckArgs = {
  /** input parameters for function "org_feature_check" */
  args: Org_Feature_Check_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_Check_AggregateArgs = {
  /** input parameters for function "org_feature_check_aggregate" */
  args: Org_Feature_Check_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Feature_Check_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Feature_Check_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Feature_Check_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_EnabledArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_Enabled_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Enabled_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Enabled_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_Enabled_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Org_Feature_Enabled_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Enabled_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_PackArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_Pack_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Org_Feature_Pack_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Org_Feature_Pack_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type Subscription_RootOrg_Feature_Pack_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrg_Feature_Pack_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Org_Feature_Pack_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Org_Feature_Pack_Bool_Exp>;
};

export type Subscription_RootOrganisationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type Subscription_RootOrganisation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type Subscription_RootOrganisation_Allocation_Counts_ViewArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Allocation_Counts_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type Subscription_RootOrganisation_Allocation_Counts_View_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Allocation_Counts_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Allocation_Counts_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type Subscription_RootOrganisation_Allocation_Counts_View_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Organisation_Allocation_Counts_View_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Allocation_Counts_View_Bool_Exp>;
};

export type Subscription_RootOrganisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganisation_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type Subscription_RootOrganisation_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type Subscription_RootOrganisation_Feature_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganisation_Feature_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Organisation_Feature_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Feature_Bool_Exp>;
};

export type Subscription_RootOrganisation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Organisation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Bool_Exp>;
};

export type Subscription_RootOrganisation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Type_Bool_Exp>;
};

export type Subscription_RootOrganisation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Organisation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Organisation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Type_Bool_Exp>;
};

export type Subscription_RootOrganisation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootOrganisation_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Organisation_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Organisation_Type_Bool_Exp>;
};

export type Subscription_RootPartner_PortalArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Partner_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Partner_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Partner_Portal_Bool_Exp>;
};

export type Subscription_RootPartner_Portal_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Partner_Portal_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Partner_Portal_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Partner_Portal_Bool_Exp>;
};

export type Subscription_RootPartner_Portal_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPartner_Portal_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Partner_Portal_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Partner_Portal_Bool_Exp>;
};

export type Subscription_RootPayment_MethodArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Subscription_RootPayment_Method_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Subscription_RootPayment_Method_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPayment_Method_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Payment_Method_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Subscription_RootPayment_Method_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Type_Bool_Exp>;
};

export type Subscription_RootPayment_Method_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Payment_Method_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Payment_Method_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Type_Bool_Exp>;
};

export type Subscription_RootPayment_Method_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootPayment_Method_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Payment_Method_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Payment_Method_Type_Bool_Exp>;
};

export type Subscription_RootPreferenceArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Subscription_RootPreference_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Preference_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Preference_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Subscription_RootPreference_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPreference_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Preference_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Preference_Bool_Exp>;
};

export type Subscription_RootProcessArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Bool_Exp>;
};

export type Subscription_RootProcess_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Bool_Exp>;
};

export type Subscription_RootProcess_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootProcess_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Status_Bool_Exp>;
};

export type Subscription_RootProcess_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Process_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Process_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Status_Bool_Exp>;
};

export type Subscription_RootProcess_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProcess_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Process_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Status_Bool_Exp>;
};

export type Subscription_RootProcess_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Process_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Process_Bool_Exp>;
};

export type Subscription_RootProjectArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_Apartment_LevelArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Level_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Subscription_RootProject_Apartment_Level_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Level_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Level_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Subscription_RootProject_Apartment_Level_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Apartment_Level_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Level_Bool_Exp>;
};

export type Subscription_RootProject_Apartment_StatArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Stat_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Stat_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
};

export type Subscription_RootProject_Apartment_Stat_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Apartment_Stat_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Apartment_Stat_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
};

export type Subscription_RootProject_Apartment_Stat_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Apartment_Stat_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Apartment_Stat_Bool_Exp>;
};

export type Subscription_RootProject_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Client_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Subscription_RootProject_Client_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Subscription_RootProject_Client_Count_For_UserArgs = {
  /** input parameters for function "project_client_count_for_user" */
  args: Project_Client_Count_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Subscription_RootProject_Client_Count_For_User_AggregateArgs = {
  /** input parameters for function "project_client_count_for_user_aggregate" */
  args: Project_Client_Count_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Client_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Client_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Subscription_RootProject_Client_Count_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Client_Count_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Client_Count_Bool_Exp>;
};

export type Subscription_RootProject_DetailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Detail_Bool_Exp>;
};

export type Subscription_RootProject_Detail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Detail_Bool_Exp>;
};

export type Subscription_RootProject_Detail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Detail_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Detail_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Detail_Bool_Exp>;
};

export type Subscription_RootProject_FeaturesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Features_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Features_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type Subscription_RootProject_Features_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Features_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Features_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type Subscription_RootProject_Features_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Features_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Features_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Features_Bool_Exp>;
};

export type Subscription_RootProject_Payment_MethodArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type Subscription_RootProject_Payment_Method_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Payment_Method_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Payment_Method_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type Subscription_RootProject_Payment_Method_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Payment_Method_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Payment_Method_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Payment_Method_Bool_Exp>;
};

export type Subscription_RootProject_Qr_Code_For_BuyerArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
};

export type Subscription_RootProject_Qr_Code_For_Buyer_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Qr_Code_For_Buyer_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
};

export type Subscription_RootProject_Qr_Code_For_Buyer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Qr_Code_For_Buyer_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Qr_Code_For_Buyer_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Qr_Code_For_Buyer_Bool_Exp>;
};

export type Subscription_RootProject_Reservation_SettingArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Reservation_Setting_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Reservation_Setting_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
};

export type Subscription_RootProject_Reservation_Setting_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Reservation_Setting_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Reservation_Setting_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
};

export type Subscription_RootProject_Reservation_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Reservation_Setting_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Reservation_Setting_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Reservation_Setting_Bool_Exp>;
};

export type Subscription_RootProject_SourcesArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Sources_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Sources_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Sources_Bool_Exp>;
};

export type Subscription_RootProject_Sources_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Sources_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Sources_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Sources_Bool_Exp>;
};

export type Subscription_RootProject_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Sources_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Sources_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Sources_Bool_Exp>;
};

export type Subscription_RootProject_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Subscription_RootProject_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Subscription_RootProject_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProject_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Subscription_RootProject_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Type_Bool_Exp>;
};

export type Subscription_RootProject_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Type_Bool_Exp>;
};

export type Subscription_RootProject_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProject_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Type_Bool_Exp>;
};

export type Subscription_RootProject_UnitsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Subscription_RootProject_Units_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Subscription_RootProject_Units_For_UserArgs = {
  /** input parameters for function "project_units_for_user" */
  args: Project_Units_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Subscription_RootProject_Units_For_User_AggregateArgs = {
  /** input parameters for function "project_units_for_user_aggregate" */
  args: Project_Units_For_User_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Project_Units_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Project_Units_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Subscription_RootProject_Units_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Project_Units_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Project_Units_Bool_Exp>;
};

export type Subscription_RootPropertyArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Subscription_RootProperty_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Subscription_RootProperty_AllocationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type Subscription_RootProperty_Allocation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type Subscription_RootProperty_Allocation_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootProperty_Allocation_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootProperty_Allocation_Count_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Allocation_Count_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootProperty_Allocation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Allocation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Allocation_Bool_Exp>;
};

export type Subscription_RootProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProperty_Completion_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Completion_Status_Bool_Exp>;
};

export type Subscription_RootProperty_Completion_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Completion_Status_Bool_Exp>;
};

export type Subscription_RootProperty_Completion_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProperty_Completion_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Completion_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Completion_Status_Bool_Exp>;
};

export type Subscription_RootProperty_DetailArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Detail_Bool_Exp>;
};

export type Subscription_RootProperty_Detail_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Detail_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Detail_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Detail_Bool_Exp>;
};

export type Subscription_RootProperty_Detail_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Detail_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Detail_Bool_Exp>;
};

export type Subscription_RootProperty_GroupArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Subscription_RootProperty_Group_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Allocation_Detail_ViewArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_Detail_View_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Allocation_Detail_View_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_Detail_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_Detail_View_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Allocation_Detail_View_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Group_Allocation_Detail_View_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_Detail_View_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Allocation_ViewArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_View_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Allocation_View_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Allocation_View_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Allocation_View_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_View_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Allocation_View_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Group_Allocation_View_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Allocation_View_Bool_Exp>;
};

export type Subscription_RootProperty_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProperty_Group_Completion_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Completion_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Completion_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Completion_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Completion_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProperty_Group_Completion_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Group_Completion_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Completion_Status_Bool_Exp>;
};

export type Subscription_RootProperty_Group_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Group_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Bool_Exp>;
};

export type Subscription_RootProperty_Group_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Type_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Group_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Group_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Type_Bool_Exp>;
};

export type Subscription_RootProperty_Group_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProperty_Group_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Group_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Group_Type_Bool_Exp>;
};

export type Subscription_RootProperty_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Bool_Exp>;
};

export type Subscription_RootProperty_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

export type Subscription_RootProperty_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Property_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Property_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

export type Subscription_RootProperty_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProperty_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Property_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Property_Type_Bool_Exp>;
};

export type Subscription_RootReservationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Reservation_Bool_Exp>;
};

export type Subscription_RootReservation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Reservation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Reservation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Reservation_Bool_Exp>;
};

export type Subscription_RootReservation_By_PkArgs = {
  deal_id: Scalars['uuid'];
};

export type Subscription_RootReservation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Reservation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Reservation_Bool_Exp>;
};

export type Subscription_RootSalesforce_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Bool_Exp>;
};

export type Subscription_RootSalesforce_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Bool_Exp>;
};

export type Subscription_RootSalesforce_Action_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSalesforce_Action_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
};

export type Subscription_RootSalesforce_Action_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Action_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Action_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
};

export type Subscription_RootSalesforce_Action_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSalesforce_Action_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Salesforce_Action_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Status_Bool_Exp>;
};

export type Subscription_RootSalesforce_Action_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Salesforce_Action_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Action_Bool_Exp>;
};

export type Subscription_RootSalesforce_Bulk_ImportArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Bulk_Import_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Bulk_Import_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
};

export type Subscription_RootSalesforce_Bulk_Import_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Bulk_Import_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Bulk_Import_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
};

export type Subscription_RootSalesforce_Bulk_Import_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSalesforce_Bulk_Import_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Salesforce_Bulk_Import_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Bulk_Import_Bool_Exp>;
};

export type Subscription_RootSalesforce_ConnectionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Connection_Bool_Exp>;
};

export type Subscription_RootSalesforce_Connection_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Connection_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Connection_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Connection_Bool_Exp>;
};

export type Subscription_RootSalesforce_Connection_By_PkArgs = {
  access_token: Scalars['String'];
};

export type Subscription_RootSalesforce_Connection_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Salesforce_Connection_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Connection_Bool_Exp>;
};

export type Subscription_RootSalesforce_Sync_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Sync_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Sync_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
};

export type Subscription_RootSalesforce_Sync_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Salesforce_Sync_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Salesforce_Sync_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
};

export type Subscription_RootSalesforce_Sync_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSalesforce_Sync_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Salesforce_Sync_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Salesforce_Sync_Status_Bool_Exp>;
};

export type Subscription_RootSettingsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Settings_Bool_Exp>;
};

export type Subscription_RootSettings_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Settings_Bool_Exp>;
};

export type Subscription_RootSettings_By_PkArgs = {
  key: Scalars['String'];
};

export type Subscription_RootSettings_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Settings_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Settings_Bool_Exp>;
};

export type Subscription_RootSf_ActionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Action_Bool_Exp>;
};

export type Subscription_RootSf_Action_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Action_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Action_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Action_Bool_Exp>;
};

export type Subscription_RootSf_Action_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSf_Action_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Sf_Action_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Action_Bool_Exp>;
};

export type Subscription_RootSf_ContactArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

export type Subscription_RootSf_Contact_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

export type Subscription_RootSf_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSf_Contact_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Sf_Contact_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Contact_Bool_Exp>;
};

export type Subscription_RootSf_OrganisationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

export type Subscription_RootSf_Organisation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Sf_Organisation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Sf_Organisation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

export type Subscription_RootSf_Organisation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSf_Organisation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Sf_Organisation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Sf_Organisation_Bool_Exp>;
};

export type Subscription_RootSolicitor_OrgsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Solicitor_Orgs_Enum_Name>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Solicitor_Orgs_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Solicitor_Orgs_Bool_Exp_Bool_Exp>;
};

export type Subscription_RootSpoken_LanguageArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Spoken_Language_Bool_Exp>;
};

export type Subscription_RootSpoken_Language_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Spoken_Language_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Spoken_Language_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Spoken_Language_Bool_Exp>;
};

export type Subscription_RootSpoken_Language_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSpoken_Language_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Spoken_Language_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Spoken_Language_Bool_Exp>;
};

export type Subscription_RootSystemArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<System_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<System_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<System_Bool_Exp>;
};

export type Subscription_RootSystem_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<System_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<System_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<System_Bool_Exp>;
};

export type Subscription_RootSystem_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSystem_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<System_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<System_Bool_Exp>;
};

export type Subscription_RootTransactionArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type Subscription_RootTransaction_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type Subscription_RootTransaction_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTransaction_StatusArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Status_Bool_Exp>;
};

export type Subscription_RootTransaction_Status_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Status_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Status_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Status_Bool_Exp>;
};

export type Subscription_RootTransaction_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTransaction_Status_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Transaction_Status_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Status_Bool_Exp>;
};

export type Subscription_RootTransaction_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Transaction_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Bool_Exp>;
};

export type Subscription_RootTransaction_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Type_Bool_Exp>;
};

export type Subscription_RootTransaction_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Transaction_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Transaction_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Type_Bool_Exp>;
};

export type Subscription_RootTransaction_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTransaction_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Transaction_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Transaction_Type_Bool_Exp>;
};

export type Subscription_RootUnit_Allocation_CountArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Unit_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Unit_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootUnit_Allocation_Count_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Unit_Allocation_Count_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Unit_Allocation_Count_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootUnit_Allocation_Count_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Unit_Allocation_Count_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Unit_Allocation_Count_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Created_Count_And_Rolling_AvgArgs = {
  /** input parameters for function "user_created_count_and_rolling_avg" */
  args: User_Created_Count_And_Rolling_Avg_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Subscription_RootUser_Created_Count_And_Rolling_Avg_AggregateArgs = {
  /** input parameters for function "user_created_count_and_rolling_avg_aggregate" */
  args: User_Created_Count_And_Rolling_Avg_Args;
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Count_With_Rolling_Avg_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Count_With_Rolling_Avg_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Count_With_Rolling_Avg_Bool_Exp>;
};

export type Subscription_RootUser_ExperimentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type Subscription_RootUser_Experiment_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type Subscription_RootUser_Experiment_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Experiment_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<User_Experiment_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type Subscription_RootUser_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Feature_Bool_Exp>;
};

export type Subscription_RootUser_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Feature_Bool_Exp>;
};

export type Subscription_RootUser_Feature_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUser_Feature_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<User_Feature_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<User_Feature_Bool_Exp>;
};

export type Subscription_RootUser_Push_TokenArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Push_Token_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

export type Subscription_RootUser_Push_Token_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Push_Token_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

export type Subscription_RootUser_Push_Token_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Push_Token_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<User_Push_Token_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

export type Subscription_RootUser_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<User_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootVariationArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Bool_Exp>;
};

export type Subscription_RootVariation_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Bool_Exp>;
};

export type Subscription_RootVariation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootVariation_CategoryArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Category_Bool_Exp>;
};

export type Subscription_RootVariation_Category_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Category_Bool_Exp>;
};

export type Subscription_RootVariation_Category_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootVariation_Category_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Variation_Category_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Category_Bool_Exp>;
};

export type Subscription_RootVariation_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Variation_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Bool_Exp>;
};

export type Subscription_RootVariation_TypeArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Bool_Exp>;
};

export type Subscription_RootVariation_Type_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Bool_Exp>;
};

export type Subscription_RootVariation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootVariation_Type_CategoryArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Category_Bool_Exp>;
};

export type Subscription_RootVariation_Type_Category_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Variation_Type_Category_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Variation_Type_Category_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Category_Bool_Exp>;
};

export type Subscription_RootVariation_Type_Category_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootVariation_Type_Category_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Variation_Type_Category_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Category_Bool_Exp>;
};

export type Subscription_RootVariation_Type_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Variation_Type_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Variation_Type_Bool_Exp>;
};

export type Subscription_RootWeb_App_FeatureArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Web_App_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Web_App_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

export type Subscription_RootWeb_App_Feature_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Web_App_Feature_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Web_App_Feature_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

export type Subscription_RootWeb_App_Feature_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootWeb_App_Feature_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Web_App_Feature_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Web_App_Feature_Bool_Exp>;
};

export type Subscription_RootWebsocket_LogArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Websocket_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Websocket_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Websocket_Log_Bool_Exp>;
};

export type Subscription_RootWebsocket_Log_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Websocket_Log_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Websocket_Log_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Websocket_Log_Bool_Exp>;
};

export type Subscription_RootWebsocket_Log_By_PkArgs = {
  request_id: Scalars['uuid'];
};

export type Subscription_RootWebsocket_Log_StreamArgs = {
  /** maximum number of rows returned in a single batch */
  batch_size: Scalars['Int'];
  /** cursor to stream the results returned by the query */
  cursor: Array<Websocket_Log_Stream_Cursor_Input>;
  /** filter the rows returned */
  where?: InputMaybe<Websocket_Log_Bool_Exp>;
};


/** columns and relationships of "system" */
export type System = {
__typename?: 'system';

  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "system" */
export type System_Aggregate = {
__typename?: 'system_aggregate';

  aggregate?: Maybe<System_Aggregate_Fields>;
  nodes: Array<System>;
};

/** aggregate fields of "system" */
export type System_Aggregate_Fields = {
__typename?: 'system_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<System_Max_Fields>;
  min?: Maybe<System_Min_Fields>;
};
export type System_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<System_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type System_Max_Fields = {
__typename?: 'system_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type System_Min_Fields = {
__typename?: 'system_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "system" */
export type System_Mutation_Response = {
__typename?: 'system_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<System>;
};

/** columns and relationships of "transaction" */
export type Transaction = {
__typename?: 'transaction';

  /** An array relationship */
  acl_deal_agent_member: Array<Acl_Deal_Agent_Member>;
  /** An aggregate relationship */
  acl_deal_agent_member_aggregate: Acl_Deal_Agent_Member_Aggregate;
  /** An array relationship */
  acl_deal_developer_member: Array<Acl_Deal_Developer_Member>;
  /** An aggregate relationship */
  acl_deal_developer_member_aggregate: Acl_Deal_Developer_Member_Aggregate;
  amount: Scalars['numeric'];
  attachment_owner: Scalars['String'];
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time: Scalars['timestamptz'];
  /** An object relationship */
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_bulk_import: Scalars['Boolean'];
  json?: Maybe<Scalars['jsonb']>;
  note?: Maybe<Scalars['String']>;
  off_platform: Scalars['Boolean'];
  origin_id?: Maybe<Scalars['String']>;
  origin_status?: Maybe<Scalars['String']>;
  origin_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  paid_by_contact: Contact;
  paid_by_contact_id: Scalars['uuid'];
  /** An object relationship */
  paid_to_contact: Contact;
  paid_to_contact_id: Scalars['uuid'];
  payment_method?: Maybe<Payment_Method_Type_Enum>;
  /** An object relationship */
  payment_method_type?: Maybe<Payment_Method_Type>;
  payment_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  receipt?: Maybe<Attachment>;
  receipt_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  status?: Maybe<Transaction_Status_Enum>;
  surcharge_fee: Scalars['numeric'];
  /** An object relationship */
  transaction_type: Transaction_Type;
  type: Transaction_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type TransactionAcl_Deal_Agent_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type TransactionAcl_Deal_Agent_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Agent_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Agent_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Agent_Member_Bool_Exp>;
};

export type TransactionAcl_Deal_Developer_MemberArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type TransactionAcl_Deal_Developer_Member_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Acl_Deal_Developer_Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Acl_Deal_Developer_Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Acl_Deal_Developer_Member_Bool_Exp>;
};

export type TransactionAttachmentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type TransactionAttachments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Attachment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Attachment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Attachment_Bool_Exp>;
};

export type TransactionJsonArgs = {
  /** JSON select path */
  path?: InputMaybe<Scalars['String']>;
};


/** aggregated selection of "transaction" */
export type Transaction_Aggregate = {
__typename?: 'transaction_aggregate';

  aggregate?: Maybe<Transaction_Aggregate_Fields>;
  nodes: Array<Transaction>;
};

/** aggregate fields of "transaction" */
export type Transaction_Aggregate_Fields = {
__typename?: 'transaction_aggregate_fields';

  avg?: Maybe<Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transaction_Max_Fields>;
  min?: Maybe<Transaction_Min_Fields>;
  stddev?: Maybe<Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Transaction_Sum_Fields>;
  var_pop?: Maybe<Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Transaction_Var_Samp_Fields>;
  variance?: Maybe<Transaction_Variance_Fields>;
};
export type Transaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Transaction_Avg_Fields = {
__typename?: 'transaction_avg_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Transaction_Max_Fields = {
__typename?: 'transaction_max_fields';

  amount?: Maybe<Scalars['numeric']>;
  attachment_owner?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  origin_id?: Maybe<Scalars['String']>;
  origin_status?: Maybe<Scalars['String']>;
  origin_type?: Maybe<Scalars['String']>;
  paid_by_contact_id?: Maybe<Scalars['uuid']>;
  paid_to_contact_id?: Maybe<Scalars['uuid']>;
  payment_url?: Maybe<Scalars['String']>;
  receipt_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  surcharge_fee?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Transaction_Min_Fields = {
__typename?: 'transaction_min_fields';

  amount?: Maybe<Scalars['numeric']>;
  attachment_owner?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  origin_id?: Maybe<Scalars['String']>;
  origin_status?: Maybe<Scalars['String']>;
  origin_type?: Maybe<Scalars['String']>;
  paid_by_contact_id?: Maybe<Scalars['uuid']>;
  paid_to_contact_id?: Maybe<Scalars['uuid']>;
  payment_url?: Maybe<Scalars['String']>;
  receipt_id?: Maybe<Scalars['uuid']>;
  sf_id?: Maybe<Scalars['String']>;
  surcharge_fee?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transaction" */
export type Transaction_Mutation_Response = {
__typename?: 'transaction_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction>;
};

/** columns and relationships of "transaction_status" */
export type Transaction_Status = {
__typename?: 'transaction_status';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "transaction_status" */
export type Transaction_Status_Aggregate = {
__typename?: 'transaction_status_aggregate';

  aggregate?: Maybe<Transaction_Status_Aggregate_Fields>;
  nodes: Array<Transaction_Status>;
};

/** aggregate fields of "transaction_status" */
export type Transaction_Status_Aggregate_Fields = {
__typename?: 'transaction_status_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Transaction_Status_Max_Fields>;
  min?: Maybe<Transaction_Status_Min_Fields>;
};
export type Transaction_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transaction_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Transaction_Status_Max_Fields = {
__typename?: 'transaction_status_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transaction_Status_Min_Fields = {
__typename?: 'transaction_status_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transaction_status" */
export type Transaction_Status_Mutation_Response = {
__typename?: 'transaction_status_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction_Status>;
};

/** aggregate stddev on columns */
export type Transaction_Stddev_Fields = {
__typename?: 'transaction_stddev_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Transaction_Stddev_Pop_Fields = {
__typename?: 'transaction_stddev_pop_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Transaction_Stddev_Samp_Fields = {
__typename?: 'transaction_stddev_samp_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Transaction_Sum_Fields = {
__typename?: 'transaction_sum_fields';

  amount?: Maybe<Scalars['numeric']>;
  surcharge_fee?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "transaction_type" */
export type Transaction_Type = {
__typename?: 'transaction_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "transaction_type" */
export type Transaction_Type_Aggregate = {
__typename?: 'transaction_type_aggregate';

  aggregate?: Maybe<Transaction_Type_Aggregate_Fields>;
  nodes: Array<Transaction_Type>;
};

/** aggregate fields of "transaction_type" */
export type Transaction_Type_Aggregate_Fields = {
__typename?: 'transaction_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Transaction_Type_Max_Fields>;
  min?: Maybe<Transaction_Type_Min_Fields>;
};
export type Transaction_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transaction_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Transaction_Type_Max_Fields = {
__typename?: 'transaction_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transaction_Type_Min_Fields = {
__typename?: 'transaction_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transaction_type" */
export type Transaction_Type_Mutation_Response = {
__typename?: 'transaction_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction_Type>;
};

/** aggregate var_pop on columns */
export type Transaction_Var_Pop_Fields = {
__typename?: 'transaction_var_pop_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Transaction_Var_Samp_Fields = {
__typename?: 'transaction_var_samp_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Transaction_Variance_Fields = {
__typename?: 'transaction_variance_fields';

  amount?: Maybe<Scalars['Float']>;
  surcharge_fee?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "unit_allocation_count" */
export type Unit_Allocation_Count = {
__typename?: 'unit_allocation_count';

  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "unit_allocation_count" */
export type Unit_Allocation_Count_Aggregate = {
__typename?: 'unit_allocation_count_aggregate';

  aggregate?: Maybe<Unit_Allocation_Count_Aggregate_Fields>;
  nodes: Array<Unit_Allocation_Count>;
};

/** aggregate fields of "unit_allocation_count" */
export type Unit_Allocation_Count_Aggregate_Fields = {
__typename?: 'unit_allocation_count_aggregate_fields';

  avg?: Maybe<Unit_Allocation_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Unit_Allocation_Count_Max_Fields>;
  min?: Maybe<Unit_Allocation_Count_Min_Fields>;
  stddev?: Maybe<Unit_Allocation_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Unit_Allocation_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unit_Allocation_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Unit_Allocation_Count_Sum_Fields>;
  var_pop?: Maybe<Unit_Allocation_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Unit_Allocation_Count_Var_Samp_Fields>;
  variance?: Maybe<Unit_Allocation_Count_Variance_Fields>;
};
export type Unit_Allocation_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unit_Allocation_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Unit_Allocation_Count_Avg_Fields = {
__typename?: 'unit_allocation_count_avg_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Unit_Allocation_Count_Max_Fields = {
__typename?: 'unit_allocation_count_max_fields';

  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Unit_Allocation_Count_Min_Fields = {
__typename?: 'unit_allocation_count_min_fields';

  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Unit_Allocation_Count_Stddev_Fields = {
__typename?: 'unit_allocation_count_stddev_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Unit_Allocation_Count_Stddev_Pop_Fields = {
__typename?: 'unit_allocation_count_stddev_pop_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Unit_Allocation_Count_Stddev_Samp_Fields = {
__typename?: 'unit_allocation_count_stddev_samp_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Unit_Allocation_Count_Sum_Fields = {
__typename?: 'unit_allocation_count_sum_fields';

  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Unit_Allocation_Count_Var_Pop_Fields = {
__typename?: 'unit_allocation_count_var_pop_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Unit_Allocation_Count_Var_Samp_Fields = {
__typename?: 'unit_allocation_count_var_samp_fields';

  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Unit_Allocation_Count_Variance_Fields = {
__typename?: 'unit_allocation_count_variance_fields';

  count?: Maybe<Scalars['Float']>;
};

/** User */
export type User = {
__typename?: 'user';

  allowed_roles?: Maybe<Scalars['String']>;
  /** An array relationship */
  buyerProjects: Array<Buyer_Project>;
  /** An array relationship */
  buyerProjectsByAgent: Array<Buyer_Project>;
  /** An aggregate relationship */
  buyerProjectsByAgent_aggregate: Buyer_Project_Aggregate;
  /** An aggregate relationship */
  buyerProjects_aggregate: Buyer_Project_Aggregate;
  code_timestamp?: Maybe<Scalars['timestamptz']>;
  cognito_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  contacts: Array<Contact>;
  /** An aggregate relationship */
  contacts_aggregate: Contact_Aggregate;
  created_at: Scalars['timestamptz'];
  default_role?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  email_verified: Scalars['Boolean'];
  /** An object relationship */
  embed?: Maybe<Embed>;
  /** An array relationship */
  experiments: Array<User_Experiment>;
  /** An aggregate relationship */
  experiments_aggregate: User_Experiment_Aggregate;
  first_name: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  hubspot: Array<Hubspot_User>;
  /** An aggregate relationship */
  hubspot_aggregate: Hubspot_User_Aggregate;
  id: Scalars['uuid'];
  impersonation_requested_at: Scalars['bigint'];
  /** An array relationship */
  invitations: Array<Invitation>;
  /** An aggregate relationship */
  invitations_aggregate: Invitation_Aggregate;
  is_enable: Scalars['Boolean'];
  is_sign_up_notification_sent: Scalars['Boolean'];
  last_login_at?: Maybe<Scalars['timestamptz']>;
  last_name: Scalars['String'];
  link_login_requested_at: Scalars['bigint'];
  login_secret?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregate relationship */
  members_aggregate: Member_Aggregate;
  middle_name?: Maybe<Scalars['String']>;
  notification_owner: Scalars['String'];
  password_hash?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_number_verified: Scalars['Boolean'];
  /** An array relationship */
  push_tokens: Array<User_Push_Token>;
  /** An aggregate relationship */
  push_tokens_aggregate: User_Push_Token_Aggregate;
  sf_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  team?: Maybe<Organisation>;
  team_id?: Maybe<Scalars['uuid']>;
  team_switch_requested_at: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};
export type UserBuyerProjectsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type UserBuyerProjectsByAgentArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type UserBuyerProjectsByAgent_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type UserBuyerProjects_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Buyer_Project_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Buyer_Project_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Buyer_Project_Bool_Exp>;
};

export type UserContactsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type UserContacts_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Contact_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Contact_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Contact_Bool_Exp>;
};

export type UserExperimentsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type UserExperiments_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Experiment_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Experiment_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Experiment_Bool_Exp>;
};

export type UserHubspotArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type UserHubspot_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Hubspot_User_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Hubspot_User_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Hubspot_User_Bool_Exp>;
};

export type UserInvitationsArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type UserInvitations_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Invitation_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Invitation_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Invitation_Bool_Exp>;
};

export type UserMembersArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type UserMembers_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<Member_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<Member_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<Member_Bool_Exp>;
};

export type UserPush_TokensArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Push_Token_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};

export type UserPush_Tokens_AggregateArgs = {
  /** distinct select on columns */
  distinct_on?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  /** limit the number of rows returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** skip the first n rows. Use only with order_by */
  offset?: InputMaybe<Scalars['Int']>;
  /** sort the rows by one or more columns */
  order_by?: InputMaybe<Array<User_Push_Token_Order_By>>;
  /** filter the rows returned */
  where?: InputMaybe<User_Push_Token_Bool_Exp>;
};


/** aggregated selection of "user" */
export type User_Aggregate = {
__typename?: 'user_aggregate';

  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
__typename?: 'user_aggregate_fields';

  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type User_Avg_Fields = {
__typename?: 'user_avg_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** Users which are participating in a particular experiments */
export type User_Experiment = {
__typename?: 'user_experiment';

  created_at: Scalars['timestamptz'];
  experiment: Experiment_Enum;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_experiment" */
export type User_Experiment_Aggregate = {
__typename?: 'user_experiment_aggregate';

  aggregate?: Maybe<User_Experiment_Aggregate_Fields>;
  nodes: Array<User_Experiment>;
};

/** aggregate fields of "user_experiment" */
export type User_Experiment_Aggregate_Fields = {
__typename?: 'user_experiment_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<User_Experiment_Max_Fields>;
  min?: Maybe<User_Experiment_Min_Fields>;
};
export type User_Experiment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type User_Experiment_Max_Fields = {
__typename?: 'user_experiment_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Experiment_Min_Fields = {
__typename?: 'user_experiment_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_experiment" */
export type User_Experiment_Mutation_Response = {
__typename?: 'user_experiment_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Experiment>;
};

/** columns and relationships of "user_feature" */
export type User_Feature = {
__typename?: 'user_feature';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "user_feature" */
export type User_Feature_Aggregate = {
__typename?: 'user_feature_aggregate';

  aggregate?: Maybe<User_Feature_Aggregate_Fields>;
  nodes: Array<User_Feature>;
};

/** aggregate fields of "user_feature" */
export type User_Feature_Aggregate_Fields = {
__typename?: 'user_feature_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<User_Feature_Max_Fields>;
  min?: Maybe<User_Feature_Min_Fields>;
};
export type User_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type User_Feature_Max_Fields = {
__typename?: 'user_feature_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Feature_Min_Fields = {
__typename?: 'user_feature_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_feature" */
export type User_Feature_Mutation_Response = {
__typename?: 'user_feature_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Feature>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
__typename?: 'user_max_fields';

  allowed_roles?: Maybe<Scalars['String']>;
  code_timestamp?: Maybe<Scalars['timestamptz']>;
  cognito_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  impersonation_requested_at?: Maybe<Scalars['bigint']>;
  last_login_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  link_login_requested_at?: Maybe<Scalars['bigint']>;
  login_secret?: Maybe<Scalars['uuid']>;
  middle_name?: Maybe<Scalars['String']>;
  notification_owner?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_switch_requested_at?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
__typename?: 'user_min_fields';

  allowed_roles?: Maybe<Scalars['String']>;
  code_timestamp?: Maybe<Scalars['timestamptz']>;
  cognito_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  impersonation_requested_at?: Maybe<Scalars['bigint']>;
  last_login_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  link_login_requested_at?: Maybe<Scalars['bigint']>;
  login_secret?: Maybe<Scalars['uuid']>;
  middle_name?: Maybe<Scalars['String']>;
  notification_owner?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_switch_requested_at?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
__typename?: 'user_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** Device push tokens used by Expo Push */
export type User_Push_Token = {
__typename?: 'user_push_token';

  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_push_token" */
export type User_Push_Token_Aggregate = {
__typename?: 'user_push_token_aggregate';

  aggregate?: Maybe<User_Push_Token_Aggregate_Fields>;
  nodes: Array<User_Push_Token>;
};

/** aggregate fields of "user_push_token" */
export type User_Push_Token_Aggregate_Fields = {
__typename?: 'user_push_token_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<User_Push_Token_Max_Fields>;
  min?: Maybe<User_Push_Token_Min_Fields>;
};
export type User_Push_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Push_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type User_Push_Token_Max_Fields = {
__typename?: 'user_push_token_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Push_Token_Min_Fields = {
__typename?: 'user_push_token_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_push_token" */
export type User_Push_Token_Mutation_Response = {
__typename?: 'user_push_token_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Token>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
__typename?: 'user_stddev_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
__typename?: 'user_stddev_pop_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
__typename?: 'user_stddev_samp_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
__typename?: 'user_sum_fields';

  impersonation_requested_at?: Maybe<Scalars['bigint']>;
  link_login_requested_at?: Maybe<Scalars['bigint']>;
  team_switch_requested_at?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
__typename?: 'user_var_pop_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
__typename?: 'user_var_samp_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
__typename?: 'user_variance_fields';

  impersonation_requested_at?: Maybe<Scalars['Float']>;
  link_login_requested_at?: Maybe<Scalars['Float']>;
  team_switch_requested_at?: Maybe<Scalars['Float']>;
};

/** Stores variations */
export type Variation = {
__typename?: 'variation';

  approved: Scalars['Boolean'];
  category?: Maybe<Variation_Category_Enum>;
  commission_payable?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  date_applied?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  deal?: Maybe<Deal>;
  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** contract deposit is only to be amended with the calculation if Reserved, On Hold & EOI statuses. so, use for calculation */
  is_pass_on_contract_deposit: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  pass_on_cost: Scalars['Boolean'];
  sf_id?: Maybe<Scalars['String']>;
  type?: Maybe<Variation_Type_Enum>;
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  variation_category?: Maybe<Variation_Category>;
  /** An object relationship */
  variation_type?: Maybe<Variation_Type>;
};

/** aggregated selection of "variation" */
export type Variation_Aggregate = {
__typename?: 'variation_aggregate';

  aggregate?: Maybe<Variation_Aggregate_Fields>;
  nodes: Array<Variation>;
};

/** aggregate fields of "variation" */
export type Variation_Aggregate_Fields = {
__typename?: 'variation_aggregate_fields';

  avg?: Maybe<Variation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Variation_Max_Fields>;
  min?: Maybe<Variation_Min_Fields>;
  stddev?: Maybe<Variation_Stddev_Fields>;
  stddev_pop?: Maybe<Variation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Variation_Stddev_Samp_Fields>;
  sum?: Maybe<Variation_Sum_Fields>;
  var_pop?: Maybe<Variation_Var_Pop_Fields>;
  var_samp?: Maybe<Variation_Var_Samp_Fields>;
  variance?: Maybe<Variation_Variance_Fields>;
};
export type Variation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Variation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Variation_Avg_Fields = {
__typename?: 'variation_avg_fields';

  value?: Maybe<Scalars['Float']>;
};

/** Stores variation categories */
export type Variation_Category = {
__typename?: 'variation_category';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "variation_category" */
export type Variation_Category_Aggregate = {
__typename?: 'variation_category_aggregate';

  aggregate?: Maybe<Variation_Category_Aggregate_Fields>;
  nodes: Array<Variation_Category>;
};

/** aggregate fields of "variation_category" */
export type Variation_Category_Aggregate_Fields = {
__typename?: 'variation_category_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Variation_Category_Max_Fields>;
  min?: Maybe<Variation_Category_Min_Fields>;
};
export type Variation_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Variation_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Variation_Category_Max_Fields = {
__typename?: 'variation_category_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Variation_Category_Min_Fields = {
__typename?: 'variation_category_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "variation_category" */
export type Variation_Category_Mutation_Response = {
__typename?: 'variation_category_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Variation_Category>;
};

/** aggregate max on columns */
export type Variation_Max_Fields = {
__typename?: 'variation_max_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  date_applied?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Variation_Min_Fields = {
__typename?: 'variation_min_fields';

  created_at?: Maybe<Scalars['timestamptz']>;
  date_applied?: Maybe<Scalars['timestamptz']>;
  deal_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sf_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "variation" */
export type Variation_Mutation_Response = {
__typename?: 'variation_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Variation>;
};

/** aggregate stddev on columns */
export type Variation_Stddev_Fields = {
__typename?: 'variation_stddev_fields';

  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Variation_Stddev_Pop_Fields = {
__typename?: 'variation_stddev_pop_fields';

  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Variation_Stddev_Samp_Fields = {
__typename?: 'variation_stddev_samp_fields';

  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Variation_Sum_Fields = {
__typename?: 'variation_sum_fields';

  value?: Maybe<Scalars['numeric']>;
};

/** Stores variation types */
export type Variation_Type = {
__typename?: 'variation_type';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "variation_type" */
export type Variation_Type_Aggregate = {
__typename?: 'variation_type_aggregate';

  aggregate?: Maybe<Variation_Type_Aggregate_Fields>;
  nodes: Array<Variation_Type>;
};

/** aggregate fields of "variation_type" */
export type Variation_Type_Aggregate_Fields = {
__typename?: 'variation_type_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Variation_Type_Max_Fields>;
  min?: Maybe<Variation_Type_Min_Fields>;
};
export type Variation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Variation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** columns and relationships of "variation_type_category" */
export type Variation_Type_Category = {
__typename?: 'variation_type_category';

  category_value: Variation_Category_Enum;
  id: Scalars['uuid'];
  type_value: Variation_Type_Enum;
};

/** aggregated selection of "variation_type_category" */
export type Variation_Type_Category_Aggregate = {
__typename?: 'variation_type_category_aggregate';

  aggregate?: Maybe<Variation_Type_Category_Aggregate_Fields>;
  nodes: Array<Variation_Type_Category>;
};

/** aggregate fields of "variation_type_category" */
export type Variation_Type_Category_Aggregate_Fields = {
__typename?: 'variation_type_category_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Variation_Type_Category_Max_Fields>;
  min?: Maybe<Variation_Type_Category_Min_Fields>;
};
export type Variation_Type_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Variation_Type_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Variation_Type_Category_Max_Fields = {
__typename?: 'variation_type_category_max_fields';

  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Variation_Type_Category_Min_Fields = {
__typename?: 'variation_type_category_min_fields';

  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "variation_type_category" */
export type Variation_Type_Category_Mutation_Response = {
__typename?: 'variation_type_category_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Variation_Type_Category>;
};

/** aggregate max on columns */
export type Variation_Type_Max_Fields = {
__typename?: 'variation_type_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Variation_Type_Min_Fields = {
__typename?: 'variation_type_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "variation_type" */
export type Variation_Type_Mutation_Response = {
__typename?: 'variation_type_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Variation_Type>;
};

/** aggregate var_pop on columns */
export type Variation_Var_Pop_Fields = {
__typename?: 'variation_var_pop_fields';

  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Variation_Var_Samp_Fields = {
__typename?: 'variation_var_samp_fields';

  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Variation_Variance_Fields = {
__typename?: 'variation_variance_fields';

  value?: Maybe<Scalars['Float']>;
};

/** A list of available features for Web App */
export type Web_App_Feature = {
__typename?: 'web_app_feature';

  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "web_app_feature" */
export type Web_App_Feature_Aggregate = {
__typename?: 'web_app_feature_aggregate';

  aggregate?: Maybe<Web_App_Feature_Aggregate_Fields>;
  nodes: Array<Web_App_Feature>;
};

/** aggregate fields of "web_app_feature" */
export type Web_App_Feature_Aggregate_Fields = {
__typename?: 'web_app_feature_aggregate_fields';

  count: Scalars['Int'];
  max?: Maybe<Web_App_Feature_Max_Fields>;
  min?: Maybe<Web_App_Feature_Min_Fields>;
};
export type Web_App_Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Web_App_Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate max on columns */
export type Web_App_Feature_Max_Fields = {
__typename?: 'web_app_feature_max_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Web_App_Feature_Min_Fields = {
__typename?: 'web_app_feature_min_fields';

  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "web_app_feature" */
export type Web_App_Feature_Mutation_Response = {
__typename?: 'web_app_feature_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Web_App_Feature>;
};

/** Hasura WebSocket logs with duration in ms */
export type Websocket_Log = {
__typename?: 'websocket_log';

  completed_at: Scalars['timestamptz'];
  duration: Scalars['numeric'];
  operation_name: Scalars['String'];
  request_id: Scalars['uuid'];
  started_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
};

/** aggregated selection of "websocket_log" */
export type Websocket_Log_Aggregate = {
__typename?: 'websocket_log_aggregate';

  aggregate?: Maybe<Websocket_Log_Aggregate_Fields>;
  nodes: Array<Websocket_Log>;
};

/** aggregate fields of "websocket_log" */
export type Websocket_Log_Aggregate_Fields = {
__typename?: 'websocket_log_aggregate_fields';

  avg?: Maybe<Websocket_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Websocket_Log_Max_Fields>;
  min?: Maybe<Websocket_Log_Min_Fields>;
  stddev?: Maybe<Websocket_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Websocket_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Websocket_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Websocket_Log_Sum_Fields>;
  var_pop?: Maybe<Websocket_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Websocket_Log_Var_Samp_Fields>;
  variance?: Maybe<Websocket_Log_Variance_Fields>;
};
export type Websocket_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Websocket_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};


/** aggregate avg on columns */
export type Websocket_Log_Avg_Fields = {
__typename?: 'websocket_log_avg_fields';

  duration?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Websocket_Log_Max_Fields = {
__typename?: 'websocket_log_max_fields';

  completed_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['numeric']>;
  operation_name?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Websocket_Log_Min_Fields = {
__typename?: 'websocket_log_min_fields';

  completed_at?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['numeric']>;
  operation_name?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "websocket_log" */
export type Websocket_Log_Mutation_Response = {
__typename?: 'websocket_log_mutation_response';

  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Websocket_Log>;
};

/** aggregate stddev on columns */
export type Websocket_Log_Stddev_Fields = {
__typename?: 'websocket_log_stddev_fields';

  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Websocket_Log_Stddev_Pop_Fields = {
__typename?: 'websocket_log_stddev_pop_fields';

  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Websocket_Log_Stddev_Samp_Fields = {
__typename?: 'websocket_log_stddev_samp_fields';

  duration?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Websocket_Log_Sum_Fields = {
__typename?: 'websocket_log_sum_fields';

  duration?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Websocket_Log_Var_Pop_Fields = {
__typename?: 'websocket_log_var_pop_fields';

  duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Websocket_Log_Var_Samp_Fields = {
__typename?: 'websocket_log_var_samp_fields';

  duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Websocket_Log_Variance_Fields = {
__typename?: 'websocket_log_variance_fields';

  duration?: Maybe<Scalars['Float']>;
};
