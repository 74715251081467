import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  GetAllProjectsWithGroupsQuery,
  GetAllProjectsWithGroupsQueryVariables,
  GetProjectDetailQuery,
  GetProjectDetailQueryVariables,
  GetProjectNameByIdQuery,
  GetProjectNameByIdQueryVariables,
  GetProjectSubDescriptionByIdQuery,
  GetProjectSubDescriptionByIdQueryVariables,
  GetProjVsolByDealQuery,
  GetProjVsolByDealQueryVariables,
  ProjForSelectQuery,
  ProjForSelectQueryVariables,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from '../generated/lib/operations';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Project_Bool_Exp, Project_Set_Input } from '@skychute/schema';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private apollo: Apollo) {}

  async getProjectNameById(projectId: string): Promise<string> {
    const response = await firstValueFrom(
      this.apollo.query<GetProjectNameByIdQuery, GetProjectNameByIdQueryVariables>({
        query: GET_PROJECT_NAME_BY_ID,
        variables: { projectId },
      }),
    );

    return response?.data.project_by_pk?.name;
  }

  async getProjectSubDescriptionById(projectId: string): Promise<string> {
    const response = await firstValueFrom(
      this.apollo.query<
        GetProjectSubDescriptionByIdQuery,
        GetProjectSubDescriptionByIdQueryVariables
      >({
        query: GET_PROJECT_SUB_DESCRIPTION_ID,
        variables: { projectId },
      }),
    );

    return response?.data.project_by_pk.sub_description;
  }

  getProjectNameById$(projectId: string): Observable<string> {
    return this.apollo
      .query<GetProjectNameByIdQuery, GetProjectNameByIdQueryVariables>({
        query: GET_PROJECT_NAME_BY_ID,
        variables: { projectId },
      })
      .pipe(map((resp) => resp.data.project_by_pk?.name ?? ''));
  }

  async getProjectDetail(projectId: string): Promise<GetProjectDetailQuery['project_details']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetProjectDetailQuery, GetProjectDetailQueryVariables>({
        query: GET_PROJECT_DETAIL,
        variables: {
          projectId,
        },
      }),
    );
    return resp.data.project_details;
  }

  async getProjectVsolByDeal(
    dealId: string,
  ): Promise<GetProjVsolByDealQuery['deal_by_pk']['project']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetProjVsolByDealQuery, GetProjVsolByDealQueryVariables>({
        query: GET_PROJ_VSOL_BY_DEAL,
        variables: {
          dealId,
        },
      }),
    );
    return resp.data.deal_by_pk?.project ?? undefined;
  }

  async getAllWithGroups(
    where: Project_Bool_Exp = {},
  ): Promise<GetAllProjectsWithGroupsQuery['project']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetAllProjectsWithGroupsQuery, GetAllProjectsWithGroupsQueryVariables>({
        query: GET_ALL_PROJECTS_WITH_GROUPS,
        variables: {
          where,
        },
      }),
    );
    return resp.data.project;
  }

  async update(projectId: string, input: Project_Set_Input): Promise<void> {
    await firstValueFrom(
      this.apollo.mutate<UpdateProjectMutation, UpdateProjectMutationVariables>({
        mutation: UPDATE_PROJECT,
        variables: {
          projectId,
          input,
        },
      }),
    );
  }

  async getForSelect(where: Project_Bool_Exp): Promise<ProjForSelectQuery['project']> {
    const resp = await firstValueFrom(
      this.apollo.query<ProjForSelectQuery, ProjForSelectQueryVariables>({
        query: GET_FOR_SELECT,
        variables: {
          where,
        },
      }),
    );
    return resp.data.project ?? [];
  }
}

// QUERIES
const GET_PROJECT_DETAIL = gql`
  query getProjectDetail($projectId: String!) {
    project_details(projectId: $projectId) {
      data {
        id
        projectName
        projectBg
        projectLogo
        projectColor
        projectBgColor
        projectDescription
        video {
          id
          type
          productTypes
          name
          downloadURL
          imageURL
          thumbnailCardURL
          viewImageUrl
          logoCardURL
          logoSmUrl
          logoSmUrl2
          logoMdUrl
          logoMdUrl2
          contentType
        }
        images {
          id
          type
          productTypes
          name
          downloadURL
          imageURL
          thumbnailCardURL
          viewImageUrl
          logoCardURL
          logoSmUrl
          logoSmUrl2
          logoMdUrl
          logoMdUrl2
          contentType
        }
        documents {
          id
          type
          productTypes
          name
          downloadURL
          imageURL
          thumbnailCardURL
          viewImageUrl
          logoCardURL
          logoSmUrl
          logoSmUrl2
          logoMdUrl
          logoMdUrl2
          contentType
        }
      }
      message
      success
    }
  }
`;
const GET_PROJECT_NAME_BY_ID = gql`
  query getProjectNameById($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      name
    }
  }
`;

const GET_PROJECT_SUB_DESCRIPTION_ID = gql`
  query getProjectSubDescriptionById($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      sub_description
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation updateProject($projectId: uuid!, $input: project_set_input!) {
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $input) {
      id
    }
  }
`;

const GET_PROJ_VSOL_BY_DEAL = gql`
  query getProjVsolByDeal($dealId: uuid!) {
    deal_by_pk(id: $dealId) {
      project {
        vendor_solicitor_contact {
          id
          first_name
          last_name
          email
        }
        organisationByVendorSolicitorId {
          id
          name
        }
      }
    }
  }
`;

const GET_ALL_PROJECTS_WITH_GROUPS = gql`
  query getAllProjectsWithGroups($where: project_bool_exp) {
    project(where: $where) {
      id
      name
      property_groups(where: { parent_id: { _is_null: true } }) {
        id
        name
        property_group_children {
          id
          name
        }
      }
    }
  }
`;

const GET_FOR_SELECT = gql`
  query projForSelect($where: project_bool_exp!) {
    project(where: $where) {
      id
      name
    }
  }
`;
