import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/**
 * Usage: {{str | justDate}}
 */
@Pipe({
  name: 'justDate',
})
export class JustDatePipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined || value === null || !value) {
      return '—';
    }

    return moment(value).format('DD/MM/YYYY');
  }
}
