/**
 * null values for vendorSignatureRequired and witnessSignatureRequired
 * means that value was not set, and error is expected to be thrown during
 * Digital Contract (envelope) creation
 */
import { Attachment_Type_Enum } from '@skychute/schema';

export interface DigitalContractSettingsInterface {
  enabled: boolean;
  inheritFromStageId: string;
  annatureMasterEnvelopeId: string;
  envelopeSenderUserId: string;
  vendorSignatureRequired: boolean | null;
  vendorSignersUserIds: string[];
  witnessSignatureRequired: boolean | null;
  vsolToReceiveCarbonCopy: boolean;
  psolToReceiveCarbonCopy: boolean;
  attachmentType: Attachment_Type_Enum | null;
  waiveCoolingOff: boolean;
  developerToIssueContracts: boolean; // if true, we can only issue new digital contract from Sales Contract page
  buyerMessage: string; // message buyer will see in the envelope Email
  vendorMessage: string; // message vendor will see in the envelope Email
  groupId: string; // used for branding
}
