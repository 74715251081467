/**
 * Various settlement option which are used on the Sales Contract page
 * in the Settlement section (which is enabled by SALES_CONTRACT_SETTLEMENT feature flag)
 * They are gathered here and not in the db via enum tables to simplify development
 * Also these values can be different for other Salesforce clients, that would lead to
 * multiple enum values which are hard to distinguish which belong to which Salesforce instance
 * Gathered here it's obvious they are only for LINK
 * Also having Enums will increase the GraphQL schema without any real benefits
 * We may switch to Enum in the future if it's beneficial
 */
export class LinkSettlement {
  static readonly formsOfSettlement: string[] = ['Cash', 'Loan'];

  static readonly stories: string[] = [
    'Awaiting Name Change',
    'Awaiting Proof of Income',
    'Cash',
    'COVID - Lost Job',
    'COVID - Reduced Income',
    'COVID - Transfer Delay',
    'Developer Sale',
    'Likely to be terminated',
    'Loan Expired – Can’t Settle',
    'Loan Expired – Reapplying',
    'Multi',
    'Not Managed by LINK',
    'Pre-Approved',
    'Preparing to settle',
    'Ready for Bank Valuation',
    'Resell',
    'Resold',
    's96',
    'Settled',
    'Settlement Booked',
    'To be contacted',
    'Unable to settle',
    'Unconditionally Approved',
    'Uncontactable',
    'Undervalued - Insufficient Cash',
    'Vendor Finance',
  ];

  static readonly outlooks: string[] = [
    'A. Intending to settle - Cash settlement',
    'B. Intending to settle - Application approved',
    'C. Intending to settle - Multiple applications',
    'D. Intending to settle - Application lodged',
    'E. Intending to settle - Application in prep.',
    'F. Intending to settle - Application not yet started',
    'G. Intending to settle - Unable to obtain finance',
    'H. Intending to settle - Waiting for solicitor to book in settlement',
    'I. Intending to settle - Property  not ready for settlement',
    'J. Unable to settle - Unsuccessful attempts at finance',
    'K. Unable to settle - Attempting resale',
    'L. Unable to settle - Uncontactable',
  ];

  static readonly defectStatuses = [
    {
      label: 'None',
      value: 'None',
    },
    {
      label: 'Closed',
      value: 'Closed',
    },
    {
      label: 'Open',
      value: 'Open',
    },
  ];
}
