import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  CancelDealMutation,
  CancelDealMutationVariables, CreateDealContactMutation, CreateDealContactMutationVariables,
  DealTitleByIdQuery,
  DealTitleByIdQueryVariables,
  EditDealNameOnContractMutation,
  EditDealNameOnContractMutationVariables,
  GetActiveOfferQuery,
  GetActiveOfferQueryVariables,
  GetBuyersIdsQuery,
  GetBuyersIdsQueryVariables,
  GetDealByPropertyIdQuery,
  GetDealByPropertyIdQueryVariables,
  GetDealContactsQuery,
  GetDealContactsQueryVariables,
  GetListingDetailQuery,
  GetListingDetailQueryVariables,
  GetMakeSomeChangeRequestQuery,
  GetMakeSomeChangeRequestQueryVariables,
  HoldingDepositPaidMutation,
  HoldingDepositPaidMutationVariables,
  SyncDealDepositPercentageOnSfMutation,
  SyncDealDepositPercentageOnSfMutationVariables,
  SyncDealPayableOnSfMutation,
  SyncDealPayableOnSfMutationVariables,
  UpdateDealContactMutation,
  UpdateDealContactMutationVariables,
  UpdateDealMutation,
  UpdateDealMutationVariables,
} from '../generated/lib/operations';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import moment from 'moment';
import {
  Deal_Contact_Set_Input,
  Deal_Fallen_Reason_Enum,
  Deal_Set_Input,
  Deal_Status_Enum,
  Deal_Type_Enum,
} from '@skychute/schema';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class DealService {
  $variationAction = new BehaviorSubject<boolean>(false);

  constructor(private apollo: Apollo, private alert: AlertService) {
  }

  isDealReserved(status: Deal_Status_Enum): boolean {
    const reservedStatuses: Deal_Status_Enum[] = [
      Deal_Status_Enum.Reserved,
      Deal_Status_Enum.Issued,
      Deal_Status_Enum.Unconditional,
      Deal_Status_Enum.Conditional,
    ];
    return reservedStatuses.includes(status);
  }

  async updateDealContact(
    dealId: string,
    input: Deal_Contact_Set_Input,
  ): Promise<UpdateDealContactMutation['update_deal_contact']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateDealContactMutation, UpdateDealContactMutationVariables>({
        mutation: UPDATE_DEAL_CONTACT,
        variables: {
          dealId,
          input,
        },
      }),
    );
    if (resp.errors) {
      console.error(JSON.stringify(resp.errors, null, 4));
    }
    return resp.data.update_deal_contact;
  }

  async cancelDeal(dealId: string, reason: string): Promise<CancelDealMutation['cancel_deal']> {
    const dealReq = {
      dealId: dealId,
      fallenExplanation: reason,
      fallenReason: Deal_Fallen_Reason_Enum.Other,
      fallenDate: moment().format('YYYY-MM-DD'),
    };
    const resp = await firstValueFrom(
      this.apollo.mutate<CancelDealMutation, CancelDealMutationVariables>({
        mutation: CANCEL_DEAL,
        variables: {
          dealReq: dealReq,
        },
      }),
    );

    return resp.data.cancel_deal;
  }

  async update(
    dealId: string,
    input: Deal_Set_Input,
  ): Promise<UpdateDealMutation['update_deal_by_pk']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<UpdateDealMutation, UpdateDealMutationVariables>({
        mutation: UPDATE_DEAL,
        variables: {
          dealId,
          input,
        },
      }),
    );
    return resp.data.update_deal_by_pk;
  }

  async getMakeSomeChangeRequest(dealId: string): Promise<boolean> {
    const resp = await firstValueFrom(
      this.apollo.query<GetMakeSomeChangeRequestQuery, GetMakeSomeChangeRequestQueryVariables>({
        query: GET_MAKE_SOME_CHANGE_REQUEST,
        variables: {
          dealId,
        },
      }),
    );
    return resp.data.deal_by_pk.changes_requested ?? false;
  }

  async getContactsByDealID(dealId: string): Promise<GetDealContactsQuery['deal_contacts']> {
    const resp = await firstValueFrom(
      this.apollo.query<GetDealContactsQuery, GetDealContactsQueryVariables>({
        query: GET_DEAL_CONTACTS,
        variables: {
          dealId,
        },
      }),
    );

    return resp.data?.deal_contacts;
  }

  async isAllIdVerifiedByAutomaticVerification(dealId: string): Promise<boolean> {
    const dealContacts = await this.getContactsByDealID(dealId);
    // // If deal contact has no valid ids
    // // we return false.
    // // If any one is valid, we return true
    for (const dealContact of dealContacts) {
      if (!dealContact.is_valid) {
        return false;
      }
    }

    return true;
  }

  /**
   * If Holding Deposit is paid via automated system like PayPal or Stripe
   * And ID was verified via Rapid ID or other automated system
   * Updates Enquiry in Salesforce if Salesforce integration is enabled for a project
   * deal with given id belongs to.
   * It's safe to call this method without any previous checks, method will handle everything
   * @param dealId
   */
  async holdingDepositPaid(
    dealId: string,
  ): Promise<HoldingDepositPaidMutation['holding_deposit_paid']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<HoldingDepositPaidMutation, HoldingDepositPaidMutationVariables>({
        mutation: HOLDING_DEPOSIT_PAID,
        variables: {
          dealId,
        },
      }),
    );
    return resp.data.holding_deposit_paid;
  }

  async getListingDetail(
    $variables: GetListingDetailQueryVariables,
  ): Promise<GetListingDetailQuery['listing']> {
    const response = await firstValueFrom(
      this.apollo.query<GetListingDetailQuery, GetListingDetailQueryVariables>({
        query: GET_LISTING_DETAIL,
        variables: $variables,
      }),
    );

    return response.data.listing;
  }

  async getDealByPropertyId(propertyId: string): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.query<GetDealByPropertyIdQuery, GetDealByPropertyIdQueryVariables>({
        query: GET_LATEST_DEAL_BY_PROPERTY_ID,
        variables: {
          propertyId,
        },
      }),
    );
    return resp.data.deal[0]?.id;
  }

  async editDealNameOnContract(
    dealId: string,
    nameOnContract: string,
  ): Promise<EditDealNameOnContractMutation['edit_deal_name_on_contract']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<EditDealNameOnContractMutation, EditDealNameOnContractMutationVariables>({
        mutation: EDIT_DEAL_NAME_ON_CONTRACT,
        variables: {
          dealId,
          nameOnContract,
        },
      }),
    );
    const updateObj = resp.data.edit_deal_name_on_contract;
    // show error if something goes wrong
    if (!updateObj.success) {
      this.alert.error(updateObj.message);
    }

    return updateObj;
  }

  async syncDealPayableOnSf(
    dealId: string,
  ): Promise<SyncDealPayableOnSfMutation['salesforce_sync_payable_on']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<SyncDealPayableOnSfMutation, SyncDealPayableOnSfMutationVariables>({
        mutation: SYNC_DEAL_PAYABLE_ON_SF,
        variables: { dealId: dealId },
      }),
    );
    return resp.data.salesforce_sync_payable_on;
  }

  async syncDealDepositPercentageOnSf(
    dealId: string,
  ): Promise<SyncDealDepositPercentageOnSfMutation['salesforce_sync_deal_deposit_percentage']> {
    const resp = await firstValueFrom(
      this.apollo.mutate<
        SyncDealDepositPercentageOnSfMutation,
        SyncDealDepositPercentageOnSfMutationVariables
      >({
        mutation: SYNC_DEAL_DEPOSIT_PERCENTAGE_ON_SF,
        variables: { dealId: dealId },
      }),
    );
    return resp.data.salesforce_sync_deal_deposit_percentage;
  }

  async addBuyerToEOI($dealContacts: Deal_Contact_Set_Input[]): Promise<number> {
    const resp = await firstValueFrom(
      this.apollo.mutate<CreateDealContactMutation, CreateDealContactMutationVariables>({
        mutation: CREATE_DEAL_CONTACT,
        variables: { inputs: $dealContacts },
      }),
    );

    return resp.data.insert_deal_contact.affected_rows;
  }

  async getActiveOfferWithConfig($id: string): Promise<GetActiveOfferQuery> {
    const resp = await firstValueFrom(
      this.apollo.query<GetActiveOfferQuery, GetActiveOfferQueryVariables>({
        query: GET_ACTIVE_OFFER,
        variables: { id: $id },
      }),
    );
    return resp.data;
  }

  async getBuyerIds($dealId: string): Promise<string[]> {
    const resp = await firstValueFrom(
      this.apollo.query<GetBuyersIdsQuery, GetBuyersIdsQueryVariables>({
        query: GET_BUYERS_IDS,
        variables: { dealId: $dealId },
      }),
    );

    if (!resp.data.deal_contact.length) {
      return [];
    }
    return resp.data.deal_contact.map((contact) => contact.contact_id);
  }

  titleById$(dealId: string): Observable<string> {
    return this.apollo.query<DealTitleByIdQuery, DealTitleByIdQueryVariables>({
      query: DEAL_TITLE_BY_ID,
      variables: {
        dealId,
      },
    }).pipe(
      map((resp) => {
        const deal = resp.data.deal_by_pk;
        if (!deal) {
          return 'Not Found';
        }
        const propertyName = deal?.property?.name ?? '';
        switch (deal.type) {
          case Deal_Type_Enum.Enquiry:
            return propertyName ? `${propertyName} - Holding Deposit` : 'Holding Deposit';
          case Deal_Type_Enum.Offer:
            return propertyName ? `${propertyName} - Sales Contract` : 'Sales Contract';
          default:
            return 'Unknown Deal';
        }
      }),
    );
  }
}

// QUERIES
const UPDATE_DEAL_CONTACT = gql`
  mutation updateDealContact($dealId: uuid!, $input: deal_contact_set_input!) {
    update_deal_contact(where: { deal_id: { _eq: $dealId } }, _set: $input) {
      affected_rows
    }
  }
`;

const UPDATE_DEAL = gql`
  mutation updateDeal($dealId: uuid!, $input: deal_set_input!) {
    update_deal_by_pk(pk_columns: { id: $dealId }, _set: $input) {
      id
      changes_requested
    }
  }
`;

const CANCEL_DEAL = gql`
  mutation cancelDeal($dealReq: CancelDealRequest!) {
    cancel_deal(req: $dealReq) {
      success
      error
    }
  }
`;

const GET_MAKE_SOME_CHANGE_REQUEST = gql`
  query getMakeSomeChangeRequest($dealId: uuid!) {
    deal_by_pk(id: $dealId) {
      changes_requested
    }
  }
`;

const GET_DEAL_CONTACTS = gql`
  query getDealContacts($dealId: String!) {
    deal_contacts(dealId: $dealId) {
      id
      first_name
      last_name
      document_id
      is_valid
      attachment_id
      attachment_name
      download_url
      attachment_type
      content_type
      email
      phone_number
      firb_purchaser
      tax_file_number
    }
  }
`;

const HOLDING_DEPOSIT_PAID = gql`
  mutation holdingDepositPaid($dealId: String!) {
    holding_deposit_paid(dealId: $dealId) {
      success
      error
    }
  }
`;

const GET_LISTING_DETAIL = gql`
  query getListingDetail($conditions: [listing_bool_exp!]) {
    listing(where: { _and: $conditions }) {
      id
      price
      car_space
      release_number
      project_id
      deals {
        status
        contact {
          id
          first_name
          last_name
        }
      }
      project {
        name
        type
        brand_bg_color
        brand_fg_color
        organisation {
          id
          name
        }
        background_attachment {
          id
          name
          download_url
          type
          content_type
          thumbnails(where: { attachment_thumbnail_type: { _eq: PROJECT_CARD } }) {
            attachment_thumbnail_type
            thumbnail_url
          }
        }
        logo_attachment {
          id
          name
          download_url
          type
          content_type
          thumbnails(where: { attachment_thumbnail_type: { _eq: PROJECT_LOGO_SM } }) {
            attachment_thumbnail_type
            thumbnail_url
          }
        }
      }
      property {
        id
        name
        bedroom
        bathroom
        internal_area
        external_area
        lot
        lot_area
        level
        lot_suffix
        lot_width
        lot_depth
        aspect
        type
        storage
        property_group {
          id
          name
          type
          property_group_parent {
            name
            type
          }
        }
        floor_plan {
          id
          name
          download_url
          path
          type
          content_type
          attachment_config(where: { attachment_thumbnail_type: { _eq: LISTING_PAGE } }) {
            attachment_thumbnail_type
            width
            height
            fit
            position
          }
          thumbnails(where: { attachment_thumbnail_type: { _eq: LISTING_PAGE } }) {
            attachment_thumbnail_type
            thumbnail_url
          }
        }
      }
    }
  }
`;

const GET_LATEST_DEAL_BY_PROPERTY_ID = gql`
  query getDealByPropertyId($propertyId: uuid!) {
    deal(
      where: { property_id: { _eq: $propertyId }, status: { _neq: CANCELLED } }
      order_by: { created_at: desc_nulls_last }
    ) {
      id
    }
  }
`;

const EDIT_DEAL_NAME_ON_CONTRACT = gql`
  mutation editDealNameOnContract($dealId: uuid!, $nameOnContract: String!) {
    edit_deal_name_on_contract(dealId: $dealId, nameOnContract: $nameOnContract) {
      message
      success
    }
  }
`;

const SYNC_DEAL_PAYABLE_ON_SF = gql`
  mutation syncDealPayableOnSf($dealId: String!) {
    salesforce_sync_payable_on(dealId: $dealId) {
      success
      message
    }
  }
`;

const SYNC_DEAL_DEPOSIT_PERCENTAGE_ON_SF = gql`
  mutation syncDealDepositPercentageOnSf($dealId: String!) {
    salesforce_sync_deal_deposit_percentage(dealId: $dealId) {
      success
      message
    }
  }
`;

const GET_ACTIVE_OFFER = gql`
  query getActiveOffer($id: uuid!) {
    deal_by_pk(id: $id) {
      created_by
      created_by_team_id
    }
    reservation(where: { deal_id: { _eq: $id } }) {
      step_config
    }
  }
`;

const DEAL_TITLE_BY_ID = gql`
  query dealTitleById($dealId: uuid!) {
    deal_by_pk(id: $dealId) {
      type
      property {
        name
      }
    }
  }
`;

const GET_BUYERS_IDS = gql`
  query getBuyersIds($dealId: uuid!) {
    deal_contact(where: {deal_id: {_eq: $dealId} }) {
       contact_id
    }
  }
`;

const CREATE_DEAL_CONTACT = gql`
  mutation createDealContact($inputs: [deal_contact_insert_input!]!) {
    insert_deal_contact(objects: $inputs) {
      affected_rows
    }
  }
`;
