import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'skychute-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent {
  @Input() titleText: string;
  @Input() slim = true;

  /**
   * By default, value of 'inherit' is used. Which is usually white background.
   * It's possible to set it to "grey", it will autoconfigure border & bg color.
   * @param bgColor
   */
  @Input() set background(bgColor: string | 'gray' | 'grey') {
    if (!bgColor) {
      this.bgColor = 'inherit';
      this.border = this.borderOnWhite;
    } else {
      if (bgColor === 'gray' || bgColor === 'grey') {
        // make bg grey
        this.bgColor = 'var(--grey-lightest)';
        this.border = this.borderOnGrey;
      } else {
        this.bgColor = bgColor;
        this.border = this.borderOnWhite;
      }
    }
  }

  /**
   * Icon and Label config for the primary button
   */
  @Input() primaryButton: ContainerButton|null|undefined;

  /**
   * Whether we need to hide primaryButton and show Save & Cancel buttons instead
   */
  @Input() showSaveCancelButtons = false;

  @Output() primaryButtonClick = new EventEmitter<void>();
  @Output() saveButtonClick = new EventEmitter<void>();
  @Output() cancelButtonClick = new EventEmitter<void>();

  borderOnWhite = '1px solid var(--grey-lightest)';
  borderOnGrey = '1px solid var(--grey-lighter)';

  bgColor = 'inherit';
  border = this.borderOnWhite;
}

export interface ContainerButton {
  label: string;
  icon: string; // material icon name
}
