export interface CardImageInterface {
  name?: string; // original file name
  downloadURL?: string; // original s3 URL
  imageURL?: string; // Big image URL which is generated from PDF
  thumbnailCardURL?: string; // resize thumbnail image url
  viewImageUrl?: string; // resize image view url
  id?: string; // optional
  type?: string; // optional
  projectCardURL?: string;
  logoCardURL?: string;
  contentType?: string;
  productTypes?: Array<string>;
  uploadedByUserId?: string;
  uploadedByTeamId?: string;
}
