import { User_Feature_Enum, Web_App_Feature_Enum } from '@skychute/schema';
import { GetUserAppConfigQuery } from '../../generated/lib/operations';

export class UserAppConfig {
  user: UserAppConfigUser;
  team: UserAppConfigCurrentTeam;
  teams: UserAppConfigTeam[];
  salesforce: UserAppConfigSalesforce[];
  graphQlQueryLog: boolean;
  cdn: string;

  constructor(input: GetUserAppConfigQuery['user_app_config'], currentTeamId = '') {
    this.team = {
      featurePacks: input.featurePacks,
      webAppFeatures: input.webAppFeatures as Web_App_Feature_Enum[],
      extraFeatures: input.extraFeatures as Web_App_Feature_Enum[],
      logoUrl: input.teamLogoUrl,
      favIconUrl: input.teamFavIconUrl,
      name: '',
    };

    this.user = {
      isEmailVerified: input.isEmailVerified,
      isPhoneVerified: input.isPhoneVerified,
      memberFeatures: input.memberFeatures as User_Feature_Enum[],
      canAllocateProjectIds: input.projectIdsToAllocate, // Mars Test Project
    };

    // Membership ordering, current team should be first in the list
    let teams = input.teams;

    // remove current team from the org list
    if (teams.length > 1) {
      // find current user's team
      const currentTeam = teams.find((team) => team.id === currentTeamId);
      if (currentTeam) {
        this.team.name = currentTeam.name;
        teams = teams.filter((team) => team.id !== currentTeamId);
        // sort teams by name asc
        teams = teams.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        // insert current team to the beginning of the team list
        teams.unshift(currentTeam);
      }
    }

    // make team list available for the drop down menu
    this.teams = teams;

    this.salesforce = input.salesforce;
    this.graphQlQueryLog = input.graphQlQueryLog;
    this.cdn = input.cdn;
  }

  /**
   * Whether user can access Project / Edit page
   * @param projectId
   */
  canEditProject(projectId: string): boolean {
    // in order to access Network page user must be able to go to Project / Edit
    return this.user.canAllocateProjectIds.includes(projectId);
  }

  /**
   * Whether user can manage allocations for given project
   * @param projectId
   */
  canAllocateProject(projectId: string): boolean {
    return this.user.canAllocateProjectIds.includes(projectId);
  }

  canAllocateSomeProject(): boolean {
    return this.user.canAllocateProjectIds.length > 0;
  }
}

export interface UserAppConfigTeam {
  id: string;
  name: string;
  role: string;
}

export interface UserAppConfigSalesforce {
  sfInstanceId: string;
  name: string;
  isCrmOwner: boolean;
}

export interface UserAppConfigUser {
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  memberFeatures: User_Feature_Enum[];
  canAllocateProjectIds: string[];
}

export interface UserAppConfigCurrentTeam {
  name: string;
  logoUrl: string;
  favIconUrl: string;
  webAppFeatures: Web_App_Feature_Enum[];
  featurePacks: string[];
  extraFeatures: Web_App_Feature_Enum[];
}
