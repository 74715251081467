import { Inject, Injectable } from '@angular/core';
import { JwtService } from '@skychute/jwt';
import { HttpClient } from '@angular/common/http';
import { IEnvironment } from "@skychute/ui-models";
import { Apollo } from 'apollo-angular';
import gql from "graphql-tag";
import { firstValueFrom } from "rxjs";
import { GetHubspotCrmQuery, GetHubspotCrmQueryVariables } from "../generated/lib/operations";

@Injectable({
  providedIn: 'root',
})
export class HubSpotTrackingService {
  hubSpotScriptId = 'hs-script-loader';
  hubSpotPortalId = '';
  isScriptInjected = false;
  eventUrl = 'https://api.hubspot.com/events/v3/send';
  environment: IEnvironment;

  constructor(
    private jwt: JwtService,
    private http: HttpClient,
    private apollo: Apollo,
    @Inject('environment') environment) {
    this.environment = environment;
  }

  /**
   * This will add a new <script> tag for HubSpot Tracking if it's not already present
   * Returns promise which will be resolved when all JS files of HubSpot Tracking are loaded
   */
  async injectSegmentScriptTag(callback: () => void = null): Promise<void> {
    try {
      if (this.isScriptInjected || this.isHubSpotTrackingScriptInjected()) {
        return;
      }
      if(!this.hubSpotPortalId){
        await this.getHubSpotPortalId();
      }
      // type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/{hubId}.js"
      const hubSpotScriptElement = document.createElement('script');
      hubSpotScriptElement.setAttribute('id', this.hubSpotScriptId);
      hubSpotScriptElement.setAttribute('type', 'text/javascript');
      hubSpotScriptElement.setAttribute('src', `//js.hs-scripts.com/${this.hubSpotPortalId}.js`);
      document.body.appendChild(hubSpotScriptElement);
      this.isScriptInjected = true;
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
    }
  }

  isHubSpotTrackingScriptInjected(): boolean {
    return !!document.querySelector(`#${this.hubSpotScriptId}`);
  }

  async getHubSpotPortalId(): Promise<void> {
    const name = 'LINK HubSpot Production';
    const hubspotProdCrm = await this.getHubSpotCrm(name);
    if (hubspotProdCrm) {
      this.hubSpotPortalId = hubspotProdCrm
    } else {
      this.hubSpotPortalId = '44862234';
    }
  }

  identifyUser(): void {
    const email = this.jwt.getUserEmail();
    const _hsq = window['_hsq'] || [];
    _hsq.push(['identify', {
      email,
    }]);
    this.injectSegmentScriptTag();
    console.log('HubSpotTrackingService.identifyUser()');
  }

  trackPageView(path: string): void {
    const _hsq = window['_hsq'] || [];
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
    this.injectSegmentScriptTag();
    console.log(`HubSpotTrackingService.trackPageView(${path})`);
  }

  async viewedProject(projectName: string): Promise<void> {
    // const _hsq = window['_hsq'] || [];
    // console.log(window['_hsq']);
    const eventPayload = {
      name: 'pe44862234_viewed_project',
      properties: {
        project_name: projectName,
      },
    };
    console.log(eventPayload);
    // _hsq.push([
    //   'trackCustomBehavioralEvent',
    //   eventPayload,
    // ]);
  }

  async getHubSpotCrm(name: string): Promise<string> {
    const resp = await firstValueFrom(
      this.apollo.query<GetHubspotCrmQuery, GetHubspotCrmQueryVariables>({
        query: GET_HUBSPOT_CRM,
        variables: { name },
      })
    );

    return resp.data.crm_connection[0]?.sf_instance_id ?? '';
  }
}

const GET_HUBSPOT_CRM = gql`
  query getHubspotCrm($name: String!) {
    crm_connection(where: { name: { _eq: $name } }) {
      sf_instance_id
      name
    }
  }
`;
