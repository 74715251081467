import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentModel } from '@skychute/ui-models';

@Component({
  selector: 'skychute-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  myFiles: AttachmentModel[] = [];

  @Input() set files(files: AttachmentModel[]) {
    if (files?.length !== this.myFiles?.length) {
      this.myFiles = files;
    }
  }
  @Input() enableFilePreviewer = true;
  @Output() fileRemove: EventEmitter<AttachmentModel> = new EventEmitter<AttachmentModel>();

  getFileIcon(contentType: string) {
    if (contentType.match(/.(jpg|jpeg|png|gif)$/i)) return 'image';
    else if (contentType.match(/.(pdf)$/i)) return 'picture_as_pdf';
    else return 'assignment';
  }
}
