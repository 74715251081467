import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService } from '@skychute/jwt';
import { Roles } from '@skychute/ui-models';
import { InvitationService } from './invitation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private jwt: JwtService,
    public router: Router,
    private invitationService: InvitationService,
  ) {}

  async deleteSession(state: RouterStateSnapshot): Promise<void> {
    if (this.isInvitationUrl(state.url)) {
      await this.jwt.deleteToken();
      const invitationId = this.getInvitationId(state.url);
      if (!invitationId) {
        // Unable to find invitationId in the URL
        await this.router.navigate(['/auth/login'], { queryParams: { return: state.url } });
        return;
      }

      const isExistingUser = await this.invitationService.isExistingUserInvited(invitationId);
      if (isExistingUser === undefined) {
        // Invitation not found
        await this.router.navigate(['/auth/login'], { queryParams: { return: state.url } });
        return;
      }

      if (isExistingUser) {
        await this.router.navigate(['/auth/login'], { queryParams: { return: state.url } });
      } else {
        await this.router.navigate(['/auth/register'], { queryParams: { return: state.url } });
      }
    } else {
      // redirect to token refresh page, try to recover access token if possible
      // it will auto redirect to /auth/login in case of any errors
      await this.router.navigate(['/auth/refresh'], { queryParams: { return: state.url } });
    }
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      if (this.jwt.isAuthenticated()) {
        const roles = next.data.roles as Roles[];
        if (roles && roles.length > 0) {
          return this.jwt.hasRole(roles);
        }
        return true;
      } else {
        await this.deleteSession(state);
        return false;
      }
    } catch (e) {
      await this.deleteSession(state);
      return false;
    }
  }

  isInvitationUrl(url: string): boolean {
    return url.toLowerCase().includes('/pages/invitation/');
  }

  getInvitationId(url: string): string {
    const match = url.match(/pages\/invitation\/(.+)/);
    return match?.[1] || '';
  }
}
