export type AnnatureFieldId =
  | 'psol_org_name'
  | 'psol_org_address'
  | 'psol_name'
  | 'psol_phone'
  | 'psol_email'
  | 'price'
  | 'deposit'
  | 'balance'
  | 'unit'
  | 'lot'
  | 'buyer_1_sig'
  | 'buyer_2_sig'
  | 'vendor_1_sig'
  | 'vendor_2_sig'
  | 'witness_1_sig'
  | 'joint_tenants'
  | 'tenants_in_common'
  | 'in_unequal_shares'
  | 'in_unequal_shares_specify'
  | 'buyer_1_name'
  | 'buyer_1_address'
  | 'buyer_1_address_2'
  | 'buyer_1_tax_file_number'
  | 'buyer_2_tax_file_number'
  | 'resident_au'
  | 'resident_permanent'
  | 'resident_nationality'
  | 'resident_other_nationality'
  | 'buyer_1_company_name'
  | 'buyer_1_company_shareholders'
  | 'buyer_1_company_sig'
  | 'buyer_1_director_name'
  | 'buyer_1_trustee_yes'
  | 'buyer_1_trustee_no'
  | 'buyer_1_trustee_info'
  | 'firb_buyer_1_name'
  | 'firb_buyer_1_address'
  | 'firb_buyer_1_tax_file_number'
  | 'firb_buyer_2_name'
  | 'firb_buyer_2_address'
  | 'firb_buyer_2_tax_file_number'
  | 'flooring_upgrade'
  | 'appliance_upgrade_package'
  | 'blinds_automation'
  | 'buyer_2_director_name'
  | 'buyer_2_company_sig'
  | 'buyer_2_name'
  | 'buyer_2_name_2'
  | 'witness_1_sig_1'
  | 'witness_1_sig_2'
  | 'witness_1_name_1'
  | 'witness_1_name_2'
  | 'contract_date'
  | 'resident_other'
  | 'buyer_1_email'
  | 'buyer_2_email'
  | 'buyer_1_mobile'
  | 'buyer_2_mobile'
  | 'vendor_1_email'
  | 'vendor_1_mobile'
  | 'vendor_2_email'
  | 'vendor_2_mobile'
  | 'name_on_contract'
  | 'psol_sig_1'
  // new
  | 'buyer_1_company_position'
  | 'buyer_2_company_position'
  | 'colour_dark'
  | 'colour_light'
  // 66w fields
  | 's66w_psol_name'
  | 's66w_psol_org_name'
  | 's66w_unit'
  | 's66w_lot'
  | 's66w_name_on_contract'
  | 's66w_psol_date_signed'
  | 's66w_psol_sig_1'
  | 'has_carspace'
  | 'num_carspaces'
  | 'storage';

export type AnnatureAgencyAgreementFieldId =
  | 'agency_name'
  | 'agency_full_address'
  | 'agency_acn'
  | 'agency_abn'
  | 'agency_registered_for_gst'
  | 'agency_trading_name'
  | 'agency_licence_number'
  | 'licensee_name'
  | 'licensee_full_address'
  | 'commission_rate'
  | 'agency_full_address_2'
  | 'agent_user_email'
  | 'agent_user_phone' // new
  | 'agent_user_company' // new
  | 'agent_user_sig_1'
  | 'agent_user_sig_2'
  | 'agent_user_full_name'
  | 'dev_user_sig'
  | 'dev_user_full_name'
  | 'dev_sign_date'
  | 'buildings';
