import { Attachment_Thumbnail_Type_Enum } from '@skychute/schema';

export interface AttachmentConfigInterface {
  attachment_thumbnail_type: Attachment_Thumbnail_Type_Enum;
  width?: number | null;
  height?: number | null;
  fit?: string | null;
  position?: string | null;
  format?: string | null;
}
