import { Deal_Status_Enum } from '@skychute/schema';

/**
 * In order to get display price for the listings
 * we need to provide the following information
 * the way this information is fetched most efficiently is up to developer to decide
 */
export interface ListingPriceRequest {
  listingPrice: number;
  // if listing has an Offer, which is not Cancelled, see requirements below
  // deals(where: {
  //   status: { _neq: CANCELLED },
  //   type: { _eq: OFFER }
  // }, limit: 1, order_by: { created_at: desc }) {
  offer?: {
    status: Deal_Status_Enum | null | undefined;
    // comes from deal.deal_price_inc_variations_calc in case offer for offerStatus exists
    priceIncVariationsCalc: number | undefined;
  };
}
