import { Member_Role_Enum, Organisation_Type_Enum } from '@skychute/schema';

export interface HasuraClaimsInterface {
  'x-hasura-user-id': string;
  'x-hasura-default-role': string;
  'x-hasura-allowed-roles': string[];
  'x-hasura-team-id': string;
  'x-hasura-team-role': Member_Role_Enum;
  'x-hasura-team-type': Organisation_Type_Enum;
}
